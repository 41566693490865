import React, { useState, useEffect } from "react";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Loader from "react-loader-spinner";
const PageTemplate = ({ user, leagues }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getData = async () => {
      setLoading(false);
    };
    getData();
  }, []);
  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );

  return (
    <div className="pageWrapper">
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="h4">Hello World</Typography>
          <Divider className="margin10" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography variant="h4">Stuff</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography variant="h4">Stuff</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Divider className="margin10" />
          <Typography variant="h4">Stuff</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default PageTemplate;

import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { noActionEmail } from "../../functions/Messaging";
import DefaultDialog from "../../components/DefaultDialog";

import ViewRichText from "../../components/ViewRichText";

const AdminProfileEdit = ({
  openEdit,
  setOpenEdit,
  selectedDirector,
  setSelectedDirector,
  selectedApp,
  setSelectedApp,
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [birthday, setBirthday] = useState(null);
  const [playZipCode, setPlayZipCode] = useState("");
  const [image, setImage] = useState("");
  const [ntrpRating, setNtrpRating] = useState("");
  const [facebook, setFacebook] = useState(null);
  const [linkedIn, setLinkedIn] = useState(null);
  const [twitter, setTwitter] = useState(null);
  const [experience, setExperience] = useState("");
  const [experienceYears, setExperienceYears] = useState("");
  const [qualities, setQualities] = useState();

  useEffect(() => {
    if (selectedDirector !== undefined) {
      setGender(selectedDirector.gender);
      setBirthday(
        selectedDirector.birthday !== "" ? selectedDirector.birthday : null
      );
      setPlayZipCode(selectedDirector.playLocation.zipCode);
      setImage(selectedDirector.imageURL);
      setNtrpRating(selectedDirector.ntrpRating);
      setPhoneNumber(selectedDirector.phoneNumber);
      setEmail(selectedDirector.email);

      setFacebook(selectedApp.information.facebook);
      setTwitter(selectedApp.information.twitter);
      setLinkedIn(selectedApp.information.linkedIn);
      setExperience(selectedApp.information.experience);
      setExperienceYears(selectedApp.information.experienceYears);
      setQualities(selectedApp.information.qualities);
    } else {
      setPhoneNumber("");
      setGender("");
      setBirthday(null);
      setPlayZipCode("");
      setImage("");
      setNtrpRating("");
    }
  }, [selectedDirector, selectedApp]);
  const sendMessage = async () => {
    await noActionEmail({
      subject:
        "Congratulations – Your Application to be a League Director is Approved",
      message:
        "We’re excited you have chosen to partner with Game.Set.Match to bring you and other tennis enthusiast in your area together through the love of tennis.",
      message2:
        "This is to confirm that you are now registered as a League Director in your area. With Game.Set.Match, you can set up events, leagues and tournaments whenever and wherever it’s convenient for you and recreational tennis players in your area.",
      message3:
        "Standby for instructions. Someone will contact you soon to help you get started and start bringing people in your area together.",
      recipients: [
        { email: selectedDirector.email, name: selectedDirector.firstName },
      ],
    });
  };
  const handlePlayerUpdate = (e) => {
    e.preventDefault();
    selectedDirector.ref.update({
      director: true,
    });
    selectedApp.ref.update({
      status: "approved",
    });
    sendMessage();
    setOpenEdit(false);
    setSelectedDirector(undefined);
    setSelectedApp(undefined);
  };
  return (
    <DefaultDialog open={openEdit} setOpen={setOpenEdit}>
      {selectedDirector !== undefined && (
        <div className="column">
          <div className="rowCenter">
            <div className="columnAlignCenter">
              <Avatar
                variant="rounded"
                src={image}
                className="image200Rounded"
              />
            </div>
            <div className="column margin10Left">
              <Typography className="profileInfoCardTitle">
                {`${selectedDirector.firstName} ${selectedDirector.lastName}`}
              </Typography>
              <Typography className="profileInfoCardSubtitle">
                {`Rating: ${Math.round(selectedDirector.gsmRating * 100)}`}
              </Typography>
            </div>
          </div>
          <Divider className="margin20Top margin20Bottom" />
          <Typography className="profileSubpageTitle margin20Bottom">
            General Information
          </Typography>
          <form className="column" onSubmit={handlePlayerUpdate}>
            <Typography className="profileRequiredFieldText margin10Top">
              Phone Number
            </Typography>
            <Typography className="h5">{phoneNumber}</Typography>
            <Typography className="profileRequiredFieldText margin10Top">
              Email
            </Typography>
            <Typography className="h5">{email}</Typography>
            <Typography className="profileRequiredFieldText margin10Top">
              Date of Birth
            </Typography>
            <Typography className="h5">{birthday}</Typography>
            <Typography className="profileRequiredFieldText margin10Top">
              Gender
            </Typography>
            <Typography className="h5">{gender}</Typography>
            <Typography className="profileRequiredFieldText margin10Top">
              NTRP Rating
            </Typography>
            <Typography className="h5">{ntrpRating}</Typography>
            {facebook ? (
              <div>
                <Typography className="profileRequiredFieldText margin10Top">
                  Facebook
                </Typography>
                <Typography className="h5">{facebook}</Typography>
              </div>
            ) : (
              <div />
            )}
            {facebook ? (
              <div>
                <Typography className="profileRequiredFieldText margin10Top">
                  Twitter
                </Typography>
                <Typography className="h5">{twitter}</Typography>
              </div>
            ) : (
              <div />
            )}
            {facebook ? (
              <div>
                <Typography className="profileRequiredFieldText margin10Top">
                  LinkedIn
                </Typography>
                <Typography className="h5">{linkedIn}</Typography>
              </div>
            ) : (
              <div />
            )}

            <Divider className="margin20Top margin20Bottom" />
            <Typography className="profileSubpageTitle margin10Bottom">
              Zone Designation
            </Typography>
            <Typography className="profileRequiredFieldText margin10Top">
              Zip Code Designation
            </Typography>
            <Typography className="h5">{playZipCode}</Typography>
            <Typography className="profileRequiredFieldText margin10Top">
              Section
            </Typography>
            <Typography className="h5">
              {selectedDirector.playLocation.sectionals}
            </Typography>
            <Typography className="profileRequiredFieldText margin10Top">
              Locale
            </Typography>
            <Typography className="h5">
              {selectedDirector.playLocation.locale}
            </Typography>
            <Typography className="profileRequiredFieldText margin10Top">
              Area
            </Typography>
            <Typography className="h5">
              {selectedDirector.playLocation.area}
            </Typography>
            <Divider className="margin20Top margin20Bottom" />
            <Typography className="profileSubpageTitle margin20Bottom">
              Application Information
            </Typography>
            <Typography className="profileRequiredFieldText">
              Experience
            </Typography>
            <Typography className="h5">
              {experience === "yes" ? experienceYears + " years" : "none"}
            </Typography>
            <Typography className="profileRequiredFieldText margin10Top">
              Qualities
            </Typography>
            <ViewRichText value={qualities} />
            {selectedApp.information.status === "pending" ? (
              <Button
                className="profileSaveButton margin20Top"
                variant="outlined"
                type="sumbit"
              >
                <Typography className="profileSaveButtonText">
                  Approve
                </Typography>
              </Button>
            ) : (
              <div />
            )}
          </form>
        </div>
      )}
    </DefaultDialog>
  );
};

export default AdminProfileEdit;

import React, { useState, useEffect, Fragment } from "react";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import orderby from "lodash.orderby";
import { DefaultPagination } from "./Pagination";
import { SortableCell, OpenNestedCell } from "./TableCells";
import TableComponent from "./TableComponent";
const DefaultCell = ({ cell }) => {
  return <TableCell align="left">{cell.content}</TableCell>;
};

const NestedTable = ({
  nestedList,
  setNestedList,
  tableHeader,
  subTableHeader,
  handleComponentEdit,
  rowsPerPageOverride,
  type,
}) => {
  const [open, setOpen] = useState(
    new Array(nestedList.length).map((row) => false)
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    rowsPerPageOverride !== undefined ? rowsPerPageOverride : 5
  );
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortSelected, setSortSelected] = useState("name");
  //Sorts the list by the specified column
  const handleSort = (sort) => {
    let directionTemp = sortDirection === "asc" ? "desc" : "asc";
    setSortSelected(sort);
    setSortDirection(directionTemp);
    setNestedList(orderby(nestedList, sort, directionTemp));
  };
  useEffect(() => {
    if (open.length !== 0 && open.length < nestedList.length) {
      setOpen([
        ...open,
        ...Array(nestedList.length - open.length).map((row) => false),
      ]);
    }
  }, [nestedList, open]);
  const displayList = nestedList.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">View</TableCell>
            {tableHeader.map((cell, key) =>
              cell.sortable ? (
                <SortableCell
                  key={key}
                  cell={cell}
                  handleSort={handleSort}
                  sortSelected={sortSelected}
                  sortDirection={sortDirection}
                />
              ) : (
                <DefaultCell cell={cell} />
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {displayList.map((row, index) => (
            <Fragment key={index}>
              <TableRow hover>
                <OpenNestedCell open={open} setOpen={setOpen} rowPos={index} />
                {row.cells.map((cell, key) => (
                  <DefaultCell key={key} cell={cell} />
                ))}
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={tableHeader.length + 1}
                >
                  <Collapse in={open[index]} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                      <TableComponent
                        handleComponentEdit={handleComponentEdit}
                        list={row.subList}
                        nestedList={nestedList}
                        setList={setNestedList}
                        tableHeader={subTableHeader}
                        type={type}
                        tableNum={index}
                      />
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
        <DefaultPagination
          tableHeader={tableHeader}
          list={nestedList}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          colSpan={tableHeader.length + 1}
        />
      </Table>
    </TableContainer>
  );
};

export default NestedTable;

import React from "react";
import update from "immutability-helper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
const CheckboxList = ({ value, setValue, disabled }) => {
  return (
    <div className="rowCenter profileCheckboxWrapper">
      {value.map((val, i) => (
        <div key={i} className="margin20Right">
          <FormControlLabel
            label={val.label}
            labelPlacement="end"
            control={
              <Checkbox
                checked={val.check}
                color="primary"
                onChange={(e) => {
                  if (!disabled) {
                    const temp = update(value, {
                      [i]: { check: { $set: !value[i].check } },
                    });
                    setValue(temp);
                  }
                }}
              />
            }
          />
        </div>
      ))}
    </div>
  );
};
export default CheckboxList;

import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Loader from "react-loader-spinner";
import { firestore } from "../../../firebase";
import NestedTable from "../../../components/table/NestedTable";

const header = [
  { label: "name", content: "Player", sortable: true },
  { label: "email", content: "Email", sortable: true },
  { label: "code", content: "Code", sortable: true },
  { label: "active", content: "Active", sortable: true },
  { label: "numReferred", content: "Number of Referrals", sortable: true },
];
const subHeader = [
  { label: "name", content: "Player", sortable: true },
  { label: "email", content: "Email", sortable: true },
  { label: "status", content: "Status", sortable: true },
];
const AdminManageReferrals = ({ referrals }) => {
  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState([]);
  useEffect(() => {
    const playersRef = firestore.collection("users");
    const unsubscribePlayers = playersRef.onSnapshot((playersSnapshot) => {
      setTable(
        playersSnapshot.docs.map((playerDoc) => {
          const player = playerDoc.data();
          const id = playerDoc.id;
          const name = `${player.firstName} ${player.lastName}`;
          const email = player.email;
          const referredHistory = player.referrals;
          const numReferred = referredHistory.length;
          const referral = referrals.find((referral) => {
            return referral.createdBy === id;
          });

          const code = referral.code;
          const active = referral.active;
          const subList = referredHistory.map((referralInfo) => ({
            cells: [
              { content: referralInfo.name, editable: false },
              { content: referralInfo.email, editable: false },
              { content: referralInfo.status, editable: false },
            ],
            ...referralInfo,
          }));
          return {
            cells: [
              { content: name, editable: false },
              { content: email, editable: false },
              { content: code, editable: false },
              { content: active.toString(), editable: false },
              { content: numReferred, editable: false },
            ],
            name,
            email,
            code,
            active,
            numReferred,
            subList,
          };
        })
      );
      setLoading(false);
    });
    return () => {
      unsubscribePlayers();
    };
  }, [referrals]);
  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  return (
    <Grid item xs={12}>
      <NestedTable
        nestedList={table}
        setNestedList={setTable}
        tableHeader={header}
        subTableHeader={subHeader}
        type="viewOnly"
        rowsPerPageOverride={10}
      />
    </Grid>
  );
};
export default AdminManageReferrals;

import React, { useState, useEffect } from "react";
import { firestore } from "../../firebase";
import Grid from "@material-ui/core/Grid";
import { LoadMore } from "../../components/table/Pagination";
import ListItemLeague from "./ListItemLeague";
import MyLeagueSearchBar from "./MyLeagueSearchBar";
import Loader from "react-loader-spinner";

const MyLeagues = ({
  leagues,
  divisions,
  tracks,
  zoneList,
  sportLoading,
  mobileSize,
  user,
  leagueNameFilter,
  setLeagueNameFilter,
  filterBySection,
  setFilterBySection,
  filterByLocale,
  setFilterByLocale,
  filterByArea,
  setFilterByArea,
  divisionSelect,
  setDivisionSelect,
  playLevel,
  setPlayLevel,
}) => {
  const [myLeaguesList, setMyLeaguesList] = useState([]);
  const [sortBy, setSortBy] = useState("Date: Earliest-Latest");
  const [sortOptions] = useState([
    "Date: Earliest-Latest",
    "Date: Latest-Earliest",
  ]);
  const [statusSelect, setStatusSelect] = useState([
    {
      label: "Draft",
      check: true,
      value: "draft",
      value2: "draft",
      value3: "draft",
    },
    {
      label: "Pending",
      check: true,
      value: "pending",
      value2: "pending",
      value3: "pending",
    },
    {
      label: "Ongoing",
      check: true,
      value: "active",
      value2: "approved",
      value3: "playoffs",
    },
    {
      label: "Completed",
      check: true,
      value: "expired",
      value2: "closed",
      value3: "dead",
    },
  ]);
  const resultsPerPage = 18;
  const [pageNum, setPageNum] = useState(1);
  const [leagueSport, setLeagueSport] = useState([]);
  const indexOfLastItem = pageNum * resultsPerPage;

  useEffect(() => {
    //Gets leagues that user is either currently in or was in
    let myLeaguesTemp = [];
    user.leaguesJoined.forEach((lJ) => {
      if (!Object.keys(tracks).some((trackID) => lJ.trackID === trackID))
        return;
      let league = leagues[lJ.leagueID];
      let division = divisions[lJ.divisionID];
      let track = tracks[lJ.trackID];
      if (league?.status !== "expired")
        myLeaguesTemp.push({
          ...league,
          ...division,
          ...track,
          leagueType: "member",
        });
    });
    setMyLeaguesList(myLeaguesTemp);

    //Gets leagues that user is directing
    let directedLeagueList = [];

    const directedLeaguesRef = firestore
      .collection("leagues")
      .where("directorID", "==", user.uid)
      .where("sportType", "==", user?.currentSport || "tennis");

    const unsubscribeDirectedLeagues = directedLeaguesRef.onSnapshot(
      (snapshot) => {
        directedLeagueList = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          leagueType:
            doc.data().status === "draft" ? "directorDraft" : "director",
        }));
        setLeagueSport(directedLeagueList);
      }
    );

    return () => {
      unsubscribeDirectedLeagues();
    };
  }, [
    leagues,
    divisions,
    tracks,
    user.leaguesJoined,
    user.uid,
    zoneList,
    user.currentSport,
  ]);

  const filterByName = (l) => {
    if (leagueNameFilter === "") return true;
    return l?.name?.indexOf(leagueNameFilter) === 0;
  };
  const filterByDivision = (l) => {
    if (l.leagueType === "directorDraft" || l.leagueType === "director")
      return true;
    const temp = divisionSelect.filter((d) => d.label === l.division);
    return temp[0] ? temp[0].check : false;
  };
  const filterByStatus = (l) => {
    const temp = statusSelect.filter(
      (s) =>
        s.value === l.status || s.value2 === l.status || s.value3 === l.status
    );
    return temp[0].check;
  };
  const filterByLevel = (l) => {
    if (l.leagueType === "directorDraft" || l.leagueType === "director")
      return true;
    const temp = playLevel.filter((lev) => lev.level === l.level);

    return temp[0].check;
  };
  const sectionFilter = (item) => {
    if (filterBySection === "All") return true;
    return item?.section?.indexOf(filterBySection) === 0;
  };
  const localeFilter = (item) => {
    if (filterByLocale === "All") return true;
    return item.locale.indexOf(filterByLocale) === 0;
  };
  const areaFilter = (item) => {
    if (filterByArea === "All") return true;
    let found = false;
    item.locations.forEach((area) => {
      if (area === filterByArea) found = true;
    });
    return found;
  };
  const sortListings = (a, b) => {
    if (a.leagueType === "directorDraft") return 1;
    if (b.leagueType === "directorDraft") return -1;
    const aDate = a.startDate.toDate();
    const bDate = b.startDate.toDate();
    if (sortBy === "Date: Earliest-Latest") {
      return aDate - bDate;
    } else if (sortBy === "Date: Latest-Earliest") {
      return bDate - aDate;
    }
    return;
  };

  const displayList = [...myLeaguesList, ...leagueSport]
    .filter((l) => filterByName(l))
    .filter((l) => filterByDivision(l))
    .filter((l) => filterByLevel(l))
    .filter((l) => sectionFilter(l))
    .filter((l) => localeFilter(l))
    .filter((l) => areaFilter(l))
    .filter((l) => filterByStatus(l))
    .sort((a, b) => sortListings(a, b));

  if (sportLoading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  return (
    <Grid container spacing={mobileSize ? 3 : 6}>
      <Grid item xs={12}>
        <MyLeagueSearchBar
          search={leagueNameFilter}
          setSearch={setLeagueNameFilter}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortOptions={sortOptions}
          user={user}
          sectionSelect={filterBySection}
          setSectionSelect={setFilterBySection}
          localeSelect={filterByLocale}
          setLocaleSelect={setFilterByLocale}
          areaSelect={filterByArea}
          setAreaSelect={setFilterByArea}
          zoneList={zoneList}
          divisionSelect={divisionSelect}
          setDivisionSelect={setDivisionSelect}
          levelSelect={playLevel}
          setLevelSelect={setPlayLevel}
          statusSelect={statusSelect}
          setStatusSelect={setStatusSelect}
        />
      </Grid>
      <Grid className="columnCenter" item xs={12}>
        <Grid container spacing={mobileSize ? 3 : 6}>
          {displayList.slice(0, indexOfLastItem).map((combinedLeague, i) => (
            <ListItemLeague key={i} combinedLeague={combinedLeague} />
          ))}
        </Grid>

        <div className="filler margin10Bottom" />
        {indexOfLastItem < displayList.length && (
          <LoadMore setValue={setPageNum} />
        )}
      </Grid>
    </Grid>
  );
};

export default MyLeagues;

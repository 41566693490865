import React, { useState, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Loader from "react-loader-spinner";
import { firestore } from "../../firebase";
import { getUserByID } from "../../functions/firebaseFunctions";
import { OutlinedButton } from "../../components/CustomButton";
import { singleActionEmail, noActionEmail } from "../../functions/Messaging";
import { UpdateNotificationRespondeStatus } from "../../utils/NotificationServices";
const DoublesInvitation = ({
  user,
  invites,
  outgoingInvites,
  leagues,
  divisions,
  tracks,
}) => {
  const [redirect, setRedirect] = useState(false);
  const [redirectURL, setRedirectURL] = useState("");
  const [loading, setLoading] = useState(true);
  const [invite, setInvite] = useState(null);
  const [inviter, setInviter] = useState(null);
  const [league, setLeague] = useState(null);
  const [division, setDivision] = useState(null);
  const [track, setTrack] = useState(null);
  const [pageNum, setPageNum] = useState(0);
  const info = window.location.href.split("/");
  const inviteID = info[info.length - 1];
  const location = useLocation();
  useEffect(() => {
    const getData = async () => {
      const invite = invites.find((invite) => invite.id === inviteID);
      let pageNum = 0;
      if (invite?.status === "accepted") pageNum = 1;
      if (invite?.status === "declined") pageNum = 2;
      setPageNum(pageNum);
      setInvite(invite);
      const inviter = await getUserByID(invite?.inviter);
      setInviter(inviter);
      setLeague(leagues?.[invite?.leagueID]);
      setDivision(divisions?.[invite?.divisionID]);
      setTrack(tracks?.[invite?.trackID]);
      setLoading(false);
    };
    getData();
  }, [leagues, divisions, tracks, inviteID, invites]);
  const sendRejectionEmail = async (rejector, rejected) => {
    await noActionEmail({
      subject: `Your invitation to ${rejector?.firstName} to play as a doubles partner was declined.`,
      message: `Your invitation to play in ${league?.name} was declined by ${rejector?.firstName}. You can go back to playgamesetmatch.com and search for a new partner. Good luck!`,
      recipients: [{ email: rejected?.email, name: rejected?.firstName }],
    });
  };
  const sendRecallEmail = async (rejector, rejected) => {
    await noActionEmail({
      recipients: [{ email: rejected?.email, name: rejected?.firstName }],
      subject: `Invite to Join ${league?.name} Recalled`,
      message: `A previous invite sent to you by ${rejector?.firstName} ${rejector?.lastName} to join ${league?.name} was recalled. If you are interested in joining the league, go to playgamesetmatch.com and click on "Sign-UP".`,
    });
  };
  const rejectOtherInvites = async () => {
    await Promise.all(
      invites.map(async (otherInvite) => {
        if (
          otherInvite?.id !== invite?.id
          // && otherInvite.trackID === track.id
        ) {
          await otherInvite?.ref.update({ status: "declined" });
          const inviter = await getUserByID(otherInvite?.inviter);
          await sendRejectionEmail(user, inviter);
        }
      })
    );
    await Promise.all(
      outgoingInvites.map(async (otherInvite) => {
        if (
          otherInvite?.id !== invite.id
          // && otherInvite.trackID === track.id
        ) {
          await otherInvite?.ref.update({ status: "declined" });
          const invited = await getUserByID(otherInvite?.invited);
          await sendRecallEmail(user, invited);
        }
      })
    );
    const partnerIncomingInvitesSnapshot = await firestore
      .collection("doublesTeamInvites")
      .where("invited", "==", inviter?.id)
      .where("status", "!=", "declined")
      // .where("trackID", "==", track.id)
      .get();
    const partnerOutgoingInvitesSnapshot = await firestore
      .collection("doublesTeamInvites")
      .where("inviter", "==", inviter?.id)
      .where("status", "!=", "declined")
      // .where("trackID", "==", track.id)
      .get();
    await Promise.all(
      partnerIncomingInvitesSnapshot.docs.map(async (doc) => {
        const data = doc.data();
        const id = doc?.id;
        if (id !== invite?.id) {
          await doc.ref.update({ status: "declined" });
          const otherInviter = await getUserByID(data?.inviter);
          await sendRejectionEmail(inviter, otherInviter);
        }
      })
    );
    await Promise.all(
      partnerOutgoingInvitesSnapshot.docs.map(async (doc) => {
        const data = doc.data();
        const id = doc?.id;
        if (id !== invite?.id) {
          await doc.ref.update({ status: "declined" });
          const invited = await getUserByID(data?.invited);
          await sendRecallEmail(inviter, invited);
        }
      })
    );
  };

  const handleAcceptInvite = async (register) => {
    await invite?.ref.update({
      status: "accepted",
    });
    await rejectOtherInvites();
    if (register) {
      setRedirectURL(
        `/League/FindLeague/Doubles/${league?.id}/${division?.id}/${track?.id}/Purchase`
      );
      setRedirect(true);
    } else {
      await singleActionEmail({
        subject: `${user?.firstName} has accepted your invitation to be your double’s partner`,
        message: `${user?.firstName} has accepted to be your Double’s partner in a league, ${league?.name}. Please click on the link below to complete the registration process.`,
        recipients: [{ email: inviter?.email, name: inviter?.firstName }],
        url: `https://app.playgamesetmatch.com/League/FindLeague/Doubles/${league?.id}/${division?.id}/${track?.id}/Purchase`,
        buttonText: "Complete Registration",
      });
      setPageNum(1);
    }

    if (location?.state?.notificationId) {
      try {
        let res = await UpdateNotificationRespondeStatus([
          location?.state?.notificationId,
        ]);
        const resData = res.data;
        console.log(resData);
      } catch (error) {
        console.warn(error);
      }
    }
  };

  const handleRejectInvite = async () => {
    setPageNum(2);
    if (location?.state?.notificationId) {
      try {
        let res = await UpdateNotificationRespondeStatus([
          location?.state?.notificationId,
        ]);
        const resData = res.data;
        console.log(resData);
      } catch (error) {
        console.warn(error);
      }
    }
    await invite?.ref.update({
      status: "declined",
    });
    await sendRejectionEmail(user, inviter);
  };

  const handleClose = () => {
    setRedirectURL("/Dashboard");
    setRedirect(true);
  };

  if (redirect) return <Redirect to={redirectURL} />;
  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  return (
    <div className="columnCenter">
      <Typography className="signUpText">Doubles Invitation</Typography>
      {pageNum === 0 && (
        <Card className="signUpInnerDiv">
          <Grid container spacing={2}>
            <Grid className="columnCenter" item xs={12}>
              <Typography>
                You are invited by {inviter?.firstName} {inviter?.lastName} to
                play as a double’s partner in the league, {league?.name}{" "}
                {division?.division} Level {division?.level}: Track{" "}
                {track?.track + 1}
              </Typography>
            </Grid>
            <Grid className="columnCenter" item xs={12} md={4} lg={4} xl={4}>
              <OutlinedButton
                handleProcess={() => handleAcceptInvite(false)}
                text="Accept Invite"
              />
            </Grid>
            <Grid className="columnCenter" item xs={12} md={4} lg={4} xl={4}>
              <OutlinedButton
                handleProcess={() => handleAcceptInvite(true)}
                text="Accept Invite and Register"
              />
            </Grid>

            <Grid className="columnCenter" item xs={12} md={4} lg={4} xl={4}>
              <OutlinedButton
                handleProcess={handleRejectInvite}
                text="Reject Invite"
              />
            </Grid>
          </Grid>
        </Card>
      )}
      {pageNum === 1 && (
        <Card className="signUpInnerDiv">
          <Grid container spacing={2}>
            <Grid className="columnCenter" item xs={12}>
              <Typography>
                Thank you for your acknowledgement but please note that your
                registration to the league is not final until{" "}
                {inviter?.firstName} completes the process.
              </Typography>
            </Grid>
            <Grid className="columnCenter" item xs={12} md={12} lg={12} xl={12}>
              <OutlinedButton
                handleProcess={() => handleClose()}
                text="Close"
              />
            </Grid>
          </Grid>
        </Card>
      )}
      {pageNum === 2 && (
        <Card className="signUpInnerDiv">
          <Grid container spacing={2}>
            <Grid className="columnCenter" item xs={12}>
              <Typography>
                Thank you for your response. This should clear the Inviter to
                look for another tennis partner.
              </Typography>
            </Grid>
            <Grid className="columnCenter" item xs={12} md={12} lg={12} xl={12}>
              <OutlinedButton
                handleProcess={() => handleClose()}
                text="Close"
              />
            </Grid>
          </Grid>
        </Card>
      )}
    </div>
  );
};

export default DoublesInvitation;

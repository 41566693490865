import React, { useState } from "react";
import update from "immutability-helper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
export const CustomCheckbox = ({ checkboxes, setCheckboxes }) => {
  return (
    <div className="filterCardCheckBoxWrapper">
      {checkboxes.map((c, index) => (
        <FormControlLabel
          key={index}
          value={c.label}
          control={
            <Checkbox
              color="primary"
              checked={c.check}
              onChange={(e) => {
                const temp = update(checkboxes, {
                  [index]: { check: { $set: !checkboxes[index].check } },
                });
                setCheckboxes(temp);
              }}
            />
          }
          label={c.label}
          labelPlacement="end"
        />
      ))}
    </div>
  );
};
const DropdownCheckbox = ({ checkboxGroupName, checkboxes, setCheckboxes }) => {
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const open = Boolean(popoverAnchor);
  const handleClick = (e) => {
    setPopoverAnchor(e.target);
  };
  const handleClose = () => {
    setPopoverAnchor(null);
  };
  return (
    <div>
      <Button fullWidth={true} onClick={handleClick}>
        <div className="rowCenter">
          <Typography className="dropdownCheckboxButtonText">
            {checkboxGroupName}
          </Typography>
          <div className="filler" />
          <ArrowDropDown />
        </div>
      </Button>
      <Popover
        open={open}
        anchorEl={popoverAnchor}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <CustomCheckbox checkboxes={checkboxes} setCheckboxes={setCheckboxes} />
      </Popover>
    </div>
  );
};
export default DropdownCheckbox;

import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import AttachMoney from "@material-ui/icons/AttachMoney";
import InfoSplit from "../InfoSplit.js";

const PageFive = ({
  joinFee,
  setJoinFee,
  percentCut,
  setPercentCut,
  error,
  errorMessage,
  splitType, 
  setSplitType,
}) => {
  const handleSplit = (e) => {
    setSplitType(e.target.value);
    if (e.target.value === "Gold") setPercentCut(80);
    else if (e.target.value === "Silver") setPercentCut(50);
    else setPercentCut(20);
  };
  return (
    <Fragment>
      <Typography className="createLeagueFieldText">
        How much will it cost to join your event?
      </Typography>
      <Typography className="createLeagueFieldSubtext">Join Fee</Typography>
      <TextField
        value={joinFee}
        required
        placeholder="0.00"
        onChange={(e) => setJoinFee(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AttachMoney />
            </InputAdornment>
          ),
        }}
      />
      <div className="row posRelative">
        <Typography className="createLeagueFieldText margin20Top">
          Proposed split of earnings
        </Typography>
        <InfoSplit />
      </div>
      <div className="maxWidth">
        <RadioGroup value={splitType} onChange={handleSplit}>
          <FormControlLabel
            className="scoreUploadRadioText"
            value="Gold"
            control={<Radio color="default" />}
            label="Gold Split (Director 80%, Platform 20%)"
            labelPlacement="end"
          />
          <FormControlLabel
            value="Silver"
            className="scoreUploadRadioText"
            control={<Radio color="default" />}
            label="Silver Split (Director 50%, Platform 50%)"
            labelPlacement="end"
          />
          <FormControlLabel
            value="Bronze"
            className="scoreUploadRadioText"
            control={<Radio color="default" />}
            label="Bronze Split (Director 20%, Platform 80%)"
            labelPlacement="end"
          />
        </RadioGroup>
      </div>
      <div className="heightPlaceholder">
        {error && (
          <Typography className="createLeagueErrorText">
            {errorMessage}
          </Typography>
        )}
      </div>
    </Fragment>
  );
};

export default PageFive;

import React, { useState, useEffect } from "react";
import orderby from "lodash.orderby";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import { Pagination } from "@material-ui/lab";
import Edit from "@material-ui/icons/Edit";
import Loader from "react-loader-spinner";
import { firestore } from "../../../firebase";
import { Link } from "react-router-dom";
import { getUserByID } from "../../../functions/firebaseFunctions";
import LeagueSearchBar from "./LeagueSearchBar";
const AdminLeagueOverview = ({ user, zoneList }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortSelected, setSortSelected] = useState("date");
  const [leagueNameFilter, setLeagueNameFilter] = useState("");
  const [filterBySection, setFilterBySection] = useState(
    user.playLocation.sectionals
  );
  const [filterByLocale, setFilterByLocale] = useState("All");
  const [filterByArea, setFilterByArea] = useState("All");
  const [filterByStatus, setFilterByStatus] = useState("All");
  const [filterByType, setFilterByType] = useState("All");
  const createLeagueTable = (
    name,
    sportType,
    type,
    status,
    location,
    director,
    date,
    league
  ) => {
    return {
      name,
      sportType,
      type,
      status,
      location,
      director,
      date,
      league,
    };
  };

  const [numPages, setNumPages] = useState(0);
  const resultsPerPage = 10;
  const [pageNum, setPageNum] = useState(1);
  const pageEndIndex = pageNum * resultsPerPage;
  const pageStartIndex = pageEndIndex - resultsPerPage;
  useEffect(() => {
    const queryLeagues = async (leaguesRef) => {
      const snapshot = await leaguesRef.get();
      let tableTemp = [];
      await Promise.all(
        snapshot.docs.map(async (doc) => {
          let league = { id: doc.id, ...doc.data() };
          if (league.status !== "draft") {
            let directorTemp = await getUserByID(league.directorID);
            tableTemp.push(
              createLeagueTable(
                league.name,
                league.sportType ? league.sportType : "tennis",
                league.event ? "Event" : "League",
                league.status,
                `${league.section}, ${league.locale}`,
                directorTemp,
                league.startDate.toDate(),
                league
              )
            );
          }
        })
      );
      tableTemp.sort((a, b) => b.name - a.name);
      setNumPages(Math.ceil(tableTemp.length / resultsPerPage));
      setList(tableTemp);
      setLoading(false);
    };
    let leaguesRef = firestore.collection("leagues");
    if (filterByStatus !== "All")
      leaguesRef = leaguesRef.where("status", "==", filterByStatus);
    if (filterBySection !== "All")
      leaguesRef = leaguesRef.where("section", "==", filterBySection);
    if (filterByLocale !== "All")
      leaguesRef = leaguesRef.where("locale", "==", filterByLocale);
    if (filterByArea !== "All")
      leaguesRef = leaguesRef.where(
        "locations",
        "array-contains",
        filterByArea
      );
    queryLeagues(leaguesRef);
  }, [filterBySection, filterByLocale, filterByArea, filterByStatus]);
  const typeFilter = (l) => {
    if (filterByType === "All") return true;
    return filterByType === l.type;
  };
  const nameFilter = (l) => {
    if (leagueNameFilter === "") return true;
    return l.name.indexOf(leagueNameFilter) === 0;
  };
  const sortList = (property) => {
    setSortDirection((sortDirection) =>
      sortDirection === "asc" ? "desc" : "asc"
    );
    setSortSelected(() => property);
    let newList = orderby(list, sortSelected, sortDirection);
    setList(newList);
  };
  if (!loading)
    return (
      <div className="columnCenter">
        <div className="maxWidth">
          <Typography variant="h4">Leagues & Events</Typography>
        </div>
        <div className="margin10Top maxWidth">
          <LeagueSearchBar
            search={leagueNameFilter}
            setSearch={setLeagueNameFilter}
            filterByType={filterByType}
            setFilterByType={setFilterByType}
            user={user}
            sectionSelect={filterBySection}
            setSectionSelect={setFilterBySection}
            localeSelect={filterByLocale}
            setLocaleSelect={setFilterByLocale}
            areaSelect={filterByArea}
            setAreaSelect={setFilterByArea}
            zoneList={zoneList}
            statusSelect={filterByStatus}
            setStatusSelect={setFilterByStatus}
          />
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Edit</TableCell>
                <TableCell align="left" onClick={() => sortList("name")}>
                  <TableSortLabel
                    direction={sortDirection}
                    active={"name" === sortSelected}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" onClick={() => sortList("sportType")}>
                  <TableSortLabel
                    direction={sortDirection}
                    active={"sportType" === sortSelected}
                  >
                    Sports
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" onClick={() => sortList("type")}>
                  <TableSortLabel
                    direction={sortDirection}
                    active={"type" === sortSelected}
                  >
                    Type
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  onClick={() => sortList("director.firstName")}
                >
                  <TableSortLabel
                    direction={sortDirection}
                    active={"director.firstName" === sortSelected}
                  >
                    Director
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" onClick={() => sortList("status")}>
                  <TableSortLabel
                    direction={sortDirection}
                    active={"status" === sortSelected}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" onClick={() => sortList("location")}>
                  <TableSortLabel
                    direction={sortDirection}
                    active={"location" === sortSelected}
                  >
                    Location
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" onClick={() => sortList("date")}>
                  <TableSortLabel
                    direction={sortDirection}
                    active={"date" === sortSelected}
                  >
                    Date
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderby(
                list.filter((l) => nameFilter(l)).filter((l) => typeFilter(l)),
                sortSelected,
                sortDirection
              )
                .slice(pageStartIndex, pageEndIndex)
                .map((row, i) => (
                  <TableRow hover key={i}>
                    <TableCell align="left">
                      <Link
                        to={{
                          pathname: row.league.event
                            ? `/Admin/ManageEvent/${row.league.id}`
                            : `/Admin/ManageLeague/${row.league.id}`,
                        }}
                      >
                        <IconButton>
                          <Edit />
                        </IconButton>
                      </Link>
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell className="capitalize" align="left">
                      {row.sportType}
                    </TableCell>
                    <TableCell align="left">{row.type}</TableCell>
                    <TableCell align="left" className="rowCenter">
                      <Avatar className="image50" src={row.director.imageURL} />
                      <Typography className="padding10" variant="h5">
                        {row.director.firstName} {row.director.lastName}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">{row.status}</TableCell>
                    <TableCell align="left">{row.location}</TableCell>
                    <TableCell align="left">
                      {moment(row.date).format("M/D/YYYY")}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          className="margin10"
          count={numPages}
          onChange={(e, value) => {
            setPageNum(value);
          }}
        />
      </div>
    );
  return (
    <div className="pageWrapper">
      <Loader
        type="TailSpin"
        color="#02c39a"
        height={100}
        width={100}
        timeout={3000}
      />
    </div>
  );
};

export default AdminLeagueOverview;

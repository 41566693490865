import React, { useEffect, useState } from "react";
import {
  Avatar,
  Typography,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  Divider,
} from "@material-ui/core";
import parsePhoneNumber from "libphonenumber-js";
import DefaultDialog from "../../components/DefaultDialog";
import { DefaultSwitch } from "../../components/CustomSwitches";
import {
  DefaultTextField,
  DisplayTextField,
  MenuTextField,
  BirthdayTextField,
  PhoneTextField,
} from "../../components/CustomTextField";
import { storage, functions } from "../../firebase";
import { states } from "../../providers/States";
const AdminProfileEdit = ({
  openEdit,
  setOpenEdit,
  selectedPlayer,
  setSelectedPlayer,
  zoneList,
}) => {
  const [directorToggle, setDirectorToggle] = useState(false);
  const [adminToggle, setAdminToggle] = useState(false);
  const [suspendToggle, setSuspendToggle] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState("");
  const [birthday, setBirthday] = useState(null);
  const [playZipCode, setPlayZipCode] = useState("");
  const [image, setImage] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [verifyToggle, setVerifyToggle] = useState(false);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [ntrpRating, setNtrpRating] = useState("");
  const ntrpSelection = [
    { label: "1.5", value: 1.5 },
    { label: "2.0", value: 2.0 },
    { label: "2.5", value: 2.5 },
    { label: "3.0", value: 3.0 },
    { label: "3.5", value: 3.5 },
    { label: "4.0", value: 4.0 },
    { label: "4.5", value: 4.5 },
    { label: "5.0", value: 5.0 },
    { label: "5.5", value: 5.5 },
    { label: "6.0", value: 6.0 },
    { label: "6.5", value: 6.5 },
    { label: "7.0", value: 7.0 },
  ];
  useEffect(() => {
    if (selectedPlayer !== undefined) {
      setDirectorToggle(selectedPlayer.director);
      setAdminToggle(selectedPlayer.admin);
      setVerifyToggle(selectedPlayer.verified);
      setSuspendToggle(selectedPlayer.active);
      setFirstName(selectedPlayer.firstName);
      setLastName(selectedPlayer.lastName);
      setEmail(selectedPlayer.email);
      setPhoneNumber(
        parsePhoneNumber(selectedPlayer.phoneNumber, "US").formatNational()
      );
      setGender(selectedPlayer.gender);
      setBirthday(
        selectedPlayer.birthday !== "" ? selectedPlayer.birthday : null
      );
      setPlayZipCode(selectedPlayer.playLocation.zipCode);
      setImage(selectedPlayer.imageURL);
      setAddressLine1(selectedPlayer.homeAddress.addressLine1);
      setCity(selectedPlayer.homeAddress.city);
      setState(selectedPlayer.homeAddress.state);
      setZipCode(selectedPlayer.homeAddress.zipCode);
      setNtrpRating(selectedPlayer.ntrpRating);
    } else {
      setDirectorToggle(false);
      setAdminToggle(false);
      setSuspendToggle(false);
      setFirstName("");
      setLastName("");
      setPhoneNumber("");
      setEmail("");
      setGender("");
      setBirthday(null);
      setPlayZipCode("");
      setImage("");
      setAddressLine1("");
      setCity("");
      setState("");
      setZipCode("");
      setNtrpRating("");
    }
  }, [selectedPlayer]);
  const findPlayLocation = () => {
    let found = false;
    let playLocation = {};
    zoneList.forEach((location) => {
      if (location.zipCode === playZipCode) {
        playLocation = location;
        found = true;
      }
    });
    if (!found) {
      playLocation = zoneList.find((location) => location.areaID === "OT-0001");
      playLocation.zipCode = playZipCode;
    }
    if (playLocation.description === undefined) playLocation.description = "";
    return playLocation;
  };
  const handlePlayerUpdate = async (e) => {
    e.preventDefault();
    if (selectedPlayer.email !== email) {
      const adminEmailChange = functions.httpsCallable(
        "userCRUD-adminEmailChange"
      );
      await adminEmailChange({ id: selectedPlayer.id, email })
        .then()
        .catch((error) => console.log(error));
    }
    let fName = firstName.replaceAll(/[^A-Za-z]/g, "");
    let lName = lastName.replaceAll(/[^A-Za-z]/g, "");
    selectedPlayer.ref.update({
      admin: adminToggle,
      director: directorToggle,
      active: suspendToggle,
      firstName: fName,
      lastName: lName,
      fullName: fName.toLowerCase() + lName.toLowerCase(),
      verified: verifyToggle,
      phoneNumber: parsePhoneNumber(phoneNumber, "US").number,
      gender: gender,
      birthday: birthday,
      playLocation: findPlayLocation(),
      homeAddress: {
        city: city,
        state: state,
        addressLine1: addressLine1,
        zipCode: zipCode,
      },
      ntrpRating: ntrpRating,
    });
    setOpenEdit(false);
    setSelectedPlayer(undefined);
  };
  return (
    <DefaultDialog open={openEdit} setOpen={setOpenEdit}>
      {selectedPlayer !== undefined && (
        <div className="column">
          <div className="rowCenter">
            <div className="columnAlignCenter">
              <Avatar
                variant="rounded"
                src={image}
                className="image200Rounded"
              />
              <input
                type="file"
                hidden
                id="imageUpload"
                onChange={async (e) => {
                  let getURL = "";
                  await storage
                    .ref(`userDocuments/${selectedPlayer.id}/profilePhoto`)
                    .put(e.target.files[0]);
                  await storage
                    .ref()
                    .child(`userDocuments/${selectedPlayer.id}/profilePhoto`)
                    .getDownloadURL()
                    .then((url) => {
                      getURL = url;
                    });
                  setImage(getURL);
                  selectedPlayer.ref.update({
                    imageURL: getURL,
                  });
                }}
              />
              <label className="maxWidth" htmlFor="imageUpload">
                <Button component="span" variant="text">
                  <Typography className="profileHighlightFieldText">
                    Change Profile Photo
                  </Typography>
                </Button>
              </label>
            </div>
            <div className="column margin10Left">
              <Typography className="profileInfoCardTitle">
                {`${selectedPlayer.firstName} ${selectedPlayer.lastName}`}
              </Typography>
              <Typography className="profileInfoCardSubtitle">
                {`Rating: ${Math.round(selectedPlayer.gsmRating * 100)}`}
              </Typography>
            </div>
          </div>
          <Divider className="margin20Top margin20Bottom" />
          <Typography className="profileSubpageTitle">Privileges</Typography>
          <div className="rowCenter margin20Vertical">
            <div className="widthHalf rowCenter">
              <Typography className="textFieldHeader margin10Right">
                Account Active
              </Typography>
              <DefaultSwitch
                state={suspendToggle}
                setState={setSuspendToggle}
              />
            </div>
            <div className="widthHalf rowCenter">
              <Typography className="textFieldHeader margin10Right">
                Verified
              </Typography>
              <DefaultSwitch state={verifyToggle} setState={setVerifyToggle} />
            </div>
          </div>
          <div className="rowCenter margin20Vertical">
            <div className="widthHalf rowCenter">
              <Typography className="textFieldHeader margin10Right">
                Director
              </Typography>
              <DefaultSwitch
                state={directorToggle}
                setState={setDirectorToggle}
              />
            </div>
            <div className="widthHalf rowCenter">
              <Typography className="textFieldHeader margin10Right">
                Admin
              </Typography>
              <DefaultSwitch state={adminToggle} setState={setAdminToggle} />
            </div>
          </div>
          <Divider className="margin20Top margin20Bottom" />
          <Typography className="profileSubpageTitle margin20Bottom">
            General Information
          </Typography>
          <form className="column" onSubmit={handlePlayerUpdate}>
            <Typography className="profileRequiredFieldText">
              First Name
            </Typography>
            <DefaultTextField
              state={firstName}
              setState={setFirstName}
              placeholder="First Name"
              required={true}
            />
            <Typography className="profileRequiredFieldText">
              Last Name
            </Typography>
            <DefaultTextField
              state={lastName}
              setState={setLastName}
              placeholder="First Name"
              required={true}
            />
            <Typography className="profileRequiredFieldText">Email</Typography>
            <DefaultTextField
              state={email}
              setState={setEmail}
              placeholder="First Name"
              required={true}
            />
            <Typography className="profileRequiredFieldText">
              Phone Number
            </Typography>
            <PhoneTextField
              state={phoneNumber}
              setState={setPhoneNumber}
              placeholder="Phone Number"
              required={true}
            />
            <Typography className="profileOptionalFieldText margin10Top">
              Date of Birth
            </Typography>
            <BirthdayTextField state={birthday} setState={setBirthday} />
            <Typography className="profileOptionalFieldText margin10Top">
              Gender
            </Typography>
            <FormControl className="rowCenter">
              <RadioGroup
                className="rowCenter"
                value={gender}
                defaultValue="male"
                onChange={(e) => {
                  setGender(e.target.value);
                }}
              >
                <FormControlLabel
                  value="female"
                  control={<Radio color="default" />}
                  label="Female"
                />
                <div className="filler" />
                <FormControlLabel
                  value="male"
                  control={<Radio color="default" />}
                  label="Male"
                />
              </RadioGroup>
            </FormControl>
            <Typography className="profileOptionalFieldText margin10Top">
              NTRP Rating
            </Typography>
            <MenuTextField
              menuSelection={ntrpSelection}
              state={ntrpRating}
              setState={setNtrpRating}
              required={false}
              placeholder="3.5"
            />
            <Divider className="margin20Top margin20Bottom" />
            <Typography className="profileSubpageTitle margin10Bottom">
              Zone Designation
            </Typography>
            <Typography className="profileRequiredFieldText margin10Top">
              Zip Code Designation
            </Typography>
            <DefaultTextField
              state={playZipCode}
              setState={setPlayZipCode}
              required={true}
              placeholder="ZIP Code"
            />
            <Typography className="profileRequiredFieldText margin10Top">
              Section
            </Typography>
            <DisplayTextField value={selectedPlayer.playLocation.sectionals} />
            <Typography className="profileRequiredFieldText margin10Top">
              Locale
            </Typography>
            <DisplayTextField value={selectedPlayer.playLocation.locale} />
            <Typography className="profileRequiredFieldText margin10Top">
              Area
            </Typography>
            <DisplayTextField value={selectedPlayer.playLocation.area} />
            <Divider className="margin20Top margin20Bottom" />
            <Typography className="profileSubpageTitle margin20Bottom">
              Mailing Address
            </Typography>
            <Typography className="profileRequiredFieldText">
              Address Line 1
            </Typography>
            <DefaultTextField
              state={addressLine1}
              setState={setAddressLine1}
              required={false}
              placeholder="Address Line 1"
            />
            <Typography className="profileRequiredFieldText  margin10Top">
              City
            </Typography>
            <DefaultTextField
              state={city}
              setState={setCity}
              required={false}
              placeholder="City"
            />
            <Typography className="profileRequiredFieldText margin10Top">
              State
            </Typography>
            <MenuTextField
              menuSelection={states}
              state={state}
              setState={setState}
              required={false}
              placeholder="State"
            />
            <Typography className="profileRequiredFieldText margin10Top">
              Zip Code
            </Typography>
            <DefaultTextField
              state={zipCode}
              setState={setZipCode}
              required={false}
              placeholder="ZIP Code"
            />
            <Button
              className="profileSaveButton margin20Top"
              variant="outlined"
              type="sumbit"
            >
              <Typography className="profileSaveButtonText">
                Update User
              </Typography>
            </Button>
          </form>
        </div>
      )}
    </DefaultDialog>
  );
};

export default AdminProfileEdit;

import React, { useState, useEffect, Fragment } from "react";
import Typography from "@material-ui/core/Typography"
import Dialog from "@material-ui/core/Dialog"
import Slide from "@material-ui/core/Slide"
import DialogContent from "@material-ui/core/DialogContent"
import Avatar from "@material-ui/core/Avatar"
import Grid from "@material-ui/core/Grid"
import Divider from "@material-ui/core/Divider"
import TextField from "@material-ui/core/TextField"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import InputAdornment from "@material-ui/core/InputAdornment"
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LocationOn from "@material-ui/icons/LocationOn";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Lock from "@material-ui/icons/Lock";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import moment from "moment";
import { firestore, Firebase, functions, storage } from "../../../firebase";
import { noActionEmail } from "../../../functions/Messaging";
import Loader from "react-loader-spinner";
import { getCoupons } from "../../../functions/firebaseFunctions";
import DialogPDF from "../../../components/DialogPDF";
import { theme as appTheme } from "../../../Theme";
import stripeIcon from "../../../res/stripeIcon.png";
import acceptedPayments from "../../../res/acceptedPayments.jpg";
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
//NOTE: add empty string to geturl function and that way I can handle when theres no id
const EventPurchaseForm = ({
  open,
  setOpen,
  user,
  league,
  division,
  track,
  setDisplayPurchaseButton,
  setRedirect,
}) => {
  const [pageNum, setPageNum] = useState(1);
  const [loading, setLoading] = useState(true);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [cardErrorMessage, setCardErrorMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [totalPrice, setTotalPrice] = useState(league.price);
  const [quantity, setQuantity] = useState(1);
  const [couponCode, setCouponCode] = useState("");
  const [couponApplied, setCouponApplied] = useState("");
  const [coupons, setCoupons] = useState([]);
  const stripe = useStripe();
  const elements = useElements();
  const [loadingPurchase, setLoadingPurchase] = useState(false);
  const [purchaseSuccess, setPurchaseSuccess] = useState(false);
  const [termsPDF, setTermsPDF] = useState(null);
  useEffect(() => {
    const getData = async () => {
      const couponsTemp = await getCoupons();
      setCoupons(couponsTemp);
      setLoading(false);
    };
    storage
      .ref()
      .child(`adminDocuments/league/GSM Standard Rules and Regulations.pdf`)
      .getDownloadURL()
      .then((url) => {
        setTermsPDF(url);
      });
    setPurchaseSuccess(false);
    setErrMessage("");
    setCardErrorMessage("");
    setDiscountAmount(0);
    setCouponCode("");
    setCouponApplied("");
    setTotalPrice(league.price);
    setPageNum(1);
    setErrMessage("");
    getData();
  }, [league, division, track, open]);
  const sendMessage = async () => {
    await noActionEmail({
      subject: `Welcome to the event – ${league.name}`,
      message:
        "Thank you for joining the event. We are very excited to get you started.",
      message2: `The event will be starting on ${moment(
        league.startDate.toDate()
      ).format(
        "M/D/YYYY h:mm A"
      )}. We will notify you before the event starts. In the meantime, please review the Event Policies and Regulations if you have not done so already.`,
      message3: "We can’t wait for you to join us. --- Game.Set.Match",
      recipients: [{ email: user.email, name: user.firstName }],
    });
  };
  const purchaseLeague = async (e) => {
    e.preventDefault();
    //verify stripe is loaded and elements are present
    if (!stripe || !elements) {
      return;
    }
    setLoadingPurchase((prevLoading) => !prevLoading);
    const card = elements.getElement(CardElement);
    if (totalPrice === "0") {
      const leaguePurchaseSuccess = functions.httpsCallable(
        "leaguePurchase-success"
      );
      await firestore.collection("receipts").add({
        userID: user.uid,
        date: Firebase.firestore.FieldValue.serverTimestamp(),
        type: "eventPurchase",
        amount: 0,
        leagueID: league.id,
        divisionID: division.id,
        quantity: quantity,
        trackID: track.id,
        couponUsed: false,
        couponID: "",
      });
      await leaguePurchaseSuccess({
        leagueID: league.id,
        divisionID: division.id,
        trackID: track.id,
        couponID: "",
      });
      await sendMessage();
      setLoadingPurchase((prevLoading) => !prevLoading);
      setDisplayPurchaseButton(false);
      setPurchaseSuccess(true);
      setPageNum(2);
    } else {
      const leaguePurchaseRequest = functions.httpsCallable(
        "leaguePurchase-request"
      );
      await leaguePurchaseRequest({
        leagueID: league.id,
        couponCode: couponApplied,
      })
        .then(async (res) => {
          const clientSecret = res.data.client_secret;
          const couponUsedID = res.data.couponUsedID;
          const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: card,
              billing_details: user.uid,
            },
          });
          if (result.paymentIntent.status === "succeeded") {
            const leaguePurchaseSuccess = functions.httpsCallable(
              "leaguePurchase-success"
            );
            await firestore.collection("receipts").add({
              userID: user.uid,
              date: Firebase.firestore.FieldValue.serverTimestamp(),
              type: "eventPurchase",
              amount: result.paymentIntent.amount,
              leagueID: league.id,
              quantity: quantity,
              divisionID: division.id,
              trackID: track.id,
              couponUsed: couponUsedID !== "" ? true : false,
              couponID: couponUsedID,
            });
            await leaguePurchaseSuccess({
              leagueID: league.id,
              divisionID: division.id,
              trackID: track.id,
              couponID: couponUsedID,
            });
            await sendMessage();
            setLoadingPurchase((prevLoading) => !prevLoading);
            setDisplayPurchaseButton(false);
            setPurchaseSuccess(true);
            setPageNum(2);
          } else {
            setCardErrorMessage(
              "We received an error processing the card. Please try again or use another one"
            );
            setLoadingPurchase((prevLoading) => !prevLoading);
          }
        })
        .catch(() => {
          setLoadingPurchase((prevLoading) => !prevLoading);
          setCardErrorMessage(
            "We received an error processing the card. Please try again or use another one2"
          );
        });
    }
  };
  const handleClose = () => {
    setRedirect(purchaseSuccess);
    setOpen(false);
  };
  const handleCouponApplication = () => {
    let foundCoupon = coupons.find((c) => c.code === couponCode);
    if (couponCode !== "" && foundCoupon !== undefined) {
      if (user.couponsUsed.includes(couponCode) && foundCoupon.oneTimeUse) {
        setErrMessage("Code already used!");
      } else if (
        user.couponsUsed.some((coupon) => coupon.createdBy !== "admin") &&
        foundCoupon.createdBy !== "admin"
      ) {
        setErrMessage("Referral code already redeemed!");
      } else {
        const c = coupons.find((c) => c.code === couponCode);
        setErrMessage(
          `Success! A ${c.percentDiscount}% discount has been applied`
        );
        setDiscountAmount((league.price * quantity * c.percentDiscount) / 100);
        let newPrice = (league.price * quantity) - (league.price * quantity * c.percentDiscount) / 100;
        newPrice = Math.round((newPrice + Number.EPSILON) * 100) / 100;
        setTotalPrice(newPrice);
        setCouponApplied(couponCode);
      }
    } else {
      setErrMessage("Invalid code!");
    }
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: "#55B56A",
        light: "#EDF8F4",
        dark: "#028360",
      },
    },
    overrides: {
      MuiTextField: {
        root: {
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
        },
      },

      MuiDialogContent: {
        root: {},
      },
      MuiDialog: {
        paper: {
          borderRadius: "10px",
          backgroundColor: pageNum === 1 ? "#55B56A" : "#FFFFFF",
        },
      },
    },
    props: {
      MuiTextField: {
        variant: "outlined",
      },
      MuiButton: {
        variant: "outlined",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogContent style={{ padding: 0 }}>
          {loading ? (
            <div className="pageWrapper">
              <Loader
                type="TailSpin"
                color="#02c39a"
                height={100}
                width={100}
                timeout={3000}
              />
            </div>
          ) : (
            <Fragment>
              {pageNum === 1 && (
                <Grid container spacing={0} className="leaguePurchaseWrapper">
                  <Grid item xs={12} lg={5} className="leaguePurchaseSideBar">
                    <Avatar
                      variant="rounded"
                      src={league.imageURL}
                      className="image150Rounded"
                    />
                    <Typography className="leaguePurchaseLeagueName">
                      {league.name}
                    </Typography>
                    <div className="rowCenter margin20Top">
                      <LocationOn className="viewLeagueSubtitleIcon" />
                      <Typography className="viewLeagueSubtitleText">
                        {league.location}
                      </Typography>
                    </div>
                    <Grid className="margin10Top" container>
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography className="leaguePurchaseDateText">
                          Starts:
                        </Typography>
                        <Typography className="leaguePurchaseDateText">
                          {moment(league.startDate.toDate()).format("M/D/YYYY")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Typography className="leaguePurchaseDateText">
                          Ends:
                        </Typography>
                        <Typography className="leaguePurchaseDateText">
                          {moment(league.endDate.toDate()).format("M/D/YYYY")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider className="leaguePurchaseDivider margin10Vertical" />
                    <Typography className="leaguePurchaseDetailText">
                      Price
                    </Typography>
                    <div className="rowCenter">
                      <Typography className="leaguePurchasePriceText">
                        ${league.price} x {quantity} = ${league.price*quantity}
                      </Typography>
                      {couponApplied !== "" && (
                        <Typography className="leaguePurchasePriceText">
                          {`- $${discountAmount}`}
                        </Typography>
                      )}
                    </div>
                    <Typography className="leaguePurchaseDetailText">
                      Price with Discount
                    </Typography>
                    <Typography className="leaguePurchasePriceText">
                      ${totalPrice}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={7} className="leaguePurchaseCardInfo">
                    <Typography className="leaguePurchaseCardInfoTitle">
                      Credit/Debit Card Information
                    </Typography>
                    <Typography className="leaguePurchaseTextFieldLabel">
                      How many tickets do you want?
                    </Typography>
                    <TextField
                      placeholder="1"
                      type="number"
                      value={quantity}
                      onChange={(e) => {
                        setQuantity(e.target.value);
                      }}
                    />
                    <Typography className="leaguePurchaseTextFieldLabel">
                      Promo Code
                    </Typography>
                    <TextField
                      placeholder="GYZ42"
                      value={couponCode}
                      onChange={(e) => {
                        setCouponCode(e.target.value.toUpperCase());
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button
                              onClick={handleCouponApplication}
                              variant="text"
                            >
                              <Typography className="leaguePurchaseCouponButton">
                                Apply
                              </Typography>
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography className="leaguePurchaseDiscountText">
                      Click on "Apply” to get your discount!
                    </Typography>
                    <div className="leaguePurchaseSpaceHolder">
                      {errMessage !== "" && (
                        <Typography className="leaguePurchaseErrorText">
                          {errMessage}
                        </Typography>
                      )}
                    </div>

                    <Divider variant="fullWidth" />
                    <form
                      className="leaguePurchaseFormWrapper margin20Top"
                      onSubmit={(e) => purchaseLeague(e)}
                    >
                      <CardElement options={CARD_ELEMENT_OPTIONS} />
                      <div className="leaguePurchaseSpaceHolder">
                        {cardErrorMessage !== "" && (
                          <Typography className="leaguePurchaseErrorText">
                            {cardErrorMessage}
                          </Typography>
                        )}
                      </div>
                      <div className="rowCenter">
                        <div className="filler" />
                        <Lock className="subHeader margin10Right" />
                        <Typography className="finePrint">
                          Guaranteed <b>safe & secure</b> checkout with
                        </Typography>
                        <a href="https://stripe.com/">
                          <img
                            alt="Stripe"
                            className="leaguePurchaseStripeIcon"
                            src={stripeIcon}
                          />
                        </a>
                        <Typography className="subHeader">®</Typography>
                        <div className="filler" />
                      </div>
                      <Divider variant="fullWidth" />
                      <div className="columnCenter">
                        <img
                          alt="Accepted Payments"
                          className="leaguePurchaseAcceptedPaymentsImg"
                          src={acceptedPayments}
                        />
                      </div>
                      <div className="filler" />
                      <div className="leaguePurchaseTermsWrapper">
                        <Checkbox required />
                        <ThemeProvider theme={appTheme}>
                          <DialogPDF
                            pdf={termsPDF}
                            openText="I agree to the Event Rules & Regulations"
                          />
                        </ThemeProvider>
                      </div>
                      <Divider variant="fullWidth" />
                      <div className="columnCenter margin20Top">
                        {!loadingPurchase ? (
                          <div className="rowCenter">
                            <div className="filler" />
                            <Button variant="text" onClick={handleClose}>
                              <Typography className="leaguePurchaseCancelButtonText">
                                Back
                              </Typography>
                            </Button>
                            <Button
                              className="leaguePurchaseFormButton margin10Left"
                              type="submit"
                            >
                              <Typography className="leaguePurchaseFormButtonText">
                                {totalPrice === "0" ? "Register" : "Pay Now"}
                              </Typography>
                            </Button>
                          </div>
                        ) : (
                          <Loader
                            type="TailSpin"
                            color="#02c39a"
                            height={50}
                            width={50}
                            timeout={20000}
                          />
                        )}
                      </div>
                    </form>
                  </Grid>
                </Grid>
              )}
              {pageNum === 2 && (
                <div className="columnCenter padding20Vertical">
                  <CheckCircle className="leaguePurchaseSuccessIcon" />
                  <Typography className="leaguePurchaseSuccessText">
                    Your payment has been accepted. Thank you.
                  </Typography>
                  <div className="maxWidth margin20Vertical">
                    <Divider variant="fullWidth" />
                  </div>
                  <Button
                    className="leaguePurchaseFormButton"
                    onClick={handleClose}
                  >
                    <Typography className="leaguePurchaseFormButtonText">
                      Done
                    </Typography>
                  </Button>
                </div>
              )}
            </Fragment>
          )}
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};
export default EventPurchaseForm;

import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
const PageThree = ({
  reward,
  handleNextPage,
  unitsSelected,
  selectedAddress,
  totalCost,
  user,
}) => {
  return (
    <div className="columnMaxWidth" onSubmit={handleNextPage}>
      <Typography variant="h6" className="margin10Vertical">
        {reward.name}
      </Typography>
      <div className="columnCenter">
        <div className="rewardPreviewImageWrapper">
          <Avatar
            src={reward.imageURLS[0]}
            variant="rounded"
            className="image200"
          />
        </div>
      </div>
      <Typography className="rewardPurchaseItemDescription">
        Congratulations! You have successfully redeemed this reward. You will
        recieve a confirmation email shortly.
      </Typography>
      <Typography className="margin10Top" variant="h5">
        Your reward will be sent to:
      </Typography>
      <div className="padding10">
        <Typography className="rewardPurchaseItemDescription">{`${selectedAddress.firstName} ${selectedAddress.lastName}`}</Typography>
        <Typography className="rewardPurchaseItemDescription">{`${selectedAddress.addressLine1} ${selectedAddress.addressLine2}`}</Typography>
        <Typography className="rewardPurchaseItemDescription">{`${selectedAddress.city}, ${selectedAddress.state} ${selectedAddress.zipCode} US`}</Typography>
      </div>
      <div className="rowCenter">
        <div className="columnCenter filler">
          <div className="column">
            <Typography variant="h6">Quantity</Typography>
            <Typography variant="subtitle1">{unitsSelected}</Typography>
          </div>
        </div>
        <div className="columnCenter filler">
          <div className="column">
            <Typography variant="h6">Cost</Typography>
            <Typography variant="subtitle1">{totalCost}</Typography>
          </div>
        </div>
      </div>
      <div className="flex margin10Top">
        <Button className="rewardPurchaseButton" onClick={handleNextPage}>
          <Typography className="rewardPurchaseButtonText">Close</Typography>
        </Button>
      </div>
    </div>
  );
};
export default PageThree;

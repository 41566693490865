import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import UncertifiedLogin from '../../assets/UncertifiedLogin.jpg';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { makeStyles } from '@material-ui/core/styles';

const UncertifiedUser = () => {
  const useStyles = makeStyles((theme) => ({
    loginContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      marginTop: '40px',
      '@media (max-width: 1620px)': {
        width: '60%',
        paddingLeft: '10px',
      },
      '@media (max-width: 900px)': {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        marginTop: '20px',
      },
    },
    backgroundImage: {
      backgroundImage: `url(${UncertifiedLogin})`,
      background:"center",
      backgroundSize: 'cover',
      padding: '20px 20px 60px 20px',
    },
    accountBtn: {
      fontSize: '20px',
      borderRadius: '8px',
      backgroundColor: '#FFFFFF',
      float: 'right',
      transition: 'background-color 0.3s ease-in-out',
      '&:hover': {
        backgroundColor: '#cccccc',
    },
    },
    unauthenticatedPageText: {
      textAlign: 'left',
      font: 'normal normal 900 44px/53px Helvetica',
      letterSpacing: '0px',
      color: '#028360',
      opacity: 1,
      marginBottom: '10px',
    },
    description: {
      marginTop: '30px',
      color: '#FFFFFF',
    },
    btn: {
      margin: '10px',
      fontSize: '20px',
      letterSpacing: '0px',
      color: '#028360',
      opacity: 1,
      width: '50%',
      borderBottom: '1px solid',
      borderRadius: 0,
    },
    btnName: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
  }));

  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12} lg={12} className="pageRight">
          <div className="columnCenter">
            <div className={classes.loginContainer}>
              <div className={classes.backgroundImage}>
                <Button
                  className={classes.accountBtn}
                  variant="text"
                  onClick={() => {
                    const searchParams = new URLSearchParams();
                    searchParams.set('uncertified-user', true);
                    window.location.replace(
                      `/Landing?${searchParams.toString()}`
                    );
                  }}
                >
                  <Typography>{'Have an account?'}</Typography>
                </Button>
                <Typography
                  className={classes.unauthenticatedPageText}
                  style={{ paddingTop: '40px' }}
                >
                  {'Your League'}
                </Typography>
                <Typography className={classes.unauthenticatedPageText}>
                  {'Your Time'}
                </Typography>
                <Typography className={classes.unauthenticatedPageText}>
                  {'Your Money'}
                </Typography>
                <Typography className={classes.description}>
                  {'Start Monetizing your LOVE and PASSION for your sport.'}
                </Typography>
              </div>
              <div className="flex justify-between margin20Top">
                <Button
                  className={classes.btn}
                  variant="text"
                  onClick={() => {
                    const searchParams = new URLSearchParams();
                    searchParams.set('uncertified-user', true);
                    window.location.replace(
                      `/SignUp?${searchParams.toString()}`
                    );
                  }}
                >
                  <div className={classes.btnName}>
                    <Typography className="fontWeight700">
                      {'Sign Up to Start your Leagues'}
                    </Typography>
                    <ArrowRightAltIcon />
                  </div>
                </Button>
                <Button
                  className={classes.btn}
                  variant="text"
                  onClick={() => {
                    const searchParams = new URLSearchParams();
                    searchParams.set('uncertified-user', true);
                    window.location.replace(
                      `/Landing?${searchParams.toString()}`
                    );
                  }}
                >
                  <div className={classes.btnName}>
                    <Typography className="fontWeight700">
                      {'Log in'}
                    </Typography>
                    <ArrowRightAltIcon />
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default UncertifiedUser;

import React, { useState, useEffect } from "react";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import Image from "@material-ui/icons/Image";
import Info from "@material-ui/icons/Info";
import Loader from "react-loader-spinner";
import { getSiteValues } from "../../functions/firebaseFunctions";
import { Link } from "react-router-dom";
import { firestore } from "../../firebase";
import RewardHistoryDialog from "./RewardHistoryDialog";
import RewardPurchaseForm from "./RewardPurchaseForm";
const Rewards = ({ user, leagues, divisions, tracks, mobileSize }) => {
  const [activityTable, setActivityTable] = useState([]);
  const [lastEarned, setLastEarned] = useState(0);
  const [rewardsClaimedTable, setRewardsClaimedTable] = useState([]);
  const [rewards, setRewards] = useState([]);
  const [rewardSelected, setRewardSelected] = useState(null);
  const [openRewardPurchase, setOpenRewardPurchase] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const [pointValues, setPointValues] = useState(null);
  const [loading, setLoading] = useState(true);
  const [earnPoints, setEarnPoints] = useState([]);
  const [openRewardsTables, setOpenRewardsTables] = useState(false);
  useEffect(() => {
    //Retrieves User Activities
    const activitiesRef = firestore.collection("activities");
    const activitiesPlayerRef = activitiesRef.where(
      "players",
      "array-contains",
      user.uid
    );
    const unsubscribeActivities = activitiesPlayerRef.onSnapshot(
      async (activitiesSnapshot) => {
        let siteValues = await getSiteValues();
        let { activityValues } = siteValues;
        let activitiesListTemp = [];
        let pointTotal = 0;
        activitiesSnapshot.forEach((activityDoc) => {
          const activity = activityDoc.data();
          const date = activity.date.toDate();
          const displayDate = moment(date).format("M/D/YYYY");
          const category = activity.category;
          const players = activity.players;
          if (
            ![
              "leagueComplete",
              "eventApproved",
              "directorMessage",
              "eventComplete",
              "leagueStart",
              "playoffsStart",
              "eventStart",
            ].some((check) => check === activity.category)
          ) {
            let points = activityValues[category];
            let description = "";
            if (category === "userJoined") {
              description = "Joined GameSetMatch!";
            } else if (
              [
                "profileComplete",
                "profilePhoto",
                "friendReferred",
                "leagueApproved",
                "eventApproved",
                "eventComplete",
                "userJoinedLeague",
                "userJoinedEvent",
              ].some((cat) => category === cat)
            ) {
              description = activity.description;
            } else {
              const playerIndex = players.findIndex(
                (playerID) => playerID === user.uid
              );
              const isStandardMatch = category === "matchPlayed";
              if (playerIndex < players.length / 2) {
                description = isStandardMatch
                  ? "Played a match and won!"
                  : "Played a finals match and won!";
                points =
                  activityValues[
                    isStandardMatch ? "matchPointWin" : "matchPointChampion"
                  ];
              } else {
                description = isStandardMatch
                  ? "Played a match and lost!"
                  : "Played a finals match and lost!";
                points =
                  activityValues[
                    isStandardMatch ? "matchPointLoss" : "matchPointFinalist"
                  ];
              }
            }
            pointTotal += points;
            activitiesListTemp.push({
              cells: [
                { content: displayDate, editable: false },
                { content: description, editable: false },
                { content: points, editable: false },
              ],
              date,
              points,
            });
          }
        });
        activitiesListTemp.sort((a, b) => b.date - a.date);
        if (activitiesListTemp.length > 0) {
          setLastEarned(activitiesListTemp[0].points);
        }
        setActivityTable(activitiesListTemp);
        setTotalPoints(pointTotal - user.pointsUsed);
      }
    );
    //Retrieves Rewards Claimed
    const rewardsClaimedRef = firestore
      .collection("rewardClaims")
      .where("userID", "==", user.uid);
    const unsubscribeRewardsClaimed = rewardsClaimedRef.onSnapshot(
      (rewardsClaimedSnap) => {
        let rewardsClaimedTableTemp = [];
        rewardsClaimedSnap.forEach((rewardClaimedDoc) => {
          const ref = rewardClaimedDoc.ref;
          const id = rewardClaimedDoc.id;
          const data = rewardClaimedDoc.data();
          const date = data.date ? data.date.toDate() : new Date();
          const displayDate = moment(date).format("M/D/YYYY");
          rewardsClaimedTableTemp.push({
            ref: ref,
            id: id,
            ...data,
            date: date,
            cells: [
              { content: displayDate, editable: false },
              { content: data.item, editable: false },
              { content: data.units, editable: false },
              { content: data.points, editable: false },
              { content: data.status, editable: false },
            ],
          });
        });
        setRewardsClaimedTable(rewardsClaimedTableTemp);
      }
    );
    //Retrieves Reward Items
    const date = new Date();
    const rewardsRef = firestore
      .collection("rewards")
      .where("endDate", ">=", date);
    const unsubscribeRewards = rewardsRef.onSnapshot((rewardsSnap) => {
      let rewardsTemp = [];
      rewardsSnap.forEach((rewardDoc) => {
        const ref = rewardDoc.ref;
        const id = rewardDoc.id;
        rewardsTemp.push({ ...rewardDoc.data(), ref, id });
      });
      setRewards(rewardsTemp);
    });
    //Retrieves methods of earning points
    const pointMethodsRef = firestore.collection("admin").doc("earnPoints");
    const unsubscribePointMethods = pointMethodsRef.onSnapshot(
      (pointMethodSnap) => {
        const methods = pointMethodSnap.data().pointMethods;
        setEarnPoints(methods);
      }
    );
    //Retrieves point values for activities
    const siteValuesRef = firestore.collection("admin").doc("siteValues");
    const unsubscribeSiteValues = siteValuesRef.onSnapshot((valuesSnap) => {
      const { activityValues } = valuesSnap.data();
      setPointValues(activityValues);
      setLoading(false);
    });
    return () => {
      unsubscribeActivities();
      unsubscribeRewardsClaimed();
      unsubscribeRewards();
      unsubscribePointMethods();
      unsubscribeSiteValues();
    };
  }, [user, leagues, divisions, tracks]);
  const handleRewardSelect = (reward) => {
    setRewardSelected(reward);
    setOpenRewardPurchase(true);
  };
  const handleDialogOpen = () => {
    setOpenRewardsTables(true);
  };
  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  return (
    <Grid container spacing={mobileSize ? 3 : 6}>
      <Grid item xs={12} md={6} lg={4} xl={4}>
        <Typography className="rewardsPointsHeader">MatchPoints</Typography>
        <RewardHistoryDialog
          open={openRewardsTables}
          setOpen={setOpenRewardsTables}
          pointsEarned={activityTable}
          pointsRedeemed={rewardsClaimedTable}
          setPointsEarned={setActivityTable}
          setPointsRedeemed={setRewardsClaimedTable}
          user={user}
        />
        <div
          onClick={handleDialogOpen}
          style={{ cursor: "pointer" }}
          className="columnCenter rewardsPointsCard margin10Top"
        >
          <div className="rewardsPointsTotalWrapper columnCenter">
            <Typography className="rewardsPointsTotalText">
              {totalPoints}
            </Typography>
            <Typography className="rewardsPointsTotalSubheaderText">
              Total Current MatchPoints
            </Typography>
          </div>
          <Typography className="rewardsPointsInfoText">
            Last points earned:
          </Typography>
          <Typography className="rewardsHighlightText">
            +{lastEarned} MatchPoints
          </Typography>
          <Typography className="rewardsPointsInfoLink">
            Click here for MatchPoints Activity Details
          </Typography>
        </div>
      </Grid>
      <Grid xs={12} md={6} lg={8} xl={8} item>
        <Typography className="rewardsPointsHeader">
          Start Earning More Points
        </Typography>
        <Grid
          container
          spacing={1}
          className="rowCenter rewardsEarnPointsWrapper margin10Top"
        >
          {earnPoints.map((method, i) => (
            <Grid key={i} item xs={12} lg={4}>
              <Link
                className="rewardsEarnPointsLinkWrapper margin10"
                to={method.url}
              >
                <Card className="rewardsEarnMorePointsCard columnCenter">
                  {method.activityCategory === "friendReferred" && (
                    <SupervisorAccount className="rewardsMethodIcon" />
                  )}
                  {method.activityCategory === "profileComplete" && (
                    <Info className="rewardsMethodIcon" />
                  )}
                  {method.activityCategory === "profilePhoto" && (
                    <Image className="rewardsMethodIcon" />
                  )}
                  <Typography className="rewardsBoldCardText">
                    {method.name}
                  </Typography>
                  <Typography className="rewardsMorePointsDescription">
                    {method.description}
                  </Typography>
                  <Typography className="rewardsHighlightText">
                    +{pointValues[method.activityCategory]} MatchPoints
                  </Typography>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid xs={12} item>
        <Typography className="rewardsPointsHeader">Rewards</Typography>
        <div className="margin10Top" />
        {rewardSelected !== null && (
          <RewardPurchaseForm
            open={openRewardPurchase}
            setOpen={setOpenRewardPurchase}
            reward={rewardSelected}
            availablePoints={totalPoints}
            user={user}
          />
        )}
        <Grid container spacing={mobileSize ? 3 : 6}>
          {rewards
            .sort((a, b) => b.points - a.points)
            .map((reward, key) => (
              <Grid key={key} xs={12} md={4} lg={3} item>
                <Card
                  className="rowStretch padding10 rewardsCardItem"
                  onClick={() => handleRewardSelect(reward)}
                >
                  <Avatar
                    variant="rounded"
                    className="image200"
                    src={reward.imageURLS[0]}
                  />
                  <div className="column margin10Left">
                    <Typography className="rewardsBoldCardText margin10Bottom">
                      {reward.name}
                    </Typography>
                    {reward.units === 0 ? (
                      <Typography className="rewardsHighlightText">
                        SOLD OUT
                      </Typography>
                    ) : (
                      <Typography className="rewardsHighlightText">
                        Units Left: {reward.units}
                      </Typography>
                    )}
                    <div className="filler" />
                    <Typography className="rewardsItemPointsText">
                      {reward.points} GSMPoints
                    </Typography>
                  </div>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Rewards;

import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import DialogPDF from "../../../components/DialogPDF";
import Divider from "@material-ui/core/Divider";
import {
  DefaultTextField,
} from "../../../components/CustomTextField";
const PageTwo = ({
  signature,
  setSignature,
  agreementURLS,
}) => {
  return (
    <Fragment>
      <Typography className="createLeagueFieldText">
        Review and Sign Application
      </Typography>
      <Typography className="h3 margin20Bottom">
        Take a few minutes to review all documents below carefully.
      </Typography>
      {agreementURLS.map((item, key) =>
        <div key={key}>
          <Divider className="margin20Bottom" />
          <div className=" margin20Right">
            <DialogPDF pdf={item.url} openText={item.label} openNewWindow={true} />
          </div>
        </div>
      )}
      <Divider className="margin20Top margin20Bottom" />
      <Typography className="h5 margin20Bottom">
        Note: Please review the documents above before signing your application. By signing your application and submitting it, you acknowledge that you have read and understood the documents above.
      </Typography>
      <Typography className="createLeagueFieldText margin20Bottom">
        Signature
      </Typography>
      <Typography className="h3 margin20Bottom">
        Type in your Full Name below to indicate that you have reviewed and acknowledged the roles and responsibilities of a League Director.      </Typography>
      <DefaultTextField
        required={true}
        state={signature}
        setState={setSignature}
        placeholder="type your legal name"
      />
      <Typography className="h3 margin20Top">
        This form is used to gather information about you. Once submitted, we will review the form and start the application process. Once approved, someone will contact you to finalize the process. In the meantime, if you have any questions, please contact admin@playgamesetmatch.com
      </Typography>
    </Fragment>
  );
};
export default PageTwo;

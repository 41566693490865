import React from 'react';
import Button from "@material-ui/core/Button";
import styled from "@material-ui/core/styles/styled";
import Grid from "@material-ui/core/Grid";
import ListItemLeague from "../leagues/ListItemLeague";
import DefaultDialog from "../../components/DefaultDialog";

const CustomButton = styled(Button)(() => ({
    color: '#fff',
    backgroundColor: '#54b56a',
    fontWeight: 'bold',
    '&:hover': {
        color: "black"
    }
}));
const ActivityLeaguesPopup=({children,leagues })=>{
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>
            <CustomButton onClick={handleClickOpen}>
                {children}
            </CustomButton>
            <DefaultDialog open={open} setOpen={handleClose} size={leagues.length === 1?"sm":leagues.length > 2? "lg":"md"}>
                <Grid className="columnCenter" item xs={12}>
                    <Grid container className="rowCenter" spacing={4} style={{justifyContent: 'center', margin: '0'}}>
                        {leagues && leagues.map((combinedLeague, i) => (
                                <ListItemLeague
                                    key={i}
                                    lg={leagues.length === 1 ? 6 : leagues.length > 2 ? 3 : 4}
                                    isUpcoming
                                    combinedLeague={combinedLeague}
                                />
                            )
                        )}
                    </Grid>
                </Grid>
            </DefaultDialog>
        </>
    )
}
export default ActivityLeaguesPopup;

import React, { useState, useEffect, Fragment } from "react";
import Create from "@material-ui/icons/Create";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Loader from "react-loader-spinner";
import moment from "moment";
import DefaultDialog from "../../../components/DefaultDialog";
import { CustomTabs } from "../../../components/CustomTabs";
import EditScoreDoc from "./EditScoreDoc";
import CreateScoreForPlayers from "./CreateScoreForPlayers";
import TableComponent from "../../../components/table/TableComponent";
import PickleballEditScoreDoc from "./PickleballEditScoreDoc";
import PickleballCreateScoreForPlayers from "./PickleballCreateScoreForPlayers";
import {divisionData} from "../../../components/Common";

const header = [
  { label: "date", content: "Date", sortable: true },
  { label: "winnerName", content: "Winner", sortable: true },
  { label: "wSetOne", content: "Set One", sortable: true },
  { label: "wSetTwo", content: "Set Two", sortable: true },
  { label: "wSetThree", content: "Set Three", sortable: true },
  { label: "loserName", content: "Loser", sortable: true },
  { label: "lSetOne", content: "Set One", sortable: true },
  { label: "lSetTwo", content: "Set Two", sortable: true },
  { label: "lSetThree", content: "Set Three", sortable: true },
  { label: "retired", content: "Retired", sortable: true },
  { label: "noshow", content: "No Show", sortable: true },
  { label: "scoreType", content: "Score Type", sortable: true },
];

const DirectorLeagueMatchesReported = ({ status, pageLabels, tracks, isPublic=false }) => {
  const [pageNum, setPageNum] = useState(0);
  const [matchScores, setMatchScores] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState(undefined);
  const [selectedTrack, setSelectedTrack] = useState(undefined);
  const [loading, setLoading] = useState(true);
 
  const createScoreTable = (
    cells,
    score,
    date,
    winner,
    winnerName,
    wSetOne,
    wSetTwo,
    wSetThree,
    loser,
    loserName,
    lSetOne,
    lSetTwo,
    lSetThree,
    retired,
    noshow,
    division,
    scoreType
  ) => {
    return {
      cells,
      score,
      date,
      winner,
      winnerName,
      wSetOne,
      wSetTwo,
      wSetThree,
      loser,
      loserName,
      lSetOne,
      lSetTwo,
      lSetThree,
      retired,
      noshow,
      division,
      scoreType,
    };
  };

  useEffect(() => {
    const getParticipants = (track, score) => {
      const isModerateSingle = divisionData.includes(track.division.division);
      let winner = "";
      let loser = "";
      if (track.division.doublesFormat) {
        winner = track.teams.find((team) => team.id === score.winner);
        loser = track.teams.find((team) => team.id === score.loser);
        return {
          winner: winner,
          winnerName: winner.teamName,
          loser: loser,
          loserName: loser.teamName,
        };
      }

      if (track.league.format === "Moderated-dropin" && !isModerateSingle) {
        winner = track.teams.find((team) => team.id === score.winner);
        loser = track.teams.find((team) => team.id === score.loser);
        return {
          winner: winner,
          winnerName: winner.teamName,
          loser: loser,
          loserName: loser.teamName,
        };
      }
      winner = track?.players.find((player) => player.id === score.winner);
      loser = track?.players.find((player) => player.id === score.loser);
      return {
        winner: winner,
        winnerName: `${winner?.firstName} ${winner?.lastName}`,
        loser: loser,
        loserName: `${loser?.firstName} ${loser?.lastName}`,
      };
    };

    setMatchScores(
      tracks.map((track) => {
        return track?.scores?.map((score) => {
          const division = track.division;
          const result = getParticipants(track, score);
          const dateParsed = score.matchDate.split("/");
          const date = new Date(
            dateParsed[2],
            dateParsed[0] - 1,
            dateParsed[1]
          );
          const dispDate = moment(date).format("M/D/YYYY");
          let wSetOne = 0;
          let wSetTwo = 0;
          let wSetThree = 0;
          let lSetOne = 0;
          let lSetTwo = 0;
          let lSetThree = 0;
          if (
            score.scoringFormat === "10GamePro" ||
            score.scoringFormat === "1Gameto15or21points"
          ) {
            wSetOne = score.proSetScore[0];
            lSetOne = score.proSetScore[1];
          } else {
            wSetOne = score.setOneScore[0];
            wSetTwo = score.setTwoScore[0];
            wSetThree = score.setThreeScore[0];
            lSetOne = score.setOneScore[1];
            lSetTwo = score.setTwoScore[1];
            lSetThree = score.setThreeScore[1];
          }
          const retired = score.retired ? "True" : "False";
          const noShow = score.noShow ? "True" : "False";
          return createScoreTable(
            [
              { content: dispDate, editable: false },
              { content: result.winnerName, editable: false },
              {
                content: wSetOne,
                editable: false,
              },
              {
                content: wSetTwo,
                editable: false,
              },
              { content: wSetThree, editable: false },
              { content: result.loserName, editable: false },
              {
                content: lSetOne,
                editable: false,
              },
              {
                content: lSetTwo,
                editable: false,
              },
              { content: lSetThree, editable: false },
              { content: retired, editable: false },
              { content: noShow, editable: false },
              { content: score.scoringFormat, editable: false },
            ],
            score,
            date,
            result.winner,
            result.winnerName,
            wSetOne,
            wSetTwo,
            wSetThree,
            result.loser,
            result.loserName,
            lSetOne,
            lSetTwo,
            lSetThree,
            retired,
            noShow,
            division,
            score.scoringFormat
          );
        });
      })
    );
    setLoading(false);
  }, [tracks]);
  const handleComponentEdit = (rowSelected) => {
    setSelectedMatch(rowSelected.score);
    setOpenEdit(true);
  };
  const handleCreateScore = (trackSelected) => {
    setSelectedTrack(trackSelected);
    setOpenCreate(true);
  };
  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  return (
    <div className="column filler">
      <DefaultDialog open={openEdit} setOpen={setOpenEdit}>
        {selectedMatch !== undefined &&
          (tracks[pageNum].league.sportType === "pickleball" ? (
            <PickleballEditScoreDoc
              score={{
                ...selectedMatch,
                leagueFormat: tracks[pageNum].league.format,
              }}
              participants={
                tracks[pageNum].league.format === "Moderated-dropin" &&
                !divisionData.includes(tracks[pageNum].division.division)
                  ? tracks[pageNum].teams
                  : tracks[pageNum].division.doublesFormat
                  ? tracks[pageNum].teams
                  : tracks[pageNum].players
              }
              setOpen={setOpenEdit}
            />
          ) : (
            <EditScoreDoc
              score={selectedMatch}
              participants={
                tracks[pageNum].league.format === "Moderated-dropin" &&
                !divisionData.includes(tracks[pageNum].division.division)
                  ? tracks[pageNum].teams
                  : tracks[pageNum].division.doublesFormat
                  ? tracks[pageNum].teams
                  : tracks[pageNum].players
              }
              setOpen={setOpenEdit}
            />
          ))}
      </DefaultDialog>
      <DefaultDialog open={openCreate} setOpen={setOpenCreate}>
        {selectedTrack !== undefined &&
          (tracks[pageNum].league.sportType === "pickleball" ? (
            <PickleballCreateScoreForPlayers
              selectedTrack={selectedTrack}
              setOpen={setOpenCreate}
            />
          ) : (
            <CreateScoreForPlayers
              selectedTrack={selectedTrack}
              setOpen={setOpenCreate}
            />
          ))}
      </DefaultDialog>
      <CustomTabs labels={pageLabels} value={pageNum} setValue={setPageNum} />
      <div className="column filler">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {matchScores.map((scores, key) => (
              <Fragment key={key}>
                {key === pageNum && (
                  <>
                    <TableComponent
                      list={scores || []}
                      nestedList={matchScores}
                      setList={setMatchScores}
                      tableHeader={header}
                      handleComponentEdit={handleComponentEdit}
                      type={!isPublic ? "componentEdit" : 'viewOnly'}
                      rowsPerPageOverride={10}
                      tableNum={pageNum}
                    />
                    <div className="rowCenter maxWidth">
                      <div className="filler" />
                      {tracks?.[0]?.league?.format !== "Moderated-dropin" && !isPublic && (
                        <Button
                          disabled={
                            status !== "active" && status !== "playoffs"
                          }
                          className="margin10Right margin10Top"
                          onClick={() => handleCreateScore(tracks[key])}
                          variant="text"
                        >
                          <div className="flexCenter">
                            <Create className="image30 appBarUploadScoreButtonIcon" />

                            <Typography className="appBarUploadScoreButtonText">
                              Report on Behalf of Player
                            </Typography>
                          </div>
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </Fragment>
            ))}
          </Grid>
        </Grid>
        <div className="filler" />
      </div>
    </div>
  );
};
export default DirectorLeagueMatchesReported;

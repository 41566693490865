const createZoneFilterList = (
  zoneList,
  selectedSection,
  selectedLocale,
  setSectionList,
  setLocaleList,
  setAreaList
) => {
  let uniqueSections = [];
  let zoneListTemp = [...zoneList];
  zoneListTemp.forEach((location) => {
    let sectional = location.sectionals;
    if (!uniqueSections.some((val) => val.value === sectional))
      uniqueSections.push({ label: sectional, value: sectional });
  });
  setSectionList([{ label: "All", value: "All" }, ...uniqueSections]);
  zoneListTemp = zoneListTemp.filter(
    (location) => location.sectionals === selectedSection
  );
  let uniqueLocales = [];
  zoneListTemp.forEach((location) => {
    let locale = location.locale;
    if (!uniqueLocales.some((val) => val.value === locale))
      uniqueLocales.push({ label: locale, value: locale });
  });
  setLocaleList([{ label: "All", value: "All" }, ...uniqueLocales]);
  if (selectedLocale !== "")
    zoneListTemp = zoneList.filter(
      (location) => location.locale === selectedLocale
    );
  let uniqueAreas = [];
  zoneListTemp.forEach((location) => {
    let area = location.area;
    let areaID = location.areaID;
    if (!uniqueAreas.some((val) => val.value === areaID))
      uniqueAreas.push({ label: area, value: areaID });
  });
  setAreaList([{ label: "All", value: "All" }, ...uniqueAreas]);
};

export default createZoneFilterList;

import React, { useState, useEffect, Fragment } from "react";
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import MenuItem from '@material-ui/core/MenuItem';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import moment from "moment";
import Loader from "react-loader-spinner";
import { firestore } from "../../firebase";
import { noActionEmail } from "../../functions/Messaging";
import {
  getMatchSchedulesByParticipant,
  getMatchSchedulesByParticipants,
  getUsersByID,
  getDoublesTeamsByID,
  getScoresByParticipantTrackAndDate,
  getLeagueById,
  getDivisionById,
  getTrackById,
} from "../../functions/firebaseFunctions";
import { MenuTextField, DateTextField } from "../../components/CustomTextField";
import {divisionData} from "../../components/Common";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const surfaceList = [
  { label: "Clay", value: "Clay" },
  { label: "Grass", value: "Grass" },
  { label: "Hard", value: "Hard" },
  { label: "Carpet", value: "Carpet" },
];

const ScoreUploadForm = ({
  open,
  setOpen,
  user,
  leagues,
  divisions,
  tracks,
  setColumns,
  columns,
  selectColumnForScore,
  userDoublesTeams,
  isFromEventModeration,
  selectedTeamsForScore,
  isFromPlayoff,
  leagueID,
  divisionId,
  trackId,
  updateNumOfPlayedMatch,
  matchScheduleId,
}) => {
  const [matchSchedules, setMatchSchedules] = useState([]);
  const [leagueSelect, setLeagueSelect] = useState([]);
  const [league, setLeague] = useState("placeholder");
  const [userTeam, setUserTeam] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [playUser, setPlayUser] = useState(user);
  const isDirectorEnterScore = isFromEventModeration || isFromPlayoff;
  const [enableResultSelection, setEnableResultSelection] = useState(!!isDirectorEnterScore);
  const [enableOpponentSelection, setEnableOpponentSelection] = useState(!!isDirectorEnterScore);
  const [matchDate, setMatchDate] = useState(moment().format("MM/DD/YYYY"));
  const [matchDateObj, setMatchDateObj] = useState(moment().toDate());
  const [surfaceType, setSurfaceType] = useState("Clay");
  const [opponentSelect, setOpponentSelect] = useState([
    { label: "Select Opponent", value: "placeholder" },
  ]);
  const [playerSelect, setPlayerSelect] = useState([
    { label: "Select Team", value: "placeholder" },
  ]);
  const [opponent, setOpponent] = useState("placeholder");
  const [player, setPlayer] = useState("placeholder");
  const [result, setResult] = useState("I Won");
  const [winnerID, setWinnerID] = useState("");
  const [loserID, setLoserID] = useState("");
  const winnerSelect = [
    { label: `${!isDirectorEnterScore ? "I" : ""} Won`, value: "I Won" },
    { label: `${!isDirectorEnterScore ? "I" : ""} Lost`, value: "I Lost" },
    {
      label: `${!isDirectorEnterScore ? "I" : ""} Retired`,
      value: "I Retired",
    },
    { label: `Opponent Retired`, value: "Opponent Retired" },
    {
      label: `No-Show (${!isDirectorEnterScore ? "I" : ""} Win)`,
      value: "No-Show (I Win)",
    },
    { label: "Walkover (Opponent Wins)", value: "Walkover (Opponent Wins)" },
    { label: "Match Cancelled", value: "Match Cancelled" },
  ];
  const [scoringFormat, setScoringFormat] = useState(
    isDirectorEnterScore ? "10GamePro" : "2/3Sets"
  );
  const [setOneScore1, setSetOneScore1] = useState("");
  const [setOneScore2, setSetOneScore2] = useState("");
  const [setTwoScore1, setSetTwoScore1] = useState("");
  const [setTwoScore2, setSetTwoScore2] = useState("");
  const [tieBreaker1, setTieBreaker1] = useState("0");
  const [tieBreaker2, setTieBreaker2] = useState("0");
  const [tenGameScore1, setTenGameScore1] = useState("");
  const [tenGameScore2, setTenGameScore2] = useState("");
  const [review, setReview] = useState("");
  const [privateReview, setPrivateReview] = useState("");
  const [retired, setRetired] = useState(false);
  const [noShow, setNoShow] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [cancelledReason, setCancelledReason] = useState("");
  const [error, setError] = useState({ visible: false, message: "" });
  const [winnerValuePlaceholder, setWinnerValuePlaceholder] =
    useState("0 (Your Score)");
  const [loserValuePlaceholder, setLoserValuePlaceholder] =
    useState("0 (Opponent Score)");
  const [isModeratorSingle, setIsModeratorSingle] = useState(false);

  const handleClose = () => {
    setLeague("placeholder");
    setUserTeam(null);
    setMatchSchedules([]);
    setLeagueSelect([]);
    setMatchDate(moment().format("MM/DD/YYYY"));
    setMatchDateObj(moment());
    setSurfaceType("Clay");
    setOpponentSelect([{ label: "Select Opponent", value: "placeholder" }]);
    setPlayerSelect([{ label: "Select Team", value: "placeholder" }]);
    setPlayer("placeholder");
    setOpponent("placeholder");
    setResult("I Won");
    setWinnerID("");
    setLoserID("");
    setWinnerValuePlaceholder("0 (Your Score)");
    setLoserValuePlaceholder("0 (Opponent Score)");
    setScoringFormat("2/3Sets");
    setSetOneScore1("");
    setSetOneScore2("");
    setSetTwoScore1("");
    setSetTwoScore2("");
    setTieBreaker1("0");
    setTieBreaker2("0");
    setTenGameScore1("");
    setTenGameScore2("");
    setReview("");
    setPrivateReview("");
    setEnableResultSelection(false);
    setEnableOpponentSelection(false);
    setRetired(false);
    setNoShow(false);
    setCancelled(false);
    setCancelledReason("");
    setError({ visible: false, message: "" });
    setOpen(false);
    setSubmitting(false);
  };

  const getInfo = async () => {
    let leaguesSelection = [{ label: "Select League", value: "placeholder" }];

    if (isDirectorEnterScore) {
      let isModSingle = false;
      let foundLeague = await getLeagueById(leagueID);
      let foundDivision = await getDivisionById(leagueID, divisionId);
      let foundTrack = await getTrackById(leagueID, divisionId, trackId);

      if (foundDivision) {
        isModSingle = divisionData.includes(foundDivision.division);
        setIsModeratorSingle(divisionData.includes(foundDivision.division));
      }
      let combinedLeague = {
        ...foundLeague,
        ...foundDivision,
        ...foundTrack,
        trackID: foundTrack.trackID,
      };

      let selected = {
        label: `${combinedLeague.name} ${combinedLeague.division}- Level ${combinedLeague.level}`,
        value: combinedLeague,
      };
      leaguesSelection.push(selected);
      selected.value.doublesFormat = !isModSingle;
      setLeague(selected.value);
      const doublesTeamsIds =
          isFromEventModeration && !isModSingle
              ? columns[selectColumnForScore].teamID
              : selectedTeamsForScore;

      const playersIds = isFromPlayoff
          ? doublesTeamsIds
          : columns[selectColumnForScore].items.map((i) => i.id);
      let opponentTeams = isModSingle
          ? await getUsersByID(playersIds)
          : await getDoublesTeamsByID(doublesTeamsIds);

      let Teams = [
        ...opponentTeams.map((team) => {
          return {
            label: isModSingle ? team.name : team.teamName,
            value: team,
          };
        }),
      ];
      if (isFromPlayoff) {
        setPlayUser(Teams[0].value);
      } else {
        setPlayUser(user);
      }

      setPlayerSelect([Teams[0]]);
      setUserTeam(Teams[0].value);
      setOpponentSelect([Teams[1]]);
      setPlayer(Teams[0].value);
      setOpponent(Teams[1].value);
      setWinnerValuePlaceholder(
          `0 (${
              isModSingle ? Teams[0].value.name : Teams[0].value.teamName
          }'s Score)`
      );
      setLoserValuePlaceholder(
          `0 (${
              isModSingle ? Teams[1].value.name : Teams[1].value.teamName
          }'s Score)`
      );
      setWinnerID(Teams[0].value.id);
      setLoserID(Teams[1].value.id);
    } else {
      playUser.leaguesJoined.forEach((lJ) => {
        if (!Object.keys(tracks).some((trackID) => lJ.trackID === trackID))
          return;
        let foundLeague = leagues[lJ.leagueID];
        let foundDivision = divisions[lJ.divisionID];
        let foundTrack = tracks[lJ.trackID];
        let combinedLeague = {
          ...foundLeague,
          ...foundDivision,
          ...foundTrack,
          trackID: foundTrack.id,
        };
        if (combinedLeague.format !== "Moderated-dropin") {
          if (
              combinedLeague.status === "active" &&
              !combinedLeague.event &&
              !lJ.withdrawn
          ) {
            leaguesSelection.push({
              label: `${combinedLeague.name} ${combinedLeague.division}- Level ${combinedLeague.level}`,
              value: combinedLeague,
            });
          } else if (
              combinedLeague.status === "playoffs" &&
              combinedLeague.playoffsGenerated &&
              !lJ.withdrawn
          ) {
            if (
                combinedLeague.doublesFormat &&
                combinedLeague.playoffsDoublesTeams.some((teamID) =>
                    userDoublesTeams.some((team) => team.id === teamID)
                )
            ) {
              leaguesSelection.push({
                label: `${combinedLeague.name} ${combinedLeague.division}- Level ${combinedLeague.level} Playoffs`,
                value: combinedLeague,
              });
            } else if (
                !combinedLeague.doublesFormat &&
                combinedLeague.playoffsPlayers.some(
                    (pID) => pID === playUser.uid
                )
            ) {
              leaguesSelection.push({
                label: `${combinedLeague.name} ${combinedLeague.division}- Level ${combinedLeague.level} Playoffs`,
                value: combinedLeague,
              });
            }
          }
        }
      });
      const singlesMatchSchedules = await getMatchSchedulesByParticipant(
          playUser.uid
      );
      const doublesMatchSchedules = await getMatchSchedulesByParticipants(
          userDoublesTeams.map((team) => team.id)
      );
      setMatchSchedules([...singlesMatchSchedules, ...doublesMatchSchedules]);
    }
    setLeagueSelect(leaguesSelection);
    setLoading(false);
  };

  useEffect(() => {
    getInfo();
  }, [open, user, userDoublesTeams, leagues, divisions, tracks]);

  const generateSinglesLeagueOpponents = async (league) => {
    let opponentIDS = [];
    if (league.status === "playoffs") {
      Object.keys(league.playoffsBracket).forEach((key) => {
        league.playoffsBracket[key].forEach((slot) => {
          if (
            slot.score === "" &&
            slot.list.find((p) => p === playUser.uid) !== undefined
          ) {
            let p = playUser.uid === slot.list[0] ? slot.list[1] : slot.list[0];
            if (
              p.slice(0, 2) !== "bye" &&
              p.length > 5 &&
              !league.withdrawn.some((p) => p === p)
            )
              opponentIDS.push(p);
          }
        });
      });
    } else {
      if (isModeratorSingle) {
        const playersIds = columns[selectColumnForScore].items.map((i) => i.id);
        opponentIDS.push(playersIds[1]);
      } else {
        league.players.forEach((playerID) => {
          if (
            playerID !== playUser.uid &&
            !league.withdrawn.some((p) => p === playerID)
          )
            opponentIDS.push(playerID);
        });
      }
    }
    let opponents = await getUsersByID(opponentIDS);
    setOpponentSelect([
      !isModeratorSingle && { label: "Select Opponent", value: "placeholder" },
      ...opponents.map((player) => {
        return {
          label: player.name,
          value: player,
        };
      }),
    ]);
    if (isModeratorSingle) {
      setOpponent(opponents[0]);
    }
  };

  const generateDoublesLeagueOpponents = async (league) => {
    const userTeam = getUserLeagueTeam(league);
    setUserTeam(userTeam);
    let opponentIDS = [];
    if (league.status === "playoffs") {
      Object.keys(league.playoffsBracket).forEach((key) => {
        league.playoffsBracket[key].forEach((slot) => {
          if (
            slot.score === "" &&
            slot.list.find((team) => team === userTeam.id) !== undefined
          ) {
            let opponentTeamID =
              userTeam.id === slot.list[0] ? slot.list[1] : slot.list[0];
            if (
              opponentTeamID.slice(0, 2) !== "bye" &&
              opponentTeamID.length > 5
            )
              opponentIDS.push(opponentTeamID);
          }
        });
      });
    } else {
      league.doublesTeams.forEach((teamID) => {
        if (
          !userDoublesTeams.some((userTeam) => userTeam.id === teamID) &&
          !league.withdrawn.some((id) => id === teamID)
        )
          opponentIDS.push(teamID);
      });
    }
    let opponentTeams = await getDoublesTeamsByID(opponentIDS);
    setOpponentSelect([
      { label: "Select Opponent", value: "placeholder" },
      ...opponentTeams.map((team) => {
        return {
          label: team.teamName,
          value: team,
        };
      }),
    ]);
  };

  const getUserLeagueTeam = (league) => {
    const team = userDoublesTeams.find((team) =>
      league.doublesTeams.some((teamID) => teamID === team.id)
    );
    return team;
  };

  const handleSelectLeague = async (e) => {
    let league = e.target.value;
    setLeague(league);
    if (league === "placeholder") {
      setEnableOpponentSelection(false);
      return;
    }
    if (!league.doublesFormat) {
      generateSinglesLeagueOpponents(league);
    } else {
      generateDoublesLeagueOpponents(league);
    }
    !isDirectorEnterScore && setEnableOpponentSelection(true);
  };

  const handlePlayerSelect = (e) => {
    // const selectedPlayer = e.target.value;
  };

  const handleOpponentSelect = (e) => {
    const opponent = e.target.value;
    setOpponent(opponent);
    setWinnerID(league.doublesFormat ? userTeam.id : playUser.uid);
    setLoserID(opponent.id);
    setEnableResultSelection(true);
  };

  const createOpponentScorePlaceholder = () => {
    if (!league.doublesFormat) return `0 (${opponent.name}'s Score)`;
    return `0 (${opponent.teamName}'s Score)`;
  };

  const handleResultSelect = (e) => {
    const result = e.target.value;
    setResult(result);
    if (result === "I Won") {
      setWinnerID(league.doublesFormat ? userTeam.id : isDirectorEnterScore && !isFromPlayoff ? userTeam.id : playUser.uid);
      setLoserID(opponent.id);
      setWinnerValuePlaceholder(
        isDirectorEnterScore
          ? `0 (${isModeratorSingle ? player.name : player.teamName}'s Score)`
          : "0 (Your Score)"
      );
      setLoserValuePlaceholder(createOpponentScorePlaceholder());
      setCancelled(false);
      setRetired(false);
      setNoShow(false);
    } else if (result === "I Lost") {
      setWinnerID(opponent.id);
      setLoserID(league.doublesFormat ? userTeam.id : isDirectorEnterScore && !isFromPlayoff ? userTeam.id : playUser.uid);
      setWinnerValuePlaceholder(createOpponentScorePlaceholder());
      setLoserValuePlaceholder(
        isDirectorEnterScore
          ? `0 (${isModeratorSingle ? player.name : player.teamName}'s Score)`
          : "0 (Your Score)"
      );
      setCancelled(false);
      setRetired(false);
      setNoShow(false);
    } else if (result === "I Retired") {
      setWinnerID(opponent.id);
      setLoserID(league.doublesFormat ? userTeam.id : isDirectorEnterScore && !isFromPlayoff ? userTeam.id : playUser.uid);
      setWinnerValuePlaceholder(createOpponentScorePlaceholder());
      setLoserValuePlaceholder(
        isDirectorEnterScore
          ? `0 (${isModeratorSingle ? player.name : player.teamName}'s Score)`
          : "0 (Your Score)"
      );
      setRetired(true);
      setCancelled(false);
      setNoShow(false);
    } else if (result === "No-Show (I Win)") {
      setWinnerID(league.doublesFormat ? userTeam.id : isDirectorEnterScore && !isFromPlayoff ? userTeam.id : playUser.uid);
      setLoserID(opponent.id);
      setWinnerValuePlaceholder(
        isDirectorEnterScore
          ? `0 (${player.teamName}'s Score)`
          : "0 (Your Score)"
      );
      setLoserValuePlaceholder(createOpponentScorePlaceholder());
      setNoShow(true);
      setCancelled(false);
      setRetired(false);
    } else if (result === "Walkover (Opponent Wins)") {
      setWinnerID(opponent.id);
      setLoserID(league.doublesFormat ? userTeam.id : isDirectorEnterScore && !isFromPlayoff ? userTeam.id : playUser.uid);
      setWinnerValuePlaceholder(createOpponentScorePlaceholder());
      setLoserValuePlaceholder(
        isDirectorEnterScore
          ? `0 (${player.teamName}'s Score)`
          : "0 (Your Score)"
      );
      setNoShow(true);
      setCancelled(false);
      setRetired(false);
    } else if (result === "Opponent Retired") {
      setWinnerID(league.doublesFormat ? userTeam.id : isDirectorEnterScore && !isFromPlayoff ? userTeam.id : playUser.uid);
      setLoserID(opponent.id);
      setWinnerValuePlaceholder(
        isDirectorEnterScore
          ? `0 (${player.teamName}'s Score)`
          : "0 (Your Score)"
      );
      setLoserValuePlaceholder(createOpponentScorePlaceholder());
      setRetired(true);
      setCancelled(false);
      setNoShow(false);
    } else {
      setCancelled(true);
      setRetired(false);
      setNoShow(false);
    }
  };

  const handleScoringFormatSelect = (e) => {
    setScoringFormat(e.target.value);
    if (
      e.target.value === "2SetsWithTieBreaker" &&
      tieBreaker1 === "0" &&
      tieBreaker2 === "0"
    ) {
      setTieBreaker1("");
      setTieBreaker2("");
    } else if (
      e.target.value === "2/3Sets" &&
      tieBreaker1 === "" &&
      tieBreaker2 === ""
    ) {
      setTieBreaker1("0");
      setTieBreaker2("0");
    }
  };

  const createSinglesEmailParams = () => {
    const winner = playUser.uid === winnerID ? playUser : opponent;
    const loser = playUser.uid === loserID ? playUser : opponent;
    const winnerName = winner.firstName;
    const loserName = loser.firstName;
    const recipients = [
      {
        name: winner.firstName,
        email: winner.email,
        result: "won",
        enemyName: loser.firstName,
      },
      {
        name: loser.firstName,
        email: loser.email,
        result: "lost",
        enemyName: winner.lastName,
      },
    ];
    return { recipients, winnerName, loserName };
  };

  const createDoublesEmailParams = () => {
    const winner = userTeam.id === winnerID ? userTeam : opponent;
    const loser = userTeam.id === loserID ? userTeam : opponent;
    const winnerName = winner.teamName;
    const loserName = loser.teamName;
    const recipients = [
      {
        name: winner.players[0].firstName,
        email: winner.players[0].email,
        result: "won",
        enemyName: loser.teamName,
      },
      {
        name: winner.players[1].firstName,
        email: winner.players[1].email,
        result: "won",
        enemyName: loser.teamName,
      },
      {
        name: loser.players[0].firstName,
        email: loser.players[0].email,
        result: "lost",
        enemyName: winner.teamName,
      },
      {
        name: loser.players[1].firstName,
        email: loser.players[1].email,
        result: "lost",
        enemyName: winner.teamName,
      },
    ];
    return { recipients, winnerName, loserName };
  };

  const sendMessage = async () => {
    const { recipients, winnerName, loserName } = league.doublesFormat
      ? createDoublesEmailParams()
      : createSinglesEmailParams();
    const result =
      scoringFormat === "10GamePro"
        ? `(${tenGameScore1} - ${tenGameScore2})`
        : `(${setOneScore1} - ${setOneScore2}, ${setTwoScore1} - ${setTwoScore2}, ${tieBreaker1} - ${tieBreaker2})`;
    const subject = `Game.Set.Match – ${winnerName} wins over ${loserName}; Score ${result}`;
    const message = `On ${matchDate}, ${winnerName} wins over ${loserName} with a score of ${result} playing on ${surfaceType}. This is ${league.division} for ${league.name}.`;
    const message2 = `Congratulations to ${winnerName}. If this is incorrect, please email your league director within the next 24 hours...  - Game.Set.Match`;
    await noActionEmail({
      subject: subject,
      recipients: recipients,
      message: message,
      message2: message2,
    });
  };

  const scoreAlreadyReported = async () => {
    const previousReportedScores = await getScoresByParticipantTrackAndDate(
      league.doublesFormat ? userTeam.id : playUser.uid,
      league.trackID,
      matchDate
    );
    const filteredScores = previousReportedScores.filter(
      (score) => score.winner === opponent.id || score.loser === opponent.id
    );
    return filteredScores.length > 0;
  };

  const getMatchScheduleID = () => {
    const match = matchSchedules.find(
      (schedule) =>
        schedule.participants.includes(
          league.doublesFormat ? userTeam.id : playUser.uid
        ) && schedule.participants.includes(opponent.id)
    );
    if (match === undefined) return "N/A";
    return match.id;
  };

  const formVerified = () => {
    if (league === "placeholder") return false;
    if (opponent === "placeholder") return false;
    return true;
  };

  const uploadScore = async (e) => {
    e.preventDefault();
    if (isFromEventModeration && cancelled) {
      handleplayerSendToWaitingRoom();
      handleClose();
      setSubmitting(false);
    } else {
      if (!formVerified()) return;
      setSubmitting(true);
      const scoreReportedToday =
        !isFromPlayoff && (await scoreAlreadyReported());
      if (scoreReportedToday && !error.visible) {
        setError({
          visible: true,
          message:
            "A score against these opponents was reported for this same day. Are you sure you want to report another one?",
        });
        setSubmitting(false);
        return;
      }
      if (winnerID === loserID) {
        setError({
          visible: true,
          message:
            "The same player seems to be selected. Please ensure you have choosen 2 different players",
        });
        setSubmitting(false);
        return;
      }

      let matchID = "";
      if (!isFromPlayoff) {
        matchID = isFromEventModeration
          ? matchScheduleId
          : getMatchScheduleID();
        if (matchID !== "N/A" && !isFromEventModeration) {
          await firestore
            .collection("matchSchedules")
            .doc(matchID)
            .update({ status: "played" });
        }
      }

      await firestore
        .collection("scores")
        .add({
          matchID: matchID,
          leagueID: league.leagueID,
          divisionID: league.divisionID,
          trackID: league.trackID,
          matchDate: matchDate,
          date: moment(matchDate, "MM/DD/YYYY").toDate(),
          surface: surfaceType,
          scoringFormat: scoringFormat,
          setOneScore: [setOneScore1, setOneScore2],
          setTwoScore: [setTwoScore1, setTwoScore2],
          setThreeScore: [tieBreaker1, tieBreaker2],
          proSetScore: [tenGameScore1, tenGameScore2],
          participants: [winnerID, loserID],
          winner: winnerID,
          loser: loserID,
          sportType: league.sportType || "tennis",
          retired: retired,
          cancelled: cancelled,
          cancelledReason: cancelledReason,
          noShow: noShow,
          doublesFormat: league.doublesFormat,
          scoreType:
            league.status === "playoffs" ? "playoffScore" : "standardScore",
        })
        .then(async (doc) => {
          if (review === "" && privateReview === "") return;
          await firestore.collection("reviews").add({
            matchID: matchID,
            scoreID: doc.id,
            leagueID: league.leagueID,
            divisionID: league.divisionID,
            trackID: league.trackID,
            reviewers: [playUser.uid],
            reviewees: league.doublesFormat
              ? opponent.players.map((player) => player.id)
              : [opponent.id],
            publicReview: review,
            privateReview: privateReview,
            matchDate: matchDate,
            surface: surfaceType,
            doublesFormat: league.doublesFormat,
          });
        });
      await sendMessage();
      handleClose();
      setSubmitting(false);
      isFromEventModeration && handleplayerSendToWaitingRoom();
    }
  };

  const handleplayerSendToWaitingRoom = () => {
    if (isFromEventModeration) {
      const sourceColumn = columns[selectColumnForScore];
      const destColumn = columns["waitingRoom"];
      const sourceItems = sourceColumn.items;
      const destItems = [...destColumn.items, ...sourceItems];
      let obj = {
        ...columns,
        [selectColumnForScore]: {
          ...sourceColumn,
          items: [],
          status: null,
          teamID: [],
        },
        waitingRoom: {
          ...destColumn,
          items: destItems,
        },
      };
      setColumns(obj);
      if (!cancelled) {
        updateNumOfPlayedMatch();
      }
    }
  };

  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogContent>
        {loading ? (
          <Loader
            type="TailSpin"
            color="#02c39a"
            height={100}
            width={100}
            timeout={3000}
          />
        ) : (
          <form onSubmit={(e) => uploadScore(e)}>
            <div className="scoreUploadFieldWrapper">
              <Typography className="scoreUploadFieldText">
                Select League
              </Typography>
              <TextField
                fullWidth={true}
                select
                required
                value={league}
                onChange={handleSelectLeague}
              >
                {leagueSelect.map((l, i) => (
                  <MenuItem key={i} value={l.value}>
                    {l.label}
                  </MenuItem>
                ))}
              </TextField>
              <Typography className="scoreUploadFieldText margin10Top">
                Match Date
              </Typography>
              <DateTextField
                dateString={matchDate}
                setDateString={setMatchDate}
                date={matchDateObj}
                setDate={setMatchDateObj}
                required={true}
              />
              <Typography className="scoreUploadFieldText margin10Top">
                Court Type
              </Typography>
              <MenuTextField
                state={surfaceType}
                setState={setSurfaceType}
                required={true}
                menuSelection={surfaceList}
              />
              <div
                className={isDirectorEnterScore ? "rowCenter margin30Top" : ""}
              >
                {isDirectorEnterScore && (
                  <div className="column widthHalf margin10Right">
                    <Typography className="scoreUploadFieldText">
                      {`Selected ${
                        isModeratorSingle ? "Player" : "Team"
                      } for Score (${
                        isModeratorSingle ? "Player-1" : "Team-1"
                      })`}
                    </Typography>
                    <TextField
                      required={true}
                      value={player}
                      select
                      onChange={(e) => handlePlayerSelect(e)}
                    >
                      {playerSelect.map((o, i) => (
                        <MenuItem key={i} value={o.value}>
                          {o.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                )}
                <div
                  className={`column ${
                    isDirectorEnterScore ? "widthHalf margin10Right" : ""
                  }`}
                >
                  <Typography className="scoreUploadFieldText">
                    {`Opponent ${
                      isDirectorEnterScore
                        ? isModeratorSingle
                          ? "Player"
                          : "Team"
                        : ""
                    } Name ${
                      isDirectorEnterScore
                        ? isModeratorSingle
                          ? "(Player-2)"
                          : "(Team-2)"
                        : ""
                    }`}
                  </Typography>
                  <TextField
                    disabled={!enableOpponentSelection}
                    required={true}
                    value={opponent}
                    fullWidth={true}
                    select
                    onChange={handleOpponentSelect}
                  >
                    {opponentSelect.map((o, i) => (
                      <MenuItem key={i} value={o.value}>
                        {o.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
              <Typography className="scoreUploadFieldText">{`Result ${
                isDirectorEnterScore
                  ? isModeratorSingle
                    ? "for (Player-1)"
                    : "for (Team-1)"
                  : ""
              }`}</Typography>
              <TextField
                disabled={!enableResultSelection}
                value={result}
                fullWidth={true}
                select
                required
                placeholder="I Won!"
                onChange={handleResultSelect}
              >
                {winnerSelect.map((w, i) => (
                  <MenuItem key={i} value={w.value}>
                    {w.label}
                  </MenuItem>
                ))}
              </TextField>
              {result !== "Match Cancelled" && (
                <>
                  <Typography className="scoreUploadFieldText margin30Top">
                    Scoring Format
                  </Typography>
                  <RadioGroup
                    className="rowCenter"
                    value={scoringFormat}
                    onChange={handleScoringFormatSelect}
                  >
                    <FormControlLabel
                      className="scoreUploadRadioText"
                      value="2/3Sets"
                      control={<Radio color="default" />}
                      label="2 out of 3 Sets"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="2SetsWithTieBreaker"
                      className="scoreUploadRadioText"
                      control={<Radio color="default" />}
                      label="3 Set Tiebreaker"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="10GamePro"
                      className="scoreUploadRadioText"
                      control={<Radio color="default" />}
                      label="10 Game Pro Set"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                  {scoringFormat === "2/3Sets" && (
                    <Fragment>
                      <Typography className="scoreUploadFieldText margin10Top">
                        Set 1
                      </Typography>
                      <div className="rowCenter">
                        <div className="column widthHalf margin10Right">
                          <TextField
                            value={setOneScore1}
                            required
                            type="number"
                            onChange={(e) => {
                              setSetOneScore1(e.target.value);
                            }}
                            placeholder={winnerValuePlaceholder}
                          />
                        </div>
                        <div className="column widthHalf margin10Left">
                          <TextField
                            value={setOneScore2}
                            required
                            type="number"
                            onChange={(e) => {
                              setSetOneScore2(e.target.value);
                            }}
                            placeholder={loserValuePlaceholder}
                          />
                        </div>
                      </div>
                      <Typography className="scoreUploadFieldText margin10Top">
                        Set 2
                      </Typography>
                      <div className="rowCenter">
                        <div className="column widthHalf margin10Right">
                          <TextField
                            value={setTwoScore1}
                            required
                            type="number"
                            onChange={(e) => {
                              setSetTwoScore1(e.target.value);
                            }}
                            placeholder={winnerValuePlaceholder}
                          />
                        </div>
                        <div className="column widthHalf margin10Left">
                          <TextField
                            value={setTwoScore2}
                            required
                            type="number"
                            onChange={(e) => {
                              setSetTwoScore2(e.target.value);
                            }}
                            placeholder={loserValuePlaceholder}
                          />
                        </div>
                      </div>
                      <Typography className="scoreUploadFieldText margin10Top">
                        Set 3 (Optional)
                      </Typography>
                      <div className="rowCenter">
                        <div className="column widthHalf margin10Right">
                          <TextField
                            value={tieBreaker1}
                            required
                            type="number"
                            onChange={(e) => {
                              setTieBreaker1(e.target.value);
                            }}
                            placeholder={winnerValuePlaceholder}
                          />
                        </div>
                        <div className="column widthHalf margin10Left">
                          <TextField
                            value={tieBreaker2}
                            required
                            type="number"
                            onChange={(e) => {
                              setTieBreaker2(e.target.value);
                            }}
                            placeholder={loserValuePlaceholder}
                          />
                        </div>
                      </div>
                    </Fragment>
                  )}
                  {scoringFormat === "2SetsWithTieBreaker" && (
                    <Fragment>
                      <Typography className="scoreUploadFieldText margin10Top">
                        Set 1
                      </Typography>
                      <div className="rowCenter">
                        <div className="column widthHalf margin10Right">
                          <TextField
                            value={setOneScore1}
                            required
                            type="number"
                            onChange={(e) => {
                              setSetOneScore1(e.target.value);
                            }}
                            placeholder={winnerValuePlaceholder}
                          />
                        </div>
                        <div className="column widthHalf margin10Left">
                          <TextField
                            value={setOneScore2}
                            required
                            type="number"
                            onChange={(e) => {
                              setSetOneScore2(e.target.value);
                            }}
                            placeholder={loserValuePlaceholder}
                          />
                        </div>
                      </div>
                      <Typography className="scoreUploadFieldText margin10Top">
                        Set 2
                      </Typography>
                      <div className="rowCenter">
                        <div className="column widthHalf margin10Right">
                          <TextField
                            value={setTwoScore1}
                            required
                            type="number"
                            onChange={(e) => {
                              setSetTwoScore1(e.target.value);
                            }}
                            placeholder={winnerValuePlaceholder}
                          />
                        </div>
                        <div className="column widthHalf margin10Left">
                          <TextField
                            value={setTwoScore2}
                            required
                            type="number"
                            onChange={(e) => {
                              setSetTwoScore2(e.target.value);
                            }}
                            placeholder={loserValuePlaceholder}
                          />
                        </div>
                      </div>
                      <Typography className="scoreUploadFieldText margin10Top">
                        TieBreaker
                      </Typography>
                      <div className="rowCenter">
                        <div className="column widthHalf margin10Right">
                          <TextField
                            value={tieBreaker1}
                            required
                            type="number"
                            onChange={(e) => {
                              setTieBreaker1(e.target.value);
                            }}
                            placeholder={winnerValuePlaceholder}
                          />
                        </div>
                        <div className="column widthHalf margin10Left">
                          <TextField
                            value={tieBreaker2}
                            required
                            type="number"
                            onChange={(e) => {
                              setTieBreaker2(e.target.value);
                            }}
                            placeholder={loserValuePlaceholder}
                          />
                        </div>
                      </div>
                    </Fragment>
                  )}
                  {scoringFormat === "10GamePro" && (
                    <Fragment>
                      <Typography className="scoreUploadFieldText margin10Top">
                        Pro Set
                      </Typography>
                      <div className="rowCenter">
                        <div className="column widthHalf margin10Right">
                          <TextField
                            value={tenGameScore1}
                            required
                            type="number"
                            onChange={(e) => {
                              setTenGameScore1(e.target.value);
                            }}
                            placeholder={winnerValuePlaceholder}
                          />
                        </div>
                        <div className="column widthHalf margin10Left">
                          <TextField
                            value={tenGameScore2}
                            required
                            type="number"
                            onChange={(e) => {
                              setTenGameScore2(e.target.value);
                            }}
                            placeholder={loserValuePlaceholder}
                          />
                        </div>
                      </div>
                    </Fragment>
                  )}
                </>
              )}
              {!isDirectorEnterScore && (
                <>
                  <Typography className="scoreUploadFieldText margin30Top">
                    (Optional) Write a quick review of your opponent:
                  </Typography>
                  <Typography className="scoreUploadFieldText">
                    Note: Please be kind. The community can see this comment.
                  </Typography>
                  <TextField
                    multiline
                    fullWidth={true}
                    rows={3}
                    value={review}
                    placeholder=""
                    onChange={(e) => {
                      setReview(e.target.value);
                    }}
                  />
                  <Typography className="scoreUploadFieldText margin10Top">
                    (Optional) This is where you can put in a candid note on
                    your opponent. Only the director and admin can see it.
                  </Typography>
                  <TextField
                    multiline
                    fullWidth={true}
                    rows={3}
                    value={privateReview}
                    onChange={(e) => {
                      setPrivateReview(e.target.value);
                    }}
                  />
                </>
              )}
              {cancelled && (
                <Fragment>
                  <Typography className="scoreUploadFieldText margin10Top">
                    Cancellation Reason
                  </Typography>
                  <TextField
                    required
                    fullWidth={true}
                    multiline
                    rows={3}
                    value={cancelledReason}
                    onChange={(e) => {
                      setCancelledReason(e.target.value);
                    }}
                  />
                </Fragment>
              )}
              <div className="margin10Top" />
            </div>
            <div className="maxWidth">
              <Divider className="margin20Top" />
            </div>
            <div className="heightPlaceholder40">
              {error.visible && (
                <Typography variant="subtitle2" className="err">
                  {error.message}
                </Typography>
              )}
            </div>
            <div className="rowCenter">
              <Button variant="text" onClick={handleClose}>
                <Typography className="cancelButtonText">Back</Typography>
              </Button>
              <div className="filler" />
              <Button
                className="purchaseButton"
                variant="outlined"
                type="submit"
                disabled={submitting}
              >
                {submitting ? (
                  <Loader
                    type="TailSpin"
                    color="#02c39a"
                    height={25}
                    width={25}
                    timeout={8000}
                  />
                ) : (
                  <Typography className="purchaseButtonText">
                    {error.visible ? "Submit Again" : "Submit"}
                  </Typography>
                )}
              </Button>
            </div>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default ScoreUploadForm;

import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import ActivityLeaguesPopup from "./ActivityLeaguesPopup";
import styled from "@material-ui/core/styles/styled";
import {FacebookShareButton} from "react-share";
import {Facebook} from "@material-ui/icons";

const CustomButton = styled(Button)(() => ({
  color: "#fff",
  backgroundColor: "#54b56a",
  fontWeight: "bold",
  "&:hover": {
    color: "black",
  },
}));

const SinglesMatchActivity = ({
  activity,
  score,
  winner,
  loser,
  scoreType,
  league,
  division,
  track,
  date,
  activityInfo,
  user,
}) => {
  const message =
    activity.category === "finalsCompleted"
      ? `Congratulations ${
          winner.firstName
        } for being the new Champion of the league - ${league.name}, ${
          division.division
        } Level ${division.level} - Track ${track.track + 1}.`
      : score.cancelled
      ? `${winner.firstName} ${winner.lastName} won a ${scoreType} against ${loser.firstName} ${loser.lastName} due to a cancelation!`
      : `${winner.firstName} ${winner.lastName} won a ${scoreType} against ${loser.firstName} ${loser.lastName}!`;
  return (
    <Card className="activityItemMain">
      <div className="rowCenter">
        <Link className="noUnderline" to={`/Profile/ViewProfile/${winner.id}`}>
          <Avatar className="image50" src={winner.imageURL} />
        </Link>
        <Link className="noUnderline" to={`/Profile/ViewProfile/${winner.id}`}>
          <div className="column margin10Left">
            <Typography className="activityItemHeader">
              {winner.firstName} {winner.lastName}
            </Typography>
            <Typography className="activityItemSubHeader">{date}</Typography>
          </div>
        </Link>
        <div className="filler" />
        <div className="activityFieldSortText">
          <Button
            className={
              activity.likes.includes(user.uid)
                ? "createLeagueNextButtonFill"
                : "createLeagueNextButton"
            }
            onClick={() => handleLike(activityInfo, user)}
          >
            <span role="img" aria-labelledby="applause">
              👏
            </span>{" "}
            {activity.likes.length}
          </Button>
        </div>
      </div>
      <div className="margin10Top" />
      <Divider />
      <div className="margin10Top" />
      <div className="column">
        <Typography className="activityItemDescription">{message}</Typography>
        {!score.noShow && !score.cancelled && !score.retired ? (
          <div className="rowCenter justify-between">
            <div className="rowCenter">
              {(score.scoringFormat === "10GamePro" ||
                score.scoringFormat === "1Gameto15or21points") && (
                <Fragment>
                  <Typography className="activityItemDescription">
                    Scores:
                  </Typography>
                  <Typography className="activityItemPoints margin10Left">
                    {score.proSetScore[0]} - {score.proSetScore[1]}
                  </Typography>
                </Fragment>
              )}
              {(score.scoringFormat === "2/3Sets" ||
                score.scoringFormat === "2SetsWithTieBreaker") && (
                <Fragment>
                  <Typography className="activityItemDescription">
                    Scores:
                  </Typography>
                  <Typography className="activityItemPoints margin10Left">
                    {score.setOneScore[0]} - {score.setOneScore[1]}
                  </Typography>
                  <Typography className="activityItemPoints margin10Left">
                    {score.setTwoScore[0]} - {score.setTwoScore[1]}
                  </Typography>
                  {(score.setThreeScore[0] !== "0" ||
                    score.setThreeScore[0] !== "0") && (
                    <Typography className="activityItemPoints margin10Left">
                      {score.setThreeScore[0]} - {score.setThreeScore[1]}
                    </Typography>
                  )}
                </Fragment>
              )}
            </div>
          </div>
        ) : (
          <Fragment>
            <Typography className="activityItemDescription">
              {score.noShow
                ? "No Show/Walkover"
                : score.cancelled
                ? "Reason: " + score.cancelledReason
                : "Retired"}
            </Typography>
          </Fragment>
        )}
        <Typography className="activityItemDescription">
          {`On ${score.surface} Court`}
        </Typography>
      </div>
    </Card>
  );
};
const DoublesMatchActivity = ({
  activity,
  score,
  winner,
  loser,
  scoreType,
  league,
  division,
  track,
  date,
  activityInfo,
  user,
}) => {
  const message =
    activity.category === "finalsCompleted"
      ? `Congratulations to the team, ${
          winner.teamName
        } for being the new Champion of the league - ${league.name}, ${
          division.division
        } Level ${division.level} - Track ${track.track + 1}.`
      : score.cancelled
      ? `The team ${winner.teamName}'s won a ${scoreType} against ${loser.teamName} due to a cancelation!`
      : `The team ${winner.teamName} won a ${scoreType} against ${loser.teamName}!`;
  return (
    <Card className="activityItemMain">
      <div className="rowCenter">
        <AvatarGroup max={2}>
          <Avatar className="image50" src={winner.players[0].imageURL} />
          <Avatar className="image50" src={winner.players[1].imageURL} />
        </AvatarGroup>
        <div className="column margin10Left">
          <div className="rowCenter">
            <Link
              className="noUnderline"
              to={`/Profile/ViewProfile/${winner.players[0].id}`}
            >
              <Typography className="activityItemHeader margin10Right">
                {winner.players[0].firstName} {winner.players[0].lastName}
              </Typography>
            </Link>
            <Typography className="activityItemHeader">&</Typography>
            <Link
              className="noUnderline"
              to={`/Profile/ViewProfile/${winner.players[1].id}`}
            >
              <Typography className="activityItemHeader margin10Left">
                {winner.players[1].firstName} {winner.players[1].lastName}
              </Typography>
            </Link>
          </div>
          <Typography className="activityItemSubHeader">{date}</Typography>
        </div>
        <div className="filler" />
        <div className="activityFieldSortText">
          <Button
            className={
              activity.likes.includes(user.uid)
                ? "createLeagueNextButtonFill"
                : "createLeagueNextButton"
            }
            onClick={() => handleLike(activityInfo, user)}
          >
            <span role="img" aria-labelledby="applause">
              👏
            </span>{" "}
            {activity.likes.length}
          </Button>
        </div>
      </div>
      <div className="margin10Top" />
      <Divider />
      <div className="margin10Top" />
      <div className="column">
        <Typography className="activityItemDescription">{message}</Typography>
        {!score.noShow && !score.cancelled && !score.retired ? (
          <div className="rowCenter">
            {(score.scoringFormat === "10GamePro" ||
              score.scoringFormat === "1Gameto15or21points") && (
              <Fragment>
                <Typography className="activityItemDescription">
                  Scores:
                </Typography>
                <Typography className="activityItemPoints margin10Left">
                  {score.proSetScore[0]} - {score.proSetScore[1]}
                </Typography>
              </Fragment>
            )}
            {(score.scoringFormat === "2/3Sets" ||
              score.scoringFormat === "2SetsWithTieBreaker") && (
              <Fragment>
                <Typography className="activityItemDescription">
                  Scores:
                </Typography>
                <Typography className="activityItemPoints margin10Left">
                  {score.setOneScore[0]} - {score.setOneScore[1]}
                </Typography>
                <Typography className="activityItemPoints margin10Left">
                  {score.setTwoScore[0]} - {score.setTwoScore[1]}
                </Typography>
                {(score.setThreeScore[0] !== "0" ||
                  score.setThreeScore[0] !== "0") && (
                  <Typography className="activityItemPoints margin10Left">
                    {score.setThreeScore[0]} - {score.setThreeScore[1]}
                  </Typography>
                )}
              </Fragment>
            )}
          </div>
        ) : (
          <Fragment>
            <Typography className="activityItemDescription">
              {score.noShow
                ? "No Show/Walkover"
                : score.cancelled
                ? "Reason: " + score.cancelledReason
                : "Retired"}
            </Typography>
          </Fragment>
        )}
        <Typography className="activityItemDescription">
          {`On ${score.surface} Court`}
        </Typography>
      </div>
    </Card>
  );
};
const handleLike = async (activityInfo, user) => {
  const activity = activityInfo.activity;
  let tempLikes = activity.likes;
  if (tempLikes.includes(user.uid)) {
    tempLikes = tempLikes.filter(function (like) {
      return like !== user.uid;
    });
  } else {
    tempLikes.push(user.uid);
  }
  await activityInfo.ref.update({ likes: tempLikes });
};

const ListItemActivity = ({ activityInfo, user }) => {
  const activity = activityInfo.activity;
  const date = activityInfo.date;
  const history = useHistory();
  if (
    activity.category !== "matchPlayed" &&
    activity.category !== "finalsCompleted"
  ) {
    const player = activityInfo.player;
    return (
      <Card className="activityItemMain">
        <div className="rowCenter">
          <Link
            className="noUnderline"
            to={`/Profile/ViewProfile/${player.id}`}
          >
            <Avatar className="image50" src={player.imageURL} />
          </Link>
          <Link
            className="noUnderline"
            to={`/Profile/ViewProfile/${player.id}`}
          >
            <div className="column margin10Left">
              <Typography className="activityItemHeader">
                {player.firstName} {player.lastName}
              </Typography>
              <Typography className="activityItemSubHeader">{`Member Since ${moment(
                player.dateCreated.toDate()
              ).format("MMMM, YYYY")}`}</Typography>
              <Typography className="activityItemSubHeader">{date}</Typography>
            </div>
          </Link>
          <div className="filler" />
          <div className="activityFieldSortText">
            <Button
              className={
                activity.likes.includes(user.uid)
                  ? "createLeagueNextButtonFill"
                  : "createLeagueNextButton"
              }
              onClick={() => handleLike(activityInfo, user)}
            >
              <span role="img" aria-labelledby="applause">
                👏
              </span>
              {activity.likes.length}
            </Button>
          </div>
        </div>

        <div className="margin10Top" />
        <Divider />
        <div className="margin10Top" />
        <div className="column">
          {activity.category === "directorMessage" ? (
            <div>
              <Typography className="activityItemDescription">
                {activity.subject}
              </Typography>
              <div className="margin10Top" />
              <Typography className="activityItemDescription">
                {activity.description}
              </Typography>
            </div>
          ) : (
            <div
              className="rowCenter"
              style={{ justifyContent: "space-between" }}
            >
              <Typography className="activityItemDescription">
                {activity.description}
              </Typography>
              {["leagueApproved", "userJoinedLeague"].includes(
                activity?.category
              ) &&
              activity.leaguesData.length > 0 &&
              activity?.leaguesData[0]?.status !== "playoffs" &&
              activity?.leaguesData[0]?.status !== "expired" ? (
                <div style={{ justifyContent: "flex-end", display: "flex" }}>
                  <Typography className="signUpErrorText margin10Right">
                    {activity?.leaguesData[0]?.registrationEndDate ===
                    "Registration open until playoff starts"
                      ? "*still accepting players"
                      : ""}
                  </Typography>
                  {activity?.category === "userJoinedLeague" ? (
                    <CustomButton
                      onClick={() => {
                        history.push(
                          `/League/FindLeague/${
                            activity?.leaguesData[0].doublesFormat
                              ? "Doubles"
                              : "Singles"
                          }/${activity.leagueID}/${activity.divisionID}/${
                            activity.trackID
                          }/Information`
                        );
                      }}
                    >
                      Join Now {activity?.leaguesData[0].division}
                    </CustomButton>
                  ) : (
                    <ActivityLeaguesPopup leagues={activity.leaguesData}>
                      Join Now
                    </ActivityLeaguesPopup>
                  )}
                  {activity?.category === 'leagueApproved' ? (
                      <FacebookShareButton
                          url={`${process.env.REACT_APP_BASE_URL}Director/ViewLeague/${activity.leagueID}`}
                          quote={""}
                          className="facebook-activity-share-btn"
                      >
                        <Facebook className="viewLeagueDirectorContactIcon"/> Share
                      </FacebookShareButton>
                  ) : ''}
                </div>
              ) : (
                ""
              )}
            </div>
          )}
          <div className="rowCenter">
            {activity.imageURLS.map((image, key) => (
              <Avatar
                key={key}
                variant="rounded"
                className="image200 margin10Top"
                src={image}
              />
            ))}
          </div>
        </div>
      </Card>
    );
  }

  const { score, winner, loser, league, division, track } = activityInfo;
  const scoreType =
    score.scoreType === "standardScore" ? "match" : "playoff match";
  return score.doublesFormat ? (
    <DoublesMatchActivity
      activityInfo={activityInfo}
      activity={activity}
      score={score}
      scoreType={scoreType}
      winner={winner}
      loser={loser}
      date={date}
      league={league}
      division={division}
      track={track}
      user={user}
    />
  ) : (
    <SinglesMatchActivity
      activityInfo={activityInfo}
      activity={activity}
      score={score}
      winner={winner}
      loser={loser}
      scoreType={scoreType}
      date={date}
      league={league}
      division={division}
      track={track}
      user={user}
    />
  );
};

export default ListItemActivity;

import React from "react";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Icon from "@mdi/react";
import { mdiPodiumGold } from "@mdi/js";
import moment from "moment";
import { Link } from "react-router-dom";
const PlayerItem = ({ player, score, scoreIndex, display }) => {
  return (
    <Link
      className={
        scoreIndex === 0
          ? "noUnderline listItemMatchScoreWinner"
          : "noUnderline listItemMatchScoreLoser"
      }
      to={`/Profile/ViewProfile/${player.id}`}
    >
      <Avatar src={player.imageURL} className="image50" />
      <div className="column">
        <Typography className="listItemMatchScorePlayerName">{`${player.firstName} ${player.lastName}`}</Typography>
      </div>
    </Link>
  );
};
const TeamItem = ({ team, score, scoreIndex, display }) => {
  return (
    <div
      className={
        scoreIndex === 0
          ? "listItemMatchScoreWinner"
          : "listItemMatchScoreLoser"
      }
    >
      <AvatarGroup max={2}>
        <Avatar className="image50" src={team?.players?.[0]?.imageURL} />
        <Avatar className="image50" src={team?.players?.[1]?.imageURL} />
      </AvatarGroup>
      <div className="column">
        <div className="rowCenterMinWidth">
          <Link
            className="noUnderline"
            to={`/Profile/ViewProfile/${team?.players?.[0]?.id}`}
          >
            <Typography className="listItemMatchScorePlayerName">
              {team?.players?.[0]?.firstName} {team?.players?.[0]?.lastName}
            </Typography>
          </Link>
          <Typography className="listItemMatchScorePlayerName">&</Typography>
          <Link
            className="noUnderline"
            to={`/Profile/ViewProfile/${team?.players?.[1]?.id}`}
          >
            <Typography className="listItemMatchScorePlayerName">
              {team?.players?.[1]?.firstName} {team?.players?.[1]?.lastName}
            </Typography>
          </Link>
        </div>
      </div>
    </div>
  );
};
const MatchListItem = ({ league, division, track, score, winner, loser }) => {
  const format = score.scoringFormat;
  const cancelled = score.cancelled;
  const noShow = score.noShow;
  const doublesFormat = score.doublesFormat;
  const scoreType =
    score.scoreType === "standardScore" ? "Regular Match" : "Playoff Match";
  let display = "";
  if (cancelled) {
    display = "cancelled";
  } else if (noShow) {
    display = "noShow";
  } else if (format === "2/3Sets" || format === "2SetsWithTieBreaker") {
    display = "standard";
  } else {
    display = "proSet";
  }
  return (
    <Card className="listItemMatchScoreWrapper">
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2} xl={2} className="rowCenterMinWidth">
          <Avatar
            variant="rounded"
            src={league?.imageURL}
            className="image100"
          />
          <div>
            <Typography className="listItemMatchScorePlayerName">{`${
              league?.name || "-"
            }`}</Typography>
            <Typography className="listItemMatchScorePlayerName">{`${
              division?.division || "-"
            } ${division?.level || "-"}`}</Typography>
            <Typography className="listItemMatchScorePlayerName">{`Track ${
              track?.track + 1 || "-"
            }`}</Typography>
          </div>
        </Grid>
        <Grid className="rowCenter" item xs={12} lg={3} xl={3}>
          {doublesFormat ? (
            <TeamItem
              team={winner}
              score={score}
              scoreIndex={0}
              display={display}
            />
          ) : (
            <PlayerItem
              player={winner}
              score={score}
              scoreIndex={0}
              display={display}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={1} xl={1} className="listItemMatchScoreMiddle">
          <Icon className="podiumIcon" size="24px" path={mdiPodiumGold} />
          {display === "cancelled" && (
            <Typography className="listItemMatchScoreMiddleText">
              Cancelled
            </Typography>
          )}
          {display === "noShow" && (
            <Typography className="listItemMatchScoreMiddleText">
              Did not show
            </Typography>
          )}
          {(display === "standard" || display === "proSet") && (
            <Typography className="listItemMatchScoreMiddleText">
              Won Over
            </Typography>
          )}
          {display === "standard" && (
            <Typography className="listItemMatchScoreMiddleText">{`${
              score.setOneScore[0]
            } - ${score.setOneScore[1]}, ${score.setTwoScore[0]} - ${
              score.setTwoScore[1]
            }${
              score.setThreeScore[0] === "0" && score.setThreeScore[1] === "0"
                ? ``
                : `, ${score.setThreeScore[0]}-${score.setThreeScore[1]}`
            }`}</Typography>
          )}
          {display === "proSet" && (
            <Typography className="listItemMatchScorePlayerName">
              {`${score.proSetScore[0]} - ${score.proSetScore[1]}`}
            </Typography>
          )}
          <Typography className="listItemMatchScoreLocation">
            {`${score.surface} Court - ${moment(score.matchDate).format(
              "M/D/YYYY"
            )}`}
          </Typography>
          <Typography className="listItemMatchScoreMiddleText">
            {scoreType}
          </Typography>
        </Grid>
        <Grid className="rowCenter" item xs={12} lg={6} xl={6}>
          {doublesFormat ? (
            <TeamItem
              team={loser}
              score={score}
              scoreIndex={1}
              display={display}
            />
          ) : (
            <PlayerItem
              player={loser}
              score={score}
              scoreIndex={1}
              display={display}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default MatchListItem;

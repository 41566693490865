import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { noActionEmail } from "../../functions/Messaging";
import { DefaultTextField } from "../../components/CustomTextField";

const DirectorNotifications = ({ status, getEmailList }) => {
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const sendMessage = async (e) => {
    e.preventDefault();
    const contactList = getEmailList();
    await noActionEmail({
      message: message,
      subject: subject,
      recipients: contactList,
    });
    setSubject("");
    setMessage("");
  };
  return (
    <form className="column filler" onSubmit={sendMessage}>
      <Typography className="createLeagueFieldText">Notify Players</Typography>
      <Divider />
      <Grid className="padding10" container spacing={6}>
        <Grid xs={12} item>
          <Typography className="createLeagueFieldText margin20Top">
            Subject
          </Typography>
          <DefaultTextField
            disabled={status === "pending"}
            required={true}
            state={subject}
            setState={setSubject}
            placeholder="The event is about to begin..."
          />
          <Typography className="createLeagueFieldText margin20Top">
            Message
          </Typography>
          <DefaultTextField
            disabled={status === "pending"}
            required={true}
            state={message}
            setState={setMessage}
            placeholder="The event is about to begin..."
            multiline={5}
          />
        </Grid>
      </Grid>
      <div className="filler" />
      <Divider className="margin20Vertical" />
      <div className="rowCenter">
        <div className="filler" />
        <Button
          className="profileSaveButton"
          variant="outlined"
          type="submit"
          disabled={status === "pending"}
        >
          <Typography className="profileSaveButtonText">
            Send Message
          </Typography>
        </Button>
      </div>
    </form>
  );
};
export default DirectorNotifications;

import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Firebase } from "../../../firebase";
import DefaultDialog from "../../../components/DefaultDialog";
import { noActionEmail } from "../../../functions/Messaging";
const ManageParticipant = ({
  openEdit,
  setOpenEdit,
  selectedPlayer,
  selectedTrack,
  tracks,
}) => {
  const disableWithdrawls =
    selectedPlayer !== undefined && selectedPlayer.withdrawn;

  const handlePlayerWithdrawl = async () => {
    const leagueHistoryIndex = selectedPlayer.leaguesJoined.findIndex(
      (lJ) => lJ.trackID === selectedTrack
    );

    let leaguesJoined = selectedPlayer.leaguesJoined;
    leaguesJoined[leagueHistoryIndex].withdrawn = true;
    await selectedPlayer.ref.update({
      leaguesJoined: leaguesJoined,
    });
    let track = tracks.find((track) => track.track.id === selectedTrack).track;
    await track.ref.update({
      withdrawn: Firebase.firestore.FieldValue.arrayUnion(selectedPlayer.id),
    });
    await noActionEmail({
      subject: `Withdrawn Status`,
      message: `
This is to confirm that your league status is now set to “withdrawn” either due to your request or a decision made by the Director of the League or Admin of the platform. If you have any questions or feel that this is not accurate, please reach out to your league director for any questions. If you qualify for a refund, that process is now initiated and will be completed soon.
`,
      recipients: [
        { email: selectedPlayer.email, name: selectedPlayer.firstName },
      ],
    });
  };

  return (
    <>
      {selectedPlayer !== undefined && (
        <DefaultDialog open={openEdit} setOpen={setOpenEdit}>
          <div className="column">
            <div className="rowCenter">
              <Avatar
                variant="rounded"
                src={selectedPlayer.imageURL}
                className="image200Rounded"
              />
              <div className="column margin10Left">
                <Typography className="profileInfoCardTitle">
                  {`${selectedPlayer.firstName} ${selectedPlayer.lastName}`}
                </Typography>
                <Typography className="profileInfoCardSubtitle">
                  {`Rating: ${Math.round(selectedPlayer.gsmRating * 100)}`}
                </Typography>
                {selectedPlayer.withdrawn && (
                  <Typography className="profileInfoCardTitle">
                    Withdrawn
                  </Typography>
                )}
              </div>
            </div>
            <Button
              className="CancelButton margin20Top"
              variant="outlined"
              disabled={disableWithdrawls}
              onClick={handlePlayerWithdrawl}
            >
              <Typography className="profileUpdateButtonText">
                Withdraw Player
              </Typography>
            </Button>
          </div>
        </DefaultDialog>
      )}
    </>
  );
};

export default ManageParticipant;

import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import { Link, Redirect } from "react-router-dom";
import { PriceBackgroundIcon } from "../../res/SVGIcons";
import moment from "moment";
const calculateDate = (combinedLeague) => {
  const startDate = moment(combinedLeague.startDate.toDate()).format(
    "M/D/YYYY"
  );
  const endDate = moment(combinedLeague.endDate.toDate()).format("M/D/YYYY");
  let regEndDate = moment(combinedLeague.startDate.toDate());
  if (
    combinedLeague.registrationEndDate === "One week before the league starts"
  )
    regEndDate.subtract(7, "d");
  else if (
    combinedLeague.registrationEndDate === "One day before the league starts"
  )
    regEndDate.subtract(1, "d");
  else if (
    combinedLeague.registrationEndDate === "One day after the league starts"
  )
    regEndDate.add(1, "d");
  else if (
    combinedLeague.registrationEndDate === "One week after the league starts"
  )
    regEndDate.add(7, "d");
  const regDeadline = regEndDate.format("M/D/YYYY");
  return { startDate, endDate, regDeadline };
};
const MemberLeagueItem = ({ combinedLeague, link, isOutGoing }) => {
  const { startDate, endDate } = calculateDate(combinedLeague);
  return (
    <div className="columnCenter listItemLeagueContentWrapper">
      <div className="columnCenter">
        <Typography className="listItemLeagueTitleText" variant="h6">
          {`${combinedLeague.name} ${
            combinedLeague.event ? "" : combinedLeague.division
          }`}
        </Typography>
        {combinedLeague.event ? (
          <Typography> </Typography>
        ) : (
          <Typography className="listItemLeagueTitleText" variant="h6">
            {`Level ${combinedLeague.level}, Track ${combinedLeague.track + 1}`}
          </Typography>
        )}
      </div>
      <div className="listItemLeagueDateWrapper">
        <div className="listItemLeagueDateObj">
          <Typography className="listItemLeagueFinePrintText">
            Starts:
          </Typography>
          <Typography className="listItemLeagueDateText">
            {startDate}
          </Typography>
        </div>
        <div className="listItemLeagueDateObj">
          <Typography className="listItemLeagueFinePrintText">Ends:</Typography>
          <Typography className="listItemLeagueDateText">{endDate}</Typography>
        </div>
      </div>
      <div className="filler" />
        {isOutGoing && combinedLeague.status !== "playoffs" && combinedLeague.registrationEndDate === "Registration open until playoff starts"  &&(
            <Typography className="listItemLeagueDeadlineText">
                *still accepting players
            </Typography>
        )}
      <Typography className="listItemLeagueDeadlineText">
        {`Status: ${
          combinedLeague.status === "approved"
            ? "Joined"
            : combinedLeague.status
        }`}
      </Typography>
      <Link
        className="noUnderline listItemLeagueButtonWrapper"
        to={{ pathname: link }}
      >
        <Button className="listItemLeagueJoinButton">
          {combinedLeague.event ? "View Event" : "View League"}
        </Button>
      </Link>
    </div>
  );
};
const PurchaseLeagueItem = ({ combinedLeague, link, isPickleball, isUpcoming }) => {
  const { startDate, endDate, regDeadline } = calculateDate(combinedLeague);
  const playerCount =
    (combinedLeague.doublesFormat
      ? combinedLeague?.doublesTeams?.length * 2
      : combinedLeague?.players?.length) - combinedLeague.withdrawn;
  return (
    <div className="columnCenter listItemLeagueContentWrapper">
      <div className="columnCenter">
        <Typography className="listItemLeagueTitleText" variant="h6">
          {`${combinedLeague.name} ${
            combinedLeague.event ? "" : combinedLeague.division
          }`}
        </Typography>

        {combinedLeague.event ? (
          <Typography> </Typography>
        ) : (
          <Typography className="listItemLeagueTitleText" variant="h6">
            {`Level ${combinedLeague.level}, Track ${combinedLeague.track + 1}`}
          </Typography>
        )}
      </div>
      <div className="listItemLeagueDateWrapper">
        <div className="listItemLeagueDateObj">
          <Typography className="listItemLeagueFinePrintText">
            Starts:
          </Typography>
          <Typography className="listItemLeagueDateText">
            {startDate}
          </Typography>
        </div>
        <div className="listItemLeagueDateObj">
          <Typography className="listItemLeagueFinePrintText">Ends:</Typography>
          <Typography className="listItemLeagueDateText">{endDate}</Typography>
        </div>
      </div>
      <div className="filler" />
        <Typography className="listItemLeagueDeadlineText">
            {isUpcoming && combinedLeague.status !== "playoffs" && combinedLeague.registrationEndDate === "Registration open until playoff starts" ? '*still accepting players' :`Registration Deadline: ${regDeadline}`}
        </Typography>
      <Typography className="listItemLeagueJoinedText">
        {playerCount}/{combinedLeague.numPlayerRange[1]} joined so far!
      </Typography>
      <Link
        className="noUnderline listItemLeagueButtonWrapper"
        to={{ pathname: link }}
      >
        <Typography className="listItemLeaguePriceText">
          {combinedLeague.price === "0" ? "Free" : "$" + combinedLeague.price}
        </Typography>
        <div className="listItemLeaguePriceBackground">
          <PriceBackgroundIcon isPickleball={isPickleball} />
        </div>
        <Button className="listItemLeagueJoinButton">Join Now</Button>
      </Link>
    </div>
  );
};
const DirectorLeagueItem = ({ combinedLeague, link }) => {
  const { startDate, endDate } = calculateDate(combinedLeague);
  return (
    <div className="columnCenter listItemLeagueContentWrapper">
      <div className="columnCenter">
        <Typography className="listItemLeagueTitleText" variant="h6">
          {`${combinedLeague.name}`}
        </Typography>
      </div>
      <div className="listItemLeagueDateWrapper">
        <div className="listItemLeagueDateObj">
          <Typography className="listItemLeagueFinePrintText">
            Starts:
          </Typography>
          <Typography className="listItemLeagueDateText">
            {startDate}
          </Typography>
        </div>
        <div className="listItemLeagueDateObj">
          <Typography className="listItemLeagueFinePrintText">Ends:</Typography>
          <Typography className="listItemLeagueDateText">{endDate}</Typography>
        </div>
      </div>
      <div className="filler" />
      <Typography className="listItemLeagueDeadlineText">
        {`Status: ${combinedLeague.status}`}
      </Typography>
      <Link
        className="noUnderline listItemLeagueButtonWrapper"
        to={{ pathname: link }}
      >
        <Button className="listItemLeagueJoinButton">
          {!combinedLeague.event ? "Edit League" : "Edit Event"}
        </Button>
      </Link>
    </div>
  );
};
const DirectorDraftLeagueItem = ({ combinedLeague, link }) => {
  return (
    <div className="columnCenter listItemLeagueContentWrapper">
      <div className="columnCenter">
        <Typography className="listItemLeagueTitleText" variant="h6">
          {`${combinedLeague.name}`}
        </Typography>
      </div>
      <div className="filler" />
      <Typography className="listItemLeagueDeadlineText">
        {`Status: ${combinedLeague.status}`}
      </Typography>
      <Link
        className="noUnderline listItemLeagueButtonWrapper"
        to={{ pathname: link }}
      >
        <Button className="listItemLeagueJoinButton">
          {!combinedLeague.event ? "Edit League" : "Edit Event"}
        </Button>
      </Link>
    </div>
  );
};
const ListItemLeague = ({ combinedLeague, isPickleball, isUpcoming=false, isOutGoing=false, lg=4 }) => {
  let link = "";
  const leagueType = combinedLeague.leagueType;
  const leagueFormat = combinedLeague.doublesFormat ? "Doubles" : "Singles";
  const eventType = combinedLeague.event;

  if (leagueType === "member")
    link = eventType
      ? `/League/MyEvents/${combinedLeague.leagueID}/${combinedLeague.divisionID}/${combinedLeague.id}/Information`
      : `/League/MyLeagues/${leagueFormat}/${combinedLeague.leagueID}/${combinedLeague.divisionID}/${combinedLeague.id}/Information`;
  else if (leagueType === "purchase")
    link = eventType
      ? `/League/FindEvent/${combinedLeague.leagueID}/${combinedLeague.divisionID}/${combinedLeague.id}/Information`
      : `/League/FindLeague/${leagueFormat}/${combinedLeague.leagueID}/${combinedLeague.divisionID}/${combinedLeague.id}/Information`;
  else if (leagueType === "directorDraft")
    link = eventType
      ? `/Director/CreateEvent/${combinedLeague.id}`
      : `/Director/CreateLeague/${combinedLeague.id}`;
  else
    link = eventType
      ? `/Director/ViewEvent/${leagueFormat}/${combinedLeague.id}`
      : `/Director/ViewLeague/${leagueFormat}/${combinedLeague.id}`;
  const [redirect, setRedirect] = useState(false);
  const handleClick = () => {
    setRedirect((redirect) => !redirect);
  };
  if (redirect) return <Redirect to={{ pathname: link }} />;
  return (
    <Grid className="myLeagueItemGridWrapper" item xs={12} md={6} lg={lg} xl={3}>
      <Card onClick={handleClick} className="myLeagueItem">
        <Avatar
          className="listLeagueImage"
          src={combinedLeague.imageURL}
          variant="rounded"
        />
        {leagueType === "member" && (
          <MemberLeagueItem combinedLeague={combinedLeague} link={link} isOutGoing={isOutGoing} />
        )}
        {leagueType === "purchase" && (
          <PurchaseLeagueItem
            isPickleball={isPickleball}
            isUpcoming={isUpcoming}
            combinedLeague={combinedLeague}
            link={link}
          />
        )}
        {leagueType === "director" && (
          <DirectorLeagueItem combinedLeague={combinedLeague} link={link} />
        )}
        {leagueType === "directorDraft" && (
          <DirectorDraftLeagueItem
            combinedLeague={combinedLeague}
            link={link}
          />
        )}
      </Card>
    </Grid>
  );
};

export default ListItemLeague;

import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SearchBar from "../../components/SearchBar";
import DropdownCheckbox from "../../components/DropdownCheckbox";
import { MenuTextField } from "../../components/CustomTextField";
import createZoneFilterList from "../../functions/Zoning.js";
const genderOptions = [
  { label: "Men", value: "Men" },
  { label: "Women", value: "Women" },
  { label: "All", value: "All" },
];
const LeaderboardSearchBar = ({
  user,
  search,
  setSearch,
  genderSelect,
  setGenderSelect,
  levelSelect,
  setLevelSelect,
  sectionSelect,
  setSectionSelect,
  localeSelect,
  setLocaleSelect,
  areaSelect,
  setAreaSelect,
  zoneList,
}) => {
  const [sectionList, setSectionList] = useState([]);
  const [localeList, setLocaleList] = useState([]);
  const [areaList, setAreaList] = useState([]);

  useEffect(() => {
    createZoneFilterList(
      zoneList,
      user.playLocation.sectionals,
      user.playLocation.locale,
      setSectionList,
      setLocaleList,
      setAreaList
    );
    // eslint-disable-next-line
  }, [user.playLocation.locale, user.playLocation.sectionals]);
  const handleFilterReset = () => {
    setSearch("");
    setGenderSelect("All");
    setLevelSelect([
      { label: "1 -Beginners", check: true, value: 3 },
      { label: "2 -Novice", check: true, value: 3.5 },
      { label: "3 -Intermediate", check: true, value: 4 },
      { label: "4 -Advanced", check: true, value: 4.5 },
      { label: "5 -Competitive/D2", check: true, value: 6.0 },
      { label: "6 -Competitive/D1", check: true, value: 7 },
      { label: "7 -Circuit Level Pro", check: true, value: 8 },
      { label: "8 -World Class", check: true, value: 9 },
    ]);
    setSectionSelect(user.playLocation.sectionals);
    setLocaleSelect(user.playLocation.locale);
    setAreaSelect("All");
    createZoneFilterList(
      zoneList,
      user.playLocation.sectionals,
      user.playLocation.locale,
      setSectionList,
      setLocaleList,
      setAreaList
    );
  };
  const handleSectionalChange = (e) => {
    let section = e.target.value;
    setSectionSelect(section);
    setLocaleSelect("All");
    if (section !== "All")
      createZoneFilterList(
        zoneList,
        section,
        "",
        setSectionList,
        setLocaleList,
        setAreaList
      );
  };
  const handleLocaleChange = (e) => {
    let locale = e.target.value;
    setLocaleSelect(locale);
    setAreaSelect("All");
    if (locale !== "All")
      createZoneFilterList(
        zoneList,
        sectionSelect,
        locale,
        setSectionList,
        setLocaleList,
        setAreaList
      );
  };
  return (
    <SearchBar search={search} setSearch={setSearch} placeholder="Type in Name">
      <div className="searchBar5Fields">
        <MenuTextField
          state={genderSelect}
          setState={setGenderSelect}
          menuSelection={genderOptions}
        />
      </div>
      <div className="searchBar5Fields">
        <MenuTextField
          state={sectionSelect}
          setState={setSectionSelect}
          menuSelection={sectionList}
          customFunction={handleSectionalChange}
        />
      </div>
      <div className="searchBar5Fields">
        <MenuTextField
          state={localeSelect}
          setState={setLocaleSelect}
          menuSelection={localeList}
          customFunction={handleLocaleChange}
          disabled={sectionSelect === "All"}
        />
      </div>
      <div className="searchBar5Fields">
        <MenuTextField
          state={areaSelect}
          setState={setAreaSelect}
          menuSelection={areaList}
          disabled={localeSelect === "All" || sectionSelect === "All"}
        />
      </div>
      <div className="searchBar5Fields">
        <DropdownCheckbox
          checkboxGroupName="Level"
          checkboxes={levelSelect}
          setCheckboxes={setLevelSelect}
        />
      </div>
      <div className="filler" />
      <div className="searchBarClearField">
        <Button variant="text" onClick={handleFilterReset}>
          <Typography className="leagueSearchBarClearText">
            Clear All
          </Typography>
        </Button>
      </div>
    </SearchBar>
  );
};
export default LeaderboardSearchBar;

import React, { useState, useEffect, Fragment } from "react";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import Loader from "react-loader-spinner";
import { firestore } from "../../../firebase";
import { simpleEmail } from "../../../functions/Messaging";
import {
  MenuTextField,
  DateTextField,
} from "../../../components/CustomTextField";

const surfaceList = [
  { label: "Clay", value: "Clay" },
  { label: "Grass", value: "Grass" },
  { label: "Hard", value: "Hard" },
  { label: "Carpet", value: "Carpet" },
];

const winnerSelect = [
  "Completed Match",
  "Player Retired",
  "Player No-Show",
  "Player Walkover",
  "Match Cancelled",
];

const CreateScoreForPlayers = ({ setOpen, selectedTrack }) => {
  const { league, division, track } = selectedTrack;
  const [submitting, setSubmitting] = useState(false);
  const [enableResultSelection, setEnableResultSelection] = useState(false);
  const [matchDate, setMatchDate] = useState(moment().format("MM/DD/YYYY"));
  const [matchDateObj, setMatchDateObj] = useState(moment());
  const [surfaceType, setSurfaceType] = useState("Clay");
  const [participantSelect, setParticipantSelect] = useState([
    { label: "Select Opponent", value: "placeholder" },
  ]);
  const [winner, setWinner] = useState("placeholder");
  const [loser, setLoser] = useState("placeholder");
  const [result, setResult] = useState("Completed Match");
  const [scoringFormat, setScoringFormat] = useState("2/3Sets");
  const [setOneScore1, setSetOneScore1] = useState("");
  const [setOneScore2, setSetOneScore2] = useState("");
  const [setTwoScore1, setSetTwoScore1] = useState("");
  const [setTwoScore2, setSetTwoScore2] = useState("");
  const [tieBreaker1, setTieBreaker1] = useState("0");
  const [tieBreaker2, setTieBreaker2] = useState("0");
  const [tenGameScore1, setTenGameScore1] = useState("");
  const [tenGameScore2, setTenGameScore2] = useState("");
  const [retired, setRetired] = useState(false);
  const [noShow, setNoShow] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [cancelledReason, setCancelledReason] = useState("");
  const [error, setError] = useState({ visible: false, message: "" });
  const [winnerValuePlaceholder, setWinnerValuePlaceholder] =
    useState("0 (Your Score)");
  const [loserValuePlaceholder, setLoserValuePlaceholder] =
    useState("0 (Opponent Score)");
  useEffect(() => {
    setParticipantSelect([
      { label: "Select Opponent", value: "placeholder" },
      ...(division.doublesFormat
        ? selectedTrack.teams.map((team) => ({
            label: team.teamName,
            value: team,
          }))
        : selectedTrack.players.map((player) => ({
            label: player.name,
            value: player,
          }))),
    ]);
  }, [selectedTrack, division.doublesFormat]);
  const handleClose = () => {
    setMatchDate(moment().format("MM/DD/YYYY"));
    setMatchDateObj(moment());
    setSurfaceType("Clay");
    setParticipantSelect([{ label: "Select Opponent", value: "placeholder" }]);
    setWinner("placeholder");
    setLoser("placeholder");
    setResult("Completed Match");
    setWinnerValuePlaceholder("0 (Your Score)");
    setLoserValuePlaceholder("0 (Opponent Score)");
    setScoringFormat("2/3Sets");
    setSetOneScore1("");
    setSetOneScore2("");
    setSetTwoScore1("");
    setSetTwoScore2("");
    setTieBreaker1("0");
    setTieBreaker2("0");
    setTenGameScore1("");
    setTenGameScore2("");
    setEnableResultSelection(false);
    setRetired(false);
    setNoShow(false);
    setCancelled(false);
    setCancelledReason("");
    setError({ visible: false, message: "" });
    setOpen(false);
    setSubmitting(false);
  };
  const handleWinnerSelect = (e) => {
    const winner = e.target.value;
    setWinnerValuePlaceholder(createScorePlaceholder(winner));
    setWinner(winner);
    setEnableResultSelection(loser !== "placeholder");
  };
  const handleLoserSelect = (e) => {
    const loser = e.target.value;
    setLoserValuePlaceholder(createScorePlaceholder(loser));
    setLoser(loser);
    setEnableResultSelection(winner !== "placeholder");
  };
  const createScorePlaceholder = (player) => {
    if (!division.doublesFormat) return `0 (${player.name}'s Score)`;
    return `0 (${player.teamName}'s Score)`;
  };
  const handleResultSelect = (e) => {
    const result = e.target.value;
    setResult(result);
    if (result === "Completed Match") {
      setCancelled(false);
      setRetired(false);
      setNoShow(false);
    } else if (result === "Player Retired") {
      setRetired(true);
      setCancelled(false);
      setNoShow(false);
    } else if (result === "Player No-Show") {
      setNoShow(true);
      setCancelled(false);
      setRetired(false);
    } else if (result === "Player Walkover") {
      setNoShow(true);
      setCancelled(false);
      setRetired(false);
    } else {
      setCancelled(true);
      setRetired(false);
      setNoShow(false);
    }
  };
  const handleScoringFormatSelect = (e) => {
    setScoringFormat(e.target.value);
    if (
      e.target.value === "2SetsWithTieBreaker" &&
      tieBreaker1 === "0" &&
      tieBreaker2 === "0"
    ) {
      setTieBreaker1("");
      setTieBreaker2("");
    } else if (
      e.target.value === "2/3Sets" &&
      tieBreaker1 === "" &&
      tieBreaker2 === ""
    ) {
      setTieBreaker1("0");
      setTieBreaker2("0");
    }
  };
  const createSinglesEmailParams = () => {
    const emailList = `${winner.email}, ${loser.email}`;
    const winnerName = winner.name;
    const loserName = loser.name;
    return { emailList, winnerName, loserName };
  };
  const createDoublesEmailParams = () => {
    const emailList = `${winner.players[0].email}, ${winner.players[1].email}, ${loser.players[0].email}, ${loser.players[1].email}`;
    const winnerName = winner.teamName;
    const loserName = loser.teamName;
    return { emailList, winnerName, loserName };
  };
  const sendMessage = async () => {
    const { winnerName, loserName, emailList } = division.doublesFormat
      ? createDoublesEmailParams()
      : createSinglesEmailParams();
    const result =
      scoringFormat === "10GamePro"
        ? `(${tenGameScore1} - ${tenGameScore2})`
        : `(${setOneScore1} - ${setOneScore2}, ${setTwoScore1} - ${setTwoScore2}, ${tieBreaker1} - ${tieBreaker2})`;
    const subject = `Game.Set.Match – ${winnerName} wins over ${loserName}; Score ${result}`;
    const message = `On ${matchDate}, ${winnerName} wins over ${loserName} with a score of ${result} playing on ${surfaceType}. This is ${division.division} for ${league.name}. Congratulations to ${winnerName}. If this is incorrect, please email your league director within the next 24 hours...  - Game.Set.Match`;
    await simpleEmail({
      message: message,
      subject: subject,
      recipients: emailList,
    });
  };
  const scoreAlreadyReported = () => {
    const filteredScores = selectedTrack.scores.filter(
      (score) =>
        score.matchDate === matchDate &&
        (score.winner === loser.id || score.loser === loser.id) &&
        (score.winner === winner.id || score.loser === winner.id)
    );
    return filteredScores.length > 0;
  };
  const getMatchScheduleID = () => {
    const match = selectedTrack.matches.find(
      (schedule) =>
        schedule.participants.includes(winner.id) &&
        schedule.participants.includes(loser.id)
    );
    if (match === undefined) return "N/A";
    return match.id;
  };
  const formVerified = () => {
    if (winner === "placeholder" || loser === "placeholder") return false;
    return true;
  };
  const uploadScore = async (e) => {
    e.preventDefault();
    if (!formVerified()) return;
    setSubmitting(true);
    const scoreReportedToday = scoreAlreadyReported();
    if (scoreReportedToday && !error.visible) {
      setError({
        visible: true,
        message:
          "A score against these opponents was reported for this same day. Are you sure you want to report another one?",
      });
      setSubmitting(false);
      return;
    }
    if (winner.id === loser.id) {
      setError({
        visible: true,
        message:
          "You have choosen the same player as the winner/loser. Make sure these are different.",
      });
      setSubmitting(false);
      return;
    }
    const matchID = getMatchScheduleID();
    if (matchID !== "N/A")
      await firestore
        .collection("matchSchedules")
        .doc(matchID)
        .update({ status: "played" });
    await firestore.collection("scores").add({
      matchID: matchID,
      leagueID: league.id,
      divisionID: division.id,
      trackID: track.id,
      matchDate: matchDate,
      date: moment(matchDate, "MM/DD/YYYY").toDate(),
      surface: surfaceType,
      scoringFormat: scoringFormat,
      setOneScore: [setOneScore1, setOneScore2],
      setTwoScore: [setTwoScore1, setTwoScore2],
      setThreeScore: [tieBreaker1, tieBreaker2],
      proSetScore: [tenGameScore1, tenGameScore2],
      winner: winner.id,
      loser: loser.id,
      retired: retired,
      cancelled: cancelled,
      cancelledReason: cancelledReason,
      noShow: noShow,
      doublesFormat: division.doublesFormat,
      scoreType:
        league.status === "playoffs" ? "playoffScore" : "standardScore",
    });
    await sendMessage();
    handleClose();
    setSubmitting(false);
  };
  return (
    <form onSubmit={(e) => uploadScore(e)}>
      <div className="scoreUploadFieldWrapper">
        <Typography className="scoreUploadFieldText margin10Top">
          Match Date
        </Typography>
        <DateTextField
          date={matchDateObj}
          setDate={setMatchDateObj}
          dateString={matchDate}
          setDateString={setMatchDate}
          required={true}
        />
        <Typography className="scoreUploadFieldText margin10Top">
          Court Type
        </Typography>
        <MenuTextField
          state={surfaceType}
          setState={setSurfaceType}
          required={true}
          menuSelection={surfaceList}
        />
        <div className="rowCenter margin30Top">
          <div className="column widthThird margin10Right">
            <Typography className="scoreUploadFieldText">Winner</Typography>
            <TextField
              required={true}
              value={winner}
              select
              onChange={handleWinnerSelect}
            >
              {participantSelect.map((o, i) => (
                <MenuItem key={i} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="column widthThird margin10Right">
            <Typography className="scoreUploadFieldText">Loser</Typography>
            <TextField
              required={true}
              value={loser}
              select
              onChange={handleLoserSelect}
            >
              {participantSelect.map((o, i) => (
                <MenuItem key={i} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="column widthThird margin10Left">
            <Typography className="scoreUploadFieldText">Result</Typography>
            <TextField
              disabled={!enableResultSelection}
              value={result}
              select
              required
              placeholder="Completed Match"
              onChange={handleResultSelect}
            >
              {winnerSelect.map((w, i) => (
                <MenuItem key={i} value={w}>
                  {w}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
        <Typography className="scoreUploadFieldText margin30Top">
          Scoring Format
        </Typography>
        <RadioGroup
          className="rowCenter"
          value={scoringFormat}
          onChange={handleScoringFormatSelect}
        >
          <FormControlLabel
            className="scoreUploadRadioText"
            value="2/3Sets"
            control={<Radio color="default" />}
            label="2 out of 3 Sets"
            labelPlacement="end"
          />
          <FormControlLabel
            value="2SetsWithTieBreaker"
            className="scoreUploadRadioText"
            control={<Radio color="default" />}
            label="3 Set Tiebreaker"
            labelPlacement="end"
          />
          <FormControlLabel
            value="10GamePro"
            className="scoreUploadRadioText"
            control={<Radio color="default" />}
            label="10 Game Pro Set"
            labelPlacement="end"
          />
        </RadioGroup>
        {scoringFormat === "2/3Sets" && (
          <Fragment>
            <Typography className="scoreUploadFieldText margin10Top">
              Set 1
            </Typography>
            <div className="rowCenter">
              <div className="column widthHalf margin10Right">
                <TextField
                  value={setOneScore1}
                  required
                  type="number"
                  onChange={(e) => {
                    setSetOneScore1(e.target.value);
                  }}
                  placeholder={winnerValuePlaceholder}
                />
              </div>
              <div className="column widthHalf margin10Left">
                <TextField
                  value={setOneScore2}
                  required
                  type="number"
                  onChange={(e) => {
                    setSetOneScore2(e.target.value);
                  }}
                  placeholder={loserValuePlaceholder}
                />
              </div>
            </div>
            <Typography className="scoreUploadFieldText margin10Top">
              Set 2
            </Typography>
            <div className="rowCenter">
              <div className="column widthHalf margin10Right">
                <TextField
                  value={setTwoScore1}
                  required
                  type="number"
                  onChange={(e) => {
                    setSetTwoScore1(e.target.value);
                  }}
                  placeholder={winnerValuePlaceholder}
                />
              </div>
              <div className="column widthHalf margin10Left">
                <TextField
                  value={setTwoScore2}
                  required
                  type="number"
                  onChange={(e) => {
                    setSetTwoScore2(e.target.value);
                  }}
                  placeholder={loserValuePlaceholder}
                />
              </div>
            </div>
            <Typography className="scoreUploadFieldText margin10Top">
              Set 3 (Optional)
            </Typography>
            <div className="rowCenter">
              <div className="column widthHalf margin10Right">
                <TextField
                  value={tieBreaker1}
                  required
                  type="number"
                  onChange={(e) => {
                    setTieBreaker1(e.target.value);
                  }}
                  placeholder={winnerValuePlaceholder}
                />
              </div>
              <div className="column widthHalf margin10Left">
                <TextField
                  value={tieBreaker2}
                  required
                  type="number"
                  onChange={(e) => {
                    setTieBreaker2(e.target.value);
                  }}
                  placeholder={loserValuePlaceholder}
                />
              </div>
            </div>
          </Fragment>
        )}
        {scoringFormat === "2SetsWithTieBreaker" && (
          <Fragment>
            <Typography className="scoreUploadFieldText margin10Top">
              Set 1
            </Typography>
            <div className="rowCenter">
              <div className="column widthHalf margin10Right">
                <TextField
                  value={setOneScore1}
                  required
                  type="number"
                  onChange={(e) => {
                    setSetOneScore1(e.target.value);
                  }}
                  placeholder={winnerValuePlaceholder}
                />
              </div>
              <div className="column widthHalf margin10Left">
                <TextField
                  value={setOneScore2}
                  required
                  type="number"
                  onChange={(e) => {
                    setSetOneScore2(e.target.value);
                  }}
                  placeholder={loserValuePlaceholder}
                />
              </div>
            </div>
            <Typography className="scoreUploadFieldText margin10Top">
              Set 2
            </Typography>
            <div className="rowCenter">
              <div className="column widthHalf margin10Right">
                <TextField
                  value={setTwoScore1}
                  required
                  type="number"
                  onChange={(e) => {
                    setSetTwoScore1(e.target.value);
                  }}
                  placeholder={winnerValuePlaceholder}
                />
              </div>
              <div className="column widthHalf margin10Left">
                <TextField
                  value={setTwoScore2}
                  required
                  type="number"
                  onChange={(e) => {
                    setSetTwoScore2(e.target.value);
                  }}
                  placeholder={loserValuePlaceholder}
                />
              </div>
            </div>
            <Typography className="scoreUploadFieldText margin10Top">
              TieBreaker
            </Typography>
            <div className="rowCenter">
              <div className="column widthHalf margin10Right">
                <TextField
                  value={tieBreaker1}
                  required
                  type="number"
                  onChange={(e) => {
                    setTieBreaker1(e.target.value);
                  }}
                  placeholder={winnerValuePlaceholder}
                />
              </div>
              <div className="column widthHalf margin10Left">
                <TextField
                  value={tieBreaker2}
                  required
                  type="number"
                  onChange={(e) => {
                    setTieBreaker2(e.target.value);
                  }}
                  placeholder={loserValuePlaceholder}
                />
              </div>
            </div>
          </Fragment>
        )}
        {scoringFormat === "10GamePro" && (
          <Fragment>
            <Typography className="scoreUploadFieldText margin10Top">
              Pro Set
            </Typography>
            <div className="rowCenter">
              <div className="column widthHalf margin10Right">
                <TextField
                  value={tenGameScore1}
                  required
                  type="number"
                  onChange={(e) => {
                    setTenGameScore1(e.target.value);
                  }}
                  placeholder={winnerValuePlaceholder}
                />
              </div>
              <div className="column widthHalf margin10Left">
                <TextField
                  value={tenGameScore2}
                  required
                  type="number"
                  onChange={(e) => {
                    setTenGameScore2(e.target.value);
                  }}
                  placeholder={loserValuePlaceholder}
                />
              </div>
            </div>
          </Fragment>
        )}
        {cancelled && (
          <Fragment>
            <Typography className="scoreUploadFieldText margin10Top">
              Cancellation Reason
            </Typography>
            <TextField
              required
              fullWidth={true}
              multiline
              rows={3}
              value={cancelledReason}
              onChange={(e) => {
                setCancelledReason(e.target.value);
              }}
            />
          </Fragment>
        )}
        <div className="margin10Top" />
      </div>
      <div className="maxWidth">
        <Divider className="margin20Top" />
      </div>
      <div className="heightPlaceholder40">
        {error.visible && (
          <Typography variant="subtitle2" className="err">
            {error.message}
          </Typography>
        )}
      </div>
      <div className="rowCenter">
        <Button variant="text" onClick={handleClose}>
          <Typography className="cancelButtonText">Back</Typography>
        </Button>
        <div className="filler" />
        <Button
          className="purchaseButton"
          variant="outlined"
          type="submit"
          disabled={submitting}
        >
          {submitting ? (
            <Loader
              type="TailSpin"
              color="#02c39a"
              height={25}
              width={25}
              timeout={8000}
            />
          ) : (
            <Typography className="purchaseButtonText">
              {error.visible ? "Submit Again" : "Submit"}
            </Typography>
          )}
        </Button>
      </div>
    </form>
  );
};
export default CreateScoreForPlayers;

import React, { useState, useEffect, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Loader from "react-loader-spinner";
import parsePhoneNumber from "libphonenumber-js";
import { CustomTabs } from "../../../components/CustomTabs";
import ManageParticipant from "./ManageParticipant";
import TableComponent from "../../../components/table/TableComponent";
const DirectorEventParticipants = ({
  league,
  status,
  pageLabels,
  tracks,
  divisions,
  quantity,
}) => {
  const [pageNum, setPageNum] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const canEdit = status !== "expired";
  const [selectedPlayer, setSelectedPlayer] = useState(undefined);
  const [selectedTrack, setSelectedTrack] = useState(undefined);
  const [players, setPlayers] = useState([]);
  const singlesHeader = [
    { label: "participantName", content: "Participant", sortable: true },
    { label: "quantity", content: "Ticket(s)", sortable: true },
    { label: "email", content: "Email", sortable: true },
    { label: "phone", content: "Phone Number", sortable: true },
  ];
  const createPlayersTable = (
    cells,
    player,
    email,
    phone,
    rank,
    participantName
  ) => {
    return {
      cells,
      player,
      email,
      phone,
      rank,
      playerName: participantName,
    };
  };
  useEffect(() => {
    setPlayers(
      tracks.map((track) => {
        return track.players.map((player,key) => {
          const pName = `${player.firstName} ${player.lastName}${
            player.withdrawn ? " - withdrawn" : ""
          }`;
          const phone = parsePhoneNumber(
            player.phoneNumber,
            "US"
          ).formatNational();
          return createPlayersTable(
            [
              { content: pName, editable: false },
              { content: quantity[key], editable: false },
              { content: player.email, editable: false },
              { content: phone, editable: false },
            ],
            player,
            quantity[key],
            player.email,
            phone,
            pName
          );
        });
      })
    );
    setLoading(false);
  }, [league, tracks, quantity]);
  const handleComponentEdit = (rowSelected) => {
    setSelectedPlayer(rowSelected.player);
    setSelectedTrack(tracks[pageNum].track.id);
    setOpenEdit(true);
  };

  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  return (
    <div className="column filler">
      <ManageParticipant
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        selectedPlayer={selectedPlayer}
        selectedTrack={selectedTrack}
        tracks={tracks}
      />
      <div className="rowCenter">
        <div className="tabsWidthWrapper">
          <CustomTabs
            labels={pageLabels}
            value={pageNum}
            setValue={setPageNum}
          />
        </div>
        <div className="filler" />
      </div>
      <div className="column filler">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {players.map((playerList, key) => (
              <Fragment key={key}>
                {key === pageNum && (
                  <TableComponent
                    list={playerList}
                    nestedList={players}
                    setList={setPlayers}
                    tableHeader={singlesHeader}
                    type={canEdit ? "componentEdit" : "viewOnly"}
                    handleComponentEdit={handleComponentEdit}
                    rowsPerPageOverride={10}
                    tableNum={pageNum}
                  />
                )}
              </Fragment>
            ))}
          </Grid>
        </Grid>
        <div className="filler" />
      </div>
    </div>
  );
};

export default DirectorEventParticipants;
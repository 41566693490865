import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import TableComponent from "../../../components/table/TableComponent";
import RewardClaimedEditForm from "./RewardClaimedEditForm";

const statusSelect = ["All", "new", "shipping", "complete"];

const RewardsClaimed = ({ rewardsClaimedTable, setRewardsClaimedTable }) => {
  const rewardsClaimedHeader = [
    "Date",
    "Item",
    "Units",
    "Points",
    "Status",
  ].map((title) => {
    let labelTemp = title.toLowerCase();
    return {
      label: labelTemp,
      content: title,
      sortable: true,
    };
  });
  const [rewardClaimedSelected, setRewardClaimedSelected] = useState(null);
  const [isEditingRewardClaimed, setIsEditingRewardClaimed] = useState(false);
  const [openRewardClaimed, setOpenRewardClaimed] = useState(false);
  const [status, setStatus] = useState("All");
  const [rewardClaimedName, setRewardClaimedName] = useState("");
  const handleComponentEditRewardClaimed = (selectedRewardClaimed) => {
    setRewardClaimedSelected(selectedRewardClaimed);
    setIsEditingRewardClaimed(true);
    setOpenRewardClaimed(true);
  };
  const itemNameFilter = (item) => {
    if (rewardClaimedName === "") return true;
    return item.item.indexOf(rewardClaimedName) === 0;
  };
  const statusFilter = (item) => {
    if (status === "All") return true;
    return item.status === status;
  };
  return (
    <Grid item xs={12}>
      <div className="rowCenter">
        <RewardClaimedEditForm
          rewardClaimed={rewardClaimedSelected}
          open={openRewardClaimed}
          setOpen={setOpenRewardClaimed}
          isEditing={isEditingRewardClaimed}
        />
        <Grid container spacing={6}>
          <Grid className="rowCenter" item xs={12} md={6} lg={6} xl={4}>
            <TextField
              className="maxWidth"
              select
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              label="Status:"
            >
              {statusSelect.map((s, key) => (
                <MenuItem value={s} key={key}>
                  {s}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid className="rowCenter" item xs={12} md={6} lg={6} xl={4}>
            <TextField
              className="maxWidth"
              value={rewardClaimedName}
              onChange={(e) => {
                setRewardClaimedName(e.target.value);
              }}
              label="Item Name:"
            />
          </Grid>
        </Grid>
      </div>
      <TableComponent
        list={rewardsClaimedTable
          .filter((rewardClaimed) => statusFilter(rewardClaimed))
          .filter((rewardClaimed) => itemNameFilter(rewardClaimed))}
        setList={setRewardsClaimedTable}
        tableHeader={rewardsClaimedHeader}
        handleComponentEdit={handleComponentEditRewardClaimed}
        type="componentEdit"
        rowsPerPageOverride={10}
      />
    </Grid>
  );
};
export default RewardsClaimed;

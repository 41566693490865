import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {
  DefaultTextField,
} from "../../../components/CustomTextField";

const formatSelect = [
  "Celebrity Appearance",
  "Exhibition",
  "Meeting",
  "Networking Event",
  "Retreat",
  "Clinics",
  "Rally",
  "Conference",
  "Vendor Presentations",
  "Other"
  ];

const PageThree = ({
  format,
  setFormat,
  tag,
  setTag,
  social,
  setSocial,
}) => {

  // const rulesSelect = [
  //   { label: "Standard Rules", value: "Standard Rules" },
  //   { label: "Custom Rules", value: "Custom Rules" },
  // ];
  return (
    <Fragment>
      <Typography className="createLeagueFieldText">
        Event Type
      </Typography>
      <TextField
        select
        required
        value={format}
        onChange={(e) => {
          setFormat(e.target.value);
        }}
        
      >
        {formatSelect.map((s, i) => (
          <MenuItem key={i} value={s}>
            {s}
          </MenuItem>
        ))}
      </TextField>
      <Typography className="createLeagueFieldText margin20Top">
        Tags
      </Typography>
      <DefaultTextField
        state={tag}
        setState={setTag}
        placeholder="Event Tag"
      />
      <Typography className="createLeagueFieldText margin20Top">
        Social Media
      </Typography>
      <DefaultTextField
        state={social}
        setState={setSocial}
        placeholder="Social media Tag"
      />
    </Fragment>
  );
};

export default PageThree;

import React, { useState, useMemo, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AppBar from "@material-ui/core/AppBar";
import Card from "@material-ui/core/Card";
import Loader from "react-loader-spinner";
import { GSMIcon } from "../../res/SVGIcons";
import { auth, firestore, Firebase, storage } from "../../firebase";
import parsePhoneNumber from "libphonenumber-js";
import xlsxParser from "xlsx-parse-json";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import {
  DefaultTextField,
  PasswordTextField,
  PhoneTextField,
} from "../../components/CustomTextField";
import { GSMRatingSlider } from "../../components/CustomSliders";
import { InfoPopover } from "./InfoPopover";
import CheckboxList from "../../components/CheckboxList";

const SignUp = () => {
  const [redirect, setRedirect] = useState(false);
  const [email, setEmail] = useState("");
  const [switchPickleBall, setSwitchPickleBall] = useState(true)
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("male");
  const [zipCode, setZipCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [customRating, setCustomRating] = useState(10);
  const [customRatingForPickleBall, setCustomRatingForPickleBall] =
  useState(10);
  const [ntrpRating, setNtrpRating] = useState(1.5);
  const [pbNtrpRating, setPbNtrpRating] = useState(1.5);
  const [interestedIn, setInterestedIn] = useState([{ label: "Tennis", check: true }]);
  const [selectedSkillLevel, setSelectedSkillLevel] = useState("Beginner");
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [sportError, setSportError] = useState(false);
  const [selectedSkillLevelForPickleball, setSelectedSkillLevelForPickleball] =
  useState("Beginner");
  const skillLevels = [
    "Beginner",
    "Beginner",
    "Beginner",
    "Beginner",
    "Novice",
    "Intermediate",
    "Advanced",
    "Advanced",
    "Competitive/D1",
    "Competitive/D1",
    "Club-Level Pro",
    "Club-Level Pro",
    "Circuit Level Pro",
    "Circuit Level Pro",
    "World Class",
  ];
  
  const pickleballSkillLevels = [
    "Beginner",
    "Beginner",
    "Beginner",
    "Novice",
    "Intermediate",
    "Advanced",
    "Competitive/D1",
    "Club-Level Pro",
    "Circuit Level Pro",
    "World Class",
    "World Class",
    "World Class",
    "World Class",
    "World Class",
    "World Class",
  ];

  useEffect(() => {
    const switchCase = firestore.collection("admin").doc("siteValues").onSnapshot(doc => {
      setSwitchPickleBall(doc.data().switchPickleball);
    });
    return () => {
      switchCase();
    };
  }, [])

  useEffect(() => {
    if (switchPickleBall) {
      setInterestedIn([{ label: "Tennis", check: true }, { label: "Pickle Ball", check: false }])
    } else {
      setInterestedIn([{ label: "Tennis", check: true }])
    }
  }, [switchPickleBall])

  useMemo(() => {
    setSelectedSkillLevel(skillLevels[(customRating * 2) / 10 - 2]);
    let calcNtrp = customRating;
    if (calcNtrp < 15) calcNtrp = 15;
    else if (calcNtrp > 70) calcNtrp = 70;
    calcNtrp /= 10;
    setNtrpRating(calcNtrp);
    // eslint-disable-next-line
  }, [customRating]);

  useMemo(() => {
    setSelectedSkillLevelForPickleball(
      pickleballSkillLevels[(customRatingForPickleBall * 2) / 10 - 2]
    );
    let calcNtrp = customRatingForPickleBall;
    if (calcNtrp < 15) calcNtrp = 15;
    else if (calcNtrp > 70) calcNtrp = 70;
    calcNtrp /= 10;
    setPbNtrpRating(calcNtrp);
    // eslint-disable-next-line
  }, [customRatingForPickleBall]);
  if (redirect) {
    return <Redirect to={{ pathname: "/Dashboard" }}></Redirect>;
  }
  const location = window.location;
  const urlObject = {
    search: location.search,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!interestedIn[0].check && !interestedIn[1]?.check) {
      setSportError(true);
      return;
    }
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      setPasswordError(true);
      setEmailError(false);
      return;
    } else {
      setSportError(false);
      setLoading(true);
      await auth
        .createUserWithEmailAndPassword(email, password)
        .then(async (response) => {
          const { user } = response;
          let imageURL = "";
          await storage
            .ref()
            .child(
              `adminDocuments/user/${gender === "male"
                ? "maleProfileImage.jpeg"
                : "femaleProfileImage.jpeg"
              }`
            )
            .getDownloadURL()
            .then((url) => {
              imageURL = url;
            });
          let playLocation = {};
          await storage
            .ref()
            .child(`adminDocuments/zipmapping.xlsx`)
            .getDownloadURL()
            .then(async (url) => {
              let file = null;
              await fetch(url)
                .then((res) => res.blob())
                .then((blob) => {
                  file = blob;
                });
              await xlsxParser.onFileSelection(file).then((zipMap) => {
                let found = false;
                zipMap["Zipcode-Area Map"].forEach((location) => {
                  if (location.zipCode === zipCode) {
                    playLocation = location;
                    found = true;
                  }
                });
                if (!found) {
                  playLocation = zipMap["Zipcode-Area Map"].find(
                    (location) => location.areaID === "OT-0001"
                  );
                  playLocation.zipCode = zipCode;
                }
                if (playLocation.description === undefined)
                  playLocation.description = "";
              });
            });
          let fName = firstName.replaceAll(/[^A-Za-z]/g, "");
          let lName = lastName.replaceAll(/[^A-Za-z]/g, "");
          await firestore
            .collection("users")
            .doc(user.uid)
            .set({
              activeCampaignID: -1,
              email: email,
              firstName: fName,
              lastName: lName,
              fullName: fName.toLowerCase() + lName.toLowerCase(),
              imageURL: imageURL,
              court: "No Home Court",
              phoneNumber: parsePhoneNumber(phoneNumber, "US").number,
              homeAddress: {
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: "",
                zipCode: zipCode,
              },
              ntrpRating: interestedIn[0].check ? ntrpRating : 1.5,
              pb_utprRating: interestedIn[1] && interestedIn[1].check ? pbNtrpRating : 1.5,
              utrRating: 0,
              playLocation: playLocation,
              playAreaID: playLocation.areaID,
              playLocale: playLocation.locale,
              playSectionals: playLocation.sectionals,
              admin: false,
              director: false,
              active: true,
              verified: false,
              dateCreated: Firebase.firestore.FieldValue.serverTimestamp(),
              leaguesJoined: [],
              pb_leaguesJoined: [],
              pb_totalMatches: 0,
              pb_totalMatchWins: 0,
              pb_totalMatchLosses: 0,
              pb_totalGameWins: 0,
              pb_totalGameLosses: 0,
              pb_totalSetWins: 0,
              pb_totalSetLosses: 0,
              pb_totalMatchesAbandoned: 0,
              pb_totalDoublesMatches: 0,
              pb_totalDoublesMatchWins: 0,
              pb_totalDoublesMatchLosses: 0,
              pb_totalDoublesSetWins: 0,
              pb_totalDoublesSetLosses: 0,
              pb_totalDoublesGameWins: 0,
              pb_totalDoublesGameLosses: 0,
              pb_totalDoublesMatchesAbandoned: 0,
              totalMatches: 0,
              totalMatchWins: 0,
              totalMatchLosses: 0,
              totalGameWins: 0,
              totalGameLosses: 0,
              totalSetWins: 0,
              totalSetLosses: 0,
              totalMatchesAbandoned: 0,
              totalDoublesMatches: 0,
              totalDoublesMatchWins: 0,
              totalDoublesMatchLosses: 0,
              totalDoublesSetWins: 0,
              totalDoublesSetLosses: 0,
              totalDoublesGameWins: 0,
              totalDoublesGameLosses: 0,
              totalDoublesMatchesAbandoned: 0,
              doublesSearch: true,
              uploadedImage: false,
              completedProfile: false,
              completedPreferences: false,
              completedInformation: false,
              currentSport:
                interestedIn[1] && interestedIn[1].check && !interestedIn[0].check
                  ? "pickleball"
                  : "tennis",
              matchPoints: 0,
              pointsUsed: 0,
              gsmRating: interestedIn[0].check ? customRating / 10 : 10 / 10,
              pb_gsmRating: interestedIn[1] && interestedIn[1].check
                ? customRatingForPickleBall / 10
                : 10 / 10,
              gsmRatingDoubles: interestedIn[0].check
                ? customRating / 10
                : 10 / 10,
              pb_gsmRatingDoubles: interestedIn[1] && interestedIn[1].check
                ? customRatingForPickleBall / 10
                : 10 / 10,
              couponsUsed: [],
              isBothSportCheckout:
                interestedIn[0].check && interestedIn[1] && interestedIn[1].check || false,
              referrals: [],
              gender: gender,
              surface: "No Preference",
              birthday: null,
              dayPref: [
                { label: "Monday", check: false },
                { label: "Tuesday", check: false },
                { label: "Wednesday", check: false },
                { label: "Thursday", check: false },
                { label: "Friday", check: false },
                { label: "Saturday", check: false },
                { label: "Sunday", check: false },
              ],
              timePref: [
                { label: "Early Morning", check: false },
                { label: "Morning", check: false },
                { label: "Afternoon", check: false },
                { label: "Evening", check: false },
              ],
            });
          await auth
            .signInWithEmailAndPassword(email, password)
            .then((userAuth) => {
              setRedirect(true);
            });
          if (urlObject.search) {
            const searchParams = new URLSearchParams();
            searchParams.set('uncertified-user', true);
            window.location.replace(
              `/VerifyUser?${searchParams.toString()}`
            );
          }
        })
        .catch((error) => {
          setError(error.message);
          if (error.code === "auth/weak-password") {
            setPasswordError(true);
            setEmailError(false);
          } else {
            setEmailError(true);
            setPasswordError(false);
          }
        });
      setLoading(false);
    }
  };

  if (redirectToLogin) return <Redirect to={{ pathname: "/Landing" }} />;

  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  return (
    <div className="pageSignUp">
      <AppBar elevation={2} className="appBar">
        <GSMIcon />
      </AppBar>
      <Typography className="signUpText">Create your Account</Typography>
      <Card className="signUpInnerDiv">
        <Button variant="text" onClick={() => setRedirectToLogin(true)}>
          <div className="rowCenter">
            <ArrowBackIos className="createLeagueBackButtonIcon margin10Right" />
            <Typography className="createLeagueUtilityButtonText">
              Back to Login
            </Typography>
          </div>
        </Button>
        <br></br>
        <form className="columnCenter" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <Typography className="signUpSubtitleText">First Name</Typography>
              <DefaultTextField
                state={firstName}
                setState={setFirstName}
                required={true}
                autoComplete="firstname"
                placeholder="First Name"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <Typography className="signUpSubtitleText">Last Name</Typography>
              <DefaultTextField
                state={lastName}
                setState={setLastName}
                required={true}
                autoComplete="lastname"
                placeholder="Last Name"
              />
            </Grid>
            <Grid className="margin10LessTop" item xs={12}>
              <Typography className="signUpSubtitleText">Email</Typography>
              <DefaultTextField
                state={email}
                setState={setEmail}
                required={false}
                placeholder="Email"
              />
              <div className="heightPlaceholder">
                {emailError && (
                  <Typography className="signUpErrorText">{error}</Typography>
                )}
              </div>
            </Grid>
            <Grid className="margin30LessTop" item xs={12} md={6} lg={6} xl={6}>
              <Typography className="signUpSubtitleText">Password</Typography>
              <PasswordTextField
                state={password}
                setState={setPassword}
                required={true}
                placeholder="Password"
              />
            </Grid>
            <Grid
              className="signUpResponsiveField"
              item
              xs={12}
              md={6}
              lg={6}
              xl={6}
            >
              <Typography className="signUpSubtitleText">
                Confirm Password
              </Typography>
              <PasswordTextField
                state={confirmPassword}
                setState={setConfirmPassword}
                required={true}
                placeholder="Confirm Password"
              />
            </Grid>
            <Grid className="margin20Top" item xs={12} md={6} lg={6} xl={6}>
              <Typography className="signUpSubtitleText">Zip Code</Typography>
              <DefaultTextField
                state={zipCode}
                setState={setZipCode}
                required={true}
                placeholder="ZIP Code"
              />
            </Grid>
            <Grid className="margin20Top" item xs={12} md={6} lg={6} xl={6}>
              <Typography className="signUpSubtitleText">
                Phone Number
              </Typography>
              <PhoneTextField
                state={phoneNumber}
                setState={setPhoneNumber}
                required={true}
                placeholder="Phone Number"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <Typography className="signUpSubtitleText">Gender</Typography>
              <FormControl className="rowCenter">
                <RadioGroup
                  className="rowCenter"
                  value={gender}
                  onChange={(e) => {
                    setGender(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio color="default" />}
                    label="Female"
                  />
                  <div className="filler" />
                  <FormControlLabel
                    value="male"
                    control={<Radio color="default" />}
                    label="Male"
                  />
                </RadioGroup>
              </FormControl>
              <Typography className="signUpSubtitleText">
                Interested in
              </Typography>
              <CheckboxList value={interestedIn} setValue={setInterestedIn} />
              <div className="heightPlaceholder">
                {sportError && (
                  <Typography className="signUpErrorText">
                    * Please select atleast one sport
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              {interestedIn[0].label === "Tennis" && interestedIn[0].check && (
                <>
                  <div className="rowCenter posRelative">
                    <Typography className="signUpSubtitleText">
                      Rate Your Tennis Skill
                    </Typography>
                    <InfoPopover level={customRating} />
                  </div>

                  <GSMRatingSlider
                    value={customRating}
                    setValue={setCustomRating}
                    editable={true}
                  />
                  <Typography className="signUpRatingText">
                    {selectedSkillLevel}
                  </Typography>
                </>
              )}
              {switchPickleBall && interestedIn[1] && interestedIn[1].label === "Pickle Ball" &&
                interestedIn[1].check && (
                  <>
                    <div className="rowCenter posRelative">
                      <Typography className="signUpSubtitleText">
                        Rate Your Pickleball Skill
                      </Typography>
                      <InfoPopover isPickleball={true} />
                    </div>
                    <GSMRatingSlider
                      value={customRatingForPickleBall}
                      setValue={setCustomRatingForPickleBall}
                      editable={true}
                    />
                    <Typography className="signUpRatingText">
                      {selectedSkillLevelForPickleball}
                    </Typography>
                  </>
                )}
            </Grid>
            <Grid className="columnCenter" item xs={12}>
              <Typography className="signUpSubtitleText">
                By clicking Create Account, you agree to the
              </Typography>
              <div className="rowCenterMinWidth">
                <a
                  className="signUpSubtitleText margin10Right"
                  style={{ textDecoration: "underline", color: "#0000ff" }}
                  href="https://playgamesetmatch.com/terms-of-use/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>
                <Typography className="signUpSubtitleText">and</Typography>
                <a
                  className="signUpSubtitleText margin10Left"
                  style={{ textDecoration: "underline", color: "#0000ff" }}
                  href="https://playgamesetmatch.com/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </div>
            </Grid>
          </Grid>
          <div className="heightPlaceholder">
            {passwordError && (
              <Typography className="signUpErrorText">{error}</Typography>
            )}
          </div>
          <div className="rowCenter">
            <Button className="signUpNextButton" type="submit">
              <Typography className="signUpNextButtonText">
                Create Account
              </Typography>
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default SignUp;

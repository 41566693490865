import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import { Firebase } from "../../../firebase";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
//NOTE: add empty string to geturl function and that way I can handle when theres no id
const statusSelect = ["new", "shipping", "complete"];
const RewardClaimedEditForm = ({ open, setOpen, rewardClaimed, isEditing }) => {
  const [status, setStatus] = useState("");
  const [prevStatus, setPrevStatus] = useState("");
  const [address, setAddress] = useState({});
  const [units, setUnits] = useState(0);
  const [points, setPoints] = useState(0);
  const [item, setItem] = useState("");

  useEffect(() => {
    if (isEditing) {
      setStatus(rewardClaimed.status);
      setPrevStatus(rewardClaimed.status);
      setAddress(rewardClaimed.address);
      setUnits(rewardClaimed.units);
      setPoints(rewardClaimed.points);
      setItem(rewardClaimed.item);
    } else {
      setStatus("new");
      setPrevStatus("");
      setAddress({});
      setUnits(0);
      setPoints(0);
      setItem("");
    }
  }, [open, rewardClaimed, isEditing]);
  const updateRewardClaimed = async (e) => {
    e.preventDefault();
    if (status === "shipping" && prevStatus === "new") {
      await rewardClaimed.ref.update({
        status: status,
        dateShipped: Firebase.firestore.FieldValue.serverTimestamp(),
      });
    } else if (status === "complete" && prevStatus === "shipping") {
      await rewardClaimed.ref.update({
        status: status,
        dateDelivered: Firebase.firestore.FieldValue.serverTimestamp(),
      });
    }

    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogContent>
        <Typography variant="h4">View Reward Order</Typography>
        <Typography variant="h5" className="margin30Top">
          Item
        </Typography>
        <Typography variant="subtitle1">{item}</Typography>
        <Typography variant="h5" className="margin10Top">
          Details
        </Typography>
        <div className="rowCenter">
          <div className="columnCenter filler">
            <div className="column">
              <Typography variant="h6">Quantity</Typography>
              <Typography variant="subtitle1">{units}</Typography>
            </div>
          </div>
          <div className="columnCenter filler">
            <div className="column">
              <Typography variant="h6">Cost</Typography>
              <Typography variant="subtitle1">{points}</Typography>
            </div>
          </div>
        </div>
        <Typography variant="h5" className="margin10Top">
          Address
        </Typography>
        <div className="column">
          <Typography variant="subtitle1">{`${address.firstName} ${address.lastName}`}</Typography>
          <Typography variant="subtitle1">{`${address.addressLine1} ${address.addressLine2}`}</Typography>
          <Typography variant="subtitle1">{`${address.city}, ${address.state} ${address.zipCode} US`}</Typography>
        </div>
        <form
          className="columnCenter margin10Top"
          onSubmit={updateRewardClaimed}
        >
          <TextField
            variant="outlined"
            className="maxWidth"
            required
            select
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
            label="Status:"
          >
            {statusSelect.map((s, key) => (
              <MenuItem key={key} value={s}>
                {s}
              </MenuItem>
            ))}
          </TextField>
          <div className="rowCenter margin10Top">
            <div className="filler" />
            <Button
              variant="contained"
              className="primaryColorBackground"
              type="submit"
            >
              Save
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default RewardClaimedEditForm;

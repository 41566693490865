import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Icon from "@mdi/react";
import { mdiCrownOutline } from "@mdi/js";
const TeamBracketObject = ({ team, score, final }) => {
  const teamWon = score.winner === team.id;
  const placeCrown = teamWon && final;
  return (
    <div
      className={`playoffObject ${
        team.withdrawn && "withdrawnPlayerBackground"
      }`}
    >
      <div className="posRelative columnAlignCenter">
        {placeCrown && (
          <Icon
            className="playoffsFinalsCrownDoubles posAbsolute"
            size="40px"
            path={mdiCrownOutline}
          />
        )}
        <AvatarGroup>
          <Avatar
            className="image50"
            src={team.players !== undefined ? team.players[0].imageURL : ""}
          />
          <Avatar
            className="image50"
            src={team.players !== undefined ? team.players[1].imageURL : ""}
          />
        </AvatarGroup>
      </div>
      <div className="playoffObjectTextWrapper">
        <Typography className="playoffsObjectPlayerText filler">
          {team.name} {team.withdrawn && "(W)"} - {team.rank}
        </Typography>
        {typeof score !== "string" ? (
          <div className="playoffScoreWrapper">
            {teamWon ? (
              <Fragment>
                {score.scoringFormat !== "10GamePro" &&
                score.scoringFormat !== "1Gameto15or21points" ? (
                  <Fragment>
                    <Typography className="playoffsObjectScoreText">
                      {score.setOneScore[0]}
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      -
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      {score.setTwoScore[0]}
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      -
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      {score.setThreeScore[0]}
                    </Typography>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Typography className="playoffsObjectScoreText">
                      {score.proSetScore[0]}
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      -
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      x
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      -
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      x
                    </Typography>
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <Fragment>
                {score.scoringFormat !== "10GamePro" &&
                score.scoringFormat !== "1Gameto15or21points" ? (
                  <Fragment>
                    <Typography className="playoffsObjectScoreText">
                      {score.setOneScore[1]}
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      -
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      {score.setTwoScore[1]}
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      -
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      {score.setThreeScore[1]}
                    </Typography>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Typography className="playoffsObjectScoreText">
                      {score.proSetScore[1]}
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      -
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      x
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      -
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      x
                    </Typography>
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
        ) : (
          <div className="playoffScoreWrapper">
            <Typography className="playoffsObjectScoreText">x</Typography>
            <Typography className="playoffsObjectScoreText">-</Typography>
            <Typography className="playoffsObjectScoreText">x</Typography>
            <Typography className="playoffsObjectScoreText">-</Typography>
            <Typography className="playoffsObjectScoreText">x</Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamBracketObject;

import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Loader from "react-loader-spinner";
import moment from "moment";
import { CustomTabs } from "../../../components/CustomTabs";
import RewardList from "./RewardList";
import RewardsClaimed from "./RewardsClaimed";
import { firestore } from "../../../firebase";
const AdminManageRewards = ({ user }) => {
  const [pageNum, setPageNum] = useState(0);
  const pageLabels = ["Rewards", "Claimed Rewards"];
  const [loading, setLoading] = useState(true);
  const [rewardsList, setRewardsList] = useState([]);
  const [rewardsClaimedTable, setRewardsClaimedTable] = useState([]);
  useEffect(() => {
    const rewardsRef = firestore.collection("rewards");
    const unsubscribe = rewardsRef.onSnapshot((rewardsSnapshot) => {
      let rewardsTemp = [];
      rewardsSnapshot.forEach((rewardDoc) => {
        const rewardData = rewardDoc.data();
        const ref = rewardDoc.ref;
        const id = rewardDoc.id;
        rewardsTemp.push({
          cells: [
            { content: rewardData.name, editable: false },
            { content: rewardData.points, editable: false },
            { content: rewardData.units, editable: false },
            { content: rewardData.unitsClaimed, editable: false },
            {
              content: moment(rewardData.startDate.toDate()).format(
                "MMM D, YYYY"
              ),
              editable: false,
            },
            {
              content: moment(rewardData.endDate.toDate()).format(
                "MMM D, YYYY"
              ),
              editable: false,
            },
          ],
          ref,
          id,
          ...rewardData,
        });
      });
      setRewardsList(rewardsTemp);
    });
    const rewardsClaimedRef = firestore.collection("rewardClaims");
    const unsubscribeRewardsClaimed = rewardsClaimedRef.onSnapshot(
      (rewardsClaimedSnap) => {
        let rewardsClaimedTableTemp = [];
        rewardsClaimedSnap.forEach((rewardClaimedDoc) => {
          const ref = rewardClaimedDoc.ref;
          const id = rewardClaimedDoc.id;
          const data = rewardClaimedDoc.data();
          const date = data.date.toDate();
          const displayDate = moment(date).format("M/D/YYYY");
          rewardsClaimedTableTemp.push({
            ref: ref,
            id: id,
            ...data,
            date: date,
            cells: [
              { content: displayDate, editable: false },
              { content: data.item, editable: false },
              { content: data.units, editable: false },
              { content: data.points, editable: false },
              { content: data.status, editable: false },
            ],
          });
        });
        setRewardsClaimedTable(rewardsClaimedTableTemp);
        setLoading(false);
      }
    );
    return () => {
      unsubscribe();
      unsubscribeRewardsClaimed();
    };
  }, [user]);

  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );

  return (
    <div className="column filler">
      <CustomTabs labels={pageLabels} value={pageNum} setValue={setPageNum} />
      <Grid container spacing={6}>
        {pageNum === 0 && (
          <RewardList
            rewardsList={rewardsList}
            setRewardsList={setRewardsList}
          />
        )}
        {pageNum === 1 && (
          <RewardsClaimed
            rewardsClaimedTable={rewardsClaimedTable}
            setRewardsClaimedTable={setRewardsClaimedTable}
          />
        )}
      </Grid>
    </div>
  );
};

export default AdminManageRewards;

import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Loader from "react-loader-spinner";
import moment from "moment";
import { firestore } from "../../../firebase";
import { CustomTabs } from "../../../components/CustomTabs";
import AdminManageCoupons from "./AdminManageCoupons";
import AdminManageReferrals from "./AdminManageReferrals";
const AdminManageDiscounts = ({ user }) => {
  const [pageNum, setPageNum] = useState(0);
  const [loading, setLoading] = useState(true);
  const pageLabels = ["Coupons", "User Referrals"];
  const [coupons, setCoupons] = useState([]);
  const [referrals, setReferrals] = useState([]);
  useEffect(() => {
    const discountsRef = firestore.collection("coupons");
    const unsubscribeDiscounts = discountsRef.onSnapshot((discountsSnap) => {
      let couponsTemp = [];
      let referralsTemp = [];
      discountsSnap.forEach((discountDoc) => {
        let data = discountDoc.data();
        let ref = discountDoc.ref;
        let id = discountDoc.id;
        const date = data.dateCreated ? data.dateCreated.toDate() : new Date();
        if (data.createdBy === "admin") {
          const displayDate = moment(date).format("MMM D, YYYY");
          couponsTemp.push({
            ...data,
            date,
            ref,
            id,
            cells: [
              { content: data.code, editable: false },
              { content: data.active.toString(), editable: false },
              { content: data.oneTimeUse.toString(), editable: false },
              { content: data.percentDiscount, editable: false },
              { content: data.discreteDiscount, editable: false },
              { content: displayDate, editable: false },
            ],
          });
        } else {
          referralsTemp.push({
            ...data,
            date,
            ref,
            id,
          });
        }
      });
      setCoupons(couponsTemp);
      setReferrals(referralsTemp);
      setLoading(false);
    });
    return () => {
      unsubscribeDiscounts();
    };
  }, []);
  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  return (
    <div className="column filler">
      <CustomTabs labels={pageLabels} value={pageNum} setValue={setPageNum} />
      <div className="column filler">
        <Grid container spacing={6}>
          {pageNum === 0 && (
            <AdminManageCoupons coupons={coupons} setCoupons={setCoupons} />
          )}
          {pageNum === 1 && <AdminManageReferrals referrals={referrals} />}
        </Grid>
        <div className="filler" />
      </div>
    </div>
  );
};

export default AdminManageDiscounts;

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { ChevronLeft } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useHistory, useLocation } from "react-router-dom";
import {
  checkCourtStatus,
  checkNewPlayerSignup, getDivisionById,
  getTrackById,
  getUpadtedMatchSchedule,
  getUserByID,
  getUsersInTrackModerate,
  handleEditDataColumn,
  moderateDataSave,
} from "../../../functions/firebaseFunctions";
import Loader from "react-loader-spinner";
import DefaultDialog from "../../../components/DefaultDialog";
import { Firebase, firestore } from "../../../firebase";
import ScoreUploadForm from "../../forms/ScoreUploadForm";
import PickleballScoreUploadForm from "../../forms/PickleballScoreUploadForm";
import {divisionData} from "../../../components/Common";

const createRankTable = (
  rank,
  player,
  matches,
  percentMatches,
  games,
  percentGames,
  sets,
  percentSets,
  rating,
  imageURL,
  id,
  matchWins,
  firstName,
  lastName,
  withdrawn
) => {
  return {
    rank,
    player,
    matches,
    percentMatches,
    games,
    percentGames,
    sets,
    percentSets,
    rating,
    imageURL,
    id,
    matchWins,
    firstName,
    lastName,
    withdrawn,
  };
};

function EventModerationScreen({
  leagues,
  divisions,
  tracks,
  user,
  userDoublesTeams,
}) {
  const history = useHistory();
  const location = useLocation();
  const {
    isEditModerated,
    trackId,
    leagueId,
    divisionId,
    trackPlayers,
    selectedMatchIdForModerate,
    noOfCourt,
    isPickleball,
  } = location.state;
  const [columns, setColumns] = useState({});
  const [loading, setLoading] = useState(false);
  const [matchScheduleData, setMatchScheduleData] = useState({});
  const [closeEvent, setCloseEvent] = useState(false);
  const [review, setReview] = useState("");
  const [closeEventErr, setCloseEventErr] = useState("");
  const [selectColumnForScore, setSelectColumnForScore] = useState("");
  const [openScoreUpload, setOpenScoreUpload] = useState(false);
  const [isStartMatchLoading, setIsStartMatchLoading] = useState(false);
  const [isSingleCourt, setIsSingleCourt] = useState(false);

  useEffect(() => {
    if (!isEditModerated) {
      getData();
    } else {
      getSelectedMatchData();
    }
    getMatchScheduleData();
  }, []);

const handleIsSingle=async ()=>{
  let foundDivision = await getDivisionById(leagueId, divisionId);
  if(foundDivision){
    setIsSingleCourt(divisionData.includes(foundDivision.division))
  }
}
  useEffect(()=> {
        handleIsSingle();
      }
  ,[leagueId, divisionId]);

  useEffect(() => {
    if (Object.keys(columns).length > 0) {
      handleSave();
    }
  }, [columns]);

  const getSelectedMatchData = async () => {
    setLoading(true);
    let matchData = await getUpadtedMatchSchedule(selectedMatchIdForModerate);
    let editColData = await handleEditDataColumn(matchData.moderateData);
    for (let key in editColData) {
      editColData[key].items = await playerRecord(editColData[key].items, null);
    }
    editColData = Object.keys(editColData)
      .sort()
      .reduce((accumulator, key) => {
        accumulator[key] = editColData[key];

        return accumulator;
      }, {});
    let checkIfNewPlayerJoin = await checkNewPlayerSignup(
      trackPlayers,
      editColData
    );

    if (checkIfNewPlayerJoin.length > 0) {
      let playersTemp = await getUsersInTrackModerate(checkIfNewPlayerJoin);
      let playerRecordsTemp = playerRecord(playersTemp);
      let existREgisterPlayer = editColData?.registerPlayer?.items;
      let newPlayersArray = existREgisterPlayer.concat(playerRecordsTemp);
      editColData.registerPlayer.items = newPlayersArray;
    }
    setColumns(editColData);
    setLoading(false);
  };

  const getMatchScheduleData = async () => {
    let matchData = await getUpadtedMatchSchedule(selectedMatchIdForModerate);
    setMatchScheduleData(matchData);
  };

  const getData = async () => {
    setLoading(true);
    let playersTemp = await getUsersInTrackModerate(trackPlayers);
    let playerRecordsTemp = playerRecord(playersTemp);

    let _column = {
      registerPlayer: {
        name: "Registered Players",
        items: [],
      },
      waitingRoom: {
        name: "Waiting Room",
        items: [],
      },
    };
    for (let l = 0; l < noOfCourt; l++) {
      let sd = `court${l + 1}`;
      _column[sd] = {
        name: sd,
        items: [],
        status: null,
      };
    }
    let newwrr = _column;
    let updatedList = {
      ...newwrr["registerPlayer"],
      items: playerRecordsTemp,
    };

    setColumns({
      ...newwrr,
      registerPlayer: updatedList,
    });
    setLoading(false);
  };

  const playerRecord = (playersTemp) => {
    let playerRecordslj = playersTemp?.map((p) =>
      p?.leaguesJoined?.find(
        (leagueJ) =>
          leagueJ.leagueID === leagueId &&
          leagueJ.divisionID === divisionId &&
          leagueJ.trackID === trackId
      )
    );
    let pb_playerRecordslj = playersTemp?.map((p) =>
      p?.pb_leaguesJoined?.find(
        (leagueJ) =>
          leagueJ.leagueID === leagueId &&
          leagueJ.divisionID === divisionId &&
          leagueJ.trackID === trackId
      )
    );
    let playerRecords = isPickleball ? pb_playerRecordslj : playerRecordslj;

    let playerRecordsTemp = playersTemp
      .map((p, i) => {
        const record = playerRecords[i];

        if (record) {
          const rank = record.rank++;
          const name =
            p.firstName + " " + p.lastName + (record.withdrawn ? "(W)" : "");
          const matches = record.matchWins + " - " + record.matchLosses;
          const matchWinPercent =
            record.matchWins + record.matchLosses > 0
              ? (
                  record.matchWins /
                  (record.matchWins + record.matchLosses)
                ).toFixed(4)
              : (0).toFixed(4);
          const games = record.gameWins + " - " + record.gameLosses;
          const gameWinPercent =
            record.gameWins + record.gameLosses > 0
              ? (
                  record.gameWins /
                  (record.gameWins + record.gameLosses)
                ).toFixed(4)
              : (0).toFixed(4);
          const sets = record.setWins + " - " + record.setLosses;
          const setWinPercent =
            record.setWins + record.setLosses > 0
              ? (record.setWins / (record.setWins + record.setLosses)).toFixed(
                  4
                )
              : (0).toFixed(4);
          return createRankTable(
            rank,
            name,
            matches,
            matchWinPercent,
            games,
            gameWinPercent,
            sets,
            setWinPercent,
            p.gsmRating,
            p.imageURL,
            p.id,
            record.matchWins,
            p.firstName,
            p.lastName,
            record.withdrawn
          );
        }
      })
      .sort((a, b) => {
        return a.rank - b.rank;
      });
    return playerRecordsTemp;
  };

  const onDragEnd = async (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      let obj = {
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      };
      const updateStatusColumn = checkCourtStatus(obj, isSingleCourt);
      await setColumns(updateStatusColumn);
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  const handleSave = async () => {
    const copyColumnObj = JSON.parse(JSON.stringify(columns));
    await moderateDataSave(selectedMatchIdForModerate, copyColumnObj);
  };

  const handleStartMatch = async (columnId, status) => {
    if (status === "Start") {
      setIsStartMatchLoading(true);
      if(isSingleCourt){
        let player1Id = columns[columnId].items[0].id;
        let player2Id = columns[columnId].items[1].id;
      for (let i=0; i<2; i++){
        let playerArr = [player1Id, player2Id];
        // let trackRef = await getTrackById(leagueId, divisionId, trackId);
        // await trackRef.ref.update({
        //   players: playerArr,
        // });
        if (!isPickleball) {
          for (let k = 0; k < 2; k++) {
            const playerRef = await getUserByID(playerArr[k]);

            await playerRef.ref.update({
              leaguesJoined: playerRef.leaguesJoined,
            });
          }
        } else {
          for (let k = 0; k < 2; k++) {
            const playerRef = await getUserByID(playerArr[k]);

            await playerRef.ref.update({
              pb_leaguesJoined: playerRef.pb_leaguesJoined,
            });
          }
        }
        setColumns({
          ...columns,
          [columnId]: {
            ...columns[columnId],
            status: "In Progress",
          },
        });
      }
      }else {
        let DoubleTeamsIds = [];
        let player1Id = columns[columnId].items[0].id;
        let player2Id = columns[columnId].items[1].id;
        let player3Id = columns[columnId].items[2].id;
        let player4Id = columns[columnId].items[3].id;
        for (let i = 0; i < 2; i++) {
          let doublesTeamID = "";
          let playerArr =
              i === 0 ? [player1Id, player2Id] : [player3Id, player4Id];
              let filterDoubleTeam = await firestore
              .collection("doublesTeams")
              .where("players", "==", playerArr)
              .get();
              if (!filterDoubleTeam.empty) {
                doublesTeamID = filterDoubleTeam.docs[0].id;
                DoubleTeamsIds.push(filterDoubleTeam.docs[0].id);
              } else {
                let docRef = await firestore.collection("doublesTeams").add({
                  players: playerArr,
                });
                doublesTeamID = docRef.id;
                DoubleTeamsIds.push(docRef.id);
              }
          // await firestore
          //     .collection("doublesTeams")
          //     .add({
          //       players: playerArr,
          //     })
          //     .then((doc) => {
          //       doublesTeamID = doc.id;
          //       DoubleTeamsIds.push(doc.id);
          //     });
          let trackRef = await getTrackById(leagueId, divisionId, trackId);
          await trackRef.ref.update({
            doublesTeams: Firebase.firestore.FieldValue.arrayUnion(doublesTeamID),
          });

          if (!isPickleball) {
            for (let k = 0; k < 2; k++) {
              const playerRef = await getUserByID(playerArr[k]);
              for (
                  let index = 0;
                  index < playerRef.leaguesJoined.length;
                  index++
              ) {
                const element = playerRef.leaguesJoined[index];
                if (
                    element.leagueID === leagueId &&
                    element.divisionID === divisionId &&
                    element.trackID === trackId
                ) {
                  element.doublesTeamID = doublesTeamID;
                }
              }
              await playerRef.ref.update({
                leaguesJoined: playerRef.leaguesJoined,
              });
            }
          } else {
            for (let k = 0; k < 2; k++) {
              const playerRef = await getUserByID(playerArr[k]);
              for (
                  let index = 0;
                  index < playerRef.pb_leaguesJoined.length;
                  index++
              ) {
                const element = playerRef.pb_leaguesJoined[index];
                if (
                    element.leagueID === leagueId &&
                    element.divisionID === divisionId &&
                    element.trackID === trackId
                ) {
                  element.doublesTeamID = doublesTeamID;
                }
              }
              await playerRef.ref.update({
                pb_leaguesJoined: playerRef.pb_leaguesJoined,
              });
            }
          }
        }

        setColumns({
          ...columns,
          [columnId]: {
            ...columns[columnId],
            status: "In Progress",
            teamID: DoubleTeamsIds,
          },
        });
      }
      setIsStartMatchLoading(false);
    } else {
      setSelectColumnForScore(columnId);
      setOpenScoreUpload(true);
    }
  };

  const handleAddCourt = () => {
    let nextNumOfCourt = Object.keys(columns).length - 2;
    let courtName = `court${nextNumOfCourt + 1}`;
    setColumns({
      ...columns,
      [courtName]: {
        name: courtName,
        items: [],
        status: null,
      },
    });
  };

  const handleREdirect = () => {
    history.push({
      pathname: `/Director/ViewLeague/Singles/${leagueId}`,
      state: {
        isFromModerate: true,
      },
    });
  };

  const handleCloseEvent = async () => {
    let matcheInProgress = 0;
    for (let key in columns) {
      if (columns[key].status === "In Progress") {
        matcheInProgress += 1;
      }
    }
    if (matcheInProgress > 0) {
      setCloseEventErr(`There is ${matcheInProgress} match in Progress`);
    } else {
      setCloseEventErr("");
      await firestore
        .doc(`matchSchedules/${selectedMatchIdForModerate}`)
        .update({
          status: "Completed",
          review: review,
        });
      handleREdirect();
    }
  };

  const updateNumOfPlayedMatch = async () => {
    let numofPlayedMatch = matchScheduleData?.numOfPlayedMatch
      ? matchScheduleData?.numOfPlayedMatch
      : 0;
    numofPlayedMatch = numofPlayedMatch + 1;
    await firestore.doc(`matchSchedules/${selectedMatchIdForModerate}`).update({
      numOfPlayedMatch: numofPlayedMatch,
    });
    setMatchScheduleData({
      ...matchScheduleData,
      numOfPlayedMatch: numofPlayedMatch,
    });
  };

  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );

  return (
    <>
      {openScoreUpload &&
        (user?.currentSport === "pickleball" ? (
          <PickleballScoreUploadForm
            open={openScoreUpload}
            setOpen={setOpenScoreUpload}
            leagues={leagues}
            divisions={divisions}
            tracks={tracks}
            setColumns={setColumns}
            selectColumnForScore={selectColumnForScore}
            columns={columns}
            leagueID={leagueId}
            trackId={trackId}
            divisionId={divisionId}
            user={user}
            updateNumOfPlayedMatch={() => updateNumOfPlayedMatch()}
            userDoublesTeams={userDoublesTeams}
            isFromEventModeration={true}
            matchScheduleId={selectedMatchIdForModerate}
          />
        ) : (
          <ScoreUploadForm
            open={openScoreUpload}
            setOpen={setOpenScoreUpload}
            leagues={leagues}
            divisions={divisions}
            tracks={tracks}
            setColumns={setColumns}
            selectColumnForScore={selectColumnForScore}
            columns={columns}
            leagueID={leagueId}
            trackId={trackId}
            divisionId={divisionId}
            user={user}
            updateNumOfPlayedMatch={() => updateNumOfPlayedMatch()}
            userDoublesTeams={userDoublesTeams}
            isFromEventModeration={true}
            matchScheduleId={selectedMatchIdForModerate}
          />
        ))}
      <DefaultDialog open={closeEvent} setOpen={setCloseEvent}>
        <div className="rowCenter">
          <Typography className="createLeagueFieldText margin10Vertical">
            Closing the Event
          </Typography>
        </div>
        <Typography className="rewardPurchaseItemDescription margin10Top">
          Summary:
        </Typography>
        <Typography className="profileInfoCardSubtitle margin10Top">
          Congratulations, You had {trackPlayers?.length} players dropping in.
          There were{" "}
          {Number(
            matchScheduleData.numOfPlayedMatch
              ? matchScheduleData.numOfPlayedMatch
              : 0
          )}{" "}
          matches played between{" "}
          {matchScheduleData?.start?.toDate()?.toLocaleTimeString()} and{" "}
          {new Date().toLocaleTimeString()}.
        </Typography>
        <Typography className="profileInfoCardSubtitle margin10Top">
          Location: {matchScheduleData?.venue}
        </Typography>
        <Typography className="profileInfoCardSubtitle">
          No. of Courts: {Object.keys(columns).length - 2}
        </Typography>
        <Typography className="scoreUploadFieldText margin10Top">
          Enter Notes (review) of the Event(Optional):
        </Typography>
        <TextField
          multiline
          fullWidth={true}
          rows={3}
          value={review}
          placeholder=""
          onChange={(e) => {
            setReview(e.target.value);
          }}
        />

        <div className="rowCenter margin10Top">
          {closeEventErr === "" && (
            <Typography className="helperText">
              Please Click on Confirm to Close Event
            </Typography>
          )}
          {closeEventErr !== "" && (
            <Typography className="err">{closeEventErr}</Typography>
          )}
          <div className="filler" />
          <Button variant="text" onClick={() => setCloseEvent(false)}>
            <Typography className="cancelButtonText">Back</Typography>
          </Button>
          <Button
            className="purchaseButton"
            variant="outlined"
            onClick={handleCloseEvent}
          >
            <Typography className="purchaseButtonText">Confirm</Typography>
          </Button>
        </div>
      </DefaultDialog>

      <div>
        <Button onClick={() => handleREdirect()} variant="text">
          <div className="flexCenter">
            <ChevronLeft className="image30 appBarUploadScoreButtonIcon" />
            <Typography className="appBarUploadScoreButtonText">
              Back
            </Typography>
          </div>
        </Button>
      </div>
      <Grid className="padding0" xs={12} md={12} lg={12} xl={12} item>
        <Card className="card profileContentWrapper">
          <div className="moderateCloseAddBtn margin10Bottom">
            <div>
              <IconButton
                onClick={() => handleAddCourt()}
                className="linkbutton"
              >
                Add More Courts
              </IconButton>
            </div>
            <div>
              <Button
                variant="text"
                onClick={() => {
                  setCloseEvent(true);
                  setCloseEventErr("");
                }}
              >
                <Typography className="cancelButtonText">
                  Close Event
                </Typography>
              </Button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <DragDropContext
              onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
            >
              <div className="flex">
                {Object.entries(columns).map(([columnId, column], index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                      key={columnId}
                    >
                      {columnId === "registerPlayer" ||
                      columnId === "waitingRoom" ? (
                        <>
                          <Typography>{column.name}</Typography>
                          <div style={{ margin: 8 }}>
                            <Droppable droppableId={columnId} key={columnId}>
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                      background: snapshot.isDraggingOver
                                        ? "#d2f4ff"
                                        : "#f4f5f7",
                                      padding: 14,
                                      width: "auto",
                                      minWidth: 201,
                                      overflowY: "auto",
                                      height: "500px",
                                      minHeight: 600,
                                    }}
                                  >
                                    {column?.items.map((item, indexx) => {
                                      return (
                                        <Draggable
                                          key={item?.id}
                                          draggableId={item?.id}
                                          index={indexx}
                                        >
                                          {(provided, snapshot) => {
                                            return (
                                              <>
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  style={{
                                                    userSelect: "none",
                                                    margin: "0 0 8px 0",
                                                    minHeight: "50px",
                                                    backgroundColor: "none",
                                                    color: "white",
                                                    ...provided.draggableProps
                                                      .style,
                                                  }}
                                                >
                                                  <Card
                                                    className={`image200Rounded ${
                                                      columnId === "waitingRoom"
                                                        ? "height142"
                                                        : ""
                                                    } posRelative `}
                                                  >
                                                    <div className="viewLeagueProfileImageTextWrapper">
                                                      <Typography className="viewLeagueProfileImageHeader">
                                                        {item?.player}
                                                      </Typography>
                                                      <Typography className="viewLeagueProfileImageText">
                                                        {item?.matches}
                                                      </Typography>
                                                    </div>
                                                    <CardMedia
                                                      variant="rounded"
                                                      className={`image200Rounded ${
                                                        columnId ===
                                                        "waitingRoom"
                                                          ? "height142"
                                                          : ""
                                                      }`}
                                                      image={item?.imageURL}
                                                    />
                                                  </Card>
                                                </div>
                                              </>
                                            );
                                          }}
                                        </Draggable>
                                      );
                                    })}
                                    {provided.placeholder}
                                  </div>
                                );
                              }}
                            </Droppable>
                          </div>
                        </>
                      ) : null}
                    </div>
                  );
                })}
              </div>
              <div className="flex flexWrap">
                {Object.entries(columns).map(
                  ([columnId, column], Courtindex) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                        key={columnId}
                      >
                        {columnId === "registerPlayer" ||
                        columnId === "waitingRoom" ? null : (
                          <>
                            <Typography className="capitalize">
                              {column.name}
                            </Typography>
                            <div style={{ margin: "8px 20px 8px 8px" }}>
                              <Droppable
                                droppableId={columnId}
                                key={columnId}
                                isDropDisabled={isSingleCourt ? column?.items.length === 2 : column?.items.length === 4}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                      style={{
                                        background: snapshot.isDraggingOver
                                          ? "#d2f4ff"
                                          : "#f4f5f7",
                                        padding: 14,
                                        minWidth: isSingleCourt ? 158 : 314,
                                        maxWidth: isSingleCourt ? 158 : 325,
                                        minHeight: isSingleCourt ? 340 :280,
                                        maxHeight: isSingleCourt ? 340 : '',
                                        display: "grid",
                                        gridTemplateColumns: isSingleCourt ? "1fr" :"1fr 1fr",
                                        gridGap: "10px",
                                        width: "100%",
                                      }}
                                    >
                                      {column?.items.map((item, index) => {
                                        return (
                                          <Draggable
                                            key={item?.id}
                                            draggableId={item?.id}
                                            index={index}
                                          >
                                            {(provided, snapshot) => {
                                              return (
                                                <>
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                      userSelect: "none",
                                                      minHeight: "50px",
                                                      backgroundColor: "none",
                                                      color: "white",
                                                      width: 158,
                                                      ...provided.draggableProps
                                                        .style,
                                                    }}
                                                  >
                                                    <Card
                                                      className={`eventCourtSection ${
                                                          isSingleCourt && column?.items.length > 1 ? "maxHeight" :column?.items.length > 2 ? "maxHeight" : "heightHalf"
                                                      } posRelative `}
                                                    >
                                                      <div className="viewLeagueProfileImageTextWrapper">
                                                        <Typography className="viewLeagueProfileImageHeader">
                                                          {item?.player}
                                                        </Typography>
                                                        <Typography className="viewLeagueProfileImageText">
                                                          {item?.matches}
                                                        </Typography>
                                                      </div>
                                                      <CardMedia
                                                        variant="rounded"
                                                        className="eventCourtSection1"
                                                        image={item?.imageURL}
                                                      />
                                                    </Card>
                                                  </div>
                                                </>
                                              );
                                            }}
                                          </Draggable>
                                        );
                                      })}
                                      {provided.placeholder}
                                    </div>
                                  );
                                }}
                              </Droppable>
                            </div>
                            {column.status !== null && (
                              <>
                                <Typography className="err margin5Bottom">
                                  {column.status}
                                </Typography>
                                {isStartMatchLoading ? (
                                  <Loader
                                    type="TailSpin"
                                    color="#02c39a"
                                    height={25}
                                    width={25}
                                    timeout={8000}
                                  />
                                ) : (
                                  <Button
                                    className={
                                      column.status === "Ready to Play"
                                        ? "modearateStartButton"
                                        : "modearateCompleteAndButton"
                                    }
                                    onClick={() =>
                                      handleStartMatch(
                                        columnId,
                                        column.status === "Ready to Play"
                                          ? "Start"
                                          : "Complete & Score"
                                      )
                                    }
                                  >
                                    {column.status === "Ready to Play"
                                      ? "START"
                                      : "Complete & Score"}
                                  </Button>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    );
                  }
                )}
              </div>
            </DragDropContext>
          </div>
        </Card>
      </Grid>
    </>
  );
}

export default EventModerationScreen;

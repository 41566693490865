import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import Close from "@material-ui/icons/Close";
import PageOne from "./PageOne";
import PageTwo from "./PageTwo";
import PageThree from "./PageThree";
import { firestore, Firebase, functions } from "../../firebase";
import { noActionEmail } from "../../functions/Messaging";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const RewardPurchaseForm = ({
  open,
  setOpen,
  reward,
  availablePoints,
  user,
}) => {
  const [pageNum, setPageNum] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [unitsSelected, setUnitsSelected] = useState(1);
  const [totalCost, setTotalCost] = useState(0);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [addressLine1, setAddressLine1] = useState(
    user.homeAddress.addressLine1
  );
  const [addressLine2, setAddressLine2] = useState(
    user.homeAddress.addressLine2
  );
  const [city, setCity] = useState(user.homeAddress.city);
  const [state, setState] = useState(user.homeAddress.state);
  const [zipCode, setZipCode] = useState(user.homeAddress.zipCode);
  useEffect(() => {
    setPageNum(1);
    setError(false);
    setErrorMessage("");
  }, [reward]);
  const sendMessage = async () => {
    const date = new Date();
    await noActionEmail({
      subject: "Confirmation: Redeemed MatchPoints™",
      message: `We are confirming that on ${moment(date).format(
        "M/D/YYYY"
      )}, you redeemed ${unitsSelected} of ${reward.name} for ${
        reward.points
      } totaling ${totalCost} Pts.`,
      message2: `We are shipping this item to: ${addressLine1}, ${addressLine2}, ${city}, ${state}, ${zipCode}. If this is incorrect, please email: rewardsadmin@playgamesetmatch.com.`,
      message3: "Thank you and play on! --- GAME.SET.MATCH",
      recipients: [{ email: user.email, name: user.firstName }],
    });
  };
  const purchaseReward = async () => {
    await firestore.collection("rewardClaims").add({
      userID: user.uid,
      rewardID: reward.id,
      units: unitsSelected,
      address: {
        firstName: firstName,
        lastName: lastName,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        state: state,
        zipCode: zipCode,
      },
      item: reward.name,
      date: Firebase.firestore.FieldValue.serverTimestamp(),
      status: "new",
      dateShipped: null,
      points: totalCost,
    });
    await firestore.doc(`users/${user.uid}`).update({
      pointsUsed:
        user.pointsUsed !== null ? user.pointsUsed + totalCost : totalCost,
      matchPoints: user.matchPoints - totalCost,
    });
    try {
      const updateUserData = functions.httpsCallable(
        'webhookProcessActionUpdateUserLeague'
      );
      updateUserData({ userID: user.uid });
    } catch (error) {
      console.log('webhookProcessActionUpdateUserLeague', error);
    }
    await reward.ref.update({
      units: reward.units - unitsSelected,
      unitsClaimed: reward.unitsClaimed + unitsSelected,
    });
    await sendMessage();
  };
  const handleClose = () => {
    setOpen(false);
    setPageNum(1);
  };
  const handleSelectedAmount = () => {
    if (unitsSelected * reward.points > availablePoints) {
      setErrorMessage("Not Enough Points");
      setError(true);
      return false;
    } else if (unitsSelected < 1) {
      setErrorMessage("Must select at least 1 item");
      setError(true);
      return false;
    }
    setError(false);
    setErrorMessage("");
    setTotalCost(unitsSelected * reward.points);
    return true;
  };
  const handleNextPage = (e) => {
    e.preventDefault();
    if (pageNum === 1 && handleSelectedAmount()) {
      setPageNum((num) => num + 1);
    } else if (pageNum === 2) {
      setPageNum((num) => num + 1);
      purchaseReward();
    } else if (pageNum === 3) {
      handleClose();
    }
  };
  const handlePreviousPage = () => {
    setPageNum((num) => num - 1);
  };
  return (
    <Dialog fullWidth={true} open={open} TransitionComponent={Transition}>
      <DialogContent>
        <div className="rowCenter">
          <Typography className="margin10Vertical rewardPurchaseItemName">
            {reward.name}
          </Typography>
          <div className="filler" />
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
        {pageNum === 1 && (
          <PageOne
            reward={reward}
            availablePoints={availablePoints}
            handleNextPage={handleNextPage}
            unitsSelected={unitsSelected}
            setUnitsSelected={setUnitsSelected}
            error={error}
            setError={setError}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        )}
        {pageNum === 2 && (
          <PageTwo
            reward={reward}
            availablePoints={availablePoints}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            unitsSelected={unitsSelected}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            addressLine1={addressLine1}
            setAddressLine1={setAddressLine1}
            addressLine2={addressLine2}
            setAddressLine2={setAddressLine2}
            city={city}
            setCity={setCity}
            state={state}
            setState={setState}
            zipCode={zipCode}
            setZipCode={setZipCode}
            totalCost={totalCost}
            user={user}
          />
        )}
        {pageNum === 3 && (
          <PageThree
            reward={reward}
            handleNextPage={handleNextPage}
            unitsSelected={unitsSelected}
            selectedAddress={{
              firstName: firstName,
              lastName: lastName,
              addressLine1: addressLine1,
              addressLine2: addressLine2,
              city: city,
              state: state,
              zipCode: zipCode,
            }}
            totalCost={totalCost}
            user={user}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RewardPurchaseForm;

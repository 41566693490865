import React from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import parsePhoneNumber from "libphonenumber-js";
const PageTwo = ({
  handleNextPage,
  handlePreviousPage,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  addressLine1,
  setAddressLine1,
  addressLine2,
  setAddressLine2,
  city,
  setCity,
  state,
  setState,
  zipCode,
  setZipCode,
  user,
}) => {
  return (
    <form className="columnMaxWidth" onSubmit={handleNextPage}>
      <Typography className="rewardPurchaseItemName">Email</Typography>
      <Typography className="rewardPurchaseItemDescription">
        {user.email}
      </Typography>
      <Typography className="margin10Top rewardPurchaseItemName">
        Phone Number
      </Typography>
      <Typography className="rewardPurchaseItemDescription">
        {parsePhoneNumber(user.phoneNumber, "US").formatNational()}
      </Typography>
      <Typography className="rewardPurchaseItemName margin10Top">
        Shipping Address
      </Typography>
      <div className="flex">
        <div className="column">
          <Typography className="rewardPurchaseFieldText">
            First Name
          </Typography>
          <TextField
            required
            variant="outlined"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="filler" />
        <div className="column">
          <Typography className="rewardPurchaseFieldText">Last Name</Typography>
          <TextField
            required
            variant="outlined"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      </div>
      <Typography className="rewardPurchaseFieldText  margin10Top">
        Address Line 1
      </Typography>
      <TextField
        required
        variant="outlined"
        placeholder="Address Line 1"
        value={addressLine1}
        onChange={(e) => setAddressLine1(e.target.value)}
      />
      <div className="flex margin10Top">
        <div className="column">
          <Typography className="rewardPurchaseFieldText">
            Address Line 2
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Address Line 2"
            value={addressLine2}
            onChange={(e) => setAddressLine2(e.target.value)}
          />
        </div>
        <div className="filler" />
        <div className="column">
          <Typography className="rewardPurchaseFieldText">ZIP Code</Typography>
          <TextField
            required
            variant="outlined"
            placeholder="ZIP Code"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
          />
        </div>
      </div>

      <div className="flex margin10Vertical">
        <div className="column">
          <Typography className="rewardPurchaseFieldText">City</Typography>
          <TextField
            required
            variant="outlined"
            placeholder="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </div>
        <div className="filler" />
        <div className="column">
          <Typography className="rewardPurchaseFieldText">State</Typography>
          <TextField
            required
            variant="outlined"
            placeholder="State"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
        </div>
      </div>

      <div className="flex margin10Top">
        <Button variant="text" onClick={handlePreviousPage}>
          <Typography className="rewardPurchaseBackButtonText">Back</Typography>
        </Button>
        <div className="filler" />
        <Button className="rewardPurchaseButton" type="submit">
          <Typography className="rewardPurchaseButtonText">Purchase</Typography>
        </Button>
      </div>
    </form>
  );
};
export default PageTwo;

import React, { useState, useEffect, Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import CardMedia from "@material-ui/core/CardMedia";
import { Link, Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import moment from "moment";
import { LoadMore } from "../../../components/table/Pagination";
import {
  getUserByID,
  getUsersInTrack,
} from "../../../functions/firebaseFunctions";
import { firestore } from "../../../firebase";
import TableComponent from "../../../components/table/TableComponent";
import { CustomTabs } from "../../../components/CustomTabs";
import EventPurchaseForm from "../leagueSignUp/EventPurchaseForm";
import { PriceBackgroundIcon } from "../../../res/SVGIcons";
import EventPageHeader from "../eventPage/EventPageHeader";
import EventPageInfo from "../eventPage/EventPageInfo";
import EventPageSideInfo from "../eventPage/EventPageSideInfo";
import {matchScheduleHeader} from "../../../components/Common";

const playerRecordsHeader = [
  { content: "Rank", label: "rank", sortable: true },
  { content: "Player", label: "player", sortable: true },
  { content: "Matches Win/Loss", label: "matches", sortable: true },
  { content: "% (Matches)", label: "percentMatches", sortable: true },
  { content: "Games Won/Loss", label: "games", sortable: true },
  { content: "% (Games)", label: "percentGames", sortable: true },
  { content: "Sets Won/Loss", label: "sets", sortable: true },
  { content: "% (Sets)", label: "percentSets", sortable: true },
  { content: "GSM Rating", label: "rating", sortable: true },
];
const cardLabels = ["General Information", "Participants"];

const EventView = ({
  user,
  viewType,
  mobileSize,
  zoneList,
}) => {
  const location = window.location.href;
  const info = location.split("/");
  const selectedPage = info[info.length - 1];
  const trackID = info[info.length - 2];
  const divisionID = info[info.length - 3];
  const leagueID = info[info.length - 4];
  const pageMap = {
    Information: 0,
    Participants: 1,
  };
  const [leaguePage, setLeaguePage] = useState(pageMap[selectedPage]);
  const [league, setLeague] = useState(null);
  const [leagueAreas, setLeagueAreas] = useState([]);
  const [division, setDivision] = useState(null);
  const [track, setTrack] = useState(null);
  const [loading, setLoading] = useState(true);
  const [director, setDirector] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [players, setPlayers] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const resultsPerPage = 16;
  const [playersPageNum, setPlayersPageNum] = useState(1);
  const indexOfLastPlayerItem = playersPageNum * resultsPerPage;
  const [playerRecords, setPlayerRecords] = useState([]);
  const [matchSchedule, setMatchSchedule] = useState([]);

  const [displayPurchaseButton, setDisplayPurchaseButton] = useState(true);
  const [openLeaguePurchase, setOpenLeaguePurchase] = useState(false);

  useEffect(() => {
    const leagueRef = firestore.collection("leagues").doc(leagueID);
    const unsubscribeLeague = leagueRef.onSnapshot((snapshot) => {
      setLeague({ ...snapshot.data(), id: snapshot.id });
    });
    const divisionRef = leagueRef.collection("divisions").doc(divisionID);
    const unsubscribeDivision = divisionRef.onSnapshot((snapshot) => {
      setDivision({ ...snapshot.data(), id: snapshot.id });
    });
    const trackRef = divisionRef.collection("tracks").doc(trackID);
    const unsubscribeTrack = trackRef.onSnapshot((snapshot) => {
      setTrack({ ...snapshot.data(), id: snapshot.id });
    });
    return () => {
      unsubscribeLeague();
      unsubscribeDivision();
      unsubscribeTrack();
    };
  }, [leagueID, divisionID, trackID]);
  useEffect(() => {
    setRedirect(false);
    const getData = async () => {
      let leagueAreas = [];
      league.locations.forEach((areaID) => {
        leagueAreas.push(zoneList.find((zone) => zone.areaID === areaID).area);
      });
      setLeagueAreas(leagueAreas);
      let directorTemp = null;
      if (league.directorID !== user?.uid)
        directorTemp = await getUserByID(league.directorID);
      else directorTemp = user;
      if (
        viewType === "view" ||
        user?.leaguesJoined.some(
          (leagueRecord) => leagueRecord.leagueID === leagueID
        )
      ) {
        setDisplayPurchaseButton(false);
      }
      setDirector(directorTemp);
      let playersTemp = await getUsersInTrack(track);
      let tempQuantity = [];
      await Promise.all(
        playersTemp.map(async (player) => {
          await firestore
            .collection("receipts")
            .where("userID", "==", player.id)
            .where("trackID", "==", trackID)
            .get()
            .then((doc) => {
              if (doc.docs.length > 0) {
                const documentSnapshot = doc.docs[0].data();
                tempQuantity.push(documentSnapshot.quantity);
              } else tempQuantity.push(1);
            })
            .catch((error) => {
              console.log(error);
            });
        })
      );
      setQuantity(tempQuantity);
      setPlayers(playersTemp);
      setLoading(false);
    };
    if (league && division && track && !openLeaguePurchase) getData();
    // eslint-disable-next-line
  }, [
    league,
    division,
    track,
    leagueID,
    divisionID,
    trackID,
    user,
    viewType,
    zoneList,
  ]);
  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );

  if (redirect) return <Redirect to={{ pathname: "/Leagues" }} />;

  return (
    <Grid className="maxHeight" container spacing={mobileSize ? 3 : 6}>
      <EventPageHeader
        league={league}
        division={division}
        track={track}
        mobileSize={mobileSize}
        leagueAreas={leagueAreas}
      />
      <Grid item xs={12} md={9} lg={9}>
        <Card className="card maxHeight">
          <CustomTabs
            labels={cardLabels}
            value={leaguePage}
            setValue={setLeaguePage}
          />
          <div className="leagueMainContentWrapper">
            {leaguePage === 0 && (
              <EventPageInfo league={league} mobileSize={mobileSize} />
            )}
            {leaguePage === 1 && (
              <Fragment>
                {players.length === 0 ? (
                  <Typography className="viewLeagueDescriptionText">
                    No players joined
                  </Typography>
                ) : (
                  <div className="columnCenter">
                    <Grid container spacing={mobileSize ? 0 : 4}>
                      {players
                        .slice(0, indexOfLastPlayerItem)
                        .map((player, key) => (
                          <Grid
                            className="columnCenter"
                            item
                            key={key}
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                            xl={3}
                          >
                            <Link to={`/Profile/ViewProfile/${player.id}`}>
                              <Card
                                className={`image250Rounded posRelative ${
                                  player.withdrawn
                                    ? "withdrawnPlayerBackground"
                                    : ""
                                }`}
                              >
                                <div className="viewLeagueProfileImageTextWrapper">
                                  {player.withdrawn && (
                                    <div className="withdrawTextOncard">
                                      Withdraw
                                    </div>
                                  )}
                                  {quantity[key] <= 1 ? (
                                    <Typography className="viewLeagueProfileImageHeader">
                                      {player.name}
                                    </Typography>
                                  ) : (
                                    <Typography className="viewLeagueProfileImageHeader">
                                      {player.firstName +
                                        " " +
                                        player.lastName +
                                        " +" +
                                        quantity[key]}
                                    </Typography>
                                  )}
                                  <Typography className="viewLeagueProfileImageText">
                                    {player.matches}
                                  </Typography>
                                </div>
                                <CardMedia
                                  variant="rounded"
                                  className="image250Rounded"
                                  image={player.imageURL}
                                />
                              </Card>
                            </Link>
                          </Grid>
                        ))}
                    </Grid>
                    <LoadMore setValue={setPlayersPageNum} />
                  </div>
                )}
              </Fragment>
            )}
            {leaguePage === 2 && (
              <Fragment>
                {league.status === "approved" ? (
                  <Typography className="viewLeagueDescriptionText">
                    Schedule Pending
                  </Typography>
                ) : (
                  <Fragment>
                    {league.format === "Flex" && (
                      <Typography className="viewLeagueDescriptionText">
                        {`Because this is a Flex League, this is just a
                        suggested schedule and location is a suggested location as well (see league rules for
                        details).`}
                      </Typography>
                    )}
                    <TableComponent
                      list={matchSchedule}
                      setList={setMatchSchedule}
                      tableHeader={matchScheduleHeader}
                      type="viewOnly"
                      paginationType="loadMore"
                      rowsPerPageOverride={10}
                    />
                  </Fragment>
                )}
              </Fragment>
            )}
            {leaguePage === 3 && (
              <Fragment>
                {league.status === "approved" ? (
                  <Typography className="viewLeagueDescriptionText">
                    Schedule Pending
                  </Typography>
                ) : (
                  <TableComponent
                    list={playerRecords}
                    setList={setPlayerRecords}
                    tableHeader={playerRecordsHeader}
                    type="viewOnly"
                    paginationType="loadMore"
                    rowsPerPageOverride={10}
                  />
                )}
              </Fragment>
            )}
          </div>
        </Card>
      </Grid>
      <Grid xs={12} md={3} lg={3} item>
        <Grid container spacing={mobileSize ? 3 : 6}>
          <Grid xs={12} item>
            <Card className="card posRelative">
              <div className="viewLeaguePriceTextWrapper">
                <Typography className="viewLeaguePriceText">
                  {league.price === "0" ? (
                    <span>&nbsp;&nbsp;</span>
                  ) : (
                    "$" + league.price
                  )}
                </Typography>
                <Typography className="viewLeaguePriceText">
                  {league.price === "0" ? "Free" : "Fee"}
                </Typography>
              </div>
              <div className="viewLeaguePriceBackground">
                <PriceBackgroundIcon width={90} height={80} />
              </div>
              <Typography className="viewLeagueCardHeader">
                Registration Details
              </Typography>
              <Grid className="margin20Vertical" container spacing={6}>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <Typography className="viewLeagueRegDateHeader">
                    Starts:
                  </Typography>
                  <Typography className="viewLeagueRegDateText">
                    {moment(league.startDate.toDate()).format("M/D/YYYY h:mmA")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <Typography className="viewLeagueRegDateHeader">
                    Ends:
                  </Typography>
                  {
                    <Typography className="viewLeagueRegDateText">
                      {moment(league.endDate.toDate()).format("M/D/YYYY h:mmA")}
                    </Typography>
                  }
                </Grid>
              </Grid>
              <Typography className="viewLeagueRegDeadlineText">
                {league.status === "approved" &&
                  `Registration Ends ${league.registrationEndDate}`}
                {league.status !== "approved" &&
                  !league.scheduleGenerated &&
                  "Registration has ended"}
                {league.status !== "approved" &&
                  league.status !== "expired" &&
                  league.scheduleGenerated &&
                  "Event in Progress"}
                {league.status === "expired" && "Event has ended"}
              </Typography>
              <Divider className="viewLeagueCardDivider margin20Vertical" />
              <Typography className="viewLeagueDescriptionText">
                This event can accommodate {league.numPlayerRange[1]}{" "}
                Participants
              </Typography>
              {user && <EventPurchaseForm
                  user={user}
                  league={league}
                  division={division}
                  track={track}
                  open={openLeaguePurchase}
                  setOpen={setOpenLeaguePurchase}
                  setDisplayPurchaseButton={setDisplayPurchaseButton}
                  setRedirect={setRedirect}
              />}
              {displayPurchaseButton && (
                <Button
                  onClick={()=>setOpenLeaguePurchase(true)}
                  className="viewLeagueCheckoutButton"
                >
                  <Typography className="viewLeagueCheckoutButtonText">
                    Checkout
                  </Typography>
                </Button>
              )}
            </Card>
          </Grid>
          <EventPageSideInfo league={league} director={director} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EventView;

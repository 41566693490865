import React, { useState, useEffect, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Loader from "react-loader-spinner";
import moment from "moment";
import { CustomTabs } from "../../../components/CustomTabs";
import TableComponent from "../../../components/table/TableComponent";
import { IntroductionEmail } from "../../../functions/Messaging";
import {
  getMailSentData,
  getMatchSchedulesByTrack,
  getUpadteMatchSchedule,
} from "../../../functions/firebaseFunctions";
import orderBy from "lodash.orderby";
import IconButton from "@material-ui/core/IconButton";
import ModeratedScheduleAddUpdateDilog from "../../../components/ModeratedScheduleAddUpdateDilog";
import { useHistory } from "react-router-dom";

const singlesHeader = [
  { label: "player1", content: "Player 1", sortable: true },
  { label: "player2", content: "Player 2", sortable: true },
  { label: "startDate", content: "Start Date", sortable: true },
  { label: "endDate", content: "End Date", sortable: true },
  { label: "location", content: "Location", sortable: true },
  { label: "status", content: "Status", sortable: true },
  { label: "intromail", content: "Intro Email", sortable: true },
];
const doublesHeader = [
  { label: "team1", content: "Team 1", sortable: true },
  { label: "team2", content: "Team 2", sortable: true },
  { label: "startDate", content: "Start Date", sortable: true },
  { label: "endDate", content: "End Date", sortable: true },
  { label: "location", content: "Location", sortable: true },
  { label: "status", content: "Status", sortable: true },
];


const DirectorLeagueMatches = ({
  isPickleball,
  league,
  status,
  pageLabels,
  tracks,
  userFirstName,
  noOfCourt,
  setNoOfCourt,
  setPageNum,
  pageNum,
  isPublic=false
}) => {
  const history = useHistory();
  const [matchSchedules, setMatchSchedules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disable, setDisable] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [scheduleId, setScheduleId] = useState("");
  const [popupType, setPopupType] = useState("");
  const [relod, setRelod] = useState(false);
  const isModerated = league?.format === "Moderated-dropin";

  const handleReload = async () => {
    tracks[pageNum].matches = await getMatchSchedulesByTrack(
      tracks?.[pageNum]?.track
    );
    setRelod(true);
  };

  const createSinglesMatchTable = (
    cells,
    player1,
    player2,
    startDate,
    endDate,
    location,
    status
  ) => {
    return {
      cells,
      player1,
      player2,
      startDate,
      endDate,
      location,
      status,
    };
  };
  const createDoublesMatchTable = (
    cells,
    team1,
    team2,
    startDate,
    endDate,
    location,
    status,
    id
  ) => {
    return {
      cells,
      team1,
      team2,
      startDate,
      endDate,
      location,
      status,
      id,
    };
  };
  useEffect(() => {
    setRelod(false);
    const createDoubelsSchedule = (
      startDate,
      endDate,
      venue,
      track,
      match,
      id
    ) => {
      const team1 = track.teams.find(
        (team) => team.id === match.participants[0]
      );
      const team2 = track.teams.find(
        (team) => team.id === match.participants[1]
      );
      return createDoublesMatchTable(
        [
          { content: team1.teamName, editable: false },
          { content: team2.teamName, editable: false },
          {
            content: moment(startDate).format("MM-DD-YYYY"),
            editable: false,
          },
          {
            content: moment(endDate).format("MM-DD-YYYY"),
            editable: false,
          },
          { content: venue, editable: false },
          { content: match.status, editable: false },
        ],
        team1.teamName,
        team2.teamName,
        startDate,
        endDate,
        venue,
        match.status,
        id
      );
    };
    setMatchSchedules(
      tracks
        .map((track) => {
          if (track) {
            let tableData = track.matches.map((match) => {
              const venue = match.venue;
              const startDate = match.start.toDate();
              const endDate = match.end.toDate();
              const date = new Date(track?.league?.endDate?.seconds * 1000);
              const enddate = date.toLocaleDateString("en-US");
              const isMailSent = match?.isMailSent ? match?.isMailSent : false;

              if (isModerated) {
                const status = match.status;
                return createModeratedSchedule(
                  startDate,
                  endDate,
                  venue,
                  status,
                  match?.id
                );
              } else {
                if (!track.division.doublesFormat)
                  return createSinglesSchedule(
                    startDate,
                    endDate,
                    venue,
                    track,
                    match,
                    enddate,
                    isMailSent,
                    match.id
                  );
                else
                  return createDoubelsSchedule(
                    startDate,
                    endDate,
                    venue,
                    track,
                    match,
                    match.id
                  );
              }
            });
            return orderBy(tableData, "startDate", "asc");
          }
        })
        .sort((a, b) => a.startDate - b.startDate)
    );

    setLoading(false);
  }, [status, tracks, relod]);

  const createModeratedSchedule = (startDate, endDate, venue, status, id) => {
    return createDoublesMatchTable(
      [
        { content: "OPEN", editable: false },
        { content: "OPEN", editable: false },
        {
          content: moment(startDate).format("MM-DD-YYYY"),
          editable: false,
        },
        {
          content: moment(endDate).format("MM-DD-YYYY"),
          editable: false,
        },
        { content: venue, editable: false },
        { content: status, editable: false },
      ],
      "OPEN",
      "OPEN",
      startDate,
      endDate,
      venue,
      status,
      id
    );
  };

  const createSinglesSchedule = (
    startDate,
    endDate,
    venue,
    track,
    match,
    leagueEndDate,
    isMailSent,
    id
  ) => {
    const p1 = track.players.find(
      (player) => player.id === match.participants[0]
    );
    const p2 = track.players.find(
      (player) => player.id === match.participants[1]
    );
    const p1Name = `${p1?.firstName} ${p1?.lastName}`;
    const p2Name = `${p2?.firstName} ${p2?.lastName}`;
    const mailStatus = isMailSent ? "Sent" : "Not Sent - Send Now";
    return createSinglesMatchTable(
      [
        { content: p1Name, editable: false },
        { content: p2Name, editable: false },
        {
          content: moment(startDate).format("MM-DD-YYYY"),
          editable: false,
        },
        {
          content: moment(endDate).format("MM-DD-YYYY"),
          editable: false,
        },
        { content: venue, editable: false },
        { content: match.status, editable: false },
        {
          content: mailStatus,
          isButton: true,
          mailData: {
            id: id,
            player1: {
              firstName: p1?.firstName,
              email: p1?.email,
              gsmRating: p1?.gsmRating,
              ntrpRating: p1?.ntrpRating,
            },
            player2: {
              firstName: p2?.firstName,
              email: p2?.email,
              gsmRating: p2?.gsmRating,
              ntrpRating: p2?.ntrpRating,
            },
            leagueEndDate: leagueEndDate,
          },
        },
      ],
      p1Name,
      p2Name,
      startDate,
      endDate,
      venue,
      match.status,
      mailStatus
    );
  };

  const handleSendMail = async (maildata) => {
    setDisable(true);
    const subject =
      "Introduction – Your League Match (powered by Game.Set.Match)";
    const message = `Hello ${maildata?.player1?.firstName} and ${maildata?.player2?.firstName}.
  Reminder: The league will end on ${maildata?.leagueEndDate}. I just want to introduce you both to each other.
  ${maildata?.player1?.firstName}is a ${maildata?.player1?.ntrpRating} player and has a GSM Rating of ${maildata?.player1?.gsmRating}; email address: ${maildata?.player1?.email}
  ${maildata?.player2?.firstName} is a ${maildata?.player2?.ntrpRating} player and has a GSM Rating of ${maildata?.player2?.gsmRating}. email address: ${maildata?.player2?.email}
  You both should start scheduling your match sometime soon. Let me know if you need anything to make your experience a positive one.
  Have fun and Best of Luck.

  League Coordinator - ${userFirstName}.
  `;
    try {
      let issuccess = await IntroductionEmail({
        message: message,
        subject: subject,
        recipients: [
          {
            email: maildata?.player1?.email,
            name: maildata?.player1?.firstName,
          },
          {
            email: maildata?.player2?.email,
            name: maildata?.player2?.firstName,
          },
        ],
      });
      if (issuccess) {
        await getUpadteMatchSchedule(maildata.id);
        let updatedList = await getMailSentData(league.id);
        let clonearry = updatedList;
        clonearry.push(maildata.id);
        if (updatedList.length > 0) {
          setMatchSchedules(
            tracks.map((track) => {
              return track.matches
                .map((match) => {
                  const venue = match.venue;
                  const startDate = match.start.toDate();
                  const endDate = match.end.toDate();
                  const date = new Date(track?.league?.endDate?.seconds * 1000);
                  const enddate = date.toLocaleDateString("en-US");
                  const isMailSent = clonearry.includes(match.id);
                  if (!track.division.doublesFormat)
                    return createSinglesSchedule(
                      startDate,
                      endDate,
                      venue,
                      track,
                      match,
                      enddate,
                      isMailSent,
                      match.id
                    );
                })
                .sort((a, b) => a.startDate - b.startDate);
            })
          );
        }
      }
      setDisable(false);
    } catch (error) {
      console.log("IntroductionEmail", error);
      setDisable(false);
    }
  };

  const handleEdit = (raw, type) => {
    if (type === "isStart") {
      let selectedTrackMatches = tracks?.[pageNum]?.matches;
      let isMatchInProgress = false;
      for (let index = 0; index < selectedTrackMatches.length; index++) {
        const trackMatchStatus = selectedTrackMatches[index].status;
        if (trackMatchStatus === "In Progress") {
          isMatchInProgress = true;
        }
      }
      setPopupType(isMatchInProgress ? "InProgressErr" : "isStart");
    }

    setIsOpen(true);
    setScheduleId(raw);
  };

  const handleRedirectToModerateScreen = (isModerated, id) => {
    history.push({
      pathname: `/Director/EventModeration`,
      state: {
        isEditModerated: isModerated,
        trackId: tracks[pageNum].track.id,
        leagueId: tracks[pageNum].track.leagueID,
        divisionId: tracks[pageNum].track.divisionID,
        trackPlayers: tracks[pageNum].track.players,
        selectedMatchIdForModerate: id,
        noOfCourt: Number(noOfCourt),
        isPickleball: isPickleball,
      },
    });
  };

  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  return (
    <div className="column filler">
      {isOpen && (
        <ModeratedScheduleAddUpdateDilog
          isOpen={isOpen}
          id={scheduleId}
          handleClose={() => {
            setIsOpen(false);
            setPopupType("");
            setScheduleId("");
          }}
          handleRedirectToModerateScreen={() =>
            handleRedirectToModerateScreen(false, scheduleId.id)
          }
          noOfCourt={noOfCourt}
          setNoOfCourt={setNoOfCourt}
          handleReload={handleReload}
          type={popupType}
          track={tracks?.[pageNum]?.track}
        ></ModeratedScheduleAddUpdateDilog>
      )}
      <CustomTabs labels={pageLabels} value={pageNum} setValue={setPageNum} />
      {isModerated && league.status !== "expired" && !isPublic && (
        <div className="alignEnd">
          <IconButton
            onClick={() => {
              setIsOpen(true);
              setPopupType("isAdd");
            }}
            className="linkbutton"
          >
            Add Schedule
          </IconButton>
        </div>
      )}
      <div className="column filler">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {matchSchedules.map((schedule, key) => (
              <Fragment key={key}>
                {key === pageNum && (
                  <TableComponent
                    isStartDisable={league.status === "approved"}
                    disable={disable}
                    handleSendMail={(mailData) => handleSendMail(mailData)}
                    list={schedule || []}
                    nestedList={matchSchedules}
                    setList={setMatchSchedules}
                    tableHeader={
                      isModerated
                        ? doublesHeader
                        : tracks?.[key]?.division?.doublesFormat
                        ? doublesHeader
                        : singlesHeader
                    }
                    handleEdit={(raw) => {
                      handleEdit(raw, "isUpdate");
                      setPopupType("isUpdate");
                    }}
                    handleDelete={(raw) => {
                      handleEdit(raw, "isDelete");
                      setPopupType("isDelete");
                    }}
                    handleStart={(raw) => {
                      handleEdit(raw, "isStart");
                    }}
                    redirectModeratedScreen={(raw) => {
                      handleRedirectToModerateScreen(true, raw.id);
                    }}
                    type={(isModerated && !isPublic) ? "lineEdit" : "viewOnly"}
                    rowsPerPageOverride={10}
                    tableNum={pageNum}
                  />
                )}
              </Fragment>
            ))}
          </Grid>
        </Grid>
        <div className="filler" />
      </div>
    </div>
  );
};
export default DirectorLeagueMatches;

import React, {useEffect, useState} from "react";
import {firestore} from "../../firebase";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Loader from "react-loader-spinner";
import ListItemActivity from "./ListItemActivity";
import {
    getDoublesTeamByID,
    getFirebaseDoc,
    getLeagueTracksById,
    getScore,
    getUserByID,
} from "../../functions/firebaseFunctions";
import {LoadMore} from "../../components/table/Pagination";

const Activities = ({ mobileSize, leagues, divisions, tracks, user }) => {
  const [activities, setActivities] = useState([]);
  const [sortBy, setSortBy] = useState("Newest-Oldest");
  const [loading, setLoading] = useState(true);
  const [sortOptions] = useState(["Oldest-Newest", "Newest-Oldest"]);
  const resultsPerPage = 6;
  const [pageNum, setPageNum] = useState(1);
  const indexOfLastItem = pageNum * resultsPerPage;
  const matchCategory = ["leagueApproved", "userJoinedLeague"];

  useEffect(() => {
    const activitiesRef = firestore
      .collection("activities")
      .where("sportType", "in", [user?.currentSport || "tennis", "both"])
      .orderBy("date", "desc")
      .limit(100);
    setLoading(true);
    const unsubscribeActivities = activitiesRef.onSnapshot(
      async (activitiesSnapshot) => {
        let activities = [];
        await Promise.all(
          activitiesSnapshot.docs.map(async (activityDoc) => {
            const activity = activityDoc.data();
            const activityID = activityDoc.id;
            const activityRef = activityDoc.ref;
            const date = moment(activity.date.toDate()).format("MMM D, YYYY");
            if (
              [
                "userJoined",
                "profilePhoto",
                "leagueStart",
                "playoffsStart",
                "eventStart",
              ].some((cat) => cat === activity.category)
            )
              return;

              if(matchCategory.includes(activity.category)){
                  activity.leaguesData = await getLeagueTracksById(activity.leagueID, "purchase");
              }

            if (
              activity.category !== "matchPlayed" &&
              activity.category !== "finalsCompleted"
            ) {
              let player = await getUserByID(activity.players[0]);
              activities.push({
                activity,
                player,
                date,
                id: activityID,
                ref: activityRef,
              });
            } else {
              const score = await getScore(activity.scoreID);
              const winner = score.doublesFormat
                ? await getDoublesTeamByID(score.winner)
                : await getUserByID(score.winner);
              const loser = (await score.doublesFormat)
                ? await getDoublesTeamByID(score.loser)
                : await getUserByID(score.loser);
              const league = leagues[score.leagueID]
                ? leagues[score.leagueID]
                : await getFirebaseDoc(`leagues/${score.leagueID}`);
              const division = divisions[score.divisionID]
                ? divisions[score.divisionID]
                : await getFirebaseDoc(
                    `leagues/${score.leagueID}/divisions/${score.divisionID}`
                  );
              const track = tracks[score.trackID]
                ? tracks[score.trackID]
                : await getFirebaseDoc(
                    `leagues/${score.leagueID}/divisions/${score.divisionID}/tracks/${score.trackID}`
                  );
              activities.push({
                activity,
                score,
                winner,
                league,
                division,
                track,
                loser,
                date,
                id: activityID,
                ref: activityRef,
              });
            }
          })
        );
        setActivities(activities);
        setLoading(false);
      }
    );
    return () => unsubscribeActivities();
    // eslint-disable-next-line
  }, [user?.currentSport]);

  const sortListings = (a, b) => {
    const aDate = a.activity.date;
    const bDate = b.activity.date;
    if (sortBy === "Oldest-Newest") {
      return aDate - bDate;
    } else if (sortBy === "Newest-Oldest") {
      return bDate - aDate;
    }
    return;
  };

  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
        />
      </div>
    );
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className="rowCenter">
        <div className="filler" />
        {!mobileSize && (
          <Typography className="activityFieldSortText">Sort by:</Typography>
        )}

        <TextField
          variant="outlined"
          select
          value={sortBy}
          onChange={(e) => {
            setSortBy(e.target.value);
          }}
        >
          {sortOptions.map((s, i) => (
            <MenuItem key={i} value={s}>
              <Typography className="activitySortText">{s}</Typography>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} className="columnCenter">
        {activities
          .sort((a, b) => sortListings(a, b))
          .slice(0, indexOfLastItem)
          .map((activityInfo, i) => (
            <ListItemActivity key={i} activityInfo={activityInfo} user={user} />
          ))}
        <div className="filler" />
        {activities.length > resultsPerPage * pageNum && (
          <LoadMore setValue={setPageNum} />
        )}
      </Grid>
    </Grid>
  );
};
export default Activities;

import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Loader from "react-loader-spinner";
import AdminManageRewards from "./rewards/AdminManageRewards";
import AdminReviewOverview from "./AdminReviewOverview";
import AdminLeagueOverview from "./leagues/AdminLeagueOverview";
import AdminManageDiscounts from "./discounts/AdminManageDiscounts";
import AdminLeaguePurchaseOverview from "./AdminLeaguePurchaseOverview";
import AdminManageDirectors from "./AdminManageDirectors";
import AdminUserOverview from "./AdminUserOverview";
import DirectorActivity from "../director/DirectorActivity";
import SideMenu from "../../components/SideMenu";
import Content from "../../components/Content";
import AdminBackendSupport from "./backend-support/AdminBackendSupport";

const routes = [
  "Users",
  "Leagues & Events",
  "Reviews",
  "Receipts",
  "Rewards",
  "Discounts",
  "Applications",
  "Post Activity",
  "Backend Support",
];
const AdminPage = ({
  user,
  leagues,
  divisions,
  tracks,
  zoneList,
  mobileSize,
}) => {
  const [pageNum, setPageNum] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [leagues, divisions, tracks]);
  const handlePageSelection = (index) => {
    setPageNum(index);
  };
  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  return (
    <Grid container spacing={mobileSize ? 3 : 6}>
      <SideMenu>
        <div className="rowCenter">
          <Avatar
            variant="rounded"
            src={user.imageURL}
            className="image200Rounded"
          />
          <div className="column margin10Left">
            <Typography className="profileInfoCardTitle">
              Admin Tools
            </Typography>
          </div>
        </div>
        <div className="columnCenter  margin30Top">
          {routes.map((route, index) => (
            <Button
              key={index}
              variant="text"
              onClick={() => handlePageSelection(index)}
              fullWidth
              className="profileNavButton"
            >
              <div className="rowCenter margin10Vertical">
                <Typography
                  className={
                    pageNum !== index
                      ? "profileNavButtonTextUnselected"
                      : "profileNavButtonTextSelected"
                  }
                >
                  {route}
                </Typography>
              </div>
            </Button>
          ))}
        </div>
      </SideMenu>
      <Content>
        {pageNum === 0 && <AdminUserOverview user={user} zoneList={zoneList} />}
        {pageNum === 1 && (
          <AdminLeagueOverview
            user={user}
            zoneList={zoneList}
            leagues={leagues}
            divisions={divisions}
            tracks={tracks}
          />
        )}
        {pageNum === 2 && (
          <AdminReviewOverview
            user={user}
            leagues={leagues}
            divisions={divisions}
            tracks={tracks}
          />
        )}
        {pageNum === 3 && (
          <AdminLeaguePurchaseOverview
            user={user}
            leagues={leagues}
            divisions={divisions}
            tracks={tracks}
          />
        )}
        {pageNum === 4 && <AdminManageRewards user={user} />}
        {pageNum === 5 && <AdminManageDiscounts user={user} />}
        {pageNum === 6 && (
          <AdminManageDirectors
            user={user}
            leagues={leagues}
            divisions={divisions}
            tracks={tracks}
          />
        )}
        {pageNum === 7 && (
          <DirectorActivity user={user} leagueName="Admin" status="Admin" />
        )}
        {pageNum === 8 && <AdminBackendSupport user={user} />}
      </Content>
    </Grid>
  );
};

export default AdminPage;

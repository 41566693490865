import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Clear from "@material-ui/icons/Clear";
import Loader from "react-loader-spinner";
import moment from "moment";
import update from "immutability-helper";
import orderby from "lodash.orderby";
import {
  getScoresByLeague,
  getUserByID,
} from "../../../functions/firebaseFunctions";
import { firestore } from "../../../firebase";
const ManageMatches = ({ user, leagues, divisions, tracks }) => {
  const [loading, setLoading] = useState(true);
  const [lists, setLists] = useState([]);
  const [editing, setEditing] = useState([]);
  const [directorLeagues, setDirectorLeagues] = useState([]);
  const [filterByDivision, setFilterByDivision] = useState([]);
  const [filterByLevel, setFilterByLevel] = useState([]);
  const [filterByTrack, setFilterByTrack] = useState([]);
  const [divisionSelect, setDivisionSelect] = useState([]);
  const [levelSelect, setLevelSelect] = useState([]);
  const [trackSelect, setTrackSelect] = useState([]);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortSelected, setSortSelected] = useState("date");
  const [scores, setScores] = useState([]);
  const createScoreItem = (setOne, setTwo, setThree) => {
    return { setOne, setTwo, setThree };
  };
  const createListItem = (
    date,
    winner,
    winnerName,
    wSetOne,
    wSetTwo,
    wSetThree,
    loser,
    loserName,
    lSetOne,
    lSetTwo,
    lSetThree,
    retired,
    noshow,
    division,
    level,
    track,
    matchType,
    score
  ) => {
    return {
      date,
      winner,
      winnerName,
      wSetOne,
      wSetTwo,
      wSetThree,
      loser,
      loserName,
      lSetOne,
      lSetTwo,
      lSetThree,
      retired,
      noshow,
      division,
      level,
      track,
      matchType,
      score,
    };
  };
  useEffect(() => {
    const getData = async () => {
      let listTemp = [];
      let leaguesTemp = [];
      let tracksSelectTemp = [];
      let divisionsSelectTemp = [];
      let levelsSelectTemp = [];
      let filterByDivisionTemp = [];
      let filterByLevelTemp = [];
      let filterByTrackTemp = [];
      let sortDirectionTemp = [];
      let sortSelectedTemp = [];
      let editingTemps = [];
      let scoresTemps = [];
      await Promise.all(
        Object.keys(leagues).map(async (leagueID) => {
          let league = leagues[leagueID];
          if (
            league.directorID === user.uid &&
            league.status !== "pending" &&
            league.status !== "draft"
          ) {
            let trackSelectTemp = ["All"];
            let divisionSelectTemp = ["All"];
            let levelSelectTemp = ["All"];
            Object.keys(tracks).forEach((trackID) => {
              let track = tracks[trackID];
              if (track) {
                let division = divisions[track.divisionID];
                if (!trackSelectTemp.find((t) => t === track.track + +1))
                  trackSelectTemp.push(track.track + 1);
                if (!divisionSelectTemp.find((d) => d === division.division))
                  divisionSelectTemp.push(division.division);
                if (!levelSelectTemp.find((l) => l === division.level))
                  levelSelectTemp.push(division.level);
              }
            });
            tracksSelectTemp.push(trackSelectTemp);
            divisionsSelectTemp.push(divisionSelectTemp);
            levelsSelectTemp.push(levelSelectTemp);
            filterByDivisionTemp.push("All");
            filterByLevelTemp.push("All");
            filterByTrackTemp.push("All");
            sortDirectionTemp.push("asc");
            sortSelectedTemp.push("date");
            let leagueListTemp = [];
            let editingTemp = [];
            let scoresTemp = [];
            leaguesTemp.push(league);
            let scores = await getScoresByLeague(league);
            await Promise.all(
              scores.map(async (s) => {
                editingTemp.push(false);
                let winner = await getUserByID(s.winner);
                let loser = await getUserByID(s.loser);
                let dateParsed = s.matchDate.split("/");
                let date = new Date(
                  dateParsed[2],
                  dateParsed[0] - 1,
                  dateParsed[1]
                );
                let scoreTrack = tracks[s.trackID];
                let scoreDivision = divisions[scoreTrack.divisionID];
                scoresTemp.push(
                  createScoreItem(
                    s.scoringFormat !== "10GamePro" &&
                      s.scoringFormat !== "1Gameto15or21points"
                      ? s.setOneScore
                      : s.proSetScore,
                    s.setTwoScore,
                    s.setThreeScore
                  )
                );

                leagueListTemp.push(
                  createListItem(
                    date,
                    winner,
                    `${winner.firstName} ${winner.lastName}`,
                    s.scoringFormat === "10GamePro" ||
                      s.scoringFormat === "1Gameto15or21points"
                      ? s.proSetScore[0]
                      : s.setOneScore[0],
                    s.setTwoScore[0],
                    s.setThreeScore[0],
                    loser,
                    `${loser.firstName} ${loser.lastName}`,
                    s.scoringFormat === "10GamePro" ||
                      s.scoringFormat === "1Gameto15or21points"
                      ? s.proSetScore[1]
                      : s.setOneScore[1],
                    s.setTwoScore[1],
                    s.setThreeScore[1],
                    s.retired ? "Yes" : "No",
                    s.noshow ? "Yes" : "No",
                    scoreDivision.division,
                    scoreDivision.level,
                    scoreTrack.track + 1,
                    s.scoringFormat,
                    s
                  )
                );
              })
            );
            scoresTemps.push(scoresTemp);
            listTemp.push(leagueListTemp);
            editingTemps.push(editingTemp);
          }
        })
      );
      setScores(scoresTemps);
      setEditing(editingTemps);
      setFilterByDivision(filterByDivisionTemp);
      setFilterByLevel(filterByLevelTemp);
      setFilterByTrack(filterByTrackTemp);
      setDivisionSelect(divisionsSelectTemp);
      setLevelSelect(levelsSelectTemp);
      setTrackSelect(tracksSelectTemp);
      setSortDirection(sortDirectionTemp);
      setSortSelected(sortSelectedTemp);
      setDirectorLeagues(leaguesTemp);
      setLists(listTemp);
      setLoading(false);
    };
    getData();
  }, [user, leagues, divisions, tracks]);
  const divisionFilter = (item, index) => {
    if (filterByDivision[index] === "All") return true;
    return filterByDivision[index] === item.division;
  };
  const levelFilter = (item, index) => {
    if (filterByLevel[index] === "All") return true;
    return filterByLevel[index] === item.level;
  };
  const trackFilter = (item, index) => {
    if (filterByTrack[index] === "All") return true;
    return filterByTrack[index] === item.track;
  };
  const sortList = (sort, index) => {
    let temp1 = update(sortSelected, {
      [index]: { $set: sort },
    });
    setSortSelected((sortSelected) => temp1);

    let temp2 = update(sortDirection, {
      [index]: { $set: sortDirection[index] === "asc" ? "desc" : "asc" },
    });
    setSortDirection((sortDirection) => temp2);

    let temp3 = update(lists, {
      [index]: {
        $set: orderby(lists[index], sortSelected[index], sortDirection[index]),
      },
    });
    setLists(temp3);
  };
  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  return (
    <div className="pageWrapper">
      <Grid container spacing={6}>
        {directorLeagues.map((dL, i) => (
          <Grid key={i} item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card className="infoCardLeft">
              <Typography variant="h4">{dL.name}</Typography>
              <Divider className="margin10" />
              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <TextField
                    className="maxWidth"
                    variant="standard"
                    select
                    value={filterByDivision[i]}
                    onChange={(e) => {
                      const temp = update(filterByDivision, {
                        [i]: { $set: e.target.value },
                      });
                      setFilterByDivision(temp);
                    }}
                    label="Filter by division"
                  >
                    {divisionSelect[i].map((x, j) => (
                      <MenuItem value={x} key={j}>
                        {x}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <TextField
                    className="maxWidth"
                    variant="standard"
                    select
                    value={filterByLevel[i]}
                    onChange={(e) => {
                      const temp = update(filterByLevel, {
                        [i]: { $set: e.target.value },
                      });
                      setFilterByLevel(temp);
                    }}
                    label="Filter by Level"
                  >
                    {levelSelect[i].map((x, j) => (
                      <MenuItem value={x} key={j}>
                        {x}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <TextField
                    className="maxWidth"
                    variant="standard"
                    select
                    value={filterByTrack[i]}
                    onChange={(e) => {
                      const temp = update(filterByTrack, {
                        [i]: { $set: e.target.value },
                      });
                      setFilterByTrack(temp);
                    }}
                    label="Filter by Track"
                  >
                    {trackSelect[i].map((x, j) => (
                      <MenuItem value={x} key={j}>
                        {x}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Actions</TableCell>
                      <TableCell
                        align="left"
                        onClick={() => sortList("date", i)}
                      >
                        <TableSortLabel
                          direction={sortDirection[i]}
                          active={"date" === sortSelected[i]}
                        >
                          Date
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() => sortList("winnerName", i)}
                      >
                        <TableSortLabel
                          direction={sortDirection[i]}
                          active={"winnerName" === sortSelected[i]}
                        >
                          Winner
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">Set One Score</TableCell>
                      <TableCell align="left">Set Two Score</TableCell>
                      <TableCell align="left">Set Three Score</TableCell>
                      <TableCell
                        align="left"
                        onClick={() => sortList("loserName", i)}
                      >
                        <TableSortLabel
                          direction={sortDirection[i]}
                          active={"loserName" === sortSelected[i]}
                        >
                          Loser
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">Set One Score</TableCell>
                      <TableCell align="left">Set Two Score</TableCell>
                      <TableCell align="left">Set Three Score</TableCell>
                      <TableCell align="left">Retired</TableCell>
                      <TableCell align="left">No Show</TableCell>
                      <TableCell align="left">Match Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lists[i]
                      .filter((item) => divisionFilter(item, i))
                      .filter((item) => levelFilter(item, i))
                      .filter((item) => trackFilter(item, i))
                      .map((row, j) => (
                        <TableRow hover key={j}>
                          <TableCell align="left">
                            <Tooltip title={editing[i][j] ? "Cancel" : "Edit"}>
                              <IconButton
                                onClick={(e) => {
                                  let temp = update(editing, {
                                    [i]: {
                                      [j]: {
                                        $set: !editing[i][j],
                                      },
                                    },
                                  });
                                  setEditing(temp);
                                }}
                              >
                                {editing[i][j] ? <Clear /> : <Edit />}
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={async (e) => {
                                  e.preventDefault();
                                  await firestore
                                    .doc(`scores/${row.score.id}`)
                                    .delete();
                                  window.location.reload();
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                            <Button
                              onClick={async (e) => {
                                e.preventDefault();
                                if (
                                  row.matchType === "10GamePro" ||
                                  row.matchType === "1Gameto15or21points"
                                )
                                  await firestore
                                    .doc(`scores/${row.score.id}`)
                                    .update({
                                      proSetScore: scores[i][j].setOne,
                                    });
                                else
                                  await firestore
                                    .doc(`scores/${row.score.id}`)
                                    .update({
                                      setOneScore: scores[i][j].setOne,
                                      setTwoScore: scores[i][j].setTwo,
                                      setThreeScore: scores[i][j].setThree,
                                    });
                                window.location.reload();
                              }}
                            >
                              Save
                            </Button>
                          </TableCell>
                          <TableCell align="left">
                            {moment(row.date).format("M/D/YYYY")}
                          </TableCell>
                          <TableCell align="left">{row.winnerName}</TableCell>
                          <TableCell align="left">
                            {editing[i][j] ? (
                              <TextField
                                value={scores[i][j].setOne[0]}
                                onChange={(e) => {
                                  let temp = update(scores, {
                                    [i]: {
                                      [j]: {
                                        setOne: {
                                          0: {
                                            $set: e.target.value,
                                          },
                                        },
                                      },
                                    },
                                  });
                                  setScores(temp);
                                }}
                              />
                            ) : (
                              row.wSetOne
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {editing[i][j] ? (
                              <TextField
                                value={scores[i][j].setTwo[0]}
                                onChange={(e) => {
                                  let temp = update(scores, {
                                    [i]: {
                                      [j]: {
                                        setTwo: {
                                          0: {
                                            $set: e.target.value,
                                          },
                                        },
                                      },
                                    },
                                  });
                                  setScores(temp);
                                }}
                              />
                            ) : (
                              row.wSetTwo
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {editing[i][j] ? (
                              <TextField
                                value={scores[i][j].setThree[0]}
                                onChange={(e) => {
                                  let temp = update(scores, {
                                    [i]: {
                                      [j]: {
                                        setThree: {
                                          0: {
                                            $set: e.target.value,
                                          },
                                        },
                                      },
                                    },
                                  });
                                  setScores(temp);
                                }}
                              />
                            ) : (
                              row.wSetThree
                            )}
                          </TableCell>
                          <TableCell align="left">{row.loserName}</TableCell>
                          <TableCell align="left">
                            {editing[i][j] ? (
                              <TextField
                                value={scores[i][j].setOne[1]}
                                onChange={(e) => {
                                  let temp = update(scores, {
                                    [i]: {
                                      [j]: {
                                        setOne: {
                                          1: {
                                            $set: e.target.value,
                                          },
                                        },
                                      },
                                    },
                                  });
                                  setScores(temp);
                                }}
                              />
                            ) : (
                              row.lSetOne
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {editing[i][j] ? (
                              <TextField
                                value={scores[i][j].setTwo[1]}
                                onChange={(e) => {
                                  let temp = update(scores, {
                                    [i]: {
                                      [j]: {
                                        setTwo: {
                                          1: {
                                            $set: e.target.value,
                                          },
                                        },
                                      },
                                    },
                                  });
                                  setScores(temp);
                                }}
                              />
                            ) : (
                              row.lSetTwo
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {editing[i][j] ? (
                              <TextField
                                value={scores[i][j].setThree[1]}
                                onChange={(e) => {
                                  let temp = update(scores, {
                                    [i]: {
                                      [j]: {
                                        setThree: {
                                          1: {
                                            $set: e.target.value,
                                          },
                                        },
                                      },
                                    },
                                  });
                                  setScores(temp);
                                }}
                              />
                            ) : (
                              row.lSetThree
                            )}
                          </TableCell>
                          <TableCell align="left">{row.retired}</TableCell>
                          <TableCell align="left">{row.noshow}</TableCell>
                          <TableCell align="left">{row.matchType}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ManageMatches;

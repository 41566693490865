import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";
import SideMenu from "../../components/SideMenu";
import Content from "../../components/Content";
import Information from "./Information";
import Preferences from "./Preferences";
import Statistics from "./Statistics";
import ManageAccount from "./ManageAccount";
import DirectorDraft from "./DirectorsDraft.js";
const routes = [
  "About",
  "Preferences",
  "Statistics",
  "Manage Account",
  "Director Application",
];
const Profile = ({
  user,
  leagues,
  divisions,
  tracks,
  zoneList,
  mobileSize,
}) => {
  const [pageNum, setPageNum] = useState(0);

  const handlePageSelection = (index) => {
    setPageNum(index);
  };

  return (
    <Grid container spacing={mobileSize ? 3 : 6}>
      <SideMenu>
        <div className="rowCenter">
          <Avatar src={user.imageURL} className="image150" />
          <div className="column margin10Left">
            <Typography className="profileInfoCardTitle">{`${user.firstName} ${user.lastName}`}</Typography>
            <Typography className="profileInfoCardSubtitle">{`${user.matchPoints} Match Points`}</Typography>
            {!user.director ? (
              <div>
                <Link to="/DirectorApplication" className="noUnderline">
                  <Button className="createLeagueNextButton">
                    Apply for Director
                  </Button>
                </Link>{" "}
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
        <div className="columnCenter  margin30Top">
          {routes.map((route, index) => (
            <Button
              key={index}
              variant="text"
              onClick={() => handlePageSelection(index)}
              fullWidth
              className="profileNavButton"
            >
              <div className="rowCenter margin10Vertical">
                <Typography
                  className={
                    pageNum !== index
                      ? "profileNavButtonTextUnselected"
                      : "profileNavButtonTextSelected"
                  }
                >
                  {route}
                </Typography>
              </div>
            </Button>
          ))}
        </div>
      </SideMenu>
      <Content>
        {pageNum === 0 && (
          <Information
            user={user}
            zoneList={zoneList}
            mobileSize={mobileSize}
          />
        )}
        {pageNum === 1 && <Preferences user={user} />}
        {pageNum === 2 && (
          <Statistics
              instance={user?.currentSport}
            user={user}
            leagues={leagues}
            divisions={divisions}
            tracks={tracks}
            viewingOtherPlayer={false}
          />
        )}
        {pageNum === 3 && <ManageAccount user={user} />}
        {pageNum === 4 && <DirectorDraft user={user} />}
      </Content>
    </Grid>
  );
};

export default Profile;

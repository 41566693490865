import React from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Info from "@material-ui/icons/Info";
import Close from "@material-ui/icons/Close";
export default function SimplePopover() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const levelDescriptions = [

    { 
        label: "Gold Split (Director 80%, Platform 20%)",
        content: 
        " - This is where the director will be doing his own marketing to his own players" },
    {
        label: "Silver Split (Director 50%, Platform 50%)",
        content:
        " - This is where GSM will provide digital marketing services to get directors sign-ups to his events and leagues",
    },
    {
        label: "Bronze Split (Director 20%, Platform 80%)",
        content:
        " - This is reserved for employees and reserved partners",
    },
  ];
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="signUpRatingWrapper">
      <IconButton onClick={handleClick}>
        <Info className="signUpIcon" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="signUpRatingDescriptionWrapper">
          <div className="rowCenter">
            <Typography className="margin10Vertical rewardPurchaseItemName">
              Split Options
            </Typography>
            <div className="filler" />
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
          {levelDescriptions.map((item, key) => (
            <Typography className="margin10Top" key={key}>
              <span className="signUpRatingText">
                {(key + 1)} - {item.label}{" "}
              </span>
              {item.content}
            </Typography>
          ))}
        </div>
      </Popover>
    </div>
  );
}

import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import LocationOn from "@material-ui/icons/LocationOn";
import { FileCopy } from "@material-ui/icons";
const LeaguePageHeader = ({
  league,
  division,
  track,
  mobileSize,
  leagueAreas,
}) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipBoard = () => {
    setCopySuccess(true);
    const text = document.createElement("textarea");
    text.innerText = window.location.href;
    document.body.appendChild(text);
    text.select();
    document.execCommand("copy");
    text.remove();
  };

  return (
    <Grid item xs={12}>
      <Card
        className="leagueHeaderImage card"
        style={{
          background: `transparent url(${league.coverImageURL})  no-repeat `,
          backgroundSize: "cover",
        }}
      >
        <div className="leaguePageHeaderWrapperMainSection">
          <div className="leaguePageHeaderWrapper">
            <Avatar
              variant="rounded"
              src={league.imageURL}
              className={mobileSize ? "image150Rounded" : "image200Rounded"}
            />
            <div className="leagueHeaderTextWrapper">
              <Typography className="viewLeagueTitleText">
                {league.name}
              </Typography>

              <div className="rowCenter">
                <LocationOn className="viewLeagueSubtitleIcon" />
                {leagueAreas.map((area, iter) => (
                  <Typography
                    key={iter}
                    className="viewLeagueSubtitleText margin10Left"
                  >
                    {`${area}${iter !== leagueAreas.length - 1 ? "," : ""}`}
                  </Typography>
                ))}
              </div>
            </div>
          </div>
          <div className="copyPlayerinfoDiv">
            <Tooltip title={copySuccess ? "Copied!" : "copy to clipboard"}>
              <IconButton
                onClick={() => copyToClipBoard()}
                className="LeagueInfoCopyButton"
              >
                <FileCopy className="viewLeagueDirectorContactIcon" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Card>
    </Grid>
  );
};

export default LeaguePageHeader;

import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import { DefaultTextField } from "../../components/CustomTextField";
import { Firebase, storage, firestore } from "../../firebase";
import update from "immutability-helper";
import Delete from "@material-ui/icons/Delete";

const DirectorActivity = ({ leagueName, status, user, leagueID }) => {
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [imageFiles, setImageFiles] = useState([]);
  const [imagesDisplayed, setImagesDisplayed] = useState([]);
  const [imagesDeleted, setImagesDeleted] = useState([]);

  const retrieveImageURLS = async (id) => {
    let getURLS = [];
    let storageRef = storage.ref(`activityDocuments/${id}`);
    await storageRef.listAll().then(async (result) => {
      await Promise.all(
        result.items.map(async (imageRef) => {
          await imageRef.getDownloadURL().then((url) => {
            getURLS.push(url);
          });
        })
      );
    });
    return getURLS;
  };
  const semiRandValue = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  const handleImageDelete = (key) => {
    const imageSelected = imagesDisplayed[key];
    setImagesDisplayed(update(imagesDisplayed, { $splice: [[key, 1]] }));
    if (imageSelected.associatedFile !== "none") {
      const index = imageFiles.findIndex(
        (imageFile) => imageSelected.associatedFile === imageFile
      );
      setImageFiles(update(imageFiles, { $splice: [[index, 1]] }));
    } else {
      let imagesDeletedTemp = imagesDeleted;
      imagesDeletedTemp.push(imageSelected.image);
      setImagesDeleted(imagesDeletedTemp);
    }
  };
  const sendActivity = async (e) => {
    e.preventDefault();
    firestore
      .collection("activities")
      .add({
        category: "directorMessage",
        leagueName: leagueName,
        subject: subject,
        sportType: "both",
        description: message,
        date: Firebase.firestore.FieldValue.serverTimestamp(),
        likes: [],
        imageURLS: [],
        leagueID: leagueID,
        players: [user.uid],
      })
      .then(async (docRef) => {
        const storageRef = storage.ref(`activityDocuments/${docRef.id}`);
        await Promise.all(
          imageFiles.map(async (image, index) => {
            await storageRef.child(`image${semiRandValue()}`).put(image);
          })
        );
        const getURLS = await retrieveImageURLS(docRef.id);
        await docRef.update({ imageURLS: getURLS });
      });
    setImageFiles([]);
    setImagesDisplayed([]);
    setSubject("");
    setMessage("");
  };
  return (
    <form className="column filler" onSubmit={sendActivity}>
      <Typography className="createLeagueFieldText">Activity</Typography>
      <Divider />
      <Grid className="padding10" container spacing={6}>
        <Grid xs={12} item>
          <Typography className="createLeagueFieldText margin20Top">
            Subject
          </Typography>
          <DefaultTextField
            disabled={status === "pending"}
            required={true}
            state={subject}
            setState={setSubject}
            placeholder="The event is about to begin..."
          />
          <Typography className="createLeagueFieldText margin20Top">
            Message
          </Typography>
          <DefaultTextField
            disabled={status === "pending"}
            required={true}
            state={message}
            setState={setMessage}
            placeholder="The event is about to begin..."
            multiline={5}
          />

          <div className="createLeagueFieldText margin20Top">
            <label htmlFor="contained-button-file">
              <Button component="span">
                Upload a max of 3 images of your activity
              </Button>
            </label>
            <div className="margin10" />
            <div className="rowCenter">
              {imagesDisplayed.map((imageObj, key) => (
                <div key={key} className="rewardEditImageWrapper">
                  <Avatar
                    variant="rounded"
                    className="rewardEditImage"
                    src={imageObj.image}
                  />
                  <IconButton onClick={() => handleImageDelete(key)}>
                    <Delete />
                  </IconButton>
                </div>
              ))}
            </div>
          </div>
          <input
            type="file"
            hidden
            id="contained-button-file"
            onChange={(e) => {
              if (imagesDisplayed.length < 3) {
                const imageFilesTemp = update(imageFiles, {
                  $push: [e.target.files[0]],
                });
                setImageFiles(imageFilesTemp);
                if (e.target.files[0] && e.target.files) {
                  const imagesDisplayedTemp = update(imagesDisplayed, {
                    $push: [
                      {
                        image: URL.createObjectURL(e.target.files[0]),
                        associatedFile: e.target.files[0],
                      },
                    ],
                  });
                  setImagesDisplayed(imagesDisplayedTemp);
                }
              } else {
              }
            }}
          />
        </Grid>
      </Grid>
      <div className="filler" />
      <Divider className="margin20Vertical" />
      <div className="rowCenter">
        <div className="filler" />
        <Button
          className="profileSaveButton"
          variant="outlined"
          type="submit"
          disabled={status === "pending"}
        >
          <Typography className="profileSaveButtonText">
            Post Activity
          </Typography>
        </Button>
      </div>
    </form>
  );
};
export default DirectorActivity;

import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Loader from "react-loader-spinner";
import { firestore } from "../../firebase";

const Statistics = ({
  instance,
  user,
  leagues,
  divisions,
  tracks,
  viewingOtherPlayer,
}) => {
  const [leagueStats, setLeagueStats] = useState([]);
  const [loading, setLoading] = useState(viewingOtherPlayer);
  const createLeagueTable = (
    name,
    name2,
    imageURL,
    matchRecord,
    matchPercent,
    setRecord,
    setPercent,
    gameRecord,
    gamePercent,
    event
  ) => {
    return {
      name: name,
      name2: name2,
      imageURL: imageURL,
      matchRecord: matchRecord,
      matchPercent: matchPercent,
      setRecord: setRecord,
      setPercent: setPercent,
      gameRecord: gameRecord,
      gamePercent: gamePercent,
      event: event,
    };
  };
  const filterLeagues = (league) => {
    return !league.event;
  };

  const circularProgress = createTheme({
    palette: {
      primary: {
        main: "#55B56A",
        light: "#EDF8F4",
        dark: "#028360",
      },
    },
    overrides: {
      MuiCircularProgress: {
        svg: {
          color: instance === "pickleball" ? "#F9EBFF" : "#F9FBE5",
        },
      },
    },
    props: {
      MuiTextField: {
        variant: "standard",
        InputProps: {
          disableUnderline: true,
        },
      },
    },
  });
  const retrievePercent = (win, loss) => {
    let res = Math.ceil((win / (loss + win)) * 100);
    return res ? res : 0;
  };
  const ProgressCircle = ({ percent }) => {
    return (
      <div className="posRelative columnCenter margin10Top circularProgressWrapper">
        <ThemeProvider theme={circularProgress}>
          <CircularProgress
            className="circularProgressBottom posAbsolute"
            size={180}
            thickness={8}
            value={100}
            variant="static"
          />
        </ThemeProvider>
        <CircularProgress
          className="circularProgressTop posAbsolute"
          size={180}
          thickness={8}
          value={percent}
          variant="static"
        />
        <div className="posAbsolute columnAlignCenter circularProgressInnerElement">
          <Typography className="circularProgressText">Win Rate:</Typography>
          <Typography className="circularProgressPercent">
            {percent}%
          </Typography>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const getMissingLeagueData = async () => {
      setLeagueStats(
        await Promise.all(
          (instance === "pickleball"
            ? user.pb_leaguesJoined
            : user.leaguesJoined
          ).map(async (joinedLeague) => {
            const leagueSnap = await firestore
              .collection("leagues")
              .doc(joinedLeague.leagueID)
              .get();
            const league = {
              ...leagueSnap.data(),
              id: leagueSnap.id,
            };
            const divisionSnap = await firestore
              .collection("leagues")
              .doc(joinedLeague.leagueID)
              .collection("divisions")
              .doc(joinedLeague.divisionID)
              .get();
            const division = {
              ...divisionSnap.data(),
              id: divisionSnap.id,
            };
            const trackSnap = await firestore
              .collection("leagues")
              .doc(joinedLeague.leagueID)
              .collection("divisions")
              .doc(joinedLeague.divisionID)
              .collection("tracks")
              .doc(joinedLeague.trackID)
              .get();
            const track = { ...trackSnap.data(), id: trackSnap.id };
            return createLeagueTable(
              `${league.name} ${division.division}`,
              `Level ${division.level}, Track: ${track.track + 1}`,
              league.imageURL,
              [joinedLeague.matchWins, joinedLeague.matchLosses],
              retrievePercent(joinedLeague.matchWins, joinedLeague.matchLosses),
              [joinedLeague.setWins, joinedLeague.setLosses],
              retrievePercent(joinedLeague.setWins, joinedLeague.setLosses),
              [joinedLeague.gameWins, joinedLeague.gameLosses],
              retrievePercent(joinedLeague.gameWins, joinedLeague.gameLosses),
              league.event
            );
          })
        )
      );
      setLoading(false);
    };
    viewingOtherPlayer
      ? getMissingLeagueData()
      : setLeagueStats(
          (instance === "pickleball"
            ? user.pb_leaguesJoined
            : user.leaguesJoined
          ).map((l, i) => {
            let league = leagues?.[l.leagueID];
            let division = divisions?.[l.divisionID];
            let track = tracks?.[l.trackID];
            return createLeagueTable(
              `${league?.name || "-"} ${division?.division || "-"}`,
              `Level ${division?.level || "-"}, Track: ${
                track?.track + 1 || "-"
              }`,
              league?.imageURL,
              [l.matchWins, l.matchLosses],
              retrievePercent(l.matchWins, l.matchLosses),
              [l.setWins, l.setLosses],
              retrievePercent(l.setWins, l.setLosses),
              [l.gameWins, l.gameLosses],
              retrievePercent(l.gameWins, l.gameLosses),
              league?.event
            );
          })
        );
  }, [user, leagues, divisions, tracks, viewingOtherPlayer, instance]);

  const handleAddPickleballData = (feild) => {
    if (instance === "pickleball") {
      return user[`pb_${feild}`];
    }
    return user[feild];
  };

  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  return (
    <Grid container spacing={6}>
      <Grid xs={12} item>
        <Typography className="profileSubpageTitle margin20Bottom">
          Overall Singles Statistics
        </Typography>
        <Grid container spacing={6}>
          <Grid item className="columnCenter" xs={12} md={6} lg={4} xl={4}>
            <div className="rowCenterMinWidth">
              <Typography className="profileStatisticsHeaderText">
                Match Record:
              </Typography>
              <Typography className="profileStatisticsHeaderValueText margin10Left">
                {`${handleAddPickleballData(
                  "totalMatchWins"
                )} - ${handleAddPickleballData("totalMatchLosses")}`}
              </Typography>
            </div>
            <ProgressCircle
              percent={retrievePercent(
                handleAddPickleballData("totalMatchWins"),
                handleAddPickleballData("totalMatchLosses")
              )}
            />
          </Grid>
          <Grid item className="columnCenter" xs={12} md={6} lg={4} xl={4}>
            <div className="rowCenterMinWidth">
              <Typography className="profileStatisticsHeaderText">
                Set Record:
              </Typography>
              <Typography className="profileStatisticsHeaderValueText margin10Left">
                {`${handleAddPickleballData(
                  "totalSetWins"
                )} - ${handleAddPickleballData("totalSetLosses")}`}
              </Typography>
            </div>
            <ProgressCircle
              percent={retrievePercent(
                handleAddPickleballData("totalSetWins"),
                handleAddPickleballData("totalSetLosses")
              )}
            />
          </Grid>
          <Grid item className="columnCenter" xs={12} md={6} lg={4} xl={4}>
            <div className="rowCenterMinWidth">
              <Typography className="profileStatisticsHeaderText">
                Game Record:
              </Typography>
              <Typography className="profileStatisticsHeaderValueText margin10Left">
                {`${handleAddPickleballData(
                  "totalGameWins"
                )} - ${handleAddPickleballData("totalGameLosses")}`}
              </Typography>
            </div>
            <ProgressCircle
              percent={retrievePercent(
                handleAddPickleballData("totalGameWins"),
                handleAddPickleballData("totalGameLosses")
              )}
            />
          </Grid>
        </Grid>

        <Typography className="profileSubpageTitle margin20Bottom margin20Top">
          Overall Doubles Statistics
        </Typography>
        <Grid container spacing={6}>
          <Grid item className="columnCenter" xs={12} md={6} lg={4} xl={4}>
            <div className="rowCenterMinWidth">
              <Typography className="profileStatisticsHeaderText">
                Match Record:
              </Typography>
              <Typography className="profileStatisticsHeaderValueText margin10Left">
                {`${handleAddPickleballData(
                  "totalDoublesMatchWins"
                )} - ${handleAddPickleballData("totalDoublesMatchLosses")}`}
              </Typography>
            </div>
            <ProgressCircle
              percent={retrievePercent(
                handleAddPickleballData("totalDoublesMatchWins"),
                handleAddPickleballData("totalDoublesMatchLosses")
              )}
            />
          </Grid>
          <Grid item className="columnCenter" xs={12} md={6} lg={4} xl={4}>
            <div className="rowCenterMinWidth">
              <Typography className="profileStatisticsHeaderText">
                Set Record:
              </Typography>
              <Typography className="profileStatisticsHeaderValueText margin10Left">
                {`${handleAddPickleballData(
                  "totalDoublesSetWins"
                )} - ${handleAddPickleballData("totalDoublesSetLosses")}`}
              </Typography>
            </div>
            <ProgressCircle
              percent={retrievePercent(
                handleAddPickleballData("totalDoublesSetWins"),
                handleAddPickleballData("totalDoublesSetLosses")
              )}
            />
          </Grid>
          <Grid item className="columnCenter" xs={12} md={6} lg={4} xl={4}>
            <div className="rowCenterMinWidth">
              <Typography className="profileStatisticsHeaderText">
                Game Record:
              </Typography>
              <Typography className="profileStatisticsHeaderValueText margin10Left">
                {`${handleAddPickleballData(
                  "totalDoublesGameWins"
                )} - ${handleAddPickleballData("totalDoublesGameLosses")}`}
              </Typography>
            </div>
            <ProgressCircle
              percent={retrievePercent(
                handleAddPickleballData("totalDoublesGameWins"),
                handleAddPickleballData("totalDoublesGameLosses")
              )}
            />
          </Grid>
        </Grid>
        <Divider className="margin20Top" />
      </Grid>
      <Grid item xs={12}>
        <Typography className="profileSubpageTitle">
          League Statistics
        </Typography>
        <div className="columnCenter ">
          {leagueStats
            .filter((league) => filterLeagues(league))
            .map((stats, index) => (
              <Card
                key={index}
                className="column profileStatisticsLeagueElement width80 margin20Top"
              >
                <div className="rowCenterMinWidth margin20Bottom">
                  <Avatar
                    variant="rounded"
                    src={stats.imageURL}
                    className="image100 margin20Right"
                  />
                  <div className="column">
                    <Typography className="profileStatisticsLeagueTitle">
                      {stats.name}
                    </Typography>
                    <Typography className="profileStatisticsLeagueTitle">
                      {stats.name2}
                    </Typography>
                  </div>
                </div>
                <Grid container spacing={6}>
                  <Grid
                    item
                    className="columnCenter"
                    xs={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <div className="rowCenterMinWidth">
                      <Typography className="profileStatisticsHeaderText">
                        Match Record:
                      </Typography>
                      <Typography className="profileStatisticsHeaderValueText margin10Left">
                        {`${stats.matchRecord[0]} - ${stats.matchRecord[1]}`}
                      </Typography>
                    </div>
                    <ProgressCircle percent={stats.matchPercent} />
                  </Grid>
                  <Grid
                    item
                    className="columnCenter"
                    xs={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <div className="rowCenterMinWidth">
                      <Typography className="profileStatisticsHeaderText">
                        Set Record:
                      </Typography>
                      <Typography className="profileStatisticsHeaderValueText margin10Left">
                        {`${stats.setRecord[0]} - ${stats.setRecord[1]}`}
                      </Typography>
                    </div>
                    <ProgressCircle percent={stats.setPercent} />
                  </Grid>
                  <Grid
                    item
                    className="columnCenter"
                    xs={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <div className="rowCenterMinWidth">
                      <Typography className="profileStatisticsHeaderText">
                        Game Record:
                      </Typography>
                      <Typography className="profileStatisticsHeaderValueText margin10Left">
                        {`${stats.gameRecord[0]} - ${stats.gameRecord[1]}`}
                      </Typography>
                    </div>
                    <ProgressCircle percent={stats.gamePercent} />
                  </Grid>
                </Grid>
              </Card>
            ))}
        </div>
      </Grid>
    </Grid>
  );
};

export default Statistics;

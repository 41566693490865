import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

function SideMenu({ children }) {
  return (
    <Grid xs={12} md={4} lg={3} xl={3} item>
      <Card className="card maxHeight">{children}</Card>
    </Grid>
  );
}

export default SideMenu;

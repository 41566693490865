import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import AccessAlarm from "@material-ui/icons/AccessAlarm";
import TimerOff from "@material-ui/icons/TimerOff";
import Gesture from "@material-ui/icons/Gesture";
import { MenuTextField } from "../../../components/CustomTextField";
import styled from '@material-ui/core/styles/styled';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const CustomButton = styled(Button)(() => ({
  color: '#fff',
  backgroundColor: '#54b56a',
  borderRadius: 50,
  fontWeight: 'bold'
}));
const CustomTextField = styled(TextField)(() => ({
  width: 'calc(100% - 110px)',
}));

const PageTwo = ({
  format,
  setFormat,
  timeboxed,
  setTimeboxed,
  // rules,
  // setRules,
  // customRules,
  // setCustomRules,
  winCriteria,
  setWinCriteria,
  scoringFormat,
  setScoringFormat,
  privateLeague,
  setPrivateLeague,
  setInviteCode,
  inviteCode,
  inviteCodeError,
  handleAddCode,
  isShowErrorIcon
}) => {
  const scoringFormatSelect = [
    { label: "Ranked by matches won", value: "Ranked by matches won" },
    { label: "Ranked by overall points", value: "Ranked by overall points" },
    {
      label: "Ranked by win/loss percent",
      value: "Ranked by win/loss percent",
    },
  ];
  const winCriteriaSelect = [
    {
      label: "Win by beating opponents in an advantage match",
      value: "Win by beating opponents in an advantage match",
    },
    {
      label: "Win by beating opponents in tiebreak matches",
      value: "Win by beating opponents in tiebreak matches",
    },
    {
      label: "Win by beating opponents in pro set matches",
      value: "Win by beating opponents in pro set matches",
    },
  ];
  // const rulesSelect = [
  //   { label: "Standard Rules", value: "Standard Rules" },
  //   { label: "Custom Rules", value: "Custom Rules" },
  // ];
  const formatSelect = ["Flex", "Moderated-dropin"];
  const privateLeagueMenu = [{label:"Yes", value: true},{label:"No", value: false}];

  return (
    <Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Typography className="createLeagueFieldText">
            What type of league will this be?
          </Typography>
          <TextField
              select
              required
              value={format}
              onChange={(e) => {
                setFormat(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                      {format === "Flex" && <Gesture />}
                    </InputAdornment>
                ),
              }}
          >
            {formatSelect.map((s, i) => (
                <MenuItem key={i} value={s}>
                  {s}
                </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="createLeagueFieldText">
            Is this a Private League?
          </Typography>
          <TextField
              select
              required
              value={privateLeague}
              onChange={(e) => {
                setPrivateLeague(e.target.value);
              }}
          >
            {privateLeagueMenu.map((s, i) => (
                <MenuItem key={i} value={s.value}>
                  {s.label}
                </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Typography className="createLeagueFieldText">
            Timeboxed?
          </Typography>
          <div className="rowCenterMinWidth">
            {timeboxed ? (
                <AccessAlarm className="fontSize40" />
            ) : (
                <TimerOff className="fontSize40" />
            )}
            <FormControlLabel
                control={
                  <Switch
                      color="primary"
                      value={timeboxed}
                      onChange={(e) => setTimeboxed((timeboxed) => !timeboxed)}
                  />
                }
                labelPlacement="start"
            />
          </div>
        </Grid>
        {privateLeague && <Grid item xs={12} md={6}>
          <Typography className="createLeagueFieldText rowCenter">
            Add New Invite Code {isShowErrorIcon && (inviteCodeError ? <CancelIcon className="margin10Left" htmlColor="#ff0000" /> : <CheckCircleIcon className="margin10Left" htmlColor="#54b56a"/>)}
          </Typography>
          <FormControl fullWidth className="rowCenter" error={inviteCodeError}>
            <CustomTextField
                required
                value={inviteCode}
                onChange={(e) => {
                  setInviteCode(e.target.value.toUpperCase());
                }}
                placeholder="Invite codes must have 3+ characters"
            />
            <CustomButton className="margin10Left" onClick={()=>handleAddCode()}>Add Code</CustomButton>
          </FormControl>
          {inviteCodeError && <Typography className="createLeagueErrorText">
            {inviteCodeError}
          </Typography>}
        </Grid>}
      </Grid>
      <div className="rowCenter margin20Top">
        <div className="column widthHalf margin20Right">
          <Typography className="createLeagueFieldText">
            Match Win Criteria
          </Typography>
          <MenuTextField
            required={true}
            state={winCriteria}
            setState={setWinCriteria}
            menuSelection={winCriteriaSelect}
          />
        </div>
        <div className="column widthHalf margin20Left">
          <Typography className="createLeagueFieldText">
            Scoring Format
          </Typography>
          <MenuTextField
            required={true}
            state={scoringFormat}
            setState={setScoringFormat}
            menuSelection={scoringFormatSelect}
          />
        </div>
      </div>
      {/* <Typography className="createLeagueFieldText margin20Top">
        Rules
      </Typography>
      <MenuTextField
        required={true}
        state={rules}
        setState={setRules}
        menuSelection={rulesSelect}
      />
      {rules === "Custom Rules" && (
        <Fragment>
          <label htmlFor="customRulesUpload">
            <div className="rowCenter  margin20Top">
              <Typography className="createLeagueFieldText underline">
                Upload Custom Rules
              </Typography>
              {customRules !== "" && (
                <CheckCircle className="appBarLocationIcon margin10Left" />
              )}
            </div>
          </label>
          <input
            type="file"
            hidden
            id="customRulesUpload"
            onChange={async (e) => {
              setCustomRules(e.target.files[0]);
            }}
          />
        </Fragment>
      )} */}
    </Fragment>
  );
};

export default PageTwo;

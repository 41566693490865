import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import {
  RichTextField,
} from "../../../components/CustomTextField";
const PageFour = ({
  setPolicy,
  setDisclosure,
  policySaved,
  disclosureSaved,
}) => {

  // const rulesSelect = [
  //   { label: "Standard Rules", value: "Standard Rules" },
  //   { label: "Custom Rules", value: "Custom Rules" },
  // ];

  return (
    <Fragment>
      <Typography className="createLeagueFieldText margin20Top">
        Refund Policy
      </Typography>
      <RichTextField
        required
        setValue={setPolicy}
        defaultValue={policySaved}
        placeholder="Describe your Refund Policies..."
      />
      <Typography className="createLeagueFieldText margin20Top">
        Legal Disclosures and other Rules and Regulation (optional)
        </Typography>
        <RichTextField
        setValue={setDisclosure}
        defaultValue={disclosureSaved}
        placeholder="Describe your Legal Disclosures and other Rules and Regulation..."
      />
    </Fragment>
  );
};

export default PageFour;

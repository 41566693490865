import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Loader from "react-loader-spinner";
import moment from "moment";
import { firestore } from "../../firebase";
import {
  getUserByID,
  getDoublesTeamByID,
} from "../../functions/firebaseFunctions";
import { LoadMore } from "../../components/table/Pagination";
import MatchListItem from "./MatchListItem";
import MatchSearchBar from "./MatchSearchBar";
import Typography from "@material-ui/core/Typography";
const Matches = ({
  sportLoading,
  user,
  userDoublesTeams,
  leagues,
  divisions,
  tracks,
  isFromDashBoard,
  showAll,
}) => {
  const [matches, setMatches] = useState([]);
  const [doublesMatches, setDoublesMatches] = useState([]);
  const [leagueSearch, setLeagueSearch] = useState("");
  const [opponentSearch, setOpponentSearch] = useState("");
  const [sortBy, setSortBy] = useState("newest");
  const [matchType, setMatchType] = useState("all");
  const [matchResult, setMatchResult] = useState("all");
  const resultsPerPage = isFromDashBoard ? 5 : 6;
  const [pageNum, setPageNum] = useState(1);
  const indexOfLastItem = pageNum * resultsPerPage;
  const [loading, setLoading] = useState(true);
  useEffect(() => {

          const matchesRef = firestore
              .collection("scores")
              .where("sportType", "==", user.currentSport || 'tennis')
              .where("participants", "array-contains", user.uid);

          userDoublesTeams =
              userDoublesTeams.length > 10
                  ? userDoublesTeams.slice(-10)
                  : userDoublesTeams;

          const doublesMatchesRef = firestore
              .collection("scores")
              .where("sportType", "==", user.currentSport || 'tennis')
              .where(
                  "participants",
                  "array-contains-any",
                  userDoublesTeams.length > 0
                      ? userDoublesTeams.map((team) => team.id)
                      : ["No Teammates"]
              );
          const unsubscribeDoublesMatches = doublesMatchesRef.onSnapshot(
              async (matchesSnapshot) => {
                  let arrTemp = [];
                  await Promise.all(
                      matchesSnapshot.docs.map(async (match) => {
                          let matchData = match.data();
                          let userWon = userDoublesTeams.some(
                              (team) => team.id === matchData.winner
                          );
                          let opponentTeamID = userWon ? matchData.loser : matchData.winner;
                          let userTeam = userDoublesTeams.find(
                              (team) =>
                                  team.id === (userWon ? matchData.winner : matchData.loser)
                          );
                          let opponent = await getDoublesTeamByID(opponentTeamID);
                          arrTemp.push({
                              league: leagues[matchData.leagueID],
                              division: divisions[matchData.divisionID],
                              track: tracks[matchData.trackID],
                              score: matchData,
                              winner: userWon ? userTeam : opponent,
                              loser: userWon ? opponent : userTeam,
                              result: userWon ? "win" : "loss",
                          });
                      })
                  );
                  setDoublesMatches(arrTemp);
              }
          );
          const unsubscribeMatches = matchesRef.onSnapshot(
              async (matchesSnapshot) => {
                  let arrTemp = [];
                  await Promise.all(
                      matchesSnapshot.docs.map(async (match) => {
                          let matchData = match.data();
                          let userWon = matchData.winner === user.uid;
                          let opponentID = userWon ? matchData.loser : matchData.winner;
                          let opponent = await getUserByID(opponentID);
                          arrTemp.push({
                              league: leagues[matchData.leagueID],
                              division: divisions[matchData.divisionID],
                              track: tracks[matchData.trackID],
                              score: matchData,
                              winner: userWon ? {...user, id: user.uid} : opponent,
                              loser: userWon ? opponent : {...user, id: user.uid},
                              result: userWon ? "win" : "loss",
                          });
                      })
                  );
                  setMatches(arrTemp);
                  setLoading(false);
              }
          );
          return () => {
              unsubscribeMatches();
              unsubscribeDoublesMatches();
          };
  }, [user, leagues, divisions, tracks, userDoublesTeams]);
  const filterByLeague = (match) => {
    if (leagueSearch === "") return true;
    return (
      match.league.name.toUpperCase().indexOf(leagueSearch.toUpperCase()) === 0
    );
  };
  const filterByMatchType = (match) => {
    return matchType === "all" ? true : match.score.scoringFormat === matchType;
  };
  const filterByMatchResult = (match) => {
    return matchResult === "all" ? true : match.result === matchResult;
  };
  const filterByOpponent = (match) => {
    if (opponentSearch === "") return true;
    let opponentName = match.score.doublesFormat
      ? match.result === "win"
        ? `${match.loser.teamName}`
        : `${match.winner.teamName}`
      : match.result === "win"
      ? `${match.loser.firstName} ${match.loser.lastName}`
      : `${match.winner.firstName} ${match.winner.lastName}`;
    opponentName = opponentName.toUpperCase();
    return opponentName.indexOf(opponentSearch.toUpperCase()) === 0;
  };
  const playedMatches = [...matches, ...doublesMatches]
    .filter((match) => filterByLeague(match))
    .filter((match) => filterByOpponent(match))
    .filter((match) => filterByMatchType(match))
    .filter((match) => filterByMatchResult(match))
    .sort((a, b) => {
      const dateA = moment(a.score.matchDate, "MM-DD-YYYY").toDate();
      const dateB = moment(b.score.matchDate, "MM-DD-YYYY").toDate();
      return sortBy === "newest" ? dateB - dateA : dateA - dateB;
    });

  if (!sportLoading ? loading : true)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  return (
    <Grid container spacing={4}>
      {!isFromDashBoard && (
        <Grid item xs={12}>
          <MatchSearchBar
            opponent={opponentSearch}
            setOpponent={setOpponentSearch}
            search={leagueSearch}
            setSearch={setLeagueSearch}
            matchType={matchType}
            setMatchType={setMatchType}
            matchResult={matchResult}
            setMatchResult={setMatchResult}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        {playedMatches
          .slice(0, showAll ? playedMatches.length : indexOfLastItem)
          .map((result, key) => (
            <MatchListItem
              key={key}
              league={result.league}
              division={result.division}
              track={result.track}
              score={result.score}
              winner={result.winner}
              loser={result.loser}
            />
          ))}

        {playedMatches.length === 0 && (
          <div className="dashboardEmptySectionWrapper">
            <Typography className="listItemMatchScorePlayerName">
              No Recent Matches
            </Typography>
          </div>
        )}

        {playedMatches.length > resultsPerPage * pageNum &&
          !isFromDashBoard && <LoadMore setValue={setPageNum} />}
      </Grid>
    </Grid>
  );
};
export default Matches;

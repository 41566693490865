import { createTheme } from "@material-ui/core/styles";

export const pickleballtheme = createTheme({
  typography: {
    fontFamily: ["Helvetica"],
  },
  palette: {
    primary: {
      main: "#6115AD",
      light: "#EDF8F4",
      dark: "#028360",
    },
    info: {
      main: "#F4B425",
    },
  },
  overrides: {
    MuiCard: {
      root: {
        overflow: "visible",
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: "24px",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "10px",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
    MUIRichTextEditor: {
      root: {
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        boxShadow: "0px 3px 6px #00000029",
        minHeight: 300,
        paddingLeft: 10,
        paddingRight: 10,
        border: "2px solid",
        borderColor: "#c0c0c0",
      },
      editor: { padding: 10 },
    },
    MuiTextField: {
      root: {
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        boxShadow: "0px 3px 6px #00000029",
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "#E1E87E",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "25px",
      },
    },
    MuiTab: {
      textColorInherit: {
        color: "#6115AD",
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#000000",
      },
    },
    MuiRadio: {
      root: {
        "&$checked": {
          color: "#000000",
        },
      },
      checked: {},
    },
    MuiDialog: {
      paper: {
        borderRadius: "10px",
      },
    },
  },
  props: {
    MuiTextField: {
      variant: "outlined",
    },
    MuiButton: {
      variant: "outlined",
    },
    MuiTooltip: {
      interactive: true,
    },
  },
});

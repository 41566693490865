import React, { useState, Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Help from "@material-ui/icons/Help";
import { Document, Page } from "react-pdf/dist/entry.webpack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const InformationDialogPDF = ({ pdf }) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleNextPage = () => {
    setPageNum((prev) => (prev % numPages) + 1);
  };
  const handlePreviousPage = () => {
    if (pageNum > 1) setPageNum((prev) => prev - 1);
  };
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Fragment>
      <IconButton onClick={handleOpen} className="margin10Bottom">
        <Help className="appBarUploadScoreButtonIcon" />
      </IconButton>
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogContent>
          <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNum} />
          </Document>
          <div className="rowCenter">
            <Button
              className="profileSaveButton"
              disabled={pageNum === 1}
              onClick={handlePreviousPage}
            >
              Back
            </Button>
            <div className="filler" />
            <p className="rewardsHighlightText">
              Page {pageNum} of {numPages}
            </p>
            <div className="filler" />
            <Button
              className="profileSaveButton"
              disabled={pageNum === numPages}
              onClick={handleNextPage}
            >
              Next
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default InformationDialogPDF;

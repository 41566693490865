import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@mdi/react";
import { mdiCrownOutline } from "@mdi/js";
const PlayerBracketObject = ({ player, score, final }) => {
  const playerWon = score.winner === player.id;
  const placeCrown = playerWon && final;
  return (
    <div
      className={`playoffObject ${
        player.withdrawn && "withdrawnPlayerBackground"
      }`}
    >
      <div className="posRelative">
        {placeCrown && (
          <Icon
            className="playoffsFinalsCrown posAbsolute"
            size="40px"
            path={mdiCrownOutline}
          />
        )}
        <Avatar className="image50" src={player.imageURL} />
      </div>
      <div className="playoffObjectTextWrapper">
        <Typography className="playoffsObjectPlayerText">
          {player.name} {player.withdrawn && "(W)"} - {player.rank}
        </Typography>
        {typeof score !== "string" ? (
          <div className="playoffScoreWrapper">
            {playerWon ? (
              <Fragment>
                {score.scoringFormat !== "10GamePro" &&
                score.scoringFormat !== "1Gameto15or21points" ? (
                  <Fragment>
                    <Typography className="playoffsObjectScoreText">
                      {score.setOneScore[0]}
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      -
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      {score.setTwoScore[0]}
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      -
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      {score.setThreeScore[0]}
                    </Typography>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Typography className="playoffsObjectScoreText">
                      {score.proSetScore[0]}
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      -
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      x
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      -
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      x
                    </Typography>
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <Fragment>
                {score.scoringFormat !== "10GamePro" &&
                score.scoringFormat !== "1Gameto15or21points" ? (
                  <Fragment>
                    <Typography className="playoffsObjectScoreText">
                      {score.setOneScore[1]}
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      -
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      {score.setTwoScore[1]}
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      -
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      {score.setThreeScore[1]}
                    </Typography>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Typography className="playoffsObjectScoreText">
                      {score.proSetScore[1]}
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      -
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      x
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      -
                    </Typography>
                    <Typography className="playoffsObjectScoreText">
                      x
                    </Typography>
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
        ) : (
          <div className="playoffScoreWrapper">
            <Typography className="playoffsObjectScoreText">x</Typography>
            <Typography className="playoffsObjectScoreText">-</Typography>
            <Typography className="playoffsObjectScoreText">x</Typography>
            <Typography className="playoffsObjectScoreText">-</Typography>
            <Typography className="playoffsObjectScoreText">x</Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlayerBracketObject;

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import styled from "@material-ui/core/styles/styled";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const CustomDialogContent = styled(DialogContent, {
  shouldForwardprop: (prop) => prop,
})(({ paddingZero }) => ({
  ...(paddingZero && { padding: "0px !important" }),
}));

const DefaultDialog = ({
  open,
  setOpen,
  children,
  size,
  paddingZero = false,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth={size ? size : "sm"}
      fullWidth={true}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <CustomDialogContent paddingZero={paddingZero}>
        {children}
      </CustomDialogContent>
    </Dialog>
  );
};

export default DefaultDialog;

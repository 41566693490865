import React, {useEffect, useState} from "react";
import { CustomTabs } from "../../components/CustomTabs";
import UpcomingLeagues from "./UpcomingLeagues";
import OngoingLeagues from "./OngoingLeagues";
import CompletedLeagues from "./CompletedLeagues";
import MyLeagues from "./MyLeagues";
const pageLabels = [
  "My Leagues/Events",
  "Upcoming Leagues/Events",
  "Ongoing Leagues/Events",
  "Completed Leagues/Events",
];
const Leagues = ({
  isPickleball,
  user,
  sportLoading,
  leagues,
  divisions,
  tracks,
  zoneList,
  mobileSize,
}) => {
  const [pageNum, setPageNum] = useState(0);
  //Shared filters
  const [leagueNameFilter, setLeagueNameFilter] = useState("");
  const [filterBySection, setFilterBySection] = useState(
    user.playLocation.sectionals
  );
  const [filterByLocale, setFilterByLocale] = useState(
    user.playLocation.locale
  );
  useEffect(()=>{
    if(window.location.search) {
      var urlParams;
      var match,
          pl = /\+/g,  // Regex for replacing addition symbol with a space
          search = /([^&=]+)=?([^&]*)/g,
          decode = function (s) {
            return decodeURIComponent(s.replace(pl, " "));
          },
          query = window.location.search.substring(1);

      urlParams = {};
      while (match = search.exec(query))
        urlParams[decode(match[1])] = decode(match[2]);
      console.log("======urlParams", urlParams);
      if (urlParams.pageNum) {
        setPageNum(parseInt(urlParams.pageNum ,10))
      }
    }
  },[])
  const [filterByArea, setFilterByArea] = useState("All");
  const [divisionSelect, setDivisionSelect] = useState([
    { label: "Men's Singles", check: true },
    { label: "Men's Doubles", check: true },
    { label: "Women's Singles", check: true },
    { label: "Women's Doubles", check: true },
    { label: "Coed Singles", check: true },
    { label: "Coed Doubles", check: true },
  ]);
  const [playLevel, setPlayLevel] = useState([
    { label: "Everyone", check: true, level: 0 },
    { label: "1.0 - Beginner", check: true, level: 1.0 },
    { label: "1.5 - Beginner", check: true, level: 1.5 },
    { label: "2.0 - Beginner", check: true, level: 2.0 },
    { label: "2.5 - Beginner", check: true, level: 2.5 },
    { label: "3.0 - Novice", check: true, level: 3.0 },
    { label: "3.5 - Intermediate", check: true, level: 3.5 },
    { label: "4.0 - Advanced", check: true, level: 4.0 },
    { label: "4.5 - Advanced", check: true, level: 4.5 },
    { label: "5.0 - Competitive/D1", check: true, level: 5.0 },
    { label: "5.5 - Competitive/D1", check: true, level: 5.5 },
    { label: "6.0 - Club-Level Pro", check: true, level: 6.0 },
    { label: "6.5 - Club-Level Pro", check: true, level: 6.5 },
    { label: "7.0 - Circuit Level Pro", check: true, level: 7.0 },
    { label: "7.5 - Circuit Level Pro", check: true, level: 7.5 },
    { label: "8.0 - World Class", check: true, level: 8.0 },
  ]);

  return (
    <div>
      <CustomTabs value={pageNum} setValue={setPageNum} labels={pageLabels} />
      {pageNum === 0 && (
        <MyLeagues
          user={user}
          sportLoading={sportLoading}
          leagues={leagues}
          divisions={divisions}
          tracks={tracks}
          mobileSize={mobileSize}
          zoneList={zoneList}
          leagueNameFilter={leagueNameFilter}
          setLeagueNameFilter={setLeagueNameFilter}
          filterBySection={filterBySection}
          setFilterBySection={setFilterBySection}
          filterByLocale={filterByLocale}
          setFilterByLocale={setFilterByLocale}
          filterByArea={filterByArea}
          setFilterByArea={setFilterByArea}
          divisionSelect={divisionSelect}
          setDivisionSelect={setDivisionSelect}
          playLevel={playLevel}
          setPlayLevel={setPlayLevel}
        />
      )}
      {pageNum === 1 && (
        <UpcomingLeagues
          user={user}
          sportLoading={sportLoading}
          isPickleball={isPickleball}
          mobileSize={mobileSize}
          zoneList={zoneList}
          leagueNameFilter={leagueNameFilter}
          setLeagueNameFilter={setLeagueNameFilter}
          filterBySection={filterBySection}
          setFilterBySection={setFilterBySection}
          filterByLocale={filterByLocale}
          setFilterByLocale={setFilterByLocale}
          filterByArea={filterByArea}
          setFilterByArea={setFilterByArea}
          divisionSelect={divisionSelect}
          setDivisionSelect={setDivisionSelect}
          playLevel={playLevel}
          setPlayLevel={setPlayLevel}
        />
      )}
      {pageNum === 2 && (
        <OngoingLeagues
          user={user}
          mobileSize={mobileSize}
          sportLoading={sportLoading}
          zoneList={zoneList}
          leagueNameFilter={leagueNameFilter}
          setLeagueNameFilter={setLeagueNameFilter}
          filterBySection={filterBySection}
          setFilterBySection={setFilterBySection}
          filterByLocale={filterByLocale}
          setFilterByLocale={setFilterByLocale}
          filterByArea={filterByArea}
          setFilterByArea={setFilterByArea}
          divisionSelect={divisionSelect}
          setDivisionSelect={setDivisionSelect}
          playLevel={playLevel}
          setPlayLevel={setPlayLevel}
        />
      )}
      {pageNum === 3 && (
        <CompletedLeagues
          user={user}
          mobileSize={mobileSize}
          sportLoading={sportLoading}
          zoneList={zoneList}
          leagueNameFilter={leagueNameFilter}
          setLeagueNameFilter={setLeagueNameFilter}
          filterBySection={filterBySection}
          setFilterBySection={setFilterBySection}
          filterByLocale={filterByLocale}
          setFilterByLocale={setFilterByLocale}
          filterByArea={filterByArea}
          setFilterByArea={setFilterByArea}
          divisionSelect={divisionSelect}
          setDivisionSelect={setDivisionSelect}
          playLevel={playLevel}
          setPlayLevel={setPlayLevel}
        />
      )}
    </div>
  );
};
export default Leagues;

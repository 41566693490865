import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
import React from "react";
import moment from "moment";
import DefaultDialog from "./DefaultDialog";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";

const NotificationDialog = ({
  notificationData,
  isOpen,
  handleClose,
  isLoading,
  doublesIncomingInvites,
}) => {
  const history = useHistory();

  const handleStatusUpadate = async (id, leagueID, divisionID, trackID) => {
    handleClose();

    const invitesDoc = doublesIncomingInvites.find(
      (invite) =>
        invite.leagueID === leagueID &&
        invite.divisionID === divisionID &&
        invite.trackID === trackID
    );
    history.push({
      pathname: `/Form/DoublesInvite/${invitesDoc.id}`,
      state: { notificationId: id },
    });
  };

  const getNotificationTime = (time) => {
    let date = new Date(time * 1000);
    return moment(date).format("D MMM");
  };

  return (
    <DefaultDialog open={isOpen} setOpen={handleClose} size={"md"}>
      <div className="rowCenter margin10Bottom">
        <Typography className="margin10Vertical rewardPurchaseItemName">
          Message/Invitation
        </Typography>
        <div className="filler" />
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
        <hr></hr>
      </div>
      <hr></hr>
      <div className="margin20Bottom">
        {notificationData.length > 0
          ? !isLoading &&
            notificationData.map((item, i) => (
              <div key={i}>
                <div
                  className={`notification margin10Bottom ${
                    !item.isRead ? "isUnread" : null
                  }`}
                >
                  <div className="margin10Right">
                    <Avatar
                      className="image50"
                      src={item?.senderUserInfo?.imageURL}
                    />
                  </div>
                  <div className="notificatinTime">
                    {getNotificationTime(item?.messageDate?._seconds)}
                  </div>
                  <div className="notificationDetail">
                    <Typography>
                      <b> {item.type} </b> - {item.title}
                    </Typography>
                  </div>
                  <div className="notificationBtnDiv">
                    {item.type === "Double's Invitation" && (
                      <Button
                        className="notificationbutton"
                        onClick={() =>
                          handleStatusUpadate(
                            item.id,
                            item?.leagueID,
                            item?.divisionID,
                            item?.trackID
                          )
                        }
                      >
                        Respond
                      </Button>
                    )}

                    {item.type === "practiceOnly" && (
                      <>
                        <Button className="notificationbutton margin10Right margin10Left">
                          Accept
                        </Button>
                        <Button className="notificationbutton">Decline</Button>
                      </>
                    )}
                  </div>
                </div>
                {i === notificationData.length - 1 ? null : <hr></hr>}
              </div>
            ))
          : !isLoading && (
              <div className="dashboardEmptySectionWrapper">
                <Typography className="listItemMatchScorePlayerName">
                  No Message
                </Typography>
              </div>
            )}

        {isLoading && (
          <div className="pageWrapper">
            <Loader type="TailSpin" color="#02c39a" height={100} width={100} />
          </div>
        )}
        {notificationData.length > 5 && (
          <div className="areaCenter">
            <Button className="notificationbutton">LoadMore</Button>
          </div>
        )}
      </div>
    </DefaultDialog>
  );
};

export default NotificationDialog;

import React, { useState, useEffect, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Loader from "react-loader-spinner";
import moment from "moment";
import { getUserByID } from "../../../functions/firebaseFunctions";
import SideMenu from "../../../components/SideMenu";
import Content from "../../../components/Content";
import { CustomTabs } from "../../../components/CustomTabs";
import Statistics from "../Statistics";
import ViewPlayerInfo from "./ViewPlayerInfo";
import PlayerReviews from "./PlayerReviews";
import parsePhoneNumber from "libphonenumber-js";
import FileCopy from "@material-ui/icons/FileCopy";
import { IconButton, Tooltip } from "@material-ui/core";

const ViewPlayer = ({ user, leagues, divisions, tracks, mobileSize }) => {
  const location = window.location.href;
  const info = location.split("/");
  const playerID = info[info.length - 1];
  const [loading, setLoading] = useState(true);
  const [player, setPlayer] = useState(null);
  const [pageNum, setPageNum] = useState(0);
  const [copySuccess, setCopySuccess] = useState(false);
  const [displayContactInfo, setDisplayContactInfo] = useState(false);
  const pageLabels = ["Statistics", "About Me", "Reviews"];
  useEffect(() => {
    const getData = async () => {
      let pTemp = await getUserByID(playerID);
      if (user) {
        setDisplayContactInfo(
            user.leaguesJoined.some((league) => {
              if (user?.currentSport === "pickleball") {
                return pTemp?.pb_leaguesJoined?.some(
                    (league2) =>
                        league2.trackID === league?.trackID &&
                        ["approved", "active", "playoffs"].some(
                            (status) => leagues[league.leagueID]?.status === status
                        ) &&
                        ["approved", "active", "playoffs"].some(
                            (status) => leagues[league2.leagueID]?.status === status
                        )
                );
              } else {
                return pTemp?.leaguesJoined?.some(
                    (league2) =>
                        league2.trackID === league.trackID &&
                        ["approved", "active", "playoffs"].some(
                            (status) => leagues[league.leagueID]?.status === status
                        ) &&
                        ["approved", "active", "playoffs"].some(
                            (status) => leagues[league2.leagueID]?.status === status
                        )
                );
              }
            })
        );
      }
      setPlayer(pTemp);
      setLoading(false);
    };
    getData();
  }, [playerID, leagues, user]);
  const handleBack = () => {
    window.history.back();
  };

  const copyToClipBoard = () => {
    setCopySuccess(true);
    const text = document.createElement("textarea");
    text.innerText = window.location.href;
    document.body.appendChild(text);
    text.select();
    document.execCommand("copy");
    text.remove();
  };

  const handleAddPickleballData = (feild) => {
    if (user?.currentSport === "pickleball") {
      return player[`pb_${feild}`];
    }
    return player[feild];
  };

  if (!loading)
    return (
      <Fragment>
        <Button
          onClick={handleBack}
          variant="text"
          className="dashboardBackButton"
        >
          <div className="flexCenter">
            <ChevronLeft className="image30 appBarUploadScoreButtonIcon" />
            <Typography className="appBarUploadScoreButtonText">
              Back
            </Typography>
          </div>
        </Button>
        <Grid container spacing={mobileSize ? 3 : 6}>
          <SideMenu>
            <div className="rowCenter">
              <Avatar src={player.imageURL} className="image150" />
              <div className="column margin10Left">
                <div className="copyPlayerinfoDiv">
                  <Tooltip
                    title={copySuccess ? "Copied!" : "copy to clipboard"}
                  >
                    <IconButton
                      onClick={() => copyToClipBoard()}
                      className="viewLeagueDirectorContactButton"
                    >
                      <FileCopy className="viewLeagueDirectorContactIcon" />
                    </IconButton>
                  </Tooltip>
                </div>
                <Typography className="profileInfoCardTitle">{`${player.firstName} ${player.lastName}`}</Typography>
                <Typography className="profileInfoCardSubtitle">{`Member Since ${moment(
                  player.dateCreated.toDate()
                ).format("MMMM, YYYY")}`}</Typography>
              </div>
            </div>
            <Grid container spacing={4} className="margin30Top">
              <Grid item xs={4}>
                <Typography className="viewPlayerInfoLabel">Phone</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography className="viewPlayerInfo">
                  {displayContactInfo
                    ? parsePhoneNumber(
                        player.phoneNumber,
                        "US"
                      ).formatNational()
                    : "********"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="viewPlayerInfoLabel">Email</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography className="viewPlayerInfo">
                  {displayContactInfo ? player.email : "********"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="viewPlayerInfoLabel">City</Typography>
              </Grid>
              <Grid item xs={8}>
                {player.city !== undefined && player.state !== undefined ? (
                  <Typography className="viewPlayerInfo">
                    {`${player.city}, ${player.state}`}
                  </Typography>
                ) : (
                  <Typography className="viewPlayerInfo">
                    Unspecified
                  </Typography>
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography className="viewPlayerInfoLabel">
                  Home Court
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography className="viewPlayerInfo">
                  {player.court !== "" ? player.court : ""}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="viewPlayerInfoLabel">
                  W/L (Games)
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography className="viewPlayerInfo">
                  {`${handleAddPickleballData(
                    "totalGameWins"
                  )} - ${handleAddPickleballData("totalGameLosses")}`}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="viewPlayerInfoLabel">
                  % (Games)
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography className="viewPlayerInfo">
                  {handleAddPickleballData("totalGameWins") +
                    handleAddPickleballData("totalGameLosses") >
                  0
                    ? (
                        handleAddPickleballData("totalGameWins") /
                        (handleAddPickleballData("totalGameWins") +
                          handleAddPickleballData("totalGameLosses"))
                      ).toFixed(4)
                    : (0).toFixed(4)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="viewPlayerInfoLabel">
                  GSM Rating (singles)
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography className="viewPlayerInfo">
                  {Math.round(handleAddPickleballData("gsmRating") * 100)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="viewPlayerInfoLabel">
                  GSM Rating (doubles)
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography className="viewPlayerInfo">
                  {Math.round(
                    handleAddPickleballData("gsmRatingDoubles") * 100
                  )}
                </Typography>
              </Grid>
            </Grid>
          </SideMenu>
          <Content>
            <div className="column maxHeight">
              <CustomTabs
                labels={pageLabels}
                value={pageNum}
                setValue={setPageNum}
              />
              {pageNum === 0 && (
                <Statistics
                  instance={user?.currentSport}
                  user={player}
                  leagues={leagues}
                  divisions={divisions}
                  tracks={tracks}
                  viewingOtherPlayer={true}
                />
              )}
              {pageNum === 1 && <ViewPlayerInfo player={player} />}
              {pageNum === 2 && (
                <PlayerReviews player={player} pageNum={pageNum} />
              )}
            </div>
          </Content>
        </Grid>
      </Fragment>
    );
  return (
    <div className="pageWrapper">
      <Loader
        type="TailSpin"
        color="#02c39a"
        height={100}
        width={100}
        timeout={3000}
      />
    </div>
  );
};

export default ViewPlayer;

import React from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";

const levelDescriptions = [
  { label: "Beginner", content: "I have not played tennis ever." },
  {
    label: "Beginner",
    content: "I have only played tennis a couple of times in my life.",
  },
  {
    label: "Beginner",
    content:
        "I am beginning to enjoy this game and can hit the ball to the other side.",
  },
  {
    label: "Beginner",
    content:
        "I am on my way. I have played a few matches with friends and rally with my opponents.",
  },
  {
    label: "Novice",
    content:
        "I can start to sustain a rally with ease and have confidence on my net game.",
  },
  {
    label: "Intermediate",
    content:
        "I have reliable strokes and developing some power and consistency.",
  },
  {
    label: "Advanced",
    content:
        "I am a regular competitor. I have an all-round game and can mix control, power, and consistency.",
  },
  {
    label: "Advanced",
    content:
        "I am a consistent competitor. I have an all-round game and can hold my own with club players.",
  },
  {
    label: "Competitive/D1",
    content:
        "I could or have played in College, Higher Club Divisions or Team Tennis.",
  },
  {
    label: "Competitive/D1",
    content:
        "I could or have played in College, Higher Club Divisions or Team Tennis plus more...",
  },
  {
    label: "Club-Level Pro",
    content:
        "I am ranked in my Club and compete with other clubs in the club circuit.",
  },
  {
    label: "Club-Level Pro",
    content:
        "I am ranked in my Club and compete with other clubs in the club circuit.",
  },
  {
    label: "Circuit Level Pro",
    content:
        "I am competing in regional pro and semi-pro circuits; I make a living or used to make a living playing tennis.",
  },
  {
    label: "Circuit Level Pro",
    content:
        "I am competing in regional pro and semi-pro circuits; I make a living or used to make a living playing tennis.",
  },
  {
    label: "World Class",
    content: "I am in the Current Top 200 ATP Rankings",
  },
];
const pickleballLevelDescriptions = [
  {
    label: "Beginner",
    content:
        "If this is the first time you are picking up a racket and pickleball.",
  },
  {
    label: "Beginner",
    content:
        "If this is the first time you are picking up a racket and pickleball.",
  },
  {
    label: "Beginner",
    content:
        "If this is the first time you are picking up a racket and pickleball.",
  },
  {
    label: "Novice",
    content:
        "You have limited experience with Pickleball and only starting to understand the rules of the game.",
  },
  {
    label: "Intermediate",
    content:
        "You can keep up with your opponent and can keep short rallies on the court and got the rules downpack.",
  },
  {
    label: "Advanced",
    content:
        "You can hit the ball with pace, and starting to develop consistency and control in your serves, drives and returns; you are able to vary your shots.",
  },
  {
    label: "Competitive/D1",
    content:
        "You can for the most part hit both forehand and backhand drives, services and returns with power and consistency; you still make a lot of unforced errors and you know the rules of the game pretty well.",
  },
  {
    label: "Club-Level Pro",
    content:
        "You understand what Staking means, you have low unforced errors and can tactically change your game at will to adapt to your opponent.",
  },
  {
    label: "Circuit Level Pro",
    content:
        "You have a good mastery of all the shots and are very very in terms of strategy and rules of the game. You rarely make unforced error and can attack your opponents weeknesses on the court",
  },
  {
    label: "World Class",
    content:
        "You are on top of not just your game but keeping up with the best professional pickleball players at the tournament level. You can known not just in your region but also know nationwide.",
  },
];

export const InfoPopover = ({ isPickleball }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="signUpRatingWrapper">
      <IconButton className="linkbutton" onClick={handleClick}>
        Help
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="signUpRatingDescriptionWrapper">
          <div className="rowCenter">
            <Typography className="margin10Vertical rewardPurchaseItemName">
              Skill Levels
            </Typography>
            <div className="filler" />
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
          {isPickleball &&
            pickleballLevelDescriptions.map((item, key) => (
              <Typography className="margin10Top" key={key}>
                <span className="signUpRatingText">{item.label} </span>
                {item.content}
              </Typography>
            ))}
          {!isPickleball &&
            levelDescriptions.map((item, key) => (
              <Typography className="margin10Top" key={key}>
                <span className="signUpRatingText">{item.label} </span>
                {item.content}
              </Typography>
            ))}
        </div>
      </Popover>
    </div>
  );
};

import { functions } from "../firebase";

export const simpleEmail = async ({ subject, message, recipients }) => {
  const notifyPlayers = functions.httpsCallable("emailNotifications-simple");
  await notifyPlayers({
    subject,
    message,
    recipients,
  })
    .then()
    .catch((error) => console.log(error));
};

export const IntroductionEmail = async ({ subject, message, recipients }) => {
  try {
    const notifyPlayers = functions.httpsCallable(
      "emailNotifications-introduction"
    );
    await notifyPlayers({
      subject,
      message,
      recipients,
    });
    return true;
  } catch (error) {
    console.log("emailNotifications Error", error);
    return false;
  }
};

export const noActionEmail = async ({
  subject,
  message,
  message2,
  message3,
  message4,
  recipients,
}) => {
  const notifyPlayers = functions.httpsCallable("emailNotifications-noAction");
  await notifyPlayers({
    subject,
    message,
    message2,
    message3,
    message4,
    recipients,
  })
    .then()
    .catch((error) => console.log(error));
};

export const singleActionEmail = async ({
  subject,
  message,
  message2,
  message3,
  message4,
  recipients,
  url,
  buttonText,
}) => {
  const notifyPlayers = functions.httpsCallable(
    "emailNotifications-singleAction"
  );
  await notifyPlayers({
    subject,
    message,
    message2,
    message3,
    message4,
    recipients,
    url,
    buttonText,
  })
    .then()
    .catch((error) => console.log(error));
};

export const introOpponent = async ({
  subject,
  message,
  message2,
  message3,
  message4,
  recipients,
  url,
  buttonText,
}) => {
  const notifyPlayers = functions.httpsCallable(
    "emailNotifications-introOpponent"
  );
  await notifyPlayers({
    subject,
    message,
    message2,
    message3,
    message4,
    recipients,
    url,
    buttonText,
  })
    .then()
    .catch((error) => console.log(error));
};

export const matchPlayedEmail = async ({
  subject,
  scores,
  recipients,
  gameMode,
}) => {
  const notifyPlayers = functions.httpsCallable(
    "emailNotifications-matchPlayed"
  );
  await notifyPlayers({
    subject,
    recipients,
    scores,
    gameMode,
  })
    .then()
    .catch((error) => console.log(error));
};

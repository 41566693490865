import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import update from "immutability-helper";
import DateFnsUtils from "@date-io/date-fns";
import { storage, firestore } from "../../../firebase";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
//NOTE: add empty string to geturl function and that way I can handle when theres no id
const RewardEditForm = ({ open, setOpen, reward, isEditing }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateDisplay, setStartDateDisplay] = useState(null);
  const [endDateDisplay, setEndDateDisplay] = useState(null);
  const [units, setUnits] = useState(0);
  const [points, setPoints] = useState(0);
  const [imageFiles, setImageFiles] = useState([]);
  const [imagesDisplayed, setImagesDisplayed] = useState([]);
  const [imagesDeleted, setImagesDeleted] = useState([]);
  const [error, setError] = useState("");
  useEffect(() => {
    const calcDisplayDate = (date) => {
      let convertedDate = reward.startDate.toDate();
      let day = convertedDate.getDate();
      let month = convertedDate.getMonth() + 1;
      return month + "/" + day + "/" + convertedDate.getFullYear();
    };
    if (isEditing) {
      setName(reward.name);
      setDescription(reward.description);
      setStartDate(reward.startDate);
      setEndDate(reward.endDate);
      setStartDateDisplay(calcDisplayDate(reward.startDate));
      setEndDateDisplay(calcDisplayDate(reward.endDate));
      setPoints(reward.points);
      setUnits(reward.units);
      setImagesDisplayed(
        reward.imageURLS.map((image) => {
          return { image: image, associatedFile: "none" };
        })
      );
      setImageFiles([]);
    } else {
      setName("");
      setDescription("");
      setStartDate(null);
      setEndDate(null);
      setStartDateDisplay(null);
      setEndDateDisplay(null);
      setImagesDisplayed([]);
      setImageFiles([]);
      setPoints(0);
      setUnits(0);
    }
    setImagesDeleted([]);
  }, [open, reward, isEditing]);
  const semiRandValue = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  const retrieveImageURLS = async (id) => {
    let getURLS = [];
    let storageRef = storage.ref(
      `rewardDocuments/${id === "" ? reward.id : id}`
    );
    await storageRef.listAll().then(async (result) => {
      await Promise.all(
        result.items.map(async (imageRef) => {
          await imageRef.getDownloadURL().then((url) => {
            getURLS.push(url);
          });
        })
      );
    });
    return getURLS;
  };
  const updateReward = async (e) => {
    e.preventDefault();
    if (isEditing) {
      await Promise.all(
        imagesDeleted.map(async (image) => {
          await storage.refFromURL(image).delete();
        })
      );
      await reward.ref.update({
        name: name,
        description: description,
        startDate: startDate,
        endDate: endDate,
        units: units,
        points: points,
      });
      if (imageFiles.length > 0) {
        const storageRef = storage.ref(`rewardDocuments/${reward.id}`);
        await Promise.all(
          imageFiles.map(async (image, index) => {
            await storageRef.child(`image${semiRandValue()}`).put(image);
          })
        );
        const getURLS = await retrieveImageURLS("");
        await reward.ref.update({ imageURLS: getURLS });
      } else if (imagesDeleted.length > 0) {
        const getURLS = await retrieveImageURLS("");
        await reward.ref.update({ imageURLS: getURLS });
      }
    } else {
      firestore
        .collection("rewards")
        .add({
          name: name,
          description: description,
          startDate: startDate,
          endDate: endDate,
          units: units,
          points: points,
          unitsClaimed: 0,
        })
        .then(async (docRef) => {
          const storageRef = storage.ref(`rewardDocuments/${docRef.id}`);
          await Promise.all(
            imageFiles.map(async (image, index) => {
              await storageRef.child(`image${semiRandValue()}`).put(image);
            })
          );
          const getURLS = await retrieveImageURLS(docRef.id);
          await docRef.update({ imageURLS: getURLS });
        });
    }
    handleClose();
  };
  const handleImageDelete = (key) => {
    const imageSelected = imagesDisplayed[key];
    setImagesDisplayed(update(imagesDisplayed, { $splice: [[key, 1]] }));
    if (imageSelected.associatedFile !== "none") {
      const index = imageFiles.findIndex(
        (imageFile) => imageSelected.associatedFile === imageFile
      );
      setImageFiles(update(imageFiles, { $splice: [[index, 1]] }));
    } else {
      let imagesDeletedTemp = imagesDeleted;
      imagesDeletedTemp.push(imageSelected.image);
      setImagesDeleted(imagesDeletedTemp);
    }
  };
  const handleDelete = async () => {
    await reward.ref.delete();
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogContent>
        <form className="columnCenter" onSubmit={updateReward}>
          <TextField
            className="fieldMarginTop"
            variant="outlined"
            required
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            label="Name:"
          />
          <TextField
            className="fieldMarginTop"
            variant="outlined"
            multiline
            rows={4}
            required
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            label="Description:"
          />
          <div className="margin10" />
          <div className="rowCenter">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className="field45MarginTop"
                required
                clearable
                inputVariant="outlined"
                openTo="year"
                format="MM/dd/yyyy"
                label="Start Date"
                views={["year", "month", "date"]}
                value={startDateDisplay}
                onChange={(date) => {
                  if (date) {
                    const day = date.getDate();
                    const month = date.getMonth() + 1;
                    const dateCreated =
                      month + "/" + day + "/" + date.getFullYear();
                    setStartDate(date);
                    setStartDateDisplay(dateCreated);
                  }
                }}
              />
            </MuiPickersUtilsProvider>
            <div className="filler" />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className="field45MarginTop"
                clearable
                required
                inputVariant="outlined"
                openTo="year"
                format="MM/dd/yyyy"
                label="End Date"
                views={["year", "month", "date"]}
                value={endDateDisplay}
                onChange={(date) => {
                  if (date) {
                    const day = date.getDate();
                    const month = date.getMonth() + 1;
                    const dateCreated =
                      month + "/" + day + "/" + date.getFullYear();
                    setEndDate(date);
                    setEndDateDisplay(dateCreated);
                  }
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="margin10" />
          <div className="rowCenter">
            <TextField
              className="field45MarginTop"
              variant="outlined"
              required
              type="number"
              value={points.toString(10)}
              onChange={(e) => {
                setPoints(parseInt(e.target.value));
              }}
              label="Points Cost:"
            />
            <div className="filler" />
            <TextField
              className="field45MarginTop"
              variant="outlined"
              required
              type="number"
              value={units.toString(10)}
              onChange={(e) => {
                setUnits(parseInt(e.target.value));
              }}
              label="Units Available:"
            />
          </div>
          <div className="margin10" />
          <div className="columnCenter">
            <label htmlFor="contained-button-file">
              <Button component="span">
                Upload a max of 3 images of your reward
              </Button>
            </label>
            <div className="margin10" />
            <div className="rowCenter">
              {imagesDisplayed.map((imageObj, key) => (
                <div key={key} className="rewardEditImageWrapper">
                  <Avatar
                    variant="rounded"
                    className="rewardEditImage"
                    src={imageObj.image}
                  />
                  <IconButton onClick={() => handleImageDelete(key)}>
                    <Delete />
                  </IconButton>
                </div>
              ))}
            </div>
            <input
              type="file"
              hidden
              id="contained-button-file"
              onChange={(e) => {
                if (imagesDisplayed.length < 3) {
                  const imageFilesTemp = update(imageFiles, {
                    $push: [e.target.files[0]],
                  });
                  setImageFiles(imageFilesTemp);
                  if (e.target.files[0] && e.target.files) {
                    const imagesDisplayedTemp = update(imagesDisplayed, {
                      $push: [
                        {
                          image: URL.createObjectURL(e.target.files[0]),
                          associatedFile: e.target.files[0],
                        },
                      ],
                    });
                    setImagesDisplayed(imagesDisplayedTemp);
                  }
                } else {
                  setError("Max of 3 images");
                }
              }}
            />
          </div>
          <div className="margin10" />

          <div className="rowCenter">
            <Button
              disabled={!isEditing}
              variant="contained"
              className="errBackground"
              onClick={handleDelete}
            >
              Delete
            </Button>
            <div className="filler" />
            <Button
              variant="contained"
              className="primaryColorBackground"
              type="submit"
            >
              Save
            </Button>
          </div>
          {error !== "" && (
            <Typography variant="subtitle1" className="err">
              {error}
            </Typography>
          )}
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default RewardEditForm;

import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
import Help from "@material-ui/icons/Help";
//import ArrowBack from "@material-ui/icons/ArrowBack";
export const CloseButton = ({ setState, disabled, tooltip }) => {
  const toggle = () => {
    setState((curr) => !curr);
  };
  return (
    <Tooltip title={tooltip}>
      <IconButton
        disabled={disabled}
        onClick={toggle}
        className="margin10Bottom"
      >
        <Close className="appBarUploadScoreButtonIcon" />
      </IconButton>
    </Tooltip>
  );
};

export const HelpButton = ({ setState, disabled, tooltip }) => {
  const toggle = () => {
    setState((curr) => !curr);
  };
  return (
    <Tooltip title={tooltip}>
      <IconButton
        disabled={disabled}
        onClick={toggle}
        className="margin10Bottom"
      >
        <Help className="appBarUploadScoreButtonIcon" />
      </IconButton>
    </Tooltip>
  );
};

export const OutlinedButton = ({ text, handleProcess, disabled }) => {
  return (
    <Button
      className="purchaseButton"
      variant="outlined"
      onClick={handleProcess}
      disabled={disabled}
    >
      <Typography className="purchaseButtonText">{text}</Typography>
    </Button>
  );
};

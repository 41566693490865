import React from "react";
import MUIRichTextEditor from "mui-rte";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
const ViewRichText = ({ value }) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#55B56A",
        light: "#EDF8F4",
        dark: "#028360",
      },
    },
    overrides: {
      MUIRichTextEditor: {
        root: {
          border: "none",
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <MUIRichTextEditor toolbar={false} readOnly={true} defaultValue={value} />
    </ThemeProvider>
  );
};
export default ViewRichText;

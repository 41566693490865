import _ from "lodash";
import moment from "moment";
import robin from "roundrobin";
import { Firebase, firestore } from "../firebase";
import {divisionData} from "../components/Common";

//Get Methods
export const getSiteValues = async () => {
  const siteValues = await firestore.doc("admin/siteValues").get();
  return siteValues.data();
};

export const getUserDoubleFormateData = async (userId, sport) => {
  const scoreRef = firestore
    .collection("doublesTeams")
    .where("players", "array-contains", userId);
  const data = await scoreRef.get();
  const arr = [];
  data.docs.forEach((item) => {
    arr.push(item.id);
  });
  let maxLength = 10;
  let newArr = [];
  const wdata = [];
  for (var i = 0; i < arr.length; i++) {
    if (i < maxLength) {
      newArr.push(arr[i]);
    } else {
      maxLength += 10;
      const winnerData = await firestore
        .collection("scores")
        .where("sportType", "==", sport)
        .where("winner", "in", newArr)
        .get();

      winnerData.docs.forEach((item) => {
        wdata.push({ ...item.data(), id: item.id });
      });
      newArr = [arr[i]];
    }
  }
  if (newArr.length > 0) {
    const winnerData = await firestore
      .collection("scores")
      .where("sportType", "==", sport)
      .where("winner", "in", newArr)
      .get();

    winnerData.docs.forEach((item) => {
      wdata.push({ ...item.data(), id: item.id });
    });
  }

  return wdata;
};

export const getUserWinnerData = async (userId, sport) => {
  if (sport && userId) {
    const scoreRef = firestore
      .collection("scores")
      .where("sportType", "==", sport)
      .where("winner", "==", userId);
    const data = await scoreRef.get();
    let singleFormate = [];
    data.docs.forEach((item) => {
      singleFormate.push(item.data());
    });
    const doubleFormateData = await getUserDoubleFormateData(userId, sport);
    return [...singleFormate, ...doubleFormateData];
  }
  return [];
};

export const isValidInviteCode = async (inviteCode) => {
  const codeData = await firestore
    .collection("leagues")
    .where("isPrivate", "==", true)
    .where("inviteCode", "==", inviteCode)
    .get();

  return codeData.docs.length === 0;
};

export const getScore = async (id) => {
  const data = await firestore.doc(`scores/${id}`).get();
  return {
    id,
    ...data.data(),
  };
};

export const setCurrentSport = async (isPickleball, userId) => {
  await firestore
    .collection("users")
    .doc(userId)
    .update({
      currentSport: isPickleball ? "tennis" : "pickleball",
    });
};

export const getPickleBallData = (obj, user) => {
  // obj.ntrpRating = user.pb_ntrpRating;
  obj.currentSport = user.currentSport || "pickleball";
  obj.gsmRating = user.pb_gsmRating || 1;
  obj.leaguesJoined = user.pb_leaguesJoined || [];
  obj.gsmRatingDoubles = user.pb_gsmRatingDoubles || 1;
  obj.totalMatches = user.pb_totalMatches || 0;
  obj.totalMatchWins = user.pb_totalMatchWins || 0;
  obj.totalMatchLosses = user.pb_totalMatchLosses || 0;
  obj.totalGameWins = user.pb_totalGameWins || 0;
  obj.totalGameLosses = user.pb_totalGameLosses || 0;
  obj.totalSetWins = user.pb_totalSetWins || 0;
  obj.totalSetLosses = user.pb_totalSetLosses || 0;
  obj.totalMatchesAbandoned = user.pb_totalMatchesAbandoned || 0;
  obj.totalDoublesMatches = user.pb_totalDoublesMatches || 0;
  obj.totalDoublesMatchWins = user.pb_totalDoublesMatchWins || 0;
  obj.totalDoublesMatchLosses = user.pb_totalDoublesMatchLosses || 0;
  obj.totalDoublesSetWins = user.pb_totalDoublesSetWins || 0;
  obj.totalDoublesSetLosses = user.pb_totalDoublesSetLosses || 0;
  obj.totalDoublesGameWins = user.pb_totalDoublesGameWins || 0;
  obj.totalDoublesGameLosses = user.pb_totalDoublesGameLosses || 0;
  obj.totalDoublesMatchesAbandoned = user.pb_totalDoublesMatchesAbandone || 0;
  return obj;
};

export const getLeagueById = async (id) => {
  const data = await firestore.doc(`leagues/${id}`).get();
  return {
    id,
    ...data.data(),
  };
};

export const getDivisionById = async (leagueId, divisionID) => {
  const leagueRef = firestore.collection(`leagues`).doc(leagueId);
  const divisionRef = leagueRef.collection("divisions").doc(divisionID);
  const division = (await divisionRef.get()).data();
  return {
    divisionID,
    ...division,
  };
};
export const getTrackById = async (leagueId, divisionID, trackID) => {
  const leagueRef = firestore.collection(`leagues`).doc(leagueId);
  const divisionRef = leagueRef.collection("divisions").doc(divisionID);
  const trackRef = divisionRef.collection("tracks").doc(trackID);
  const track = (await trackRef.get()).data();
  return {
    trackID,
    ref: trackRef,
    ...track,
  };
};

export const getUpadteMatchSchedule = async (id) => {
  const reff = await firestore.doc(`matchSchedules/${id}`);
  reff.update({
    isMailSent: true,
  });
};

export const getUpadtedMatchSchedule = async (id) => {
  const data = await firestore.doc(`matchSchedules/${id}`).get();
  return {
    id,
    ...data.data(),
  };
};

export const getMailSentData = async (id) => {
  let combinedLeagueList = [];
  const dataref = firestore
    .collection("matchSchedules")
    .where("leagueID", "==", id)
    .where("isMailSent", "==", true);
  let tracksSnap = await dataref.get();
  tracksSnap.forEach((trackDoc) => {
    combinedLeagueList.push(trackDoc.id);
  });
  return combinedLeagueList;
};

export const getExpiredLeague = async (sport) => {
  const LeagueReff = firestore
    .collection(`leagues`)
    .where("sportType", "==", sport)
    .where("status", "==", "expired");
  let expiredLeague = await LeagueReff.get();
  return expiredLeague;
};

export const getOutCome = async (league, userId, sport) => {
  let outcome = "DNQ for Playoffs";
  let winnertag = [
    "CHAMPION",
    "FINALISTS",
    "SEMI-FINALISTS",
    "QUARTER-FINALISTS",
    "ROUND of 16",
  ];
  let LooserTag = [
    "FINALISTS",
    "SEMI-FINALISTS",
    "QUARTER-FINALISTS",
    "ROUND of 16",
    "ROUND of 32",
  ];
  const getExpireLeague = await getExpiredLeague(sport || "tennis");

  await Promise.all(
    getExpireLeague.docs.map(async (leaguDoc) => {
      let divisionsRef = firestore
        .collection("leagues")
        .doc(leaguDoc.id)
        .collection("divisions")
        .doc(league.divisionID)
        .collection("tracks")
        .doc(league.trackID);
      await divisionsRef.get().then((resp) => {
        if (resp) {
          let data = resp.data();
          if (data !== undefined) {
            if (data?.playoffsPlayers?.includes(userId)) {
              const reversedKeys = Object.keys(data?.playoffsBracket).reverse();
              if (reversedKeys) {
                let isUserInFinalArray = false;
                for (let l = 0; l < reversedKeys.length; l++) {
                  const key = reversedKeys[l];
                  const index = l;
                  if (data?.playoffsBracket?.[key]?.length > 0) {
                    for (let k = 0; k < data.playoffsBracket[key].length; k++) {
                      const element = data?.playoffsBracket?.[key]?.[k];
                      isUserInFinalArray = element?.list.includes(userId);
                      if (isUserInFinalArray) {
                        const isUserWinner = getScore(element.score);
                        outcome =
                          isUserWinner.winner === userId
                            ? winnertag[index]
                            : LooserTag[index];
                        break;
                      }
                    }
                  }
                  if (isUserInFinalArray) {
                    break;
                  }
                }
              }
            } else {
              outcome = "DNQ for Playoffs";
            }
          }
        }
      });
    })
  );
  return outcome;
};

export const getLeagueTracksById = async (leagueId, leagueType) => {
  let combinedLeagueList = [];
  await getLeagueById(leagueId);
  const divisionsSnap = await firestore
    .collection("leagues")
    .doc(leagueId)
    .collection("divisions")
    .get();
  await Promise.all(
    divisionsSnap.docs.map(async (divisionDoc) => {
      let tracksRef = firestore
        .collection("leagues")
        .doc(leagueId)
        .collection("divisions")
        .doc(divisionDoc.id)
        .collection("tracks");
      let tracksSnap = await tracksRef.get();
      const leagueData = await firestore.doc(`leagues/${leagueId}`).get();

      tracksSnap.forEach((trackDoc) => {
        combinedLeagueList.push({
          ...leagueData.data(),
          leagueID: leagueId,
          ...divisionDoc.data(),
          ...trackDoc.data(),
          id: trackDoc.id,
          ref: trackDoc.ref,
          leagueType: leagueType,
        });
      });
    })
  );
  return combinedLeagueList;
};

export const getLeagueTracks = async (leagues, leagueType) => {
  let combinedLeagueList = [];
  await Promise.all(
    leagues.map(async (league) => {
      let divisionsRef = firestore
        .collection("leagues")
        .doc(league.id)
        .collection("divisions");
      const divisionsSnap = await divisionsRef.get();
      return await Promise.all(
        divisionsSnap.docs.map(async (divisionDoc) => {
          let tracksRef = firestore
            .collection("leagues")
            .doc(league.id)
            .collection("divisions")
            .doc(divisionDoc.id)
            .collection("tracks");
          let tracksSnap = await tracksRef.get();
          tracksSnap.forEach((trackDoc) => {
            combinedLeagueList.push({
              ...league,
              ...divisionDoc.data(),
              ...trackDoc.data(),
              id: trackDoc.id,
              ref: trackDoc.ref,
              leagueType: leagueType,
            });
          });
        })
      );
    })
  );
  return combinedLeagueList;
};
export const getFirebaseDoc = async (path) => {
  const ref = firestore.doc(path);
  const doc = await ref.get();
  return { ...doc.data(), id: doc.id, ref: doc.ref };
};
export const getScoresByParticipantID = async (id) => {
  const snapshotA = await firestore
    .collection("scores")
    .where("winner", "==", id)
    .get();
  const snapshotB = await firestore
    .collection("scores")
    .where("loser", "==", id)
    .get();
  const wins = snapshotA.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
  const losses = snapshotB.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
  return [...wins, ...losses];
};
export const getScoresByParticipantTrackAndDate = async (
  participantID,
  trackID,
  matchDate
) => {
  const snapshotA = await firestore
    .collection("scores")
    .where("winner", "==", participantID)
    .where("trackID", "==", trackID)
    .where("matchDate", "==", matchDate)
    .get();
  const snapshotB = await firestore
    .collection("scores")
    .where("loser", "==", participantID)
    .where("trackID", "==", trackID)
    .where("matchDate", "==", matchDate)
    .get();
  const wins = snapshotA.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
  const losses = snapshotB.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
  return [...wins, ...losses];
};
export const getReviews = async (id) => {
  const reviewsDoc = await firestore.collection("reviews").get();
  return reviewsDoc.docs.map((doc) => {
    return { id: doc.data, ...doc.data() };
  });
};
export const getDirectorApplications = async () => {
  const snapshot = await firestore.collection("directorApplication").get();
  return snapshot.docs.map((doc) => doc.data());
};

export const getCoupons = async () => {
  const snapshot = await firestore.collection("coupons").get();
  return snapshot.docs.map((doc) => doc.data());
};

export const getMatchSchedulesByParticipant = async (id) => {
  const snapshot = await firestore
    .collection("matchSchedules")
    .where("participants", "array-contains", id)
    .get();
  return snapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
};
export const getMatchSchedulesByParticipants = async (ids) => {
  let schedule = [];
  await Promise.all(
    ids.map(async (id) => {
      const participantSchedules = await getMatchSchedulesByParticipant(id);
      schedule.push(...participantSchedules);
    })
  );
  return schedule;
};
export const getMatchSchedulesByTrack = async (track) => {
  const snapshot = await firestore
    .collection("matchSchedules")
    .where("leagueID", "==", track.leagueID)
    .where("divisionID", "==", track.divisionID)
    .where("trackID", "==", track.id)
    .get();
  return snapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
};

export const getScoresByTrack = async (track) => {
  const snapshot = await firestore
    .collection("scores")
    .where("leagueID", "==", track.leagueID)
    .where("divisionID", "==", track.divisionID)
    .where("trackID", "==", track.id)
    .get();
  return snapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id, ref: doc.ref };
  });
};
export const getScoresByLeague = async (league) => {
  const snapshot = await firestore
    .collection("scores")
    .where("leagueID", "==", league.id)
    .get();
  return snapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
};

export const getUserByID = async (id) => {
  const userDocument = await firestore.doc(`users/${id}`).get();
  const data = userDocument.data();
  return {
    id,
    ...data,
    uid: id,
    name: `${data?.firstName} ${data?.lastName}`,
    ref: userDocument.ref,
  };
};

export const getUsersByID = async (ids) => {
  if (ids?.length > 0) {
    let playersTemp = await Promise.all(
      ids?.map(async (id) => {
        return await getUserByID(id);
      })
    );
    return playersTemp;
  } else {
    return [];
  }
};

export const handleEditDataColumn = async (moderateData) => {
  let _updateData = moderateData;
  for (let key in _updateData) {
    _updateData[key].items = await getUsersByID(_updateData[key].items);
  }
  return _updateData;
};

export const moderateDataSave = async (
  selectedMatchIdForModerate,
  ColumObj
) => {
  for (let keey in ColumObj) {
    let dragIdArr = [];
    for (let h = 0; h < ColumObj[keey].items.length; h++) {
      if (ColumObj[keey].items.length > 0) {
        const element = ColumObj[keey].items[h].id;
        dragIdArr.push(element);
      }
    }
    ColumObj[keey].items = dragIdArr;
  }
  await firestore.doc(`matchSchedules/${selectedMatchIdForModerate}`).update({
    moderateData: ColumObj,
  });
};

export const checkCourtStatus = (columnObj, isSingleCourt = false) => {
  let _columnnn = columnObj;
  for (let keyy in _columnnn) {
    if (keyy !== "registerPlayer" && keyy !== "waitingRoom") {
      const updateStatus = isSingleCourt ? 2 : 4;
      if (_columnnn[keyy].items.length === updateStatus) {
        if (_columnnn[keyy].status !== "In Progress")
          _columnnn[keyy].status = "Ready to Play";
      } else {
        _columnnn[keyy].status = null;
      }
    }
  }

  return _columnnn;
};

export const getUsersInTrack = async (track) => {
  const trackPlayers = await getUsersByID(track.players);
  return trackPlayers;
};
export const getUsersInTrackModerate = async (players) => {
  const trackPlayers = await getUsersByID(players);
  return trackPlayers;
};

export const checkNewPlayerSignup = (newPlayerArray, columns) => {
  let alreadyplayerArr = [];
  for (let keey in columns) {
    for (let i = 0; i < columns[keey].items.length; i++) {
      if (columns[keey].items.length > 0) {
        const playerId = columns[keey].items[i].id;
        alreadyplayerArr.push(playerId);
      }
    }
  }
  return newPlayerArray.filter(function (n) {
    return !this.has(n);
  }, new Set(alreadyplayerArr));
};

export const getDoublesTeamByID = async (id) => {
  const doublesTeamDoc = await firestore.doc(`doublesTeams/${id}`).get();
  let doublesTeam = {
    id,
    ...doublesTeamDoc.data(),
    ref: doublesTeamDoc.ref,
  };
  const players = await getUsersByID(doublesTeam.players);
  doublesTeam.players = players;
  doublesTeam.teamName = `${players?.[0]?.lastName} - ${players?.[1]?.lastName}`;
  return doublesTeam;
};
export const getDoublesTeamsByID = async (ids) => {
  if (ids?.length > 0) {
    let doublesTeams = await Promise.all(
      ids.map(async (id) => {
        return await getDoublesTeamByID(id);
      })
    );
    return doublesTeams;
  } else {
    return [];
  }
};

//Generation Methods
export const generateMatchSchedules = async (league, tracks, divisions) => {
  const createSinglesMatchSchedule = async (startDate, numDays, track) => {
    const users = await getUsersByID(
      track.players.filter(
        (id) => !track.withdrawn.some((withdrawn) => withdrawn === id)
      )
    );
    const numPlayers = users.length;
    const roundRobin = robin(numPlayers, users);
    const daysPerMatch = Math.floor(numDays / roundRobin.length);
    let currDate = startDate;
    for (let i = 0; i < roundRobin.length; i++) {
      let start = currDate.toDate();
      currDate = moment(currDate, "MM-DD-YY").add(daysPerMatch, "d");
      let end = currDate.toDate();
      let matches = roundRobin[i];
      for (let j = 0; j < roundRobin[i].length; j++) {
        let player1 = matches[j][0];
        let player2 = matches[j][1];
        let court = player1.court !== "" ? player1.court : player2.court;
        await firestore.collection("matchSchedules").add({
          leagueID: league.id,
          divisionID: track.divisionID,
          trackID: track.id,
          start: start,
          end: end,
          status: "notPlayed",
          participants: [player1.id, player2.id],
          doublesFormat: false,
          venue: court,
        });
      }
    }
  };
  const createDoublesMatchSchedule = async (startDate, numDays, track) => {
    const teams = await getDoublesTeamsByID(
      track.doublesTeams.filter(
        (id) => !track.withdrawn.some((withdrawn) => withdrawn === id)
      )
    );
    const numTeams = teams.length;
    const roundRobin = robin(numTeams, teams);
    const daysPerMatch = Math.floor(numDays / roundRobin.length);
    let currDate = startDate;
    for (let i = 0; i < roundRobin.length; i++) {
      let start = currDate.toDate();
      currDate = moment(currDate, "MM-DD-YY").add(daysPerMatch, "d");
      let end = currDate.toDate();
      let matches = roundRobin[i];
      for (let j = 0; j < roundRobin[i].length; j++) {
        let team1 = matches[j][0];
        let team2 = matches[j][1];
        let court =
          team1.players[0].court !== ""
            ? team1.players[0].court
            : team2.players[0].court;
        await firestore.collection("matchSchedules").add({
          leagueID: league.id,
          divisionID: track.divisionID,
          trackID: track.id,
          start: start,
          end: end,
          status: "notPlayed",
          participants: [team1.id, team2.id],
          doublesFormat: true,
          venue: court,
          isMailSent: false,
        });
      }
    }
  };

  await Promise.all(
    Object.keys(tracks).map(async (trackID) => {
      const track = tracks[trackID];
      if (track.leagueID !== league.id) return;
      moment().format();
      const startDate = moment(league.startDate.toDate(), "MM-DD-YY");
      const endDate = moment(league.endDate.toDate(), "MM-DD-YY");
      const numDays = endDate.diff(startDate, "days");
      if (divisions[track.divisionID].doublesFormat)
        await createDoublesMatchSchedule(startDate, numDays, track);
      else await createSinglesMatchSchedule(startDate, numDays, track);
    })
  );
  await firestore
    .doc(`leagues/${league.id}`)
    .update({ scheduleGenerated: true });
};

export const generateModeratedMatchSchedules = async (
  league,
  tracks,
  divisions,
  filterWeekData
) => {
  const createModeratedMatchSchedule = async (track) => {
    for (let k = 0; k < filterWeekData.length; k++) {
      await firestore.collection("matchSchedules").add({
        leagueID: league.id,
        divisionID: track.divisionID,
        trackID: track.id,
        start: moment(filterWeekData[k], "YYYY-MM-DD").toDate(),
        end: moment(filterWeekData[k], "YYYY-MM-DD").toDate(),
        status: "notPlayed",
        doublesFormat: true,
        venue: "",
      });
    }
  };

  await Promise.all(
    Object.keys(tracks).map(async (trackID) => {
      const track = tracks[trackID];
      if (track.leagueID !== league.id) return;
      moment().format();
      await createModeratedMatchSchedule(track);
    })
  );
  await firestore
    .doc(`leagues/${league.id}`)
    .update({ scheduleGenerated: true });
};

export const handleMakeDoubleTeamForModerate = async (
  brackets,
  tracks,
  isPickleball
) => {
  let DoubleTeamsIds = [];
  let newDoubleTeamsArr = _.map(brackets, _.clone);

  for (let j = 0; j < brackets.length; j++) {
    for (const key of Object.keys(brackets[j])) {
      for (let k = 0; k < brackets[j][key].length; k++) {
        let slot = brackets[j][key][k];
        if (slot.list.length === 4 && !divisionData.includes(slot.division)) {
          let team1 = [slot?.list?.[0]?.id, slot?.list?.[1]?.id];
          let team2 = [slot?.list?.[2]?.id, slot?.list?.[3]?.id];
          for (let i = 0; i < 2; i++) {
            const teamArr = i === 0 ? team1 : team2;
            if (
              teamArr[0].substring(0, 3) !== "bye" ||
              teamArr[1].substring(0, 3) !== "bye"
            ) {
             let filterDoubleTeam = await firestore
              .collection("doublesTeams")
              .where("players", "==", teamArr)
              .get();
              let doublesTeamID
              if (!filterDoubleTeam.empty) {
                doublesTeamID = filterDoubleTeam.docs[0].id;
              } else {
                let docRef = await firestore.collection("doublesTeams").add({
                  players: teamArr,
                });
                doublesTeamID = docRef.id;
              }
              // await firestore
              //   .collection("doublesTeams")
              //   .add({
              //     players: teamArr,
              //   })
                // .then(async (doc) => {
                //   let doublesTeamID = doc.id;
                  await tracks?.[j]?.track?.ref?.update({
                    doublesTeams:
                      Firebase.firestore.FieldValue.arrayUnion(doublesTeamID),
                  });
                  DoubleTeamsIds.push(doublesTeamID);
                  let team = await getDoublesTeamByID(doublesTeamID);
                  const playerRecord = team?.players?.[0]?.leaguesJoined?.find(
                    (leagueJoined) =>
                      leagueJoined?.trackID === tracks[j].track.id
                  );
                  const pb_playerRecord =
                    team?.players?.[0]?.pb_leaguesJoined?.find(
                      (leagueJoined) =>
                        leagueJoined?.trackID === tracks[j].track.id
                    );
                  let record = isPickleball ? pb_playerRecord : playerRecord;
                  let obj = {
                    ...team,
                    rank: record?.rank,
                    withdrawn: record?.withdrawn,
                    winloss:
                      record?.matchWins + record?.matchLosses > 0
                        ? (
                            record?.matchWins /
                            (record?.matchWins + record?.matchLosses)
                          ).toFixed(4)
                        : (0).toFixed(4),
                  };
                  newDoubleTeamsArr[j][key][k].list = newDoubleTeamsArr[j][key][
                    k
                  ].list.filter((item) => {
                    if (item.id === teamArr[0] || item.id === teamArr[1]) {
                      return false;
                    } else {
                      return true;
                    }
                  });
                  newDoubleTeamsArr[j][key][k].list[i] = obj;
                // });
            }
          }
        }
      }
    }
  }
  return { newDoubleTeamsArr, DoubleTeamsIds };
};

export const generatePlayoffsList = async (tracks, isModerated) => {
  const createSinglesPlayoffBracket = (track) => {
    let playoffsBracket = {};
    let numCols = 0;
    let numPlayers = track.players.length - track.withdrawn.length;
    while (Math.pow(2, numCols) < numPlayers) {
      numCols++;
    }
    for (let i = numCols - 1; i >= 0; i--) {
      let col = [];
      for (let j = 0; j < Math.pow(2, i); j++) {
        if (i === numCols - 1) {
          col.push({ list: [], score: "" });
        } else col.push({ list: ["empty", "empty"], score: "" });
      }
      playoffsBracket[numCols - 1 - i] = col;
    }
    return playoffsBracket;
  };
  const createModeratePlayoffBracket = (track) => {
    let playoffsBracket = {};
    let numCols = 0;
    let removeIfOdd = [1, 5, 9, 17, 33, 65];
    let numPlayers = track.players.length - track.withdrawn.length;
    while (Math.pow(2, numCols) < numPlayers) {
      numCols++;
    }
    if (removeIfOdd.includes(numPlayers)) {
      numCols = numCols - 1;
    }
    for (let i = numCols - 2; i >= 0; i--) {
      let col = [];

      for (let j = 0; j < Math.pow(2, i); j++) {
        if (i === numCols - 2) {
          col.push({ list: [], score: "" });
        } else col.push({ list: ["empty", "empty"], score: "" });
      }
      playoffsBracket[numCols - 2 - i] = col;
    }
    return playoffsBracket;
  };

  const createDoublesPlayoffBracket = (track) => {
    let playoffsBracket = {};
    let numCols = 0;
    let numTeams = track.doublesTeams.length - track.withdrawn.length;
    while (Math.pow(2, numCols) < numTeams) {
      numCols++;
    }
    for (let i = numCols - 1; i >= 0; i--) {
      let col = [];
      for (let j = 0; j < Math.pow(2, i); j++) {
        if (i === numCols - 1) {
          col.push({ list: [], score: "" });
        } else col.push({ list: ["empty", "empty"], score: "" });
      }
      playoffsBracket[numCols - 1 - i] = col;
    }
    return playoffsBracket;
  };
  await Promise.all(
    tracks.map(async (leagueTrack) => {
      const track = leagueTrack.track;
      const isModerateSingle = divisionData.includes(
        leagueTrack.division.division
      );
      const playoffsBracket = leagueTrack.division.doublesFormat
        ? createDoublesPlayoffBracket(track)
        : isModerated && !isModerateSingle
        ? createModeratePlayoffBracket(track)
        : createSinglesPlayoffBracket(track);
      await track.ref.update({
        playoffsBracket: playoffsBracket,
      });
    })
  );
};
export const generateActivity = async (activity) => {
  await firestore.collection("activities").add({ ...activity });
};

import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { auth, firestore } from "./firebase";
import Landing from "./pages/signin/Landing";
import SignUp from "./pages/signin/SignUp";
import AppRouter from "./pages/main/AppRouter";
import { ThemeProvider } from "@material-ui/core";
import { pickleballtheme } from "./PickleballTheme";
import { theme } from "./Theme";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
function App() {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [isPublicRoute, setIsPublicRoute] = useState(true);
  const [isPickleball, setIsPickleball] = useState(false);

  let urls =
    localStorage.getItem("initialURLS") !== null
      ? JSON.parse(localStorage.getItem("initialURLS"))
      : [];
  urls.push(window.location.href);
  localStorage.setItem("initialURLS", JSON.stringify(urls));
  useEffect(() => {
    const pathname = window.location.pathname.substring(1);
    const pathArray=pathname.split('/');
    const isUnauthenticatedUser = pathArray.length === 1 && pathArray[0] === 'uncertified-user';
    const isDirectorView = pathArray.length === 3 && pathArray[0] === 'League' && pathArray[1] === 'ViewLeague';
    const isLeagueView = pathArray.length === 7 && pathArray[0] === 'League' && pathArray[1] === 'MyLeaguesView' && pathArray[2] === 'Singles' && pathArray[pathArray.length - 1] === 'Information';
      auth.onAuthStateChanged((userAuth) => {
        if (userAuth) {
          let userRef = firestore.doc(`users/${userAuth.uid}`);
          const unsubscribe = userRef.onSnapshot((userDocument) => {
            const data = userDocument.data();
            const userData = {
              authEmail: userAuth.email,
              uid: userAuth.uid,
              ...data,
              name: `${data?.firstName} ${data?.lastName}`,
            };
            setUser(userData);
            localStorage.setItem("authUser", JSON.stringify(userData));
            if(!(isLeagueView || isDirectorView || isUnauthenticatedUser)) {
              setIsPublicRoute(false);
            } else{
              setIsPublicRoute(true);
            }
          });
          return () => unsubscribe();
        } else {
          localStorage.setItem("authUser", null);
          setUser(null);
          if(!(isLeagueView || isDirectorView || isUnauthenticatedUser)) {
            setIsPublicRoute(false);
          } else{
            setIsPublicRoute(true);
          }
        }
      });
    if(!(isLeagueView || isDirectorView || isUnauthenticatedUser)) {
      setIsPublicRoute(false);
    } else{
      setIsPublicRoute(true);
    }
  }, []);

  useEffect(()=>{
    document.body.className = (user ? user?.currentSport === "pickleball" : isPickleball) ? 'pick-a-ball' : '';
  },[isPickleball, user, isPublicRoute]);

  return (
    <Elements stripe={stripePromise}>
      <ThemeProvider
        theme={(user ? user?.currentSport === "pickleball" : isPickleball) ? pickleballtheme : theme}
      >
        <Router>
          <Switch>
            <Route path="/Landing">
              <Landing user={user} />
            </Route>

            <Route
              path="/SignUp"
              render={(props) => <SignUp {...props} user={user} />}
            />
            {((user && user.active) || isPublicRoute) ? (
              <Route path="/">
                <AppRouter user={user} setIsPickleball={setIsPickleball} isPublicRoute={isPublicRoute}/>
              </Route>
            ) : (
              <Redirect to="/Landing" />
            )}
          </Switch>
        </Router>
      </ThemeProvider>
    </Elements>
  );
}

export default App;

import React, { useState, useEffect, Fragment } from "react";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Loader from "react-loader-spinner";
import moment from "moment";
import { firestore } from "../../../firebase";
import {
  MenuTextField,
  DateTextField,
} from "../../../components/CustomTextField";

const surfaceList = [
  { label: "Clay", value: "Clay" },
  { label: "Grass", value: "Grass" },
  { label: "Hard", value: "Hard" },
  { label: "Carpet", value: "Carpet" },
];

const matchResults = [
  { label: "Full Game", value: "fullgame" },
  { label: "Retired", value: "retired" },
  { label: "No-Show", value: "noshow" },
  { label: "Match Cancelled", value: "cancelled" },
];

const EditScoreDoc = ({ score, participants, setOpen }) => {
  const doublesFormat = score.doublesFormat;
  const [matchDate, setMatchDate] = useState(score.matchDate);
  const [matchDateObj, setMatchDateObj] = useState(
    moment(score.matchDate, "MM/DD/YYYY")
  );
  const [surfaceType, setSurfaceType] = useState(score.surface);
  const [participantSelect, setParticipantSelect] = useState([
    { label: "Select Opponent", value: "placeholder" },
  ]);
  const [winnerID, setWinnerID] = useState(score.winner);
  const [loserID, setLoserID] = useState(score.loser);
  const [scoringFormat, setScoringFormat] = useState(score.scoringFormat);
  const [setOneScore1, setSetOneScore1] = useState(score.setOneScore[0]);
  const [setOneScore2, setSetOneScore2] = useState(score.setOneScore[1]);
  const [setTwoScore1, setSetTwoScore1] = useState(score.setTwoScore[0]);
  const [setTwoScore2, setSetTwoScore2] = useState(score.setTwoScore[1]);
  const [tieBreaker1, setTieBreaker1] = useState(score.setThreeScore[0]);
  const [tieBreaker2, setTieBreaker2] = useState(score.setThreeScore[1]);
  const [tenGameScore1, setTenGameScore1] = useState(score.proSetScore[0]);
  const [tenGameScore2, setTenGameScore2] = useState(score.proSetScore[1]);
  const [cancelledReason, setCancelledReason] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const winnerValuePlaceholder = "0 (Winner)";
  const loserValuePlaceholder = "0 (Loser)";
  const [matchResult, setMatchResult] = useState(() => {
    if (score.retired) return "retired";
    if (score.noShow) return "noshow";
    if (score.cancelled) return "cancelled";
    return "fullgame";
  });

  useEffect(() => {
    setParticipantSelect(
      participants.map((participant) => {
        return {
          label: doublesFormat
            ? participant.teamName
            : `${participant.firstName} ${participant.lastName}`,
          value: participant.id,
        };
      })
    );
    // eslint-disable-next-line
  }, [score, participants]);
  const handleClose = () => {
    setOpen(false);
  };
  const verifyForm = () => {
    if (winnerID === loserID) return false;
    return true;
  };
  const updateScores = async (e) => {
    e.preventDefault();
    if (!verifyForm()) return;
    setSubmitting(true);
    const reviewRefs = firestore
      .collection(`reviews`)
      .where("scoreID", "==", score.id);
    const reviewDocs = await reviewRefs.get();
    const review =
      reviewDocs.docs.length > 0 ? reviewDocs.docs[0].data() : null;
    await firestore
      .collection("scores")
      .add({
        matchID: score.matchID,
        leagueID: score.leagueID,
        divisionID: score.divisionID,
        trackID: score.trackID,
        matchDate: matchDate,
        date: moment(matchDate, "MM/DD/YYYY").toDate(),
        surface: surfaceType,
        scoringFormat: scoringFormat,
        setOneScore: [setOneScore1, setOneScore2],
        setTwoScore: [setTwoScore1, setTwoScore2],
        setThreeScore: [tieBreaker1, tieBreaker2],
        proSetScore: [tenGameScore1, tenGameScore2],
        winner: winnerID,
        loser: loserID,
        sportType: 'tennis',
        retired: matchResult === "retired",
        cancelled: matchResult === "cancelled",
        cancelledReason: cancelledReason,
        noShow: matchResult === "noshow",
        doublesFormat: score.doublesFormat,
        scoreType: score.scoreType,
      })
      .then(async (doc) => {
        if (review === null) return;
        await firestore.collection("reviews").add({
          matchID: score.matchID,
          scoreID: doc.id,
          leagueID: score.leagueID,
          divisionID: score.divisionID,
          trackID: score.trackID,
          reviewers: review.reviewers,
          reviewees: review.reviewees,
          publicReview: review.publicReview,
          privateReview: review.privateReview,
          matchDate: matchDate,
          surface: surfaceType,
          doublesFormat: score.doublesFormat,
        });
      });
    await score.ref.delete();
    setSubmitting(false);
    handleClose();
  };
  const handleDelete = async (e) => {
    e.preventDefault();
    await score.ref.delete();
    handleClose();
  };
  return (
    <form onSubmit={(e) => updateScores(e)}>
      <div className="scoreUploadFieldWrapper">
        <Typography className="scoreUploadFieldText margin10Top">
          Match Date
        </Typography>
        <DateTextField
          dateString={matchDate}
          setDateString={setMatchDate}
          date={matchDateObj}
          setDate={setMatchDateObj}
          required={true}
        />
        <Typography className="scoreUploadFieldText margin10Top">
          Surface Type
        </Typography>
        <MenuTextField
          state={surfaceType}
          setState={setSurfaceType}
          menuSelection={surfaceList}
          required={true}
        />
        <div className="rowCenter">
          <div className="column widthHalf margin10Right">
            <Typography className="scoreUploadFieldText margin30Top">
              Winner
            </Typography>
            <MenuTextField
              state={winnerID}
              setState={setWinnerID}
              menuSelection={participantSelect}
              required={true}
            />
          </div>
          <div className="column widthHalf margin10Left">
            <Typography className="scoreUploadFieldText margin30Top">
              Loser
            </Typography>
            <MenuTextField
              state={loserID}
              setState={setLoserID}
              menuSelection={participantSelect}
              required={true}
            />
          </div>
        </div>
        <Typography className="scoreUploadFieldText margin10Top">
          Match Result
        </Typography>
        <MenuTextField
          state={matchResult}
          setState={setMatchResult}
          menuSelection={matchResults}
          required={true}
        />
        <Typography className="scoreUploadFieldText margin30Top">
          Scoring Format
        </Typography>
        <RadioGroup
          className="rowCenter"
          required
          value={scoringFormat}
          onChange={(e) => {
            setScoringFormat(e.target.value);
          }}
        >
          <FormControlLabel
            className="scoreUploadRadioText"
            value="2/3Sets"
            control={<Radio color="default" />}
            label="Best of 3 Sets"
            labelPlacement="end"
          />
          <FormControlLabel
            value="2SetsWithTieBreaker"
            className="scoreUploadRadioText"
            control={<Radio color="default" />}
            label="3 Set Tiebreaker"
            labelPlacement="end"
          />
          <FormControlLabel
            value="10GamePro"
            className="scoreUploadRadioText"
            control={<Radio color="default" />}
            label="10 Game Pro Set"
            labelPlacement="end"
          />
        </RadioGroup>
        {scoringFormat === "2/3Sets" && (
          <Fragment>
            <Typography className="scoreUploadFieldText margin10Top">
              Set 1
            </Typography>
            <div className="rowCenter">
              <div className="column widthHalf margin10Right">
                <TextField
                  value={setOneScore1}
                  required
                  type="number"
                  onChange={(e) => {
                    setSetOneScore1(e.target.value);
                  }}
                  placeholder={winnerValuePlaceholder}
                />
              </div>
              <div className="column widthHalf margin10Left">
                <TextField
                  value={setOneScore2}
                  required
                  type="number"
                  onChange={(e) => {
                    setSetOneScore2(e.target.value);
                  }}
                  placeholder={loserValuePlaceholder}
                />
              </div>
            </div>
            <Typography className="scoreUploadFieldText margin10Top">
              Set 2
            </Typography>
            <div className="rowCenter">
              <div className="column widthHalf margin10Right">
                <TextField
                  value={setTwoScore1}
                  required
                  type="number"
                  onChange={(e) => {
                    setSetTwoScore1(e.target.value);
                  }}
                  placeholder={winnerValuePlaceholder}
                />
              </div>
              <div className="column widthHalf margin10Left">
                <TextField
                  value={setTwoScore2}
                  required
                  type="number"
                  onChange={(e) => {
                    setSetTwoScore2(e.target.value);
                  }}
                  placeholder={loserValuePlaceholder}
                />
              </div>
            </div>
            <Typography className="scoreUploadFieldText margin10Top">
              Set 3
            </Typography>
            <div className="rowCenter">
              <div className="column widthHalf margin10Right">
                <TextField
                  value={tieBreaker1}
                  required
                  type="number"
                  onChange={(e) => {
                    setTieBreaker1(e.target.value);
                  }}
                  placeholder={winnerValuePlaceholder}
                />
              </div>
              <div className="column widthHalf margin10Left">
                <TextField
                  value={tieBreaker2}
                  required
                  type="number"
                  onChange={(e) => {
                    setTieBreaker2(e.target.value);
                  }}
                  placeholder={loserValuePlaceholder}
                />
              </div>
            </div>
          </Fragment>
        )}
        {scoringFormat === "2SetsWithTieBreaker" && (
          <Fragment>
            <Typography className="scoreUploadFieldText margin10Top">
              Set 1
            </Typography>
            <div className="rowCenter">
              <div className="column widthHalf margin10Right">
                <TextField
                  value={setOneScore1}
                  required
                  type="number"
                  onChange={(e) => {
                    setSetOneScore1(e.target.value);
                  }}
                  placeholder={winnerValuePlaceholder}
                />
              </div>
              <div className="column widthHalf margin10Left">
                <TextField
                  value={setOneScore2}
                  required
                  type="number"
                  onChange={(e) => {
                    setSetOneScore2(e.target.value);
                  }}
                  placeholder={loserValuePlaceholder}
                />
              </div>
            </div>
            <Typography className="scoreUploadFieldText margin10Top">
              Set 2
            </Typography>
            <div className="rowCenter">
              <div className="column widthHalf margin10Right">
                <TextField
                  value={setTwoScore1}
                  required
                  type="number"
                  onChange={(e) => {
                    setSetTwoScore1(e.target.value);
                  }}
                  placeholder={winnerValuePlaceholder}
                />
              </div>
              <div className="column widthHalf margin10Left">
                <TextField
                  value={setTwoScore2}
                  required
                  type="number"
                  onChange={(e) => {
                    setSetTwoScore2(e.target.value);
                  }}
                  placeholder={loserValuePlaceholder}
                />
              </div>
            </div>
            <Typography className="scoreUploadFieldText margin10Top">
              Set 3
            </Typography>
            <div className="rowCenter">
              <div className="column widthHalf margin10Right">
                <TextField
                  value={tieBreaker1}
                  required
                  type="number"
                  onChange={(e) => {
                    setTieBreaker1(e.target.value);
                  }}
                  placeholder={winnerValuePlaceholder}
                />
              </div>
              <div className="column widthHalf margin10Left">
                <TextField
                  value={tieBreaker2}
                  required
                  type="number"
                  onChange={(e) => {
                    setTieBreaker2(e.target.value);
                  }}
                  placeholder={loserValuePlaceholder}
                />
              </div>
            </div>
          </Fragment>
        )}
        {scoringFormat === "10GamePro" && (
          <Fragment>
            <Typography className="scoreUploadFieldText margin10Top">
              Pro Set
            </Typography>
            <div className="rowCenter">
              <div className="column widthHalf margin10Right">
                <TextField
                  value={tenGameScore1}
                  required
                  type="number"
                  onChange={(e) => {
                    setTenGameScore1(e.target.value);
                  }}
                  placeholder={winnerValuePlaceholder}
                />
              </div>
              <div className="column widthHalf margin10Left">
                <TextField
                  value={tenGameScore2}
                  required
                  type="number"
                  onChange={(e) => {
                    setTenGameScore2(e.target.value);
                  }}
                  placeholder={loserValuePlaceholder}
                />
              </div>
            </div>
          </Fragment>
        )}
        {matchResult === "cancelled" && (
          <Fragment>
            <Typography className="scoreUploadFieldText margin10Top">
              Cancellation Reason
            </Typography>
            <TextField
              required
              multiline
              fullWidth={true}
              rows={3}
              value={cancelledReason}
              onChange={(e) => {
                setCancelledReason(e.target.value);
              }}
            />
          </Fragment>
        )}
        <div className="margin10Top" />
      </div>
      <div className="maxWidth">
        <Divider className="margin20Vertical" />
      </div>
      <div className="rowCenter">
        <Button variant="text" onClick={handleClose}>
          <Typography className="cancelButtonText">Back</Typography>
        </Button>
        <div className="filler" />
        <Button className="margin10Right" variant="text" onClick={handleDelete}>
          <Typography className="cancelButtonText err">Delete</Typography>
        </Button>
        <Button className="purchaseButton" variant="outlined" type="submit">
          {submitting ? (
            <Loader
              type="TailSpin"
              color="#02c39a"
              height={25}
              width={25}
              timeout={8000}
            />
          ) : (
            <Typography className="purchaseButtonText">Update</Typography>
          )}
        </Button>
      </div>
    </form>
  );
};

export default EditScoreDoc;

import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Loader from "react-loader-spinner";
import moment from "moment";
import Redirect from "react-router-dom/Redirect";
import { firestore } from "../../firebase";
import {
  getUserWinnerData,
  getOutCome,
  getSiteValues,
} from "../../functions/firebaseFunctions";
import TableComponent from "../../components/table/TableComponent";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Line } from "react-chartjs-2";
import Matches from "../matches/Matches";
import Leaderboard from "../leaderboard/Leaderboard";

const header = [
  { label: "name", content: "League Name", sortable: true },
  { label: "divisionLevel", content: "Division Level", sortable: true },
  { label: "matchRecord", content: "Match Record", sortable: true },
  { label: "setRecord", content: "Set Record", sortable: true },
  { label: "gameRecord", content: "Game Record", sortable: true },
  { label: "outeCome", content: "Outcome", sortable: true },
];

const Dashboard = ({
  user,
  leagues,
  divisions,
  sportLoading,
  tracks,
  mobileSize,
  userDoublesTeams,
}) => {
  const [loading, setLoading] = useState(true);
  const [graphLoading, setGraphLoading] = useState(false);
  const [rewardRedirect, setRewardRedirect] = useState(false);
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(false);
  const [dataGap, setDataGap] = useState("Weekly");
  const [lastEarned, setLastEarned] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);
  const [isEmptyChart, setIsEmptyChart] = useState(false);
  const [matchLoading, setMatchLoading] = useState(false);
  const [lineChartData, setLineChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Matches Win",
        fill: true,
        backgroundColor: "rgba(112,245,142,0.17)",
        borderColor: "#31a064",
        tension: 0.5,
      },
      {
        label: "Sets Win",
        fill: true,
        backgroundColor: "rgba(245,192,183,0.14)",
        borderColor: "#ef5262",
        tension: 0.5,
      },
      {
        label: "Games Win",
        fill: true,
        backgroundColor: "rgba(100,225,254,0.13)",
        borderColor: "#64e1fe",
        tension: 0.5,
      },
    ],
  });

  const getMonths = (type) => {
    const months = [];
    const monthsinNumber = [];
    const dateStart = moment();
    const dateEnd = moment().add(12, "month");
    while (dateEnd.diff(dateStart, "months") >= 0) {
      months.push(
        new Date(0, +dateStart.format("M") - 1).toLocaleString("en-US", {
          month: "short",
        })
      );
      monthsinNumber.push(dateStart.format("M"));
      dateStart.add(1, "month");
    }
    return type === "number" ? monthsinNumber : months;
  };
  const [showAll, setShowAll] = useState(false);

  const [leagueStats, setLeagueStats] = useState([]);
  const [showAllLeagueHistory, setShowAllLeagueHistory] = useState(false);



  useEffect(() => {
    (async function () {
      if(user) {
        //League history tableData
        setMatchLoading(true);
        const expireLeagues = user?.leaguesJoined?.filter(
            (o) => leagues[o?.leagueID]?.status === "expired"
        );
        let participantsData = expireLeagues?.map(async (l, i) => {
          let league = leagues[l.leagueID];
          let division = `${divisions?.[l.divisionID]?.division} ${
              divisions?.[l.divisionID]?.level
          } `;
          let matchRecord = `${l.matchWins}-${l.matchLosses}`;
          let setRecord = `${l.matchWins}-${l.matchLosses}`;
          let gameRecord = `${l.matchWins}-${l.matchLosses}`;
          let tableData = {
            cells: [
              {content: league?.name, editable: false},
              {content: division, editable: false},
              {content: matchRecord, editable: false},
              {content: setRecord, editable: false},
              {content: gameRecord, editable: false},
            ],
            name: league?.name,
            divisionLevel: division,
            matchRecord: matchRecord,
            setRecord: setRecord,
            gameRecord: gameRecord,
          };
          let outComeContent = await getOutCome(l, user.uid, user?.currentSport);
          tableData.outeCome = outComeContent;
          tableData.cells.push({content: outComeContent, editable: false});

          return tableData;
        });

        let leagueHistoryData = await Promise.all(participantsData);
        setLeagueStats(leagueHistoryData);
        setMatchLoading(false);

        //Retrieves User Activities
        const activitiesRef = firestore.collection("activities");
        const activitiesPlayerRef = activitiesRef.where(
            "players",
            "array-contains",
            user.uid
        );
        const unsubscribeActivities = activitiesPlayerRef.onSnapshot(
            async (activitiesSnapshot) => {
              let siteValues = await getSiteValues();
              let {activityValues} = siteValues;
              let activitiesListTemp = [];
              let pointTotal = 0;
              activitiesSnapshot.forEach((activityDoc) => {
                const activity = activityDoc?.data();
                const date = activity?.date?.toDate();
                const displayDate = moment(date).format("M/D/YYYY");
                const category = activity?.category;
                const players = activity?.players;
                if (
                    ![
                      "leagueComplete",
                      "eventApproved",
                      "directorMessage",
                      "eventComplete",
                      "leagueStart",
                      "playoffsStart",
                      "eventStart",
                    ].some((check) => check === activity.category)
                ) {
                  let points = activityValues[category];
                  let description = "";
                  if (category === "userJoined") {
                    description = "Joined GameSetMatch!";
                  } else if (
                      [
                        "profileComplete",
                        "profilePhoto",
                        "friendReferred",
                        "leagueApproved",
                        "eventApproved",
                        "eventComplete",
                        "userJoinedLeague",
                        "userJoinedEvent",
                      ].some((cat) => category === cat)
                  ) {
                    description = activity.description;
                  } else {
                    const playerIndex = players.findIndex(
                        (playerID) => playerID === user.uid
                    );
                    const isStandardMatch = category === "matchPlayed";
                    if (playerIndex < players.length / 2) {
                      description = isStandardMatch
                          ? "Played a match and won!"
                          : "Played a finals match and won!";
                      points =
                          activityValues[
                              isStandardMatch ? "matchPointWin" : "matchPointChampion"
                              ];
                    } else {
                      description = isStandardMatch
                          ? "Played a match and lost!"
                          : "Played a finals match and lost!";
                      points =
                          activityValues[
                              isStandardMatch ? "matchPointLoss" : "matchPointFinalist"
                              ];
                    }
                  }
                  pointTotal += points;
                  activitiesListTemp.push({
                    cells: [
                      {content: displayDate, editable: false},
                      {content: description, editable: false},
                      {content: points, editable: false},
                    ],
                    date,
                    points,
                  });
                }
              });
              activitiesListTemp.sort((a, b) => b.date - a.date);
              if (activitiesListTemp.length > 0) {
                setLastEarned(activitiesListTemp[0].points);
              }
              setTotalPoints(pointTotal - user.pointsUsed || 0);
              setLoading(false);
            }
        );

        return () => {
          unsubscribeActivities();
        };
      }
    })();
  }, [user, leagues, divisions, userDoublesTeams, tracks]);

  useEffect(() => {
    (async function () {
      if(user) {
        setGraphLoading(true);
        let matchData = await getUserWinnerData(
            user.uid,
            user?.currentSport || "tennis"
        );

        if (dataGap === "Monthly") {
          //if user select monthly data
          getMonthlyData(matchData);
        } else {
          //if user select weekly data
          getWeeklyData(matchData);
        }
        setGraphLoading(false);
      }
    })();
  }, [dataGap, user?.currentSport]);

  const getWeeklyData = (arr) => {
    const aYearAgoFromNow = new Date();
    aYearAgoFromNow.setFullYear(aYearAgoFromNow.getFullYear() - 1);
    let filterDataOfMatch = arr.filter(
      (item) => new Date(item?.matchDate) >= aYearAgoFromNow
    );
    let dataOfMatchWinn = [0, 0, 0, 0, 0, 0, 0];
    let dataOfSetsWinn = [0, 0, 0, 0, 0, 0, 0];
    let dataOfGamesWinn = [0, 0, 0, 0, 0, 0, 0];

    if (filterDataOfMatch?.length > 0) {
      setIsEmptyChart(false);
      for (let k = 0; k < filterDataOfMatch.length; k++) {
        const element = filterDataOfMatch?.[k];
        let weekIndex = new Date(element?.matchDate).getDay();
        let useParticipatsIndex = element?.participants?.findIndex(
          (o) => o === element.winner
        );
        if (useParticipatsIndex !== -1 && weekIndex) {
          let setKeys = [
            "setOneScore",
            "setTwoScore",
            "setThreeScore",
            "proSetScore",
          ];
          for (let i = 0; i < setKeys.length; i++) {
            let opponentIndex = useParticipatsIndex === 0 ? 1 : 0;
            if (
              element[setKeys[i]][useParticipatsIndex] >
              element[setKeys[i]][opponentIndex]
            ) {
              dataOfSetsWinn[weekIndex] = dataOfSetsWinn[weekIndex] + 1;
              dataOfGamesWinn[weekIndex] =
                dataOfGamesWinn[weekIndex] + Number(element[setKeys[i]][0]);
            }
          }
          dataOfMatchWinn[weekIndex] = dataOfMatchWinn[weekIndex] + 1;
        }
      }
      const newArr = { ...lineChartData };
      newArr.labels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      let updatedList = newArr.datasets.map((item, i) => {
        return {
          ...item,
          data:
            i === 0
              ? dataOfMatchWinn
              : i === 1
              ? dataOfSetsWinn
              : dataOfGamesWinn,
        };
      });
      setLineChartData({
        ...newArr,
        datasets: updatedList,
      });
      setLoading(false);
    } else {
      setIsEmptyChart(true);
    }
  };

  const getMonthlyData = (arr) => {
    const aYearAgoFromNow = new Date();
    aYearAgoFromNow.setFullYear(aYearAgoFromNow.getFullYear() - 1);
    const currentYear = new Date().getFullYear();
    let ArrOfMonthsNumber = getMonths("number");
    let dataOfMatchWin = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let dataOfSetsWin = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let dataOfGamesWin = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let filterDataOfMatch = arr.filter(
      (item) => new Date(item?.matchDate) >= aYearAgoFromNow
    );
    if (filterDataOfMatch?.length > 0) {
      setIsEmptyChart(false);
      for (let k = 0; k < filterDataOfMatch.length; k++) {
        const element = filterDataOfMatch?.[k];
        const date = moment(element?.matchDate, "MM/DD/YYYY");
        const month = date.format("M");
        const year = +date.format("yyyy");
        let matchIndex = ArrOfMonthsNumber.findIndex((l) => l === month);
        if (matchIndex !== -1) {
          matchIndex =
            month === ArrOfMonthsNumber[ArrOfMonthsNumber?.length - 1] &&
            year === currentYear
              ? ArrOfMonthsNumber?.length - 1
              : matchIndex;
          let useParticipatsIndex = element?.participants?.findIndex(
            (o) => o === element.winner
          );
          if (useParticipatsIndex !== -1 && matchIndex) {
            let setKeys = [
              "setOneScore",
              "setTwoScore",
              "setThreeScore",
              "proSetScore",
            ];
            for (let i = 0; i < setKeys.length; i++) {
              let opponentIndex = useParticipatsIndex === 0 ? 1 : 0;
              if (
                element[setKeys[i]][useParticipatsIndex] >
                element[setKeys[i]][opponentIndex]
              ) {
                dataOfSetsWin[matchIndex] = dataOfSetsWin[matchIndex] + 1;
                dataOfGamesWin[matchIndex] =
                  dataOfGamesWin[matchIndex] + Number(element[setKeys[i]][0]);
              }
            }
            dataOfMatchWin[matchIndex] = dataOfMatchWin[matchIndex] + 1;
          }
        }
      }
      const newArr = { ...lineChartData };
      newArr.labels = getMonths("short");
      let updatedList = newArr.datasets.map((item, i) => {
        return {
          ...item,
          data:
            i === 0 ? dataOfMatchWin : i === 1 ? dataOfSetsWin : dataOfGamesWin,
        };
      });
      setLineChartData({
        ...newArr,
        datasets: updatedList,
      });
    } else {
      setIsEmptyChart(true);
    }
  };

  const handleProfileMenuOpen = (event) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setProfileMenuAnchorEl(false);
  };

  const handleSelect = (value) => {
    setDataGap(value);
    handleProfileMenuClose();
  };

  if (rewardRedirect) {
    return (
      <Redirect
        to={{
          pathname: "/Rewards",
        }}
      />
    );
  }
  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );

  const retrievePercent = (win, loss) => {
    let res = Math.ceil((win / (loss + win)) * 100);
    return res ? res : 0;
  };

  return (
    <Grid className="maxHeight" container spacing={mobileSize ? 3 : 6}>
      <Grid className="column" item xs={12} sm={12} md={12} lg={8} xl={8}>
        <div className="dashboardHeaderSection">
          <Typography className="dashboardSectionHeader">
            OVERALL PERFORMANCE SUMMARY
          </Typography>
        </div>
        <div className="dashboardSectionWrapper">
          <div className="dashboardOverallSummaryMainSection">
            {user && <div className="OverallSummaryFirstSubSection">
              <div className="overAllDataSection">
                <div className="titleOverallSummarySubSection">MATCHES</div>
                <div className="totalScore">
                  {user.totalMatchWins +
                      user.totalMatchLosses +
                      user.totalDoublesMatchWins +
                      user.totalDoublesMatchLosses}
                </div>
                <div className="winLostTitle">win / lost - %</div>
                <div>{`${user.totalMatchWins + user.totalDoublesMatchWins} / ${
                    user.totalMatchLosses + user.totalDoublesMatchLosses
                } - ${retrievePercent(
                    user.totalMatchWins + user.totalDoublesMatchWins,
                    user.totalMatchLosses + user.totalDoublesMatchLosses
                )}%`}</div>
              </div>
              <div className="overAllDataSection">
                <div className="titleOverallSummarySubSection">SETS</div>
                <div className="totalScore">
                  {user.totalSetWins +
                      user.totalDoublesSetWins +
                      user.totalSetLosses +
                      user.totalDoublesSetLosses}
                </div>
                <div className="winLostTitle">win / lost - %</div>
                <div>{`${user.totalSetWins + user.totalDoublesSetWins} / ${
                    user.totalSetLosses + user.totalDoublesSetLosses
                } - ${retrievePercent(
                    user.totalSetWins + user.totalDoublesSetWins,
                    user.totalSetLosses + user.totalDoublesSetLosses
                )}%`}</div>
              </div>
              <div className="overAllDataSection">
                <div className="titleOverallSummarySubSection">GAMES</div>
                <div className="totalScore">
                  {user.totalGameWins +
                      user.totalDoublesGameWins +
                      user.totalGameLosses +
                      user.totalDoublesGameLosses}
                </div>
                <div className="winLostTitle">win / lost - %</div>
                <div>{`${user.totalGameWins + user.totalDoublesGameWins} / ${
                    user.totalGameLosses + user.totalDoublesGameLosses
                } - ${retrievePercent(
                    user.totalGameWins + user.totalDoublesGameWins,
                    user.totalGameLosses + user.totalDoublesGameLosses
                )}%`}</div>
              </div>
              <div className="overAllDataSection">
                <div className="titleOverallSummarySubSection">CALORIES</div>
                <div className="warningMassage">Not Available yet</div>
                <div className="winLostTitle">burned</div>
              </div>
            </div>}
            <div className="OverallSummarySecondSubSection">
              <Button
                className="DashordChartDropDown"
                variant="text"
                onClick={handleProfileMenuOpen}
              >
                <div className="flexCenter">
                  <Typography className="chartButtonText">{dataGap}</Typography>
                  <ArrowDropDownIcon />
                </div>
              </Button>
              <Menu
                className="appBarProfileMenu"
                keepMounted
                anchorEl={profileMenuAnchorEl}
                getContentAnchorEl={null}
                open={Boolean(profileMenuAnchorEl)}
                onClose={handleProfileMenuClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <MenuItem onClick={() => handleSelect("Monthly")}>
                  <div className="rowCenter">
                    <Typography className="chartMenu">Monthly</Typography>
                  </div>
                </MenuItem>
                <MenuItem onClick={() => handleSelect("Weekly")}>
                  <Typography className="chartMenu">Weekly</Typography>
                </MenuItem>
              </Menu>
            </div>
            <div className="OverallSummaryThirdSubSection">
              {graphLoading ? (
                <div className="pageWrapper">
                  <Loader
                    type="TailSpin"
                    color="#02c39a"
                    height={100}
                    width={100}
                  />
                </div>
              ) : !isEmptyChart ? (
                <Line height={130} data={{ ...lineChartData }} />
              ) : (
                <div className="dashboardEmptySectionWrapper">
                  <Typography className="listItemMatchScorePlayerName">
                    No Data!
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
        <div className="dashboardHeaderSection">
          <Typography className="dashboardSectionHeader">
            LEADERBOARD
          </Typography>
        </div>
        {user && <Leaderboard
            user={user}
            leagues={leagues}
            divisions={divisions}
            tracks={tracks}
            mobileSize={mobileSize}
            isFromDashBoard={true}
        />}
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
        <div className="dashboardHeaderSection">
          <Typography className="dashboardSectionHeader">
            RECENT MATCHES
          </Typography>
          <div onClick={() => setShowAll(!showAll)}>
            <Typography className="dashboardViewAll">
              {!showAll ? `View All` : `View Less`}
            </Typography>
          </div>
        </div>
        <Grid container spacing={4}>
          {user && <Grid item xs={12}>
            <Matches
                sportLoading={sportLoading}
                user={user}
                leagues={leagues}
                divisions={divisions}
                tracks={tracks}
                userDoublesTeams={userDoublesTeams}
                isFromDashBoard={true}
                showAll={showAll}
            />
          </Grid>}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
        <div className="dashboardHeaderSection">
          <Typography className="dashboardSectionHeader">
            MATCHPOINTS (Reward Points)
          </Typography>
        </div>
        <Grid
          className="myLeagueItemGridWrapper marginAuto"
          item
          xs={12}
          md={6}
          lg={6}
          xl={3}
        >
          <Card className="dashboardMatchPointsItem margin20Top borderTop5px">
            <div
              onClick={() => setRewardRedirect(true)}
              className="dashbordRewardDetails"
            >
              Details
            </div>
            <Avatar
              className="rewardImageDashbord"
              src={
                "https://www.kindpng.com/picc/m/15-154355_trophy-golden-gold-cup-concacaf-hd-image-free.png"
              }
              variant="rounded"
            />
            <div className="columnCenter dashbordRewardContentWrapper">
              <div className="columnCenter">
                <Typography className="dashbordRewardPoint" variant="h6">
                  {totalPoints}
                </Typography>
                <Typography className="dashbordRewardPointTitle" variant="h6">
                  CURRENT POINTS
                </Typography>
                <Typography
                  className="dashbordRewardLastEarnedTitle"
                  variant="h6"
                >
                  {` -- Last earned points ${lastEarned} --`}
                </Typography>
              </div>
              <div className="filler" />
              <Button
                onClick={() => setRewardRedirect(true)}
                className="dashBoardRedeemButton"
              >
                Redeem
              </Button>
            </div>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
        <div className="dashboardHeaderSection">
          <Typography className="dashboardSectionHeader">
            LEAGUE HISTORY
          </Typography>
          <div onClick={() => setShowAllLeagueHistory(!showAllLeagueHistory)}>
            <Typography className="dashboardViewAll">
              {!showAllLeagueHistory ? `View All` : `View Less`}
            </Typography>
          </div>
        </div>
        {matchLoading ? (
          <div className="pageWrapper">
            <Loader
              type="TailSpin"
              color="#02c39a"
              height={100}
              width={100}
              timeout={3000}
            />
          </div>
        ) : (
          <>
            {leagueStats.length > 0 ? (
              <TableComponent
                list={leagueStats}
                tableHeader={header}
                setList={setLeagueStats}
                type="viewOnly"
                isShowAll={showAllLeagueHistory}
                isFromDashBoard={true}
              />
            ) : (
              <div className="dashboardEmptySectionWrapper">
                <Typography className="listItemMatchScorePlayerName">
                  No League history
                </Typography>
              </div>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Dashboard;

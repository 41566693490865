import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import {
  MenuTextField,
  DateTextField,
} from "../../../components/CustomTextField";

import { TwoSlider } from "../../../components/CustomSliders";
const PageFour = ({
  format,
  isPickleBall,
  startDateDisplay,
  setStartDateDisplay,
  startDate,
  setStartDate,
  endDateDisplay,
  setEndDateDisplay,
  endDate,
  setEndDate,
  regEnd,
  setRegEnd,
  numPeople,
  setNumPeople,
  error,
  errorMessage,
}) => {
  const regEndSelect = [
    {
      label: "One week before the league starts",
      value: "One week before the league starts",
    },
    {
      label: "One day before the league starts",
      value: "One day before the league starts",
    },
    {
      label: "The day that the league starts",
      value: "The day that the league starts",
    },
    {
      label: "One day after the league starts",
      value: "One day after the league starts",
    },
    {
      label: "One week after the league starts",
      value: "One week after the league starts",
    },
    {
      label: "Registration open until playoff starts",
      value: "Registration open until playoff starts",
    },
  ];
  return (
    <Fragment>
      <Typography className="createLeagueFieldText">
        When will this league take place?
      </Typography>
      <div className="rowCenter">
        <div className="column widthHalf margin20Right">
          <Typography className="createLeagueFieldSubtext">
            Start Date
          </Typography>
          <DateTextField
            dateString={startDateDisplay}
            setDateString={setStartDateDisplay}
            date={startDate}
            setDate={setStartDate}
            required={true}
          />
          <Typography className="referralsInformation">
            *Minimum 1 week from today
          </Typography>
        </div>
        <div className="column widthHalf margin20Left">
          <Typography className="createLeagueFieldSubtext">End Date</Typography>
          <DateTextField
            dateString={endDateDisplay}
            setDateString={setEndDateDisplay}
            date={endDate}
            setDate={setEndDate}
            required={true}
          />
          <Typography className="referralsInformation">
            *End Date must be after start
          </Typography>
        </div>
      </div>
      <Typography className="createLeagueFieldText margin20Top">
        Registration
      </Typography>
      <div className="rowCenter">
        <div className="column widthHalf margin20Left">
          <Typography className="createLeagueFieldSubtext">
            Registration End Date
          </Typography>
          <MenuTextField
            state={regEnd}
            setState={setRegEnd}
            menuSelection={regEndSelect}
          />
        </div>
      </div>
      <Typography className="createLeagueFieldText margin20Top">
        How many players(or teams) can join your league?
      </Typography>
      <div className="maxWidth">
        <TwoSlider
          value={numPeople}
          setValue={setNumPeople}
          isPickleball={isPickleBall}
        />
      </div>
      <div className="heightPlaceholder">
        {error && (
          <Typography className="createLeagueErrorText">
            {errorMessage}
          </Typography>
        )}
      </div>
    </Fragment>
  );
};

export default PageFour;

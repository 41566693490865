import React, { useEffect, useState, Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Loader from "react-loader-spinner";
import {
  getUsersInTrack,
  getMatchSchedulesByTrack,
  getUserByID,
  getDoublesTeamsByID,
} from "../../../functions/firebaseFunctions";
import { noActionEmail } from "../../../functions/Messaging";
import { firestore, Firebase } from "../../../firebase";
import DefaultDialog from "../../../components/DefaultDialog";
import SideMenu from "../../../components/SideMenu";
import Content from "../../../components/Content";
import DirectorEventParticipants from "./DirectorEventParticipants";
import DirectorLeagueInfo from "./DirectorEventInfo";
import DirectorNotifications from "../DirectorNotifications";
import DirectorActivity from "../DirectorActivity";
import AdminLeagueApprovalForm from "../../admin/leagues/AdminLeagueApprovalForm";
import {createDoublesTracksTable, createSinglesTracksTable} from "../../../components/Common";

const routes = [
  "General Information",
  "Participants",
  "Notify Participants",
  "Post Activity",
];

const DirectorEventPage = ({ user, zoneList, mobileSize }) => {
  const [pageNum, setPageNum] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pageLabels, setPageLabels] = useState([]);
  const url = window.location.href.split("/");
  const leagueID = url.pop();
  const route = url[url.length - 2];
  const [league, setLeague] = useState(null);
  const [leagueDivisions, setLeagueDivisions] = useState({});
  const [leagueTracks, setLeagueTracks] = useState({});
  const [tracksList, setTracksList] = useState([]);
  const [director, setDirector] = useState(null);
  const [status, setStatus] = useState("");
  const [statusButtonText, setStatusButtonText] = useState("End Event");
  const [openStatusConfirm, setOpenStatusConfirm] = useState(false);
  const [openLeagueApprovalForm, setOpenLeagueApprovalForm] = useState(false);
  const [leagueStatusLoad, setLeagueStatusLoad] = useState(false);
  const [quantity, setQuantity] = useState([]);
  const [enableAdminApproveButton, setEnableAdminApproveButton] =
    useState(false);
  const [enableCancelButton, setEnableCancelButton] = useState(false);

  useEffect(() => {
    const leagueRef = firestore.collection("leagues").doc(leagueID);
    const unsubscribeLeague = leagueRef.onSnapshot((snapshot) => {
      setLeague({ ...snapshot.data(), id: snapshot.id, ref: snapshot.ref });
    });
    const divisionsRef = leagueRef.collection("divisions");
    const unsubscribeDivisions = divisionsRef.onSnapshot((snapshot) => {
      let divisionsTemp = {};
      snapshot.docs.forEach((doc) => {
        divisionsTemp[doc.id] = { ...doc.data(), id: doc.id, ref: doc.ref };
      });
      setLeagueDivisions(divisionsTemp);
    });
    const tracksRef = firestore
      .collectionGroup("tracks")
      .where("leagueID", "==", leagueID);
    const unsubscribeTracks = tracksRef.onSnapshot((snapshot) => {
      let tracksTemp = {};
      let quantityTemp = [];
      snapshot.docs.forEach(async (doc) => {
        tracksTemp[doc.id] = { ...doc.data(), id: doc.id, ref: doc.ref };
        doc.data().players.map(async (player) => {
          await firestore
            .collection("receipts")
            .where("userID", "==", player)
            .where("trackID", "==", doc.id)
            .get()
            .then((rec) => {
              if (rec.docs.length > 0) {
                const documentSnapshot = rec.docs[0].data();
                quantityTemp.push(documentSnapshot.quantity);
              } else quantityTemp.push(1);
            })
            .catch((error) => {
              console.log(error);
            });
        });
      });
      setQuantity(quantityTemp);
      setLeagueTracks(tracksTemp);
    });
    return () => {
      unsubscribeLeague();
      unsubscribeDivisions();
      unsubscribeTracks();
    };
  }, [leagueID]);

  useEffect(() => {
    setLoading(true);

    const handleSinglesTrack = async (league, division, track, scores) => {
      const trackID = track.id;
      let players = await getUsersInTrack(track);
      players = players
        .map((player) => {
          let record = player.leaguesJoined.find(
            (leagueJoined) => leagueJoined.trackID === trackID
          );
          return {
            ...player,
            withdrawn: record?.withdrawn,
            rank: record?.rank,
            winloss:
              record?.matchWins + record?.matchLosses > 0
                ? (
                    record?.matchWins /
                    (record?.matchWins + record?.matchLosses)
                  ).toFixed(4)
                : (0).toFixed(4),
          };
        })
        .sort((a, b) => a.rank - b.rank);
      let schedule = await getMatchSchedulesByTrack(track);
      return createSinglesTracksTable(
        league,
        division,
        track,
        players,
        scores,
        schedule
      );
    };
    const handleDoublesTrack = async (league, division, track, scores) => {
      const trackID = track.id;
      let teams = await getDoublesTeamsByID(track.doublesTeams);
      teams = teams
        .map((team) => {
          let record = team.players[0].leaguesJoined.find(
            (leagueJoined) => leagueJoined.trackID === trackID
          );
          return {
            ...team,
            rank: record.rank,
            withdrawn: record.withdrawn,
            winloss:
              record.matchWins + record.matchLosses > 0
                ? (
                    record.matchWins /
                    (record.matchWins + record.matchLosses)
                  ).toFixed(4)
                : (0).toFixed(4),
          };
        })
        .sort((a, b) => a.rank - b.rank);
      let schedule = await getMatchSchedulesByTrack(track);
      let playoffTeams = teams.filter((team) =>
        track.playoffsDoublesTeams.some((teamID) => teamID === team.id)
      );
      return createDoublesTracksTable(
        league,
        division,
        track,
        teams,
        playoffTeams,
        scores,
        schedule
      );
    };

    const getData = async (scores) => {
      setStatus(league.status);
      setEnableAdminApproveButton(
        league.status === "pending" && user.admin && route === "Admin"
      );
      setEnableCancelButton(
        league.status !== "expired" && user.admin && route === "Admin"
      );
      if (league.status === "pending") {
        setStatusButtonText("Event Pending");
      } else if (league.status === "approved") {
        setStatusButtonText("Start Event");
      } else if (league.status === "active") {
        setStatusButtonText("End Event");
      }
      let tracksList = [];
      let pageLabels = [];
      if (league.directorID === user.uid)
        setDirector({ ...user, id: user.uid });
      else setDirector(await getUserByID(league.directorID));
      await Promise.all(
        Object.keys(leagueTracks).map(async (trackID) => {
          let track = leagueTracks[trackID];
          let division = leagueDivisions[track.divisionID];
          const trackScores = scores.filter(
            (score) => score.trackID === trackID
          );
          tracksList.push(
            division.doublesFormat
              ? await handleDoublesTrack(league, division, track, trackScores)
              : await handleSinglesTrack(league, division, track, trackScores)
          );
          pageLabels.push(
            `${division.division} ${division.level}, Track ${track.track + 1}`
          );
        })
      );
      setTracksList(tracksList);
      setPageLabels(pageLabels);
      setLoading(false);
    };
    const scoresRef = firestore
      .collection("scores")
      .where("leagueID", "==", leagueID);
    const unsubscribeScores = scoresRef.onSnapshot((snapshot) => {
      const scores = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        ref: doc.ref,
      }));
      if (league) getData(scores);
    });

    return () => {
      unsubscribeScores();
    };
    // eslint-disable-next-line
  }, [user, league, leagueDivisions, leagueTracks, leagueID, route]);

  const handlePageSelection = (index) => {
    setPageNum(index);
  };
  const generateEventActivity = async (category, description) => {
    await firestore.collection("activities").add({
      date: Firebase.firestore.FieldValue.serverTimestamp(),
      category: category,
      leagueID: league.id,
      players: [user.uid],
      sportType: "both",
      likes: [],
      imageURLS: [],
      description: description,
    });
  };
  const handleStatusUpdate = async () => {
    setLeagueStatusLoad(true);
    if (league.status === "approved") {
      league.ref.update({ status: "active" });
    } else if (league.status === "active" && !league.eventStarted) {
      await generateEventActivity(
        "eventStart",
        `The event, ${league.name}, has now started.`
      );
      league.ref.update({ eventStarted: true });
    } else if (league.status === "active" && league.eventStarted) {
      await generateEventActivity(
        "eventComplete",
        `The event, ${league.name}, has now ended. Thank you for participating.`
      );
      league.ref.update({ status: "expired" });
      const contactList = getEmailList();
      await noActionEmail({
        message: `The event, ${league.name}, has now ended. Thank you for participating.`,
        subject: `Event, ${league.name}, ended today`,
        recipients: contactList,
      });
    }
    setLeagueStatusLoad(false);
    handleClose();
  };

  const getEmailList = () => {
    let contactList = [];
    tracksList.forEach((track) => {
      if (track.division.doublesFormat)
        track.teams.forEach((team) => {
          if (!team.withdrawn) {
            contactList.push({
              email: team.players[0].email,
              name: `${team.players[0].firstName} ${team.players[0].lastName}`,
            });
            contactList.push({
              email: team.players[1].email,
              name: `${team.players[1].firstName} ${team.players[1].lastName}`,
            });
          }
        });
      else
        track.players.forEach((player) => {
          if (!player.withdrawn)
            contactList.push({
              email: player.email,
              name: `${player.firstName} ${player.lastName}`,
            });
        });
    });
    return contactList;
  };

  const handleCancel = () => {
    league.ref.update({ status: "expired" });
  };
  const handleClose = () => {
    setOpenStatusConfirm(false);
  };
  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  return (
    <Grid container spacing={mobileSize ? 3 : 6}>
      <SideMenu>
        <div className="rowCenter">
          <Avatar
            variant="rounded"
            src={league.imageURL}
            className="image200Rounded"
          />
          <div className="column margin10Left">
            <Typography className="profileInfoCardTitle">
              {league.name}
            </Typography>
            <Typography className="profileInfoCardSubtitle">
              {league.status}
            </Typography>
            <DefaultDialog
              open={openStatusConfirm}
              setOpen={setOpenStatusConfirm}
            >
              <Typography className="createLeagueFieldText margin20Top">
                Confirm {statusButtonText}
              </Typography>
              <div className="rowCenter">
                <div className="filler" />
                <Button variant="text" onClick={handleClose}>
                  <Typography className="cancelButtonText">Back</Typography>
                </Button>
                <Button
                  className="purchaseButton"
                  variant="outlined"
                  onClick={handleStatusUpdate}
                >
                  <Typography className="purchaseButtonText">
                    Confirm
                  </Typography>
                </Button>
              </div>
            </DefaultDialog>
            {league.status !== "expired" && (
              <Button
                className="profileSaveButton margin10Top"
                variant="outlined"
                onClick={()=>setOpenStatusConfirm(true)}
                disabled={status === "pending"}
              >
                {leagueStatusLoad ? (
                  <Loader
                    type="TailSpin"
                    color="#02c39a"
                    height={25}
                    width={25}
                    timeout={8000}
                  />
                ) : (
                  <Typography className="profileSaveButtonText">
                    {statusButtonText}
                  </Typography>
                )}
              </Button>
            )}

            {enableAdminApproveButton && (
              <Fragment>
                <Button
                  className="profileSaveButton margin10Top"
                  variant="outlined"
                  onClick={()=>setOpenLeagueApprovalForm(true)}
                >
                  <Typography className="profileSaveButtonText">
                    Approve Event
                  </Typography>
                </Button>
                <AdminLeagueApprovalForm
                  open={openLeagueApprovalForm}
                  setOpen={setOpenLeagueApprovalForm}
                  league={league}
                  director={director}
                />
              </Fragment>
            )}
            {enableCancelButton && (
              <Fragment>
                <Button
                  className="CancelButton margin10Top"
                  variant="outlined"
                  onClick={handleCancel}
                >
                  <Typography className="profileUpdateButtonText">
                    Cancel Event
                  </Typography>
                </Button>
              </Fragment>
            )}
          </div>
        </div>
        <div className="columnCenter  margin30Top">
          {routes.map((route, index) => (
            <Button
              key={index}
              variant="text"
              onClick={() => handlePageSelection(index)}
              fullWidth
              className="profileNavButton"
            >
              <div className="rowCenter margin10Vertical">
                <Typography
                  className={
                    pageNum !== index
                      ? "profileNavButtonTextUnselected"
                      : "profileNavButtonTextSelected"
                  }
                >
                  {route}
                </Typography>
              </div>
            </Button>
          ))}
        </div>
      </SideMenu>
      <Content>
        {pageNum === 0 && (
          <DirectorLeagueInfo
            league={league}
            divisions={leagueDivisions}
            tracks={tracksList}
            status={status}
            zoneList={zoneList}
          />
        )}
        {pageNum === 1 && (
          <DirectorEventParticipants
            league={league}
            status={status}
            pageLabels={pageLabels}
            tracks={tracksList}
            divisions={leagueDivisions}
            quantity={quantity}
          />
        )}
        {pageNum === 2 && (
          <DirectorNotifications
            league={league}
            status={status}
            tracks={tracksList}
            pageLabels={pageLabels}
            getEmailList={getEmailList}
          />
        )}
        {pageNum === 3 && (
          <DirectorActivity
            leagueName={league.name}
            leagueID={league.id}
            status={status}
            tracks={tracksList}
            pageLabels={pageLabels}
            user={user}
          />
        )}
      </Content>
    </Grid>
  );
};

export default DirectorEventPage;

import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/Add";
import TableComponent from "../../../components/table/TableComponent";
import RewardEditForm from "./RewardEditForm";

const RewardList = ({ rewardsList, setRewardsList }) => {
  const [rewardSelected, setRewardSelected] = useState(null);
  const [isEditingReward, setIsEditingReward] = useState(false);
  const [openReward, setOpenReward] = useState(false);
  const rewardsListHeader = [
    "Name",
    "Points",
    "Available",
    "Claimed",
    "Start Date",
    "End Date",
  ].map((title) => {
    let labelTemp = title.toLowerCase();
    if (title === "Available") labelTemp = "units";
    else if (title === "Claimed") labelTemp = "unitsClaimed";
    else if (title === "Start Date") labelTemp = "startDate";
    else if (title === "End Date") labelTemp = "endDate";
    return { label: labelTemp, content: title, sortable: true };
  });
  const handleComponentEdit = (selectedReward) => {
    setRewardSelected(selectedReward);
    setIsEditingReward(true);
    setOpenReward(true);
  };
  const handleRewardCreation = () => {
    setIsEditingReward(false);
    setOpenReward(true);
  };
  return (
    <Grid item xs={12}>
      <RewardEditForm
        reward={rewardSelected}
        open={openReward}
        setOpen={setOpenReward}
        isEditing={isEditingReward}
      />
      <Tooltip title="Add Reward">
        <Button
          onClick={handleRewardCreation}
          className="margin20Right"
          variant="text"
        >
          <div className="flexCenter">
            <Add className="image30 appBarUploadScoreButtonIcon" />
            <Typography className="appBarUploadScoreButtonText">
              Add Reward
            </Typography>
          </div>
        </Button>
      </Tooltip>

      <TableComponent
        list={rewardsList}
        setList={setRewardsList}
        tableHeader={rewardsListHeader}
        handleComponentEdit={handleComponentEdit}
        type="componentEdit"
        rowsPerPageOverride={10}
      />
    </Grid>
  );
};
export default RewardList;

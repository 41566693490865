import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  DefaultTextField,
  RichTextField,
} from "../../../components/CustomTextField";
const PageOne = ({
  experience,
  setExperience,
  experienceYears,
  setExperienceYears,
  setQualities,
  QualitiesSaved,
  facebook,
  setFacebook,
  twitter,
  setTwitter,
  linkedIn,
  setLinkedIn,
}) => {
  return (
    <Fragment>
      <Typography className="createLeagueFieldText">
        Do you have previous experience in Managing Leagues or Events?
      </Typography>
      <FormControl className="rowCenter">
        <RadioGroup
          className="rowCenter"
          value={experience}
          onChange={(e) => {
            setExperience(e.target.value);
          }}
        >
          <FormControlLabel
            value="yes"
            control={<Radio color="default" />}
            label="Yes"
          />
          <div className="filler" />
          <FormControlLabel
            value="no"
            control={<Radio color="default" />}
            label="No"
          />
        </RadioGroup>
      </FormControl>
      { experience === "no" ? <div></div>:<div><Typography className="createLeagueFieldText">
        How many years?
      </Typography>
      <DefaultTextField
        required={true}
        type={"number"}
        state={experienceYears}
        setState={setExperienceYears}
      /></div>}
      <Typography className="createLeagueFieldText margin20Top">
        What would make you stand out as a League Director?
      </Typography>
      <RichTextField
        setValue={setQualities}
        defaultValue={QualitiesSaved}
        placeholder="Type in here and describe why you want to be a league director and what will make you stand out; or describe your vision on what your partnership with Game.Set.Match will be like that will bring value to the community."
      />
      <Typography className="createLeagueFieldText margin20Top">
        Facebook
      </Typography>
      <DefaultTextField
        required={false}
        state={facebook}
        setState={setFacebook}
        placeholder="Facebook"
      />
      <Typography className="createLeagueFieldText margin20Top">
        Twitter
      </Typography>
      <DefaultTextField
        required={false}
        state={twitter}
        setState={setTwitter}
        placeholder="Twitter"
      />
      <Typography className="createLeagueFieldText margin20Top">
        LinkedIn
      </Typography>
      <DefaultTextField
        required={false}
        state={linkedIn}
        setState={setLinkedIn}
        placeholder="LinkedIn"
      />
    </Fragment>
  );
};

export default PageOne;

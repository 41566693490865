import React, { useEffect, useState } from "react";
import { singleActionEmail } from "../../functions/Messaging";
import { firestore, functions } from "../../firebase";
import Loader from "react-loader-spinner";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Link,  Redirect } from "react-router-dom";

const VerifyUser = ({ user }) => {

  const [loading, setLoading] = useState(true);
  let urlpath = window.location
  let url = urlpath.search ? urlpath.href.split('?')[0] : urlpath.href
  let params = url.split("/");
  let userID = params[params.length - 1];
  let sent = false;
  useEffect(() => {
    const updateUser = async () => {
      const updateUserContact = functions.httpsCallable(
        "userCRUD-addActiveCampaignContact"
      );
      await updateUserContact({ user }).catch((error) => console.log(error));
      await firestore.doc(`users/${user.uid}`).update({ verified: true });
      setLoading(false);
    };
    if (userID !== "VerifyUser" && userID === user.uid) {
      updateUser();
      try{
        const updateUserData = functions.httpsCallable(
          "webhookProcessActionUpdateUserLeague"
        ); 
        updateUserData({userID:user.uid});
      } catch (error) {
        console.log("webhookProcessActionUpdateUserLeague", error);
      }
    } else setLoading(false);
  }, []);
  const verifyEmail = async () => {
    sent = true;
    await singleActionEmail({
      message:
        "Thank you for joining Game.Set.Match – your online partner to find the best tennis leagues and events that fit your time, level, and preferences.",
      message2:
        "Our goal is to get you to expand your love for the sport of tennis by bringing the leagues, tourneys, and other events closer to you so you can go out and play!",
      message3:
        "Before you can go out and play on the different leagues and events we have for you, please verify your email address for us.",
      message4:
        "Why, you ask? Connecting you seamlessly with other players is a big part of the Game.Set.Match experience. Using your email address is crucial to maintaining our goal to get you connected.",
      subject: `Welcome to Game.Set.Match – Balls on your court!`,
      url: `${url}/${user.uid}${urlpath.search && urlpath.search}`,
      buttonText: "Verify Email",
      recipients: [{ email: user.email, name: user.firstName }],
    });
  };
  return (
    <>
      {loading ? (
        <div className="pageWrapper">
          <Loader
            type="TailSpin"
            color="#02c39a"
            height={100}
            width={100}
            timeout={3000}
          />
        </div>
      ) : (
        <>
          <Grid className="gridCenter maxHeight" item xs={12} md={12} lg={12}>
          {user.verified && urlpath.search ? (
              <Redirect to="/DirectorApplication"/>
            ) : (
              user.verified && (
                <div className="columnCenter widthHalf margin30LessTop">
                  <Typography className="highlightHeader margin20Bottom">
                    Thank you
                  </Typography>
                  <Typography className="helperTextLarge">
                    You have verified your email.
                  </Typography>
                  <div className="margin30" />
                  <div className="columnCenter">
                    <Link to="/Dashboard" className="noUnderline">
                      <Button className="createLeagueNextButton">
                        Go to Dashboard
                      </Button>
                    </Link>
                  </div>
                </div>
              )
            )}
            {!user.verified && (
              <div className="widthHalf margin30LessTop">
                <Typography className="highlightHeader margin20Bottom">
                  An activation link has been sent to your email address.
                </Typography>
                <Typography className="helperTextLarge">
                  Click on the link in the email to verify your account. If you
                  do not receive your email in a couple of minutes, click on the
                  resend button below, check your <b>SPAM</b> folder or email{" "}
                  <b>admin@playgamesetmatch.com</b>. Thanks.
                </Typography>
                <div className="margin30" />
                <div className="columnCenter">
                  <Button
                    onClick={verifyEmail}
                    className="createLeagueNextButton"
                  >
                    {sent ? "Sent to " + user.email : "Resend"}
                  </Button>
                </div>
              </div>
            )}
          </Grid>
        </>
      )}
    </>
  );
};
export default VerifyUser;

export const surfaces = [
  { label: "No Preference", value: "No Preference" },
  ...[
    { label: "Clay", value: "Clay" },
    { label: "Grass", value: "Grass" },
    { label: "Hard", value: "Hard" },
    { label: "Carpet", value: "Carpet" },
  ].sort((a, b) => a.label - b.label),
];

export const shots = [
  { label: "Backhand", value: "Backhand" },
  { label: "Forehand", value: "Forehand" },
  { label: "Serve", value: "Serve" },
  { label: "Volley", value: "Volley" },
].sort((a, b) => a.label - b.label);

export const brands = [
  { label: "Babolat", value: "Babolat" },
  { label: "Wilson", value: "Wilson" },
  { label: "Head", value: "Head" },
  { label: "Prince", value: "Prince" },
  { label: "Dunlop", value: "Dunlop" },
  { label: "Yonex", value: "Yonex" },
  { label: "Tecnifibre", value: "Tecnifibre" },
  { label: "Volkl", value: "Volkl" },
  { label: "Asics", value: "Asics" },
].sort((a, b) => a.label - b.label);

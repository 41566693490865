import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Firebase, firestore } from "../../../firebase";
import DefaultDialog from "../../../components/DefaultDialog";
import { MenuTextField } from "../../../components/CustomTextField";
import { CustomCheckbox } from "../../../components/DropdownCheckbox";
import { noActionEmail } from "../../../functions/Messaging";
const DirectorManageTrackPlayers = ({
  isPickleball,
  openEdit,
  setOpenEdit,
  selectedPlayer,
  setSelectedPlayer,
  selectedTrack,
  setSelectedTrack,
  openAddTrack,
  setOpenAddTrack,
  addTrackPlayerList,
  setAddTrackPlayerList,
  addTrackTeamList,
  setAddTrackTeamList,
  selectedTeam,
  setSelectedTeam,
  selectedDivision,
  setSelectedDivision,
  divisionSelection,
  trackSelection,
  setTrackSelection,
  tracks,
  league,
  pageNum,
  divisions,
  canEditTracks,
}) => {
  const withdrawn =
    !selectedPlayer && !selectedTeam
      ? false
      : selectedPlayer
      ? selectedPlayer.withdrawn
      : selectedTeam.team.withdrawn;
  const handleCreateSinglesTrack = () => {
    let playersMoved = [];
    addTrackPlayerList.forEach((player) => {
      if (player.check)
        playersMoved.push({
          player: player.player,
          trackID: player.trackID,
        });
    });
    let trackCount = 0;
    tracks.forEach((track) => {
      if (track.division.id === selectedDivision) trackCount++;
    });
    firestore
      .collection("leagues")
      .doc(league.id)
      .collection("divisions")
      .doc(selectedDivision)
      .collection("tracks")
      .add({
        leagueID: league.id,
        divisionID: selectedDivision,
        track: trackCount,
        players: [],
        playoffsPlayers: [],
        withdrawn: [],
      })
      .then(async (docRef) => {
        await Promise.all(
          playersMoved.map(async (player) => {
            let trackFrom = tracks.find(
              (track) => track.track.id === player.trackID
            ).track;
            await removePlayerFromTrack(player.player, trackFrom);
          })
        );
        await Promise.all(
          playersMoved.map(async (player) => {
            let trackFrom = tracks.find(
              (track) => track.track.id === player.trackID
            ).track;
            await updatePlayerTrack(player.player, trackFrom, {
              ref: docRef,
              id: docRef.id,
            });
          })
        );
        await Promise.all(
          playersMoved.map(async (player) => {
            await addPlayerToTrack(player.player, {
              ref: docRef,
              id: docRef.id,
            });
          })
        );
        docRef.update({
          leagueID: league.id,
          divisionID: selectedDivision,
          track: trackCount,
          players: playersMoved.map((player) => player.player.id),
          playoffsPlayers: [],
        });
      });
    setOpenAddTrack(false);
  };
  const handleCreateDoublesTrack = () => {
    let teamsMoved = [];
    addTrackTeamList.forEach((team) => {
      if (team.check)
        teamsMoved.push({
          team: team.team,
          trackID: team.trackID,
        });
    });
    let trackCount = 0;
    tracks.forEach((track) => {
      if (track.division.id === selectedDivision) trackCount++;
    });
    firestore
      .collection("leagues")
      .doc(league.id)
      .collection("divisions")
      .doc(selectedDivision)
      .collection("tracks")
      .add({
        leagueID: league.id,
        divisionID: selectedDivision,
        track: trackCount,
        doublesTeams: [],
        playoffsDoublesTeams: [],
        withdrawn: [],
      })
      .then(async (docRef) => {
        await Promise.all(
          teamsMoved.map(async (team) => {
            let trackFrom = tracks.find(
              (track) => track.track.id === team.trackID
            ).track;
            await removeTeamFromTrack(team.team, trackFrom);
          })
        );
        await Promise.all(
          teamsMoved.map(async (team) => {
            let trackFrom = tracks.find(
              (track) => track.track.id === team.trackID
            ).track;
            await updateTeamTrack(team.team, trackFrom, {
              ref: docRef,
              id: docRef.id,
            });
          })
        );
        await Promise.all(
          teamsMoved.map(async (team) => {
            await addTeamToTrack(team.team, {
              ref: docRef,
              id: docRef.id,
            });
          })
        );
        docRef.update({
          leagueID: league.id,
          divisionID: selectedDivision,
          track: trackCount,
          doublesTeams: teamsMoved.map((team) => team.team.id),
          playoffsDoublesTeams: [],
        });
      });
    setOpenAddTrack(false);
  };
  const handleDivisionSelect = (e) => {
    const divisionID = e.target.value;
    setSelectedDivision(divisionID);
    let newTrackSelection = tracks
      .filter((track) => track.division.id === divisionID)
      .map((track) => {
        const trackLabel = divisions[divisionID].doublesFormat
          ? `Track ${track.track.track + 1}: (${
              track.track.doublesTeams.length
            } Current Teams)`
          : `Track ${track.track.track + 1}: (${
              track.track.players.length
            } Current Players)`;
        return {
          value: track.track.id,
          label: trackLabel,
        };
      });
    setTrackSelection(newTrackSelection);
    setSelectedTrack(newTrackSelection[0].value);
  };
  const handlePlayerRemoval = () => {
    let track = tracks.find((track) => track.track.id === selectedTrack);
    removePlayerFromTrack(selectedPlayer, tracks[pageNum].track);
    updatePlayerTrack(selectedPlayer, tracks[pageNum].track, track.track);
    addPlayerToTrack(selectedPlayer, track.track);
    setOpenEdit(false);
    setSelectedPlayer(undefined);
  };
  const removePlayerFromTrack = async (player, track) => {
    if (track.withdrawn.some((playerID) => playerID === player.id))
      await track.ref.update({
        players: Firebase.firestore.FieldValue.arrayRemove(player.id),
        withdrawn: Firebase.firestore.FieldValue.arrayRemove(player.id),
      });
    else
      await track.ref.update({
        players: Firebase.firestore.FieldValue.arrayRemove(player.id),
      });
  };
  const addPlayerToTrack = async (player, track) => {
    const playerLeaguejoin = player?.leaguesJoined?.some(
      (leagueJoined) =>
        leagueJoined.trackID === track.id && leagueJoined.withdrawn
    );
    const playerPbLeaguejoin = player?.pb_leaguesJoined?.some(
      (leagueJoined) =>
        leagueJoined?.trackID === track?.id && leagueJoined?.withdrawn
    );
    const isPlayerInTrack = isPickleball
      ? playerPbLeaguejoin
      : playerLeaguejoin;
    if (isPlayerInTrack)
      await track.ref.update({
        players: Firebase.firestore.FieldValue.arrayUnion(player.id),
        withdrawn: Firebase.firestore.FieldValue.arrayUnion(player.id),
      });
    else
      await track.ref.update({
        players: Firebase.firestore.FieldValue.arrayUnion(player.id),
      });
  };
  const updatePlayerTrack = async (player, trackFrom, trackTo) => {
    if (isPickleball) {
      const pbLeagueHistoryIndex = player?.pb_leaguesJoined?.findIndex(
        (lJ) => lJ?.trackID === trackFrom?.id
      );
      let pbLeaguesJoined = player?.pb_leaguesJoined;
      pbLeaguesJoined[pbLeagueHistoryIndex].divisionID = selectedDivision;
      pbLeaguesJoined[pbLeagueHistoryIndex].trackID = trackTo.id;
      await player.ref.update({
        pb_leaguesJoined: pbLeaguesJoined,
      });
    } else {
      const leagueHistoryIndex = player.leaguesJoined.findIndex(
        (lJ) => lJ.trackID === trackFrom.id
      );
      let leaguesJoined = player.leaguesJoined;
      leaguesJoined[leagueHistoryIndex].divisionID = selectedDivision;
      leaguesJoined[leagueHistoryIndex].trackID = trackTo.id;
      await player.ref.update({
        leaguesJoined: leaguesJoined,
      });
    }
  };
  const handleTeamRemoval = () => {
    let track = tracks.find((track) => track.track.id === selectedTrack);
    removeTeamFromTrack(selectedTeam.team, tracks[pageNum].track);
    updateTeamTrack(selectedTeam.team, tracks[pageNum].track, track.track);
    addTeamToTrack(selectedTeam.team, track.track);
    setOpenEdit(false);
    setSelectedTeam(undefined);
  };
  const removeTeamFromTrack = async (team, track) => {
    if (track.withdrawn.some((teamID) => teamID === team.id))
      await track.ref.update({
        doublesTeams: Firebase.firestore.FieldValue.arrayRemove(team.id),
        withdrawn: Firebase.firestore.FieldValue.arrayRemove(team.id),
      });
    else
      await track.ref.update({
        doublesTeams: Firebase.firestore.FieldValue.arrayRemove(team.id),
      });
  };
  const addTeamToTrack = async (team, track) => {
    const teamLeaguejoin = team.players[0].leaguesJoined.some(
      (leagueJoined) =>
        leagueJoined.trackID === track.id && leagueJoined.withdrawn
    );
    const teamPbLeaguejoin = team?.players?.[0]?.pb_leaguesJoined?.some(
      (leagueJoined) =>
        leagueJoined?.trackID === track?.id && leagueJoined?.withdrawn
    );
    const isTeamInTrack = isPickleball ? teamPbLeaguejoin : teamLeaguejoin;

    if (isTeamInTrack)
      await track.ref.update({
        doublesTeams: Firebase.firestore.FieldValue.arrayUnion(team.id),
        withdrawn: Firebase.firestore.FieldValue.arrayUnion(team.id),
      });
    else
      await track.ref.update({
        doublesTeams: Firebase.firestore.FieldValue.arrayUnion(team.id),
      });
  };
  const updateTeamTrack = async (team, trackFrom, trackTo) => {
    if (isPickleball) {
      await Promise.all(
        team?.players.map(async (player) => {
          const pbLeagueHistoryIndex = player?.pb_leaguesJoined?.findIndex(
            (lJ) => lJ?.trackID === trackFrom?.id
          );
          let pbLeaguesJoined = player?.pb_leaguesJoined;
          pbLeaguesJoined[pbLeagueHistoryIndex].divisionID = selectedDivision;
          pbLeaguesJoined[pbLeagueHistoryIndex].trackID = trackTo.id;
          await player.ref.update({
            pb_leaguesJoined: pbLeaguesJoined,
          });
        })
      );
    } else {
      await Promise.all(
        team.players.map(async (player) => {
          const leagueHistoryIndex = player.leaguesJoined.findIndex(
            (lJ) => lJ.trackID === trackFrom.id
          );
          let leaguesJoined = player.leaguesJoined;
          leaguesJoined[leagueHistoryIndex].divisionID = selectedDivision;
          leaguesJoined[leagueHistoryIndex].trackID = trackTo.id;
          await player.ref.update({
            leaguesJoined: leaguesJoined,
          });
        })
      );
    }
  };
  const handlePlayerWithdrawl = async () => {
    if (isPickleball) {
      const pbLeagueHistoryIndex = selectedPlayer?.pb_leaguesJoined?.findIndex(
        (lJ) => lJ.trackID === selectedTrack
      );

      let pbLeaguesJoined = selectedPlayer?.pb_leaguesJoined;
      pbLeaguesJoined[pbLeagueHistoryIndex].withdrawn = true;
      await selectedPlayer.ref.update({
        pb_leaguesJoined: pbLeaguesJoined,
      });
    } else {
      const leagueHistoryIndex = selectedPlayer.leaguesJoined.findIndex(
        (lJ) => lJ.trackID === selectedTrack
      );

      let leaguesJoined = selectedPlayer.leaguesJoined;
      leaguesJoined[leagueHistoryIndex].withdrawn = true;
      await selectedPlayer.ref.update({
        leaguesJoined: leaguesJoined,
      });
    }

    let track = tracks.find((track) => track.track.id === selectedTrack).track;
    await track.ref.update({
      withdrawn: Firebase.firestore.FieldValue.arrayUnion(selectedPlayer.id),
    });
    await noActionEmail({
      subject: `Withdrawn Status`,
      message:
        "This is to confirm that your league status is now set to “withdrawn” either due to your request or a decision made by the Director of the League or Admin of the platform. If you have any questions or feel that this is not accurate, please reach out to your league director for any questions. If you qualify for a refund, that process is now initiated and will be completed soon.",
      recipients: [
        { email: selectedPlayer.email, name: selectedPlayer.firstName },
      ],
    });
  };
  const handleTeamWithdrawl = async () => {
    await Promise.all(
      selectedTeam.team.players.map(async (player) => {
        if (isPickleball) {
          const pbLeagueHistoryIndex = player?.pb_leaguesJoined?.findIndex(
            (lJ) => lJ.trackID === selectedTrack
          );
          let pbLeaguesJoined = player.pb_leaguesJoined;
          pbLeaguesJoined[pbLeagueHistoryIndex].withdrawn = true;
          await player.ref.update({
            pb_leaguesJoined: pbLeaguesJoined,
          });
        } else {
          const leagueHistoryIndex = player.leaguesJoined.findIndex(
            (lJ) => lJ.trackID === selectedTrack
          );
          let leaguesJoined = player.leaguesJoined;
          leaguesJoined[leagueHistoryIndex].withdrawn = true;
          await player.ref.update({
            leaguesJoined: leaguesJoined,
          });
        }
        await noActionEmail({
          subject: `Withdrawn Status`,
          message:
            "This is to confirm that your league status is now set to “withdrawn” either due to your request or a decision made by the Director of the League or Admin of the platform. If you have any questions or feel that this is not accurate, please reach out to your league director for any questions. If you qualify for a refund, that process is now initiated and will be completed soon.",
          recipients: [{ email: player.email, name: player.firstName }],
        });
      })
    );
    let track = tracks.find((track) => track.track.id === selectedTrack).track;
    await track.ref.update({
      withdrawn: Firebase.firestore.FieldValue.arrayUnion(selectedTeam.team.id),
    });
  };
  return (
    <>
      {(selectedPlayer !== undefined || selectedTeam !== undefined) && (
        <DefaultDialog open={openEdit} setOpen={setOpenEdit}>
          <div className="column">
            {selectedPlayer !== undefined ? (
              <div className="rowCenter">
                <Avatar
                  variant="rounded"
                  src={selectedPlayer.imageURL}
                  className="image200Rounded"
                />
                <div className="column margin10Left">
                  <Typography className="profileInfoCardTitle">
                    {`${selectedPlayer.firstName} ${selectedPlayer.lastName}`}
                  </Typography>
                  <Typography className="profileInfoCardSubtitle">
                    {`Rating: ${Math.round(selectedPlayer.gsmRating * 100)}`}
                  </Typography>
                  {selectedPlayer.withdrawn && (
                    <Typography className="profileInfoCardTitle">
                      Withdrawn
                    </Typography>
                  )}
                </div>
              </div>
            ) : (
              <div className="rowCenter">
                <div className="rowCenter">
                  <Avatar
                    variant="rounded"
                    className="image200Rounded"
                    src={selectedTeam.team.players[0].imageURL}
                  />
                  <Avatar
                    variant="rounded"
                    className="image200Rounded"
                    src={selectedTeam.team.players[1].imageURL}
                  />
                </div>
                <div className="column margin10Left">
                  <Typography className="profileInfoCardTitle">
                    {selectedTeam.teamName}
                  </Typography>
                  <Typography className="profileInfoCardSubtitle">
                    {`Rating: ${Math.round(selectedTeam.teamGsmRating)}`}
                  </Typography>
                  {selectedTeam.withdrawn && (
                    <Typography className="profileInfoCardTitle">
                      Withdrawn
                    </Typography>
                  )}
                </div>
              </div>
            )}
            <Typography className="createLeagueFieldText margin20Top">
              Select Division
            </Typography>
            <MenuTextField
              state={selectedDivision}
              setState={setSelectedDivision}
              menuSelection={divisionSelection}
              customFunction={handleDivisionSelect}
              disabled={!canEditTracks}
            />
            <Typography className="createLeagueFieldText margin20Top">
              Select Track
            </Typography>
            <MenuTextField
              state={selectedTrack}
              setState={setSelectedTrack}
              menuSelection={trackSelection}
              disabled={!canEditTracks}
            />
            <Button
              className="profileSaveButton margin20Top"
              variant="outlined"
              disabled={!canEditTracks}
              onClick={
                selectedPlayer !== undefined
                  ? handlePlayerRemoval
                  : handleTeamRemoval
              }
            >
              <Typography className="profileSaveButtonText">
                {selectedPlayer !== undefined ? "Move Player" : "Move Team"}
              </Typography>
            </Button>
            <Button
              className="CancelButton margin20Top"
              variant="outlined"
              disabled={
                withdrawn ||
                (league.status === "playoffs" && !league.playoffsGenerated)
              }
              onClick={
                selectedPlayer !== undefined
                  ? handlePlayerWithdrawl
                  : handleTeamWithdrawl
              }
            >
              <Typography className="profileUpdateButtonText">
                {selectedPlayer !== undefined
                  ? "Withdraw Player"
                  : "Withdraw Team"}
              </Typography>
            </Button>
          </div>
        </DefaultDialog>
      )}
      {selectedDivision !== undefined && (
        <DefaultDialog open={openAddTrack} setOpen={setOpenAddTrack}>
          <div className="column">
            <div className="rowCenter"></div>
            <Typography className="createLeagueFieldText margin20Top">
              Select Division
            </Typography>
            <MenuTextField
              state={selectedDivision}
              setState={setSelectedDivision}
              menuSelection={divisionSelection}
              customFunction={handleDivisionSelect}
            />
            <Typography className="createLeagueFieldText margin20Top">
              Move Players to Track
            </Typography>
            {divisions[selectedDivision].doublesFormat ? (
              <CustomCheckbox
                checkboxes={addTrackTeamList}
                setCheckboxes={setAddTrackTeamList}
              />
            ) : (
              <CustomCheckbox
                checkboxes={addTrackPlayerList}
                setCheckboxes={setAddTrackPlayerList}
              />
            )}
            <Button
              className="profileSaveButton margin20Top"
              variant="outlined"
              disabled={withdrawn || !canEditTracks}
              onClick={
                divisions[selectedDivision].doublesFormat
                  ? handleCreateDoublesTrack
                  : handleCreateSinglesTrack
              }
            >
              <Typography className="profileSaveButtonText">
                Create Track & Move Players
              </Typography>
            </Button>
          </div>
        </DefaultDialog>
      )}
    </>
  );
};

export default DirectorManageTrackPlayers;

import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Loader from "react-loader-spinner";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import { firestore } from "../../firebase";
import TableComponent from "../../components/table/TableComponent";
import { useHistory } from "react-router-dom";

const header = [
  { label: "type", content: "Type", sortable: true },
  { label: "status", content: "Status", sortable: true },
  { label: "date", content: "Date", sortable: true },
];

const DirectorDraft = ({ user, zoneList }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  const meFilter = (person) => {
    if (user.uid === "") return true;
    return person.information.directorID.indexOf(user.uid) === 0;
  };
  useEffect(() => {
    const usersRef = firestore.collection("applications");
    const unsubscribeUsers = usersRef.onSnapshot( (usersSnapshot) => {
      setUsers(
        usersSnapshot.docs.map( (userDoc) => {
          const appData = userDoc.data();
          const ref = userDoc.ref;
          let type = appData.type;
          const status = appData.status;
          const date =  moment(appData.dateCreated.toDate()).format(
            "MMM D, YYYY"
          );
          return {
            cells: [
              { content: type, editable: false },
              { content: status, editable: false },
              { content: date, editable: false },
            ],
            type,
            status,
            date,
            ref,
            information: { ...appData, ref: ref, id: userDoc.id },
          };
        })
      );
      setLoading(false);
    });
    return () => {
      unsubscribeUsers();
    };
  }, []);
  const handleComponentEdit = (rowSelected) => {
    if(rowSelected.status === "draft")
      history.push("/DirectorApplication/"+rowSelected.ref.id);
  };

  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );

  return (
    <div className="column filler">
      <Typography className="margin10Bottom" variant="h4">
        Director Applications
      </Typography>
      <div className="pageWrapper">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <TableComponent
              list={users.filter((person) => meFilter(person))}
              setList={setUsers}
              tableHeader={header}
              type="componentEdit"
              defaultSort="date"
              handleComponentEdit={handleComponentEdit}
              rowsPerPageOverride={10}
            />
          </Grid>
        </Grid>
        <div className="filler" />
      </div>
    </div>
  );
};

export default DirectorDraft;

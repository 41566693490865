import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
export const CustomTabs = ({ labels, value, setValue }) => {
  return (
    <div className="tabsWrapper">
      <Tabs
        className="customTabs"
        value={value}
        onChange={(e, page) => {
          setValue(page);
        }}
        variant="scrollable"
        scrollButtons="auto"
      >
        {labels.map((label, index) => (
          <Tab key={index} label={label} />
        ))}
      </Tabs>
    </div>
  );
};

import React, { useState, useEffect, useRef } from "react";
import clonedeep from "lodash.clonedeep";
import Grid from "@material-ui/core/Grid";
import Loader from "react-loader-spinner";
import { CustomTabs } from "../../../components/CustomTabs";
import PlayoffsBracket from "./PlayoffsBracket";
import {divisionData} from "../../../components/Common";

const DirectorLeaguePlayoffs = ({
  league,
  pageLabels,
  tracks,
  setPlayoffsGenerated,
  setAllPlayoffsEnded,
  setPlayoffChangeDetected,
  brackets,
  setBrackets,
  isPublic =false
}) => {
  const [pageNum, setPageNum] = useState(0);
  const [loading, setLoading] = useState(true);
  const didMountRef = useRef(false);
  const isModerated = league?.format === "Moderated-dropin";

  useEffect(() => {
    if (didMountRef.current && !loading) setPlayoffChangeDetected(true);
    else didMountRef.current = true;
    // eslint-disable-next-line
  }, [brackets]);
  useEffect(() => {
    let brackets = [];
    let filledBracket = true;
    let leagueFinished = true;
    const fillSinglesBracket = (track) => {
      let bracket = clonedeep(track.track.playoffsBracket);
      if (!bracket[0]) return bracket;
      let scores = track.scores;
      let players = track.players;
      const isModerateSingle = divisionData.includes(track.division.division);
      Object.keys(bracket).forEach((key, index) => {
        bracket[key].forEach((slot, index2) => {
          let playerBracketSize =
            index !== 0 ? 2 : isModerated && !isModerateSingle ? 4 : 2;
          if (slot.list.length !== playerBracketSize) filledBracket = false;
          if (!slot.division) {
            bracket[index][index2].division = track.division.division;
          }
          if (slot.score !== "") {
            let foundScore = scores.find((s) => s.id === slot.score);
            bracket[index][index2].score = foundScore;
          } else if (index === Object.keys(bracket).length - 1)
            leagueFinished = false;
          slot.list.forEach((playerID, index3) => {
            let foundPlayer = players.find((p) => p.id === playerID);
            if (foundPlayer !== undefined)
              bracket[index][index2].list[index3] = foundPlayer;
            else if (playerID !== "empty")
              bracket[index][index2].list[index3] = {
                name: "Bye",
                id: playerID,
              };
          });
        });
      });
      return bracket;
    };
    const fillDoublesBracket = (track) => {
      let bracket = clonedeep(track.track.playoffsBracket);
      if (!bracket[0]) return bracket;
      let scores = track.scores;
      Object.keys(bracket).forEach((key, index) => {
        bracket[key].forEach((slot, index2) => {
          if (slot.list.length !== 2) filledBracket = false;
          if (slot.score !== "") {
            let foundScore = scores.find((score) => score.id === slot.score);
            bracket[index][index2].score = foundScore;
          } else if (index === Object.keys(bracket).length - 1)
            leagueFinished = false;
          slot.list.forEach((teamID, index3) => {
            let foundTeam = track.teams.find((team) => team.id === teamID);
            if (foundTeam !== undefined)
              bracket[index][index2].list[index3] = {
                ...foundTeam,
                name: foundTeam.teamName,
              };
            else if (teamID !== "empty")
              bracket[index][index2].list[index3] = {
                name: "Bye",
                id: teamID,
              };
          });
        });
      });
      return bracket;
    };
    tracks.forEach((track) => {
      const isModerateSingle = divisionData.includes(track.division.division);
      brackets.push(
        track.division.doublesFormat
          ? fillDoublesBracket(track)
          : isModerated && league.playoffsGenerated && !isModerateSingle
          ? fillDoublesBracket(track)
          : fillSinglesBracket(track)
      );
    });
    if(setPlayoffsGenerated){
      setPlayoffsGenerated(filledBracket);
    }
    if(setAllPlayoffsEnded){
      setAllPlayoffsEnded(leagueFinished);
    }
    setBrackets(brackets);
    if(setPlayoffChangeDetected){
      setPlayoffChangeDetected(false);
    }
    setLoading(false);
    didMountRef.current = false;
    // eslint-disable-next-line
  }, [tracks]);

  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  return (
    <div className="column filler">
      <CustomTabs labels={pageLabels} value={pageNum} setValue={setPageNum} />
      <div className="column filler">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {tracks.map((track, i) => (
              <PlayoffsBracket
                key={i}
                index={i}
                currIndex={pageNum}
                league={league}
                division={track.division}
                track={track}
                scores={track.scores}
                brackets={brackets}
                setBrackets={setBrackets}
                isDirector={true}
                isPublic={isPublic}
              />
            ))}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default DirectorLeaguePlayoffs;

import React, { useState, useEffect, Fragment } from "react";
import { auth, firestore, storage } from "../../firebase";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import Snackbar from "@material-ui/core/Snackbar";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";

import AccountCircle from "@material-ui/icons/AccountCircle";
import Settings from "@material-ui/icons/Settings";
import Add from "@material-ui/icons/Add";
import ExitToApp from "@material-ui/icons/ExitToApp";
import SportsTennisIcon from "@material-ui/icons/SportsTennis";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Create from "@material-ui/icons/Create";
import LoginIcon from "@material-ui/icons/Lock";
import LocationCityRounded from "@material-ui/icons/LocationCityRounded";
import IconMenu from "@material-ui/icons/Menu";
import Alert from "@material-ui/lab/Alert";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
  NavLink
} from "react-router-dom";
import xlsxParser from "xlsx-parse-json";
import windowSize from "react-window-size";
import Loader from "react-loader-spinner";
import PrivateRoute from "../../components/PrivateRoute";
import Rewards from "../rewards/Rewards";
import Matches from "../matches/Matches";
import Referrals from "../referrals/Referrals";
import Leagues from "../leagues/Leagues";
import Leaderboard from "../leaderboard/Leaderboard";
// import LeagueView from "../leagues/leaguePage/LeagueView";
import EventView from "../leagues/eventPage/EventView";
// import DoublesLeagueView from "../leagues/leaguePage/DoublesLeagueView";
import ScoreUploadForm from "../forms/ScoreUploadForm";
import ViewPlayer from "../profile/view_player/ViewPlayer";
import Profile from "../profile/Profile";
import UncertifiedUser from "../profile/UncertifiedUser";
import Dashboard from "../dashboard/Dashboard";
import Activities from "../activities/Activities";
import VerifyUser from "../signin/VerifiyUser";
import AdminPage from "../admin/AdminPage";
import CreateLeague from "../director/CreateLeague/CreateLeague";
import CreateEvent from "../director/CreateEvent/CreateEvent";
import ManageMatches from "../director/leagues/ManageMatches";
import DirectorApplication from "../director/DirectorApplication/DirectorApplication";
import DirectorLeaguePage from "../director/leagues/DirectorLeaguePage";
import DirectorEventPage from "../director/events/DirectorEventPage";
import DoublesInvitation from "../forms/DoublesInvitation";
import PageTemplate from "../../components/PageTemplate";
import {
  DashboardIcon,
  GSMIcon,
  ActivitiesIcon,
  EventsIcon,
  MatchesIcon,
  LeaderboardIcon,
  RewardsIcon,
  ReferralsIcon,
  PickleBallIcon,
} from "../../res/SVGIcons";
import {
  getPickleBallData,
  getUsersByID,
  setCurrentSport,
} from "../../functions/firebaseFunctions";
import { AlertTitle } from "@material-ui/lab";
import {
  getNotification,
  UpdateNotificationReadStatus,
} from "../../utils/NotificationServices";
import NotificationDialog from "../../components/NotificationDialog";
import EventModerationScreen from "../director/leagues/EventModerationScreen";
import SwitchSportPopup from "../../components/SwitchSportPopup";
import PickleballScoreUploadForm from "../forms/PickleballScoreUploadForm";

const  LeagueView = React.lazy(() => import('../leagues/leaguePage/LeagueView'));
const  DoublesLeagueView = React.lazy(() => import('../leagues/leaguePage/DoublesLeagueView'));
const  LeagueViewPage = React.lazy(() => import('../director/leagues/LeagueViewPage'));
const  PublicLeagueView = React.lazy(() => import('../leagues/leaguePage/PublicLeagueView'));


const sideNavRoutes = [
  {
    label: "Dashboard",
    path: `/Dashboard`,
  },
  {
    label: "Activities",
    path: `/Activities`,
  },
  {
    label: "Leagues & Events",
    path: `/Leagues`,
  },
  {
    label: "My Matches",
    path: `/Matches`,
  },
  {
    label: "Leaderboard",
    path: `/Leaderboard`,
  },
  {
    label: "Rewards",
    path: `/Rewards`,
  },
  {
    label: "Referrals",
    path: `/Referrals`,
  },
];

const playerProfileRoutes = [
  {
    label: "My Account",
    path: `/Profile`,
  },
  // {
  //   label: "Payment",
  //   path: `/Profile/Payment`,
  // },
  // {
  //   label: "Settings",
  //   path: `/Profile/Settings`,
  // },
  // {
  //   label: "Help & Support",
  //   path: `/Profile/Support`,
  // },
];

const AppRouter = ({ user, windowWidth, setIsPickleball:handlePickleBall , isPublicRoute}) => {
  const location = window.location.pathname;
  const [leagues, setLeagues] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [switchPickleBall,setSwitchPickleBall]=useState(true)
  const [isPickleball, setIsPickleball] = useState(user?.currentSport === "pickleball");
  const [tracks, setTracks] = useState([]);
  //Incoming invites for user
  const [doublesIncomingInvites, setDoublesIncomingInvites] = useState([]);
  const [doublesIncomingInvitesByEmail, setDoublesIncomingInvitesByEmail] =
    useState([]);
  //Invites sent by user to other players
  const [doublesOutgoingInvites, setDoublesOutgoingInvites] = useState([]);
  const [userDoublesTeams, setUserDoublesTeams] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sportLoading, setSportLoading] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openScoreUpload, setOpenScoreUpload] = useState(false);
  const [snackBarToggle, setSnackBarToggle] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSwitchSportPopup, setIsOpenSwitchSportPopup] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisableSwitchSport, setisDisableSwitchSport] = useState(false);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(null);
  const [userState, setuserState] = useState(user);

  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);

  const mobileSize = windowWidth < 900;
  const drawerWidth = mobileSize ? 160 : 120;
  const drawerTop = mobileSize ? 60 : 75;

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: "black",
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      marginTop: drawerTop,
    },
    drawerPaper: {
      width: drawerWidth,
      marginTop: drawerTop,
      paddingTop: !mobileSize && "30px",
    },
    content: {
      flexGrow: 1,
      marginTop: drawerTop,
      padding: mobileSize ? "20px" : "40px",
      backgroundColor: isPickleball ? "#FCF4FF" : "#EDF8F4",
    },
  }));

  const classes = useStyles();

  useEffect(()=>{
    const switchCase = firestore.collection("admin").doc("siteValues").onSnapshot(doc => {
      setSwitchPickleBall(doc.data().switchPickleball);
    });
    return () => {
      switchCase();
    };
  },[])

  const retrieveAsyncData = async (leagueJoin) => {
    let leagues = [];
    let divisions = [];
    let tracks = [];
    if (user && user.authEmail && user.authEmail !== user.email) {
      await firestore
          .collection("users")
          .doc(user?.uid)
          .update({ email: user?.authEmail });
    }
    if (leagueJoin?.length > 0)
      await Promise.all(
          leagueJoin?.map(async (leagueRecord) => {
            const leagueRef = firestore
                .collection("leagues")
                .doc(leagueRecord.leagueID);
            const leagueDoc = await leagueRef.get();
            const league = {
              ...leagueDoc.data(),
              id: leagueDoc.id,
              ref: leagueRef,
            };
            const divisionRef = leagueRef
                .collection("divisions")
                .doc(leagueRecord.divisionID);
            const divisionDoc = await divisionRef.get();
            const division = {
              ...divisionDoc.data(),
              id: divisionDoc.id,
              ref: divisionRef,
            };
            const trackRef = divisionRef
                .collection("tracks")
                .doc(leagueRecord.trackID);
            const trackDoc = await trackRef.get();
            const track = {
              ...trackDoc.data(),
              id: trackDoc.id,
              ref: trackRef,
            };
            leagues[league.id] = league;
            divisions[division.id] = division;
            tracks[track.id] = track;
          })
      );
    setLeagues(leagues);
    setDivisions(divisions);
    setTracks(tracks);
    setSportLoading(false);
    setLoading(false);
  };

  const getZonListData = async ()=>{
    await storage
        .ref()
        .child(`adminDocuments/zipmapping.xlsx`)
        .getDownloadURL()
        .then(async (url) => {
          let file = null;
          await fetch(url)
              .then((res) => res.blob())
              .then((blob) => {
                file = blob;
              });
          xlsxParser.onFileSelection(file).then((data) => {
            setZoneList(data["Zipcode-Area Map"]);
            setLoading(false);
          });
        });
  }

  useEffect(() => {
    setSportLoading(true);
    getZonListData();
    if(user && user.uid && user.email){
      console.log({
        user
      })
      const doublesTeamsRef = firestore
          .collection("doublesTeams")
          .where("players", "array-contains", user !== null ? user?.uid : "");
      const unsubscribeDoublesTeams = doublesTeamsRef.onSnapshot(
          async (doublesTeamsSnapshot) => {
            setUserDoublesTeams(
                await Promise.all(
                    doublesTeamsSnapshot.docs.map(async (doc) => {
                      const id = doc.id;
                      const data = doc.data();
                      const players = await getUsersByID(data.players);
                      const teamName = `${players[0].lastName} - ${players[1].lastName}`;
                      return { ...data, teamName, players, id, ref: doc.ref };
                    })
                )
            );
          }
      );
      const doublesInvitesRef = firestore
          .collection("doublesTeamInvites")
          .where("invited", "==", user !== null ? user?.uid : "")
          .where("status", "!=", "declined");
      const unsubscribeDoublesInvites = doublesInvitesRef.onSnapshot(
          (doublesInvitesSnapshot) => {
            setDoublesIncomingInvites(
                doublesInvitesSnapshot.docs.map((doc) => {
                  const id = doc.id;
                  return { ...doc.data(), id, ref: doc.ref };
                })
            );
          }
      );
      const doublesInvitesByEmailRef = firestore
          .collection("doublesTeamInvites")
          .where("newPlayerEmail", "==", user !== null ? user?.email : "")
          .where("status", "!=", "declined");
      const unsubscribeDoublesInvitesByEmail =
          doublesInvitesByEmailRef.onSnapshot((doublesInvitesSnapshot) => {
            setDoublesIncomingInvitesByEmail(
                doublesInvitesSnapshot.docs.map((doc) => {
                  const id = doc.id;
                  return { ...doc.data(), id, ref: doc.ref };
                })
            );
          });
      const doublesInvitedRef = firestore
          .collection("doublesTeamInvites")
          .where("inviter", "==", user !== null ? user?.uid : "")
          .where("status", "!=", "declined");
      const unsubscribeDoublesInvited = doublesInvitedRef.onSnapshot(
          (doublesInvitedSnapshot) => {
            setDoublesOutgoingInvites(
                doublesInvitedSnapshot.docs.map((doc) => {
                  const id = doc.id;
                  return { ...doc.data(), id, ref: doc.ref };
                })
            );
            if(!isPublicRoute){
              retrieveAsyncData(
                isPickleball ? user?.pb_leaguesJoined : user?.leaguesJoined
            );
            }else {
              setLoading(false)
            }
          }
      );

      return () => {
        unsubscribeDoublesTeams();
        unsubscribeDoublesInvited();
        unsubscribeDoublesInvites();
        unsubscribeDoublesInvitesByEmail();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.leaguesJoined, isPickleball, user?.authEmail,isPublicRoute]);

  useEffect(()=>{
      if(isPickleball){
          handlePickleBall(isPickleball)
      }
  },[isPickleball]);

  useEffect(() => {
    if(user) {
      getUserNotification();
    }
    location === "/Director/EventModeration" ||
    location.includes("/Profile/ViewProfile/")
      ? setisDisableSwitchSport(true)
      : setisDisableSwitchSport(false);
  }, [user,location]);

  const getUserNotification = async () => {
    setIsLoading(true);
    try {
      let res = await getNotification(user?.uid);
      const resData = res?.data;
      setNotificationData(resData);
      if (resData.length > 0) {
        let unread = resData.filter((item) => !item.isRead);
        setUnreadNotificationCount(unread.length);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.warn(error);
    }
  };

  const logout = async () => {
    handleProfileMenuClose();
    localStorage.clear("initialURLS");
    document.body.className = "";
    auth.signOut();
    localStorage.clear("authUser");
  };
  const handleProfileMenuOpen = (event) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setProfileMenuAnchorEl(null);
  };
  const toggleDrawer = () => {
    setOpenDrawer((curr) => !curr);
  };
  const handleClose = async () => {
    setIsOpen(false);
    let unread = notificationData.filter((item) => !item.isRead);
    let unreadArryId = [];
    for (let i = 0; i < unread.length; i++) {
      const element = unread[i].id;
      unreadArryId.push(element);
    }
    if (unreadArryId.length > 0) {
      try {
        await UpdateNotificationReadStatus(unreadArryId);
        getUserNotification();
        setUnreadNotificationCount(null);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if(user && user.currentSport === "pickleball") {
      let obj = {...userState};
      obj = getPickleBallData(obj, user);
      setuserState(isPickleball ? obj : user);
      // setSportLoading(true);
      // setTimeout(() => {
      //   setSportLoading(false);
      // }, 1100);
      setIsPickleball(user?.currentSport === "pickleball");
      document.body.className = "pick-a-ball";
    } else {
      document.body.className = "";
    }
  }, [user, user?.currentSport, isPickleball]);

  const handleSwitch = async () => {
    if (user?.director || user?.isBothSportCheckout) {
      setCurrentSport(user?.currentSport !== "tennis", user?.uid);
    } else {
      setIsOpenSwitchSportPopup(true);
    }
  };

  const handleClick=()=>{
    retrieveAsyncData(isPickleball ? user?.pb_leaguesJoined : user?.leaguesJoined)
    setOpenScoreUpload(true)
  }

  return (
    <Router>
      {user && (
          <>
            <NotificationDialog
                notificationData={notificationData}
                isOpen={isOpen}
                handleClose={handleClose}
                isLoading={isLoading}
                doublesIncomingInvites={doublesIncomingInvites}
            />
            <SwitchSportPopup
                user={user}
                isOpen={isOpenSwitchSportPopup}
                handleClose={() => setIsOpenSwitchSportPopup(false)}
                isLoading={isLoading}
            />
            <Snackbar
                open={snackBarToggle}
                autoHideDuration={5000}
                onClose={(e) => {
                  setSnackBarToggle(false);
                }}
            >
              <Alert
                  onClose={(e) => {
                    setSnackBarToggle(false);
                  }}
                  severity="success"
              >
                A reset email has been sent to your email!
              </Alert>
            </Snackbar>
      </>
      )}
      <div className="dashboardWrapper">
        {!loading &&
          openScoreUpload &&
          user && (user?.currentSport === "pickleball" ? (
            <PickleballScoreUploadForm
              open={openScoreUpload}
              setOpen={setOpenScoreUpload}
              leagues={leagues}
              divisions={divisions}
              tracks={tracks}
              user={userState}
              userDoublesTeams={userDoublesTeams}
            />
          ) : (
            <ScoreUploadForm
              open={openScoreUpload}
              setOpen={setOpenScoreUpload}
              leagues={leagues}
              divisions={divisions}
              tracks={tracks}
              user={userState}
              userDoublesTeams={userDoublesTeams}
            />
          ))}
        <AppBar elevation={2} className="appBar">
          {!mobileSize ? (
            <Fragment>
              <GSMIcon />
              <div className="appBaGSMWrapper">
                <PickleBallIcon isPickleball={isPickleball}/>
                {user && <div className="rowCenter">
                  <LocationCityRounded className="appBarLocationIcon"/>
                  <Typography className="appBarLocationText">
                    {user?.playLocation?.locale}: {user?.playLocation?.area}
                  </Typography>
                </div>}
              </div>
            </Fragment>
          ) : (
            <IconButton onClick={toggleDrawer}>
              <IconMenu className="appBarUploadScoreButtonIcon" />
            </IconButton>
          )}
          <div className="filler" />
          {user && <Button
              onClick={() => {
                getUserNotification();
                setIsOpen(true);
              }}
              className={mobileSize ? "" : "margin20Right"}
              variant="text"
          >
            <div className="flexCenter">
              <NotificationsIcon
                  className="appBarUploadScoreButtonIcon"
                  fontSize="large"
              />
              {unreadNotificationCount !== null && (
                  <Badge
                      className="notificationCount"
                      badgeContent={unreadNotificationCount}
                      color="secondary"
                  />
              )}
            </div>
          </Button>}

          {user?.admin && (
            <Link className="noUnderline" to="/Admin">
              <Button
                className={mobileSize ? "" : "margin20Right"}
                variant="text"
              >
                <div className="flexCenter">
                  <Settings className="image30 appBarUploadScoreButtonIcon" />
                  {!mobileSize && (
                    <Typography className="appBarUploadScoreButtonText">
                      Manage App
                    </Typography>
                  )}
                </div>
              </Button>
            </Link>
          )}
          {user?.director && (
            <Link className="noUnderline" to="/Director/CreateEvent">
              <Button
                className={mobileSize ? "" : "margin20Right"}
                variant="text"
              >
                <div className="flexCenter">
                  <Add className="image30 appBarUploadScoreButtonIcon" />
                  {!mobileSize && (
                    <Typography className="appBarUploadScoreButtonText">
                      Create Event
                    </Typography>
                  )}
                </div>
              </Button>
            </Link>
          )}
          {user?.director && (
            <Link className="noUnderline" to="/Director/CreateLeague">
              <Button
                className={mobileSize ? "" : "margin20Right"}
                variant="text"
              >
                <div className="flexCenter">
                  <Add className="image30 appBarUploadScoreButtonIcon" />
                  {!mobileSize && (
                    <Typography className="appBarUploadScoreButtonText">
                      Create League
                    </Typography>
                  )}
                </div>
              </Button>
            </Link>
          )}
          {user && <Button
              onClick={()=>handleClick()}
              className={mobileSize ? "" : "margin20Right"}
              variant="text"
          >
            <div className="flexCenter">
              <Create className="image30 appBarUploadScoreButtonIcon"/>
              {!mobileSize && (
                  <Typography className="appBarUploadScoreButtonText">
                    Report Score
                  </Typography>
              )}
            </div>
          </Button>}
            {!user && isPublicRoute && <Button
              onClick={()=>window.location.replace('/Landing')}
              className={mobileSize ? "" : "margin20Right"}
              variant="text"
          >
            <div className="flexCenter">
              <LoginIcon className="image30 appBarUploadScoreButtonIcon"/>
              {!mobileSize && (
                  <Typography className="appBarUploadScoreButtonText">
                    Log in
                  </Typography>
              )}
            </div>
          </Button>}

          {user && <>
            <Button variant="text" onClick={handleProfileMenuOpen}>
              <div className="flexCenter">
                {!mobileSize && (
                    <Typography className="appBarProfileButtonText">
                      {user?.firstName}
                    </Typography>
                )}
                <Avatar src={user?.imageURL} className="image50"/>
              </div>
            </Button>
            <Menu
                className="appBarProfileMenu"
                keepMounted
                anchorEl={profileMenuAnchorEl}
                getContentAnchorEl={null}
                open={Boolean(profileMenuAnchorEl)}
                onClose={handleProfileMenuClose}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                transformOrigin={{vertical: "top", horizontal: "center"}}
            >
              {playerProfileRoutes.map((route, key) => (
                  <Link key={key} className="noUnderline maxWidth" to={route.path}>
                    <MenuItem
                        onClick={() => {
                          handleProfileMenuClose();
                        }}
                    >
                      <div className="rowCenter">
                        {route.label === "My Account" && (
                            <AccountCircle className="appBarProfileMenuIcon"/>
                        )}
                        <Typography className="appBarProfileMenuText">
                          {route.label}
                        </Typography>
                      </div>
                    </MenuItem>
                  </Link>
              ))}
              {(switchPickleBall || user.admin) && <MenuItem onClick={handleSwitch} disabled={isDisableSwitchSport}>
                <div className="rowCenter">
                  <SportsTennisIcon className="appBarProfileMenuIcon"/>
                  <Typography className="appBarProfileMenuText">
                    {`Switch to ${
                        user?.currentSport === "tennis" ? "pickleball" : "tennis"
                    }`}
                  </Typography>
                </div>
              </MenuItem>}
              <MenuItem onClick={logout}>
                <div className="rowCenter">
                  <ExitToApp className="appBarProfileMenuIcon"/>
                  <Typography className="appBarProfileMenuText">
                    Signout
                  </Typography>
                </div>
              </MenuItem>
            </Menu>
          </>}
        </AppBar>
        {user && <Drawer
            className={classes.drawer}
            variant={mobileSize ? "temporary" : "permanent"}
            open={openDrawer}
            onClose={toggleDrawer}
            anchor="left"
            classes={{
              paper: classes.drawerPaper,
            }}
        >
          <div className="sideBar">
            {sideNavRoutes.map((route, key) => (
                <NavLink
                    key={key}
                    className={'noUnderline maxWidth'}
                    activeStyle={{
                      backgroundColor: "#c8d95136"
                    }}
                    to={route.path}
                >
                  <Button
                      onClick={() => {
                        toggleDrawer();
                      }}
                      style={{borderRadius: 0}}
                      variant="text"
                      className="maxWidth"
                  >
                    <div className="dashboardSideNavButtonWrapper">
                      {route.label === "Dashboard" && (
                          <DashboardIcon
                              mobile={mobileSize}
                              isPickleball={isPickleball}
                          />
                      )}
                      {route.label === "Activities" && (
                          <ActivitiesIcon
                              mobile={mobileSize}
                              isPickleball={isPickleball}
                          />
                      )}
                      {route.label === "Leagues & Events" && (
                          <EventsIcon
                              mobile={mobileSize}
                              isPickleball={isPickleball}
                          />
                      )}
                      {route.label === "My Matches" && (
                          <MatchesIcon
                              mobile={mobileSize}
                              isPickleball={isPickleball}
                          />
                      )}
                      {route.label === "Leaderboard" && (
                          <LeaderboardIcon
                              mobile={mobileSize}
                              isPickleball={isPickleball}
                          />
                      )}
                      {route.label === "Rewards" && (
                          <RewardsIcon
                              mobile={mobileSize}
                              isPickleball={isPickleball}
                          />
                      )}
                      {route.label === "Referrals" && (
                          <ReferralsIcon
                              mobile={mobileSize}
                              isPickleball={isPickleball}
                          />
                      )}
                      <Typography className="dashboardSideNavButtonText">
                        {route.label}
                      </Typography>
                    </div>
                  </Button>
                </NavLink>
            ))}
          </div>
        </Drawer>}
        <div className={classes.content}>
          {user && (!user?.phoneNumber ||
            !user?.homeAddress?.zipCode ||
            !user?.gender) && (
            <Alert className="alertmessage" severity="warning">
              <AlertTitle>Warning</AlertTitle>
              Please update your profile information like gender, zipcode, phone
              number.
            </Alert>
          )}
          {!loading && (
            <Switch>
                <Route path="/VerifyUser">
                  <VerifyUser user={user}/>
                </Route>
                {user && !user?.verified && <Redirect to="VerifyUser"/>}
                <Route path="/Admin/PageTemplate">
                  <PageTemplate/>
                </Route>
                <Route path="/Form/DoublesInvite/:team">
                  <DoublesInvitation
                      invites={[
                        ...doublesIncomingInvites,
                        ...doublesIncomingInvitesByEmail,
                      ]}
                      outgoingInvites={doublesOutgoingInvites}
                      user={user}
                      leagues={leagues}
                      divisions={divisions}
                      tracks={tracks}
                      zoneList={zoneList}
                      mobileSize={mobileSize}
                  />
                </Route>
                <PrivateRoute access={user?.admin} exact path="/Admin">
                  <AdminPage
                      user={user}
                      leagues={leagues}
                      divisions={divisions}
                      tracks={tracks}
                      zoneList={zoneList}
                      mobileSize={mobileSize}
                  />
                </PrivateRoute>
                <Route path="/DirectorApplication">
                  <DirectorApplication user={user} mobileSize={mobileSize}/>
                </Route>
                <PrivateRoute
                    access={user?.admin}
                    path={`/Admin/ManageLeague/:leagueID`}
                >
                  <DirectorLeaguePage
                      user={user}
                      setisDisableSwitchSport={setisDisableSwitchSport}
                      zoneList={zoneList}
                      mobileSize={mobileSize}
                  />
                </PrivateRoute>
                <PrivateRoute
                    access={user && user.director}
                    path={`/Director/ViewLeague/:leagueID`}
                >
                  <DirectorLeaguePage
                      user={user}
                      setisDisableSwitchSport={setisDisableSwitchSport}
                      zoneList={zoneList}
                      mobileSize={mobileSize}
                  />
                </PrivateRoute>
                <PrivateRoute
                    access={user?.admin}
                    path={`/Admin/ManageEvent/:leagueID`}
                >
                  <DirectorEventPage
                      user={user}
                      mobileSize={mobileSize}
                      zoneList={zoneList}
                  />
                </PrivateRoute>
                <PrivateRoute
                    access={user?.director}
                    path={`/Director/ViewEvent/:leagueID`}
                >
                  <DirectorEventPage
                      user={user}
                      zoneList={zoneList}
                      mobileSize={mobileSize}
                  />
                </PrivateRoute>
                <PrivateRoute access={user?.director} path="/Director/CreateEvent">
                  <CreateEvent
                      user={user}
                      zoneList={zoneList}
                  />
                </PrivateRoute>
                <PrivateRoute
                    access={user?.director}
                    path="/Director/CreateLeague"
                >
                  <CreateLeague
                      user={user}
                      zoneList={zoneList}
                  />
                </PrivateRoute>
                <PrivateRoute
                    access={user?.director}
                    path="/Director/EventModeration"
                >
                  <EventModerationScreen
                      leagues={leagues}
                      divisions={divisions}
                      tracks={tracks}
                      user={user}
                      userDoublesTeams={userDoublesTeams}
                  />
                </PrivateRoute>

                <PrivateRoute
                    access={user?.director}
                    path="/Director/ManageMatches"
                >
                  <ManageMatches
                      user={user}
                      leagues={leagues}
                      divisions={divisions}
                      tracks={tracks}
                  />
                </PrivateRoute>
              <Route path="/Activities">
                <Activities
                  user={userState}
                  mobileSize={mobileSize}
                  leagues={leagues}
                  divisions={divisions}
                  tracks={tracks}
                />
              </Route>
              <Route exact path="/Profile">
                <Profile
                  user={userState}
                  leagues={leagues}
                  divisions={divisions}
                  tracks={tracks}
                  zoneList={zoneList}
                  mobileSize={mobileSize}
                />
              </Route>
              <Route path="/Rewards">
                <Rewards
                  user={user}
                  leagues={leagues}
                  divisions={divisions}
                  tracks={tracks}
                  mobileSize={mobileSize}
                />
              </Route>
              <Route path="/Referrals">
                <Referrals
                  user={user}
                  mobileSize={mobileSize}
                />
              </Route>
              <Route exact path="/Leagues">
                <Leagues
                  isPickleball={isPickleball}
                  sportLoading={sportLoading}
                  user={userState}
                  leagues={leagues}
                  divisions={divisions}
                  tracks={tracks}
                  zoneList={zoneList}
                  mobileSize={mobileSize}
                />
              </Route>
              <Route
                exact
                path={`/League/FindEvent/:leagueID/:divisionID/:trackID/:page`}
                render={() => (
                  <EventView
                    user={user}
                    mobileSize={mobileSize}
                    zoneList={zoneList}
                    viewType="purchase"
                  />
                )}
              />
              <Route
                exact
                path={`/League/MyEvents/:leagueID/:divisionID/:trackID/:page`}
                render={() => (
                  <EventView
                    user={user}
                    mobileSize={mobileSize}
                    zoneList={zoneList}
                    viewType="view"
                  />
                )}
              />
              <Route
                exact
                path={`/League/FindLeague/Singles/:leagueID/:divisionID/:trackID/:page`}
                render={() => (
                  <React.Suspense fallback={<Loader type="TailSpin"
                                                      color="#02c39a"
                                                      height={100}
                                                      width={100} />}>
                  <LeagueView
                    setisDisableSwitchSport={setisDisableSwitchSport}
                    user={userState}
                    mobileSize={mobileSize}
                    zoneList={zoneList}
                    viewType="purchase"
                  />
                  </React.Suspense>
                )}
              />
              <Route
                exact
                path={`/League/FindLeague/Doubles/:leagueID/:divisionID/:trackID/:page`}
                render={() => (
                  <React.Suspense fallback={<Loader type="TailSpin"
                                                      color="#02c39a"
                                                      height={100}
                                                      width={100} />}>
                  <DoublesLeagueView
                    user={user}
                    mobileSize={mobileSize}
                    zoneList={zoneList}
                    doublesOutgoingInvites={doublesOutgoingInvites}
                    doublesIncomingInvites={doublesIncomingInvites}
                    userDoublesTeams={userDoublesTeams}
                    viewType="purchase"
                  />
                  </React.Suspense>
                )}
              />
              <Route
                path={`/League/MyLeagues/Singles/:leagueID/:divisionID/:trackID/:page`}
                render={() => (
                  <React.Suspense fallback={<Loader type="TailSpin"
                                                      color="#02c39a"
                                                      height={100}
                                                      width={100} />}>
                  <LeagueView
                    setisDisableSwitchSport={setisDisableSwitchSport}
                    user={userState}
                    mobileSize={mobileSize}
                    setIsPickleball={setIsPickleball}
                    zoneList={zoneList}
                    viewType="view"
                  />
                  </React.Suspense>
                )}
              />
              <Route path="/uncertified-user">
                <UncertifiedUser/>
              </Route>
              <Route
                path={`/League/MyLeaguesView/Singles/:leagueID/:divisionID/:trackID/:page`}
                render={() => (
                    <React.Suspense fallback={<Loader type="TailSpin"
                                                      color="#02c39a"
                                                      height={100}
                                                      width={100} />}>
                      <PublicLeagueView
                          setisDisableSwitchSport={setisDisableSwitchSport}
                          user={userState}
                          isView
                          mobileSize={mobileSize}
                          setIsPickleball={setIsPickleball}
                          zoneList={zoneList}
                          viewType="view"
                      />
                    </React.Suspense>
                )}
              />
              <Route
                path={`/League/ViewLeague/:leagueID`}
                render={() => (
                    <React.Suspense fallback={<Loader type="TailSpin"
                                                      color="#02c39a"
                                                      height={100}
                                                      width={100} />}>
                        <LeagueViewPage
                            user={user}
                            isPublic
                            setIsPickleball={setIsPickleball}
                            setisDisableSwitchSport={setisDisableSwitchSport}
                            zoneList={zoneList}
                            mobileSize={mobileSize}
                        />
                    </React.Suspense>
                )}
              />
              <Route
                path={`/League/MyLeagues/Doubles/:leagueID/:divisionID/:trackID/:page`}
                render={() => (
                  <React.Suspense fallback={<Loader type="TailSpin"
                                                      color="#02c39a"
                                                      height={100}
                                                      width={100} />}>
                  <DoublesLeagueView
                    user={user}
                    mobileSize={mobileSize}
                    zoneList={zoneList}
                    doublesOutgoingInvites={doublesOutgoingInvites}
                    doublesIncomingInvites={doublesIncomingInvites}
                    userDoublesTeams={userDoublesTeams}
                    viewType="view"
                  />
                  </React.Suspense>
                )}
              />
              <Route path={`/Profile/ViewProfile/:playerID`}>
                <ViewPlayer
                  user={userState}
                  leagues={leagues}
                  divisions={divisions}
                  tracks={tracks}
                  mobileSize={mobileSize}
                />
              </Route>
              <Route exact path="/Matches">
                <Matches
                  user={userState}
                  sportLoading={sportLoading}
                  leagues={leagues}
                  divisions={divisions}
                  tracks={tracks}
                  userDoublesTeams={userDoublesTeams}
                />
              </Route>
              <Route exact path="/Dashboard">
                <Dashboard
                  sportLoading={sportLoading}
                  user={userState}
                  leagues={leagues}
                  divisions={divisions}
                  tracks={tracks}
                  userDoublesTeams={userDoublesTeams}
                  mobileSize={mobileSize}
                />
              </Route>
              <Route exact path="/Leaderboard">
                <Leaderboard
                  user={user}
                  zoneList={zoneList}
                  mobileSize={mobileSize}
                />
              </Route>
              {/*<Route exact path="/ListItemActivity/:id">*/}
              {/*  <ListItemActivity*/}
              {/*    user={user}*/}
              {/*  />*/}
              {/*</Route>*/}
              <Redirect to="/Leagues" />
            </Switch>
          )}
          {loading && (
            <div className="pageWrapper">
              <Loader
                type="TailSpin"
                color="#02c39a"
                height={100}
                width={100}
                timeout={2000}
              />
            </div>
          )}
        </div>
      </div>
    </Router>
  );
};

export default windowSize(AppRouter);

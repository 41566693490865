import React, { useState, Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import { CustomTabs } from "../../components/CustomTabs";
import {
  DefaultTextField,
  PasswordTextField,
} from "../../components/CustomTextField";
import { Firebase, firestore, functions } from "../../firebase";
// import { singleActionEmail } from "../../functions/Messaging";
const ManageAccount = ({ user }) => {
  // const url = window.location.href.split("/");
  // const verifyURL = url[0] + "//" + url[2] + "/VerifyUser";
  const pageLabels = ["Change Email", "Reset Password", "Delete Account"];
  const [currPassword, setCurrPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [confirmNewEmail, setConfirmNewEmail] = useState("");
  const [error, setError] = useState("");
  const [pageNum, setPageNum] = useState(0);
  const [snackBarToggle, setSnackBarToggle] = useState(false);
  const deletionSelection = [
    "I didn't find the service I was looking for.",
    "I'm having trouble breaking into the marketplace.",
    "There's too much competition.",
    "The service is too expensive",
    "I don't need your service right now.",
    "The service is too confusing",
    "GameSetMatch sends too many emails.",
    "Other",
  ];
  const [reasonForDelete, setReasonForDelete] = useState(
    "I didn't find the service I was looking for."
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    const currUser = Firebase.auth().currentUser;
    if (pageNum === 0) {
      const credential = Firebase.auth.EmailAuthProvider.credential(
        user.email,
        currPassword
      );
      currUser
        .reauthenticateWithCredential(credential)
        .then(async () => {
          if (newEmail !== confirmNewEmail) {
            setError("Emails do not match.");
          } else {
            await currUser
              .updateEmail(newEmail)
              .then((success) => {
                firestore.collection("users").doc(user.uid).update({
                  email: newEmail,
                });
                try {
                  const updateUserData = functions.httpsCallable(
                    'webhookProcessActionUpdateUserLeague'
                  );
                  updateUserData({ userID: user.uid });
                } catch (error) {
                  console.log('webhookProcessActionUpdateUserLeague', error);
                }
                setError("");
                setCurrPassword("");
                setNewEmail("");
                setConfirmNewEmail("");
                setSnackBarToggle(true);
              })
              .catch((err) => {
                setError(err.message);
              });
          }
        })
        .catch(() => {
          setError("Current password does not match existing password.");
        });
    } else if (pageNum === 1) {
      const credential = Firebase.auth.EmailAuthProvider.credential(
        user.email,
        currPassword
      );
      currUser
        .reauthenticateWithCredential(credential)
        .then(async () => {
          if (newPassword !== confirmPassword) {
            setError("Passwords do not match.");
          } else {
            await currUser
              .updatePassword(confirmPassword)
              .then((success) => {
                firestore.collection("users").doc(user.uid).update({
                  verified: false,
                });
                setError("");
                setCurrPassword("");
                setNewPassword("");
                setConfirmPassword("");
                setSnackBarToggle(true);
              })
              .catch((err) => {
                setError(err.message);
              });
          }
        })
        .catch(() => {
          setError("Current password does not match existing password.");
        });
    } else {
      await firestore.collection("deleted").add({
        userID: user.uid,
        userEmail: user.email,
        reason: reasonForDelete,
      });
      await firestore.collection("users").doc(user.uid).update({
        active: false,
      });
    }
  };
  const handleSnackBarClose = () => {
    setSnackBarToggle(false);
  };
  return (
    <div className="column filler">
      <CustomTabs labels={pageLabels} value={pageNum} setValue={setPageNum} />
      <Snackbar
        open={snackBarToggle}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      >
        <Alert onClose={handleSnackBarClose} severity="success">
          {pageNum === 0 && "Your email has been updated!"}
          {pageNum === 1 && "Your password has been reset!"}
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit} className="column filler">
        {pageNum === 0 && (
          <Fragment>
            <Typography className="profileSubpageTitle">
              Change Email
            </Typography>
            <div className="padding20">
              <Typography className="profileRequiredFieldText">
                New Email
              </Typography>
              <DefaultTextField
                state={newEmail}
                setState={setNewEmail}
                placeholder="New Email"
                type="email"
                required={true}
              />
              <Typography className="profileRequiredFieldText">
                Confirm New Email
              </Typography>
              <DefaultTextField
                state={confirmNewEmail}
                setState={setConfirmNewEmail}
                placeholder="New Email"
                type="email"
                required={true}
              />
              <Typography className="profileRequiredFieldText">
                Password
              </Typography>
              <PasswordTextField
                state={currPassword}
                setState={setCurrPassword}
                placeholder="Password"
                required={true}
              />
            </div>
          </Fragment>
        )}
        {pageNum === 1 && (
          <Fragment>
            <Typography className="profileSubpageTitle">
              Change Password
            </Typography>
            <div className="padding20">
              <Typography className="profileRequiredFieldText">
                Current Password
              </Typography>
              <DefaultTextField
                state={currPassword}
                setState={setCurrPassword}
                placeholder="Current Password"
                type="password"
                required={true}
              />
              <Typography className="profileRequiredFieldText">
                New Password
              </Typography>
              <DefaultTextField
                state={newPassword}
                setState={setNewPassword}
                placeholder="New Password"
                type="password"
                required={true}
              />
              <Typography className="profileRequiredFieldText">
                Confirm Password
              </Typography>
              <DefaultTextField
                state={confirmPassword}
                setState={setConfirmPassword}
                placeholder="Confirm Password"
                type="password"
                required={true}
              />
            </div>
          </Fragment>
        )}
        {pageNum === 2 && (
          <Fragment>
            <Typography className="profileSubpageTitle">
              Delete Account
            </Typography>
            <FormControl className="column padding20">
              <RadioGroup
                className="column"
                value={reasonForDelete}
                onChange={(e) => {
                  setReasonForDelete(e.target.value);
                }}
              >
                {deletionSelection.map((select, index) => (
                  <FormControlLabel
                    key={index}
                    value={select}
                    control={<Radio color="default" />}
                    label={select}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Fragment>
        )}
        <div className="filler margin20Bottom" />
        <div className="heightPlaceholder">
          {error !== "" && (
            <Typography className="signUpErrorText">{error}</Typography>
          )}
        </div>
        <Divider className="margin20Bottom" />
        <div className="rowCenter">
          <div className="filler" />
          <Button
            className="profileSaveButton"
            variant="outlined"
            type="submit"
          >
            <Typography className="profileSaveButtonText">
              {pageNum === 0 && "Change Email"}
              {pageNum === 1 && "Reset Password"}
              {pageNum === 2 && "Confirm Delete"}
            </Typography>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ManageAccount;

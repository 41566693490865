import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { firestore } from "../../firebase";
import { getLeagueTracks } from "../../functions/firebaseFunctions";
import { LoadMore } from "../../components/table/Pagination";
import ListItemLeague from "./ListItemLeague";
import LeagueSearchBar from "./LeagueSearchBar";
import Loader from "react-loader-spinner";

function OngoingLeagues({
  sportLoading,
  zoneList,
  user,
  mobileSize,
  leagueNameFilter,
  setLeagueNameFilter,
  filterBySection,
  setFilterBySection,
  filterByLocale,
  setFilterByLocale,
  filterByArea,
  setFilterByArea,
  divisionSelect,
  setDivisionSelect,
  playLevel,
  setPlayLevel,
}) {
  const [leagues, setLeagues] = useState([]);
  const [statusSelect, setStatusSelect] = useState([
    {
      label: "Draft",
      check: true,
      value: "draft",
      value2: "pending",
      value3: "draft",
    },
    {
      label: "Ongoing",
      check: true,
      value: "active",
      value2: "approved",
      value3: "playoffs",
    },
    {
      label: "Completed",
      check: true,
      value: "expired",
      value2: "closed",
      value3: "dead",
    },
  ]);
  const [sortBy, setSortBy] = useState("Date: Earliest-Latest");
  const [sortOptions] = useState([
    "Date: Earliest-Latest",
    "Date: Latest-Earliest",
  ]);
  const [pageNum, setPageNum] = useState(1);
  const resultsPerPage = 18;
  const indexOfLastItem = pageNum * resultsPerPage;
  useEffect(() => {
    const queryLeagues = async (leaguesRef) => {
      const snapshot = await leaguesRef.get();
      let leagues = snapshot.docs.map((league) => ({
        id: league.id,
        ...league.data(),
      }));
      setLeagues(await getLeagueTracks(leagues, "member"));
    };
    let leaguesRef = firestore
      .collection("leagues")
      .where("status", "in", ["active", "playoffs"]);
    if (filterBySection !== "All")
      leaguesRef = leaguesRef.where("section", "==", filterBySection);
    if (filterByLocale !== "All")
      leaguesRef = leaguesRef.where("locale", "==", filterByLocale);
    if (filterByArea !== "All")
      leaguesRef = leaguesRef.where(
        "locations",
        "array-contains",
        filterByArea
      );
    leaguesRef = leaguesRef.where(
      "sportType",
      "==",
      user?.currentSport || "tennis"
    );
    leaguesRef = leaguesRef.limit(pageNum * resultsPerPage);
    queryLeagues(leaguesRef);
  }, [
    filterBySection,
    filterByLocale,
    filterByArea,
    pageNum,
    user.currentSport,
  ]);

  const filterByName = (l) => {
    if (leagueNameFilter === "") return true;
    return l.name.indexOf(leagueNameFilter) === 0;
  };
  const filterByDivision = (l) => {
    if (l.leagueType === "directorDraft" || l.leagueType === "director")
      return true;
    const temp = divisionSelect.filter((d) => d.label === l.division);
    return temp[0] ? temp[0].check : false;
  };
  const filterByStatus = (l) => {
    const temp = statusSelect.filter(
      (s) =>
        s.value === l.status || s.value2 === l.status || s.value3 === l.status
    );
    return temp[0].check;
  };
  const filterByLevel = (l) => {
    if (l.leagueType === "directorDraft" || l.leagueType === "director")
      return true;
    const temp = playLevel.filter((lev) => lev.level === l.level);

    return temp[0].check;
  };
  const sortListings = (a, b) => {
    if (a.leagueType === "directorDraft") return 1;
    if (b.leagueType === "directorDraft") return -1;
    const aDate = a.startDate.toDate();
    const bDate = b.startDate.toDate();
    if (sortBy === "Date: Earliest-Latest") {
      return aDate - bDate;
    } else if (sortBy === "Date: Latest-Earliest") {
      return bDate - aDate;
    }
    return;
  };
  const displayList = leagues
    .filter((l) => filterByName(l))
    .filter((l) => filterByDivision(l))
    .filter((l) => filterByLevel(l))
    .filter((l) => filterByStatus(l))
    .sort((a, b) => sortListings(a, b));

  if (sportLoading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  return (
    <Grid container spacing={mobileSize ? 3 : 6}>
      <Grid item xs={12}>
        <LeagueSearchBar
          type="myLeagues"
          search={leagueNameFilter}
          setSearch={setLeagueNameFilter}
          user={user}
          sectionSelect={filterBySection}
          setSectionSelect={setFilterBySection}
          localeSelect={filterByLocale}
          setLocaleSelect={setFilterByLocale}
          areaSelect={filterByArea}
          setAreaSelect={setFilterByArea}
          zoneList={zoneList}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortOptions={sortOptions}
          divisionSelect={divisionSelect}
          setDivisionSelect={setDivisionSelect}
          levelSelect={playLevel}
          setLevelSelect={setPlayLevel}
          statusSelect={statusSelect}
          setStatusSelect={setStatusSelect}
        />
      </Grid>
      <Grid className="columnCenter" item xs={12}>
        <Grid container spacing={mobileSize ? 3 : 6}>
          {displayList.slice(0, indexOfLastItem).map((combinedLeague, i) => (
            <ListItemLeague key={i} combinedLeague={combinedLeague} isOutGoing />
          ))}
        </Grid>

        <div className="filler margin10Bottom" />
        {indexOfLastItem < displayList.length && (
          <LoadMore setValue={setPageNum} />
        )}
      </Grid>
    </Grid>
  );
}

export default OngoingLeagues;

import React, { useState, useEffect, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Loader from "react-loader-spinner";
import parsePhoneNumber from "libphonenumber-js";
import { CustomTabs } from "../../../components/CustomTabs";
import TableComponent from "../../../components/table/TableComponent";
import DirectorManageTrackForms from "./DirectorManageTrackForms";

const singlesHeader = [
  { label: "rank", content: "Rank", sortable: true },
  { label: "playerName", content: "Player", sortable: true },
  { label: "email", content: "Email", sortable: true },
  { label: "phone", content: "Phone Number", sortable: true },
  { label: "division", content: "Division", sortable: true },
  { label: "level", content: "Level", sortable: true },
  { label: "track", content: "Track", sortable: true },
  { label: "matchesWon", content: "Matches Won", sortable: true },
  { label: "matchesLost", content: "Matches Lost", sortable: true },
];
const doublesHeader = [
  { label: "rank", content: "Rank", sortable: true },
  { label: "teamName", content: "Team", sortable: true },
  { label: "email", content: "Email", sortable: true },
  { label: "phone", content: "Phone Number", sortable: true },
  { label: "division", content: "Division", sortable: true },
  { label: "level", content: "Level", sortable: true },
  { label: "track", content: "Track", sortable: true },
  { label: "matchesWon", content: "Matches Won", sortable: true },
  { label: "matchesLost", content: "Matches Lost", sortable: true },
];

const DirectorLeaguePlayers = ({
  league,
  status,
  pageLabels,
  tracks,
  divisions,
  isPublic=false
}) => {
  const [pageNum, setPageNum] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAddTrack, setOpenAddTrack] = useState(false);
  const [addTrackPlayerList, setAddTrackPlayerList] = useState([]);
  const [addTrackTeamList, setAddTrackTeamList] = useState([]);
  const canEditTracks =
    league?.format === "Moderated-dropin"
      ? status === "approved"
      : league?.scheduleGenerated
      ? false
      : status === "approved";
  const isPickleball = league?.sportType === "pickleball";
  const [selectedTeam, setSelectedTeam] = useState(undefined);
  const [selectedPlayer, setSelectedPlayer] = useState(undefined);
  const [selectedDivision, setSelectedDivision] = useState(undefined);
  const [selectedTrack, setSelectedTrack] = useState(undefined);
  const [divisionSelection, setDivisionSelection] = useState([]);
  const [trackSelection, setTrackSelection] = useState([]);
  const [players, setPlayers] = useState([]);

  const createPlayersTable = (
    cells,
    player,
    email,
    phone,
    rank,
    playerName,
    division,
    level,
    track,
    matchesWon,
    matchesLost,
    trackID
  ) => {
    return {
      cells,
      player,
      email,
      phone,
      rank,
      playerName,
      division,
      level,
      track,
      matchesWon,
      matchesLost,
      trackID,
    };
  };
  const createTeamsTable = (
    cells,
    team,
    email,
    phone,
    rank,
    teamName,
    division,
    level,
    track,
    matchesWon,
    matchesLost,
    trackID,
    teamGsmRating
  ) => {
    return {
      cells,
      team,
      email,
      phone,
      rank,
      teamName,
      division,
      level,
      track,
      matchesWon,
      matchesLost,
      trackID,
      teamGsmRating,
    };
  };

  const handlePlayerHistory = (array, field, trackId)=>array[isPickleball ? `pb_${field}`: field].find((leagueJ) => leagueJ.trackID === trackId)

  useEffect(() => {
    setPlayers(
      tracks.map((track) => {
        if (track) {
          if (track.division.doublesFormat)
            return track.teams.map((team) => {
              const players = team.players;
              const teamName = `${players[0].lastName} - ${
                players[1].lastName
              }${team.withdrawn ? " - Withdrawn" : ""}`;
              const phone = `${parsePhoneNumber(
                players[0].phoneNumber,
                "US"
              ).formatNational()}, ${parsePhoneNumber(
                players[1].phoneNumber,
                "US"
              ).formatNational()}`;
              const email = `${players[0].email}, ${players[1].email}`;
              let history = handlePlayerHistory(players[0], 'leaguesJoined', track.track.id);
              const avgTeamRating = Math.round(
                ((players[0].gsmRatingDoubles + players[1].gsmRatingDoubles) /
                  2) *
                  100
              );
              return createTeamsTable(
                [
                  {
                    content: history?.rank || 0,
                    editable: false,
                  },
                  { content: teamName, editable: false },
                  { content: email, editable: false },
                  { content: phone, editable: false },
                  {
                    content: track.division.division,
                    editable: false,
                  },
                  { content: track.division.level, editable: false },
                  { content: track.track.track + 1, editable: false },
                  { content: history?.matchWins || 0, editable: false },
                  { content: history?.matchLosses || 0, editable: false },
                ],
                team,
                email,
                phone,
                history?.rank || 0,
                teamName,
                track.division.division,
                track.division.level,
                track.track.track + 1,
                history?.matchWins || 0,
                history?.matchLosses || 0,
                track.track.id,
                avgTeamRating
              );
            });
        }
        return track?.players?.map((player) => {
          const pName = `${player?.firstName} ${player?.lastName}${
            player.withdrawn ? " - Withdrawn" : ""
          }`;
          const phone = parsePhoneNumber(
            player.phoneNumber,
            "US"
          ).formatNational();

          let history = handlePlayerHistory(player, 'leaguesJoined', track.track.id);

          return createPlayersTable(
            [
              {
                content: history?.rank || 0,
                editable: false,
              },
              { content: pName, editable: false },
              { content: player.email, editable: false },
              { content: phone, editable: false },
              {
                content: track.division.division,
                editable: false,
              },
              { content: track.division.level, editable: false },
              { content: track.track.track + 1, editable: false },
              { content: history?.matchWins || 0, editable: false },
              { content: history?.matchLosses || 0, editable: false },
            ],
            player,
            player.email,
            phone,
            history?.rank || 0,
            pName,
            track.division.division,
            track.division.level,
            track.track.track + 1,
            history?.matchWins || 0,
            history?.matchLosses || 0,
            track.track.id
          );
        });
      })
    );
    setLoading(false);
  }, [league, tracks]);
  const handleComponentEdit = (rowSelected) => {
    let currTrack = tracks[pageNum];
    let currDivision = currTrack.division;
    if (currDivision.doublesFormat) {
      setSelectedTeam(rowSelected);
      let divisionSelection = [];
      Object.keys(divisions).forEach((key) => {
        let division = divisions[key];
        if (
          division.doublesFormat &&
          division.gender === rowSelected.team.players[0].gender
        )
          divisionSelection.push({
            value: key,
            label: `${division.division}: Level ${division.level}`,
          });
      });
      setDivisionSelection(divisionSelection);
      setTrackSelection(
        tracks
          .filter((track) => track.division.id === currDivision.id)
          .map((track) => {
            return {
              value: track.track.id,
              label: `Track ${track.track.track + 1}: (${
                track.track.doublesTeams.length
              } Current Teams)`,
            };
          })
      );
    } else {
      setSelectedPlayer(rowSelected.player);
      let divisionSelection = [];
      Object.keys(divisions).forEach((key) => {
        let division = divisions[key];
        if (!division.doublesFormat)
          divisionSelection.push({
            value: key,
            label: `${division.division}: Level ${division.level}`,
          });
      });
      setDivisionSelection(divisionSelection);
      setTrackSelection(
        tracks
          .filter((track) => track.division.id === currDivision.id)
          .map((track) => {
            return {
              value: track.track.id,
              label: `Track ${track.track.track + 1}: (${
                track.track.players.length
              } Current Players)`,
            };
          })
      );
    }
    setSelectedDivision(currDivision.id);
    setSelectedTrack(currTrack.track.id);
    setOpenEdit(true);
  };
  const handleOpenAddTrack = () => {
    setSelectedDivision(tracks[0].division.id);
    setDivisionSelection(
      Object.keys(divisions).map((key) => {
        let division = divisions[key];
        return {
          value: key,
          label: `${division.division}: Level ${division.level}`,
        };
      })
    );
    let addTrackPlayerList = [];
    let addTrackTeamsList = [];
    players.forEach((track, index) => {
      if (tracks[index].division.doublesFormat)
        addTrackTeamsList = [
          ...addTrackTeamsList,
          ...track.map((team) => {
            return {
              label: team.teamName,
              check: false,
              trackID: team.trackID,
              team: team.team,
            };
          }),
        ];
      else
        addTrackPlayerList = [
          ...addTrackPlayerList,
          ...track.map((player) => {
            return {
              label: player.playerName,
              check: false,
              trackID: player.trackID,
              player: player.player,
            };
          }),
        ];
    });
    setAddTrackTeamList(addTrackTeamsList);
    setAddTrackPlayerList(addTrackPlayerList);
    setOpenAddTrack(true);
  };
  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  return (
    <div className="column filler">
      <div className="rowCenter">
        <div className="tabsWidthWrapper">
          <CustomTabs
            labels={pageLabels}
            value={pageNum}
            setValue={setPageNum}
          />
        </div>
        <div className="filler" />
        {!isPublic && <Button
            className="directorAddTrackButton"
            variant="outlined"
            onClick={handleOpenAddTrack}
            disabled={!canEditTracks}
        >
          <Typography className="profileSaveButtonText">Add Track</Typography>
        </Button>}
        <DirectorManageTrackForms
          isPickleball={isPickleball}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          openAddTrack={openAddTrack}
          setOpenAddTrack={setOpenAddTrack}
          selectedPlayer={selectedPlayer}
          setSelectedPlayer={setSelectedPlayer}
          selectedTrack={selectedTrack}
          setSelectedTrack={setSelectedTrack}
          addTrackPlayerList={addTrackPlayerList}
          setAddTrackPlayerList={setAddTrackPlayerList}
          addTrackTeamList={addTrackTeamList}
          setAddTrackTeamList={setAddTrackTeamList}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          selectedDivision={selectedDivision}
          setSelectedDivision={setSelectedDivision}
          divisionSelection={divisionSelection}
          setDivisionSelection={setDivisionSelection}
          trackSelection={trackSelection}
          setTrackSelection={setTrackSelection}
          tracks={tracks}
          league={league}
          pageNum={pageNum}
          divisions={divisions}
          canEditTracks={canEditTracks}
        />
      </div>
      <div className="column filler">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {players.map((playerList, key) => (
              <Fragment key={key}>
                {key === pageNum && (
                  <TableComponent
                    list={playerList || []}
                    nestedList={players}
                    setList={setPlayers}
                    tableHeader={
                      tracks?.[key]?.division?.doublesFormat
                        ? doublesHeader
                        : singlesHeader
                    }
                    type={!isPublic ? "componentEdit" : "viewOnly"}
                    handleComponentEdit={handleComponentEdit}
                    rowsPerPageOverride={10}
                    tableNum={pageNum}
                  />
                )}
              </Fragment>
            ))}
          </Grid>
        </Grid>
        <div className="filler" />
      </div>
    </div>
  );
};

export default DirectorLeaguePlayers;

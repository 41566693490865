import React, { useState, useRef, useEffect, Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import Info from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import parsePhoneNumber from "libphonenumber-js";
import xlsxParser from "xlsx-parse-json";
import { states } from "../../providers/States";
import { firestore, storage, Firebase, functions } from "../../firebase";
import { CustomTabs } from "../../components/CustomTabs";
import InformationDialogPDF from "../../components/InformationDialogPDF";
import {
  DefaultTextField,
  DisplayTextField,
  MenuTextField,
  BirthdayTextField,
  PhoneTextField,
} from "../../components/CustomTextField";

const ntrpSelection = [
  { label: "1.5", value: 1.5 },
  { label: "2.0", value: 2.0 },
  { label: "2.5", value: 2.5 },
  { label: "3.0", value: 3.0 },
  { label: "3.5", value: 3.5 },
  { label: "4.0", value: 4.0 },
  { label: "4.5", value: 4.5 },
  { label: "5.0", value: 5.0 },
  { label: "5.5", value: 5.5 },
  { label: "6.0", value: 6.0 },
  { label: "6.5", value: 6.5 },
  { label: "7.0", value: 7.0 },
];

const Information = ({ user, zoneList, mobileSize }) => {
  const [enableSave, setEnableSave] = useState(false);
  const didMountRef = useRef(false);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [phoneNumber, setPhoneNumber] = useState(
    user.phoneNumber
      ? parsePhoneNumber(user.phoneNumber, "US").formatNational()
      : ""
  );
  const [gsmRankURL, setGsmRankURL] = useState("");
  const [gender, setGender] = useState(user.gender);
  const [city, setCity] = useState(user.homeAddress.city);
  const [state, setState] = useState(user.homeAddress.state);
  const [addressLine1, setAddressLine1] = useState(
    user.homeAddress.addressLine1
  );
  const [zipCode, setZipCode] = useState(user.homeAddress.zipCode);
  const [playZipCode, setPlayZipCode] = useState(user.playLocation.zipCode);
  const [homeCourt, setHomeCourt] = useState(user.court);
  const [homeCourtFile, setHomeCourtFile] = useState(null);
  const [homeCourtList, setHomeCourtList] = useState(null);
  const [birthday, setBirthday] = useState(
    user.birthday !== "" ? user.birthday : null
  );
  const [ntrpRating, setNtrpRating] = useState(user.ntrpRating);
  const [utrRating, setUtrRating] = useState(user.utrRating);
  const [pageNum, setPageNum] = useState(0);
  const gsmRating = Math.round(user.gsmRating * 100);
  const gsmRatingDoubles = Math.round(user.gsmRatingDoubles * 100);
  const isPickleball = user?.currentSport === "pickleball";

  const pageLabels = ["Basic Information", "Locations", "Ratings"];
  useEffect(() => {
    if (didMountRef.current) setEnableSave(true);
    else {
      storage
        .ref()
        .child(`adminDocuments/GSMRating.pdf`)
        .getDownloadURL()
        .then((url) => {
          setGsmRankURL(url);
        });
      storage
        .ref()
        .child(`adminDocuments/courtlocations.xlsx`)
        .getDownloadURL()
        .then(async (url) => {
          let file = null;
          await fetch(url)
            .then((res) => res.blob())
            .then((blob) => {
              file = blob;
            });
          setHomeCourtFile(file);
          xlsxParser.onFileSelection(file).then((data) => {
            setHomeCourtList([
              { label: "No Home Court", value: "No Home Court" },
              ...data.Sheet1.filter(
                (court) =>
                  court.Locale === user.playLocation.locale &&
                  court.State === user.homeAddress.state
              ).map((court) => {
                court.label = `${court["Tennis Court Name"]}: ${court.Address1}`;
                court.value = court["Tennis Court Name"];
                return court;
              }),
            ]);
          });
        });
      didMountRef.current = true;
    }
    // eslint-disable-next-line
  }, [
    firstName,
    lastName,
    email,
    phoneNumber,
    gender,
    homeCourt,
    city,
    state,
    addressLine1,
    zipCode,
    ntrpRating,
    utrRating,
    birthday,
    playZipCode,
  ]);

  useEffect(() => {
    setNtrpRating(isPickleball ? user?.pb_utprRating : user?.ntrpRating);
  }, [isPickleball]);

  const updateUser = async (e) => {
    e.preventDefault();
    let complete = false;
    if (
      city !== "" &&
      state !== "" &&
      homeCourt !== "" &&
      birthday !== "" &&
      utrRating !== 0 &&
      ntrpRating !== 0 &&
      zipCode !== "" &&
      addressLine1 !== "" &&
      !user.completedInformation
    ) {
      complete = true;
    }
    let result = user.completedInformation
      ? user.completedInformation
      : complete;
    let profileComplete = user.completedProfile
      ? user.completedProfile
      : user.completedPreferences && complete;
    if (user.completedPreferences && complete) {
      await firestore.collection("activities").add({
        date: Firebase.firestore.FieldValue.serverTimestamp(),
        category: "profileComplete",
        players: [user.uid],
        sportType: "both",
        likes: [],
        imageURLS: [],
        description: `${user.firstName} ${user.lastName} completed their profile!`,
      });
    }
    const playLocation = findPlayLocation();
    xlsxParser.onFileSelection(homeCourtFile).then((data) => {
      setHomeCourtList([
        { label: "No Home Court", value: "No Home Court" },
        ...data.Sheet1.filter(
          (court) =>
            court.Locale === playLocation.locale && court.State === state
        ).map((court) => {
          court.label = `${court["Tennis Court Name"]}: ${court.Address1}`;
          court.value = court["Tennis Court Name"];
          return court;
        }),
      ]);
    });
    let fName = firstName; //.replaceAll(/[^A-Za-z]/g, "");
    let lName = lastName; //.replaceAll(/[^A-Za-z]/g, "");
    await firestore.doc(`users/${user.uid}`).update({
      firstName: fName,
      lastName: lName,
      fullName: fName.toLowerCase() + lName.toLowerCase(),
      email: email,
      phoneNumber: parsePhoneNumber(phoneNumber, "US").number,
      gender: gender,
      court: homeCourt,
      homeAddress: {
        addressLine1: addressLine1,
        addressLine2: "",
        city: city,
        state: state,
        zipCode: zipCode,
      },
      ntrpRating: ntrpRating,
      utrRating: utrRating,
      birthday: birthday,
      completedInformation: result,
      completedProfile: profileComplete,
      playLocation: playLocation,
      playAreaID: playLocation.areaID,
      playLocale: playLocation.locale,
      playSectionals: playLocation.sectionals,
    });
    try {
      const updateUserData = functions.httpsCallable('webhookProcessActionUpdateUserLeague');
      updateUserData({ userID: user.uid });
    } catch (error) {
      console.log('webhookProcessActionUpdateUserLeague', error);
    }
    setEnableSave(false);
  };
  const findPlayLocation = () => {
    let found = false;
    let playLocation = {};
    zoneList.forEach((location) => {
      if (location.zipCode === playZipCode) {
        playLocation = location;
        found = true;
      }
    });
    if (!found) {
      playLocation = zoneList.find((location) => location.areaID === "OT-0001");
      playLocation.zipCode = playZipCode;
    }
    if (playLocation.description === undefined) playLocation.description = "";
    return playLocation;
  };
  return (
    <div className="column filler">
      <CustomTabs labels={pageLabels} value={pageNum} setValue={setPageNum} />
      <form className="column filler" onSubmit={updateUser}>
        <Grid container spacing={mobileSize ? 3 : 6}>
          {pageNum === 0 && (
            <Fragment>
              <Grid xs={12} item>
                <div className="rowCenter">
                  <div className="columnAlignCenter">
                    <Typography className="profileBoldFieldText">
                      Profile Photo
                    </Typography>
                    <Avatar
                      className="image150"
                      variant="rounded"
                      src={user.imageURL}
                    />
                    <input
                      type="file"
                      hidden
                      id="imageUpload"
                      onChange={async (e) => {
                        let getURL = "";
                        await storage
                          .ref(`userDocuments/${user.uid}/profilePhoto`)
                          .put(e.target.files[0]);
                        await storage
                          .ref()
                          .child(`userDocuments/${user.uid}/profilePhoto`)
                          .getDownloadURL()
                          .then((url) => {
                            getURL = url;
                          });
                        await firestore.doc(`users/${user.uid}`).update({
                          imageURL: getURL,
                          uploadedImage: true,
                        });
                        try {
                  const updateUserData = functions.httpsCallable(
                    'webhookProcessActionUpdateUserLeague'
                  );
                  updateUserData({ userID: user.uid });
                } catch (error) {
                  console.log('webhookProcessActionUpdateUserLeague', error);
                }
                        if (!user.uploadedImage) {
                          await firestore.collection("activities").add({
                            date: Firebase.firestore.FieldValue.serverTimestamp(),
                            category: "profilePhoto",
                            likes: [],
                            sportType: "both",
                            imageURLS: [],
                            players: [user.uid],
                            description: `${user.firstName} ${user.lastName} uploaded a profile image!`,
                          });
                        }
                      }}
                    />
                    <label className="maxWidth" htmlFor="imageUpload">
                      <Button component="span" variant="text">
                        <Typography className="profileHighlightFieldText">
                          Change Profile Photo
                        </Typography>
                      </Button>
                    </label>
                  </div>
                </div>
              </Grid>
              <Grid xs={12} md={6} lg={6} xl={6} item>
                <Typography className="profileRequiredFieldText">
                  First Name
                </Typography>
                <DefaultTextField
                  state={firstName}
                  setState={setFirstName}
                  placeholder="First Name"
                  required={true}
                />
                <div className="rowCenter">
                  <Typography className="profileRequiredFieldText margin10Top">
                    Email
                  </Typography>
                  <div className="filler" />
                  <Tooltip title="Email Management is found under manage account.">
                    <IconButton onClick={() => { }}>
                      <Info className="signUpIcon" />
                    </IconButton>
                  </Tooltip>
                </div>
                <DefaultTextField
                  state={email}
                  setState={setEmail}
                  placeholder="Email"
                  required={true}
                  disabled
                />
                <Typography className="profileOptionalFieldText margin10Top">
                  Date of Birth
                </Typography>
                <BirthdayTextField state={birthday} setState={setBirthday} />
              </Grid>
              <Grid xs={12} md={6} lg={6} xl={6} item>
                <Typography className="profileRequiredFieldText">
                  Last Name
                </Typography>
                <DefaultTextField
                  state={lastName}
                  setState={setLastName}
                  placeholder="Last Name"
                  required={true}
                />
                <Typography className="profileRequiredFieldText margin10Top">
                  Phone Number
                </Typography>
                <PhoneTextField
                  state={phoneNumber}
                  setState={setPhoneNumber}
                  placeholder="Phone Number"
                  required={true}
                />
                <Typography className="profileOptionalFieldText margin10Top">
                  Gender
                </Typography>
                <FormControl className="rowCenter">
                  <RadioGroup
                    className="rowCenter"
                    value={gender}
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="female"
                      control={<Radio color="default" />}
                      label="Female"
                    />
                    <div className="filler" />
                    <FormControlLabel
                      value="male"
                      control={<Radio color="default" />}
                      label="Male"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Fragment>
          )}
          {pageNum === 1 && (
            <Fragment>
              <Grid xs={12} item>
                <Typography className="profileSubpageTitle margin20Bottom">
                  Zone Designation
                </Typography>
                <Typography className="profileRequiredFieldText margin10Top">
                  Zip Code Designator
                </Typography>
                <DefaultTextField
                  state={playZipCode}
                  setState={setPlayZipCode}
                  required={true}
                  placeholder="ZIP Code"
                />
                <Typography className="profileRequiredFieldText margin10Top">
                  Section
                </Typography>
                <DisplayTextField value={user.playLocation.sectionals} />
                <Typography className="profileRequiredFieldText margin10Top">
                  Locale (Metro Area)
                </Typography>
                <DisplayTextField value={user.playLocation.locale} />
                <Typography className="profileRequiredFieldText margin10Top">
                  Area
                </Typography>
                <DisplayTextField value={user.playLocation.area} />
                <Typography className="profileRequiredFieldText margin10Top">
                  Home Court
                </Typography>
                <MenuTextField
                  menuSelection={homeCourtList}
                  state={homeCourt}
                  setState={setHomeCourt}
                />
                <Divider className="margin20Top margin20Bottom" />
                <Typography className="profileSubpageTitle margin20Bottom">
                  Mailing Address
                </Typography>
                <Typography className="profileRequiredFieldText">
                  Address Line 1
                </Typography>
                <DefaultTextField
                  state={addressLine1}
                  setState={setAddressLine1}
                  required={false}
                  placeholder="Address Line 1"
                />
                <Typography className="profileRequiredFieldText  margin10Top">
                  City
                </Typography>
                <DefaultTextField
                  state={city}
                  setState={setCity}
                  required={false}
                  placeholder="City"
                />
                <Typography className="profileRequiredFieldText margin10Top">
                  State
                </Typography>
                <MenuTextField
                  menuSelection={states}
                  state={state}
                  setState={setState}
                  required={false}
                  placeholder="State"
                />
                <Typography className="profileRequiredFieldText margin10Top">
                  Zip Code
                </Typography>
                <DefaultTextField
                  state={zipCode}
                  setState={setZipCode}
                  required={false}
                  placeholder="ZIP Code"
                />
              </Grid>
            </Fragment>
          )}
          {pageNum === 2 && (
            <Fragment>
              <Grid xs={12} item>
                <Typography className="profileOptionalFieldText">
                  UTR Rating - Feature Not Yet Available
                </Typography>
                <DefaultTextField
                  state={utrRating}
                  setState={setUtrRating}
                  required={false}
                  placeholder="3.5"
                  disabled
                />
                <Typography className="profileOptionalFieldText margin10Top">
                  {isPickleball ? `UTPR Rating` : `NTRP Rating`}
                </Typography>
                <MenuTextField
                  menuSelection={ntrpSelection}
                  state={ntrpRating}
                  setState={setNtrpRating}
                  required={false}
                  placeholder="3.5"
                />
                <div className="rowCenter">
                  <Typography className="profileRequiredFieldText margin10Top">
                    Current {isPickleball ? `GSM-PB` : `GSM`} Singles Rating (Changes
                    based on your singles performance)
                  </Typography>
                  <InformationDialogPDF pdf={gsmRankURL} />
                </div>
                <DefaultTextField
                  state={gsmRating}
                  required={false}
                  placeholder="3.5"
                  disabled={true}
                />
                <div className="rowCenter">
                  <Typography className="profileRequiredFieldText margin10Top">
                    Current {isPickleball ? `GSM-PB` : `GSM`} Doubles Rating
                    (Changes based on your doubles performance)
                  </Typography>
                  <InformationDialogPDF pdf={gsmRankURL} />
                </div>
                <DefaultTextField
                  state={gsmRatingDoubles}
                  required={false}
                  placeholder="3.5"
                  disabled={true}
                />
              </Grid>
            </Fragment>
          )}
        </Grid>
        <div className="filler" />
        <Divider className="margin20Vertical" />
        <div className="rowCenter profileSaveButtonPlaceholder">
          <div className="filler" />
          <Button
            className="profileSaveButton"
            variant="outlined"
            type="submit"
            disabled={!enableSave}
            style={{
              border: enableSave ? "1px solid #f70909" : "1px solid #55b56a",
            }}
          >
            {enableSave ? (
              <Typography className="profileUpdateButtonText">
                Update
              </Typography>
            ) : (
              <Typography className="profileSaveButtonText">Saved</Typography>
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Information;

import React, { useState, Fragment, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import update from "immutability-helper";
import orderby from "lodash.orderby";
import {
  SortableCell,
  EditableCell,
  LineEditCell,
  ComponentEditCell,
  CustomCell,
  CustomSortableCell,
} from "./TableCells";
import { LoadMore, DefaultPagination } from "./Pagination";

export const LinkCell = ({ cell, handleSendMail, disable }) => {
  return (
    <TableCell align="left">
      <IconButton
        disabled={disable}
        onClick={handleSendMail}
        className="linkbutton"
      >
        {cell.content}
      </IconButton>
    </TableCell>
  );
};

const TableComponent = ({
  isStartDisable,
  list,
  nestedList,
  setList,
  tableHeader,
  handleComponentEdit,
  type,
  paginationType,
  rowsPerPageOverride,
  defaultSort,
  desc,
  tableNum,
  isShowAll,
  isFromDashBoard,
  handleSendMail,
  handleDelete,
  handleEdit,
  handleStart,
  disable,
  redirectModeratedScreen,
}) => {
  const [page, setPage] = useState(paginationType === "loadMore" ? 1 : 0);
  const [rowsPerPage, setRowsPerPage] = useState(
    rowsPerPageOverride !== undefined ? rowsPerPageOverride : 5
  );
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortSelected, setSortSelected] = useState(
    defaultSort ? defaultSort : "name"
  );
  const DefaultCell = ({ cell }) => {
    return <TableCell align="left">{cell.content}</TableCell>;
  };

  useEffect(() => {
    setRowsPerPage(isShowAll ? list.length || 0 : 5);
  }, [isShowAll]);

  //Sorts the list by the specified column
  const handleSort = (sort) => {
    let directionTemp = sortDirection === "asc" ? "desc" : "asc";
    setSortSelected(sort);
    setSortDirection(directionTemp);
    if (tableNum !== undefined) {
      if (nestedList?.[0]?.subList !== undefined)
        setList(
          update(nestedList, {
            [tableNum]: {
              subList: { $set: orderby(list, sort, directionTemp) },
            },
          })
        );
      else
        setList(
          update(nestedList, {
            [tableNum]: {
              $set: orderby(list, sort, directionTemp),
            },
          })
        );
    } else {
      setList(orderby(list, sort, directionTemp));
    }
  };
  let displayList = [];
  if (paginationType === undefined || paginationType === "muiTools")
    displayList = list.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  if (paginationType === "loadMore")
    displayList = list.slice(0, page * rowsPerPage);
  const emptyRows =
    paginationType === "loadMore"
      ? 0
      : rowsPerPage - Math.min(rowsPerPage, list.length - page * rowsPerPage);
  return (
    <div className="columnCenter">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {!(type === "viewOnly") && (
                <TableCell align="left">Actions</TableCell>
              )}
              {tableHeader.map((cell, key) =>
                cell.sortable ? (
                  isFromDashBoard ? (
                    <CustomSortableCell
                      key={key}
                      cell={cell}
                      handleSort={handleSort}
                      sortSelected={sortSelected}
                      sortDirection={sortDirection}
                    />
                  ) : (
                    <SortableCell
                      key={key}
                      cell={cell}
                      handleSort={handleSort}
                      sortSelected={sortSelected}
                      sortDirection={sortDirection}
                    />
                  )
                ) : (
                  <DefaultCell cell={cell} />
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayList.map((row, i) => (
              <TableRow hover key={i}>
                <Fragment>
                  {type === "lineEdit" && (
                    <LineEditCell
                      isStartDisable={isStartDisable}
                      row={row}
                      handleDelete={(row) => handleDelete(row)}
                      handleEdit={(row) => handleEdit(row)}
                      handleStart={(row) => handleStart(row)}
                      redirectModeratedScreen={(row) =>
                        redirectModeratedScreen(row)
                      }
                    />
                  )}
                  {type === "componentEdit" && (
                    <ComponentEditCell
                      row={row}
                      handleComponentEdit={handleComponentEdit}
                    />
                  )}
                  {row?.cells.map((cell, key) =>
                    cell?.editable ? (
                      <EditableCell key={key} cell={cell} />
                    ) : isFromDashBoard ? (
                      <CustomCell key={key} cell={cell} />
                    ) : cell.isButton ? (
                      <LinkCell
                        handleSendMail={() => handleSendMail(cell.mailData)}
                        key={key}
                        cell={cell}
                        disable={disable}
                      />
                    ) : (
                      <DefaultCell key={key} cell={cell} />
                    )
                  )}
                </Fragment>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={tableHeader.length} />
              </TableRow>
            )}
          </TableBody>
          {(paginationType === undefined || paginationType === "muiTools") &&
            !isFromDashBoard && (
              <DefaultPagination
                tableHeader={tableHeader}
                list={list}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                colSpan={
                  type === "viewOnly"
                    ? tableHeader.length
                    : tableHeader.length + 1
                }
              />
            )}
        </Table>
      </TableContainer>

      {paginationType === "loadMore" && page * rowsPerPage < list.length && (
        <LoadMore setValue={setPage} />
      )}
    </div>
  );
};

export default TableComponent;

import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid"
import Mail from "@material-ui/icons/Mail";
import Lock from "@material-ui/icons/Lock";
import FacebookRoundedIcon from "@material-ui/icons/Facebook";
import Alert from "@material-ui/lab/Alert";
import windowSize from "react-window-size";
import { Redirect } from "react-router-dom";
import ResetPasswordForm from "./ResetPasswordForm";
import { GSMLogo, PlayerIcon } from "../../res/SVGIcons";
import { auth, Firebase, firestore } from "../../firebase";
import { parsePhoneNumber } from "libphonenumber-js";
import CircularLoader from "../../components/CircularLoader";

const Landing = ({ user, windowWidth }) => {
  const quotes = [
    {
      quote: "There is no way around the hard work. Embrace it.",
      author: "Roger Federer",
    },
    {
      quote:
        "Golfers are forever working on mechanics. My tennis swing hasn't changed in 10 years.",
      author: "Pete Sampras",
    },
    {
      quote: "The next point - That's all you must think about.",
      author: "Rod Laver",
    },
  ];
  const pageText = [
    {
      headerSubtitle: "Existing User",
      header: "LOG IN",
      buttonText: "Log In",
      lowerTextSubtitle: "New member?",
      lowerTextAction: "SIGN UP",
    },
    {
      headerSubtitle: "New Member",
      header: "SIGN UP",
      buttonText: "Sign Up",
      lowerTextSubtitle: "Coming back?",
      lowerTextAction: "LOG IN",
    },
  ];
  const [quote] = useState(quotes[Math.floor(Math.random() * quotes.length)]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [revealPassword, setRevealPassword] = useState("password");
  const signUpMode = 0;
  const [signUpRedirect, setSignUpRedirect] = useState(false);
  const [openPasswordReset, setOpenPasswordReset] = useState(false);
  const [firebaseError, setFirebaseError] = useState("");
  const [firebaseErrorBool, setFirebaseErrorBool] = useState(false);
  const [snackBarToggle, setSnackBarToggle] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = window.location;
  const urlObject = {
    search: location.search,
  };

  if (user && user.active) {
    if(urlObject.search) {
      return <Redirect to={{ pathname: "/DirectorApplication" }}></Redirect>;
    }else{
      return <Redirect to={{ pathname: "/Dashboard" }}></Redirect>;
    }
  }
  if (signUpRedirect) {
    return (
      <Redirect
        to={{
          pathname: "/SignUp",
        }}
      />
    );
  }
  const handleSignIn = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      await auth.signInWithEmailAndPassword(email, password).catch((error) => {
        setFirebaseErrorBool(true);
        setFirebaseError(error.message);
      });
    } catch (error) {
      console.log("Signin", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSignUp = () => {
    setSignUpRedirect(true);
  };
  const signInWithGoogle = async () => {
    try {
      const googleProvider = new Firebase.auth.GoogleAuthProvider();
      const res = await auth.signInWithPopup(googleProvider);
      if (res) {
        const q = await firestore.doc(`users/${res?.user?.uid}`).get();
        const docs = q.data();
        if (docs === undefined) {
          await firestore
            .collection("users")
            .doc(res?.user?.uid)
            .set({
              activeCampaignID: -1,
              email: res?.additionalUserInfo?.profile?.email,
              firstName: res?.additionalUserInfo?.profile?.given_name,
              lastName: res?.additionalUserInfo?.profile?.family_name,
              imageURL: res?.user?.photoURL,
                court: "No Home Court",
                phoneNumber: res?.user?.phoneNumber
                    ? parsePhoneNumber(res?.user?.phoneNumber, "US").number
                    : null,
                homeAddress: {
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    state: "",
                    zipCode: "",
                },
                ntrpRating: 1.5,
                pb_utprRating: 1.5,
                utrRating: 0,
                playLocation: {
                    area: "Placeholder ",
                    areaID: "OT-0001",
                    description: "",
                    district: "Other",
                    locale: "Other",
                    neighborhood: "Other",
                    sectionals: "Other",
                    zipCode: "",
                },
                playAreaID: "OT-0001",
                playLocale: "Other",
                playSectionals: "Other",
                admin: false,
                director: false,
                active: true,
                verified: true,
                dateCreated: Firebase.firestore.FieldValue.serverTimestamp(),
                leaguesJoined: [],
                pb_leaguesJoined: [],
                pb_totalMatches: 0,
                pb_totalMatchWins: 0,
                pb_totalMatchLosses: 0,
                pb_totalGameWins: 0,
                pb_totalGameLosses: 0,
                pb_totalSetWins: 0,
                pb_totalSetLosses: 0,
                pb_totalMatchesAbandoned: 0,
                pb_totalDoublesMatches: 0,
                pb_totalDoublesMatchWins: 0,
                pb_totalDoublesMatchLosses: 0,
                pb_totalDoublesSetWins: 0,
                pb_totalDoublesSetLosses: 0,
                pb_totalDoublesGameWins: 0,
                pb_totalDoublesGameLosses: 0,
                pb_totalDoublesMatchesAbandoned: 0,
                totalMatches: 0,
                totalMatchWins: 0,
                totalMatchLosses: 0,
                totalGameWins: 0,
                totalGameLosses: 0,
                totalSetWins: 0,
                totalSetLosses: 0,
                totalMatchesAbandoned: 0,
                totalDoublesMatches: 0,
                totalDoublesMatchWins: 0,
                totalDoublesMatchLosses: 0,
                totalDoublesSetWins: 0,
                totalDoublesSetLosses: 0,
                totalDoublesGameWins: 0,
                totalDoublesGameLosses: 0,
                totalDoublesMatchesAbandoned: 0,
                doublesSearch: true,
                uploadedImage: false,
                completedProfile: false,
                completedPreferences: false,
                completedInformation: false,
                currentSport: 'tennis',
                matchPoints: 0,
                pointsUsed: 0,
                gsmRating: 1,
                pb_gsmRating: 1,
                gsmRatingDoubles: 1,
                pb_gsmRatingDoubles: 1,
                couponsUsed: [],
                isBothSportCheckout: false,
                referrals: [],
                gender: "",
                surface: "No Preference",
                birthday: null,
                dayPref: [
                    { label: "Monday", check: false },
                    { label: "Tuesday", check: false },
                    { label: "Wednesday", check: false },
                    { label: "Thursday", check: false },
                    { label: "Friday", check: false },
                    { label: "Saturday", check: false },
                    { label: "Sunday", check: false },
                ],
                timePref: [
                    { label: "Early Morning", check: false },
                    { label: "Morning", check: false },
                    { label: "Afternoon", check: false },
                    { label: "Evening", check: false },
                ],
            });
        }
      }
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };
  const signInWithFacebook = async () => {
    try {
        var facebookProvider = new Firebase.auth.FacebookAuthProvider();
        const res = await auth.signInWithPopup(facebookProvider);
        if (res) {
            const q = await firestore.doc(`users/${res?.user?.uid}`).get();
            const docs = q.data();
            if (docs === undefined) {
                await firestore
                    .collection("users")
                    .doc(res?.user?.uid)
                    .set({
                        activeCampaignID: -1,
                        email: res?.additionalUserInfo?.profile?.email,
                        firstName: res?.additionalUserInfo?.profile?.first_name,
                        lastName: res?.additionalUserInfo?.profile?.last_name,
                        imageURL: res?.user?.photoURL,
                        court: "No Home Court",
                        phoneNumber: res?.user?.phoneNumber
                            ? parsePhoneNumber(res?.user?.phoneNumber, "US").number
                            : null,
                        homeAddress: {
                            addressLine1: "",
                            addressLine2: "",
                            city: "",
                            state: "",
                            zipCode: "",
                        },
                        ntrpRating: 1.5,
                        pb_utprRating: 1.5,
                        utrRating: 0,
                        playLocation: {
                            area: "Placeholder ",
                            areaID: "OT-0001",
                            description: "",
                            district: "Other",
                            locale: "Other",
                            neighborhood: "Other",
                            sectionals: "Other",
                            zipCode: "",
                        },
                        playAreaID: "OT-0001",
                        playLocale: "Other",
                        playSectionals: "Other",
                        admin: false,
                        director: false,
                        active: true,
                        verified: true,
                        dateCreated: Firebase.firestore.FieldValue.serverTimestamp(),
                        leaguesJoined: [],
                        pb_leaguesJoined: [],
                        pb_totalMatches: 0,
                        pb_totalMatchWins: 0,
                        pb_totalMatchLosses: 0,
                        pb_totalGameWins: 0,
                        pb_totalGameLosses: 0,
                        pb_totalSetWins: 0,
                        pb_totalSetLosses: 0,
                        pb_totalMatchesAbandoned: 0,
                        pb_totalDoublesMatches: 0,
                        pb_totalDoublesMatchWins: 0,
                        pb_totalDoublesMatchLosses: 0,
                        pb_totalDoublesSetWins: 0,
                        pb_totalDoublesSetLosses: 0,
                        pb_totalDoublesGameWins: 0,
                        pb_totalDoublesGameLosses: 0,
                        pb_totalDoublesMatchesAbandoned: 0,
                        totalMatches: 0,
                        totalMatchWins: 0,
                        totalMatchLosses: 0,
                        totalGameWins: 0,
                        totalGameLosses: 0,
                        totalSetWins: 0,
                        totalSetLosses: 0,
                        totalMatchesAbandoned: 0,
                        totalDoublesMatches: 0,
                        totalDoublesMatchWins: 0,
                        totalDoublesMatchLosses: 0,
                        totalDoublesSetWins: 0,
                        totalDoublesSetLosses: 0,
                        totalDoublesGameWins: 0,
                        totalDoublesGameLosses: 0,
                        totalDoublesMatchesAbandoned: 0,
                        doublesSearch: true,
                        uploadedImage: false,
                        completedProfile: false,
                        completedPreferences: false,
                        completedInformation: false,
                        currentSport: 'tennis',
                        matchPoints: 0,
                        pointsUsed: 0,
                        gsmRating: 1,
                        pb_gsmRating: 1,
                        gsmRatingDoubles: 1,
                        pb_gsmRatingDoubles: 1,
                        couponsUsed: [],
                        isBothSportCheckout: false,
                        referrals: [],
                        gender: "",
                        surface: "No Preference",
                        birthday: null,
                        dayPref: [
                            { label: "Monday", check: false },
                            { label: "Tuesday", check: false },
                            { label: "Wednesday", check: false },
                            { label: "Thursday", check: false },
                            { label: "Friday", check: false },
                            { label: "Saturday", check: false },
                            { label: "Sunday", check: false },
                        ],
                        timePref: [
                            { label: "Early Morning", check: false },
                            { label: "Morning", check: false },
                            { label: "Afternoon", check: false },
                            { label: "Evening", check: false },
                        ],
                    });
            }
        }
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  return (
    <div className="page">
      <ResetPasswordForm
        open={openPasswordReset}
        setOpen={setOpenPasswordReset}
        setSnackBarToggle={setSnackBarToggle}
      />
      <Snackbar
        open={snackBarToggle}
        autoHideDuration={5000}
        onClose={(e) => {
          setSnackBarToggle(false);
        }}
      >
        <Alert
          onClose={(e) => {
            setSnackBarToggle(false);
          }}
          severity="success"
        >
          A reset email has been sent to your email!
        </Alert>
      </Snackbar>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12} lg={5} className="pageLeft">
          <div className="rowCenter">
            <div className="landingPageLogoWrapper">
              <a href="https://playgamesetmatch.com">
                <GSMLogo />
              </a>
            </div>
          </div>
          <div className="landingPageQuoteWrapper">
            <Typography className="landingPageQuote">{quote.quote}</Typography>
            <Typography className="landingPageAuthor margin10Top">
              -{quote.author}
            </Typography>
          </div>
          {windowWidth > 1400 && (
            <div className="landingPagePlayerIconWrapper">
              <PlayerIcon />
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={7} className="pageRight">
          <div className="columnCenter">
            <form className="loginContainer" onSubmit={handleSignIn}>
              <Typography className="landingPagePrimarySmallText">
                {pageText[signUpMode].headerSubtitle}
              </Typography>
              <Typography className="landingPageLoginText">
                {pageText[signUpMode].header}
              </Typography>
              <Typography>Email</Typography>
              <TextField
                placeholder="Email"
                autoComplete="email"
                required
                className="loginItem"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Mail className="landingPageTextFieldIcon" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <Typography>Password</Typography>
              <TextField
                placeholder="Password"
                autoComplete="password"
                required
                className="loginItem"
                type={revealPassword}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock className="landingPageTextFieldIcon" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              {firebaseErrorBool ? (
                <Typography variant="subtitle2" className="err">
                  {firebaseError}
                </Typography>
              ) : null}
              <div className="rowCenter">
                <Button
                  variant="text"
                  onClick={() => {
                    setOpenPasswordReset(true);
                  }}
                >
                  <Typography className="landingPageSecondarySmallText ">
                    Forgot Password?
                  </Typography>
                </Button>
                <div className="filler" />
                <Button
                  variant="text"
                  className="margin30LessTop"
                  onClick={() => {
                    setRevealPassword((pass) =>
                      pass === "password" ? "" : "password"
                    );
                  }}
                >
                  <Typography className="landingPageSecondarySmallText ">
                    Show Password
                  </Typography>
                </Button>
              </div>
              <div className="logindiv">
                <button
                  type="button"
                  onClick={signInWithGoogle}
                  className="login-with-google-btn"
                >
                  Sign in with Google
                </button>
                  <Button
                  type="button"
                  onClick={signInWithFacebook}
                  className="login-with-facebook-btn"
                >
                      <FacebookRoundedIcon htmlColor={"#1B74E4"} />
                      Sign in with Facebook
                </Button>
                <Button
                  className="loginButton"
                  type="submit"
                  disabled={loading}
                >
                  {loading && <CircularLoader />}
                  {pageText[signUpMode].header}
                </Button>
              </div>
              <div className="rowCenter margin50Top">
                <Typography className="landingPageNewMemberText">
                  {pageText[signUpMode].lowerTextSubtitle}
                </Typography>
                <Button variant="text" onClick={handleSignUp}>
                  <Typography className="landingPageSignupTextSmall">
                    {pageText[signUpMode].lowerTextAction}
                  </Typography>
                </Button>
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default windowSize(Landing);

import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { OutlinedButton } from "../../../components/CustomButton";
import { firestore, functions } from "../../../firebase";
import moment from "moment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import PlayerDecayAdjuster from "./PlayerDecayAdjuster";

// import { getSiteValues } from "../../functions/firebaseFunctions";
const AdminBackendSupport = ({ user }) => {
  const [functionsUpdateStatus, setFunctionsUpdateStatus] = useState("Call");
  const [weeklyLeagueCampaignStatus, setWeeklyLeagueCampaignStatus] =
    useState("Start");
  const [weeklyActivityCampaignStatus, setWeeklyActivityCampaignStatus] =
    useState("Start");
  const [weeklyPlayoffCampaignStatus, setWeeklyPlayoffCampaignStatus] =
    useState("Start");
  const [updateStatus, setUpdateStatus] = useState("Run Script");
  const [pickleBallSpace, setPickleBallSpace] = useState(true)
  const callCloudFunction = async () => {
    setFunctionsUpdateStatus("RUNNING");
    // const fun = functions.httpsCallable("cronJobs-logActiveCampaignTemplate");
    // await fun({ templateID: "100023" }).catch((error) => console.log(error));
    setFunctionsUpdateStatus("Call");
  };
  const callWeeklyLeagueCampaign = async () => {
    setWeeklyLeagueCampaignStatus("RUNNING");
    const fun = functions.httpsCallable(
      "cronJobs-upcomingLeaguesDailyReminderTest"
    );
    await fun().catch((error) => console.log(error));
    setWeeklyLeagueCampaignStatus("Start");
  };
  const callWeeklyActivityCampaign = async () => {
    setWeeklyActivityCampaignStatus("RUNNING");
    const fun = functions.httpsCallable("cronJobs-weeklyActivityUpdate");
    await fun().catch((error) => console.log(error));
    setWeeklyActivityCampaignStatus("Start");
  };
  const callWeeklyPlayoffCampaign = async () => {
    setWeeklyPlayoffCampaignStatus("RUNNING");
    const fun = functions.httpsCallable("cronJobs-weeklyPlayoffReminder");
    await fun().catch((error) => console.log(error));
    setWeeklyPlayoffCampaignStatus("Start");
  };
  const updateScript = async () => {
    setUpdateStatus("RUNNING");

    // updates activities to include leagueID
    firestore
      .collection("activities")
      .get()
      .then(async (snapshot) => {
        await Promise.all(
          snapshot.docs.map(async (doc) => {
            let data = doc.data();
            let curr = data.category;
            if (
              ["directorMessage", "leagueApproved", "leagueComplete"].some(
                (c) => c === curr
              )
            ) {
              doc.ref.update({
                leagueID: "vmTzQ03AiOSrgje0UitF",
              });
            }
          })
        );
      })
      .then(() => setUpdateStatus("START"));
    // updates scores to have date field
    firestore
      .collection("scores")
      .get()
      .then(async (snapshot) => {
        await Promise.all(
          snapshot.docs.map(async (doc) => {
            let data = doc.data();
            let date = moment(data.matchDate, "MM/DD/YYYY").toDate();
            doc.ref.update({
              date: date,
            });
          })
        );
      })
      .then(() => setUpdateStatus("START"));
    // updates user profile to have activeCampaignID
    firestore
      .collection("users")
      .get()
      .then(async (snapshot) => {
        await Promise.all(
          snapshot.docs.map(async (doc) => {
            doc.ref.update({
              activeCampaignID: -1,
            });
          })
        );
      })
      .then(() => setUpdateStatus("START"));
    setUpdateStatus("Run Script");
  };
  useEffect(() => {
    const switchCase = firestore.collection("admin").doc("siteValues").onSnapshot(doc => {
      setPickleBallSpace(doc.data().switchPickleball);
    });
    return () => {
      switchCase();
    };
  }, [])

  const handlePickleBallSpace = async () => {
    setPickleBallSpace(!pickleBallSpace)
    await firestore
      .collection("admin").doc("siteValues").update(
        { switchPickleball: !pickleBallSpace }
      )
  }
  return (
    <div className="column filler">
      <Typography className="margin10Bottom" variant="h4">
        Backend Tools
      </Typography>
      <Divider />
      <Grid className="margin10Top" container spacing={3}>
        {/* <Grid item xs={6}>
          <Typography className="margin10Bottom" variant="h6">
            Update Database Documents
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <OutlinedButton text={updateStatus} handleProcess={updateScript} />
        </Grid>
        <Grid item xs={6}>
          <Typography className="margin10Bottom" variant="h6">
            Call Cloud Function
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <OutlinedButton
            text={functionsUpdateStatus}
            handleProcess={callCloudFunction}
          />
        </Grid> */}
        <Grid item xs={6}>
          <Typography className="margin10Bottom" variant="h6">
            Weekly League Campaign
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <OutlinedButton
            text={weeklyLeagueCampaignStatus}
            handleProcess={callWeeklyLeagueCampaign}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography className="margin10Bottom" variant="h6">
            Weekly Activity Campaign
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <OutlinedButton
            text={weeklyActivityCampaignStatus}
            handleProcess={callWeeklyActivityCampaign}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography className="margin10Bottom" variant="h6">
            Weekly Playoffs Reminder
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <OutlinedButton
            text={weeklyPlayoffCampaignStatus}
            handleProcess={callWeeklyPlayoffCampaign}
          />
        </Grid>
        <PlayerDecayAdjuster user={user} />
        <Grid item xs={12} className="margin20Top">
          <Typography className="fontWeight700" variant="h5">
            Configuration Items
          </Typography>
          <div className="flex">
            <Typography className="margin20Right" variant="h6">
              Turn On/Off Pickleball Space to User
            </Typography>
            <div>
              <span className="margin10Right">
                {pickleBallSpace ? (
                  "On"
                ) : (
                  "Off"
                )}
              </span>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={pickleBallSpace}
                    onChange={() => handlePickleBallSpace()}
                  />
                }
              />
            </div>
          </div>
        </Grid>
        {/* <Grid item xs={6}>
          <Typography className="margin10Bottom" variant="h6">
            Update Admin Documents
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <OutlinedButton
            text="Upload File"
            handleProcess={() => {}}
            disabled={true}
          />
        </Grid> */}
      </Grid>
    </div>
  );
};

export default AdminBackendSupport;

export const createRankTable = (
    cells,
    rank,
    player,
    matches,
    percentMatches,
    games,
    percentGames,
    sets,
    percentSets,
    rating,
    imageURL,
    id,
    matchWins,
    firstName,
    lastName,
    withdrawn
) => {
    return {
        cells,
        rank,
        player,
        matches,
        percentMatches,
        games,
        percentGames,
        sets,
        percentSets,
        rating,
        imageURL,
        id,
        matchWins,
        firstName,
        lastName,
        withdrawn,
    };
};

export const createModerateRankTable = (
    cells,
    rank,
    doublesTeam,
    matches,
    percentMatches,
    games,
    percentGames,
    sets,
    percentSets,
    rating,
    imageURL1,
    imageURL2,
    matchWins,
    teamName,
    withdrawn
) => {
    return {
        cells,
        rank,
        doublesTeam,
        matches,
        percentMatches,
        games,
        percentGames,
        sets,
        percentSets,
        rating,
        imageURL1,
        imageURL2,
        matchWins,
        teamName,
        withdrawn,
    };
};

export const createMatchTable = (
    cells,
    opponent,
    date,
    location,
    status,
    email,
    phone
) => {
    return {
        cells,
        opponent,
        date,
        location,
        status,
        email,
        phone,
    };
};
export const createModeratedMatchTable = (
    cells,
    team1,
    team2,
    startDate,
    endDate,
    location,
    status
) => {
    return {
        cells,
        team1,
        team2,
        startDate,
        endDate,
        location,
        status,
    };
};

export const cardLabels = [
    "General Information",
    "Players",
    "Match Schedule",
    "Standings",
    "Played Matches",
    "Playoffs",
];

export const moderatedScheduleHeader = [
    { label: "team1", content: "Team 1", sortable: true },
    { label: "team2", content: "Team 2", sortable: true },
    { label: "startDate", content: "Start Date", sortable: true },
    { label: "endDate", content: "End Date", sortable: true },
    { label: "location", content: "Location", sortable: true },
    { label: "status", content: "Status", sortable: true },
];

export const matchScheduleHeader = [
    { label: "opponent", content: "Opponent", sortable: true },
    { label: "date", content: "Match Dates", sortable: true },
    { label: "location", content: "Suggested Location", sortable: true },
    { label: "email", content: "Email", sortable: true },
    { label: "phone", content: "Phone Number", sortable: true },
    { label: "status", content: "Status", sortable: true },
];

export const divisionData = ["Men's Singles", "Women's Singles", "Coed Singles"];

export const playerRecordsHeader =(isPickleball=false)=> {
    return [
        {content: "Rank", label: "rank", sortable: true},
        {content: "Player", label: "player", sortable: true},
        {content: "Matches Win/Loss", label: "matches", sortable: true},
        {content: "% (Matches)", label: "percentMatches", sortable: true},
        {
            content: `${isPickleball ? "Points" : "Games"} Won/Loss`,
            label: "games",
            sortable: true,
        },
        {
            content: `% (${isPickleball ? "Points" : "Games"})`,
            label: "percentGames",
            sortable: true,
        },
        {
            content: `${isPickleball ? "Games" : "Sets"} Won/Loss`,
            label: "sets",
            sortable: true,
        },
        {
            content: `% (${isPickleball ? "Games" : "Sets"})`,
            label: "percentSets",
            sortable: true,
        },
        {content: "GSM Rating", label: "rating", sortable: true},
    ]
};

export const pageMap = {
    Information: 0,
    Players: 1,
    Schedule: 2,
    Standings: 3,
    PlayedMatches: 4,
    Playoffs: 5,
};

export const createSinglesTracksTable = (
    league,
    division,
    track,
    players,
    playoffPlayers,
    scores,
    matches
) => {
    return {
        league,
        division,
        track,
        players,
        playoffPlayers,
        scores,
        matches,
    };
};

export const createModeratedTracksTable = (
    league,
    division,
    track,
    players,
    playoffPlayers,
    scores,
    matches,
    teams
) => {
    return {
        league,
        division,
        track,
        players,
        playoffPlayers,
        scores,
        matches,
        teams,
    };
};

export const createDoublesTracksTable = (
    league,
    division,
    track,
    teams,
    playoffTeams,
    scores,
    matches
) => {
    return {
        league,
        division,
        track,
        teams,
        playoffTeams,
        scores,
        matches,
    };
};

import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CheckboxList from "../../../components/CheckboxList";
const InfoSection = ({ title, children }) => {
  return (
    <Grid className="margin10LessTop" xs={12} item>
      <Typography className="createLeagueFieldText">{title}</Typography>
      <Grid className="padding10 margin20LessTop" container spacing={6}>
        {children}
      </Grid>
    </Grid>
  );
};
const ViewPlayerInfo = ({ player }) => {
  const dayPref = player.dayPref
    ? player.dayPref
    : [
        { label: "Monday", check: false },
        { label: "Tuesday", check: false },
        { label: "Wednesday", check: false },
        { label: "Thursday", check: false },
        { label: "Friday", check: false },
        { label: "Saturday", check: false },
        { label: "Sunday", check: false },
      ];

  const timePref = player.timePref
    ? player.timePref
    : [
        { label: "Early Morning", check: false },
        { label: "Morning", check: false },
        { label: "Afternoon", check: false },
        { label: "Evening", check: false },
      ];

  return (
    <Grid container spacing={6}>
      <InfoSection title="General Information">
        <Grid xs={12} item>
          <Typography className="viewPlayerInfoLabel">About Me</Typography>
          <Typography className="viewPlayerAboutText">
            {player.aboutMe}
          </Typography>
        </Grid>
        <Grid className="margin20LessTop" xs={12} item>
          <Typography className="viewPlayerInfoLabel">
            Availability – Day
          </Typography>
          <CheckboxList value={dayPref} disabled={true} />
        </Grid>
        <Grid className="margin20LessTop" xs={12} item>
          <Typography className="viewPlayerInfoLabel">
            Availability – Time
          </Typography>
          <CheckboxList value={timePref} disabled={true} />
        </Grid>
      </InfoSection>
      <InfoSection title="Ratings">
        <Grid xs={12} md={3} item>
          <Typography className="viewPlayerInfoLabel">
            Singles GSM Rating
          </Typography>
          <Typography className="viewPlayerAboutText">
            {Math.round(player.gsmRating * 100)}
          </Typography>
        </Grid>
        <Grid xs={12} md={3} item>
          <Typography className="viewPlayerInfoLabel">
            Doubles GSM Rating
          </Typography>
          <Typography className="viewPlayerAboutText">
            {Math.round(player.gsmRatingDoubles * 100)}
          </Typography>
        </Grid>
        <Grid xs={12} md={3} item>
          <Typography className="viewPlayerInfoLabel">NTRP Rating</Typography>
          <Typography className="viewPlayerAboutText">
            {player.ntrpRating}
          </Typography>
        </Grid>
        <Grid xs={12} md={3} item>
          <Typography className="viewPlayerInfoLabel">UTR Rating</Typography>
          <Typography className="viewPlayerAboutText">
            {player.utrRating}
          </Typography>
        </Grid>
      </InfoSection>
      <InfoSection title="Favorites">
        <Grid xs={12} md={4} item>
          <Typography className="viewPlayerInfoLabel">Player(s)</Typography>
          <Typography className="viewPlayerAboutText">
            {player.favoritePlayer}
          </Typography>
        </Grid>
        <Grid xs={12} md={4} item>
          <Typography className="viewPlayerInfoLabel">Shot</Typography>
          <Typography className="viewPlayerAboutText">
            {player.favoriteShot}
          </Typography>
        </Grid>
        <Grid xs={12} md={4} item>
          <Typography className="viewPlayerInfoLabel">Surface</Typography>
          <Typography className="viewPlayerAboutText">
            {player.surface}
          </Typography>
        </Grid>
      </InfoSection>
    </Grid>
  );
};

export default ViewPlayerInfo;

import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import {
  MenuTextField,
  DateTextField,
  TimeTextField,
} from "../../../components/CustomTextField";

import { TwoSlider } from "../../../components/CustomSliders";

const regEndSelect = [
  {
    label: "One week before the event starts",
    value: "One week before the event starts",
  },
  {
    label: "One day before the event starts",
    value: "One day before the event starts",
  },
  {
    label: "The day that the event starts",
    value: "The day that the event starts",
  },
];
const eventSelect = [
  {
    label: "Single time event",
    value: "single time event",
  },
  // {
  //   label: "Reocuring event",
  //   value: "Reocuring event",
  // }
];
const PageTwo = ({
  startDateDisplay,
  setStartDateDisplay,
  startDate,
  setStartDate,
  endDateDisplay,
  setEndDateDisplay,
  endDate,
  setEndDate,
  regEnd,
  setRegEnd,
  numPeople,
  setNumPeople,
  setFrequency,
  frequency,
  error,
  errorMessage,
}) => {

  return (
    <Fragment>
      <Typography className="createLeagueFieldText margin20Top">
        Date and Time
      </Typography>
      <div className="rowCenter">
        <div className="column widthHalf margin20Left">
          <Typography className="createLeagueFieldSubtext">
            Tell event-goers when your event starts and ends so they can make
            plans to attend.
          </Typography>
          <MenuTextField
            state={frequency}
            setState={setFrequency}
            menuSelection={eventSelect}
          />
        </div>
      </div>
      <Typography className="createLeagueFieldText">
        When will this event take place?
      </Typography>
      <div className="rowCenter">
        <div className="column widthHalf margin20Right">
          <Typography className="createLeagueFieldSubtext">
            Start Date
          </Typography>
          <DateTextField
            dateString={startDateDisplay}
            setDateString={setStartDateDisplay}
            date={startDate}
            setDate={setStartDate}
            required={true}
          />
          <Typography className="referralsInformation">
            *Minimum 1 week from today
          </Typography>
        </div>
        <div className="column widthHalf margin20Left">
          <Typography className="createLeagueFieldSubtext">End Date</Typography>
          <DateTextField
            dateString={endDateDisplay}
            setDateString={setEndDateDisplay}
            date={endDate}
            setDate={setEndDate}
            required={true}
          />
          <Typography className="referralsInformation">
            *End Date must be after start
          </Typography>
        </div>
      </div>
      <div className="rowCenter">
        <div className="column widthHalf margin20Right">
          <Typography className="createLeagueFieldSubtext">
            Start Time
          </Typography>
          <TimeTextField
            time={startDate}
            setTime={setStartDate}
            required={false}
          />
        </div>
        <div className="column widthHalf margin20Left">
          <Typography className="createLeagueFieldSubtext">End Time</Typography>
          <TimeTextField time={endDate} setTime={setEndDate} required={false} />
        </div>
      </div>
      <Typography className="createLeagueFieldText margin20Top">
        Registration
      </Typography>
      <div className="rowCenter">
        <div className="column widthHalf margin20Left">
          <Typography className="createLeagueFieldSubtext">
            Registration End Date
          </Typography>
          <MenuTextField
            state={regEnd}
            setState={setRegEnd}
            menuSelection={regEndSelect}
          />
        </div>
      </div>
      <Typography className="createLeagueFieldText margin20Top">
        How many participants can join your event?
      </Typography>
      <div className="maxWidth">
        <TwoSlider value={numPeople} setValue={setNumPeople} />
      </div>
      <div className="heightPlaceholder">
        {error && (
          <Typography className="createLeagueErrorText">
            {errorMessage}
          </Typography>
        )}
      </div>
    </Fragment>
  );
};

export default PageTwo;

import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { auth } from "../../firebase";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

function ResetPasswordForm({ open, setOpen, setSnackBarToggle }) {
  const [email, setEmail] = useState("");
  const [firebaseError, setFirebaseError] = useState("");
  const [firebaseErrorBool, setFirebaseErrorBool] = useState(false);

  function handleClose() {
    setOpen(false);
  }
  async function resetPassword(e) {
    e.preventDefault();
    auth
      .sendPasswordResetEmail(email)
      .then((res) => {
        setSnackBarToggle(true);
        setOpen(false);
      })
      .catch((err) => {
        setFirebaseErrorBool(true);
        setFirebaseError(err.message);
      });
  }

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogContent>
        <form className="columnCenter" onSubmit={(e) => resetPassword(e)}>
          <Typography className="fieldMarginTop" variant="h6">
            Enter the email attached to your account and we will send a link to
            reset your password.
          </Typography>
          <TextField
            className="fieldMarginTop"
            fullWidth={true}
            required
            label="Email"
            autoComplete="email"
            variant="outlined"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <div className="fieldMarginTop" />
          <Button
            className="highlightButton"
            variant="outlined"
            type="submit"
            fullWidth={true}
          >
            Send Email
          </Button>
          {firebaseErrorBool && (
            <Typography className="err" variant="subtitle2">
              {firebaseError}
            </Typography>
          )}
          <div className="signUpFieldSection" />
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default ResetPasswordForm;

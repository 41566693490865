import React, { useEffect, useState, Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Loader from "react-loader-spinner";
import ReactDOMServer from "react-dom/server";
import PDF from "./Leaguepdf"
import { jsPDF } from "jspdf";
import moment from "moment";
import {
  getUsersInTrack,
  getMatchSchedulesByTrack,
  generatePlayoffsList,
  generateMatchSchedules,
  getUserByID,
  getDoublesTeamsByID,
  generateModeratedMatchSchedules,
  handleMakeDoubleTeamForModerate,
} from "../../../functions/firebaseFunctions";
import {
  introOpponent,
  noActionEmail,
  singleActionEmail,
} from "../../../functions/Messaging";
import {firestore, Firebase, storage} from "../../../firebase";
import DefaultDialog from "../../../components/DefaultDialog";
import SideMenu from "../../../components/SideMenu";
import Content from "../../../components/Content";
import DirectorLeagueMatches from "./DirectorLeagueMatches";
import DirectorLeagueMatchesReported from "./DirectorLeagueMatchesReported";
import DirectorLeaguePlayers from "./DirectorLeaguePlayers";
import DirectorLeagueInfo from "./DirectorLeagueInfo";
import DirectorLeaguePlayoffs from "./DirectorLeaguePlayoffs";
import DirectorNotifications from "../DirectorNotifications";
import DirectorActivity from "../DirectorActivity";
import AdminLeagueApprovalForm from "../../admin/leagues/AdminLeagueApprovalForm";
import CheckboxList from "../../../components/CheckboxList";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import QRCodeSVG from "qrcode.react";
import {FacebookShareButton} from "react-share";
import {Facebook, FileCopy,PictureAsPdf} from "@material-ui/icons";
import {PostLeagueDataIntoFacebookPage, PostLeagueDataIntoTwitter} from "../../../utils/NotificationServices";
import {
  createDoublesTracksTable,
  createModeratedTracksTable,
  createSinglesTracksTable,
  divisionData
} from "../../../components/Common";


const routes = [
  "General Information",
  "Players",
  "Match Schedule",
  "Match History",
  "Playoffs",
  "Notify Players",
  "Post Activity",
];

const DirectorLeaguePage = ({
  user,
  zoneList,
  mobileSize,
  setisDisableSwitchSport,
}) => {
  const [pageNum, setPageNum] = useState(0);
  const [matchScheduleTrackPageNum, setMatchScheduleTrackPageNum] = useState(0);
  const [noOfCourt, setNoOfCourt] = useState(1);
  const [loading, setLoading] = useState(true);
  const [pageLabels, setPageLabels] = useState([]);
  const url = window.location.href.split("/");
  const leagueID = url.pop();
  const route = url[url.length - 2];
  const [league, setLeague] = useState(null);
  const [leagueDivisions, setLeagueDivisions] = useState({});
  const [leagueTracks, setLeagueTracks] = useState({});
  const [tracksList, setTracksList] = useState([]);
  const [director, setDirector] = useState(null);
  const [status, setStatus] = useState("");
  const [statusButtonText, setStatusButtonText] = useState("End League");
  const [openStatusConfirm, setOpenStatusConfirm] = useState(false);
  const [openLeagueApprovalForm, setOpenLeagueApprovalForm] = useState(false);
  const [leagueStatusLoad, setLeagueStatusLoad] = useState(false);
  const [enableAdminApproveButton, setEnableAdminApproveButton] = useState(false);
  const [enableCancelButton, setCancelButton] = useState(false);
  const [playoffsGenerated, setPlayoffsGenerated] = useState(false);
  const [allPlayoffsEnded, setAllPlayoffsEnded] = useState(false);
  const [savingPlayoffs, setSavingPlayoffs] = useState(false);
  const [playoffChangeDetected, setPlayoffChangeDetected] = useState(false);
  const [brackets, setBrackets] = useState([]);
  const [moderatedStatusConfirm, setModeratedStatusConfirm] = useState(false);
  const [isRemainingSchedule, setIsRemainingSchedule] = useState(false);
  const [isOpenPlayOffWarningPopup, setIsOpenPlayOffWarningPopup] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [divisionName, setDivisionName] = useState(null);
  const [track, setTrack] = useState(null);

  const location = useLocation();
  setisDisableSwitchSport(true);

  useEffect(() => {
    if (location?.state?.isFromModerate) {
      setPageNum(2);
    }
  }, [location?.state?.isFromModerate]);

  const isModerated = league?.format === "Moderated-dropin";
  const isPickleball = league?.sportType === "pickleball";

  const [dayPref, setDayPref] = useState([
    { label: "Monday", check: false, weekDay: 1 },
    { label: "Tuesday", check: false, weekDay: 2 },
    { label: "Wednesday", check: false, weekDay: 3 },
    { label: "Thursday", check: false, weekDay: 4 },
    { label: "Friday", check: false, weekDay: 5 },
    { label: "Saturday", check: false, weekDay: 6 },
    { label: "Sunday", check: false, weekDay: 0 },
  ]);
  const [checkboxErr, setCheckboxErr] = useState("");

  useEffect(() => {
    const leagueRef = firestore.collection("leagues").doc(leagueID);
    const unsubscribeLeague = leagueRef.onSnapshot((snapshot) => {
      setLeague({ ...snapshot.data(), id: snapshot.id, ref: snapshot.ref });
    });
    const divisionsRef = leagueRef.collection("divisions");
    const unsubscribeDivisions = divisionsRef.onSnapshot((snapshot) => {
      let divisionsTemp = {};
      snapshot.docs.forEach((doc) => {
        divisionsTemp[doc.id] = { ...doc.data(), id: doc.id, ref: doc.ref };
      });
      setLeagueDivisions(divisionsTemp);
    });
    const tracksRef = firestore
      .collectionGroup("tracks")
      .where("leagueID", "==", leagueID);
    const unsubscribeTracks = tracksRef.onSnapshot((snapshot) => {
      let tracksTemp = {};
      snapshot.docs.forEach((doc) => {
        tracksTemp[doc.id] = { ...doc.data(), id: doc.id, ref: doc.ref };
      });
      setLeagueTracks(tracksTemp);
    });
    return () => {
      unsubscribeLeague();
      unsubscribeDivisions();
      unsubscribeTracks();
    };
  }, [leagueID]);
  const handleSinglesTrack = async (league, division, track, scores) => {
    let players = await getUsersInTrack(track);
    players = players
        .map((player) => {
          let record = handlePlayerRecord(player, 'leaguesJoined', track.id) || {};

          return {
            ...player,
            rank: record?.rank || 0,
            withdrawn: record?.withdrawn || 0,
            winloss: record && record.matchWins + record.matchLosses > 0 ? (record.matchWins / (record.matchWins + record.matchLosses)).toFixed(4) : 0,
          };
        })
        .sort((a, b) => a.rank - b.rank);
    let schedule = await getMatchSchedulesByTrack(track);
    let playoffPlayers = players?.filter((player) => track?.playoffsPlayers?.find((playerID) => playerID === player.id));

    return createSinglesTracksTable(
        league,
        division,
        track,
        players,
        playoffPlayers,
        scores,
        schedule
    );
  };

  const handlePlayerRecord=(array, field, trackId)=> {
    return array[isPickleball ? `pb_${field}` : field].find((leagueJ) => leagueJ.trackID === trackId);
  }

  const handleModeratedTrack = async (league, division, track, scores) => {
    let players = await getUsersInTrack(track);
    let teams = await getDoublesTeamsByID(track.doublesTeams);
    teams = teams.sort((a, b) => a.rank - b.rank);
    players = players.map((player) => {
          let record = handlePlayerRecord(player, 'leaguesJoined', track.id);
          return {
            ...player,
            rank: record?.rank || 0,
            withdrawn: record?.withdrawn || 0,
            winloss:
                record && record.matchWins + record?.matchLosses > 0 ? (record.matchWins / (record.matchWins + record.matchLosses)).toFixed(4) : 0,
          };
        })
        .sort((a, b) => a.rank - b.rank);
    let schedule = await getMatchSchedulesByTrack(track);
    let playoffPlayers = players?.filter((player) => track?.playoffsPlayers?.some((playerID) => playerID === player.id));
    return createModeratedTracksTable(
        league,
        division,
        track,
        players,
        playoffPlayers,
        scores,
        schedule,
        teams
    );
  };

  const handleDoublesTrack = async (league, division, track, scores) => {
    let teams = await getDoublesTeamsByID(track.doublesTeams);
    if (teams.length > 0) {
      teams = teams
          .map((team) => {
            let record = handlePlayerRecord(team?.players?.[0], 'leaguesJoined', track.id);
            return {
              ...team,
              rank: record?.rank || 0,
              withdrawn: record?.withdrawn || 0,
              winloss: record && record.matchWins + record.matchLosses > 0 ? (record.matchWins / (record.matchWins + record.matchLosses)).toFixed(4) : 0,
            };
          })
          .sort((a, b) => a.rank - b.rank);
      let schedule = await getMatchSchedulesByTrack(track);
      let playoffTeams = teams?.filter((team) => track.playoffsDoublesTeams.find((teamID) => teamID === team.id));
      return createDoublesTracksTable(
          league,
          division,
          track,
          teams,
          playoffTeams,
          scores,
          schedule
      );
    }
  };


  const copyToClipBoard = () => {
    setCopySuccess(true);
    const text = document.createElement("textarea");
    text.innerText = window.location.href;
    document.body.appendChild(text);
    text.select();
    document.execCommand("copy");
    text.remove();
  };

  const generateQRCCode =async () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrlDataUrl = canvas
        .toDataURL("image/png");
    const store = await storage
        .ref(`leagueDocuments/${league.id}/QRCodePhoto`);
    await store.putString(pngUrlDataUrl, 'data_url').then(async (snapshot)=>{
      if(snapshot.state === "success") {
        await snapshot.ref.getDownloadURL()
            .then(async (url) => {
              await league.ref.update({QRCodeImage: url});
            });
      }
    })
  };

  const  downloadFile=async (url,ex) =>{
    const a = document.createElement("a");
    a.href = await toDataURL(url);
    a.download = `${league.name}.${ex}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  useEffect(() => {
    Object.keys(leagueTracks).map((trackID) => {
      const track = leagueTracks[trackID];
      const trackNo = `Track ${(track.track + 1)}`
      setTrack(trackNo)
      let divisionData = leagueDivisions[track.divisionID];
      setDivisionName(divisionData.division);
    });
  },[leagueTracks]);

  const generatePDFFile = (league, divisionName, track, director,) => {
    const obj = league?.description
    const content = JSON.parse(obj)
    const discription = content.blocks.map(block => block.text).join('');
    const canvas = document.getElementById("qr-pdf");
    const pngUrlDataUrl = canvas.toDataURL("image/png");
    const element = (
      <html>
        <body>
          <PDF
            league={league}
            divisionName={divisionName}
            track={track}
            director={director}
            pngUrlDataUrl={pngUrlDataUrl}
            discription={discription.trim()}
          />
        </body>
      </html>
    );
    const pdfFile = ReactDOMServer.renderToString(element);
    const pdf = new jsPDF();
    pdf.html(pdfFile, {
      callback: async function (doc) {
        let link = doc.output('datauristring', 'league.pdf');
        const store = await storage.ref(
          `leagueDocuments/${league?.id}/LeaguePDF`
        );
        await store.putString(link, 'data_url').then(async (snapshot) => {
          if (snapshot.state === 'success') {
            await snapshot.ref.getDownloadURL().then(async (url) => {
              await league.ref.update({ Leaguepdf: url });
            });
          }
        });
      },
    });
  };

  const toDataURL =(url)=> {
    return fetch(url)
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          return URL.createObjectURL(blob);
        });
  }

  const getData = async (scores) => {
    setStatus(league.status);
    setEnableAdminApproveButton(
        league.status === "pending" && user.admin && route === "Admin"
    );
    setCancelButton(
        league.status !== "expired" && user.admin && route === "Admin"
    );
    if (league.status === "pending") {
      setStatusButtonText("League Pending");
    } else if (league.status === "approved" && !league.scheduleGenerated) {
      setStatusButtonText("Generate Schedule");
    } else if (league.status === "approved" && league.scheduleGenerated) {
      setStatusButtonText("Publish Schedule");
    } else if (league.status === "active") {
      setStatusButtonText("Start Playoffs");
    } else if (league.status === "playoffs" && !league.playoffsGenerated) {
      setStatusButtonText("Publish Playoffs");
    } else if (league.status === "playoffs" && league.playoffsGenerated) {
      setStatusButtonText("End League");
    }
    let tracksList = [];
    let pageLabels = [];
    if (league.directorID === user.uid) {
      setDirector({...user, id: user.uid});
    } else {
      setDirector(await getUserByID(league.directorID));
    }
    const usePromises = await Object.keys(leagueTracks).map(async (trackID) => {
        let track = leagueTracks[trackID];
        let division = leagueDivisions[track.divisionID];
        const isModerateSingle = divisionData.includes(division.division);
        const trackScores = scores.filter((score) => score.trackID === trackID);
          tracksList.push(
              division.doublesFormat
                  ? await handleDoublesTrack(league, division, track, trackScores)
                  : isModerated && !isModerateSingle
                      ? await handleModeratedTrack(league, division, track, trackScores)
                      : await handleSinglesTrack(league, division, track, trackScores)
          );
          pageLabels.push(
              `${division.division} ${division.level}, Track ${track.track + 1}`
          );
        });

    const promisesAll = await Promise.all(usePromises).then((data) => {
      return tracksList;
    });

    setTracksList(promisesAll);
    setPageLabels(pageLabels);
    setTimeout(()=>{
      setLoading(false);
    }, 2000);
  };

  useEffect(() => {
    setLoading(true);

    const scoresRef = firestore
      .collection("scores")
      .where("leagueID", "==", leagueID);
    const unsubscribeScores = scoresRef.onSnapshot((snapshot) => {
      const scores = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        ref: doc.ref,
      }));
      if (league) getData(scores);
    });
    return () => {
      unsubscribeScores();
    };
    // eslint-disable-next-line
  }, [user, league, leagueDivisions, leagueTracks, leagueID, route]);

  const savePlayoffs = async (bracketsTemp, isModerateSave) => {
    setSavingPlayoffs(true);
    await Promise.all(
      brackets.map(async (bracket, index) => {
        let playoffParticipants = [];
        if (!bracket[0]) return;
        Object.keys(bracket).forEach((key, index2) => {
          bracket[key].forEach((slot, index3) => {
            if (bracketsTemp?.[index]?.[index2]?.[index3]?.division) {
              delete bracketsTemp[index][index2][index3].division;
            }
            slot.list.forEach((participant, index4) => {
              if (participant === "empty")
                bracketsTemp[index][index2][index3].list[index4] = participant;
              else {
                bracketsTemp[index][index2][index3].list[index4] =
                  participant.id;
                if (
                  participant.id.substring(0, 3) !== "bye" &&
                  !playoffParticipants.some((pID) => pID === participant.id)
                )
                  playoffParticipants.push(participant.id);
              }
            });
          });
        });
        await tracksList[index].track.ref.update(
          tracksList[index].division.doublesFormat
            ? {
                playoffsDoublesTeams: playoffParticipants,
                playoffsBracket: bracketsTemp[index],
              }
            : isModerateSave &&
              !divisionData.includes(tracksList[index].division.division)
            ? {
                playoffsDoublesTeams: playoffParticipants,
                playoffsBracket: bracketsTemp[index],
              }
            : {
                playoffsPlayers: playoffParticipants,
                playoffsBracket: bracketsTemp[index],
              }
        );
        playoffParticipants = [];
      })
    );
    setPlayoffChangeDetected(false);
    setSavingPlayoffs(false);
  };

  const calculateNextPosition = (currentCol, currentRow, offset) => {
    const nextCol = currentCol + 1;
    const nextRow = currentRow - offset;
    const pos = currentRow % 2 === 0 ? 0 : 1;
    return { nextCol, nextRow, pos };
  };

  const handleBracket = (bracket) => {
    Object.keys(bracket).forEach((key, index2) => {
      if (!bracket[0]) return;
      let rowOffset = 0;
      if (index2 < Object.keys(bracket).length - 1)
        bracket[key].forEach((slot, index3) => {
          if (bracket[index2][index3].division) {
            delete bracket[index2][index3].division;
          }
          if (
            slot.list[0] !== "empty" &&
            slot.list[1] !== "empty" &&
            (slot.list[0].id.substring(0, 3) === "bye" ||
              slot.list[1].id.substring(0, 3) === "bye")
          ) {
            let { nextCol, nextRow, pos } = calculateNextPosition(
              index2,
              index3,
              rowOffset
            );
            let participantID =
              slot.list[0].id.substring(0, 3) === "bye"
                ? slot.list[1]
                : slot.list[0];
            bracket[nextCol][nextRow].list[pos] = participantID;
          }
          if (index3 % 2 === 0) rowOffset++;
        });
    });
    return bracket;
  };

  const publishPlayoffs = async () => {
    let brcket = _.map(brackets, _.clone);
    let bracketsTemp = [];
    tracksList.forEach(async (tracks, index) => {
      let moderatebracket;
      if (!divisionData.includes(tracks.division.division)) {
        moderatebracket = await handleMakeDoubleTeamForModerate(
          brcket,
          tracksList,
          isPickleball
        );
      }
      handleSendMail(
        moderatebracket,
        tracks,
        !divisionData.includes(tracks.division.division),
        index
      );

      let bracketData = handleBracket(
        !divisionData.includes(tracks.division.division)
          ? moderatebracket.newDoubleTeamsArr[index]
          : brackets[index]
      );
      bracketsTemp.push(bracketData);
      if (tracksList.length - 1 === index) {
        setBrackets(bracketsTemp);
        savePlayoffs(bracketsTemp, isModerated);
      }
    });
  };

  const handleGenrateMail = async (tracks, isModeratorDouble, trackIndex) => {
    let contactList = [];
    if (!isModeratorDouble ? tracks.division.doublesFormat : isModeratorDouble) {
      tracks.teams.forEach((team) => {
        if (!team.withdrawn) {
          contactList.push({
            email: team.players[0].email,
            opponentName: `${team.players[1].firstName} ${team.players[1].lastName}`,
            name: `${team.players[0].firstName} ${team.players[0].lastName}`,
          });
          contactList.push({
            email: team.players[1].email,
            opponentName: `${team.players[0].firstName} ${team.players[0].lastName}`,
            name: `${team.players[1].firstName} ${team.players[1].lastName}`,
          });
        }
      });
    } else {
      for (const key of Object.keys(brackets[trackIndex])) {
        for (let k = 0; k < brackets[trackIndex][key].length; k++) {
          let slot = brackets[trackIndex][key][k];
          if (tracks.division.division === slot.division) {
            let foundPlayer = [];
            slot.list.forEach((e) => {
              if (e.id) {
                const fPlayer = tracks.players.find((p) => p.id === e.uid);
                if (fPlayer) {
                  foundPlayer.push(fPlayer);
                } else {
                  foundPlayer.push(e);
                }
              }
            });
            if (foundPlayer.length > 0) {
              if (foundPlayer[0].uid) {
                contactList.push({
                  email: foundPlayer[0].email,
                  opponentName: `${
                    foundPlayer[1].firstName || foundPlayer[1].name
                  } ${foundPlayer[1].lastName || ""}`,
                  name: `${foundPlayer[0].firstName} ${foundPlayer[0].lastName}`,
                });
              }
              if (foundPlayer[1].uid) {
                contactList.push({
                  email: foundPlayer[1].email,
                  opponentName: `${foundPlayer[0].firstName} ${foundPlayer[0].lastName}`,
                  name: `${foundPlayer[1].firstName} ${foundPlayer[1].lastName}`,
                });
              }
            }
          }
        }
      }
    }

    if (contactList.length > 0) {
      await introOpponent({
        message: `Playoffs for ${tracks.league.name} ${tracks.division.division}: Level ${tracks.division.level}, Track:${tracks.track.track + 1}
          is about to start and the brackets are now posted on the site.`,
        message2: `If this is a moderated league, watch out for your director’s instructions on where the moderated matches will be held or, if this is a FLEX League, then please reach out to your partner and opponents to setup your playoff matches (their contact numbers will be made available to you by the system) at a time most convenient to all participants.`,
        message3: "Best luck to you, be safe and have fun",
        subject: `${tracks.league?.name} PLAYOFFS starts today`,
        recipients: contactList,
        url: `${process.env.REACT_APP_BASE_URL}League/MyLeagues/Singles/${leagueID}/${tracks.division.id}/${tracks.track.id}/Playoffs`,
        buttonText: "View Bracket",
      });
      let message = `Playoffs has now started for ${tracks.league.name} ${tracks.division.division}: Level ${tracks.division.level}, Track:${tracks.track.track + 1}\n\nWow! This is going to be exciting.\n\nQualifiers:\n`;
      contactList.forEach(item => {
        message += item.name + '\n'
      });
      message += '\nBest of luck to all qualifiers!\n';
      let link = `/League/MyLeaguesView/Singles/${leagueID}/${tracks.division.id}/${tracks.track.id}/Information`;

      await PostLeagueDataIntoFacebookPage({message, link, userId: league.directorID});
      message = `Playoffs has now started for ${tracks.league.name} ${tracks.division.division}: Level ${tracks.division.level}, Track:${tracks.track.track + 1}. \n\nGo here to see all qualifiers.`
      await PostLeagueDataIntoTwitter({tweetMessage: message, link, userId: league.directorID});
    }
  };

  const handleAllTeam= async(track)=>{
    let teams = await getDoublesTeamsByID(track.doublesTeams);
    return teams;
  }

  const handleSendMail = async (
    moderatebracket,
    tracks,
    isModeratorDouble = false,
    index
  ) => {
    if (isModeratorDouble && isModerated) {
      const tracksRef = firestore
          .collectionGroup("tracks")
          .where("leagueID", "==", leagueID);
          const snapshot = await tracksRef.get();
        let tracksTemp = {};
        snapshot.docs.forEach((doc) => {
          tracksTemp[doc.id] = { ...doc.data(), id: doc.id, ref: doc.ref };
        });
        let trackTeam = []
        const usePromises = await Object.keys(tracksTemp).map(async (trackID) => {
          let track = tracksTemp[trackID];
          trackTeam.push(await handleAllTeam(track))
        });
        const allTeam = await Promise.all(usePromises).then((data) => {
          return trackTeam;
        });
        const trac =  [...allTeam[index]] ;
          tracks.teams = trac.filter((a1) => moderatebracket.DoubleTeamsIds.includes(a1.id));
          await handleGenrateMail(tracks, isModeratorDouble, index);
    }else if(isModeratorDouble){
      const trac = { ...tracks };
      tracks.teams = trac.teams.filter((a1) =>
        moderatebracket.DoubleTeamsIds.includes(a1.id)
      );
    }
    if(!isModeratorDouble){
      await handleGenrateMail(tracks, isModeratorDouble, index);
    }
  };

  const handlePageSelection = (index) => {
    setPageNum(index);
  };

  const generateLeagueActivity = async (category, description) => {
    await firestore.collection("activities").add({
      date: Firebase.firestore.FieldValue.serverTimestamp(),
      category: category,
      sportType: league?.sportType || "tennis",
      leagueID: league.id,
      players: [user.uid],
      likes: [],
      imageURLS: [],
      description: description,
    });
  };
  const handleStatusApproved = async (filterWeekdata) => {
    if (isModerated) {
      await generateModeratedMatchSchedules(
        league,
        leagueTracks,
        leagueDivisions,
        filterWeekdata
      );
    } else {
      await generateMatchSchedules(league, leagueTracks, leagueDivisions);
    }
    league.ref.update({ scheduleGenerated: true });
  };
  const handleStatusActive = async () => {
    await generateLeagueActivity(
      "leagueStart",
      `The league, ${league.name}, has started.`
    );
    const contactList = getEmailList();
    await singleActionEmail({
      message: `The league, ${
        league.name
      }, started today and will end on ${moment(league.endDate.toDate()).format(
        "MM/DD/YYYY"
      )}. Your League Coordinator is
      ${director.firstName} ${director.lastName}.`,
      message2: `If you have any questions, please reach out to ${director.firstName} @ ${director.email} or Call ${director.phoneNumber}.`,
      subject: `${league.name}, started today`,
      recipients: contactList,
      url: `${process.env.REACT_APP_BASE_URL}Leagues`,
      buttonText: "View Leagues",
    });
    league.ref.update({ status: "active" });
  };
  const handleStatusPlayoffs = async () => {
    await generatePlayoffsList(tracksList, isModerated);
    league.ref.update({ status: "playoffs" });
  };
  const handleCheckIsAnyScheduleRemain = () => {
    let isRemainingSchedule = false;
    tracksList.map((track) => {
      if (track) {
        track.matches.map((match) => {
          if (match.status === "notPlayed") {
            isRemainingSchedule = true;
          }
        });
      }
    });
    setIsRemainingSchedule(isRemainingSchedule);
  };

  const handleStatusPlayoffsGenerated = async () => {
    if (!isModerated) {
      await Promise.all(
        tracksList.map(async (track) => {
          let contactList = [];
          if (track.division.doublesFormat)
            track.teams.forEach((team) => {
              if (!team.withdrawn) {
                contactList.push({
                  email: team.players[0].email,
                  name: `${team.players[0].firstName} ${team.players[0].lastName}`,
                });
                contactList.push({
                  email: team.players[1].email,
                  name: `${team.players[1].firstName} ${team.players[1].lastName}`,
                });
              }
            });
          else
            track.players.forEach((player) => {
              if (!player.withdrawn)
                contactList.push({
                  email: player.email,
                  name: `${player.firstName} ${player.lastName}`,
                });
            });
          if (contactList.length > 0)
            await singleActionEmail({
              message: `Congratulations to all who made the playoffs. The playoff brackets is now available.
          The Champion gets $${league.firstPlacePrize}. So best of luck to everyone.`,
              message2:
                "Reminder: Let’s try to complete this out soon. We will be sending out occasional reminder to move things along.",
              message3: "Have fun and be safe always!",
              subject: `${league.name} PLAYOFFS starts today`,
              recipients: contactList,
              url: `${process.env.REACT_APP_BASE_URL}League/MyLeagues/${
                track.division.doublesFormat ? "Doubles" : "Singles"
              }/${leagueID}/${track.division.id}/${track.track.id}/Playoffs`,
              buttonText: "View Bracket",
            });
        })
      );
    }
    await publishPlayoffs();
    await generateLeagueActivity(
      "playoffsStart",
      `The league, ${league.name}, has begun playoffs.`
    );
    league.ref.update({ playoffsGenerated: true });
  };

  const handleCheckIsRedyForPlayOff = () => {
    if (isModerated) {
      let playerBracket = [...brackets];
      let iswrongPairInBracket = 0;
      playerBracket.map(async (bracket, index) => {
        Object.keys(bracket).forEach((key, index2) => {
          if (index2 === 0) {
            bracket[key].forEach((slot) => {
              if (!divisionData.includes(slot.division)) {
                if (slot.list.length === 4) {
                  let player1Name = [
                    slot?.list?.[0]?.name,
                    slot?.list?.[1]?.name,
                  ];
                  let player2Name = [
                    slot?.list?.[2]?.name,
                    slot?.list?.[3]?.name,
                  ];
                  let isTeam1HaveByePlayer = player1Name?.filter(
                    (item) => item === "Bye"
                  );
                  let isTeam2HaveByePlayer = player2Name?.filter(
                    (item) => item === "Bye"
                  );

                  if (
                    isTeam1HaveByePlayer?.length !== 0 &&
                    isTeam1HaveByePlayer?.length !== 2
                  ) {
                    iswrongPairInBracket += 1;
                  }
                  if (
                    isTeam2HaveByePlayer?.length !== 0 &&
                    isTeam2HaveByePlayer?.length !== 2
                  ) {
                    iswrongPairInBracket += 1;
                  }
                } else {
                  iswrongPairInBracket += 1;
                }
              }
            });
          }
        });
      });
      return iswrongPairInBracket <= 0;
    }
  };

  const handleStatusExpired = async () => {
    await Promise.all(
        tracksList.map(async (track, index)=>{
          let message = `The league, ${league.name}, has now ended.\n\n`;
          message += 'Winners: \n';
          brackets[index][(Object.keys(brackets[index]).length - 1).toString(10)].map((slot, index3) => {
            slot.list.map((participant, key) => {
              console.log({winner: slot.score.winner === participant.id});
              if(slot.score.winner === participant.id) {
                if (track.division.doublesFormat || (isModerated && !divisionData.includes(track.division.division))) {
                  message += `${track.division.division} ${track.division.level} Track ${track.track.track + 1}: ${participant.players[0].name} - ${participant.players[1].name} \n`;
                } else {
                  message += `${track.division.division} ${track.division.level} Track ${track.track.track + 1}: ${participant.name} \n`;
                }
              }
            })
          });
          let link = `/League/MyLeaguesView/Singles/${leagueID}/${track.division.id}/${track.track.id}/Information`;
          await PostLeagueDataIntoFacebookPage({message, link, userId: league.directorID});
          message += 'See you next season.';
          await PostLeagueDataIntoTwitter({tweetMessage: message, link, userId: league.directorID});
        })
    );
    await generateLeagueActivity(
      "leagueComplete",
      `The league, ${league.name}, has now ended. Congratulations to all champions for winning the playoffs.`
    );
    league.ref.update({ status: "expired" });
    const contactList = getEmailList();
    await noActionEmail({
      message: `The league, ${league.name}, has now ended. Congratulations to all champions for winning the playoffs.`,
      subject: `League, ${league.name}, ended today`,
      recipients: contactList,
    });
  };
  const handleStatusUpdate = async () => {
    setLeagueStatusLoad(true);
    if (league.status === "approved" && !league.scheduleGenerated) {
      if (isModerated) {
        let isDaySelected = dayPref;
        let filterData = isDaySelected.filter((ds) => ds.check);

        let start = moment(new Date(league.startDate.toDate()));
        let end = moment(new Date(league.endDate.toDate()));
        let arr = [];
        for (let i = 0; i < filterData.length; i++) {
          const weekDay = filterData[i].weekDay;
          let tmp = start.clone().day(weekDay);
          if (tmp.isSameOrAfter(start, "d")) {
            arr.push(tmp.format("YYYY-MM-DD"));
          }
          while (tmp.isSameOrBefore(end)) {
            tmp.add(7, "days");
            arr.push(tmp.format("YYYY-MM-DD"));
          }
        }

        let startFormated = moment(start).format("YYYY-MM-DD");
        let endFormated = moment(end).format("YYYY-MM-DD");
        let filterWeekdata = arr.filter(
          (d) =>
            new Date(d) >= new Date(startFormated) &&
            new Date(d) <= new Date(endFormated)
        );

        if (filterData?.length === 0) {
          setCheckboxErr("You need to select at least one day for the record.");
        } else {
          if (filterWeekdata.length === 0) {
            setCheckboxErr(
              "You need to select a valid day which is between a league start date and end date"
            );
          } else {
            setCheckboxErr("");
            await handleStatusApproved(filterWeekdata);
          }
        }
      } else {
        await handleStatusApproved();
      }
    } else if (league.status === "approved" && league.scheduleGenerated) {
      await handleStatusActive();
    } else if (league.status === "active") {
      await handleStatusPlayoffs();
    } else if (league.status === "playoffs" && !league.playoffsGenerated) {
      await handleStatusPlayoffsGenerated();
    } else if (league.status === "playoffs" && league.playoffsGenerated) {
      await handleStatusExpired();
    }
    setLeagueStatusLoad(false);
    handleModeratedClose();
    handleClose();
  };

  const getEmailList = () => {
    let contactList = [];
    tracksList.forEach((track) => {
      if (track.division.doublesFormat)
        track.teams.forEach((team) => {
          if (!team.withdrawn) {
            contactList.push({
              email: team.players[0].email,
              name: `${team.players[0].firstName} ${team.players[0].lastName}`,
            });
            contactList.push({
              email: team.players[1].email,
              name: `${team.players[1].firstName} ${team.players[1].lastName}`,
            });
          }
        });
      else
        track.players.forEach((player) => {
          if (!player.withdrawn)
            contactList.push({
              email: player.email,
              name: `${player.firstName} ${player.lastName}`,
            });
        });
    });
    return contactList;
  };

  const handleStatusConfirmOpen = () => {
    if (isModerated && !league.scheduleGenerated) {
      setModeratedStatusConfirm(true);
    } else {
      let ModelOpen = true;
      if (
        isModerated &&
        league.status === "playoffs" &&
        !league.playoffsGenerated
      ) {
        ModelOpen = handleCheckIsRedyForPlayOff();
        if (!ModelOpen) {
          setIsOpenPlayOffWarningPopup(true);
        }
      }
      setOpenStatusConfirm(ModelOpen);
      isModerated && handleCheckIsAnyScheduleRemain();
    }
  };
  const handleApproveLeagueOpen = () => {
    setOpenLeagueApprovalForm(true);
  };
  const handleCancel = () => {
    league.ref.update({ status: "expired" });
  };
  const handleClose = () => {
    setOpenStatusConfirm(false);
  };
  const handleModeratedClose = () => {
    setModeratedStatusConfirm(false);
  };
  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );

  return (
    <Grid container spacing={mobileSize ? 3 : 6}>
      {pageNum !== 7 && (
        <SideMenu>
          <div className="rowCenter">
            <Avatar
              variant="rounded"
              src={league.imageURL}
              className="image200Rounded"
            />
            <div className="column margin10Left">
              <Typography className="profileInfoCardTitle">
                {league.name}
              </Typography>
              <Typography className="profileInfoCardSubtitle">
                {league.status}
              </Typography>
              <DefaultDialog
                open={openStatusConfirm}
                setOpen={setOpenStatusConfirm}
              >
                <Typography className="createLeagueFieldText margin20Top">
                  Confirm {statusButtonText}
                </Typography>
                {statusButtonText === "Generate Schedule" && (
                  <Typography className="helperText margin20Top margin10Bottom">
                    Please make sure that your players are in their appropriate
                    divisions and tracks before you continue.
                  </Typography>
                )}
                {statusButtonText === "Publish Schedule" && (
                  <Typography className="helperText margin20Top margin10Bottom">
                    Please make sure that you have checked the schedule. Once
                    you confirm, the schedule will be made available to the
                    players.
                  </Typography>
                )}
                {statusButtonText === "Start Playoffs" &&
                  isRemainingSchedule && (
                    <Typography className="helperText margin20Top">
                      Warning: It looks like there some events has been started.
                      Confirm if you want to proceed.
                    </Typography>
                  )}
                <div className="rowCenter">
                  <div className="filler" />
                  <Button variant="text" onClick={handleClose}>
                    <Typography className="cancelButtonText">Back</Typography>
                  </Button>
                  <Button
                    className="purchaseButton"
                    variant="outlined"
                    onClick={handleStatusUpdate}
                  >
                    <Typography className="purchaseButtonText">
                      Confirm
                    </Typography>
                  </Button>
                </div>
              </DefaultDialog>

              <DefaultDialog
                open={isOpenPlayOffWarningPopup}
                setOpen={setIsOpenPlayOffWarningPopup}
              >
                <Typography className="createLeagueFieldText margin20Top">
                  Warning: Please make proper pair for team.
                </Typography>

                <div className="rowCenter">
                  <div className="filler" />
                  <Button
                    variant="text"
                    onClick={() => setIsOpenPlayOffWarningPopup(false)}
                  >
                    <Typography className="cancelButtonText">Back</Typography>
                  </Button>
                </div>
              </DefaultDialog>

              <DefaultDialog
                size={"md"}
                open={moderatedStatusConfirm}
                setOpen={setModeratedStatusConfirm}
              >
                <Typography className="createLeagueFieldText margin20Top">
                  Confirm {statusButtonText}
                </Typography>
                <Typography className="profileInfoCardSubtitle">
                  Since this is a Moderated-drop in league type, what days
                  whereyou thinking of having your events?
                </Typography>

                <CheckboxList value={dayPref} setValue={setDayPref} />
                {checkboxErr !== "" && (
                  <Typography className="err">{checkboxErr}</Typography>
                )}
                <div className="rowCenter">
                  <Typography className="profileInfoCardSubtitle">
                    <Typography className="err"> Note:-</Typography> Don't
                    worry. you can always change or add more dates later. this
                    is just to post something on the schedule and will help us
                    remind players on the days.
                  </Typography>
                  <Button variant="text" onClick={handleModeratedClose}>
                    <Typography className="cancelButtonText">Back</Typography>
                  </Button>
                  <Button
                    className="purchaseButton"
                    variant="outlined"
                    onClick={handleStatusUpdate}
                  >
                    <Typography className="purchaseButtonText">
                      Confirm
                    </Typography>
                  </Button>
                </div>
              </DefaultDialog>
              {league.status !== "expired" && (
                <Button
                  className="profileSaveButton margin10Top"
                  variant="outlined"
                  onClick={handleStatusConfirmOpen}
                  disabled={
                    (!playoffsGenerated && status === "playoffs") ||
                    (!allPlayoffsEnded && league.playoffsGenerated) ||
                    status === "pending"
                  }
                >
                  {leagueStatusLoad ? (
                    <Loader
                      type="TailSpin"
                      color="#02c39a"
                      height={25}
                      width={25}
                      timeout={8000}
                    />
                  ) : (
                    <Typography className="profileSaveButtonText">
                      {statusButtonText}
                    </Typography>
                  )}
                </Button>
              )}
              {!league.playoffsGenerated &&
                status === "playoffs" &&
                pageNum === 4 && (
                  <Button
                    className="profileSaveButton margin10Top"
                    variant="outlined"
                    disabled={savingPlayoffs || !playoffChangeDetected}
                    onClick={() => savePlayoffs(brackets)}
                  >
                    {savingPlayoffs ? (
                      <Loader
                        type="TailSpin"
                        color="#02c39a"
                        height={25}
                        width={25}
                        timeout={8000}
                      />
                    ) : (
                      <Typography className="profileSaveButtonText">
                        Save Playoffs
                      </Typography>
                    )}
                  </Button>
                )}

              {enableAdminApproveButton && (
                <Fragment>
                  <Button
                    className="profileSaveButton margin10Top"
                    variant="outlined"
                    onClick={handleApproveLeagueOpen}
                  >
                    <Typography className="profileSaveButtonText">
                      Approve League
                    </Typography>
                  </Button>
                  <AdminLeagueApprovalForm
                    open={openLeagueApprovalForm}
                    setOpen={setOpenLeagueApprovalForm}
                    league={league}
                    director={director}
                    zoneList={zoneList}
                  />
                </Fragment>
              )}
              {enableCancelButton && (
                <Fragment>
                  <Button
                    className="CancelButton margin10Top"
                    variant="outlined"
                    onClick={handleCancel}
                  >
                    <Typography className="profileUpdateButtonText">
                      Cancel League
                    </Typography>
                  </Button>
                </Fragment>
              )}
            </div>
          </div>
          <div className="columnCenter  margin30Top">
            {routes.map((route, index) => (
              <Button
                key={index}
                variant="text"
                onClick={() => handlePageSelection(index)}
                fullWidth
                className="profileNavButton"
              >
                <div className="rowCenter margin10Vertical">
                  <Typography
                    className={
                      pageNum !== index
                        ? "profileNavButtonTextUnselected"
                        : "profileNavButtonTextSelected"
                    }
                  >
                    {route}
                  </Typography>
                </div>
              </Button>
            ))}
            {!league.QRCodeImage && <Button
                key={routes.length + 1}
                variant="text"
                onClick={() => generateQRCCode()}
                fullWidth
                className="profileNavButton"
            >
              <div className="rowCenter margin10Vertical">
                <Typography
                    className={"profileNavButtonTextUnselected"}
                >
                  Create QR Code
                </Typography>
              </div>
              <QRCodeSVG id="qr-gen" value={`${process.env.REACT_APP_BASE_URL}Leagues?pageNum=1`} size={200}
                         level={"H"}
                         includeMargin={true}
                         style={{display: "none"}}
              />
            </Button>}
               {!league.Leaguepdf && <Button
                key={routes.length + 2}
                variant="text"
                onClick={() => generatePDFFile(league,divisionName,track,director)}
                fullWidth
                className="profileNavButton"
            >
              <div className="rowCenter margin10Vertical">
                <Typography
                    className={"profileNavButtonTextUnselected"}
                >
                  Create League Flyer (PDF)
                </Typography>
                <QRCodeSVG id="qr-pdf" value={`${process.env.REACT_APP_BASE_URL}Leagues?pageNum=1`} size={200}
                         level={"H"}
                         bgColor={"transparent"}
                         includeMargin={true}
                         style={{display: "none"}}
              />
              </div>
            </Button>}
            <div className="directorLeagueCopyToClipBlock">
              <div className="directorLeagueCopyToClip">
            {league.Leaguepdf && <div id="pdf-gen"className="LeagueInfoCopyButton facebook-share-btn gen-pdf-btn">
                  <PictureAsPdf className="viewLeagueDirectorContactIcon Leaguepdf" onClick={()=>downloadFile(league.Leaguepdf,"pdf")}/>
                </div>}
                <FacebookShareButton
                    url={`${process.env.REACT_APP_BASE_URL}/League/ViewLeague/${league.id}`}
                    quote={league.name}
                    className="LeagueInfoCopyButton facebook-share-btn"
                >
                  <Facebook className="viewLeagueDirectorContactIcon"/>
                </FacebookShareButton>
                <Tooltip title={copySuccess ? "Copied!" : "copy to clipboard"}>
                  <IconButton
                      onClick={() => copyToClipBoard()}
                      className="LeagueInfoCopyButton"
                  >
                    <FileCopy className="viewLeagueDirectorContactIcon"/>
                  </IconButton>
                </Tooltip>
              </div>
              {league.QRCodeImage && <div className="QRCode_block">
                <Avatar
                    className="image200"
                    variant="rounded"
                    src={league.QRCodeImage}
                />
                <Button className="QRCode_download_btn" style={{backgroundColor: league.sportType === "pickleball" ? "#340761ab": "#028360ab" }} onClick={()=>downloadFile(league.QRCodeImage,"png")}>Download</Button>
              </div>
              }
            </div>
          </div>
        </SideMenu>
      )}

      <Content>
        {pageNum === 0 && (
          <DirectorLeagueInfo
            league={league}
            divisions={leagueDivisions}
            tracks={tracksList}
            status={status}
            zoneList={zoneList}
          />
        )}
        {pageNum === 1 && (
          <DirectorLeaguePlayers
            league={league}
            status={status}
            pageLabels={pageLabels}
            tracks={tracksList}
            divisions={leagueDivisions}
          />
        )}
        {pageNum === 2 && (
          <DirectorLeagueMatches
            isPickleball={isPickleball}
            league={league}
            status={status}
            pageLabels={pageLabels}
            tracks={tracksList}
            userFirstName={user?.firstName}
            setPageNum={setMatchScheduleTrackPageNum}
            pageNum={matchScheduleTrackPageNum}
            noOfCourt={noOfCourt}
            setNoOfCourt={setNoOfCourt}
          />
        )}
        {pageNum === 3 && (
          <DirectorLeagueMatchesReported
            league={league}
            status={status}
            pageLabels={pageLabels}
            tracks={tracksList}
          />
        )}
        {pageNum === 4 && (
          <Fragment>
            {status !== "playoffs" && status !== "expired" ? (
              <Typography className="profileInfoCardTitle">
                Playoffs have not begun
              </Typography>
            ) : (
              <DirectorLeaguePlayoffs
                league={league}
                status={status}
                pageLabels={pageLabels}
                tracks={tracksList}
                setPlayoffsGenerated={setPlayoffsGenerated}
                setAllPlayoffsEnded={setAllPlayoffsEnded}
                setPlayoffChangeDetected={setPlayoffChangeDetected}
                brackets={brackets}
                setBrackets={setBrackets}
              />
            )}
          </Fragment>
        )}
        {pageNum === 5 && (
          <DirectorNotifications
            league={league}
            status={status}
            tracks={tracksList}
            pageLabels={pageLabels}
            getEmailList={getEmailList}
          />
        )}
        {pageNum === 6 && (
          <DirectorActivity
            leagueName={league.name}
            leagueID={league.id}
            status={status}
            tracks={tracksList}
            pageLabels={pageLabels}
            user={user}
          />
        )}
      </Content>
    </Grid>
  );
};

export default DirectorLeaguePage;

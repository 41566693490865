import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid  from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import Loader from "react-loader-spinner";
import TableComponent from "../../components/table/TableComponent";
import { getUserByID, getFirebaseDoc } from "../../functions/firebaseFunctions";
import { firestore } from "../../firebase";
const listHeader = [
  { content: "Reviewer", label: "reviewer", sortable: true },
  { content: "League", label: "league", sortable: true },
  { content: "Public Review", label: "publicReview", sortable: true },
  { content: "Private Review", label: "privateReview", sortable: true },
  { content: "Reviewee", label: "reviewee", sortable: true },
  { content: "Date", label: "date", sortable: true },
];
function AdminReviewOverview({ leagues }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterByLeague, setFilterByLeague] = useState("");
  const [filterByPlayer, setFilterByPlayer] = useState("");

  useEffect(() => {
    const reviewsRef = firestore.collection("reviews");
    const unsubscribe = reviewsRef.onSnapshot(async (snapshot) => {
      setList(
        await Promise.all(
          snapshot.docs.map(async (r, i) => {
            r = r.data();
            console.log(r);
            let reviewerTemp = await getUserByID(r.reviewers[0]);
            let reviewee1Temp = await getUserByID(r.reviewees[0]);
            let reviewee2Temp = null;
            let revieweeName = `${reviewee1Temp.firstName} ${reviewee1Temp.lastName}`;
            let reviewerName = `${reviewerTemp.firstName} ${reviewerTemp.lastName}`;
            if (r.doublesFormat) {
              reviewee2Temp = await getUserByID(r.reviewees[1]);
              revieweeName = `${reviewee1Temp.lastName} - ${reviewee2Temp.lastName}`;
            }
            let league = await getFirebaseDoc(`leagues/${r.leagueID}`);
            league = league.name;
            let dateParsed = r.matchDate.split("/");
            let date = new Date(
              dateParsed[2],
              dateParsed[0] - 1,
              dateParsed[1]
            );
            let dispDate = moment(date).format("MMM D, YYYY");
            return {
              cells: [
                { content: reviewerName, editable: false },
                { content: league, editable: false },
                { content: r.publicReview, editable: false },
                { content: r.privateReview, editable: false },
                { content: revieweeName, editable: false },
                { content: dispDate, editable: false },
              ],
              reviewer: reviewerName,
              reviewee: revieweeName,
              publicReview: r.publicReview,
              privateReview: r.privateReview,
              league,
              date,
            };
          })
        )
      );
      setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);
  const playerFilter = (item) => {
    if (filterByPlayer === "") return true;
    return item.reviewer.indexOf(filterByPlayer) === 0;
  };
  const leagueFilter = (item) => {
    if (filterByLeague === "") return true;
    return item.league.indexOf(filterByLeague) === 0;
  };
  if (!loading)
    return (
      <div className="columnCenter">
        <div className="maxWidth">
          <Typography variant="h4">Reviews</Typography>
        </div>
        <div className="margin10" />
        <Grid container spacing={6}>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              className="maxWidth"
              value={filterByPlayer}
              onChange={(e) => {
                setFilterByPlayer(e.target.value);
              }}
              label="Filter by player"
            />
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              className="maxWidth"
              value={filterByLeague}
              onChange={(e) => {
                setFilterByLeague(e.target.value);
              }}
              label="Filter by league"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TableComponent
            list={list
              .filter((review) => playerFilter(review))
              .filter((review) => leagueFilter(review))}
            setList={setList}
            tableHeader={listHeader}
            type="viewOnly"
            rowsPerPageOverride={10}
          />
        </Grid>
      </div>
    );
  return (
    <div className="pageWrapper">
      <Loader
        type="TailSpin"
        color="#02c39a"
        height={100}
        width={100}
        timeout={3000}
      />
    </div>
  );
}

export default AdminReviewOverview;

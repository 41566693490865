import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Loader from "react-loader-spinner";
import moment from "moment";
import { DefaultTextField } from "../../components/CustomTextField";
import TableComponent from "../../components/table/TableComponent";
import { firestore } from "../../firebase";
import { getUserByID, getFirebaseDoc } from "../../functions/firebaseFunctions";
const receiptsListHeader = [
  { content: "Name", label: "name", sortable: true },
  { content: "Purchased", label: "purchased", sortable: true },
  { content: "($)Cost", label: "cost", sortable: true },
  { content: "Date", label: "date", sortable: true },
  { content: "Used Coupon", label: "usedCoupon", sortable: true },
];
const AdminLeaguePurchaseOverview = () => {
  const [loading, setLoading] = useState(true);
  const [receiptsList, setReceiptsList] = useState([]);
  const [filterByPlayer, setFilterByPlayer] = useState("");
  const [filterByLeague, setFilterByLeague] = useState("");

  useEffect(() => {
    const receiptsRef = firestore.collection("receipts").orderBy("date");
    const unsubscribe = receiptsRef.onSnapshot(async (snapshot) => {
      setReceiptsList(
        await Promise.all(
          snapshot.docs.map(async (receiptDoc) => {
            const receiptData = receiptDoc.data();
            const ref = receiptDoc.ref;
            const id = receiptDoc.id;
            const player = await getUserByID(receiptData.userID);
            const name = `${player.firstName} ${player.lastName}`;
            let league = await getFirebaseDoc(
              `leagues/${receiptData.leagueID}`
            );
            let division = await getFirebaseDoc(
              `leagues/${receiptData.leagueID}/divisions/${receiptData.divisionID}`
            );
            const purchased = league.event
              ? `${league.name}`
              : `${league.name} ${division.division}, Level ${division.level}`;
            const cost = receiptData.amount / 100;
            const date = receiptData.date.toDate();
            const dispDate = moment(date).format("MMM D, YYYY");
            const couponUsed = receiptData.couponUsed.toString();
            return {
              cells: [
                { content: name, editable: false },
                { content: purchased, editable: false },
                { content: cost, editable: false },
                { content: dispDate, editable: false },
                { content: couponUsed, editable: false },
              ],
              date,
              ref,
              id,
              name,
              cost,
              purchased,
              couponUsed,
            };
          })
        )
      );
      setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);
  const nameFilter = (receipt) => {
    if (filterByPlayer === "") return true;
    return receipt.name.indexOf(filterByPlayer) === 0;
  };
  const leagueFilter = (receipt) => {
    if (filterByLeague === "") return true;
    return receipt.purchased.indexOf(filterByLeague) === 0;
  };
  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  return (
    <div className="column filler">
      <Typography className="margin10Bottom" variant="h4">
        Receipts
      </Typography>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <div className="rowCenter">
            <Grid container spacing={6}>
              <Grid className="rowCenter" item xs={12} md={6}>
                <DefaultTextField
                  state={filterByPlayer}
                  setState={setFilterByPlayer}
                  placeholder="Player Name"
                />
              </Grid>
              <Grid className="rowCenter" item xs={12} md={6}>
                <DefaultTextField
                  state={filterByLeague}
                  setState={setFilterByLeague}
                  placeholder="Leagues"
                />
              </Grid>
            </Grid>
          </div>
          <TableComponent
            list={receiptsList
              .filter((receipt) => nameFilter(receipt))
              .filter((receipt) => leagueFilter(receipt))}
            setList={setReceiptsList}
            tableHeader={receiptsListHeader}
            type="viewOnly"
            rowsPerPageOverride={10}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default AdminLeaguePurchaseOverview;

import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Loader from "react-loader-spinner";
import moment from "moment";
import { firestore } from "../../firebase";
import TableComponent from "../../components/table/TableComponent";
import AdminProfileEdit from "./AdminProfileEdit";

const header = [
  { label: "name", content: "Name", sortable: true },
  { label: "email", content: "Email", sortable: true },
  { label: "matchPoints", content: "Match Points", sortable: true },
  { label: "director", content: "Director", sortable: true },
  { label: "active", content: "Account Active", sortable: true },
  { label: "verified", content: "Verified", sortable: true },
  { label: "dateCreated", content: "Date Joined", sortable: true },
];

const AdminUserOverview = ({ zoneList }) => {
  const [loading, setLoading] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(undefined);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const usersRef = firestore.collection("users");
    const unsubscribeUsers = usersRef.onSnapshot((usersSnapshot) => {
      setUsers(
        usersSnapshot.docs.map((userDoc) => {
          const userData = userDoc.data();
          const ref = userDoc.ref;
          const name = `${userData.firstName} ${userData.lastName}`;
          const email = userData.email;
          const director = userData.director;
          const matchPoints = userData.matchPoints;
          const active = userData.active;
          const verified = userData.verified;
          const dateCreated = userData.dateCreated.toDate();
          return {
            cells: [
              { content: name, editable: false },
              { content: email, editable: false },
              { content: matchPoints.toString(), editable: false },
              { content: director ? "Yes" : "No", editable: false },
              { content: active ? "Yes" : "No", editable: false },
              { content: verified ? "Yes" : "No", editable: false },
              {
                content: moment(dateCreated).format("MM/DD/YYYY"),
                editable: false,
              },
            ],
            dateCreated,
            name,
            email,
            matchPoints,
            director,
            active,
            verified,
            ref,
            player: { ...userData, ref: ref, id: userDoc.id },
          };
        })
      );
      setLoading(false);
    });
    return () => {
      unsubscribeUsers();
    };
  }, []);
  const handleComponentEdit = (rowSelected) => {
    let player = rowSelected.player;
    setSelectedPlayer(player);
    setOpenEdit(true);
  };

  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );

  return (
    <div className="column filler">
      <AdminProfileEdit
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        selectedPlayer={selectedPlayer}
        setSelectedPlayer={setSelectedPlayer}
        zoneList={zoneList}
      />
      <div className="pageWrapper">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <TableComponent
              list={users}
              setList={setUsers}
              tableHeader={header}
              type="componentEdit"
              handleComponentEdit={handleComponentEdit}
              rowsPerPageOverride={10}
            />
          </Grid>
        </Grid>
        <div className="filler" />
      </div>
    </div>
  );
};

export default AdminUserOverview;

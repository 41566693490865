import React from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const themeDistanceSlider = createTheme({
  palette: {
    primary: {
      main: "#55B56A",
      light: "#EDF8F4",
      dark: "#028360",
    },
  },
  overrides: {
    MuiTextField: {},
    MuiSlider: {
      track: {
        marginTop: -3,
        height: 8,
        borderRadius: 4,
        backgroundColor: "#55B56A",
      },
      thumb: {
        marginTop: -11,
        width: 24,
        height: 24,
        backgroundColor: "#028360",
      },
      valueLabel: {
        left: -5,
      },
    },
  },
  props: {
    MuiTextField: {
      variant: "standard",
      InputProps: {
        disableUnderline: true,
      },
    },
  },
});
const pbthemeDistanceSlider = createTheme({
  palette: {
    primary: {
      main: "#6115AD",
      light: "#F9EBFF",
      dark: "#340761",
    },
  },
  overrides: {
    MuiTextField: {},
    MuiSlider: {
      track: {
        marginTop: -3,
        height: 8,
        borderRadius: 4,
        backgroundColor: "#6115AD",
      },
      thumb: {
        marginTop: -11,
        width: 24,
        height: 24,
        backgroundColor: "#340761",
      },
      valueLabel: {
        left: -5,
      },
    },
  },
  props: {
    MuiTextField: {
      variant: "standard",
      InputProps: {
        disableUnderline: true,
      },
    },
  },
});
export const GSMRatingSlider = ({ value, setValue, editable }) => {
  return (
    <div className="paddingTopBox">
      <ThemeProvider theme={themeDistanceSlider}>
        <Slider
          onChange={(e, newVal) => {
            if (editable) setValue(newVal);
          }}
          value={value}
          min={10}
          max={80}
          step={5}
          valueLabelFormat={() => value / 10}
          valueLabelDisplay="on"
        />
      </ThemeProvider>
    </div>
  );
};

export const PercentSlider = ({ value, setValue }) => {
  return (
    <div className="padding10">
      <ThemeProvider theme={themeDistanceSlider}>
        <Slider
          onChange={(e, newVal) => {
            setValue(newVal);
          }}
          value={value}
          min={1}
          max={100}
          step={1}
          valueLabelDisplay="auto"
        />
      </ThemeProvider>
    </div>
  );
};

export const TwoSlider = ({ value, setValue, isPickleball }) => {
  const min = 2;
  const max = 100;
  const handleChange = (e, newVal) => {
    if (newVal[0] !== value[0] || newVal[1] !== value[1]) {
      setValue(newVal);
    }
  };
  return (
    <div className="rowCenter margin10Top">
      <div className="margin10Right columnAlignCenter margin20LessTop">
        <Typography className="referralsInformation ">Min</Typography>
        <TextField
          className="sliderTextField"
          value={value[0]}
          onChange={(e) => {
            setValue([e.target.value, value[1]]);
          }}
          onBlur={() => {
            if (value[0] < 2) setValue([2, value[1]]);
          }}
        />
      </div>
      <ThemeProvider
        theme={isPickleball ? pbthemeDistanceSlider : themeDistanceSlider}
      >
        <Slider
          valueLabelDisplay="auto"
          onChange={handleChange}
          value={value}
          min={min}
          max={max}
        />
      </ThemeProvider>
      <div className="margin10Left columnAlignCenter  margin20LessTop">
        <Typography className="referralsInformation">Max</Typography>
        <TextField
          className="sliderTextField"
          value={value[1]}
          onChange={(e) => {
            setValue([value[0], e.target.value]);
          }}
          onBlur={() => {
            if (value[1] > 100) setValue([value[0], 100]);
          }}
        />
      </div>
    </div>
  );
};

export const MatchDistanceSlider = ({ value, setValue }) => {
  return (
    <ThemeProvider theme={themeDistanceSlider}>
      <div className="padding10">
        <Slider
          className="width80"
          onChange={(e, newVal) => {
            setValue(newVal);
          }}
          value={value}
          min={1}
          max={10}
          step={0.5}
        />
      </div>
    </ThemeProvider>
  );
};

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import {
  DateTextField,
  DefaultTextField,
  TimeTextField,
} from "./CustomTextField";
import DefaultDialog from "./DefaultDialog";
import { firestore } from "../firebase";
import moment from "moment";
import Loader from "react-loader-spinner";

const ModeratedScheduleAddUpdateDilog = ({
  isOpen,
  handleClose,
  id: row,
  type,
  track,
  handleReload,
  noOfCourt,
  setNoOfCourt,
  handleRedirectToModerateScreen,
}) => {
  const [venue, setVenue] = useState(row?.location || "");
  const [isLoading, setIsLoading] = useState(false);
  const [startDateDisplay, setStartDateDisplay] = useState(
    moment(row.startDate).format("MM/DD/YYYY")
  );
  const [startDate, setStartDate] = useState(moment(row?.startDate).toDate());
  const [endDateDisplay, setEndDateDisplay] = useState(
    moment(row.endDate).format("MM/DD/YYYY")
  );
  const [endDate, setEndDate] = useState(moment(row?.endDate).toDate());

  const handleConfirm = async () => {
    setIsLoading(true);
    if (type === "isAdd") {
      await firestore.collection("matchSchedules").add({
        leagueID: track.leagueID,
        divisionID: track.divisionID,
        trackID: track.id,
        start: moment(startDate, "YYYY-MM-DD").toDate(),
        end: moment(endDate, "YYYY-MM-DD").toDate(),
        status: "notPlayed",
        doublesFormat: true,
        venue: venue,
      });
    }
    if (type === "isUpdate") {
      await firestore.doc(`matchSchedules/${row.id}`).update({
        start: moment(startDate, "YYYY-MM-DD").toDate(),
        end: moment(endDate, "YYYY-MM-DD").toDate(),
        venue: venue,
      });
    }
    if (type === "isStart") {
      await firestore.doc(`matchSchedules/${row.id}`).update({
        start: moment(startDate, "YYYY-MM-DD").toDate(),
        end: moment(endDate, "YYYY-MM-DD").toDate(),
        venue: venue,
        status: "In Progress",
      });
      handleRedirectToModerateScreen();
    }

    if (type === "isDelete") {
      await firestore.doc(`matchSchedules/${row.id}`).delete();
    }
    handleReload();
    setIsLoading(false);
    handleClose();
  };

  const messageMap = {
    isAdd:
      'You are about to add an entry to schedule. Please enter the following:',
    isUpdate:
      'You are about to update an entry in schedule. Please update the following:',
    isStart:
      'You are about to start the event. Please confirm the following information:',
    isDelete: 'You are about to delete a schedule. Are you Sure?',
    InProgressErr: (
      <div>
        <Typography className="err">Error:-</Typography>Please Conclude the
        event that is still In Progress before starting a new one
      </div>
    ),
  };

  return (
    <DefaultDialog open={isOpen} setOpen={handleClose}>
      <Typography className="moderatedScheduleDilougTitleText">
        {messageMap[type]}
      </Typography>

      <br></br>
      {type !== "isDelete" && type !== "InProgressErr" && (
        <div className="margin20Bottom">
          <div className="rowCenter">
            <div className="column widthHalf margin20Right">
              <Typography className="createLeagueFieldSubtext">
                Start Date:
              </Typography>
              <DateTextField
                dateString={startDateDisplay}
                setDateString={setStartDateDisplay}
                date={startDate}
                setDate={setStartDate}
              />
            </div>
            <div className="column widthHalf margin20Left">
              <Typography className="createLeagueFieldSubtext">
                End Date:
              </Typography>
              <DateTextField
                required={true}
                dateString={endDateDisplay}
                setDateString={setEndDateDisplay}
                setDate={setEndDate}
                date={endDate}
              />
            </div>
          </div>
          {type === "isStart" && (
            <>
              <div>
                <Typography className="createLeagueFieldSubtext">
                  Time:
                </Typography>
                <TimeTextField
                  time={startDate}
                  setTime={setStartDate}
                  required={false}
                />
              </div>
              <div>
                <Typography className="createLeagueFieldSubtext">
                  Number of Court:
                </Typography>
                <DefaultTextField
                  state={noOfCourt}
                  setState={setNoOfCourt}
                  placeholder="No of court"
                  required={true}
                />
              </div>
            </>
          )}
          <div>
            <Typography className="createLeagueFieldSubtext">
              Location:
            </Typography>
            <DefaultTextField
              state={venue}
              setState={setVenue}
              placeholder="Enter Location"
              required={true}
            />
          </div>
        </div>
      )}
      <div className="rowCenter">
        <div className="filler" />
        <Button variant="text" onClick={handleClose}>
          <Typography className="cancelButtonText">Back</Typography>
        </Button>

        {type !== "InProgressErr" && (
          <Button
            className="purchaseButton"
            variant="outlined"
            onClick={handleConfirm}
          >
            {isLoading ? (
              <Loader
                type="TailSpin"
                color="#02c39a"
                height={30}
                width={30}
                timeout={4000}
              />
            ) : (
              <Typography className="purchaseButtonText">Confirm</Typography>
            )}
          </Button>
        )}
      </div>
    </DefaultDialog>
  );
};

export default ModeratedScheduleAddUpdateDilog;

import React, { Fragment, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import windowSize from "react-window-size";
import {GSMIcon, PickleBallIcon, PlayerLogoIcon} from "../res/SVGIcons";
import DefaultDialog from "./DefaultDialog";
import { DefaultTextField, MenuTextField } from "./CustomTextField";
import { setCurrentSport } from "../functions/firebaseFunctions";
import { firestore, functions } from "../firebase";
import {InfoPopover} from "../pages/signin/InfoPopover";

const ntrpSelection = [
  { label: "1.5", value: 1.5 },
  { label: "2.0", value: 2.0 },
  { label: "2.5", value: 2.5 },
  { label: "3.0", value: 3.0 },
  { label: "3.5", value: 3.5 },
  { label: "4.0", value: 4.0 },
  { label: "4.5", value: 4.5 },
  { label: "5.0", value: 5.0 },
  { label: "5.5", value: 5.5 },
  { label: "6.0", value: 6.0 },
  { label: "6.5", value: 6.5 },
  { label: "7.0", value: 7.0 },
];

const SwitchSportPopup = ({ user, isOpen, handleClose }) => {
  const isPickleball = user.currentSport === "pickleball";
  const [ntrpRating, setNtrpRating] = useState(
    isPickleball ? user.ntrpRating : user.pb_utprRating
  );
  const [utrRating, setUtrRating] = useState(user.utrRating);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    let calcNtrp = ntrpRating;
    if (calcNtrp < 15) calcNtrp = 15;
    else if (calcNtrp > 70) calcNtrp = 70;
    calcNtrp /= 10;

    setLoading(true);
    if (isPickleball) {
      await firestore.doc(`users/${user.uid}`).update({
        ntrpRating: ntrpRating,
        isBothSportCheckout: true,
        gsmRating: calcNtrp,
        gsmRatingDoubles: calcNtrp
      });
      try {
        const updateUserData = functions.httpsCallable(
          'webhookProcessActionUpdateUserLeague'
        );
        updateUserData({ userID: user.uid });
      } catch (error) {
        console.log('webhookProcessActionUpdateUserLeague', error);
      }
    } else {
      await firestore.doc(`users/${user.uid}`).update({
        pb_utprRating: ntrpRating,
        isBothSportCheckout: true,
        pb_gsmRating: calcNtrp,
        pb_gsmRatingDoubles: calcNtrp
      });
      try {
        const updateUserData = functions.httpsCallable(
          'webhookProcessActionUpdateUserLeague'
        );
        updateUserData({ userID: user.uid });
      } catch (error) {
        console.log('webhookProcessActionUpdateUserLeague', error);
      }
    }
    setCurrentSport(isPickleball, user.uid);
    handleClose();
  };

  return (
    <DefaultDialog paddingZero open={isOpen} setOpen={handleClose} size={"lg"}>
      <div className="switchPopup">
        <Grid container spacing={0}>
          <Grid item xs={12} md={12} lg={6} className="SwitchPopupPageLeft">
            <div className="rowCenter">
              <div className="landingPageLogoWrapper">
                <a href="https://playgamesetmatch.com">
                  <GSMIcon />
                  <PickleBallIcon isPickleball={!isPickleball} />
                </a>
              </div>
            </div>
            <div className="flex gridCenter">
              <div>
                <PlayerLogoIcon isPickleball={!isPickleball}/>
              </div>
              <div className={`switchPopupwelcomeText ${isPickleball?'margin30Left':''}`}>
                <Typography className="switchPopupLoginText">
                  {isPickleball
                    ? "Welcome to Tennis League"
                    : "Welcome to Pickleball"}
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={6} className="switchableColorLight">
            <br></br>
            <br></br>
            <div className="columnCenter margin50Top">
              <Typography className="switchPopupPrimarySmallText fontSize20 margin10Bottom fontWeight700">
                {`Almost Done - Please enter your ${!isPickleball ? 'Pickleball' : 'Tennis'} Rating`}
              </Typography>
              <br></br>
              <Fragment>
                <Grid xs={12} item>
                  <Typography className="profileOptionalFieldText">
                    UTR Rating - Feature Not Yet Available
                  </Typography>
                  <DefaultTextField
                    state={utrRating}
                    setState={setUtrRating}
                    required={false}
                    placeholder="3.5"
                    disabled
                  />
                  <Typography className="profileOptionalFieldText posRelative margin10Top">
                    UTPR Rating
                    <InfoPopover isPickleball={!isPickleball} />
                  </Typography>
                  <MenuTextField
                    menuSelection={ntrpSelection}
                    state={ntrpRating}
                    setState={setNtrpRating}
                    required={false}
                    placeholder="3.5"
                  />
                  <div className="rowCenter" style={{visibility: 'hidden'}}>
                    <Typography className="profileRequiredFieldText margin10Top">
                      Current GSM Rating (Changes based on your singles
                      performance)
                    </Typography>
                  </div>
                  <div className="logindiv margin30Top">
                    <Button variant="text" onClick={() => handleClose()}>
                      <div className="rowCenter ">
                        <Typography className="switchPopupBackButtonText">
                          {isPickleball
                            ? "Back to Pickleball"
                            : "Back to Tennis"}
                        </Typography>
                      </div>
                    </Button>
                    <Button
                      className="switchPopUpButton"
                      onClick={() => handleSubmit()}
                      disabled={loading}
                    >
                      Done
                    </Button>
                  </div>
                </Grid>
              </Fragment>
            </div>
          </Grid>
        </Grid>
      </div>
    </DefaultDialog>
  );
};

export default windowSize(SwitchSportPopup);

import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Loader from "react-loader-spinner";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import { firestore } from "../../firebase";
import TableComponent from "../../components/table/TableComponent";
import DirectorApplicationReview from "./DirectorApplicationReview";
import { getUserByID } from "../../functions/firebaseFunctions";

const header = [
  { label: "name", content: "Name", sortable: true },
  { label: "type", content: "Type", sortable: true },
  { label: "status", content: "Status", sortable: true },
  { label: "date", content: "Date", sortable: true },
];
const AdminManageDirector = ({ zoneList }) => {
  const [loading, setLoading] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedDirector, setSelectedDirector] = useState(undefined);
  const [selectedApp, setSelectedApp] = useState(undefined);
  const [users, setUsers] = useState([]);

  const draftFilter = (app) => {
    return app.status.indexOf("draft") !== 0;
  };
  useEffect(() => {
    const usersRef = firestore.collection("applications");
    const unsubscribeUsers = usersRef.onSnapshot( (usersSnapshot) => {
      setUsers(
        usersSnapshot.docs.map( (userDoc) => {
          const appData = userDoc.data();
          const ref = userDoc.ref;
          let name = appData.name;
          let type = appData.type;
          const status = appData.status;
          const date =  moment(appData.dateCreated.toDate()).format(
            "MMM D, YYYY"
          );
          return {
            cells: [
              { content: name, editable: false },
              { content: type, editable: false },
              { content: status, editable: false },
              { content: date, editable: false },
            ],
            name,
            type,
            status,
            date,
            ref,
            information: { ...appData, ref: ref, id: userDoc.id },
          };
        })
      );
      setLoading(false);
    });
    return () => {
      unsubscribeUsers();
    };
  }, []);
  const handleComponentEdit = async (rowSelected) => {
    await setSelectedApp(rowSelected);
    let director = await getUserByID(rowSelected.information.directorID);
    setSelectedDirector(director);
    setOpenEdit(true);
  };

  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );

  return (
    <div className="column filler">
      <Typography className="margin10Bottom" variant="h4">
        Director Applications
      </Typography>
      <DirectorApplicationReview
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        selectedDirector={selectedDirector}
        setSelectedDirector={setSelectedDirector}
        selectedApp={selectedApp}
        setSelectedApp={setSelectedApp}
        zoneList={zoneList}
      />
      <div className="pageWrapper">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <TableComponent
              list={users.filter((app) => draftFilter(app))}
              setList={setUsers}
              tableHeader={header}
              type="componentEdit"
              defaultSort="date"
              handleComponentEdit={handleComponentEdit}
              rowsPerPageOverride={10}
            />
          </Grid>
        </Grid>
        <div className="filler" />
      </div>
    </div>
  );
};

export default AdminManageDirector;

import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SearchBar from "../../components/SearchBar";
import DropdownCheckbox from "../../components/DropdownCheckbox";
import { MenuTextField } from "../../components/CustomTextField";
import createZoneFilterList from "../../functions/Zoning.js";
const LeagueSearchBar = ({
  search,
  setSearch,
  sortBy,
  setSortBy,
  sortOptions,
  divisionSelect,
  setDivisionSelect,
  levelSelect,
  setLevelSelect,
  user,
  sectionSelect,
  setSectionSelect,
  localeSelect,
  setLocaleSelect,
  areaSelect,
  setAreaSelect,
  zoneList,
}) => {
  const [sectionList, setSectionList] = useState([]);
  const [localeList, setLocaleList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  useEffect(() => {
    createZoneFilterList(
      zoneList,
      user.playLocation.sectionals,
      user.playLocation.locale,
      setSectionList,
      setLocaleList,
      setAreaList
    );
    // eslint-disable-next-line
  }, [user.playLocation.locale, user.playLocation.sectionals]);
  const handleFilterReset = () => {
    setSortBy("Date: Earliest-Latest");
    setSearch("");
    setDivisionSelect([
      { label: "Men's Singles", check: true },
      { label: "Men's Doubles", check: true },
      { label: "Women's Singles", check: true },
      { label: "Women's Doubles", check: true },
      { label: "Coed Singles", check: true },
      { label: "Coed Doubles", check: true },
    ]);
    setLevelSelect([
      { label: "Everyone", check: true, level: 0 },
      { label: "1.0 - Beginner", check: true, level: 1.0 },
      { label: "1.5 - Beginner", check: true, level: 1.5 },
      { label: "2.0 - Beginner", check: true, level: 2.0 },
      { label: "2.5 - Beginner", check: true, level: 2.5 },
      { label: "3.0 - Novice", check: true, level: 3.0 },
      { label: "3.5 - Intermediate", check: true, level: 3.5 },
      { label: "4.0 - Advanced", check: true, level: 4.0 },
      { label: "4.5 - Advanced", check: true, level: 4.5 },
      { label: "5.0 - Competitive/D1", check: true, level: 5.0 },
      { label: "5.5 - Competitive/D1", check: true, level: 5.5 },
      { label: "6.0 - Club-Level Pro", check: true, level: 6.0 },
      { label: "6.5 - Club-Level Pro", check: true, level: 6.5 },
      { label: "7.0 - Circuit Level Pro", check: true, level: 7.0 },
      { label: "7.5 - Circuit Level Pro", check: true, level: 7.5 },
      { label: "8.0 - World Class", check: true, level: 8.0 },
    ]);
    setSectionSelect(user.playLocation.sectionals);
    setLocaleSelect(user.playLocation.locale);
    setAreaSelect("All");
    createZoneFilterList(
      zoneList,
      user.playLocation.sectionals,
      user.playLocation.locale,
      setSectionList,
      setLocaleList,
      setAreaList
    );
  };
  const handleSectionalChange = (e) => {
    let section = e.target.value;
    setSectionSelect(section);
    setLocaleSelect("All");
    setAreaSelect("All");
    if (section !== "All")
      createZoneFilterList(
        zoneList,
        section,
        "",
        setSectionList,
        setLocaleList,
        setAreaList
      );
  };
  const handleLocaleChange = (e) => {
    let locale = e.target.value;
    setLocaleSelect(locale);
    setAreaSelect("All");
    if (locale !== "All")
      createZoneFilterList(
        zoneList,
        sectionSelect,
        locale,
        setSectionList,
        setLocaleList,
        setAreaList
      );
  };
  return (
    <SearchBar search={search} setSearch={setSearch}>
      <div className="searchBar6Fields">
        <TextField
          fullWidth={true}
          select
          value={sortBy}
          onChange={(e) => {
            setSortBy(e.target.value);
          }}
          placeholder="Sort By"
        >
          {sortOptions.map((sortO, key) => (
            <MenuItem key={key} value={sortO}>
              {sortO}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className="searchBar6Fields">
        <MenuTextField
          state={sectionSelect}
          setState={setSectionSelect}
          menuSelection={sectionList}
          customFunction={handleSectionalChange}
        />
      </div>
      <div className="searchBar6Fields">
        <MenuTextField
          state={localeSelect}
          setState={setLocaleSelect}
          menuSelection={localeList}
          customFunction={handleLocaleChange}
          disabled={sectionSelect === "All"}
        />
      </div>
      <div className="searchBar6Fields">
        <MenuTextField
          state={areaSelect}
          setState={setAreaSelect}
          menuSelection={areaList}
          disabled={localeSelect === "All" || sectionSelect === "All"}
        />
      </div>
      <div className="searchBar6Fields">
        <DropdownCheckbox
          checkboxGroupName="Division"
          checkboxes={divisionSelect}
          setCheckboxes={setDivisionSelect}
        />
      </div>
      <div className="searchBar6Fields">
        <DropdownCheckbox
          checkboxGroupName="Level"
          checkboxes={levelSelect}
          setCheckboxes={setLevelSelect}
        />
      </div>
      <div className="filler" />
      <div className="searchBarClearField">
        <Button variant="text" onClick={handleFilterReset}>
          <Typography className="leagueSearchBarClearText">
            Clear All
          </Typography>
        </Button>
      </div>
    </SearchBar>
  );
};
export default LeagueSearchBar;

import React, { Fragment, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Phone from "@material-ui/icons/Phone";
import Mail from "@material-ui/icons/Mail";
import FileCopy from "@material-ui/icons/FileCopy";
import Icon from "@mdi/react";
import { mdiPodiumGold } from "@mdi/js";
import parsePhoneNumber from "libphonenumber-js";
const LeaguePageSideInfo = ({ league, director }) => {
  const [copySuccessPhone, setCopySuccessPhone] = useState(false);
  const [copySuccessMail, setCopySuccessMail] = useState(false);
  const copyToClipBoard = (copy) => {
    if (copy === "Phone") {
      copy = director.phoneNumber;
      setCopySuccessPhone(true);
      setTimeout(() => {
        setCopySuccessPhone(false);
      }, 500);
    } else if (copy === "Mail") {
      copy = director.email;
      setCopySuccessMail(true);
      setTimeout(() => {
        setCopySuccessMail(false);
      }, 500);
    }
    const text = document.createElement("textarea");
    text.innerText = copy;
    document.body.appendChild(text);
    text.select();
    document.execCommand("copy");
    text.remove();
  };
  return (
    <Fragment>
      <Grid xs={12} item>
        <Card className="card">
          <div className="rowCenter">
            <Avatar
              variant="circular"
              className="image100"
              src={director.imageURL}
            />
            <div className="column">
              <Typography className="viewLeagueCardHeader margin10Left">{`Event Director: ${director.firstName} ${director.lastName}`}</Typography>
              <div className="rowCenterMinWidth">
                <Tooltip
                  title={
                    copySuccessPhone
                      ? "Copied!"
                      : parsePhoneNumber(
                          director.phoneNumber,
                          "US"
                        ).formatNational()
                  }
                >
                  <IconButton
                    onClick={() => copyToClipBoard("Phone")}
                    className="viewLeagueDirectorContactButton"
                  >
                    {copySuccessPhone ? (
                      <FileCopy className="viewLeagueDirectorContactIcon" />
                    ) : (
                      <Phone className="viewLeagueDirectorContactIcon" />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip title={copySuccessMail ? "Copied!" : director.email}>
                  <IconButton
                    onClick={() => copyToClipBoard("Mail")}
                    className="viewLeagueDirectorContactButton"
                  >
                    {copySuccessMail ? (
                      <FileCopy className="viewLeagueDirectorContactIcon" />
                    ) : (
                      <Mail className="viewLeagueDirectorContactIcon" />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
        </Card>
      </Grid>
      <Grid xs={12} item>
        <Card className="card">
          <Typography className="viewLeagueCardHeader margin20Bottom">
            Social
          </Typography>
          <div className="rowCenter">
            <Icon
              className="viewLeagueRewardIcon"
              size="24px"
              path={mdiPodiumGold}
            />
            <Typography className="viewLeagueRewardText margin10Left margin20Right">
              Tags
            </Typography>
            <Typography className="viewLeagueRewardValueText">
              {league.tag}
            </Typography>
          </div>
          <div className="rowCenter margin10Vertical">
            <Icon
              className="viewLeagueRewardIcon"
              size="24px"
              path={mdiPodiumGold}
            />
            <Typography className="viewLeagueRewardText margin10Left margin20Right">
              Social Media
            </Typography>
            <Typography className="viewLeagueRewardValueText">
              {league.social}
            </Typography>
          </div>
        </Card>
      </Grid>
    </Fragment>
  );
};

export default LeaguePageSideInfo;

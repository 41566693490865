import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import update from "immutability-helper";
import { InfoPopover } from "../../signin/InfoPopover";
const PageThree = ({ divisionSelect, setDivisionSelect, format }) => {
  const filterByName = (l) => {
    if (format === "Flex") {
      if (
        l.label === "Men's Singles" ||
        l.label === "Women's Singles" ||
        l.label === "Coed Singles"
      ) {
        return true;
      }
    } else {
      if (l.label === "Coed Doubles" ||
          l.label === "Men's Singles" ||
          l.label === "Women's Singles" ||
          l.label === "Coed Singles") {
        return true;
      }
    }
  };
  const displayList = divisionSelect.filter((l) => filterByName(l));
  return (
    <Fragment>
      <div className="row posRelative">
        <Typography className="createLeagueFieldText">
          What divisions will your league have?
        </Typography>
        <InfoPopover />
      </div>
      <div className="checkboxWrapperVertical">
        {displayList.map((division, i) => (
          <Fragment key={i}>
            <FormControlLabel
              value={division.label}
              control={
                <Checkbox
                  color="primary"
                  checked={division.check}
                  onChange={(e) => {
                    const temp = update(displayList, {
                      [i]: {
                        check: {
                          $set: !displayList[i].check,
                        },
                      },
                    });
                    setDivisionSelect(temp);
                  }}
                />
              }
              label={division.label}
              labelPlacement="end"
            />
            {displayList[i].check && (
              <Fragment>
                <div className="checkboxWrapperHorizontal">
                  <Typography className="createLeagueFieldText">
                    Levels
                  </Typography>
                </div>
                <div className="checkboxWrapperHorizontal">
                  {displayList[i].playLevels.map((level, j) => {
                    if (j > 4) return <Fragment key={j} />;
                    return (
                      <FormControlLabel
                        key={j}
                        value={level.label}
                        control={
                          <Checkbox
                            color="primary"
                            checked={level.check}
                            onChange={(e) => {
                              const temp = update(displayList, {
                                [i]: {
                                  playLevels: {
                                    [j]: {
                                      check: {
                                        $set: !displayList[i].playLevels[j]
                                          .check,
                                      },
                                    },
                                  },
                                },
                              });
                              setDivisionSelect(temp);
                            }}
                          />
                        }
                        label={level.label}
                        labelPlacement="top"
                      />
                    );
                  })}
                </div>
                <div className="checkboxWrapperHorizontal">
                  {displayList[i].playLevels.map((level, j) => {
                    if (j < 5 || j > 9) return <Fragment key={j} />;
                    return (
                      <FormControlLabel
                        key={j}
                        value={level.label}
                        control={
                          <Checkbox
                            color="primary"
                            checked={level.check}
                            onChange={(e) => {
                              const temp = update(displayList, {
                                [i]: {
                                  playLevels: {
                                    [j]: {
                                      check: {
                                        $set: !displayList[i].playLevels[j]
                                          .check,
                                      },
                                    },
                                  },
                                },
                              });
                              setDivisionSelect(temp);
                            }}
                          />
                        }
                        label={level.label}
                        labelPlacement="top"
                      />
                    );
                  })}
                </div>
                <div className="checkboxWrapperHorizontal">
                  {displayList[i].playLevels.map((level, j) => {
                    if (j < 10) return <Fragment key={j} />;
                    return (
                      <FormControlLabel
                        key={j}
                        value={level.label}
                        control={
                          <Checkbox
                            color="primary"
                            checked={level.check}
                            onChange={(e) => {
                              const temp = update(displayList, {
                                [i]: {
                                  playLevels: {
                                    [j]: {
                                      check: {
                                        $set: !displayList[i].playLevels[j]
                                          .check,
                                      },
                                    },
                                  },
                                },
                              });
                              setDivisionSelect(temp);
                            }}
                          />
                        }
                        label={level.label}
                        labelPlacement="top"
                      />
                    );
                  })}
                </div>
              </Fragment>
            )}
          </Fragment>
        ))}
      </div>
    </Fragment>
  );
};

export default PageThree;

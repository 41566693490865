import React, { Fragment } from "react";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import {
  DefaultTextField,
  RichTextField,
} from "../../../components/CustomTextField";
import CheckboxList from "../../../components/CheckboxList";
const PageOne = ({
  eventName,
  setEventName,
  setEventDescription,
  eventDescriptionSaved,
  imageDisplay,
  setImageDisplay,
  setImageFile,
  coverImageDisplay,
  setCoverImageDisplay,
  setCoverImageFile,
  areaCheckboxList,
  setAreaCheckboxList,
  error,
  errorMessage,
}) => {
  return (
    <Fragment>
      <Typography className="createLeagueFieldText">
        Name your Event
      </Typography>
      <DefaultTextField
        required={true}
        state={eventName}
        setState={setEventName}
        placeholder="Event Title"
      />
      <Typography className="createLeagueFieldText margin20Top">
        Describe your event
      </Typography>
      <RichTextField
        setValue={setEventDescription}
        defaultValue={eventDescriptionSaved}
        placeholder="Describe your event..."
      />
      <Typography className="createLeagueFieldText margin20Top">
        Areas your event to be available in?
      </Typography>
      <CheckboxList value={areaCheckboxList} setValue={setAreaCheckboxList} />
      <div className="heightPlaceholder">
        {error && (
          <Typography className="createLeagueErrorText">
            {errorMessage}
          </Typography>
        )}
      </div>
      <div className="rowCenter margin20Top">
        <div className=" margin10Right">
          <Typography className="createLeagueFieldText">
            Upload event image
          </Typography>
          <div className="createLeagueImageWrapper">
            <label htmlFor="leagueImage">
              <Avatar
                variant="rounded"
                className="profilePhoto padding10Horizontal"
                src={imageDisplay}
              />
            </label>
            <input
              type="file"
              hidden
              id="leagueImage"
              onChange={(e) => {
                setImageFile(e.target.files[0]);
                if (e.target.files[0] && e.target.files)
                  setImageDisplay(URL.createObjectURL(e.target.files[0]));
              }}
            />
          </div>
        </div>
        <div className="columnCenterMaxWidth margin10Left">
          <Typography className="createLeagueFieldText">
            Upload a cover image for your event
          </Typography>
          <div className="createLeagueImageWrapper">
            <label htmlFor="coverLeagueImage">
              <img
                className="createLeagueCoverImage padding10Horizontal"
                alt="alt"
                src={coverImageDisplay}
              />
            </label>
            <input
              type="file"
              hidden
              id="coverLeagueImage"
              onChange={(e) => {
                setCoverImageFile(e.target.files[0]);
                if (e.target.files[0] && e.target.files)
                  setCoverImageDisplay(URL.createObjectURL(e.target.files[0]));
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PageOne;

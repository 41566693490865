import React, { useState, Fragment, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import { firestore, storage } from "../../../firebase";
import { singleActionEmail } from "../../../functions/Messaging";
import CustomStepper from "../../../components/CustomStepper";
import PageOne from "./PageOne";
import PageTwo from "./PageTwo";
import PageThree from "./PageThree";
import PageFour from "./PageFour";
import PageFive from "./PageFive";
import coverImageTemp from "../../../res/leagueDefaultBackground.jpg";
import imageTemp from "../../../res/leagueDefaultImage.jpg";
const CreateEvent = ({ user, zoneList }) => {
  //Stepper Info
  const [activeStep, setActiveStep] = useState(0);
  const stepNames = [
    "Event Information",
    "Details",
    "Promotion",
    "Disclosures",
    "Pricing",
  ];
  //Draft Info
  const [submittingApp, setSubmittingApp] = useState(false);
  const [savingDraftApplication, setSavingDraftApplication] = useState(false);
  const [currDraft, setCurrDraft] = useState(null);
  const [saved, setSaved] = useState(false);
  const [completedApplication, setCompletedApplication] = useState(false);
  const [eventName, setEventName] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventDescriptionSaved, setEventDescriptionSaved] = useState("");
  const [areaCheckboxList, setAreaCheckboxList] = useState([]);
  const [directorZoneInfo, setDirectorZoneInfo] = useState({});

  const [format, setFormat] = useState("Celebrity Appearance");
  const [tag, setTag] = useState("");
  const [timeboxed, setTimeboxed] = useState(false);

  const [startDate, setStartDate] = useState(moment().add(8, "days").toDate());
  const [startDateDisplay, setStartDateDisplay] = useState(
    moment().add(8, "days").format("MM/DD/YYYY")
  );
  const [endDate, setEndDate] = useState(moment().add(9, "days").toDate());
  const [endDateDisplay, setEndDateDisplay] = useState(
    moment().add(9, "days").format("MM/DD/YYYY")
  );
  const [regEnd, setRegEnd] = useState("One week before the event starts");
  const [frequency, setFrequency] = useState("single time event");
  const [numPeople, setNumPeople] = useState([3, 90]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [social, setSocial] = useState("");

  const [joinFee, setJoinFee] = useState(0);
  const [percentCut, setPercentCut] = useState(0);
  const [splitType, setSplitType] = useState(null);
  const [disclosure, setDisclosure] = useState(null);
  const [policy, setPolicy] = useState(null);
  const [disclosureSaved, setDisclosureSaved] = useState("");
  const [policySaved, setPolicySaved] = useState("");
  const [imageFile, setImageFile] = useState(imageTemp);
  const [imageDisplay, setImageDisplay] = useState(imageTemp);
  const [coverImageDisplay, setCoverImageDisplay] = useState(coverImageTemp);
  const [coverImageFile, setCoverImageFile] = useState(coverImageTemp);
  useEffect(() => {
    const targetLocale = user.playLocation.locale;
    const targetSectional = user.playLocation.sectionals;
    const directorAreaID = user.playLocation.areaID;
    let areaCheckboxList = [];
    zoneList
      .filter(
        (location) =>
          location.locale === targetLocale &&
          location.sectionals === targetSectional
      )
      .forEach((location) => {
        const area = location.area;
        const areaID = location.areaID;
        if (!areaCheckboxList.some((item) => item.value === areaID))
          areaCheckboxList.push({
            label: area,
            check: areaID === directorAreaID ? true : false,
            value: areaID,
          });
      });
    setAreaCheckboxList(areaCheckboxList);
    setDirectorZoneInfo({
      section: targetSectional,
      locale: targetLocale,
    });
    let url = window.location.href;
    let params = url.split("/");
    let draftID = params[params.length - 1];
    const draftRef = firestore.collection("leagues").doc(draftID);
    const unsubscribeDraft = draftRef.onSnapshot((snapshot) => {
      if (draftID !== "CreateEvent") {
        const data = snapshot.data();
        const draft = { id: snapshot.id, ref: snapshot.ref, ...data };
        setCompletedApplication(draft.status !== "draft");
        autoFillDraftInfo(draft, areaCheckboxList);
      }
    });
    return () => {
      unsubscribeDraft();
    };
  }, [user, zoneList]);
  const sendMessage = async (draftID) => {
    await singleActionEmail({
      subject: `An event has been submitted for proposal!`,
      message: `${user.firstName} ${user.lastName}'s event, ${eventName} is ready for review!`,
      recipients: [{ email: "Admin@playgamesetmatch.com", name: "Admin" }],
      url: `${process.env.REACT_APP_BASE_URL}Admin/ManageLeague/${draftID}`,
      buttonText: "Review Event",
    });
  };
  const addEventFiles = async (id) => {
    let getURL = "";
    //let get
    if (typeof imageFile !== "string") {
      await storage.ref(`leagueDocuments/${id}/leaguePhoto`).put(imageFile);
      await storage
        .ref()
        .child(`leagueDocuments/${id}/leaguePhoto`)
        .getDownloadURL()
        .then((url) => {
          getURL = url;
        });
    } else if (currDraft === null) {
      await storage
        .ref()
        .child(`adminDocuments/league/leaguePhoto.jpg`)
        .getDownloadURL()
        .then((url) => {
          getURL = url;
        });
    }
    if (typeof imageFile !== "string" || currDraft === null)
      await firestore.doc(`leagues/${id}`).update({ imageURL: getURL });
    //repeat process for cover image
    getURL = "";
    if (typeof coverImageFile !== "string") {
      await storage.ref(`leagueDocuments/${id}/coverImage`).put(coverImageFile);
      await storage
        .ref()
        .child(`leagueDocuments/${id}/coverImage`)
        .getDownloadURL()
        .then((url) => {
          getURL = url;
        });
    } else if (currDraft === null) {
      await storage
        .ref()
        .child(`adminDocuments/league/coverImage.jpg`)
        .getDownloadURL()
        .then((url) => {
          getURL = url;
        });
    }
    if (typeof coverImageFile !== "string" || currDraft === null)
      await firestore.doc(`leagues/${id}`).update({ coverImageURL: getURL });
    if (currDraft === null) {
      await storage
        .ref()
        .child(`adminDocuments/league/GSM Standard Rules and Regulations.pdf`)
        .getDownloadURL()
        .then((url) => {
          getURL = url;
        });
      await firestore.doc(`leagues/${id}`).update({ rules: getURL });
    }
  };
  const addEventDoc = async (status) => {
    const locations = createLocationsList();
    let draftID = "";
    await firestore
      .collection("leagues")
      .add({
        directorID: user.uid,
        name: eventName,
        event: true,
        description: eventDescription,
        format: format,
        timeboxed: timeboxed,
        startDate: startDate,
        endDate: endDate,
        registrationEndDate: regEnd,
        numPlayerRange: numPeople,
        locations: locations,
        locale: directorZoneInfo.locale,
        section: directorZoneInfo.section,
        price: joinFee,
        percentCut: percentCut,
        status: status,
        currStep: activeStep,
        frequency: frequency,
        policy: policy,
        disclosure: disclosure,
        tag: tag,
        social: social,
      })
      .then(async (docRef) => {
        await addEventFiles(docRef.id);
        if (status !== "pending") window.location.href += `/${docRef.id}`;
        draftID = docRef.id;
      });
    return draftID;
  };
  const updateEventDoc = async (status) => {
    const locations = createLocationsList();
    await currDraft.ref.update({
      name: eventName,
      description: eventDescription,
      format: format,
      timeboxed: timeboxed,
      startDate: startDate,
      endDate: endDate,
      registrationEndDate: regEnd,
      numPlayerRange: numPeople,
      locations: locations,
      locale: directorZoneInfo.locale,
      section: directorZoneInfo.section,
      price: joinFee,
      percentCut: percentCut,
      status: status,
      currStep: activeStep,
      frequency: frequency,
      policy: policy,
      disclosure: disclosure,
      tag: tag,
      social: social,
      eventStarted: false,
    });
    addEventFiles(currDraft.ref.id);
    return currDraft.ref.id;
  };

  const createLocationsList = () => {
    let locations = [];
    areaCheckboxList.forEach((location) => {
      if (location.check) locations.push(location.value);
    });
    return locations;
  };

  const submitEventApplication = async () => {
    setSubmittingApp(true);
    let draftID = "";
    if (currDraft === null) draftID = await addEventDoc("pending");
    else draftID = await updateEventDoc("pending");
    await sendMessage(draftID);
    setCompletedApplication(true);
    setActiveStep(0);
    setSubmittingApp(false);
  };

  const submitDraftApplication = async () => {
    setSavingDraftApplication(true);
    if (currDraft === null && !saved) {
      await addEventDoc("draft");
      setSaved(true);
    } else {
      await updateEventDoc("draft");
    }
    setSavingDraftApplication(false);
  };
  const autoFillDraftInfo = (ds, areaCheckboxList) => {
    setCurrDraft(ds);
    setEventName(ds.name);
    if (ds.imageURL !== "") setImageDisplay(ds.imageURL);
    if (ds.coverImageURL !== "") setCoverImageDisplay(ds.coverImageURL);
    setEventDescription(ds.description);
    setEventDescriptionSaved(ds.description);
    setFormat(ds.format);
    setTimeboxed(ds.timeboxed);
    setSocial(ds.social);
    setDisclosure(ds.disclosure);
    setDisclosureSaved(ds.disclosure);
    setPolicy(ds.policy);
    setPolicySaved(ds.policy);
    setTag(ds.tag);
    if (ds.startDate) {
      let date = ds.startDate.toDate();
      setStartDate(date);
      setStartDateDisplay(moment(date).format("MM/DD/YYYY"));
    }

    if (ds.endDate) {
      let date = ds.endDate.toDate();
      setEndDate(date);
      setEndDateDisplay(moment(date).format("MM/DD/YYYY"));
    }
    setRegEnd(ds.registrationEndDate);
    setNumPeople(ds.numPlayerRange);
    setJoinFee(ds.price);
    setPercentCut(ds.percentCut);
    if (ds.percentCut === 80) setSplitType("Gold");
    else if (ds.percentCut === 50) setSplitType("Silver");
    else if (ds.percentCut === 20) setSplitType("Bronze");
    else setSplitType("Old");
    setActiveStep(ds.currStep);
    setAreaCheckboxList(
      areaCheckboxList.map((location) => {
        location.check = ds.locations.some(
          (areaID) => areaID === location.value
        );
        return location;
      })
    );
  };
  const handleBack = () => {
    setActiveStep((step) => step - 1);
  };
  const verifyLocationSelected = () => {
    let oneSelected = false;
    areaCheckboxList.forEach((check) => {
      if (check.check) oneSelected = true;
    });
    return oneSelected;
  };
  const submitPage = (e) => {
    e.preventDefault();
    if (activeStep === 0) {
      setEventDescriptionSaved(eventDescription);
      if (verifyLocationSelected()) {
        setActiveStep((step) => step + 1);
        setError(false);
        setErrorMessage("");
      } else {
        setError(true);
        setErrorMessage("Must select at least one location");
      }
    } else if (activeStep === 2) {
      setActiveStep((step) => step + 1);
    } else if (activeStep === 3) {
      setPolicySaved(policy);
      setDisclosure(disclosure);
      setActiveStep((step) => step + 1);
    } else if (activeStep === 1) {
      setActiveStep((step) => step + 1);
    } else if (activeStep === 4) {
      if (percentCut === 0) {
        setError(true);
        setErrorMessage("Select a valid cut.");
      } else {
        setError(false);
        setErrorMessage("");
        submitEventApplication();
      }
    }
  };
  return (
    <Grid container spacing={6}>
      {!completedApplication && (
        <Fragment>
          <Grid item xs={12} md={12} lg={12}>
            <CustomStepper
              step={activeStep}
              setStep={setActiveStep}
              stepLabels={stepNames}
            />
          </Grid>
          <Grid className="columnCenter" item xs={12} md={12} lg={12}>
            <form className="widthHalf" onSubmit={submitPage}>
              {activeStep === 0 && (
                <PageOne
                  setActiveStep={setActiveStep}
                  eventName={eventName}
                  setEventName={setEventName}
                  eventDescriptionSaved={eventDescriptionSaved}
                  eventDescription={eventDescription}
                  setEventDescription={setEventDescription}
                  imageDisplay={imageDisplay}
                  setImageDisplay={setImageDisplay}
                  setImageFile={setImageFile}
                  coverImageDisplay={coverImageDisplay}
                  setCoverImageDisplay={setCoverImageDisplay}
                  setCoverImageFile={setCoverImageFile}
                  areaCheckboxList={areaCheckboxList}
                  setAreaCheckboxList={setAreaCheckboxList}
                  error={error}
                  errorMessage={errorMessage}
                />
              )}
              {activeStep === 1 && (
                <PageTwo
                  startDateDisplay={startDateDisplay}
                  setStartDateDisplay={setStartDateDisplay}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  endDateDisplay={endDateDisplay}
                  setEndDateDisplay={setEndDateDisplay}
                  setEndDate={setEndDate}
                  regEnd={regEnd}
                  setRegEnd={setRegEnd}
                  numPeople={numPeople}
                  setNumPeople={setNumPeople}
                  frequency={frequency}
                  setFrequency={setFrequency}
                  error={error}
                  errorMessage={errorMessage}
                />
              )}
              {activeStep === 2 && (
                <PageThree
                  format={format}
                  setFormat={setFormat}
                  tag={tag}
                  setTag={setTag}
                  social={social}
                  setSocial={setSocial}
                />
              )}
              {activeStep === 3 && (
                <PageFour
                  disclosure={disclosure}
                  setDisclosure={setDisclosure}
                  disclosureSaved={disclosureSaved}
                  policy={policy}
                  setPolicy={setPolicy}
                  policySaved={policySaved}
                />
              )}
              {activeStep === 4 && (
                <PageFive
                  joinFee={joinFee}
                  setJoinFee={setJoinFee}
                  percentCut={percentCut}
                  setPercentCut={setPercentCut}
                  splitType={splitType}
                  setSplitType={setSplitType}
                  error={error}
                  errorMessage={errorMessage}
                />
              )}
              <div className="maxWidth margin20Vertical">
                <Divider />
              </div>
              <div className="rowCenter">
                <Button
                  variant="text"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  <div className="rowCenter">
                    <ArrowBackIos className="createLeagueBackButtonIcon margin10Right" />
                    <Typography className="createLeagueUtilityButtonText">
                      Back
                    </Typography>
                  </div>
                </Button>
                <div className="filler" />
                <Button
                  variant="text"
                  onClick={submitDraftApplication}
                  disabled={savingDraftApplication}
                >
                  {savingDraftApplication ? (
                    <Loader
                      type="TailSpin"
                      color="#02c39a"
                      height={40}
                      width={40}
                      timeout={3000}
                    />
                  ) : (
                    <Typography className="createLeagueUtilityButtonText">
                      Save Draft
                    </Typography>
                  )}
                </Button>
                <Button
                  className="createLeagueNextButton margin10Left"
                  type="submit"
                  disabled={submittingApp}
                >
                  {submittingApp ? (
                    <Loader
                      type="TailSpin"
                      color="#02c39a"
                      height={40}
                      width={40}
                      timeout={3000}
                    />
                  ) : (
                    <Typography className="createLeagueNextButtonText">
                      {activeStep !== 4 ? "Next" : "Submit"}
                    </Typography>
                  )}
                </Button>
              </div>
            </form>
          </Grid>
        </Fragment>
      )}
      {completedApplication && (
        <Grid className="columnCenter" item xs={12} md={12} lg={12}>
          <div className="widthHalf">
            <Typography variant="h4">
              {`Thank you for submitting your proposed Event, ${eventName}.`}
            </Typography>
            <Typography variant="h4">
              We will be reviewing your event submission shortly and get back to
              you if we have any questions.
            </Typography>
            <Typography variant="h4">
              Please monitor your event page for updates. Best wishes.
            </Typography>
            <div className="margin30" />
            <div className="columnCenter">
              <Link to="/Leagues" className="noUnderline">
                <Button className="createLeagueNextButton">View Events</Button>
              </Link>
            </div>
          </div>
        </Grid>
      )}
    </Grid>
  );
};
export default CreateEvent;

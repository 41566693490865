import React, { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import MUIRichTextEditor from "mui-rte";
import { convertToRaw } from "draft-js";
import { AsYouType, formatIncompletePhoneNumber } from "libphonenumber-js";
export const DefaultTextField = ({
  state,
  setState,
  required,
  placeholder,
  autoComplete,
  multiline,
  type,
  disabled,
}) => {
  const handleChange = (e) => {
    if (type === "number") {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        setState(e.target.value);
      }
    } else setState(e.target.value);
  };
  return (
    <TextField
      value={state}
      onChange={handleChange}
      required={required}
      placeholder={placeholder}
      autoComplete={autoComplete}
      className=""
      fullWidth
      rows={multiline}
      multiline={multiline !== null}
      disabled={disabled}
    />
  );
};
export const DisplayTextField = ({ value, multiline }) => {
  return (
    <TextField
      value={value}
      fullWidth
      rows={multiline}
      multiline={multiline !== null}
      disabled={true}
    />
  );
};

export const PasswordTextField = ({
  state,
  setState,
  required,
  placeholder,
  autoComplete,
  disabled,
}) => {
  const handleChange = (e) => {
    setState(e.target.value);
  };
  return (
    <TextField
      value={state}
      onChange={handleChange}
      required={required}
      placeholder={placeholder}
      autoComplete={autoComplete}
      className=""
      fullWidth
      type="password"
      disabled={disabled}
    />
  );
};

export const MenuTextField = ({
  state,
  setState,
  required,
  menuSelection,
  customFunction,
  disabled,
}) => {
  const handleChange = (e) => {
    setState(e.target.value);
  };
  return (
    <TextField
      value={state}
      disabled={disabled}
      onChange={customFunction ? customFunction : handleChange}
      select
      fullWidth
      required={required}
      className=""
    >
      {menuSelection?.map((selection, index) => (
        <MenuItem key={index} value={selection.value}>
          {selection.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const DateTextField = ({
  date,
  setDate,
  dateString,
  setDateString,
  required,
  disabled=false
}) => {
  const onDateChange = (date, value) => {
    setDate(date);
    setDateString(value);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        fullWidth
        clearable
        required={required}
        inputVariant="outlined"
        openTo="year"
        format="MM/dd/yyyy"
        views={["year", "month", "date"]}
        value={date}
        inputValue={dateString}
        disabled={disabled}
        onChange={onDateChange}
      />
    </MuiPickersUtilsProvider>
  );
};
export const BirthdayTextField = ({
  state,
  setState,
  setDateState,
  required,
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        fullWidth
        clearable
        required={required}
        inputVariant="outlined"
        openTo="year"
        format="MM/dd/yyyy"
        views={["year", "month", "date"]}
        value={state}
        onChange={(date) => {
          if (date) {
            const dateCreated = moment(date).format("MM/DD/YYYY");
            if (setDateState !== undefined) {
              setDateState(date);
            }
            setState(dateCreated);
          }
        }}
      />
    </MuiPickersUtilsProvider>
  );
};
export const TimeTextField = ({ time, setTime, required }) => {
  const onTimeChange = (time) => {
    setTime(time);
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        fullWidth
        clearable
        required={required}
        inputVariant="outlined"
        value={time}
        onChange={onTimeChange}
      />
    </MuiPickersUtilsProvider>
  );
};

export const PhoneTextField = ({
  state,
  setState,
  required,
  disabled,
  autoComplete,
  placeholder,
}) => {
  const handleChange = (e) => {
    let newValue = new AsYouType("US").input(e.target.value);
    if (newValue === state) {
      const newValueFormatted = formatIncompletePhoneNumber(newValue, "US");
      if (newValueFormatted.indexOf(e.target.value) === 0) {
        // Trim the last digit (or plus sign).
        newValue = newValue.slice(0, -1);
      }
    }
    setState(newValue);
  };
  return (
    <TextField
      value={state}
      onChange={handleChange}
      required={required}
      placeholder={placeholder}
      autoComplete={autoComplete}
      fullWidth
      type="phone"
      disabled={disabled}
    />
  );
};

export const RichTextField = ({ setValue, placeholder, defaultValue, readOnly=false }) => {
  const focusedColor = "#55B56A";
  const unfocusedColor = "#c0c0c0";
  const [borderColor, setBorderColor] = useState(unfocusedColor);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#55B56A",
        light: "#EDF8F4",
        dark: "#028360",
      },
    },
    overrides: {
      MUIRichTextEditor: {
        root: {
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          boxShadow: "0px 3px 6px #00000029",
          minHeight: 300,
          paddingLeft: 10,
          paddingRight: 10,
          border: "1px solid",
          borderColor: borderColor,
        },
        editor: { padding: 10 },
      },
    },
  });
  const handleChange = (e) => {
    const text = JSON.stringify(convertToRaw(e.getCurrentContent()));
    setValue(text);
  };
  return (
    <ThemeProvider theme={theme}>
      <MUIRichTextEditor
        label={placeholder}
        defaultValue={defaultValue}
        controls={[
          "title",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "highlight",
          "undo",
          "redo",
          "link",
          "media",
          "numberList",
          "bulletList",
          "quote",
        ]}
        readOnly={readOnly}
        onChange={(e)=>!readOnly && handleChange(e)}
        onFocus={() => {
          setBorderColor(focusedColor);
        }}
        onBlur={() => {
          setBorderColor(unfocusedColor);
        }}
      />
    </ThemeProvider>
  );
};

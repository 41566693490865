import { ApiPost, ApiPut } from "../Networking";

export const getNotification = (id) => {
  return new Promise((resolve, reject) => {
    ApiPost(`/getNotifications`, { id })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const SendNotification = (body) => {
  return new Promise((resolve, reject) => {
    ApiPost(`/sendNotification`, body)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const UpdateNotificationRespondeStatus = async (id) => {
  return new Promise((resolve, reject) => {
    ApiPut(`/updateNotificationRespondeStatus`, { id })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const UpdateNotificationReadStatus = async (id) => {
  return new Promise((resolve, reject) => {
    ApiPut(`/updateNotificationReadStatus`, { id })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const PostLeagueDataIntoFacebookPage = async (data)=>{
  return new Promise((resolve, reject) => {
    ApiPost(`/postToFBPage`, data)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
  });
}
export const PostLeagueDataIntoTwitter = async (data)=>{
  return new Promise((resolve, reject) => {
    ApiPost(`/postToTwitter`, data)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
  });
}

import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SearchBar from "../../components/SearchBar";
import {
  DefaultTextField,
  MenuTextField,
} from "../../components/CustomTextField";

const matchTypesList = [
  { label: "All Types", value: "all" },
  { label: "Best of 3 Sets", value: "2/3Sets" },
  { label: "3 Set Tiebreaker", value: "2SetsWithTieBreaker" },
  { label: "10 Game Pro Set", value: "10GamePro" },
];
const sortByList = [
  { label: "Date - Newest-Oldest", value: "newest" },
  { label: "Date - Oldest-Newest", value: "oldest" },
];
const resultList = [
  { label: "All Matches", value: "all" },
  { label: "Matches Won", value: "win" },
  { label: "Matches Lost", value: "loss" },
];
const MatchSearchBar = ({
  search,
  setSearch,
  opponent,
  setOpponent,
  matchType,
  setMatchType,
  matchResult,
  setMatchResult,
  sortBy,
  setSortBy,
}) => {

  const handleFilterReset = () => {
    setOpponent("");
    setSearch("");
    setMatchType("all");
    setMatchResult("all");
    setSortBy("newest");
  };
  return (
    <SearchBar search={search} setSearch={setSearch}>
      <div className="searchBar4Fields">
        <MenuTextField
          menuSelection={sortByList}
          state={sortBy}
          setState={setSortBy}
        />
      </div>
      <div className="searchBar4Fields">
        <MenuTextField
          menuSelection={matchTypesList}
          state={matchType}
          setState={setMatchType}
        />
      </div>
      <div className="searchBar4Fields">
        <MenuTextField
          menuSelection={resultList}
          state={matchResult}
          setState={setMatchResult}
        />
      </div>
      <div className="searchBar4Fields">
        <DefaultTextField
          state={opponent}
          setState={setOpponent}
          placeholder="Type Opponent Name"
        />
      </div>
      <div className="filler" />
      <div className="searchBarClearField">
        <Button variant="text" onClick={handleFilterReset}>
          <Typography className="leagueSearchBarClearText">
            Clear All
          </Typography>
        </Button>
      </div>
    </SearchBar>
  );
};
export default MatchSearchBar;

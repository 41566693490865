import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ViewRichText from "../../../components/ViewRichText";
const LeaguePageInfo = ({ league, mobileSize }) => {
  return (
    <Fragment>
      <ViewRichText value={league.description} />
      <Grid container spacing={mobileSize ? 0 : 6}>
        <Grid item xs={12} md={6} lg={6} xl={6}>
          <Typography className="viewLeagueCategoryText">Event Type</Typography>
          <Typography className="viewLeagueDescriptionText">
            {league.format}
          </Typography>
          <Typography className="viewLeagueCategoryText">Policies</Typography>
          <ViewRichText value={league.policy} />
          {league.disclosure !== "" ? <div>
          <Typography className="viewLeagueCategoryText">Disclosure</Typography>
          <ViewRichText value={league.disclosure} /> 
          </div>
          : ""}
        </Grid>
        {/* <Grid item xs={12} md={6} lg={6} xl={6}>
          <Typography className="viewLeagueCategoryText">
            Tags
          </Typography>
          <Typography className="viewLeagueDescriptionText">
            {league.tag}
          </Typography>
          <Typography className="viewLeagueCategoryText">
            Social Media
          </Typography>
          <Typography className="viewLeagueDescriptionText">
            {league.social}
          </Typography>
        </Grid> */}
      </Grid>
    </Fragment>
  );
};

export default LeaguePageInfo;

import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
function SideMenu({ children }) {
  return (
    <Grid xs={12} md={8} lg={9} xl={9} item>
      <Card className="card profileContentWrapper">{children}</Card>
    </Grid>
  );
}

export default SideMenu;

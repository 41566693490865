import React from "react";
import Switch from "@material-ui/core/Switch";
export const DefaultSwitch = ({ state, setState, required }) => {
  const handleChange = (e) => {
    setState(e.target.checked);
  };
  return (
    <Switch
      color="primary"
      checked={state}
      onChange={handleChange}
      required={required}
    />
  );
};

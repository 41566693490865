import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";

export const ReviewByMe = ({ review, reviewer, reviewee }) => {
  return (
    <Card className="profileReviewMain">
      <div className="rowCenter">
        <Avatar className="image50" src={reviewee.imageURL} />
        <div className="column margin10Left">
          <Typography className="activityItemHeader">
            Reviewing {reviewee.firstName} {reviewee.lastName}
          </Typography>
          <Typography className="activityItemSubHeader">
            {review.displayDate}
          </Typography>
          <Typography className="activityItemSubHeader">
            {review.location !== undefined ? `At ${review.location}` : ""}
          </Typography>
        </div>
      </div>
      <div className="margin10Top" />
      <Divider />
      <div className="margin10Top" />
      <div className="column">
        <Typography className="activityItemDescription">
          {review.publicReview}
        </Typography>
      </div>
    </Card>
  );
};

export const ReviewAboutMe = ({ review, reviewer, reviewee }) => {
  return (
    <Card className="profileReviewMain">
      <div className="rowCenter">
        <Avatar className="image50" src={reviewer.imageURL} />
        <div className="column margin10Left">
          <Typography className="activityItemHeader">
            Reviewer {reviewer.firstName} {reviewer.lastName}
          </Typography>
          <Typography className="activityItemSubHeader">
            {review.displayDate}
          </Typography>
          <Typography className="activityItemSubHeader">
            {`${review.surface} Court`}
          </Typography>
        </div>
      </div>
      <div className="margin10Top" />
      <Divider />
      <div className="margin10Top" />
      <div className="column">
        <Typography className="activityItemDescription">
          {review.publicReview}
        </Typography>
      </div>
    </Card>
  );
};

import React, { useState, Fragment, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import { firestore, storage } from "../../../firebase";
import { singleActionEmail } from "../../../functions/Messaging";
import CustomStepper from "../../../components/CustomStepper";
import PageOne from "./PageOne";
import PageTwo from "./PageTwo";
import PageThree from "./PageThree";
import PageFour from "./PageFour";
import PageFive from "./PageFive";
import coverImageTemp from "../../../res/leagueDefaultBackground.jpg";
import imageTemp from "../../../res/leagueDefaultImage.jpg";
import { isValidInviteCode } from "../../../functions/firebaseFunctions";
let levelsTemplate = [
  { label: "1.0", check: false, level: 1.0 },
  { label: "1.5", check: false, level: 1.5 },
  { label: "2.0", check: false, level: 2.0 },
  { label: "2.5", check: false, level: 2.5 },
  { label: "3.0", check: false, level: 3.0 },
  { label: "3.5", check: false, level: 3.5 },
  { label: "4.0", check: false, level: 4.0 },
  { label: "4.5", check: false, level: 4.5 },
  { label: "5.0", check: false, level: 5.0 },
  { label: "5.5", check: false, level: 5.5 },
  { label: "6.0", check: false, level: 6.0 },
  { label: "6.5", check: false, level: 6.5 },
  { label: "7.0", check: false, level: 7.0 },
  { label: "7.5", check: false, level: 7.5 },
  { label: "8.0", check: false, level: 8.0 },
];
const CreateLeague = ({ user, zoneList }) => {
  //Stepper Info
  const isPickleball = user.currentSport === "pickleball";
  const [activeStep, setActiveStep] = useState(0);
  const stepNames = [
    "League Information",
    "Theme",
    "Divisions",
    "Details",
    "Pricing",
  ];
  //Draft Info
  const [submittingApp, setSubmittingApp] = useState(false);
  const [sportType, setSportType] = useState("tennis");
  const [savingDraftApplication, setSavingDraftApplication] = useState(false);
  const [currDraft, setCurrDraft] = useState(null);
  const [saved, setSaved] = useState(false);
  const [completedApplication, setCompletedApplication] = useState(false);
  const [leagueName, setLeagueName] = useState("");
  const [leagueDescription, setLeagueDescription] = useState("");
  const [leagueDescriptionSaved, setLeagueDescriptionSaved] = useState("");
  const [areaCheckboxList, setAreaCheckboxList] = useState([]);
  const [directorZoneInfo, setDirectorZoneInfo] = useState({});

  const [divisionSelect, setDivisionSelect] = useState([
    {
      label: "Men's Singles",
      check: false,
      playLevels: levelsTemplate,
    },
    {
      label: "Women's Singles",
      check: false,
      playLevels: levelsTemplate,
    },
    {
      label: "Men's Doubles",
      check: false,
      playLevels: levelsTemplate,
    },
    {
      label: "Women's Doubles",
      check: false,
      playLevels: levelsTemplate,
    },
    {
      label: "Coed Singles",
      check: false,
      playLevels: levelsTemplate,
    },
    {
      label: "Coed Doubles",
      check: false,
      playLevels: levelsTemplate,
    },
  ]);
  const [format, setFormat] = useState("Flex");
  const [timeboxed, setTimeboxed] = useState(false);
  const [startDate, setStartDate] = useState(moment().add(8, "days").toDate());
  const [startDateDisplay, setStartDateDisplay] = useState(
    moment().add(8, "days").format("MM/DD/YYYY")
  );
  const [endDate, setEndDate] = useState(moment().add(9, "days").toDate());
  const [endDateDisplay, setEndDateDisplay] = useState(
    moment().add(9, "days").format("MM/DD/YYYY")
  );
  const [regEnd, setRegEnd] = useState("One day before the league starts");
  const [numPeople, setNumPeople] = useState([3, 90]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [rules, setRules] = useState("Standard Rules");
  const [customRules, setCustomRules] = useState("");
  const [winCriteria, setWinCriteria] = useState(
    "Win by beating opponents in an advantage match"
  );
  const [scoringFormat, setScoringFormat] = useState("Ranked by matches won");
  const [joinFee, setJoinFee] = useState(0);
  const [firstPlacePrize, setFirstPlacePrize] = useState(0);
  const [secondPlacePrize, setSecondPlacePrize] = useState(0);
  const [thirdPlacePrize, setThirdPlacePrize] = useState(0);
  const [percentCut, setPercentCut] = useState(0);
  const [splitType, setSplitType] = useState(null);
  const [privateLeague, setPrivateLeague] = useState(false);
  const [nextBtnIsDisabled, setNextBtnIsDisabled] = useState(true);
  const [inviteCode, setInviteCode] = useState("");
  const [isShowErrorIcon, setIsShowErrorIcon] = useState(false);
  const [inviteCodeError, setInviteCodeError] = useState("");
  const [imageFile, setImageFile] = useState(imageTemp);
  const [imageDisplay, setImageDisplay] = useState(imageTemp);
  const [coverImageDisplay, setCoverImageDisplay] = useState(coverImageTemp);
  const [coverImageFile, setCoverImageFile] = useState(coverImageTemp);

  useEffect(() => {
    setSportType(isPickleball ? "pickleball" : "tennis");
  }, [isPickleball]);

  useEffect(() => {
    const targetLocale = user.playLocation.locale;
    const targetSectional = user.playLocation.sectionals;
    const directorAreaID = user.playLocation.areaID;
    let areaCheckboxList = [];
    zoneList
      .filter(
        (location) =>
          location.locale === targetLocale &&
          location.sectionals === targetSectional
      )
      .forEach((location) => {
        const area = location.area;
        const areaID = location.areaID;
        if (!areaCheckboxList.some((item) => item.value === areaID))
          areaCheckboxList.push({
            label: area,
            check: areaID === directorAreaID ? true : false,
            value: areaID,
          });
      });
    setAreaCheckboxList(areaCheckboxList);
    setDirectorZoneInfo({
      section: targetSectional,
      locale: targetLocale,
    });
    let url = window.location.href;
    let params = url.split("/");
    let draftID = params[params.length - 1];
    const draftRef = firestore.collection("leagues").doc(draftID);
    const unsubscribeDraft = draftRef.onSnapshot((snapshot) => {
      if (draftID !== "CreateLeague") {
        const data = snapshot.data();
        const draft = { id: snapshot.id, ref: snapshot.ref, ...data };
        setCompletedApplication(draft.status !== "draft");
        autoFillDraftInfo(draft, areaCheckboxList);
      }
    });
    return () => {
      unsubscribeDraft();
    };
  }, [user, zoneList]);

  useEffect(() => {
    setDivisionSelect([
      {
        label: "Men's Singles",
        check: false,
        playLevels: levelsTemplate,
      },
      {
        label: "Women's Singles",
        check: false,
        playLevels: levelsTemplate,
      },
      {
        label: "Men's Doubles",
        check: false,
        playLevels: levelsTemplate,
      },
      {
        label: "Women's Doubles",
        check: false,
        playLevels: levelsTemplate,
      },
      {
        label: "Coed Singles",
        check: false,
        playLevels: levelsTemplate,
      },
      {
        label: "Coed Doubles",
        check: false,
        playLevels: levelsTemplate,
      },
    ]);
  }, [format]);

  const sendMessage = async (draftID) => {
    await singleActionEmail({
      subject: `A league has been submitted for proposal!`,
      message: `${user.firstName} ${user.lastName}'s league, ${leagueName} is ready for review!`,
      recipients: [{ email: "Admin@playgamesetmatch.com", name: "Admin" }],
      url: `${process.env.REACT_APP_BASE_URL}Admin/ManageLeague/${draftID}`,
      buttonText: "Review League",
    });
  };
  const addLeagueFiles = async (id) => {
    let getURL = "";
    if (typeof imageFile !== "string") {
      await storage.ref(`leagueDocuments/${id}/leaguePhoto`).put(imageFile);
      await storage
        .ref()
        .child(`leagueDocuments/${id}/leaguePhoto`)
        .getDownloadURL()
        .then((url) => {
          getURL = url;
        });
    } else if (currDraft === null) {
      await storage
        .ref()
        .child(`adminDocuments/league/leaguePhoto.jpg`)
        .getDownloadURL()
        .then((url) => {
          getURL = url;
        });
    }
    if (typeof imageFile !== "string" || currDraft === null)
      await firestore.doc(`leagues/${id}`).update({ imageURL: getURL });
    //repeat process for cover image
    getURL = "";
    if (typeof coverImageFile !== "string") {
      await storage.ref(`leagueDocuments/${id}/coverImage`).put(coverImageFile);
      await storage
        .ref()
        .child(`leagueDocuments/${id}/coverImage`)
        .getDownloadURL()
        .then((url) => {
          getURL = url;
        });
    } else if (currDraft === null) {
      await storage
        .ref()
        .child(`adminDocuments/league/coverImage.jpg`)
        .getDownloadURL()
        .then((url) => {
          getURL = url;
        });
    }
    if (typeof coverImageFile !== "string" || currDraft === null)
      await firestore.doc(`leagues/${id}`).update({ coverImageURL: getURL });
    if (currDraft === null) {
      await storage
        .ref()
        .child(`adminDocuments/league/GSM Standard Rules and Regulations.pdf`)
        .getDownloadURL()
        .then((url) => {
          getURL = url;
        });
      await firestore.doc(`leagues/${id}`).update({ rules: getURL });
    }
  };
  const addLeagueDoc = async (status) => {
    const locations = createLocationsList();
    let draftID = "";
    const isPrivateLeague = privateLeague ? { inviteCode: inviteCode } : {};
    await firestore
      .collection("leagues")
      .add({
        directorID: user.uid,
        scheduleGenerated: false,
        playoffsGenerated: false,
        name: leagueName,
        description: leagueDescription,
        divisions: divisionSelect,
        format: format,
        timeboxed: timeboxed,
        sportType: sportType,
        startDate: startDate,
        endDate: endDate,
        registrationEndDate: regEnd,
        numPlayerRange: numPeople,
        locations: locations,
        locale: directorZoneInfo.locale,
        section: directorZoneInfo.section,
        winCriteria: winCriteria,
        scoringFormat: scoringFormat,
        price: joinFee,
        firstPlacePrize: firstPlacePrize,
        secondPlacePrize: secondPlacePrize,
        thirdPlacePrize: thirdPlacePrize,
        percentCut: percentCut,
        status: status,
        currStep: activeStep,
        customRules: "",
        isPrivate: privateLeague,
        ...isPrivateLeague,
      })
      .then(async (docRef) => {
        await addLeagueFiles(docRef.id);
        if (status !== "pending") window.location.href += `/${docRef.id}`;
        draftID = docRef.id;
      });
    return draftID;
  };
  const updateLeagueDoc = async (status) => {
    const locations = createLocationsList();
    const isPrivateLeague = privateLeague ? { inviteCode: inviteCode } : {};
    await currDraft.ref.update({
      name: leagueName,
      description: leagueDescription,
      divisions: divisionSelect,
      format: format,
      timeboxed: timeboxed,
      startDate: startDate,
      endDate: endDate,
      sportType: sportType,
      registrationEndDate: regEnd,
      numPlayerRange: numPeople,
      locations: locations,
      locale: directorZoneInfo.locale,
      section: directorZoneInfo.section,
      winCriteria: winCriteria,
      scoringFormat: scoringFormat,
      price: joinFee,
      firstPlacePrize: firstPlacePrize,
      secondPlacePrize: secondPlacePrize,
      thirdPlacePrize: thirdPlacePrize,
      percentCut: percentCut,
      status: status,
      currStep: activeStep,
      customRules: "",
      isPrivate: privateLeague,
      ...isPrivateLeague,
    });
    addLeagueFiles(currDraft.ref.id);
    return currDraft.ref.id;
  };

  const createLocationsList = () => {
    let locations = [];
    areaCheckboxList.forEach((location) => {
      if (location.check) locations.push(location.value);
    });
    return locations;
  };

  const submitLeagueApplication = async () => {
    setSubmittingApp(true);
    let draftID = "";
    if (currDraft === null) draftID = await addLeagueDoc("pending");
    else draftID = await updateLeagueDoc("pending");
    await sendMessage(draftID);
    setCompletedApplication(true);
    setActiveStep(0);
    setSubmittingApp(false);
  };

  const submitDraftApplication = async () => {
    setSavingDraftApplication(true);
    if (currDraft === null && !saved) {
      await addLeagueDoc("draft");
      setSaved(true);
    } else {
      await updateLeagueDoc("draft");
    }
    setSavingDraftApplication(false);
  };
  const autoFillDraftInfo = (ds, areaCheckboxList) => {
    setCurrDraft(ds);
    setLeagueName(ds.name);
    if (ds.imageURL !== "") setImageDisplay(ds.imageURL);
    if (ds.coverImageURL !== "") setCoverImageDisplay(ds.coverImageURL);
    setLeagueDescription(ds.description);
    setLeagueDescriptionSaved(ds.description);
    setDivisionSelect(ds.divisions);
    setFormat(ds.format);
    setTimeboxed(ds.timeboxed);
    setRules("Standard Rules");
    setCustomRules(ds.customRules);
    setWinCriteria(ds.winCriteria);
    setScoringFormat(ds.scoringFormat);
    if (ds.startDate) {
      let date = ds.startDate.toDate();
      setStartDate(date);
      setStartDateDisplay(moment(date).format("MM/DD/YYYY"));
    }

    if (ds.endDate) {
      let date = ds.endDate.toDate();
      setEndDate(date);
      setEndDateDisplay(moment(date).format("MM/DD/YYYY"));
    }
    setRegEnd(ds.registrationEndDate);
    setNumPeople(ds.numPlayerRange);
    setJoinFee(ds.price);
    setFirstPlacePrize(ds.firstPlacePrize);
    setSecondPlacePrize(ds.secondPlacePrize);
    setThirdPlacePrize(ds.thirdPlacePrize);
    setPercentCut(ds.percentCut);
    if (percentCut === 80) setSplitType("ds.Gold");
    else if (percentCut === 50) setSplitType("ds.Silver");
    else if (percentCut === 20) setSplitType("ds.Bronze");
    else setSplitType("Old");
    setActiveStep(ds.currStep);
    setAreaCheckboxList(
      areaCheckboxList.map((location) => {
        location.check = ds.locations.some(
          (areaID) => areaID === location.value
        );
        return location;
      })
    );
  };
  const handleBack = () => {
    setActiveStep((step) => step - 1);
  };
  const verifyLocationSelected = () => {
    let oneSelected = false;
    areaCheckboxList.forEach((check) => {
      if (check.check) oneSelected = true;
    });
    return oneSelected;
  };
  const submitPage = (e) => {
    e.preventDefault();
    if (activeStep === 0) {
      setLeagueDescriptionSaved(leagueDescription);
      if (verifyLocationSelected()) {
        setActiveStep((step) => step + 1);
        setError(false);
        setErrorMessage("");
      } else {
        setError(true);
        setErrorMessage("Must select at least one location");
      }
    } else if (activeStep === 1) {
      if (privateLeague) {
        if (inviteCode.length < 3) {
          setInviteCodeError("Invite codes must have 3+ characters");
        } else {
          setInviteCodeError("");
          setActiveStep((step) => step + 1);
        }
      } else {
        setActiveStep((step) => step + 1);
      }
    } else if (activeStep === 2) {
      let selectedSomething = false;
      divisionSelect.map((d) =>
        d.playLevels.forEach((level) => {
          if (level.check) selectedSomething = true;
        })
      );
      if (selectedSomething) setActiveStep((step) => step + 1);
    } else if (activeStep === 3) {
      let tempDate = new Date();
      tempDate.setDate(tempDate.getDate() + 7);
      if (tempDate > startDate) {
        setStartDate(null);
        setStartDateDisplay("");
      }
      if (startDate > endDate || tempDate > endDate) {
        setEndDate(null);
        setEndDateDisplay("");
      }
      if (
        !(
          numPeople[0] > 2 &&
          numPeople[0] <= numPeople[1] &&
          numPeople[1] < 100
        )
      ) {
        setError(true);
        setErrorMessage(
          "Invalid Player Range (Must be between 2 and 100 Players)"
        );
      }
      if (
        startDate < endDate &&
        startDate > tempDate &&
        numPeople[0] > 1 &&
        numPeople[0] <= numPeople[1] &&
        numPeople[1] < 100
      ) {
        setError(false);
        setErrorMessage("");
        setActiveStep((step) => step + 1);
      }
    } else if (activeStep === 4) {
      // if (percentCut === 0) {
      //   setError(false);
        // setErrorMessage("Select a valid cut.");
      // } else {
        setError(false);
        setErrorMessage("");
        submitLeagueApplication();
      // }
    }
  };

  const handleAddCode = async () => {
    if (inviteCode.length < 3) {
      setInviteCodeError("Invite codes must have 3+ characters");
    } else {
      const isValid = await isValidInviteCode(inviteCode);
      if (isValid) {
        setNextBtnIsDisabled(false);
        setIsShowErrorIcon(true);
        setInviteCodeError("");
      } else {
        setInviteCodeError("This code already exits");
        setNextBtnIsDisabled(true);
        setIsShowErrorIcon(true);
      }
    }
  };

  return (
    <Grid container spacing={6}>
      {!completedApplication && (
        <Fragment>
          <Grid item xs={12} md={12} lg={12}>
            <CustomStepper
              isPickleBall={isPickleball}
              step={activeStep}
              setStep={setActiveStep}
              stepLabels={stepNames}
            />
          </Grid>
          <Grid className="columnCenter" item xs={12} md={12} lg={12}>
            <form className="width55" onSubmit={submitPage}>
              {activeStep === 0 && (
                <PageOne
                  isPickleBall={isPickleball}
                  sportType={sportType}
                  setSportType={setSportType}
                  setActiveStep={setActiveStep}
                  leagueName={leagueName}
                  setLeagueName={setLeagueName}
                  leagueDescriptionSaved={leagueDescriptionSaved}
                  leagueDescription={leagueDescription}
                  setLeagueDescription={setLeagueDescription}
                  imageDisplay={imageDisplay}
                  setImageDisplay={setImageDisplay}
                  setImageFile={setImageFile}
                  coverImageDisplay={coverImageDisplay}
                  setCoverImageDisplay={setCoverImageDisplay}
                  setCoverImageFile={setCoverImageFile}
                  areaCheckboxList={areaCheckboxList}
                  setAreaCheckboxList={setAreaCheckboxList}
                  error={error}
                  errorMessage={errorMessage}
                />
              )}
              {activeStep === 1 && (
                <PageTwo
                  format={format}
                  setFormat={setFormat}
                  timeboxed={timeboxed}
                  setTimeboxed={setTimeboxed}
                  rules={rules}
                  setRules={setRules}
                  customRules={customRules}
                  setCustomRules={setCustomRules}
                  winCriteria={winCriteria}
                  setWinCriteria={setWinCriteria}
                  scoringFormat={scoringFormat}
                  setScoringFormat={setScoringFormat}
                  currDraft={currDraft}
                  setPrivateLeague={setPrivateLeague}
                  privateLeague={privateLeague}
                  handleAddCode={handleAddCode}
                  inviteCodeError={inviteCodeError}
                  isShowErrorIcon={isShowErrorIcon}
                  setInviteCode={setInviteCode}
                  inviteCode={inviteCode}
                />
              )}
              {activeStep === 2 && (
                <PageThree
                  format={format}
                  setActiveStep={setActiveStep}
                  divisionSelect={divisionSelect}
                  setDivisionSelect={setDivisionSelect}
                />
              )}
              {activeStep === 3 && (
                <PageFour
                  isPickleBall={isPickleball}
                  format={format}
                  startDateDisplay={startDateDisplay}
                  setStartDateDisplay={setStartDateDisplay}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  endDateDisplay={endDateDisplay}
                  setEndDateDisplay={setEndDateDisplay}
                  setEndDate={setEndDate}
                  regEnd={regEnd}
                  setRegEnd={setRegEnd}
                  numPeople={numPeople}
                  setNumPeople={setNumPeople}
                  error={error}
                  errorMessage={errorMessage}
                />
              )}
              {activeStep === 4 && (
                <PageFive
                  joinFee={joinFee}
                  setJoinFee={setJoinFee}
                  firstPlacePrize={firstPlacePrize}
                  setFirstPlacePrize={setFirstPlacePrize}
                  secondPlacePrize={secondPlacePrize}
                  setSecondPlacePrize={setSecondPlacePrize}
                  thirdPlacePrize={thirdPlacePrize}
                  setThirdPlacePrize={setThirdPlacePrize}
                  percentCut={percentCut}
                  setPercentCut={setPercentCut}
                  splitType={splitType}
                  setSplitType={setSplitType}
                  error={error}
                  errorMessage={errorMessage}
                />
              )}
              <div className="maxWidth margin20Vertical">
                <Divider />
              </div>
              <div className="rowCenter">
                <Button
                  variant="text"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  <div className="rowCenter">
                    <ArrowBackIos className="createLeagueBackButtonIcon margin10Right" />
                    <Typography className="createLeagueUtilityButtonText">
                      Back
                    </Typography>
                  </div>
                </Button>
                <div className="filler" />
                <Button
                  variant="text"
                  onClick={submitDraftApplication}
                  disabled={savingDraftApplication}
                >
                  {savingDraftApplication ? (
                    <Loader
                      type="TailSpin"
                      color="#02c39a"
                      height={40}
                      width={40}
                      timeout={3000}
                    />
                  ) : (
                    <Typography className="createLeagueUtilityButtonText">
                      Save Draft
                    </Typography>
                  )}
                </Button>
                <Button
                  className="createLeagueNextButton margin10Left"
                  type="submit"
                  disabled={
                    submittingApp ||
                    (privateLeague && activeStep === 1 && nextBtnIsDisabled)
                  }
                >
                  {submittingApp ? (
                    <Loader
                      type="TailSpin"
                      color="#02c39a"
                      height={40}
                      width={40}
                      timeout={3000}
                    />
                  ) : (
                    <Typography className="createLeagueNextButtonText">
                      {activeStep !== 4 ? "Next" : "Submit"}
                    </Typography>
                  )}
                </Button>
              </div>
            </form>
          </Grid>
        </Fragment>
      )}
      {completedApplication && (
        <Grid className="columnCenter" item xs={12} md={12} lg={12}>
          <div className="widthHalf">
            <Typography variant="h4">
              {`Thank you for submitting your proposed League, ${leagueName}.`}
            </Typography>
            <Typography variant="h4">
              We will be reviewing your league submission shortly and get back
              to you if we have any questions.
            </Typography>
            <Typography variant="h4">
              Please monitor your league page for updates. Best wishes.
            </Typography>
            <div className="margin30" />
            <div className="columnCenter">
              <Link to="/Leagues" className="noUnderline">
                <Button className="createLeagueNextButton">View Leagues</Button>
              </Link>
            </div>
          </div>
        </Grid>
      )}
    </Grid>
  );
};
export default CreateLeague;

import React, { useState, useEffect, Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import { firestore, storage } from "../../firebase";
import { LoadMore } from "../../components/table/Pagination";
import { DefaultSwitch } from "../../components/CustomSwitches";
import InformationDialogPDF from "../../components/InformationDialogPDF";
import LeaderboardSearchBar from "./LeaderboardSearchBar";
const Leaderboard = ({ user, mobileSize, zoneList, isFromDashBoard }) => {
  const [loading, setLoading] = useState(true);
  const [gsmRankURL, setGsmRankURL] = useState("");
  const [doublesSortToggle, setDoublesSortToggle] = useState(false);
  const [leaderboardList, setLeaderboardList] = useState([]);
  const [doublesLeaderboardList, setDoublesLeaderboardList] = useState([]);
  const [filterByPlayer, setFilterByPlayer] = useState("");
  const [filterBySection, setFilterBySection] = useState(
    user.playLocation.sectionals
  );
  const [filterByLocale, setFilterByLocale] = useState(
    user.playLocation.locale
  );

  useEffect(() => {
    if (localStorage.getItem("doublesSortToggle")) {
      setDoublesSortToggle(
        localStorage.getItem("doublesSortToggle").toLowerCase() === "true"
      );
    }
  }, []);

  const [filterByArea, setFilterByArea] = useState("All");
  const [filterByLevel, setFilterByLevel] = useState([
    { label: "All Levels", check: true, value: 0 },
    { label: "1 -Beginners", check: true, value: 1 },
    { label: "2 -Novice", check: true, value: 2 },
    { label: "3 -Intermediate", check: true, value: 3 },
    { label: "4 -Advanced", check: true, value: 4 },
    { label: "5 -Competitive/D2", check: true, value: 5 },
    { label: "6 -Competitive/D1", check: true, value: 6 },
    { label: "7 -Circuit Level Pro", check: true, value: 7 },
    { label: "8 -World Class", check: true, value: 8 },
  ]);
  const [filterByGender, setFilterByGender] = useState("All");
  const [handleRedirect, setHandleRedirect] = useState(false);
  const [redirectProfile, setRedirectProfile] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const gender = ["All", "Men", "Women"];
  const createLeaderboardTable = (
    pos,
    player,
    locale,
    matchesPlayed,
    id,
    gsmRating
  ) => {
    return {
      pos,
      player,
      locale,
      matchesPlayed,
      id,
      gsmRating,
    };
  };
  const resultsPerPage = isFromDashBoard ? 4 : 10;
  const [pageNum, setPageNum] = useState(1);
  const isPickleball = user.currentSport === "pickleball";

  useEffect(() => {
    setRedirectProfile(null);
    setHandleRedirect(false);
    storage
      .ref()
      .child(`adminDocuments/GSMRating.pdf`)
      .getDownloadURL()
      .then((url) => {
        setGsmRankURL(url);
      });
    const queryFilter = isPickleball ? "pb_totalMatches" : "totalMatches";
    const queryFilterDoubles = isPickleball
      ? "pb_totalDoublesMatches"
      : "totalDoublesMatches";
    const playersRef = firestore
      .collection("users")
      .where(queryFilter, ">", 4)
      .orderBy(queryFilter, "desc");
    const doublesPlayersRef = firestore
      .collection("users")
      .where(queryFilterDoubles, ">", 4)
      .orderBy(queryFilterDoubles, "desc");

    const unsubscribePlayers = playersRef.onSnapshot((snapshot) => {
      setLeaderboardList(
        snapshot.docs.map((doc, index) => {
          const data = doc.data();
          const matchPlayed = isPickleball
            ? data.pb_totalMatchWins + data.pb_totalMatchLosses
            : data.totalMatchWins + data.totalMatchLosses;
          const gsm = isPickleball ? data.pb_gsmRating : data.gsmRating;
          const gsmDouble = isPickleball
            ? data.gsmRatingDoubles
            : data.pb_gsmRatingDoubles;
          return createLeaderboardTable(
            index,
            data,
            data.playLocation.locale,
            matchPlayed,
            doc.id,
            gsm,
            gsmDouble
          );
        })
      );
    });
    const unsubscribeDoublesPlayers = doublesPlayersRef.onSnapshot(
      (snapshot) => {
        setDoublesLeaderboardList(
          snapshot.docs.map((doc, index) => {
            const data = doc.data();
            const doubleMatchPlayed = isPickleball
              ? data.pb_totalDoublesMatchWins + data.pb_totalDoublesMatchLosses
              : data.totalDoublesMatchWins + data.totalDoublesMatchLosses;
            const gsmDouble = isPickleball
              ? data.gsmRatingDoubles
              : data.pb_gsmRatingDoubles;
            return createLeaderboardTable(
              index,
              data,
              data.playLocation.locale,
              doubleMatchPlayed,
              doc.id,
              gsmDouble
            );
          })
        );
        setLoading(false);
      }
    );
    return () => {
      unsubscribePlayers();
      unsubscribeDoublesPlayers();
    };
  }, [user, isPickleball]);
  const handleProfileView = (item) => {
    setRedirectProfile(item.id);
    setHandleRedirect(true);
  };
  const playerFilter = (item) => {
    if (filterByPlayer === "") return true;
    const name = `${item.player.firstName} ${item.player.lastName}`;
    return name.indexOf(filterByPlayer) === 0;
  };
  const levelFilter = (item) => {
    if (filterByLevel[0].check) return true;
    return filterByLevel.some(
      (element) =>
        element.value + 1 > item.player.gsmRating &&
        element.value <= item.player.gsmRating &&
        element.check
    );
  };
  const locationFilter = (item, locationType, locationSelected) => {
    if (locationSelected === "All") return true;
    return (
      item.player.playLocation[locationType].indexOf(locationSelected) === 0
    );
  };
  const genderFilter = (item) => {
    if (filterByGender === "All") return true;
    const compare = filterByGender === "Men" ? "male" : "female";
    return compare === item.player.gender;
  };
  const sortByRating = (a, b) => {
    if (isPickleball)
      return doublesSortToggle
        ? b.player.pb_gsmRatingDoubles - a.player.pb_gsmRatingDoubles
        : b.player.pb_gsmRating - a.player.pb_gsmRating;
    return doublesSortToggle
      ? b.player.gsmRatingDoubles - a.player.gsmRatingDoubles
      : b.player.gsmRating - a.player.gsmRating;
  };
  const displayList = doublesSortToggle
    ? doublesLeaderboardList
      .filter((item) => locationFilter(item, "sectionals", filterBySection))
      .filter((item) => locationFilter(item, "locale", filterByLocale))
      .filter((item) => locationFilter(item, "areaID", filterByArea))
      .filter((item) => levelFilter(item))
      .filter((item) => genderFilter(item))
      .filter((item) => playerFilter(item))
      .sort((a, b) => sortByRating(a, b))
    : leaderboardList
      .filter((item) => locationFilter(item, "sectionals", filterBySection))
      .filter((item) => locationFilter(item, "locale", filterByLocale))
      .filter((item) => locationFilter(item, "areaID", filterByArea))
      .filter((item) => levelFilter(item))
      .filter((item) => genderFilter(item))
      .filter((item) => playerFilter(item))
      .sort((a, b) => sortByRating(a, b));
  const pageEndIndex =
    pageNum * resultsPerPage < displayList.length
      ? pageNum * resultsPerPage
      : displayList.length;

  const handleToggle = () => (
    <div className="leaderboardRatingToggleWrapper">
      <Typography
        className={
          doublesSortToggle
            ? "leaderboardRatingToggleTextDisabled"
            : "leaderboardRatingToggleTextEnabled"
        }
      >
        Singles
      </Typography>
      <DefaultSwitch
        state={doublesSortToggle}
        setState={(e) => {
          localStorage.setItem("doublesSortToggle", e);
          setDoublesSortToggle(e);
        }}
      />
      <Typography
        className={
          !doublesSortToggle
            ? "leaderboardRatingToggleTextDisabled"
            : "leaderboardRatingToggleTextEnabled"
        }
      >
        Doubles
      </Typography>
    </div>
  );

  if (handleRedirect) {
    window.history.pushState({}, "", "/Leaderboard");
    return <Redirect to={`/Profile/ViewProfile/${redirectProfile}`} />;
  }
  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  return (
    <div>
      {!isFromDashBoard ? (
        <>
          <div className="rowCenter">
            <LeaderboardSearchBar
              user={user}
              search={filterByPlayer}
              setSearch={setFilterByPlayer}
              genderSelect={filterByGender}
              setGenderSelect={setFilterByGender}
              levelSelect={filterByLevel}
              setLevelSelect={setFilterByLevel}
              sectionSelect={filterBySection}
              setSectionSelect={setFilterBySection}
              localeSelect={filterByLocale}
              setLocaleSelect={setFilterByLocale}
              areaSelect={filterByArea}
              setAreaSelect={setFilterByArea}
              zoneList={zoneList}
            />
            <div className="filler" />
            {handleToggle()}
          </div>
          <Typography className="helperText margin20Top">
            ( note: only players with 5 or more matches played will appear on
            this board )
          </Typography>

          <Table className="margin10Top">
            <TableHead>
              <TableRow>
                <TableCell className="leaderboardCell">
                  <Typography className="leaderboardColumnHeaderText">
                    Rank
                  </Typography>
                </TableCell>
                <TableCell className="leaderboardCell" align="left">
                  <Typography className="leaderboardColumnHeaderText">
                    Player
                  </Typography>
                </TableCell>
                {!mobileSize && (
                  <Fragment>
                    <TableCell className="leaderboardCell" align="left">
                      <Typography className="leaderboardColumnHeaderText">
                        Locale
                      </Typography>
                    </TableCell>
                    <TableCell className="leaderboardCell" align="left">
                      <Typography className="leaderboardColumnHeaderText">
                        Matches Played
                      </Typography>
                    </TableCell>
                    <TableCell className="leaderboardCell" align="left">
                      <Typography className="leaderboardColumnHeaderText">
                        Leagues Joined
                      </Typography>
                    </TableCell>
                  </Fragment>
                )}

                <TableCell className="leaderboardCell" align="left">
                  <div className="rowCenter">
                    <Typography className="leaderboardColumnHeaderText">
                      {doublesSortToggle ? "GSM Doubles Rating" : "GSM Rating"}
                    </Typography>
                    <InformationDialogPDF pdf={gsmRankURL} />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayList.slice(0, pageEndIndex).map((row, i) => (
                <TableRow onClick={() => handleProfileView(row)} key={i}>
                  <TableCell
                    className="leaderboardCell"
                    component="th"
                    scope="row"
                  >
                    <div className="leaderboardCellLeftInner">
                      <div className="leaderboardRankWrapper">
                        <Typography className="leaderboardHighlightText">
                          {i + 1}
                        </Typography>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="left" className="leaderboardCell">
                    {/* eslint-disable-next-line */}
                    <a className="noUnderline" href="javascript:void(0);">
                      <div className="leaderboardCellInner rowCenter">
                        <Avatar
                          className={mobileSize ? "image50" : "image100"}
                          src={row.player.imageURL}
                        />
                        <Typography className="padding10 leaderboardHighlightText">
                          {row.player.firstName} {row.player.lastName}
                        </Typography>
                      </div>
                    </a>
                  </TableCell>
                  {!mobileSize && (
                    <Fragment>
                      <TableCell className="leaderboardCell" align="left">
                        <div className="leaderboardCellInner">
                          <Typography className="leaderboardStatText">
                            {row.locale}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell className="leaderboardCell" align="left">
                        <div className="leaderboardCellInner">
                          <Typography className="leaderboardStatTextBold">
                            {row.matchesPlayed}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell className="leaderboardCell" align="left">
                        <div className="leaderboardCellInner">
                          <Typography className="leaderboardStatTextBold">
                            {isPickleball
                              ? row.player?.pb_leaguesJoined?.length
                              : row.player?.leaguesJoined?.length}
                          </Typography>
                        </div>
                      </TableCell>
                    </Fragment>
                  )}
                  <TableCell className="leaderboardCell" align="left">
                    <div className="leaderboardCellRightInner">
                      <Typography className="leaderboardStatTextBold">
                        {Math.round(
                          (doublesSortToggle
                            ? isPickleball
                              ? row.player.pb_gsmRatingDoubles
                              : row.player.gsmRatingDoubles
                            : isPickleball
                              ? row.player.gsmRating
                              : row.player.gsmRating) * 100
                        )}
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {displayList.length > pageEndIndex && (
            <LoadMore setValue={setPageNum} />
          )}
        </>
      ) : (
        <>
          <div className="rowCenter">
            {gender.map((item, i) => (
              <Button
                key={i}
                className={
                  selectedIndex === i
                    ? "selectedbutton margin10Right minWidth75"
                    : "margin10Right minWidth75"
                }
                onClick={() => {
                  setFilterByGender(item);
                  setSelectedIndex(i);
                }}
              >
                {item}
              </Button>
            ))}
            {handleToggle()}
          </div>
          <Table className="margin10Top">
            {displayList.length > 0 ? (
              <TableBody>
                {displayList.slice(0, pageEndIndex).map((row, i) => (
                  <TableRow onClick={() => handleProfileView(row)} key={i}>
                    <TableCell align="left" className="leaderboardCell">
                      {/* eslint-disable-next-line */}
                      <a className="noUnderline" href="javascript:void(0);">
                        <div className="leaderboardCellInner rowCenter radius10 alignFlexStart">
                          <Avatar
                            className={
                              mobileSize ? "image50" : "image100 radius10"
                            }
                            src={row.player.imageURL}
                          />
                          <div className="columnAlignCenter">
                            <Typography className="padding10 leaderboardHighlightText">
                              {row.player.firstName} {row.player.lastName}
                            </Typography>
                            <Typography className="leaderboardStatTextBold">
                              {Math.round(
                                (doublesSortToggle
                                  ? isPickleball
                                    ? row.player.pb_gsmRatingDoubles
                                    : row.player.gsmRatingDoubles
                                  : isPickleball
                                    ? row.player.gsmRating
                                    : row.player.gsmRating) * 100
                              )}
                            </Typography>
                          </div>
                        </div>
                      </a>
                    </TableCell>
                    <TableCell
                      className="leaderboardCell"
                      component="th"
                      scope="row"
                    >
                      <div className="dashbordleaderboardCellLeftInner radius10">
                        <div className="leaderboardRankWrapper">
                          <Typography className="leaderboardHighlightText">
                            {i + 1}
                          </Typography>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <div className="dashboardEmptySectionWrapper">
                <Typography className="listItemMatchScorePlayerName">
                  No leaderBoard data!
                </Typography>
              </div>
            )}
          </Table>
        </>
      )}
    </div>
  );
};
export default Leaderboard;

import React from 'react';
import moment from 'moment';
import pdfbackground from '../../../assets/pdf-background.jpg';

const PDF = ({
  league,
  divisionName,
  track,
  director,
  pngUrlDataUrl,
  discription,
}) => {
  const styles = {
    page: {
      width: 195,
      padding: '8px',
      height: 280,
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    sportType: {
      margin: 0,
      fontSize: '20px',
      color: '#FFFFFF',
      textTransform: 'uppercase',
    },
    leagueType: {
      margin: 0,
      fontSize: '14px',
      color: '#87c522',
    },
    divisionInfo: {
      display: 'flex',
      alignItems: 'centre',
    },
    division: {
      margin: 'auto 2px auto 6px',
      width: 65,
      color: '#FFFFFF',
      textAlign: 'center',
    },
    leagueInfo: {
      display: 'flex',
      alignItems: 'centre',
      justifyContent: 'space-between',
    },
    font: {
      margin: 0,
      fontSize: '5px',
    },
    margin0: {
      margin: 0,
      fontSize: '6px',
    },
    link: {
      textDecoration: 'none',
      color: '#bd8f39',
    },
    position: {
      position: 'fixed',
      top: 0,
      zIndex: -1,
    },
  };
  return (
    <div style={{ position: 'relative' }}>
      <div style={styles.page}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <p style={{ margin: 0, fontSize: '14px', color: '#87c522' }}>
              {league?.name}
            </p>
            <p style={styles.sportType}>{league?.sportType}</p>
            <p style={styles.leagueType}>{league?.format}</p>
            <p style={{ margin: 0, fontSize: '5px', color: '#FFFFFF' }}>
              {' '}
              {discription.length > 140
                ? `${discription.substring(0, 140)}...`
                : discription}{' '}
            </p>
          </div>
          <img src={league?.imageURL} width="30" height="30" />
        </div>
        <div>
          <div style={styles.divisionInfo}>
            <div style={styles.division}>
              <p style={styles.font}>DIVISIONS:</p>
              <p style={styles.font}>
                {divisionName} {track}
              </p>
              <p style={styles.font}> for the {league?.name} </p>
            </div>
          </div>
        </div>
        <div style={styles.leagueInfo}>
          <div style={{ color: '#FFFFFF'}}>
            <p style={{ margin: '8px 0',fontSize: '10px',fontWeight:"bold",whiteSpace:"nowrap" }}>
              {moment(league?.startDate?.toDate()).format('M/D/YYYY')}-{' '}
              {moment(league?.endDate?.toDate()).format('M/D/YYYY')}
            </p>
            <p style={{ margin: 0, fontSize: '8px' }}>AT {league?.locale}</p>
            <p style={styles.margin0}>Registration</p>
            <p style={{margin: 0,fontSize: '6px',width:90}}>Ends {league?.registrationEndDate}</p>
            <p style={styles.margin0}>Registration Fee:</p>
            <p style={styles.margin0}>
              $ <span style={{ fontSize: '8px' }}> {league?.price}</span> per
            </p>
            <p style={styles.margin0}>person</p>
          </div>
          <div style={{ color: '#bd8f39' }}>
            <div style={{ marginLeft: '8px' }}>
              <img src={pngUrlDataUrl} width="30" height="30" />
            </div>
            <p style={styles.margin0}>For more information</p>
            <p style={styles.margin0}>
              Call: {director?.firstName} {director?.lastName}
            </p>
            <p style={styles.margin0}>
              Email:{' '}
              <span style={{ wordBreak: 'break-word' }}>
                {director?.authEmail}
              </span>
            </p>
            <p style={styles.margin0}>
              <a href="https://www.game-set-match.net/" style={styles.link}>
                www.playgamesetmatch.com
              </a>
            </p>
          </div>
        </div>
      </div>
      <div style={styles.position}>
        <img src={pdfbackground} width="220" height="297" />
      </div>
    </div>
  );
};

export default PDF;

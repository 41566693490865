import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Tune from "@material-ui/icons/Tune";
import Search from "@material-ui/icons/Search";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
const themeSearchBar = createTheme({
  palette: {
    primary: {
      main: "#55B56A",
      light: "#EDF8F4",
      dark: "#028360",
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        backgroundColor: "#FFFFFF",
        borderRadius: "24px",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "24px",
      },
    },
  },
  props: {
    MuiTextField: {
      variant: "outlined",
    },
  },
});
const theme = createTheme({
  palette: {
    primary: {
      main: "#55B56A",
      light: "#EDF8F4",
      dark: "#028360",
    },
  },
  overrides: {
    MuiSlider: {
      track: {
        marginTop: -3,
        height: 8,
        borderRadius: 4,
        backgroundColor: "#028360",
      },
      thumb: {
        marginTop: -11,
        width: 24,
        height: 24,
        backgroundColor: "#028360",
      },
    },
  },
  props: {
    MuiTextField: {
      variant: "standard",
      InputProps: {
        disableUnderline: true,
      },
    },
  },
});
const SearchBar = ({ search, setSearch, placeholder, children }) => {
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const open = Boolean(popoverAnchor);
  const handleClick = (e) => {
    setPopoverAnchor(e.target);
  };
  const handleClose = () => {
    setPopoverAnchor(null);
  };
  return (
    <div className="leagueSearchWrapper">
      <Button variant="text" onClick={handleClick}>
        <Typography className="highlightText">Show Filters</Typography>
        <Tune className="leagueSearchBarButtonIcon" />
      </Button>
      <Popover
        open={open}
        anchorEl={popoverAnchor}
        onClose={handleClose}
        anchorOrigin={{ vertical: "center", horizontal: "right" }}
        transformOrigin={{ vertical: "center", horizontal: "left" }}
      >
        <ThemeProvider theme={theme}>
          <div className="leagueSearchBarFilterPopoverWrapper">{children}</div>
        </ThemeProvider>
      </Popover>
      <ThemeProvider theme={themeSearchBar}>
        <TextField
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          className="leagueSearchBarField"
          placeholder={placeholder ? placeholder : "Search"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search className="leagueSearchBarFieldIcon" />
              </InputAdornment>
            ),
          }}
        />
      </ThemeProvider>
    </div>
  );
};
export default SearchBar;

import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { firestore, Firebase } from "../../../firebase";
import DefaultDialog from "../../../components/DefaultDialog";
import { DefaultTextField } from "../../../components/CustomTextField";
import { DefaultSwitch } from "../../../components/CustomSwitches";
import { PercentSlider } from "../../../components/CustomSliders";
//NOTE: add empty string to geturl function and that way I can handle when theres no id
const CouponForm = ({ open, setOpen, coupon, isEditing }) => {
  const [code, setCode] = useState("");
  const [active, setActive] = useState(true);
  const [oneTimeUse, setOneTimeUse] = useState(true);
  const [percentDiscount, setPercentDiscount] = useState(20);
  const [discreteDiscount, setDiscreteDiscount] = useState(0);
  useEffect(() => {
    if (isEditing) {
      setCode(coupon.code);
      setActive(coupon.active);
      setOneTimeUse(coupon.oneTimeUse);
      setPercentDiscount(coupon.percentDiscount);
      setDiscreteDiscount(coupon.discreteDiscount);
    } else {
      setCode("");
      setActive(true);
      setOneTimeUse(true);
      setPercentDiscount(20);
      setDiscreteDiscount(0);
    }
  }, [open, coupon, isEditing]);
  const updateCoupon = async (e) => {
    e.preventDefault();
    if (isEditing) {
      await coupon.ref.update({
        code: code,
        active: active,
        oneTimeUse: oneTimeUse,
        percentDiscount: percentDiscount,
        discreteDiscount: discreteDiscount,
      });
    } else {
      await firestore.collection("coupons").add({
        code: code,
        active: active,
        oneTimeUse: oneTimeUse,
        percentDiscount: percentDiscount,
        discreteDiscount: discreteDiscount,
        createdBy: "admin",
        dateCreated: Firebase.firestore.FieldValue.serverTimestamp(),
      });
    }
    handleClose();
  };
  const handleDelete = async () => {
    await coupon.ref.delete();
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <DefaultDialog open={open} setOpen={setOpen}>
      <form className="column" onSubmit={updateCoupon}>
        <Typography className="textFieldHeader">Code</Typography>
        <DefaultTextField
          state={code}
          setState={setCode}
          required={true}
          placeholder={"CODE"}
        />
        <Typography className="textFieldHeader margin20Top">
          Percent Discount
        </Typography>
        <PercentSlider value={percentDiscount} setValue={setPercentDiscount} />
        <Typography className="textFieldHeader">Discrete Discount</Typography>
        <DefaultTextField
          state={discreteDiscount}
          setState={setDiscreteDiscount}
          required={true}
          placeholder={"5"}
        />
        <div className="rowCenter margin20Vertical">
          <div className="widthHalf rowCenter">
            <Typography className="textFieldHeader margin10Right">
              Active
            </Typography>
            <DefaultSwitch state={active} setState={setActive} />
          </div>
          <div className="widthHalf rowCenter">
            <Typography className="textFieldHeader margin10Right">
              One Time Use
            </Typography>
            <DefaultSwitch state={oneTimeUse} setState={setOneTimeUse} />
          </div>
        </div>
        <div className="rowCenter">
          <Button
            disabled={!isEditing}
            variant="contained"
            className="errBackground"
            onClick={handleDelete}
          >
            Delete
          </Button>
          <div className="filler" />
          <Button
            variant="contained"
            className="primaryColorBackground"
            type="submit"
          >
            Save
          </Button>
        </div>
      </form>
    </DefaultDialog>
  );
};

export default CouponForm;

import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ access, children, exact, path }) => {
  return access ? (
    <Route exact={exact} path={path}>
      {children}
    </Route>
  ) : (
    <Redirect to="/Dashboard" />
  );
};

export default PrivateRoute;

import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PlayerBracketObject from "../../../components/PlayerBracketObject";
import TeamBracketObject from "../../../components/TeamBracketObject";
import Button from "@material-ui/core/Button";
import ScoreUploadForm from "../../forms/ScoreUploadForm";
import PickleballScoreUploadForm from "../../forms/PickleballScoreUploadForm";
import {divisionData} from "../../../components/Common";

const PlayerObjDraggable = ({ player, index, isInTeam, isModeratedBye }) => {
  return (
    <Draggable draggableId={player.id} index={index}>
      {(provided) => (
        <Card
          className={isInTeam ? "moderatePlayoffObject" : "playoffObject"}
          key={index}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {isModeratedBye ? (
            <AvatarGroup>
              <Avatar className="image50" src={player.imageURL} />
              <Avatar className="image50" src={player.imageURL} />
            </AvatarGroup>
          ) : (
            <Avatar className="image50" src={player.imageURL} />
          )}
          <div>
            <Typography className={isInTeam ? "moderatePlayerObjText" : ""}>
              {player.name}
            </Typography>
            {player.name !== "Bye" && (
              <Typography className={isInTeam ? "moderatePlayerObjText" : ""}>
                Rank {player.rank}, {player.winloss} winrate
              </Typography>
            )}
          </div>
        </Card>
      )}
    </Draggable>
  );
};
const TeamObjDraggable = ({ team, index }) => {
  return (
    <Draggable draggableId={team.id} index={index}>
      {(provided) => (
        <Card
          className="playoffObject"
          key={index}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <AvatarGroup>
            <Avatar
              className="image50"
              src={team.players !== undefined ? team.players[0].imageURL : ""}
            />
            <Avatar
              className="image50"
              src={team.players !== undefined ? team.players[1].imageURL : ""}
            />
          </AvatarGroup>
          <div>
            <Typography>{team.name}</Typography>
            {team.rank !== undefined && (
              <Typography>
                Rank {team.rank}, {team.winloss} winrate
              </Typography>
            )}
          </div>
        </Card>
      )}
    </Draggable>
  );
};
const PlayerObj = ({ player }) => {
  return (
    <Card
      className={`playoffObject ${
        player.withdrawn ? "withdrawnPlayerBackground" : ""
      }`}
    >
      <Avatar className="image50" src={player.imageURL} />
      <div>
        <Typography>{player.name}</Typography>
      </div>
    </Card>
  );
};
const TeamObj = ({ team }) => {
  return (
    <Card
      className={`playoffObject ${
        team.withdrawn ? "withdrawnPlayerBackground" : ""
      }`}
    >
      <AvatarGroup>
        <Avatar
          className="image50"
          src={team.players !== undefined ? team.players[0].imageURL : ""}
        />
        <Avatar
          className="image50"
          src={team.players !== undefined ? team.players[1].imageURL : ""}
        />
      </AvatarGroup>
      <div>
        <Typography>{team.name}</Typography>
      </div>
    </Card>
  );
};
const PlayoffsBracket = ({
  track,
  index,
  currIndex,
  brackets,
  setBrackets,
  isDirector,
                           isPublic=false
}) => {
  const { league, division } = track;
  const isModerated = league?.format === "Moderated-dropin";
  const isModerateSingle = divisionData.includes(division.division);

  const [scorePopupOpen, setScorePopupOpen] = useState(false);
  const [selectedTeamsForScore, setSelectedTeamsForScore] = useState([]);
  const [numBuys, setNumBuys] = useState(() => {
    if (brackets[index] && brackets[index][0]) {
      let largestBuy = 0;
      brackets[index][0].forEach((slot, index2) => {
        slot.list.forEach((p) => {
          if (
            p.id.substring(0, 3) === "bye" &&
            p.id.length < 5 &&
            p.id.substring(3) > largestBuy
          ) {
            largestBuy = p.id.substring(3);
          }
        });
      });
      return Number(largestBuy) + 1;
    } else return 0;
  });

  const [participantList, setParticipantList] = useState(() => {
    let trackBracket = track.track.playoffsBracket;
    let participantList = [];
    if (division.doublesFormat) {
      const teams = track.teams;
      if (!trackBracket[0]) return teams.filter((team) => !team.withdrawn);
      teams.forEach((team) => {
        let found = false;
        trackBracket[0].forEach((slot) => {
          if (slot.list.find((teamID) => teamID === team.id)) found = true;
        });
        if (!found) participantList.push({ ...team, name: team.teamName });
      });
      participantList.push({
        name: "Bye",
        id: `bye${numBuys}`,
      });
      setNumBuys((numB) => Number(numB) + 1);
    } else {
      const players = track.players;
      if (!trackBracket[0])
        return players.filter((player) => !player.withdrawn);
      players.forEach((player) => {
        let found = false;
        trackBracket[0].forEach((slot) => {
          if (slot.list.find((playerID) => playerID === player.id))
            found = true;
        });
        if (!found)
          participantList.push({
            ...player,
            name: `${player.firstName} ${player.lastName}`,
          });
      });
      participantList.push({
        name: "Bye",
        id: `bye${numBuys}`,
      });
      setNumBuys((numB) => numB + 1);
    }

    return participantList;
  });

  const addBuy = (numerTaken) => {
    let participantsTemp = participantList;
    participantsTemp.push({ name: "Bye", id: `bye${numerTaken}` });
    setNumBuys(Number(numerTaken) + 1);
    setParticipantList(participantsTemp);
  };
  const onDragEnd = async (result) => {
    const { destination, source } = result;
    const limiitInBracket = isModerated && !isModerateSingle ? 3 : 1;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;
    let playerMoved;
    if (
      destination.droppableId !== `playerList${index}` &&
      brackets[index][0][destination.droppableId].list.length >
        limiitInBracket &&
      destination.droppableId !== source.droppableId
    ) {
      return;
    }
    if (
      destination.droppableId === `playerList${index}` &&
      source.droppableId === `playerList${index}`
    ) {
      playerMoved = participantList[source.index];
      let arrTemp = participantList;
      arrTemp.splice(source.index, 1);
      arrTemp.splice(destination.index, 0, playerMoved);
      setParticipantList(arrTemp);
    } else if (
      source.droppableId === `playerList${index}` &&
      destination.droppableId !== `playerList${index}`
    ) {
      playerMoved = participantList[source.index];
      let bracketsTemp = brackets;
      if (isModerated && !isModerateSingle && playerMoved.name === "Bye") {
        let alreadyPlayer =
          bracketsTemp[index][0][destination.droppableId].list;
        let alreadyByePlayer = alreadyPlayer.filter((b) => b.name === "Bye");
        if (
          brackets[index][0][destination.droppableId].list.length === 3 ||
          alreadyByePlayer.length > 1
        ) {
          return;
        }
        bracketsTemp[index][0][destination.droppableId].list.push({
          name: "Bye",
          id: `bye${numBuys}`,
        });
      }

      let arrTemp = participantList;
      arrTemp.splice(source.index, 1);
      setParticipantList(arrTemp);
      bracketsTemp[index][0][destination.droppableId].list.push(playerMoved);

      const bracketReplace = [...bracketsTemp];

      await setBrackets(bracketReplace);
      if (playerMoved.name === "Bye") {
        isModerated && !isModerateSingle ? addBuy(numBuys + 1) : addBuy(numBuys);
      }
    } else if (
      source.droppableId !== `playerList${index}` &&
      destination.droppableId !== `playerList${index}`
    ) {
      playerMoved = brackets[index][0][source.droppableId].list[source.index];
      let bracketsTemp = brackets;
      if (isModerated && !isModerateSingle && playerMoved.name === "Bye") {
        let alreadyPlayer =
          bracketsTemp[index][0][destination.droppableId].list;
        let alreadyByePlayer = alreadyPlayer.filter((b) => b.name === "Bye");
        let newPlayer = bracketsTemp[index][0][source.droppableId].list.filter(
          (b) => b.name === "Bye"
        );
        if (alreadyPlayer.length === 3 || alreadyByePlayer.length > 1) {
          return;
        }
        bracketsTemp[index][0][destination.droppableId].list = [
          ...alreadyPlayer,
          ...newPlayer,
        ];
        bracketsTemp[index][0][source.droppableId].list = bracketsTemp[
          index
        ][0][source.droppableId].list.filter((b) => b.name !== "Bye");
      } else {
        bracketsTemp[index][0][source.droppableId].list.splice(source.index, 1);
        bracketsTemp[index][0][destination.droppableId].list.splice(
          destination.index,
          0,
          playerMoved
        );
      }
      const bracketReplace = [...bracketsTemp];
      setBrackets(bracketReplace);
    } else {
      playerMoved = brackets[index][0][source.droppableId].list[source.index];
      let bracketsTemp = brackets;
      if (isModerated && !isModerateSingle && playerMoved.name === "Bye") {
        bracketsTemp[index][0][source.droppableId].list = bracketsTemp[
          index
        ][0][source.droppableId].list.filter((b) => b.name !== "Bye");
      } else {
        bracketsTemp[index][0][source.droppableId].list.splice(source.index, 1);
      }
      const bracketReplace = [...bracketsTemp];
      setBrackets(bracketReplace);
      if (!(playerMoved.name === "Bye")) {
        let arrTemp = participantList;
        arrTemp.splice(destination.index, 0, playerMoved);
        setParticipantList(arrTemp);
      }
    }
  };
  if (!league.playoffsGenerated)
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        {index === currIndex && (
          <div className="playoffPage">
            <div className="playoffPlayerList">
              <Typography variant="h4">
                {division.doublesFormat ? "Teams" : "Players"}
              </Typography>
              <Droppable droppableId={`playerList${index}`}>
                {(provided) => (
                  <div
                    className="playoffPlayerList"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    key={"playerList"}
                  >
                    {participantList.map((participant, index2) =>
                      division.doublesFormat ? (
                        <TeamObjDraggable
                          team={participant}
                          index={index2}
                          key={participant.id}
                        />
                      ) : (
                        <PlayerObjDraggable
                          player={participant}
                          index={index2}
                          key={participant.id}
                          isModeratedBye={
                            participant.name === "Bye" && isModerated && !isModerateSingle
                          }
                        />
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
            <div className="playoffBracketWrapper">
              {Object.keys(brackets[index]).map((key, index2) => {
                if (index2 === 0) {
                  return (
                    <div key={index2} className="playoffStage">
                      {brackets[index][key].map((slot, index3) => {
                        return (
                          <Droppable key={index3} droppableId={`${index3}`}>
                            {(provided) => (
                              <Card
                                className={
                                  isModerated && !isModerateSingle
                                    ? "ModeratePlayOffSlot"
                                    : "playoffSlot"
                                }
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                key={index3}
                              >
                                {slot.list.map((participant, index4) =>
                                  division.doublesFormat ? (
                                    <TeamObjDraggable
                                      team={participant}
                                      index={index4}
                                      key={participant.id}
                                    />
                                  ) : (
                                    <PlayerObjDraggable
                                      player={participant}
                                      index={index4}
                                      key={participant.id}
                                      isInTeam={isModerated && !isModerateSingle ? true : false}
                                    />
                                  )
                                )}
                                {provided.placeholder}
                              </Card>
                            )}
                          </Droppable>
                        );
                      })}
                    </div>
                  );
                }
                return (
                  <div key={index2} className="playoffStage">
                    {brackets[index][key].map((slot, index3) => (
                      <Card key={index3} className="playoffSlot">
                        {slot.list.map((participant, index4) =>
                          division.doublesFormat ? (
                            <TeamObj team={participant} key={index4} />
                          ) : isModerated && !isModerateSingle ? (
                            <TeamObj team={participant} key={index4} />
                          ) : (
                            <PlayerObj player={participant} key={index4} />
                          )
                        )}
                      </Card>
                    ))}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </DragDropContext>
    );
  else
    return (
      <div>
        {scorePopupOpen &&
          (league?.sportType === "pickleball" ? (
            <PickleballScoreUploadForm
              open={scorePopupOpen}
              setOpen={setScorePopupOpen}
              leagueID={track.track.leagueID}
              divisionId={track.track.divisionID}
              trackId={track.track.id}
              selectedTeamsForScore={selectedTeamsForScore}
              isFromPlayoff={true}
            />
          ) : (
            <ScoreUploadForm
              open={scorePopupOpen}
              setOpen={setScorePopupOpen}
              leagueID={track.track.leagueID}
              divisionId={track.track.divisionID}
              trackId={track.track.id}
              selectedTeamsForScore={selectedTeamsForScore}
              isFromPlayoff={true}
            />
          ))}
        {index === currIndex && (
          <div className="playoffPage">
            <div className="playoffBracketWrapper">
              {Object.keys(brackets[index]).map((key, index2) => {
                return (
                  <div key={index2} className="playoffStage">
                    {brackets[index][key].map((slot, index3) => (
                      <Card key={index3} className="playoffSlot">
                        {slot.list.map((participant, key) => (
                          <>
                            {division.doublesFormat ? (
                              <TeamBracketObject
                                key={key}
                                team={participant}
                                score={slot.score}
                                final={
                                  index2 ===
                                  Object.keys(brackets[index]).length - 1
                                }
                              />
                            ) : isModerated && !isModerateSingle? (
                              <TeamBracketObject
                                key={key}
                                team={participant}
                                score={slot.score}
                                final={
                                  index2 ===
                                  Object.keys(brackets[index]).length - 1
                                }
                              />
                            ) : (
                              <PlayerBracketObject
                                key={key}
                                player={participant}
                                score={slot.score}
                                final={
                                  index2 ===
                                  Object.keys(brackets[index]).length - 1
                                }
                              />
                            )}
                            {key === 0 &&
                                !isPublic &&
                                isDirector &&
                                (isModerated || isModerateSingle) &&
                                slot.score === "" &&
                                slot?.list?.filter((i) => {return i === "empty" || i.name === "Bye";}).length === 0 && (
                                    <Button
                                      className="enterResultPlayoffBtn"
                                      variant="outlined"
                                      onClick={() => {
                                        setScorePopupOpen(true);
                                        setSelectedTeamsForScore([
                                          slot?.list?.[0]?.id,
                                          slot?.list?.[1]?.id,
                                        ]);
                                      }}
                                    >
                                      Enter results
                                    </Button>
                              )}
                          </>
                        ))}
                      </Card>
                    ))}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
};

export default PlayoffsBracket;

import React, { useState, useEffect, Fragment } from "react";
import Loader from "react-loader-spinner";
import moment from "moment";
import { firestore } from "../../../firebase";
import { ReviewAboutMe } from "./ListItemProfileReview";
import { LoadMore } from "../../../components/table/Pagination";
import { getUserByID } from "../../../functions/firebaseFunctions";
const PlayerReviews = ({ player, pageNum }) => {
  const [loading, setLoading] = useState(true);
  // const [reviewsByMe, setReviewsByMe] = useState([]);
  const [reviewsAboutMe, setReviewsAboutMe] = useState([]);
  const resultsPerPage = 4;
  // const [reviewMePageNum, setReviewMePageNum] = useState(1);
  // const lastReviewMePageIndex = reviewMePageNum * resultsPerPage;
  const [reviewAboutMePageNum, setReviewAboutMePageNum] = useState(1);
  const lastReviewAboutMePageIndex = reviewAboutMePageNum * resultsPerPage;
  useEffect(() => {
    // const reviewsByMeRef = firestore
    //   .collection("reviews")
    //   .where("reviewer", "==", player.id)
    //   .where("publicReview", "!=", "");
    const reviewsAboutMeRef = firestore
      .collection("reviews")
      .where("reviewees", "array-contains", player.id)
      .where("publicReview", "!=", "");
    // const unsubscribeReviewsByMe = reviewsByMeRef.onSnapshot(
    //   async (snapshot) => {
    //     setReviewsByMe(
    //       await Promise.all(
    //         snapshot.docs.map(async (doc) => {
    //           const data = doc.data();
    //           const date = moment(data.matchDate).format("MMM D, YYYY");
    //           const reviewee = await getUserByID(data.reviewee);
    //           return { ...data, id: doc.id, reviewee, date };
    //         })
    //       )
    //     );
    //   }
    // );
    const unsubscribeReviewsAboutMe = reviewsAboutMeRef.onSnapshot(
      async (snapshot) => {
        const reviewsAboutMe = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const data = doc.data();
            const date = moment(data.matchDate);
            const displayDate = date.format("MMM D, YYYY");
            const reviewer = await getUserByID(data.reviewers[0]);
            return { ...doc.data(), id: doc.id, reviewer, date, displayDate };
          })
        );
        setReviewsAboutMe(reviewsAboutMe.sort((a, b) => b.date - a.date));
        setLoading(false);
      }
    );
    return () => {
      // unsubscribeReviewsByMe();
      unsubscribeReviewsAboutMe();
    };
  }, [player]);
  if (!loading)
    return (
      <Fragment>
        <div className="columnCenter">
          {reviewsAboutMe.slice(0, lastReviewAboutMePageIndex).map((r, i) => (
            <ReviewAboutMe
              key={i}
              review={r}
              reviewee={player}
              reviewer={r.reviewer}
            />
          ))}
          <LoadMore setValue={setReviewAboutMePageNum} />
        </div>
        {/* {pageNum === 3 && (
          <div className="columnCenter">
            {reviewsByMe.slice(0, lastReviewMePageIndex).map((r, i) => (
              <ReviewByMe
                key={i}
                review={r}
                reviewer={player}
                reviewee={r.reviewee}
              />
            ))}
            <LoadMore setValue={setReviewMePageNum} />
          </div>
        )} */}
      </Fragment>
    );
  return (
    <div className="pageWrapper">
      <Loader
        type="TailSpin"
        color="#02c39a"
        height={100}
        width={100}
        timeout={3000}
      />
    </div>
  );
};

export default PlayerReviews;

import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Loader from "react-loader-spinner";
import moment from "moment";
import { firestore, Firebase } from "../../firebase";
import { singleActionEmail } from "../../functions/Messaging";
import { DefaultTextField } from "../../components/CustomTextField";
import TableComponent from "../../components/table/TableComponent";
const tableHeader = [
  { label: "name", content: "Name", sortable: true },
  { label: "email", content: "Email", sortable: true },
  { label: "date", content: "Date", sortable: true },
  { label: "status", content: "Status", sortable: true },
];
const Referrals = ({ user, mobileSize }) => {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [referralCode, setReferralCode] = useState("XYZABC");
  const [referralHistory, setReferralHistory] = useState([]);

  useEffect(() => {
    setReferralHistory(
      user.referrals.map((referral) => {
        let date = Date(referral.date);
        const name = referral.name;
        const email = referral.email;
        const status = referral.status;
        return {
          cells: [
            { content: name, editable: false },
            { content: email, editable: false },
            { content: moment(date).format("MM-DD-YYYY"), editable: false },
            { content: status, editable: false },
          ],
          name: name,
          email: email,
          status: status,
          date: date,
        };
      })
    );
    const getData = async () => {
      const referralsSnap = await firestore
        .collection("coupons")
        .where("createdBy", "==", user.uid)
        .get();
      let referrals = referralsSnap.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });

      setReferralCode(referrals[0].code);
      setLoading(false);
    };
    getData();
  }, [user]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    firestore
      .collection("users")
      .doc(user.uid)
      .update({
        referrals: Firebase.firestore.FieldValue.arrayUnion({
          userID: "",
          email: email,
          name: name,
          status: "pending",
          date: new Date(),
        }),
      });
    await singleActionEmail({
      subject: `Join an exciting tennis league – get your 50% discount as introduction`,
      message: `${user.firstName} ${user.lastName} is referring you to an exciting tennis league that you might want to check out and join. Sign up by going to playgamesetmatch.com and view the ongoing or upcoming leagues.`,
      message2: ` Join the league that is right for you and enter the REFERRAL CODE: ${referralCode} during check-out. By using the code, you will be getting an introductory 50% discount on your first league.`,
      message3: "Good luck and Play On! ---- Game.Set.Match",
      recipients: [{ email, name }],
      url: `${process.env.REACT_APP_BASE_URL}Leagues`,
      buttonText: "View Leagues",
    });
    handleReset();
  };
  const handleReset = () => {
    setName("");
    setEmail("");
  };
  const handleCopy = () => {
    const text = document.createElement("textarea");
    text.innerText = referralCode;
    document.body.appendChild(text);
    text.select();
    document.execCommand("copy");
    text.remove();
  };
  if (loading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  return (
    <Grid container spacing={mobileSize ? 3 : 6}>
      <Grid item xs={12} md={6} lg={4} xl={4}>
        <Typography className="referralsHeader">Refer a Friend</Typography>
        <form className="referralsAddWrapper" onSubmit={handleSubmit}>
          <Typography className="referralsTextFieldHeader">Name</Typography>
          <DefaultTextField
            state={name}
            setState={setName}
            required={true}
            placeholder="Name"
          />
          <Typography className="referralsTextFieldHeader">Email</Typography>
          <DefaultTextField
            state={email}
            setState={setEmail}
            required={true}
            placeholder="Email"
          />
          <Typography className="referralsInformation margin20Top">
            There are several ways to keep earning MatchPoints™ on your next
            League:
          </Typography>
          <Typography className="referralsInformation margin20Top">
            1) Enter your tennis buddy’s name and email above. We will
            automatically send them an email and reward you with 100
            MatchPoints™ when they eventually sign up for a league using the
            promotional code we will send them.
          </Typography>
          <Typography className="referralsInformation margin20Top">
            -OR-
          </Typography>
          <Typography className="referralsInformation margin20Top">
            2) Copy the Coupon Code below and share it with your friends (via
            your favorite social media or email or text).
          </Typography>
          <Typography className="referralsInformation margin20Top">
            <b>
              Note: make sure your friend uses the Coupon Code when they sign up
              for a league so you can get credit for it. Once they sign up, we
              will reward you with 100 MatchPoints™
            </b>
          </Typography>
          <Typography className="referralsInformation margin20Top">
            (See MatchPoints Reward Program on how to redeem these MatchPoints)
          </Typography>
          <TextField
            className="loginItem"
            value={referralCode}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button variant="text" onClick={handleCopy}>
                    <Typography className="referralsCodeCopyText">
                      Copy/Share
                    </Typography>
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          <Divider className="margin20Vertical" />
          <div className="rowCenter">
            <div className="filler" />
            <Button
              variant="text"
              className="referralResetButton"
              onClick={handleReset}
            >
              <Typography className="referralResetButtonText">Reset</Typography>
            </Button>
            <Button className="referralSendButton margin10Left" type="submit">
              <Typography className="referralSendButtonText">SEND</Typography>
            </Button>
          </div>
        </form>
      </Grid>
      <Grid
        style={{ display: "flex", flexDirection: "column" }}
        item
        xs={12}
        md={6}
        lg={8}
        xl={8}
      >
        <Typography className="referralsHeader">Referral History</Typography>
        <div className="referralsHistoryWrapper">
          <TableComponent
            tableHeader={tableHeader}
            type={"viewOnly"}
            list={referralHistory}
            setList={setReferralHistory}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Referrals;

import React, { useState, Fragment, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import { Firebase, firestore, storage } from "../../../firebase";
import { singleActionEmail } from "../../../functions/Messaging";
import { noActionEmail } from "../../../functions/Messaging";
import CustomStepper from "../../../components/CustomStepper";
import PageOne from "./PageOne";
import PageTwo from "./PageTwo";

const stepNames = ["Director Information", "Agreement"];
const DirectorApplication = ({ user, zoneList }) => {
  //Stepper Info
  const [activeStep, setActiveStep] = useState(0);
  //Draft Info
  const [submittingApp, setSubmittingApp] = useState(false);
  const [savingDraftApplication, setSavingDraftApplication] = useState(false);
  const [currDraft, setCurrDraft] = useState(null);
  const [saved, setSaved] = useState(false);
  const [completedApplication, setCompletedApplication] = useState(false);
  const [qualities, setQualities] = useState("");
  const [QualitiesSaved, setQualitiesSaved] = useState("");
  const [facebook, setFacebook] = useState(null);
  const [linkedIn, setLinkedIn] = useState(null);
  const [twitter, setTwitter] = useState(null);
  const [experience, setExperience] = useState("no");
  const [experienceYears, setExperienceYears] = useState(null);
  const [signature, setSignature] = useState("");
  const [agreementURLS, setAgreementURLS] = useState([""]);
  useEffect(() => {
    const getDocuments = async () => {
      const ref = storage.ref("adminDocuments/applications");
      const urls = await ref.listAll();
      setAgreementURLS(await Promise.all(urls.items.map(async (docRef) => ({ url: await docRef.getDownloadURL(), label: docRef.name }))));
    }
    getDocuments();
  }, []);
  useEffect(() => {
    let url = window.location.href;
    let params = url.split("/");
    let draftID = params[params.length - 1];
    if (draftID !== "DirectorApplication") {
      firestore
        .doc(`applications/${draftID}`)
        .get()
        .then((doc) => {
          autoFillDraftInfo(doc);
        });
    }
  }, [user, zoneList]);
  const sendMessage = async () => {
    await singleActionEmail({
      subject: `An director application been submitted for approval!`,
      message: `${user.firstName} ${user.lastName}'s director application is ready for review!`,
      recipients: [{ email: "Admin@playgamesetmatch.com", name: "Admin" }],
      url: `${process.env.REACT_APP_BASE_URL}Admin`,
      buttonText: "Review Application",
    });
    await noActionEmail({
      subject: `Application Received`,
      message: `Thank you for applying to be a League Coordinator and being part of our growing community. We will respond to your application shortly. Thanks.`,
      recipients: [{ email: `${user.authEmail}`, name: `${user.firstName}` }],
    });
  };

  const addEventDoc = async (status) => {
    await firestore
      .collection("applications")
      .add({
        type: "Director Application",
        directorID: user.uid,
        name: user.firstName + " " + user.lastName,
        status: status,
        experience: experience,
        experienceYears: experienceYears,
        qualities: qualities,
        facebook: facebook,
        twitter: twitter,
        linkedIn: linkedIn,
        signature: signature,
        dateCreated: Firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(async (docRef) => {
        if (status !== "pending") window.location.href += `/${docRef.id}`;
      });
  };
  const updateEventDoc = async (status) => {
    await currDraft.ref.update({
      directorID: user.uid,
      name: user.firstName + " " + user.lastName,
      status: status,
      experience: experience,
      experienceYears: experienceYears,
      qualities: qualities,
      facebook: facebook,
      twitter: twitter,
      linkedIn: linkedIn,
      signature: signature,
      dateCreated: Firebase.firestore.FieldValue.serverTimestamp(),
    });
  };

  const submitEventApplication = async () => {
    setSubmittingApp(true);
    if (currDraft === null) await addEventDoc("pending");
    else await updateEventDoc("pending");
    await sendMessage();
    setCompletedApplication(true);
    setActiveStep(0);
    setSubmittingApp(false);
  };

  const submitDraftApplication = async () => {
    setSavingDraftApplication(true);
    if (currDraft === null && !saved) {
      await addEventDoc("draft");
      setSaved(true);
    } else {
      await updateEventDoc("draft");
    }
    setSavingDraftApplication(false);
  };
  const autoFillDraftInfo = (ds) => {
    setCurrDraft(ds);
    ds = ds.data();
    setFacebook(ds.facebook);
    setTwitter(ds.twitter);
    setLinkedIn(ds.linkedIn);
    setExperience(ds.experience);
    setExperienceYears(ds.experienceYears);
    //setStatus(ds.status);
    setSignature(ds.signature);
    setQualities(ds.description);
    setQualitiesSaved(ds.description);

    //setActiveStep(ds.currStep);
  };
  const handleBack = () => {
    setActiveStep((step) => step - 1);
  };
  const submitPage = (e) => {
    e.preventDefault();
    if (activeStep === 0) {
      setQualitiesSaved(qualities);
      setActiveStep((step) => step + 1);
    } else if (activeStep === 1) {
      submitEventApplication();
    }
  };
  return (
    <Grid container spacing={6}>
      {!completedApplication && (
        <Fragment>
          <Grid item xs={12} md={12} lg={12}>
            <CustomStepper
              step={activeStep}
              setStep={setActiveStep}
              stepLabels={stepNames}
            />
          </Grid>
          <Grid className="columnCenter" item xs={12} md={12} lg={12}>
            <form className="widthHalf" onSubmit={submitPage}>
              {activeStep === 0 && (
                <PageOne
                  setActiveStep={setActiveStep}
                  experience={experience}
                  setExperience={setExperience}
                  experienceYears={experienceYears}
                  setExperienceYears={setExperienceYears}
                  setQualities={setQualities}
                  QualitiesSaved={QualitiesSaved}
                  facebook={facebook}
                  setFacebook={setFacebook}
                  twitter={twitter}
                  setTwitter={setTwitter}
                  linkedIn={linkedIn}
                  setLinkedIn={setLinkedIn}
                />
              )}
              {activeStep === 1 && (
                <PageTwo
                  setActiveStep={setActiveStep}
                  signature={signature}
                  setSignature={setSignature}
                  agreementURLS={agreementURLS}
                />
              )}

              <div className="maxWidth margin20Vertical">
                <Divider />
              </div>
              <div className="rowCenter">
                <Button
                  variant="text"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  <div className="rowCenter">
                    <ArrowBackIos className="createLeagueBackButtonIcon margin10Right" />
                    <Typography className="createLeagueUtilityButtonText">
                      Back
                    </Typography>
                  </div>
                </Button>
                <div className="filler" />
                <Button
                  variant="text"
                  onClick={submitDraftApplication}
                  disabled={savingDraftApplication}
                >
                  {savingDraftApplication ? (
                    <Loader
                      type="TailSpin"
                      color="#02c39a"
                      height={40}
                      width={40}
                      timeout={3000}
                    />
                  ) : (
                    <Typography className="createLeagueUtilityButtonText">
                      Save Draft
                    </Typography>
                  )}
                </Button>
                <Button
                  className="createLeagueNextButton margin10Left"
                  type="submit"
                  disabled={submittingApp}
                >
                  {submittingApp ? (
                    <Loader
                      type="TailSpin"
                      color="#02c39a"
                      height={40}
                      width={40}
                      timeout={3000}
                    />
                  ) : (
                    <Typography className="createLeagueNextButtonText">
                      {activeStep !== 1 ? "Next" : "Submit"}
                    </Typography>
                  )}
                </Button>
              </div>
            </form>
          </Grid>
        </Fragment>
      )}
      {completedApplication && (
        <Grid className="columnCenter" item xs={12} md={12} lg={12}>
          <div className="widthHalf">
            <Typography variant="h4">
              {`Thank you for submitting your director application.`}
            </Typography>
            <Typography variant="h4">
              We will be reviewing your application shortly and get back to you
              if we have any questions.
            </Typography>
            <Typography variant="h4">
              Please monitor your email for updates. Best wishes.
            </Typography>
            <div className="margin30" />
            <div className="columnCenter">
              <Link to="/Leagues" className="noUnderline">
                <Button className="createLeagueNextButton">back home</Button>
              </Link>
            </div>
          </div>
        </Grid>
      )}
    </Grid>
  );
};
export default DirectorApplication;

import React, { useState, useEffect, Fragment, useRef } from "react";
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Divider from "@material-ui/core/Divider"
import { surfaces, shots, brands } from "../../providers/PreferenceLists";
import { firestore, functions } from "../../firebase";
import { generateActivity } from "../../functions/firebaseFunctions";
import { CustomTabs } from "../../components/CustomTabs";
import {
  DefaultTextField,
  MenuTextField,
} from "../../components/CustomTextField";
import { DefaultSwitch } from "../../components/CustomSwitches";
import CheckboxList from "../../components/CheckboxList";

const distances = [
  { label: "No Preference", value: 0 },
  { label: "1 Mile", value: 1 },
  { label: "2 Miles", value: 2 },
  { label: "3 Miles", value: 3 },
  { label: "4 Miles", value: 4 },
  { label: "5 Miles", value: 5 },
  { label: "6 Miles", value: 6 },
  { label: "7 Miles", value: 7 },
  { label: "8 Miles", value: 8 },
  { label: "9 Miles", value: 9 },
  { label: "10 Miles", value: 10 },
];

const Preferences = ({ user }) => {
  const [enableSave, setEnableSave] = useState(false);
  const didMountRef = useRef(false);
  const [surface, setSurface] = useState(user.surface ? user.surface : "");
  const [aboutMe, setAboutMe] = useState(user.aboutMe ? user.aboutMe : "");
  const [facebookAccessToken, setFacebookAccessToken] = useState(user.facebookAccessToken ? user.facebookAccessToken : "");
  const [facebookPageId, setFacebookPageId] = useState(user.facebookPageId ? user.facebookPageId : "");
  const [twitterAppSecret, setTwitterAppSecret] = useState(user.twitterAppSecret || "");
  const [twitterAppKey, setTwitterAppKey] = useState(user.twitterAppKey || "");
  const [twitterAccessToken, setTwitterAccessToken] = useState(user.twitterAccessToken || "");
  const [twitterAccessSecret, setTwitterAccessSecret] = useState(user.twitterAccessSecret || "");
  const [favoriteBrand, setFavoriteBrand] = useState(
    user.favoriteBrand ? user.favoriteBrand : ""
  );
  const [favoritePlayer, setFavoritePlayer] = useState(
    user.favoritePlayer ? user.favoritePlayer : ""
  );
  const [favoriteShot, setFavoriteShot] = useState(
    user.favoriteShot ? user.favoriteShot : ""
  );
  const [playRadius, setPlayRadius] = useState(
    user.playRadius ? user.playRadius : 0
  );
  const [doublesSearch, setDoublesSearch] = useState(user.doublesSearch);
  const [matchTypes, setMatchTypes] = useState(
    user.matchTypes
      ? user.matchTypes
      : [
          { label: "Singles", check: false },
          { label: "Doubles", check: false },
          { label: "Mixed Doubles", check: false },
        ]
  );
  const [dayPref, setDayPref] = useState(
    user.dayPref
      ? user.dayPref
      : [
          { label: "Monday", check: false },
          { label: "Tuesday", check: false },
          { label: "Wednesday", check: false },
          { label: "Thursday", check: false },
          { label: "Friday", check: false },
          { label: "Saturday", check: false },
          { label: "Sunday", check: false },
        ]
  );
  const [timePref, setTimePref] = useState(
    user.timePref
      ? user.timePref
      : [
          { label: "Early Morning", check: false },
          { label: "Morning", check: false },
          { label: "Afternoon", check: false },
          { label: "Evening", check: false },
        ]
  );
  const pageLabels = ["Play Information", "Favorites"];
  if(user.director){
    pageLabels.push("Social Post Config")
  }
  const [pageNum, setPageNum] = useState(0);
  useEffect(() => {
    if (didMountRef.current) setEnableSave(true);
    else didMountRef.current = true;
  }, [
    surface,
    aboutMe,
    favoriteBrand,
    favoritePlayer,
    favoriteShot,
    playRadius,
    dayPref,
    timePref,
    matchTypes,
    doublesSearch,
    facebookPageId,
    facebookAccessToken,
    twitterAppSecret,
    twitterAppKey,
    twitterAccessToken,
    twitterAccessSecret
  ]);
  const updateUser = async (e) => {
    e.preventDefault();
    let complete = false;
    if (
      surface !== "" &&
      aboutMe !== "" &&
      favoriteBrand !== "" &&
      favoriteShot !== "" &&
      favoritePlayer !== "" &&
      playRadius !== 0 &&
      !user.completedPreferences
    ) {
      complete = true;
    }
    let result = user.completedPreferences
      ? user.completedPreferences
      : complete;
    let profileComplete = user.completedProfile
      ? user.completedProfile
      : user.completedInformation && complete;
    if (user.completedInformation && complete) {
      const time = new Date();
      await generateActivity({
        date: time,
        category: "profileComplete",
        sportType: "both",
        players: [user.uid],
        description: `${user.firstName} ${user.lastName} completed their profile!`,
      });
    }
    await firestore.doc(`users/${user.uid}`).update({
      surface: surface,
      aboutMe: aboutMe,
      favoriteBrand: favoriteBrand,
      favoriteShot: favoriteShot,
      favoritePlayer: favoritePlayer,
      playRadius: playRadius,
      matchTypes: matchTypes,
      dayPref: dayPref,
      timePref: timePref,
      completedPreferences: result,
      completedProfile: profileComplete,
      doublesSearch: doublesSearch,
      facebookPageId: facebookPageId,
      facebookAccessToken: facebookAccessToken,
      twitterAppSecret: twitterAppSecret,
      twitterAccessToken: twitterAccessToken,
      twitterAppKey: twitterAppKey,
      twitterAccessSecret: twitterAccessSecret
    });
    try {
      const updateUserData = functions.httpsCallable(
        'webhookProcessActionUpdateUserLeague'
      );
      updateUserData({ userID: user.uid });
    } catch (error) {
      console.log('webhookProcessActionUpdateUserLeague', error);
    }
    setEnableSave(false);
  };
  return (
    <div className="column filler">
      <CustomTabs labels={pageLabels} value={pageNum} setValue={setPageNum} />
      <form className="column filler" onSubmit={(e) => updateUser(e)}>
        <Grid className="preferencesWrapper" container spacing={2}>
          {pageNum === 0 && (
            <Fragment>
              <Grid xs={12} item>
                <Typography className="profileRequiredFieldText">
                  Available Days
                </Typography>
                <CheckboxList value={dayPref} setValue={setDayPref} />
                <Typography className="profileRequiredFieldText margin10Top">
                  Available Times
                </Typography>
                <CheckboxList value={timePref} setValue={setTimePref} />
                <Typography className="profileRequiredFieldText margin10Top">
                  Match Type
                </Typography>
                <CheckboxList value={matchTypes} setValue={setMatchTypes} />
              </Grid>
              <Grid xs={12} item>
                <Typography className="profileRequiredFieldText">
                  Search Inclusion
                </Typography>
                <Typography className="profileRequiredFieldText">
                  I want to be included on the available list when people are
                  searching for Double's Partner for leagues(note: you will
                  still need to accept an invitation if selected by a player):
                </Typography>
                <div className="rowCenter">
                  <DefaultSwitch
                    state={doublesSearch}
                    setState={setDoublesSearch}
                  />
                  {doublesSearch ? (
                    <Typography className="profileRequiredFieldText">
                      Yes. Include me please.
                    </Typography>
                  ) : (
                    <Typography className="profileRequiredFieldText">
                      No. Do not include me.
                    </Typography>
                  )}
                </div>
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
                <Typography className="profileRequiredFieldText">
                  Court Type
                </Typography>
                <MenuTextField
                  menuSelection={surfaces}
                  state={surface}
                  setState={setSurface}
                  required={false}
                  placeholder="Clay"
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
                <Typography className="profileRequiredFieldText">
                  Preferred Distance from Court
                </Typography>
                <MenuTextField
                  menuSelection={distances}
                  state={playRadius}
                  setState={setPlayRadius}
                  required={false}
                  placeholder="7 Miles"
                />
              </Grid>
            </Fragment>
          )}
          {pageNum === 1 && (
            <Fragment>
              <Grid xs={12} item>
                <Typography className="profileRequiredFieldText">
                  More About Me
                </Typography>
                <DefaultTextField
                  state={aboutMe}
                  setState={setAboutMe}
                  placeholder="Put in a little bit more information about you here."
                  required={false}
                  multiline={6}
                />
                <Typography className="profileRequiredFieldText margin10Top">
                  Favorite Pro Player
                </Typography>
                <DefaultTextField
                  state={favoritePlayer}
                  setState={setFavoritePlayer}
                  placeholder="Serena Williams"
                  required={false}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
                <Typography className="profileRequiredFieldText">
                  Favorite Racquet Brand
                </Typography>
                <MenuTextField
                  menuSelection={brands}
                  state={favoriteBrand}
                  setState={setFavoriteBrand}
                  required={false}
                  placeholder="Prince"
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
                <Typography className="profileRequiredFieldText">
                  Favorite Tennis Shot
                </Typography>
                <MenuTextField
                  menuSelection={shots}
                  state={favoriteShot}
                  setState={setFavoriteShot}
                  required={false}
                  placeholder="Backhand"
                />
              </Grid>
            </Fragment>
          )}
          {pageNum === 2 && user.director && (
              <>
                <Grid xs={12} item>
                  <Typography className="profileSubpageTitle margin20Bottom">
                    Facebook
                  </Typography>
                  <div className="margin20Bottom">
                    <Typography className="profileRequiredFieldText">
                      Access Token
                    </Typography>
                    <DefaultTextField
                        state={facebookAccessToken}
                        setState={setFacebookAccessToken}
                        required={true}
                        placeholder="Access Token"
                    />
                  </div>
                  <div>
                    <Typography className="profileRequiredFieldText">
                      Page Id
                    </Typography>
                    <DefaultTextField
                        state={facebookPageId}
                        setState={setFacebookPageId}
                        required={true}
                        placeholder="Page Id"
                    />
                  </div>
                </Grid>
                <Grid xs={12} item>
                  <Typography className="profileSubpageTitle margin20Bottom">
                    Twitter
                  </Typography>
                  <div className="margin20Bottom">
                    <Typography className="profileRequiredFieldText">
                      App Secret
                    </Typography>
                    <DefaultTextField
                        state={twitterAppSecret}
                        setState={setTwitterAppSecret}
                        required={true}
                        placeholder="App Secret"
                    />
                  </div>
                  <div className="margin20Bottom">
                    <Typography className="profileRequiredFieldText">
                      App Key
                    </Typography>
                    <DefaultTextField
                        state={twitterAppKey}
                        setState={setTwitterAppKey}
                        required={true}
                        placeholder="App Key"
                    />
                  </div>
                  <div className="margin20Bottom">
                    <Typography className="profileRequiredFieldText">
                      Access Token
                    </Typography>
                    <DefaultTextField
                        state={twitterAccessToken}
                        setState={setTwitterAccessToken}
                        required={true}
                        placeholder="Access Token"
                    />
                  </div>
                  <div className="margin20Bottom">
                    <Typography className="profileRequiredFieldText">
                      Access Secret
                    </Typography>
                    <DefaultTextField
                        state={twitterAccessSecret}
                        setState={setTwitterAccessSecret}
                        required={true}
                        placeholder="Access Secret"
                    />
                  </div>
                </Grid>
              </>
          )}
        </Grid>
        <div className="filler" />
        <Divider className="margin20Vertical" />
        <div className="rowCenter profileSaveButtonPlaceholder">
          <div className="filler" />
          <Button
            className="profileSaveButton"
            variant="outlined"
            type="submit"
            disabled={!enableSave}
            style={{
              border: enableSave ? "1px solid #f70909" : "1px solid #55b56a",
            }}
          >
            {enableSave ? (
              <Typography className="profileUpdateButtonText">
                Update
              </Typography>
            ) : (
              <Typography className="profileSaveButtonText">Saved</Typography>
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Preferences;

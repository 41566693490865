import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Clear from "@material-ui/icons/Clear";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import update from "immutability-helper";
//A view only tableCell

const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#058360",
    color: theme.palette.common.white,
    fontSize: 17,
    fontWeight: 600,
  },
  body: {
    fontSize: 16,
    background: "white",
  },
}))(TableCell);
export const CustomCell = ({ cell }) => {
  return <CustomTableCell align="left">{cell.content}</CustomTableCell>;
};

export const CustomSortableCell = ({
  cell,
  handleSort,
  sortDirection,
  sortSelected,
}) => {
  return (
    <CustomTableCell align="left" onClick={() => handleSort(cell.label)}>
      <TableSortLabel
        className="customTableHeader"
        direction={sortDirection}
        active={sortSelected === cell.label}
      >
        {cell.content}
      </TableSortLabel>
    </CustomTableCell>
  );
};
//A tableCell that supports sorting
export const SortableCell = ({
  cell,
  handleSort,
  sortDirection,
  sortSelected,
}) => {
  return (
    <TableCell align="left" onClick={() => handleSort(cell.label)}>
      <TableSortLabel
        direction={sortDirection}
        active={sortSelected === cell.label}
      >
        {cell.content}
      </TableSortLabel>
    </TableCell>
  );
};

//A tablecell for opening nested table
export const OpenNestedCell = ({ open, setOpen, rowPos }) => {
  return (
    <TableCell align="left">
      <IconButton
        onClick={() => {
          const temp = update(open, {
            [rowPos]: { $set: !open[rowPos] },
          });
          setOpen(temp);
        }}
      >
        {open[rowPos] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
    </TableCell>
  );
};

export const EditableCell = ({ cell, edit }) => {
  return (
    <TableCell align="left">
      {edit ? (
        <TextField value={cell.content} onChange={cell.handleCellEdit} />
      ) : (
        cell.content
      )}
    </TableCell>
  );
};

//A tableCell that handles in line edits
export const LineEditCell = ({
  row,
  handleDelete,
  handleEdit,
  handleStart,
  isStartDisable,
  redirectModeratedScreen,
}) => {
  return (
    <TableCell align="left">
      {row?.status !== "Completed" && (
        <>
          {row?.status !== "In Progress" ? (
            <>
              <Button
                disabled={isStartDisable}
                className="selectedbutton margin10Right minWidth75"
                onClick={() => handleStart(row)}
              >
                Start
              </Button>
              <Tooltip title={row.editing ? "Cancel" : "Edit"}>
                <IconButton onClick={() => handleEdit(row)}>
                  {row.editing ? <Clear /> : <Edit />}
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton onClick={() => handleDelete(row)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <Button
              onClick={() => redirectModeratedScreen(row)}
              className="profileSaveButton margin10Top"
              variant="outlined"
            >
              Moderate
            </Button>
          )}
        </>
      )}
    </TableCell>
  );
};

//A tableCell that opens another component for viewing or editing
export const ComponentEditCell = ({ row, handleComponentEdit }) => {
  return (
    <TableCell align="left">
      <IconButton onClick={() => handleComponentEdit(row)}>
        <Edit />
      </IconButton>
    </TableCell>
  );
};

import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { CustomTabs } from "../../components/CustomTabs";
import TableComponent from "../../components/table/TableComponent";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
//NOTE: add empty string to geturl function and that way I can handle when theres no id
const RewardHistoryDialog = ({
  open,
  setOpen,
  pointsEarned,
  setPointsEarned,
  pointsRedeemed,
  setPointsRedeemed,
}) => {
  const [pageNum, setPageNum] = useState(0);
  const pointsEarnedHeader = ["Date", "Description", "Points"].map((title) => {
    let labelTemp = title.toLowerCase();
    return {
      label: labelTemp,
      content: title,
      sortable: title !== "description" ? true : false,
    };
  });
  const pointsRedeemedHeader = [
    "Date",
    "Item",
    "Units",
    "Points",
    "Status",
  ].map((title) => {
    let labelTemp = title.toLowerCase();
    return {
      label: labelTemp,
      content: title,
      sortable: true,
    };
  });
  const handleClose = () => {
    setOpen(false);
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: "#55B56A",
        light: "#EDF8F4",
        dark: "#028360",
      },
    },
    overrides: {
      MuiDialog: {
        paper: {
          borderRadius: "10px",
        },
      },
      MuiTab: {
        textColorInherit: {
          color: "#55b56a",
        },
      },
      MuiTabs: {
        indicator: {
          backgroundColor: "#000000",
        },
      },
    },
    props: {
      MuiButton: {
        variant: "outlined",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogContent style={{ padding: 0, paddingTop: 10 }}>
          <CustomTabs
            value={pageNum}
            setValue={setPageNum}
            labels={["Points Earned", "Points Redeemed"]}
          />
          {pageNum === 0 && (
            <TableComponent
              list={pointsEarned}
              setList={setPointsEarned}
              tableHeader={pointsEarnedHeader}
              type="viewOnly"
            />
          )}
          {pageNum === 1 && (
            <TableComponent
              list={pointsRedeemed}
              setList={setPointsRedeemed}
              tableHeader={pointsRedeemedHeader}
              type="viewOnly"
            />
          )}
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};
export default RewardHistoryDialog;

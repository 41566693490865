import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { firestore } from "../../firebase";
import { getLeagueTracks } from "../../functions/firebaseFunctions";
import { LoadMore } from "../../components/table/Pagination";
import ListItemLeague from "./ListItemLeague";
import UpcomingLeagueSearchBar from "./UpcomingLeagueSearchBar";
import Loader from "react-loader-spinner";

const UpcomingLeagues = ({
  isPickleball,
  zoneList,
  mobileSize,
  sportLoading,
  user,
  leagueNameFilter,
  setLeagueNameFilter,
  filterBySection,
  setFilterBySection,
  filterByLocale,
  setFilterByLocale,
  filterByArea,
  setFilterByArea,
  divisionSelect,
  setDivisionSelect,
  playLevel,
  setPlayLevel,
}) => {
  const [leagues, setLeagues] = useState([]);
  const [sortBy, setSortBy] = useState("Date: Earliest-Latest");
  const [sortOptions] = useState([
    "Price: Low-High",
    "Price: High-Low",
    "Date: Earliest-Latest",
    "Date: Latest-Earliest",
  ]);

  const [priceRange, setPriceRange] = useState([0, 200]);
  const [pageNum, setPageNum] = useState(1);
  const resultsPerPage = 18;
  const indexOfLastItem = pageNum * resultsPerPage;
  useEffect(() => {
    const queryLeagues = async (leaguesRef) => {
      const snapshot = await leaguesRef.get();
      let leagues = snapshot.docs.map((league) => ({
        id: league.id,
        ...league.data(),
      }));
      setLeagues(await getLeagueTracks(leagues, "purchase"));
    };
    let leaguesRef = firestore
      .collection("leagues")
      .where("status", "==", "approved");
    if (filterBySection !== "All")
      leaguesRef = leaguesRef.where("section", "==", filterBySection);
    if (filterByLocale !== "All")
      leaguesRef = leaguesRef.where("locale", "==", filterByLocale);
    if (filterByArea !== "All")
      leaguesRef = leaguesRef.where(
        "locations",
        "array-contains",
        filterByArea
      );
    leaguesRef = leaguesRef.where(
      "sportType",
      "==",
      user?.currentSport || "tennis"
    );
    leaguesRef = leaguesRef.limit(pageNum * resultsPerPage);
    queryLeagues(leaguesRef);
  }, [
    filterBySection,
    filterByLocale,
    filterByArea,
    pageNum,
    user.currentSport,
  ]);

  const filterByName = (l) => {
    if (leagueNameFilter === "") return true;
    return l.name.indexOf(leagueNameFilter) === 0;
  };
  const filterByDivision = (l) => {
    const temp = divisionSelect.filter((d) => d.label === l.division);
    return temp[0] ? temp[0].check : false;
  };
  const filterByLevel = (l) => {
    const temp = playLevel.filter((lev) => lev.level === l.level);
    return temp[0].check;
  };
  const filterByPrice = (l) => {
    return priceRange[0] <= l.price && l.price <= priceRange[1];
  };
  const sortListings = (a, b) => {
    const aDate = a.startDate.toDate();
    const bDate = b.startDate.toDate();
    if (sortBy === "Price: Low-High") {
      return a.price - b.price;
    } else if (sortBy === "Price: High-Low") {
      return b.price - a.price;
    } else if (sortBy === "Date: Earliest-Latest") {
      return aDate - bDate;
    } else if (sortBy === "Date: Latest-Earliest") {
      return bDate - aDate;
    }
    return;
  };

  const displayList = leagues
    .filter((l) => filterByName(l))
    .filter((l) => filterByDivision(l))
    .filter((l) => filterByLevel(l))
    .filter((l) => filterByPrice(l))
    .sort((a, b) => sortListings(a, b));

  if (sportLoading)
    return (
      <div className="pageWrapper">
        <Loader
          type="TailSpin"
          color="#02c39a"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    );
  return (
    <Grid container spacing={mobileSize ? 3 : 6}>
      <Grid item xs={12}>
        <UpcomingLeagueSearchBar
          search={leagueNameFilter}
          setSearch={setLeagueNameFilter}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortOptions={sortOptions}
          user={user}
          sectionSelect={filterBySection}
          setSectionSelect={setFilterBySection}
          localeSelect={filterByLocale}
          setLocaleSelect={setFilterByLocale}
          areaSelect={filterByArea}
          setAreaSelect={setFilterByArea}
          zoneList={zoneList}
          divisionSelect={divisionSelect}
          setDivisionSelect={setDivisionSelect}
          levelSelect={playLevel}
          setLevelSelect={setPlayLevel}
          priceRange={priceRange}
          setPriceRange={setPriceRange}
        />
      </Grid>
      <Grid className="columnCenter" item xs={12}>
        <Grid container spacing={mobileSize ? 3 : 6}>
          {displayList &&
            displayList
              .slice(0, indexOfLastItem)
              .map((combinedLeague, i) => (
                <ListItemLeague
                  isPickleball={isPickleball}
                  key={i}
                  isUpcoming
                  combinedLeague={combinedLeague}
                />
              ))}
        </Grid>
        <div className="filler" />
        {indexOfLastItem < displayList.length && (
          <LoadMore setValue={setPageNum} />
        )}
      </Grid>
    </Grid>
  );
};

export default UpcomingLeagues;

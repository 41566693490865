import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Add from "@material-ui/icons/Add";
import TableComponent from "../../../components/table/TableComponent";
import CouponForm from "./CouponForm";

const header = [
  { label: "code", content: "Code", sortable: true },
  { label: "active", content: "Active", sortable: true },
  { label: "oneTimeUse", content: "One Time Use", sortable: true },
  { label: "percentDiscount", content: "Percent Discount", sortable: true },
  { label: "discreteDiscount", content: "Discrete Discount", sortable: true },
  { label: "date", content: "Date Created", sortable: true },
];

const AdminManageCoupons = ({ coupons, setCoupons }) => {
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [editingCoupon, setEditingCoupon] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);

  const handleComponentEdit = (selectedReward) => {
    setSelectedCoupon(selectedReward);
    setEditingCoupon(true);
    setOpenEditForm(true);
  };
  const handleCouponCreation = () => {
    setEditingCoupon(false);
    setOpenEditForm(true);
  };
  return (
    <Grid item xs={12}>
      <CouponForm
        open={openEditForm}
        setOpen={setOpenEditForm}
        isEditing={editingCoupon}
        coupon={selectedCoupon}
      />
      <Tooltip title="Add Coupon">
        <Button
          onClick={handleCouponCreation}
          className="margin20Right"
          variant="text"
        >
          <div className="flexCenter">
            <Add className="image30 appBarUploadScoreButtonIcon" />
            <Typography className="appBarUploadScoreButtonText">
              Add Coupon
            </Typography>
          </div>
        </Button>
      </Tooltip>
      <TableComponent
        list={coupons}
        setList={setCoupons}
        tableHeader={header}
        handleComponentEdit={handleComponentEdit}
        type="componentEdit"
        rowsPerPageOverride={10}
      />
    </Grid>
  );
};
export default AdminManageCoupons;

import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import DialogActions from "@material-ui/core/DialogActions";
import { functions } from "../../../firebase";
import { noActionEmail } from "../../../functions/Messaging";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

function AdminLeagueApprovalForm({ open, setOpen, league, director, zoneList }) {
  const [comment, setComment] = useState("");
  function handleClose() {
    setOpen(false);
  }
  const isPrivateLeagueSendMessage =async ()=>{
    let subject = `The ${league.event ? `event` : `league`}, ${league.name}, you proposed is now approved.`;
    await noActionEmail({
      subject: subject,
      message: subject,
      message2: `Reminder: since this is a Private League, make sure that you communicate your Invite Code: ${league.inviteCode} to your invited players via email or your social media channels.`,
      message3: `Alternatively, you can go to each league and division and copy the URL into your clipboard. There will be a copy URL button on each league information page.`,
      message4: 'Good Luck',
      recipients: [{ email: director.email, name: director.firstName }],
    });
  }

  const sendMessage = async (result) => {
    let subject = `Your proposed ${league.event ? `event` : `league`}, ${
      league.name
    } has been ${result}!`;
    await noActionEmail({
      subject: subject,
      message: subject,
      message2: "Admin Comments:",
      message3: comment,
      recipients: [{ email: director.email, name: director.firstName }],
    });
  };
  const activateEvent = async () => {
    league.ref
      .collection("divisions")
      .add({
        division: "Coed",
        doublesFormat: false,
        gender: "coed",
        leagueID: league.id,
        level: 0,
      })
      .then(function (docRef) {
        league.ref
          .collection("divisions")
          .doc(docRef.id)
          .collection("tracks")
          .add({
            divisionID: docRef.id,
            leagueID: league.id,
            players: [],
            track: 0,
          });
      });
  };

  async function submitApproval(e, result) {
    e.preventDefault();
    await league.ref.update({
      status: result,
      adminComment: comment,
    });
    if(league.isPrivate){
      await isPrivateLeagueSendMessage();
    }
    await sendMessage(result);
    if (result === "approved") {
      if (league.event) await activateEvent();
      try {

        let leagueAreas = [];
        if(zoneList?.length> 0){
          league.locations.forEach((areaID) => {
            leagueAreas.push(zoneList.find((zone) => zone.areaID === areaID).area);
          });
        }

        const processApproval = functions.httpsCallable(
          "processLeagueApproval"
        );
        processApproval({
          leagueID: league.id,
          directorID: director.id,
          leagueData: {
            locations : leagueAreas
        }
        });
      } catch (error) {
        console.log("processLeagueApprovalError", error);
      }
    }

    setOpen(false);
  }

  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
      <DialogContent style={{ width: "500px" }}>
        <TextField
          className="maxWidth"
          label="Comments"
          variant="outlined"
          multiline
          rows={4}
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className="primaryColorBackground"
          onClick={(e) => {
            submitApproval(e, "approved");
          }}
        >
          {league.event ? `Approve Event` : `Approve League`}
        </Button>
        <Button
          variant="contained"
          className="errBackground"
          onClick={(e) => {
            submitApproval(e, "draft");
          }}
        >
          {league.event ? `Reject Event` : `Reject League`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AdminLeagueApprovalForm;

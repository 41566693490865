import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import DialogPDF from "../../components/DialogPDF";
import { storage } from "../../firebase";
const PageOne = ({
  reward,
  availablePoints,
  handleNextPage,
  unitsSelected,
  setUnitsSelected,
  error,
  errorMessage,
}) => {
  const [primaryImage, setPrimaryImage] = useState(null);
  const [termsPDF, setTermsPDF] = useState("");
  const soldOut = reward.units === 0;
  useEffect(() => {
    setPrimaryImage(reward.imageURLS[0]);

    storage
      .ref()
      .child(`adminDocuments/rewardProgram/MatchPoints_Reward_Program.pdf`)
      .getDownloadURL()
      .then((url) => {
        setTermsPDF(url);
      });
  }, [reward]);
  const handleImagePreviewSelect = (img) => {
    setPrimaryImage(img);
  };
  return (
    <form className="columnMaxWidth" onSubmit={handleNextPage}>
      <div className="columnCenter">
        <div className="rewardPreviewImageWrapper">
          <Avatar src={primaryImage} variant="rounded" className="image200" />
        </div>
      </div>
      <div className="rowCenter">
        {reward.imageURLS.map((img, key) => (
          <div
            key={key}
            className="rewardPreviewImageWrapper"
            onClick={() => handleImagePreviewSelect(img)}
          >
            <Avatar variant="rounded" className="image50" src={img} />
          </div>
        ))}
      </div>
      <Typography className="rewardPurchaseItemPrice">Cost</Typography>
      <div className="rowCenter">
        <Typography className="rewardPurchaseItemPrice">
          {reward.points} Points
        </Typography>
        <div className="filler" />
        <Typography className="rewardPurchaseItemPrice">
          Points Available: {availablePoints}
        </Typography>
      </div>
      <div className="maxWidth">
        <Divider />
      </div>
      <Typography className="margin10Vertical rewardPurchaseItemDescription">
        {reward.description}
      </Typography>
      <Typography className="rewardPurchaseItemSubtitle">
        *Item not available outside continental US*
      </Typography>
      <Typography className="margin10Top rewardPurchaseFieldText">
        Quantity
      </Typography>
      <TextField
        variant="outlined"
        select
        onChange={(e) => setUnitsSelected(e.target.value)}
        value={unitsSelected}
      >
        {[...Array(reward.units + 1).keys()].map((i) => (
          <MenuItem key={i} value={i}>
            {i}
          </MenuItem>
        ))}
      </TextField>
      <div className="heightPlaceholder">
        {error && (
          <Typography className="signUpErrorText">{errorMessage}</Typography>
        )}
      </div>
      <div className="rowCenter">
        <Checkbox color="primary" required />
        <Typography className="rewardPurchaseItemDescription">
          I agree to the
        </Typography>
        <DialogPDF pdf={termsPDF} openText="MatchPoints Rewards Terms" />
      </div>
      <Button className="rewardPurchaseButton" disabled={soldOut} type="submit">
        <Typography className="rewardPurchaseButtonText">Continue</Typography>
      </Button>
    </form>
  );
};
export default PageOne;

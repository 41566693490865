import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { OutlinedButton } from "../../../components/CustomButton";
import { functions } from "../../../firebase";
const PlayerDecayAdjuster = () => {
    const [decayStatus, setDecayStatus] = useState("Start");
    const callDecayRatings = async () => {
        setDecayStatus("RUNNING");
        const fun = functions.httpsCallable("scoreCrud-recalculateRankings");
        await fun().catch(error => console.log(error));
        setDecayStatus("Start");
    };
    return (
        <>
            <Grid item xs={12} md={6}>
                <Typography className="margin10Bottom" variant="h6">
                    Decay User Ratings
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <OutlinedButton text={decayStatus} handleProcess={callDecayRatings} />
            </Grid>
        </>
    );
};

export default PlayerDecayAdjuster;
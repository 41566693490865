import axios from "axios";
const axiosApi = axios.create({
  baseURL: "https://us-central1-gamesetmatchproduction.cloudfunctions.net/api",
  // baseURL:
  //   "https://6b97-163-53-179-63.in.ngrok.io/gamesetmatch-19de0/us-central1/api",
});

// if a 401 happens, when token is expired
axiosApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    return Promise.reject(error);
  }
);

export default axiosApi;

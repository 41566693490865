import React, { Fragment } from "react";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import {
  DefaultTextField,
  MenuTextField,
  RichTextField,
} from "../../../components/CustomTextField";
import CheckboxList from "../../../components/CheckboxList";
const PageOne = ({
  leagueName,
  isPickleBall,
  setLeagueName,
  setLeagueDescription,
  leagueDescriptionSaved,
  imageDisplay,
  setImageDisplay,
  setImageFile,
  coverImageDisplay,
  setCoverImageDisplay,
  setCoverImageFile,
  areaCheckboxList,
  setAreaCheckboxList,
  error,
  errorMessage,
  sportType,
  setSportType,
}) => {
  const sportTypeSelect = [
    isPickleBall
      ? {
          label: "Pickleball",
          value: "pickleball",
        }
      : {
          label: "Tennis",
          value: "tennis",
        },
  ];

  return (
    <Fragment>
      <Typography className="createLeagueFieldText">Sport Type</Typography>
      <MenuTextField
        required={true}
        state={sportType}
        setState={setSportType}
        menuSelection={sportTypeSelect}
      />
      <Typography className="createLeagueFieldText margin20Top">
        What will you name your league?
      </Typography>
      <DefaultTextField
        required={true}
        state={leagueName}
        setState={setLeagueName}
        placeholder="Name"
      />
      <Typography className="createLeagueFieldText margin20Top">
        Describe your league
      </Typography>
      <RichTextField
        setValue={setLeagueDescription}
        defaultValue={leagueDescriptionSaved}
        placeholder="Describe your league..."
      />
      <Typography className="createLeagueFieldText margin20Top">
        Where is your league located?
      </Typography>
      <CheckboxList value={areaCheckboxList} setValue={setAreaCheckboxList} />
      <div className="heightPlaceholder">
        {error && (
          <Typography className="createLeagueErrorText">
            {errorMessage}
          </Typography>
        )}
      </div>
      <div className="rowCenter margin20Top">
        <div className=" margin10Right">
          <Typography className="createLeagueFieldText">
            Upload league image
          </Typography>
          <div className="createLeagueImageWrapper">
            <label htmlFor="leagueImage">
              <Avatar
                variant="rounded"
                className="profilePhoto padding10Horizontal"
                src={imageDisplay}
              />
            </label>
            <input
              type="file"
              hidden
              id="leagueImage"
              onChange={(e) => {
                setImageFile(e.target.files[0]);
                if (e.target.files[0] && e.target.files)
                  setImageDisplay(URL.createObjectURL(e.target.files[0]));
              }}
            />
          </div>
        </div>
        <div className="columnCenterMaxWidth margin10Left">
          <Typography className="createLeagueFieldText">
            Upload a cover image for your league
          </Typography>
          <div className="createLeagueImageWrapper">
            <label htmlFor="coverLeagueImage">
              <img
                className="createLeagueCoverImage padding10Horizontal"
                alt="alt"
                src={coverImageDisplay}
              />
            </label>
            <input
              type="file"
              hidden
              id="coverLeagueImage"
              onChange={(e) => {
                setCoverImageFile(e.target.files[0]);
                if (e.target.files[0] && e.target.files)
                  setCoverImageDisplay(URL.createObjectURL(e.target.files[0]));
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PageOne;

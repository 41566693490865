import React, {useState, Fragment} from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import { storage } from "../../../firebase";
import { CustomTabs } from "../../../components/CustomTabs";
import {
  DefaultTextField,
  RichTextField,
  DateTextField,
} from "../../../components/CustomTextField";
import DialogPDF from "../../../components/DialogPDF";
import ViewRichText from "../../../components/ViewRichText";

const pageLabels = ["Information", "Format", "Registration", "Prize Pool"];

const DirectorLeagueInfo = ({ league, zoneList, isPublic=false }) => {
  const [pageNum, setPageNum] = useState(0);
  const [leagueName, setLeagueName] = useState(league.name);
  const [leagueDescription, setLeagueDescription] = useState(league.description);
  const [leagueDescriptionSaved, setLeagueDescriptionSaved] = useState(league.description);
  const [endDate, setEndDate] = useState(league?.endDate?.toDate());
  const [endDateDisplay, setEndDateDisplay] = useState(moment(league?.endDate?.toDate()).format("MM/DD/YYYY"));
  const [imageIcon, setImageIcon] = useState(league.imageURL);
  const [coverImage, setCoverImage] = useState(league.coverImageURL);
  const [errorImage, setErrorImage] = useState('');
  const [errorCoverImage, setErrorCoverImage] = useState('');

    let leagueAreas = [];
  if(zoneList.length> 0){
    league.locations.forEach((areaID) => {
      leagueAreas.push(zoneList.find((zone) => zone.areaID === areaID).area);
    });
  }

  const updateLeague = async (e) => {
    e.preventDefault();
    await league.ref.update({
      name: leagueName,
      description: leagueDescription,
      endDate: endDate,
    });
    setLeagueDescriptionSaved(leagueDescription);
  };

  return (
    <div className="column filler">
      <CustomTabs labels={pageLabels} value={pageNum} setValue={setPageNum} />
      <form className="column filler" onSubmit={updateLeague}>
        <Grid container spacing={6}>
          {pageNum === 0 && (
            <Fragment>
              <Grid xs={12} md={4} lg={4} xl={4} item>
                <div className="columnAlignCenter">
                  <Typography className="profileBoldFieldText">
                    League Photo
                  </Typography>
                  <Avatar
                    className="image200"
                    variant="rounded"
                    src={imageIcon}
                  />
                  <input
                    type="file"
                    hidden
                    id="imageUpload"
                    onChange={async (e) => {
                      if (e.target.files[0] && e.target.files) {
                        const filesData = e.target.files[0];
                        setImageIcon(URL.createObjectURL(e.target.files[0]));

                        var reader = new FileReader();

                        //Read the contents of Image File.
                        reader.readAsDataURL(e.target.files[0]);
                       reader.onload = await function (load) {
                          //Initiate the JavaScript Image object.
                          var image = new Image();

                          //Set the Base64 string return from FileReader as source.
                          image.src = load.target.result;

                          //Validate the File Height and Width.
                         image.onload =async function () {
                            var height = this.height;
                            var width = this.width;
                            if (height < 200 || width < 200) {
                              setErrorImage("Height and Width should be 200px");
                            } else {
                              if(filesData) {
                                let getURL = "";
                                await storage
                                    .ref(`leagueDocuments/${league.id}/leaguePhoto`)
                                    .put(filesData);
                                await storage
                                    .ref()
                                    .child(`leagueDocuments/${league.id}/leaguePhoto`)
                                    .getDownloadURL()
                                    .then((url) => {
                                      getURL = url;
                                    });
                                await league.ref.update({imageURL: getURL});
                                setErrorImage('');
                              }
                            }
                          };
                        };
                      }
                    }
                    }
                  />
                  {!isPublic &&<label className="columnCenter" htmlFor="imageUpload">
                    <Button component="span" variant="text">
                      <Typography className="profileHighlightFieldText">
                        Change League Image
                      </Typography>
                    </Button>
                    {errorImage !=='' ? <Typography className="err">
                      {errorImage}
                    </Typography>:''}
                  </label>}
                </div>
              </Grid>
              <Grid xs={12} md={8} lg={8} xl={8} item>
                <div className="columnCenter">
                  <Typography className="profileBoldFieldText">
                    League Banner
                  </Typography>
                  <img
                    className="directorLeagueCoverImage"
                    alt="alt"
                    src={coverImage}
                  />
                  <input
                    type="file"
                    hidden
                    id="coverUpload"
                    onChange={async (e) => {
                      if (e.target.files[0] && e.target.files) {
                        const filesData = e.target.files[0];
                        setCoverImage(URL.createObjectURL(e.target.files[0]));

                        var reader = new FileReader();

                        //Read the contents of Image File.
                        reader.readAsDataURL(e.target.files[0]);

                        reader.onload = async function (load) {
                          //Initiate the JavaScript Image object.
                          var image = new Image();

                          //Set the Base64 string return from FileReader as source.
                          image.src = load.target.result;

                          //Validate the File Height and Width.
                          image.onload = async function () {
                            var height = this.height;
                            var width = this.width;
                            if (height < 200 || width < 200) {
                              setErrorCoverImage("Height and Width should be 200px");
                            } else {
                              if(filesData) {
                                let getURL = "";
                                await storage
                                    .ref(`leagueDocuments/${league.id}/coverImage`)
                                    .put(filesData);
                                await storage
                                    .ref()
                                    .child(`leagueDocuments/${league.id}/coverImage`)
                                    .getDownloadURL()
                                    .then((url) => {
                                      getURL = url;
                                    });
                                await league.ref.update({coverImageURL: getURL});
                                setErrorCoverImage('');
                              }
                            }
                          };
                        };
                      }
                    }}
                  />
                  {!isPublic && <label className="columnCenter" htmlFor="coverUpload">
                    <Button component="span" variant="text">
                      <Typography className="profileHighlightFieldText">
                        Change League Banner
                      </Typography>
                    </Button>
                    {errorCoverImage !=='' ? <Typography className="err">
                      {errorCoverImage}
                    </Typography>:''}
                  </label>}
                </div>
              </Grid>
              <Grid xs={12} item>
                <Typography className="createLeagueFieldText">
                  League Name
                </Typography>
                {!isPublic ? (
                    <DefaultTextField
                        required={true}
                        state={leagueName}
                        setState={setLeagueName}
                        placeholder="Name"
                        disabled={isPublic}
                    />
                ):(
                    <Typography>
                      {leagueName}
                    </Typography>
                )}
                <Typography className="createLeagueFieldText margin20Top">
                  League Description
                </Typography>
                {!isPublic ? (
                    <RichTextField
                        value={leagueDescription}
                        setValue={setLeagueDescription}
                        defaultValue={leagueDescriptionSaved}
                        placeholder="Describe your league..."
                    />
                ):(
                    <ViewRichText value={leagueDescriptionSaved} />
                )}
                <Typography className="createLeagueFieldText margin20Top">
                  End Date
                </Typography>
                {!isPublic? (
                    <DateTextField
                        dateString={endDateDisplay}
                        setDateString={setEndDateDisplay}
                        date={endDate}
                        setDate={setEndDate}
                        disabled={isPublic}
                    />
                ):(
                    <Typography>
                      {endDateDisplay}
                    </Typography>
                )}
              </Grid>
            </Fragment>
          )}
          {pageNum === 1 && (
            <Fragment>
              <Grid xs={12} item className={isPublic? 'margin20Top':''}>
                <Typography variant="h6" className="margin30LessTop capitalize">
                  <b> League Type</b> : {league?.format || "flex"}
                </Typography>
              </Grid>
              <Grid xs={12} item>
                <Typography variant="h6" className="margin30LessTop capitalize">
                  <b> Sport Type</b> : {league?.sportType || "tennis"}
                </Typography>
                <Typography className="createLeagueFieldText margin10Top">
                  Divisions
                </Typography>
                <div className="padding10">
                  {league.divisions.map((d, i) => (
                    <Fragment key={i}>
                      {d.playLevels.some((p) => p.check) && (
                        <Fragment>
                          <Typography variant="h6">{d.label}</Typography>
                          <div className="padding10">
                            {d.playLevels.map((pL, j) => (
                              <Fragment key={j}>
                                {pL.check && (
                                  <Typography variant="h6">
                                    {pL.label}
                                  </Typography>
                                )}
                              </Fragment>
                            ))}
                          </div>
                        </Fragment>
                      )}
                    </Fragment>
                  ))}
                </div>
              </Grid>
              <Grid xs={12} item>
                <Typography className="createLeagueFieldText">Rules</Typography>
                <DialogPDF pdf={league.rules} openText="Standard Rules" />
                {league.customRules !== "" && (
                  <DialogPDF
                    pdf={league.customRules}
                    openText="Additional Rules"
                  />
                )}
              </Grid>
            </Fragment>
          )}
          {pageNum === 2 && (
            <Fragment>
              <Grid xs={12} item>
                {league.isPrivate && (
                    <Typography variant="h6" className="rowCenter">
                      This is PRIVATE League (by invite Only): INVITE CODE: <Typography variant="h5" style={{color: "red", marginLeft: '5px'}}>{league.inviteCode}</Typography>
                    </Typography>
                )}
                {league.status === "pending" ? (
                  <Typography variant="h5">
                    Registration Start Date: Pending Approval
                  </Typography>
                ) : (
                  <Typography variant="h5">
                    Registration Start Date:{" "}
                    {moment(league?.approvalDate?.toDate()).format("M/D/YYYY")}
                  </Typography>
                )}
                <Typography variant="h5">
                  Registration Ends {league.registrationEndDate}
                </Typography>
                <Typography variant="h5">
                  This league can handle {league.numPlayerRange[0]} to{" "}
                  {league.numPlayerRange[1]} players
                </Typography>
              </Grid>
              <Grid xs={12} item>
                <Typography variant="h5">Availiable Areas</Typography>
                <div className="padding10">
                  {leagueAreas.map((area, index) => (
                    <Typography key={index} variant="subtitle1">
                      {area}
                    </Typography>
                  ))}
                </div>
              </Grid>
            </Fragment>
          )}
          {pageNum === 3 && (
            <Fragment>
              <Grid xs={12} item>
                <Typography variant="h5">
                  Price to join: ${league.price}
                </Typography>
                <Typography variant="h5">
                  You get a {league.percentCut}% cut of earnings
                </Typography>
                <Typography variant="h5">Prizes</Typography>
                <div className="padding10">
                  <Typography variant="subtitle1">
                    First Place: ${league.firstPlacePrize}
                  </Typography>
                  <Typography variant="subtitle1">
                    Second Place: ${league.secondPlacePrize}
                  </Typography>
                  <Typography variant="subtitle1">
                    Third Place: ${league.thirdPlacePrize}
                  </Typography>
                </div>
              </Grid>
            </Fragment>
          )}
        </Grid>
        {!isPublic && <>
          <div className="filler" />
          <Divider className="margin20Vertical" />
          <div className="rowCenter">
            <div className="filler"/>
            <Button
                className="profileSaveButton"
                variant="outlined"
                type="submit"
            >
              <Typography className="profileSaveButtonText">Save</Typography>
            </Button>
          </div>
        </>}
      </form>
    </div>
  );
};

export default DirectorLeagueInfo;

import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Typography from "@material-ui/core/Typography";
import {
  withStyles,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";

export const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica"],
  },
  palette: {
    primary: {
      main: "#55B56A",
      light: "#EDF8F4",
      dark: "#028360",
    },
    info: {
      main: "#F4B425",
    },
  },
  overrides: {
    MuiStepLabel: {
      root: {
        textAlign: "left",
        font: "16px/19px SF Pro Display",
        letterSpacing: "0px",
        color: "#55b56a",
        opacity: "1",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "10px",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
    MuiTextField: {
      root: {
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "#E1E87E",
      },
    },
  },
  props: {
    MuiTextField: {
      variant: "outlined",
    },
    MuiButton: {
      variant: "outlined",
    },
  },
});
export const theme2 = createTheme({
  typography: {
    fontFamily: ["Helvetica"],
  },
  palette: {
    primary: {
      main: "#6115AD",
      light: "#EDF8F4",
      dark: "#028360",
    },
    info: {
      main: "#F4B425",
    },
  },
  overrides: {
    MuiStepLabel: {
      root: {
        textAlign: "left",
        font: "16px/19px SF Pro Display",
        letterSpacing: "0px",
        color: "#55b56a",
        opacity: "1",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "10px",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
    MuiTextField: {
      root: {
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "#E1E87E",
      },
    },
  },
  props: {
    MuiTextField: {
      variant: "outlined",
    },
    MuiButton: {
      variant: "outlined",
    },
  },
});
const CustomConnector = withStyles({
  alternativeLabel: {
    top: 10,
  },

  line: {
    height: 3,
    border: 0,
    backgroundColor: "#e1e8ed",
    borderRadius: 1,
  },
})(StepConnector);

const CustomStepper = ({
  step,
  setStep,
  stepLabels,
  stepIcons,
  isPickleBall,
}) => {
  const handleClick = (index) => {
    if (index < step) {
      setStep(index);
    }
  };
  if (stepIcons === undefined)
    return (
      <ThemeProvider theme={isPickleBall ? theme2 : theme}>
        <Stepper
          className="stepperMain"
          activeStep={step}
          connector={<CustomConnector />}
        >
          {stepLabels.map((stepLabel, index) => (
            <Step onClick={() => handleClick(index)} key={index}>
              <StepLabel>
                <Typography className="stepperLabel">{stepLabel}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </ThemeProvider>
    );

  return (
    <Stepper step={step} connector={<CustomConnector />}>
      {stepLabels.map((stepLabel, index) => (
        <Step onClick={() => handleClick(index)} key={index}>
          <StepLabel StepIconComponent={stepIcons[index]}>
            {stepLabel}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
export default CustomStepper;
